"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionWhen = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const rule_1 = require("./rule");
const rule_contact_1 = require("./rule_contact");
const misc_1 = require("../../misc");
const utils_1 = require("../utils");
exports.JourneyActionWhen = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.When,
    settings_schema: (0, base_1.settingsValidationSchema)({
        type: {
            type: 'string',
            valid: (0, misc_1.EnumValues)(enums_1.JourneyActionTriggerType),
            default: enums_1.JourneyActionTriggerType.ConversationUpdated,
        },
        condition: {
            type: 'object',
            properties: {
                changed: {
                    type: 'array',
                    items: {
                        type: 'string',
                        valid: rule_1.WHEN_CONDITION_CHANGED_OPTIONS,
                    },
                },
                data_changed: {
                    type: 'array',
                    items: {
                        type: 'string',
                    },
                },
            },
            when: [
                { reference: { '$ref': 'type', }, is: { type: 'string', valid: enums_1.JourneyActionTriggerType.ConversationUpdated, }, then: { type: 'any', required: true, }, otherwise: { type: 'any', forbidden: true, }, },
            ],
        },
        contact_condition: {
            type: 'object',
            properties: {
                changed: {
                    type: 'array',
                    items: {
                        type: 'string',
                        valid: rule_contact_1.WHEN_CONTACT_CONDITION_CHANGED_OPTIONS,
                    },
                },
                data_changed: {
                    type: 'array',
                    items: {
                        type: 'string',
                    },
                },
            },
            when: [
                { reference: { '$ref': 'type', }, is: { type: 'string', valid: enums_1.JourneyActionTriggerType.ContactUpdated, }, then: { type: 'any', required: true, }, otherwise: { type: 'any', forbidden: true, }, },
            ],
        },
        since: {
            type: 'object',
            properties: {
                source: {
                    type: 'string',
                    valid: (0, misc_1.EnumValues)(enums_1.JourneyWhenSinceSourceEnum),
                    required: true,
                },
                since: {
                    type: 'object',
                    required: true,
                    properties: {
                        time: {
                            type: 'number',
                            required: true,
                            min: 1,
                        },
                        unit: {
                            type: 'string',
                            required: true,
                            valid: (0, misc_1.EnumValues)(enums_1.SlaTimeUnitEnum),
                        },
                    },
                },
            },
            when: [
                { reference: { '$ref': 'type', }, is: { type: 'any', valid: enums_1.JourneyActionTriggerType.SinceConversation, }, then: { type: 'any', required: true, }, otherwise: { type: 'any', forbidden: true, }, },
            ],
        },
    }),
    getPorts: (node) => {
        return {
            [constants_1.JOURNEY_ACTION_WHEN_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_WHEN_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: true,
                index: 0,
                title: 'TRIGGERED',
                color: null,
            }),
        };
    },
});
