"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionVoiceVoicemail = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
exports.JourneyActionVoiceVoicemail = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.VoiceVoicemail,
    channels: [enums_1.ChannelEnum.Voice,],
    direction: enums_1.JourneyNodeDirectionEnum.Output,
    settings_schema: (0, base_1.settingsValidationSchema)({
        voice_voicemail_id: {
            type: 'number',
            required: true,
        },
        asterisk_sound_id: {
            type: 'number',
            allow: [null,],
            default: null,
        },
    }),
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_VOICE_VOICEMAIL_INITIATED_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_VOICE_VOICEMAIL_INITIATED_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                title: 'INITIATED',
            }), [constants_1.JOURNEY_ACTION_VOICE_VOICEMAIL_DONE_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_VOICE_VOICEMAIL_DONE_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                required: true,
                title: 'DONE',
                color: '#5bbf1c',
            }) });
    },
});
