import { useCallback, useEffect, useState } from "react";
import {
  ISharedFullConversation,
  IConversationSummary,
} from "atlas-shared/dist/interfaces/entities/conversation.interface";
import { fetchConversationSummaries, summarizeConversation } from "@Api";

export const useConversationSummary = (conversation: ISharedFullConversation) => {
  const [newSummary, setNewSummary] = useState<IConversationSummary | null>(null);
  const [newSummaryLoading, setNewSummaryLoading] = useState(false);
  const [newSummaryError, setNewSummaryError] = useState<string | null>(null);
  const [summaries, setSummaries] = useState<IConversationSummary[]>([]);
  const [summariesLoading, setSummariesLoading] = useState(false);
  const [summariesError, setSummariesError] = useState<string | null>(null);
  const [isSummaryOutdated, setIsSummaryOutdated] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const lastConversationMessageTimestamp = conversation.messages?.[0]?.created_at;
  const lastSummaryTimestamp = summaries?.[0]?.createdAt;

  useEffect(() => {
    if (!lastConversationMessageTimestamp) return;

    const latestMessageDate = new Date(lastConversationMessageTimestamp);
    const latestSummaryDate = lastSummaryTimestamp ? new Date(lastSummaryTimestamp) : null;

    setIsSummaryOutdated(latestSummaryDate !== null && latestMessageDate > latestSummaryDate);
  }, [lastConversationMessageTimestamp, lastSummaryTimestamp]);

  const getConversationSummary = useCallback(async () => {
    setNewSummaryLoading(true);
    try {
      const summaryData = await summarizeConversation(conversation.id);
      setNewSummary(summaryData);
    } catch (error) {
      setNewSummaryError(error as string);
    } finally {
      setNewSummaryLoading(false);
    }
  }, [conversation.id]);

  const getConversationSummaries = useCallback(async () => {
    setSummariesLoading(true);
    try {
      const summariesData = await fetchConversationSummaries(conversation.id);
      setSummaries(summariesData.reverse());
    } catch (error) {
      setSummariesError(error as string);
    } finally {
      setSummariesLoading(false);
    }
  }, [conversation.id]);

  useEffect(() => {
    getConversationSummaries();
  }, [conversation.id]);

  return {
    getConversationSummary,
    newSummary,
    newSummaryLoading,
    newSummaryError,
    summaries,
    summariesLoading,
    summariesError,
    isSummaryOutdated,
    isOpen,
    setIsOpen,
  };
};
