"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionDict = exports.PermissionEnum = void 0;
var PermissionEnum;
(function (PermissionEnum) {
    PermissionEnum["Adminprofile"] = "Adminprofile";
    PermissionEnum["Agentprofile"] = "Agentprofile";
    PermissionEnum["AiAssistant"] = "AiAssistant";
    PermissionEnum["ApiKey"] = "ApiKey";
    PermissionEnum["Audit"] = "Audit";
    PermissionEnum["CannedAnswer"] = "CannedAnswer";
    PermissionEnum["Client"] = "Client";
    PermissionEnum["Contact"] = "Contact";
    PermissionEnum["ContactCustomEvent"] = "ContactCustomEvent";
    PermissionEnum["Conversation"] = "Conversation";
    PermissionEnum["ConversationFavourite"] = "ConversationFavourite";
    PermissionEnum["ConversationFilter"] = "ConversationFilter";
    PermissionEnum["ConversationWatcher"] = "ConversationWatcher";
    PermissionEnum["DestinationRestrictor"] = "DestinationRestrictor";
    PermissionEnum["Disposition"] = "Disposition";
    PermissionEnum["Draft"] = "Draft";
    PermissionEnum["Field"] = "Field";
    PermissionEnum["Form"] = "Form";
    PermissionEnum["FormSubmission"] = "FormSubmission";
    PermissionEnum["FormWebsite"] = "FormWebsite";
    PermissionEnum["Gdpr"] = "Gdpr";
    PermissionEnum["GdprAnonymize"] = "GdprAnonymize";
    PermissionEnum["InternalNote"] = "InternalNote";
    PermissionEnum["Invoice"] = "Invoice";
    PermissionEnum["InvoicePost"] = "InvoicePost";
    PermissionEnum["Journey"] = "Journey";
    PermissionEnum["JourneyVariable"] = "JourneyVariable";
    PermissionEnum["LicenseHistory"] = "LicenseHistory";
    PermissionEnum["List"] = "List";
    PermissionEnum["MailAccount"] = "MailAccount";
    PermissionEnum["MailMessage"] = "MailMessage";
    PermissionEnum["Notification"] = "Notification";
    PermissionEnum["NotificationUser"] = "NotificationUser";
    PermissionEnum["OperationalHours"] = "OperationalHours";
    PermissionEnum["Organization"] = "Organization";
    PermissionEnum["PasswordHistory"] = "PasswordHistory";
    PermissionEnum["Pause"] = "Pause";
    PermissionEnum["PhonenumberMonthlyCost"] = "PhonenumberMonthlyCost";
    PermissionEnum["PhonenumberMonthlyCostParent"] = "PhonenumberMonthlyCostParent";
    PermissionEnum["PhonenumberPrefix"] = "PhonenumberPrefix";
    PermissionEnum["PhonenumberPurchase"] = "PhonenumberPurchase";
    PermissionEnum["PhonenumberSetupCost"] = "PhonenumberSetupCost";
    PermissionEnum["PhonenumberSetupCostParent"] = "PhonenumberSetupCostParent";
    PermissionEnum["PhonenumberStock"] = "PhonenumberStock";
    PermissionEnum["PointsSystem"] = "PointsSystem";
    PermissionEnum["PrepaidCredit"] = "PrepaidCredit";
    PermissionEnum["Queue"] = "Queue";
    PermissionEnum["RecentlySearched"] = "RecentlySearched";
    PermissionEnum["RecentlyViewedConversation"] = "RecentlyViewedConversation";
    PermissionEnum["Release"] = "Release";
    PermissionEnum["Session"] = "Session";
    PermissionEnum["Signature"] = "Signature";
    PermissionEnum["Skill"] = "Skill";
    PermissionEnum["SlaPolicy"] = "SlaPolicy";
    PermissionEnum["Sound"] = "Sound";
    PermissionEnum["StatsAlarm"] = "StatsAlarm";
    PermissionEnum["StatsCustomMetric"] = "StatsCustomMetric";
    PermissionEnum["StatsReport"] = "StatsReport";
    PermissionEnum["StatsReportPreviewWidget"] = "StatsReportPreviewWidget";
    PermissionEnum["StatsReportWidget"] = "StatsReportWidget";
    PermissionEnum["StatsWidget"] = "StatsWidget";
    PermissionEnum["SuperadminProfile"] = "SuperadminProfile";
    PermissionEnum["Tab"] = "Tab";
    PermissionEnum["Tag"] = "Tag";
    PermissionEnum["Tariff"] = "Tariff";
    PermissionEnum["TariffParent"] = "TariffParent";
    PermissionEnum["Team"] = "Team";
    PermissionEnum["TextMessage"] = "TextMessage";
    PermissionEnum["User"] = "User";
    PermissionEnum["TimeInterval"] = "TimeInterval";
    PermissionEnum["UserActivity"] = "UserActivity";
    PermissionEnum["VoiceAsterisk"] = "VoiceAsterisk";
    PermissionEnum["VoiceCall"] = "VoiceCall";
    PermissionEnum["VoiceChanspy"] = "VoiceChanspy";
    PermissionEnum["VoiceChanspyCall"] = "VoiceChanspyCall";
    PermissionEnum["VoiceInternalCall"] = "VoiceInternalCall";
    PermissionEnum["VoiceRecording"] = "VoiceRecording";
    PermissionEnum["VoiceRecordingTranscript"] = "VoiceRecordingTranscript";
    PermissionEnum["VoiceRecordingTranscriptSetting"] = "VoiceRecordingTranscriptSetting";
    PermissionEnum["VoiceRoute"] = "VoiceRoute";
    PermissionEnum["VoiceShortcut"] = "VoiceShortcut";
    PermissionEnum["VoiceTrunk"] = "VoiceTrunk";
    PermissionEnum["VoiceVoicemail"] = "VoiceVoicemail";
    PermissionEnum["VoiceVoicemailMessage"] = "VoiceVoicemailMessage";
    PermissionEnum["Wallboard"] = "Wallboard";
})(PermissionEnum = exports.PermissionEnum || (exports.PermissionEnum = {}));
exports.PermissionDict = {
    Adminprofile: PermissionEnum.Adminprofile,
    Agentprofile: PermissionEnum.Agentprofile,
    AgentprofilePause: PermissionEnum.Agentprofile,
    AgentprofileQueue: PermissionEnum.Agentprofile,
    AgentprofileTeam: PermissionEnum.Agentprofile,
    AgentprofileVoiceShortcut: PermissionEnum.Agentprofile,
    AiAssistant: PermissionEnum.AiAssistant,
    ApiKey: PermissionEnum.ApiKey,
    Audit: PermissionEnum.Audit,
    CannedAnswer: PermissionEnum.CannedAnswer,
    Client: PermissionEnum.Client,
    Contact: PermissionEnum.Contact,
    ContactCustomEvent: PermissionEnum.ContactCustomEvent,
    Conversation: PermissionEnum.Conversation,
    ConversationFavourite: PermissionEnum.ConversationFavourite,
    ConversationFilter: PermissionEnum.ConversationFilter,
    ConversationTag: PermissionEnum.Conversation,
    ConversationWatcher: PermissionEnum.ConversationWatcher,
    DestinationRestrictor: PermissionEnum.DestinationRestrictor,
    Disposition: PermissionEnum.Disposition,
    Draft: PermissionEnum.Draft,
    Field: PermissionEnum.Field,
    Form: PermissionEnum.Form,
    FormField: PermissionEnum.Form,
    FormSubmission: PermissionEnum.FormSubmission,
    FormWebsite: PermissionEnum.FormWebsite,
    Gdpr: PermissionEnum.Gdpr,
    GdprAnonymize: PermissionEnum.GdprAnonymize,
    InternalNote: PermissionEnum.InternalNote,
    Invoice: PermissionEnum.Invoice,
    InvoicePost: PermissionEnum.InvoicePost,
    Journey: PermissionEnum.Journey,
    JourneyDraft: PermissionEnum.Journey,
    JourneyTrigger: PermissionEnum.Journey,
    JourneyVariable: PermissionEnum.JourneyVariable,
    LicenseHistory: PermissionEnum.LicenseHistory,
    List: PermissionEnum.List,
    ListContact: PermissionEnum.List,
    ListFile: PermissionEnum.List,
    MailAccount: PermissionEnum.MailAccount,
    MailMessage: PermissionEnum.MailMessage,
    Notification: PermissionEnum.Notification,
    NotificationUser: PermissionEnum.NotificationUser,
    OperationalHours: PermissionEnum.OperationalHours,
    OperationalHoursTimeInterval: PermissionEnum.OperationalHours,
    Organization: PermissionEnum.Organization,
    PasswordHistory: PermissionEnum.PasswordHistory,
    Pause: PermissionEnum.Pause,
    PhonenumberMonthlyCost: PermissionEnum.PhonenumberMonthlyCost,
    PhonenumberMonthlyCostParent: PermissionEnum.PhonenumberMonthlyCostParent,
    PhonenumberPrefix: PermissionEnum.PhonenumberPrefix,
    PhonenumberPurchase: PermissionEnum.PhonenumberPurchase,
    PhonenumberSetupCost: PermissionEnum.PhonenumberSetupCost,
    PhonenumberSetupCostParent: PermissionEnum.PhonenumberSetupCostParent,
    PhonenumberStock: PermissionEnum.PhonenumberStock,
    PointsSystem: PermissionEnum.PointsSystem,
    PrepaidCredit: PermissionEnum.PrepaidCredit,
    Queue: PermissionEnum.Queue,
    QueueCannedAnswer: PermissionEnum.Queue,
    QueueDisposition: PermissionEnum.Queue,
    QueueOutboundMailAccount: PermissionEnum.Queue,
    QueueOutboundVoiceRoute: PermissionEnum.Queue,
    QueueSignature: PermissionEnum.Queue,
    QueueTag: PermissionEnum.Queue,
    RecentlySearched: PermissionEnum.RecentlySearched,
    RecentlyViewedConversation: PermissionEnum.RecentlyViewedConversation,
    Release: PermissionEnum.Release,
    Session: PermissionEnum.Session,
    Signature: PermissionEnum.Signature,
    Skill: PermissionEnum.Skill,
    SlaPolicy: PermissionEnum.SlaPolicy,
    Sound: PermissionEnum.Sound,
    StatsAlarm: PermissionEnum.StatsAlarm,
    StatsCustomMetric: PermissionEnum.StatsCustomMetric,
    StatsReport: PermissionEnum.StatsReport,
    StatsReportPreviewWidget: PermissionEnum.StatsReportPreviewWidget,
    StatsReportWidget: PermissionEnum.StatsReportWidget,
    StatsWidget: PermissionEnum.StatsWidget,
    SuperadminProfile: PermissionEnum.SuperadminProfile,
    Tab: PermissionEnum.Tab,
    Tag: PermissionEnum.Tag,
    Tariff: PermissionEnum.Tariff,
    TariffParent: PermissionEnum.TariffParent,
    Team: PermissionEnum.Team,
    TeamUser: PermissionEnum.User,
    TimeInterval: PermissionEnum.TimeInterval,
    User: PermissionEnum.User,
    UserActivity: PermissionEnum.UserActivity,
    UserOrganization: PermissionEnum.User,
    UserStatus: PermissionEnum.User,
    VoiceAsterisk: PermissionEnum.VoiceAsterisk,
    VoiceAsteriskDowngrade: PermissionEnum.VoiceAsterisk,
    VoiceAsteriskOperationalHours: PermissionEnum.VoiceAsterisk,
    VoiceAsteriskToggleHistory: PermissionEnum.VoiceAsterisk,
    VoiceCall: PermissionEnum.VoiceCall,
    VoiceCallBridge: PermissionEnum.VoiceCall,
    VoiceChanspy: PermissionEnum.VoiceChanspy,
    VoiceChanspyCall: PermissionEnum.VoiceChanspyCall,
    VoiceInternalCall: PermissionEnum.VoiceInternalCall,
    VoiceRecording: PermissionEnum.VoiceRecording,
    VoiceRecordingTranscript: PermissionEnum.VoiceRecordingTranscript,
    VoiceRecordingTranscriptSetting: PermissionEnum.VoiceRecordingTranscriptSetting,
    VoiceRoute: PermissionEnum.VoiceRoute,
    VoiceShortcut: PermissionEnum.VoiceShortcut,
    VoiceTrunk: PermissionEnum.VoiceTrunk,
    VoiceVoicemail: PermissionEnum.VoiceVoicemail,
    VoiceVoicemailMessage: PermissionEnum.VoiceVoicemailMessage,
    Wallboard: PermissionEnum.Wallboard,
};
