"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionUnAssign = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
exports.JourneyActionUnAssign = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.UnAssign,
    settings_schema: (0, base_1.settingsValidationSchema)({
        queue: {
            type: 'boolean',
            required: true,
            default: false,
        },
        user: {
            type: 'boolean',
            required: true,
            default: false,
        },
    }),
    getPorts: (node) => {
        const ports = node.ports;
        if (Object.keys(ports).length !== 2)
            return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_UNASSIGN_UNASSIGNED_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                    id: constants_1.JOURNEY_ACTION_UNASSIGN_UNASSIGNED_OUTPUT,
                    title: 'UNASSIGNED',
                }) });
        return ports;
    },
});
