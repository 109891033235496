import { IOption } from "@Utils";

export const countTotalOrganizations = (options: IOption[]): number => {
  return options.reduce((count, option) => {
    let total = 1;

    if (option.children && Array.isArray(option.children)) {
      total += countTotalOrganizations(option.children);
    }
    return count + total;
  }, 0);
};
