"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionReplyLatestInboundMessage = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
exports.JourneyActionReplyLatestInboundMessage = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.ReplyLatestInboundMessage,
    channels: [enums_1.ChannelEnum.Mail, enums_1.ChannelEnum.Form,],
    settings_schema: (0, base_1.settingsValidationSchema)({
        subject: {
            type: 'string',
            default: null,
            allow: [null,],
        },
        subject_prefix: {
            type: 'string',
            default: 'Re: ',
            allow: [null,],
        },
        mail_account_id: {
            type: 'number',
            required: true,
        },
        canned_answer_id: {
            type: 'number',
            required: true,
        },
        canned_answer_id2: {
            type: 'number',
            allow: [null,],
            default: null,
        },
        canned_answer_id3: {
            type: 'number',
            allow: [null,],
            default: null,
        },
        canned_answer_id4: {
            type: 'number',
            allow: [null,],
            default: null,
        },
        signature_id: {
            type: 'number',
            allow: [null,],
            default: null,
        },
    }),
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_REPLY_LATEST_INBOUND_MESSAGE_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_REPLY_LATEST_INBOUND_MESSAGE_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: false,
                index: 0,
                title: 'NEXT',
                color: null,
            }) });
    },
});
