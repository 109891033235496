"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionAgent = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const misc_1 = require("../../misc");
exports.JourneyActionAgent = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.Agent,
    channels: (0, misc_1.EnumValues)(enums_1.ChannelEnum).filter(c => c !== enums_1.ChannelEnum.Voice),
    settings_schema: (0, base_1.settingsValidationSchema)({
        queue_id: {
            type: 'number',
            required: true,
        },
        user_id: {
            type: 'number',
            required: true,
        },
    }),
    getPorts: (node) => {
        const ports = node.ports;
        if (Object.keys(ports).length !== 2)
            return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_AGENT_ASSIGNED_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                    id: constants_1.JOURNEY_ACTION_AGENT_ASSIGNED_OUTPUT,
                    title: 'ASSIGNED',
                }) });
        return ports;
    },
});
