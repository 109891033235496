import { TFunction, } from 'i18next';
import React, { useCallback, useEffect, useState, } from 'react';
import { ConversationFilterSortColumnEnum, IPreviewConversation, ISharedConversationFilter, } from 'atlas-shared';
import { timeSince, } from '@Utils';

interface IProps {
  t: TFunction;
  conversation: IPreviewConversation;
  selectedFilter?: ISharedConversationFilter;
}

export const ConversationTime = React.memo(({ conversation, selectedFilter, t, }: IProps) => {
  const renderTime = useCallback(() => {

    let timestamp;

    if (selectedFilter?.sort_by?.column)
      switch (selectedFilter.sort_by.column) {

      case ConversationFilterSortColumnEnum.CreatedAt: {
        timestamp = conversation.created_at;

        break;
      }

      case ConversationFilterSortColumnEnum.StatusAt: {
        timestamp = conversation.status_at;

        break;
      }

      case ConversationFilterSortColumnEnum.LastMessageAt: {
        timestamp = conversation.last_message_at;

        break;
      }

      case ConversationFilterSortColumnEnum.LastOutboundMessageAt: {
        timestamp = conversation.last_inbound_message_at;

        break;
      }

      case ConversationFilterSortColumnEnum.LastInboundMessageAt: {
        timestamp = conversation.last_outbound_message_at;

        break;
      }

      case ConversationFilterSortColumnEnum.ResolutionTime: {
        timestamp = conversation.resolution_time;

        break;
      }

      case ConversationFilterSortColumnEnum.ResponseTime: {
        timestamp = conversation.response_time;

        break;
      }

      case ConversationFilterSortColumnEnum.AssignedAt: {
        timestamp = conversation.assigned_at;

        break;
      }

      }

    return timestamp ? timeSince(t, new Date(timestamp)) : '';
  }, [t, conversation, selectedFilter, ]);

  const [time, setTime, ] = useState<string>(renderTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(renderTime());
      return () => clearInterval(timer);
    }, 60_000);
  }, [conversation, selectedFilter, renderTime, ]);

  return (
    <small
      className='time'
      title={t(selectedFilter?.sort_by?.column?.toUpperCase() || '-')}
    >
      {time}
    </small>
  );
});
