import { RestRequest } from "@Utils";
import {
  IOngoingChannel,
  IPreviewVoiceRecording,
  ISharedVoiceCall,
  ISharedVoiceRecording,
  IPreviewVoiceCall,
  ISharedVoiceCallBridge,
  ChannelEnum,
  ISharedConversation,
  IConversationTimeline,
  IPreviewVoiceCallBridge,
  ICreateExternalBlindTransferRequest,
  ICreateInternalBlindTransferRequest,
} from "atlas-shared";
import { checkFlag } from "./featureFlagApi";

export const voiceCallStartRecording = async (
  organization_id: ISharedVoiceCall["organization_id"],
  voice_call_id: ISharedVoiceCall["id"],
  voice_call_bridge_id: ISharedVoiceCallBridge["id"]
): Promise<any> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/start_recording", {
      method: "POST",
      body: JSON.stringify({ voice_call_bridge_id, organization_id, voice_call_id }),
      headers: { "Content-Type": "application/json" },
    }).then((v) => v.json());
  } else {
    return RestRequest.post<any, ISharedVoiceRecording>(
      `voice_recording/${organization_id}/${voice_call_id}/${voice_call_bridge_id}/start_recording`,
      {}
    );
  }
};

export const voiceCallStopRecording = async (
  organization_id: ISharedVoiceCall["organization_id"],
  voice_recording_id: IPreviewVoiceRecording["id"]
): Promise<any> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/stop_recording", {
      method: "POST",
      body: JSON.stringify({ voice_recording_id }),
      headers: { "Content-Type": "application/json" },
    }).then((v) => v.json());
  } else {
    return RestRequest.put<any, ISharedVoiceRecording>(
      `voice_recording/${organization_id}/${voice_recording_id}/stop_recording`,
      {}
    );
  }
};

export const voiceCallsOngoing = (): Promise<Array<IOngoingChannel>> => {
  return RestRequest.get<Array<IOngoingChannel>>("voice_call/ongoing");
};

export const hangupVoiceCall = async (
  organization_id: ISharedVoiceCall["organization_id"],
  id: IPreviewVoiceCallBridge["id"]
): Promise<IPreviewVoiceCall> => {
  return RestRequest.delete<IPreviewVoiceCall>(`voice_call/p/${organization_id}/${id}/terminate`);
};

export const blindTransferVoiceCallExternal = async (
  organization_id: ISharedVoiceCall["organization_id"],
  id: IPreviewVoiceCall["id"],
  voice_call_bridge_id: IPreviewVoiceCallBridge["id"],
  body: ICreateExternalBlindTransferRequest
): Promise<ISharedVoiceCallBridge> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/blind_transfer", {
      method: "POST",
      body: JSON.stringify({ voice_call_bridge_id, ...body } as any),
      headers: { "content-type": "application/json" },
    }).then((v) => v.json());
  } else {
    return RestRequest.put<ICreateExternalBlindTransferRequest, ISharedVoiceCallBridge>(
      `voice_call/p/${organization_id}/${id}/${voice_call_bridge_id}/blind-transfer/external`,
      body
    );
  }
};

export const blindTransferVoiceCallInternal = async (
  organization_id: ISharedVoiceCall["organization_id"],
  id: IPreviewVoiceCall["id"],
  voice_call_bridge_id: IPreviewVoiceCallBridge["id"],
  body: ICreateInternalBlindTransferRequest
): Promise<ISharedVoiceCallBridge> => {
  return fetch(
    process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/internal_blind_transfer",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...body,
        organization_id,
        id,
        voice_call_bridge_id,
      }),
    }
  ).then((v) => v.json());
};

export const attendedTransferVoiceCallBridge = async (
  organization_id: ISharedVoiceCall["organization_id"],
  bridge_id: IPreviewVoiceCall["id"],
  transfer_bridge_id: IPreviewVoiceCall["id"]
): Promise<ISharedVoiceCallBridge> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/attended_transfer", {
      body: JSON.stringify({
        bridge_id,
        transfer_bridge_id,
      }),
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    }).then((v) => v.json());
  } else {
    return RestRequest.put<{}, ISharedVoiceCallBridge>(
      `voice_call/p/${organization_id}/${bridge_id}/attended-transfer/${transfer_bridge_id}`,
      {}
    );
  }
};

export const hangupVoiceCallBridge = async (
  organization_id: ISharedVoiceCall["organization_id"],
  voice_call_bridge_id: IPreviewVoiceCallBridge["id"]
): Promise<boolean> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/hangup", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ voice_call_bridge_id }),
    }) as any;
  } else {
    return RestRequest.delete<boolean>(
      `voice_call/p/${organization_id}/${voice_call_bridge_id}/hangup`
    );
  }
};

export const holdVoiceCallBridge = async (
  organization_id: ISharedVoiceCall["organization_id"],
  voice_call_bridge_id: IPreviewVoiceCallBridge["id"]
): Promise<boolean> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/hold", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ voice_call_bridge_id }),
    }) as any;
  } else {
    return RestRequest.put<any, boolean>(
      `voice_call/p/${organization_id}/${voice_call_bridge_id}/hold`,
      {}
    );
  }
};

export const unholdVoiceCallBridge = async (
  organization_id: ISharedVoiceCall["organization_id"],
  voice_call_bridge_id: IPreviewVoiceCallBridge["id"]
): Promise<boolean> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/unhold", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ voice_call_bridge_id }),
    }) as any;
  } else {
    return RestRequest.put<any, boolean>(
      `voice_call/p/${organization_id}/${voice_call_bridge_id}/unhold`,
      {}
    );
  }
};

export const muteVoiceCallBridge = async (
  organization_id: ISharedVoiceCall["organization_id"],
  voice_call_bridge_id: IPreviewVoiceCallBridge["id"]
): Promise<boolean> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/mute", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ voice_call_bridge_id }),
    }) as any;
  } else {
    return RestRequest.put<any, boolean>(
      `voice_call/p/${organization_id}/${voice_call_bridge_id}/mute`,
      {}
    );
  }
};

export const unmuteVoiceCallBridge = async (
  organization_id: ISharedVoiceCall["organization_id"],
  voice_call_bridge_id: IPreviewVoiceCallBridge["id"]
): Promise<boolean> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/unmute", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ voice_call_bridge_id }),
    }) as any;
  } else {
    return RestRequest.put<any, boolean>(
      `voice_call/p/${organization_id}/${voice_call_bridge_id}/unmute`,
      {}
    );
  }
};

export const voiceCallsTimeline = (
  organization_id: ISharedVoiceCall["organization_id"],
  conversation_id: ISharedConversation["id"],
  voice_call_id: ISharedVoiceCall["id"]
): Promise<{ voice_call: IPreviewVoiceCall; events: Array<IConversationTimeline> }> => {
  return RestRequest.get<{ voice_call: IPreviewVoiceCall; events: Array<IConversationTimeline> }>(
    `conversation/p/${organization_id}/${conversation_id}/${ChannelEnum.Voice}/${voice_call_id}/timeline`,
    {}
  );
};

export const fetchVoiceCalls = async (): Promise<Array<IPreviewVoiceCall>> => {
  return RestRequest.get<Array<IPreviewVoiceCall>>("voice_call", { socket_id: global.socketId });
};

export { fetchVoiceCall, patchVoiceCall } from "./abstract/voice_call.api.abstract";
