import { getFullName, getOutboundAsterisk, VoiceCallTitle, } from '@Utils';
import React from 'react';
import {
  IPreviewQueue,
  IPreviewUser,
  IPreviewUserStatus,
  UserStatusStatusEnum,
  DirectionEnum, } from 'atlas-shared';
import { TVoiceSuggestionOnSelect, } from './voice-suggestion.utils';
import { UserIcon, } from '@Assets/icons';
import { IconText, StatusColor, } from '@Components';
import { IAuth, } from '@Store';

interface IProps {
  auth: IAuth;
  user: IPreviewUser;
  user_status: IPreviewUserStatus;
  queue?: IPreviewQueue;
  onSelect: TVoiceSuggestionOnSelect;
  required_route?: boolean;
  callBtn: React.ReactNode;
}

export const VoiceSuggestionAgent = React.memo(({ auth, user, user_status, onSelect, queue, required_route, callBtn, }: IProps) => {
  const clickable = user_status.voice_status < UserStatusStatusEnum.Busy;

  return <div
    className={`li-content${clickable ? ' clickable' : ''} voice-suggestion-queue-agent`}
    onClick={() => {
      if (clickable) {
        const overlapping_voice_asterisks = queue ? [getOutboundAsterisk(user_status), ] : user_status.__current_voice_asterisks.filter(voice_asterisk_item => auth.user_status.__current_voice_asterisks.some(_voice_asterisk_item => _voice_asterisk_item.vaid === voice_asterisk_item.vaid));

        onSelect(overlapping_voice_asterisks.map(overlapping_voice_asterisk => {
          return {
            direction: DirectionEnum.Internal,
            title: <VoiceCallTitle title={getFullName(user)} voice_asterisk_id={overlapping_voice_asterisk.vaid} />,
            voice_asterisk_id: overlapping_voice_asterisk.vaid,
            numbers: [`u-${user_status.user_id}-${user_status.agentprofile_id || 0}-q-0${queue?.id || 0}`, ],
            requires_route: !!required_route,
            callee_user_id: user_status.user_id,
            callee_agentprofile_id: user_status.agentprofile_id || 0,
            agentprofile_id: auth.user_status.agentprofile_id || 0,
            queue_id: queue?.id,
          };
        }), true);
      }
    }
    }
  >
    <IconText icon={UserIcon} iconWrapperStyle={{ paddingLeft: queue ? 10 : 0, }} text={getFullName(user)} postFix={<StatusColor status={user_status.voice_status} pause_id={user_status.pause_id} tooltip={true} />} />
    {callBtn}
  </div>;
});
