"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionJourney = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
exports.JourneyActionJourney = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.Journey,
    settings_schema: (0, base_1.settingsValidationSchema)({
        journey_id: {
            type: 'number',
            required: true,
        },
    }),
    getPorts: (node) => {
        return {
            [constants_1.JOURNEY_ACTION_PORT_INPUT]: (0, utils_1.journeyNodePortAddDefault)(Object.assign(Object.assign({}, base_1.journeyDefaultInputPort[constants_1.JOURNEY_ACTION_PORT_INPUT]), { required: false })),
        };
    },
});
