import { RestRequest } from "@Utils";
import {
  IPreviewVoiceInternalCall,
  ICreateInternalVoiceCallRequest,
  ICreateInternalVoiceCallResponse,
} from "atlas-shared/dist";
import { checkFlag } from "./featureFlagApi";

export * from "./abstract/voice_internal_call.api.abstract";

export const hangupVoiceInternalCall = async (
  id: IPreviewVoiceInternalCall["id"]
): Promise<IPreviewVoiceInternalCall> => {
  return RestRequest.delete<IPreviewVoiceInternalCall>(`voice_internal_call/${id}`);
};

export const hangupRequestVoiceInternalCall = async (
  id: IPreviewVoiceInternalCall["id"]
): Promise<any> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/internal_hangup", {
      body: JSON.stringify({ id }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then((v) => v.json());
  } else {
    return RestRequest.delete<IPreviewVoiceInternalCall>(`voice_internal_call/${id}/terminate`);
  }
};

export const createVoiceInternalCall = async (
  body: ICreateInternalVoiceCallRequest
): Promise<ICreateInternalVoiceCallResponse> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/internal_dial", {
      body: JSON.stringify({ ...body }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then((v) => v.json());
  } else {
    return RestRequest.post<ICreateInternalVoiceCallRequest, ICreateInternalVoiceCallResponse>(
      "voice_internal_call",
      body
    );
  }
};
