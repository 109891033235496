import {
  ISharedConversation,
  ISharedFullConversation,
  IPreviewMailMessage,
  ISharedAgentprofile,
  ISharedConversationFilter,
  IPreviewDraft,
  IPreviewInternalNote,
  ISharedContact,
  IPreviewConversation,
  IPreviewConversationWatcher,
  IPreviewVoiceCall,
  IPreviewVoiceRecording,
  IPreviewVoiceVoicemailMessage,
  ISharedPatchConversation,
  IPreviewVoiceCallBridge,
} from "atlas-shared";
import { RestRequest } from "@Utils/rest";
import { store, ConversationActions } from "@Store";
import { actionSetConversations } from "./abstract/conversation.actions.abstract";

import { fetchConversations } from "@Api";
import { IPaginationProps } from "../../types";

export * from "./abstract/conversation.actions.abstract";

export const actionFetchConversations = (
  conversation_filter_id: ISharedConversationFilter["id"],
  agentprofile_id: ISharedAgentprofile["id"],
  useNewFilterApi: boolean
) => {
  return async (dispatch) => {
    const conversations = await fetchConversations(
      conversation_filter_id,
      agentprofile_id,
      useNewFilterApi
    );

    dispatch(actionSetConversations(conversations));
    return conversations;
  };
};

export const actionFetchConversationsPaginating = (
  conversation_filter_id: ISharedConversationFilter["id"],
  agentprofile_id: ISharedAgentprofile["id"],
  useNewFilterApi: boolean,
  pagination?: IPaginationProps
) => {
  return async (dispatch, getState) => {
    const conversations = await fetchConversations(
      conversation_filter_id,
      agentprofile_id,
      useNewFilterApi,
      pagination
    );

    dispatch(actionAddConversations(conversations));
    return conversations;
  };
};

export const actionAddConversations = (payload: Array<IPreviewConversation>) => ({
  type: ConversationActions.ADDS_STATE,
  payload: payload,
});

export const patchConversation = (
  id: ISharedConversation["id"],
  payload: ISharedPatchConversation
) => {
  return RestRequest.patch<any, ISharedConversation>(`conversation/${id}`, payload);
};

export const conversationsRemoveFullState = (id?: ISharedFullConversation["id"]) => ({
  type: ConversationActions.REMOVE_FULL_STATE,
  payload: id,
  // @ts-ignore
  store: store.getState(),
});

export const currentDashboardConversation = (id: ISharedFullConversation["id"]) => ({
  type: ConversationActions.SET_CURRENT_DASHBOARD_CONVERSATION_ID,
  payload: id,
});

export const conversationsRemoveState = (id: ISharedConversation["id"]) => ({
  type: ConversationActions.REMOVE_STATE,
  payload: {
    id,
  },
});

export const conversationsUpdateFullState = (
  payload: ISharedFullConversation | ISharedConversation
) => ({
  type: ConversationActions.UPDATE_FULL_STATE,
  payload: payload,
});

export const conversationsAddFullState = (payload: ISharedFullConversation) => ({
  type: ConversationActions.ADD_FULL_STATE,
  payload: payload,
});

export const conversationsAddFullStateLoading = (payload: ISharedFullConversation["id"]) => ({
  type: ConversationActions.ADD_FULL_STATE_LOADING,
  payload: payload,
});

export const conversationsRemoveFullStateLoading = (payload: ISharedFullConversation["id"]) => ({
  type: ConversationActions.REMOVE_FULL_STATE_LOADING,
  payload: payload,
});

export const actionAddConversationMessage = (
  payload: IPreviewMailMessage | IPreviewInternalNote | IPreviewVoiceCall
) => ({
  type: ConversationActions.ADD_CONVERSATION_MESSAGE,
  payload: payload,
});

export const actionAddConversationVoiceCallBridge = (payload: IPreviewVoiceCallBridge) => ({
  type: ConversationActions.ADD_CONVERSATION_VOICE_CALL_BRIDGE,
  payload: payload,
});

export const actionUpdateConversationMessage = (
  payload: IPreviewMailMessage | IPreviewInternalNote | IPreviewVoiceCall
) => ({
  type: ConversationActions.UPDATE_CONVERSATION_MESSAGE,
  payload: payload,
});

export const actionUpdateConversationVoiceCallBridge = (payload: IPreviewVoiceCallBridge) => ({
  type: ConversationActions.UPDATE_CONVERSATION_VOICE_CALL_BRIDGE,
  payload: payload,
});

export const actionAddConversationDraft = (payload: IPreviewDraft) => ({
  type: ConversationActions.ADD_CONVERSATION_DRAFT,
  payload: payload,
});

export const actionUpdateConversationDraft = (payload: IPreviewDraft) => ({
  type: ConversationActions.UPDATE_CONVERSATION_DRAFT,
  payload: payload,
});

export const actionRemoveConversationDraft = (payload: IPreviewDraft["id"]) => ({
  type: ConversationActions.REMOVE_CONVERSATION_DRAFT,
  payload: payload,
});

export const actionAddConversationConversationWatcher = (payload: IPreviewConversationWatcher) => ({
  type: ConversationActions.ADD_CONVERSATION_CONVERSATION_WATCHER,
  payload: payload,
});

export const actionRemoveConversationConversationWatcher = (
  payload: IPreviewConversationWatcher
) => ({
  type: ConversationActions.REMOVE_CONVERSATION_CONVERSATION_WATCHER,
  payload: payload,
});

export const actionUpdateConversationContact = (payload: ISharedContact) => ({
  type: ConversationActions.UPDATE_CONVERSATION_CONTACT,
  payload: payload,
});

export const actionSetConversationContact = (payload: {
  conversation_id: IPreviewConversation["id"];
  contact: ISharedContact;
}) => ({
  type: ConversationActions.SET_CONVERSATION_CONTACT,
  payload: payload,
});

export const actionSetConversationJourney = (payload: {
  conversation_id: IPreviewConversation["id"];
  journey: ISharedFullConversation["journey"];
}) => ({
  type: ConversationActions.SET_CONVERSATION_JOURNEY,
  payload,
});

export const actionUpdateConversation = (payload: ISharedConversation) => ({
  type: ConversationActions.UPDATE_STATE,
  payload: payload,
  // @ts-ignore
  store: store.getState(),
});

export const actionAddConversation = (payload: IPreviewConversation) => ({
  type: ConversationActions.ADD_STATE,
  payload: payload,
  // @ts-ignore
  store: store.getState(),
});

export const actionAddConversationVoiceRecording = (payload: IPreviewVoiceRecording) => ({
  type: ConversationActions.ADD_CONVERSATION_VOICE_RECORDING,
  payload: payload,
});

export const actionUpdateConversationVoiceRecording = (payload: IPreviewVoiceRecording) => ({
  type: ConversationActions.UPDATE_CONVERSATION_VOICE_RECORDING,
  payload: payload,
});

export const actionAddConversationVoiceVoicemailMessage = (
  payload: IPreviewVoiceVoicemailMessage
) => ({
  type: ConversationActions.ADD_CONVERSATION_VOICE_VOICEMAIL_MESSAGE,
  payload: payload,
});

export const actionUpdateConversationVoiceVoicemailMessage = (
  payload: IPreviewVoiceVoicemailMessage
) => ({
  type: ConversationActions.UPDATE_CONVERSATION_VOICE_VOICEMAIL_MESSAGE,
  payload: payload,
});
