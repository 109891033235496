"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCreateChannels = exports.canSend = exports.hasVoiceLicense = exports.canEmail = exports.getFullName = exports.getInitials = exports.isAgent = exports.roleIncludesSuperadmin = exports.roleIncludesAdmin = exports.roleIncludesAgent = exports.roleIncludes = exports.isSuperAdmin = exports.isAdmin = exports.hasRole = void 0;
const enums_1 = require("../enums");
const hasRole = (user, role) => user.__organizations.some((o) => (o.role & role) === role);
exports.hasRole = hasRole;
const isAdmin = (user) => (0, exports.hasRole)(user, enums_1.RoleEnum.Admin) || (0, exports.hasRole)(user, enums_1.RoleEnum.Superadmin);
exports.isAdmin = isAdmin;
const isSuperAdmin = (user) => (0, exports.hasRole)(user, enums_1.RoleEnum.Superadmin);
exports.isSuperAdmin = isSuperAdmin;
const roleIncludes = (sum, role) => (sum & role) === role;
exports.roleIncludes = roleIncludes;
const roleIncludesAgent = (sum) => (sum & enums_1.RoleEnum.Agent) === enums_1.RoleEnum.Agent;
exports.roleIncludesAgent = roleIncludesAgent;
const roleIncludesAdmin = (sum) => (sum & enums_1.RoleEnum.Admin) === enums_1.RoleEnum.Admin;
exports.roleIncludesAdmin = roleIncludesAdmin;
const roleIncludesSuperadmin = (sum) => (sum & enums_1.RoleEnum.Superadmin) === enums_1.RoleEnum.Superadmin;
exports.roleIncludesSuperadmin = roleIncludesSuperadmin;
const isAgent = (user) => (0, exports.hasRole)(user, enums_1.RoleEnum.Agent);
exports.isAgent = isAgent;
const getInitials = (user) => user.first_name.substr(0, 1).toUpperCase() + user.last_name.substr(0, 1).toUpperCase();
exports.getInitials = getInitials;
const getFullName = (user) => [user.first_name, user.last_name].filter(Boolean).join(" ");
exports.getFullName = getFullName;
const canEmail = (user) => !!user.mail_capacity && user.mail_capacity > 0;
exports.canEmail = canEmail;
const hasVoiceLicense = (user) => user.voice_license;
exports.hasVoiceLicense = hasVoiceLicense;
const canSend = (user, channel) => {
    switch (channel) {
        case enums_1.ChannelEnum.Mail:
            return user.mail_capacity && user.mail_capacity > 0;
        case enums_1.ChannelEnum.Sms:
            return false;
        default:
            return false;
    }
};
exports.canSend = canSend;
const getCreateChannels = (user) => {
    return [enums_1.ChannelEnum.Mail].filter((channel) => (0, exports.canSend)(user, channel));
};
exports.getCreateChannels = getCreateChannels;
