import { RestRequest } from "@Utils";
import { ITextMessage } from "atlas-shared";

export const sendTextMessage = (
  id: string,
  payload: ITextMessage
): Promise<{ data: ITextMessage }> => {
  return RestRequest.post<ITextMessage, { data: ITextMessage }>(
    `api/v1/text_message_source/${id}/text_message`,
    payload
  );
};

export const getTextMessages = (query: string): Promise<ITextMessage[]> => {
  return RestRequest.get<ITextMessage[]>(`api/v1/text_message?q=${query}`);
};
