"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MailAccountIsGoogleOrMicrosoftService = exports.MailAccountIsMicorosftService = exports.MailAccountIsMicrosoftOAuth2Service = exports.MailAccountIsGoogleService = exports.MailAccountIsGoogleOAuth2Service = exports.MailAccountIsOauth2 = exports.MailAccountStateColor = void 0;
const enums_1 = require("../enums");
const MailAccountStateColor = (state) => {
    switch (state) {
        case enums_1.MailAccountImapStateEnum.Error:
        case enums_1.MailAccountImapStateEnum.Disconnected:
        case enums_1.MailAccountImapStateEnum.Unauthorized:
            return 'var(--red)';
        case enums_1.MailAccountImapStateEnum.Authenticated:
        case enums_1.MailAccountImapStateEnum.Connected:
            return 'var(--green)';
        case enums_1.MailAccountImapStateEnum.Connecting:
        case enums_1.MailAccountImapStateEnum.None:
            return 'var(--orange-dark)';
        default:
            return 'var(--COLOR-000533)';
    }
};
exports.MailAccountStateColor = MailAccountStateColor;
const MailAccountIsOauth2 = (mail_account, limit = ['imap', 'smtp',]) => {
    return [!limit.includes('imap') ? null : mail_account.imap.strategy, !limit.includes('smtp') ? null : mail_account.smtp.strategy,].filter(Boolean)
        .some(strategy => [enums_1.MailAccountAuthStrategy.XOauth2Microsoft, enums_1.MailAccountAuthStrategy.XOauth2Google,].includes(strategy));
};
exports.MailAccountIsOauth2 = MailAccountIsOauth2;
const MailAccountIsGoogleOAuth2Service = (mail_account, limit = ['imap', 'smtp',]) => {
    return [!limit.includes('imap') ? null : mail_account.imap.strategy, !limit.includes('smtp') ? null : mail_account.smtp.strategy,].filter(Boolean)
        .includes(enums_1.MailAccountAuthStrategy.XOauth2Google);
};
exports.MailAccountIsGoogleOAuth2Service = MailAccountIsGoogleOAuth2Service;
const MailAccountIsGoogleService = (mail_account, limit = ['imap', 'smtp',]) => {
    return [!limit.includes('imap') ? null : mail_account.imap.strategy, !limit.includes('smtp') ? null : mail_account.smtp.strategy,].filter(Boolean)
        .some(strategy => [enums_1.MailAccountAuthStrategy.BasicGoogle, enums_1.MailAccountAuthStrategy.XOauth2Google,].includes(strategy));
};
exports.MailAccountIsGoogleService = MailAccountIsGoogleService;
const MailAccountIsMicrosoftOAuth2Service = (mail_account, limit = ['imap', 'smtp',]) => {
    return [!limit.includes('imap') ? null : mail_account.imap.strategy, !limit.includes('smtp') ? null : mail_account.smtp.strategy,].filter(Boolean)
        .includes(enums_1.MailAccountAuthStrategy.XOauth2Microsoft);
};
exports.MailAccountIsMicrosoftOAuth2Service = MailAccountIsMicrosoftOAuth2Service;
const MailAccountIsMicorosftService = (mail_account, limit = ['imap', 'smtp',]) => {
    return [!limit.includes('imap') ? null : mail_account.imap.strategy, !limit.includes('smtp') ? null : mail_account.smtp.strategy,].filter(Boolean)
        .some(strategy => [enums_1.MailAccountAuthStrategy.BasicMicrosoft, enums_1.MailAccountAuthStrategy.XOauth2Microsoft,].includes(strategy));
};
exports.MailAccountIsMicorosftService = MailAccountIsMicorosftService;
const MailAccountIsGoogleOrMicrosoftService = (mail_account, limit = ['imap', 'smtp',]) => {
    return (0, exports.MailAccountIsGoogleService)(mail_account, limit) || (0, exports.MailAccountIsMicorosftService)(mail_account, limit);
};
exports.MailAccountIsGoogleOrMicrosoftService = MailAccountIsGoogleOrMicrosoftService;
