import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Button, Drawer, Form, Select } from "antd";
import { t } from "i18next";
import { useContactSearch } from "../hooks/useContactSearch";
import { useContactCreation } from "../hooks/useContactCreation";
import { IPreviewQueue } from "atlas-shared";
import { PlusIcon, BookmarkIcon } from "@Assets";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useForm } from "antd/lib/form/Form";
import { useAsync } from "@Hooks/abstract/useAsync";
import { createContactByList, fetchForms, fetchLists } from "@Api";
import { FormElementTypes, FormLayoutTypes } from "@Modules/atlas-form-core";
import { AtlasForm } from "@Modules/atlas-form-react";
import { clone } from "lodash";
import { fromJson } from "json-joi-converter";
import { entityToOption } from "@Utils";

interface ContactSearchSelectProps {
  organizationId?: number;
  agentprofileId?: number | null;
  selectedQueue?: IPreviewQueue;
  paramsContact?: { contact_id: string; contact_email: string; contact_number: string };
  required?: boolean;
  errors?: any;
  formId?: number;
  size?: SizeType;
  suffix?: ReactNode;
  style?: React.CSSProperties;
  valueKey?: "contact_id" | "contact_email" | "contact_number";
  name?: string;
  mode?: "multiple" | "tags";
  setFieldTouched?: (field: string) => void;
  onContactSelect?: (contactId: number | null, contactEmail: string, contactNumber: string) => void;
  onContactDeselect?: (
    contactId: number | null,
    contactEmail: string,
    contactNumber: string
  ) => void;
  setValues?: (values: any) => void;
  setFieldValue?: (field: string, value: any) => void;
}

export const ContactSearchSelect: React.FC<ContactSearchSelectProps> = ({
  organizationId,
  paramsContact,
  agentprofileId,
  selectedQueue,
  required = true,
  name = "contact_id",
  valueKey = "contact_id",
  errors,
  setValues,
  setFieldValue,
  size,
  style,
  onContactSelect,
  suffix,
  mode,
}) => {
  const [createContactError, setCreateContactError] = useState("");
  const [extraContacts, setExtraContacts] = useState<any[]>([]);
  const { data: forms, loading: formLoading } = useAsync(fetchForms);
  const { data: lists, loading: listLoading } = useAsync(fetchLists);
  const { searchTerm, options, dropdownVisible, setDropdownVisible, handleSearch, handleChange } =
    useContactSearch({
      organization_id: organizationId,
      agentprofile_id: agentprofileId,
      onContactSelect,
      paramsContact,
      valueKey,
    });

  const { showAddContact, setShowAddContact, newContact, setNewContact, createNewContact } =
    useContactCreation({
      organization_id: organizationId,
      selectedQueue,
    });

  const isLoading = listLoading || formLoading;

  const createList = lists?.find(
    (list) => selectedQueue && list.id === selectedQueue.create_list_id
  );
  const createForm = forms?.find((form) => createList && form.id == createList.form_id);
  const [form] = useForm();

  const handleCreateContact = async (values) => {
    if (!createList) {
      console.error("Missing selected QUEUE");
      setCreateContactError(t("SELECT_QUEUE_WITH_CREATE_LIST"));
      return;
    }

    const contact = await createContactByList(createList?.id, {
      ...values,
      organization_id: organizationId,
      __lists: [createList?.id],
    });

    if (contact) {
      setCreateContactError("");
      setShowAddContact(false);
      setDropdownVisible(false);
      setExtraContacts([contact]);

      const contactValue =
        valueKey === "contact_email"
          ? contact.emails[0]
          : valueKey === "contact_number"
          ? contact.phones[0]
          : contact.id;

      setValues?.((prev) => ({ ...prev, [name]: contactValue }));
      setFieldValue?.(name, contactValue);

      onContactSelect?.(contact.id, contact.emails[0] || "", contact.phones[0] || "");
    }
  };

  const formSchema = useMemo(() => {
    if (!createForm) return null;

    const properties = clone(createForm.__validation_on_save);

    if (!properties.__contact_emails && properties.__contact_phones)
      properties.__contact_phones.min = 1;

    return fromJson({
      type: "object",
      properties,
    });
  }, [createForm]);

  useEffect(() => {
    if (paramsContact) {
      const contactValue =
        valueKey === "contact_email"
          ? paramsContact.contact_email
          : valueKey === "contact_number"
          ? paramsContact.contact_number
          : paramsContact.contact_id;

      setValues?.((prev) => ({ ...prev, [name]: contactValue }));
      setFieldValue?.(name, contactValue);

      if (!extraContacts.some((contact) => contact.id === Number(paramsContact.contact_id))) {
        setExtraContacts([
          {
            id: Number(paramsContact.contact_id),
            emails: [paramsContact.contact_email],
            phones: [""],
          },
        ]);
      }
    }
  }, []);

  return (
    <div>
      <Form.Item
        name={name}
        label={<span className="visually-hidden">{t("CONTACT_ID")}</span>}
        required={required}
        validateStatus={errors?.contact_id ? "error" : undefined}
        help={errors?.contact_id}
        rules={[{ required: true, message: t("MISSING_CONTACT") }]}
      >
        <Select
          style={style}
          suffixIcon={suffix}
          open={dropdownVisible}
          size={size}
          mode={mode}
          onDropdownVisibleChange={setDropdownVisible}
          showSearch
          notFoundContent={
            searchTerm?.length == 0 ? (
              <>{t("BEGIN_TYPING_TO_SEE_CONTACT")}</>
            ) : (
              <Button
                onClick={() => {
                  setShowAddContact(true);
                  const prefillValue =
                    valueKey === "contact_email"
                      ? { emails: [searchTerm] }
                      : valueKey === "contact_number"
                      ? { phones: [searchTerm] }
                      : { emails: [searchTerm] };
                  setNewContact(prefillValue);
                }}
                className="antd-default-styling"
                type="text"
                icon={<PlusIcon size={18}></PlusIcon>}
              >
                {t("CREATE_NEW_CONTACT")} {searchTerm}
              </Button>
            )
          }
          placeholder={
            <span>
              <BookmarkIcon size={18} style={{ marginRight: 8, verticalAlign: "middle" }} />
              {t("CONTACT_ID")}
              {required ? " *" : ""}
            </span>
          }
          dropdownRender={(menu) => {
            return (
              <>
                {menu}
                {options?.length !== 0 && searchTerm?.length > 0 && (
                  <Button
                    style={{ margin: "0.5em" }}
                    className="antd-default-styling"
                    onClick={() => {
                      setShowAddContact(true);
                      const prefillValue =
                        valueKey === "contact_email"
                          ? { emails: [searchTerm] }
                          : valueKey === "contact_number"
                          ? { phones: [searchTerm] }
                          : { emails: [searchTerm] };
                      setNewContact(prefillValue);
                    }}
                    type="text"
                    icon={<PlusIcon size={18}></PlusIcon>}
                  >
                    {t("CREATE_NEW_CONTACT")} - {searchTerm}
                  </Button>
                )}
              </>
            );
          }}
          onSearch={(value) => {
            handleSearch(value);
            setExtraContacts([]);
          }}
          onSelect={(value) => {
            // Value is id
            handleChange(value);
          }}
          filterOption={false}
        >
          {[...extraContacts].map((c) => {
            const option = entityToOption(c as any, "contact");
            const contactName = option.title.split(": ")[1] || "";
            const email = option.entity?.emails[0];
            const phoneNr = option.entity?.phones[0];
            const label = [contactName, email ?? phoneNr]
              .filter((v) => v !== "null null")
              .filter(Boolean)
              .join(", ");

            return (
              <Select.Option
                key={option.entity.id}
                value={
                  valueKey == "contact_email"
                    ? email
                    : valueKey == "contact_number"
                    ? phoneNr
                    : option.key
                }
              >
                {label}
              </Select.Option>
            );
          })}

          {[...options]
            .filter((value, index) => {
              // filter unique by id, all but the first index of a particular key
              const email = value.entity?.emails[0];

              if (valueKey === "contact_email")
                return options.findIndex((opt) => opt.entity?.emails[0] === email) === index;

              return options.findIndex((opt) => opt.key === value.key) === index;
            })
            .map((option) => {
              const contactName = option.title.split(": ")[1] || "";
              const email = option.entity?.emails[0];
              const phoneNr = option.entity?.phones[0]?.split(" ")[0];
              const label = [contactName].filter((v) => v !== "null null").filter(Boolean)[0];

              return (
                <Select.Option
                  key={option.entity.id}
                  value={
                    valueKey == "contact_email"
                      ? email
                      : valueKey == "contact_number"
                      ? phoneNr
                      : option.key
                  }
                >
                  {label && (
                    <>
                      <b>{label}</b>
                      {"<"}
                      {valueKey == "contact_email"
                        ? email
                        : valueKey == "contact_number"
                        ? phoneNr
                        : ""}
                      {">"}
                    </>
                  )}
                  {!label && (
                    <>
                      <b>{email ?? phoneNr}</b>
                    </>
                  )}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>

      <Drawer
        onClose={() => {
          setShowAddContact(false);
          form.resetFields();
        }}
        key={newContact.emails?.join(",")}
        open={showAddContact}
      >
        {!isLoading && createForm && (
          <AtlasForm
            antForm={form}
            formLayout={{
              id: "contact",
              ui_layout: FormLayoutTypes.VERTICAL,
              noPadding: true,
              elements: createForm?.__layout as any,
            }}
            form={formSchema!}
            initialValues={{
              __contact_emails: newContact.emails,
              __contact_phones: newContact.phones,
            }}
            additionalParams={{
              __contact_emails: {
                ui_type: FormElementTypes.LIST,
              },
              organization_id: { hidden: true },
              __lists: { hidden: true },
              __contact_phones: {
                ui_type: FormElementTypes.LIST,
              },
            }}
            onFinish={async (values) => {
              await handleCreateContact(values);
            }}
            onCancel={async () => {
              setShowAddContact(false);
              form.resetFields();
            }}
          />
        )}
        {!isLoading && !createForm && <p>{t("MISSING_CONTACT_FORM")}</p>}
      </Drawer>
    </div>
  );
};
