import { TypeConversationMessage, ChannelEnum, ISharedVoiceCall, TConversationVoiceCall, IPreviewVoiceAsterisk, IPreviewOrganization, ISharedUserStatus, ISharedUserStatusCurrentVoiceAsteriskProperty, } from 'atlas-shared';
import {
  actionSetGlobalCall,
  actionSetGlobalCalls, IAuth,
  IConversationStore,
  IVoiceAsteriskStore,
  TGlobalCallsInterface,
} from '@Store';
import { useVoiceAsterisks, } from '@Hooks';

export const getActiveVoiceCalls = (messages: Array<TypeConversationMessage>) => {
  return messages.filter(message => message.channel === ChannelEnum.Voice && !(message as ISharedVoiceCall).end_time) as Array<TConversationVoiceCall>;
};

export const pickVoiceCall = (dispatch, calls: Array<TGlobalCallsInterface>) => {
  if (calls?.length === 1)
    dispatch(actionSetGlobalCall(calls[0]));
  else
    dispatch(actionSetGlobalCalls(calls));
};

export const VoiceCallTitle = ({ title, voice_asterisk_id, postFix, }: {title: string; voice_asterisk_id: IPreviewVoiceAsterisk['id']; postFix?: string}) => {
  const voice_asterisks = useVoiceAsterisks();

  return <div className='voice-call-option'>
    <div className='number'>{title}</div>
    {postFix && <div className='postfix'>{postFix}</div>}
    <div className='asterisk'>{voice_asterisks.dict[voice_asterisk_id].title}</div>
  </div>;
};

export const getVoiceAsteriskByOrganizationId = (voice_asterisks: IVoiceAsteriskStore, organization_id: IPreviewOrganization['id']): IPreviewVoiceAsterisk => {
  return voice_asterisks.voice_asterisks.find(voice_asterisk => voice_asterisk.__organization_ids.includes(organization_id)) as IPreviewVoiceAsterisk;
};

export const getOutboundAsterisk = (user_status: ISharedUserStatus): ISharedUserStatusCurrentVoiceAsteriskProperty => {
  return user_status.__current_voice_asterisks.find(voice_asterisk_item => !!voice_asterisk_item.apid) as ISharedUserStatusCurrentVoiceAsteriskProperty;
};

export const getActiveCalls = (auth: IAuth, conversations: IConversationStore) => {
  return Object.values(conversations.full_conversations_dict).find(conversation => conversation.active_calls.some(call => call.voice_call_bridges.some(bridge => !bridge.transfer_type && !bridge.leave_at && (bridge.user_id === auth.user.id || bridge.callee_user_id === auth.user.id))));
};
