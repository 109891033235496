"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockSharedVoiceCallOutbound = exports.mockSharedVoiceCallIncoming2 = exports.mockSharedVoiceCallIncoming = exports.mockSharedVoiceCall2 = exports.mockSharedVoiceCall = exports.mockPreviewVoiceCall = void 0;
const enums_1 = require("../../utilities/enums");
const utils_1 = require("../utils");
const voice_asterisk_1 = require("./voice_asterisk");
const voice_route_1 = require("./voice_route");
const base = {
    channel: enums_1.ChannelEnum.Voice,
    id: 10000,
    organization_id: 1,
    direction: enums_1.DirectionEnum.InBound,
    conversation_id: 1,
    voice_asterisk_id: (0, voice_asterisk_1.mockPreviewVoiceAsterisk)().id,
    voice_route_id: (0, voice_route_1.mockPreviewVoiceRoute)().id,
    asterisk_call_id: 'abc',
    contact_unique_id: 'aaa',
    unique_id: 'aaa',
    contact_phone: '+46731234567',
    start_time: utils_1.mockDateTime,
    system_answer_time: utils_1.mockDateTime,
    agi_exit_at: null,
    transferred_unique_ids: null,
    end_time: null,
    variables: {},
    created_at: utils_1.mockDateTime,
    updated_at: utils_1.mockDateTime,
};
exports.mockPreviewVoiceCall = (0, utils_1.mockFunction)(Object.assign({}, base));
exports.mockSharedVoiceCall = (0, utils_1.mockFunction)(Object.assign({}, base));
exports.mockSharedVoiceCall2 = (0, utils_1.mockFunction)(Object.assign(Object.assign({}, base), { id: 10009, contact_phone: 'u-10001-1', direction: enums_1.DirectionEnum.Internal }));
exports.mockSharedVoiceCallIncoming = (0, utils_1.mockFunction)(Object.assign(Object.assign({}, base), { id: 10002, contact_phone: '+46731111111', direction: enums_1.DirectionEnum.InBound }));
exports.mockSharedVoiceCallIncoming2 = (0, utils_1.mockFunction)(Object.assign(Object.assign({}, base), { id: 10004, contact_phone: '+46731111111', direction: enums_1.DirectionEnum.InBound, end_time: utils_1.mockDateTime }));
exports.mockSharedVoiceCallOutbound = (0, utils_1.mockFunction)(Object.assign(Object.assign({}, base), { id: 10005, contact_phone: '+46731111111', direction: enums_1.DirectionEnum.Outbound, end_time: utils_1.mockDateTime }));
