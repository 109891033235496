import { JourneyActionEnum, Metrics, GroupMetrics } from "atlas-shared";

export const sv = {
  ...Object.fromEntries(
    [...Metrics, ...GroupMetrics]
      .flatMap((metric) => [
        metric.title ? [`METRIC_${metric.alias}`, metric.title] : false,
        metric.description ? [`METRIC_${metric.alias}_DESCRIPTION`, metric.description] : false,
      ])
      .filter(Boolean) as Array<[string, string]>
  ),
  COUNTRY_AF: "Afghanistan",
  COUNTRY_AX: "Åland Islands",
  COUNTRY_AL: "Albania",
  COUNTRY_DZ: "Algeria",
  COUNTRY_AS: "American Samoa",
  COUNTRY_AD: "Andorra",
  COUNTRY_AO: "Angola",
  COUNTRY_AI: "Anguilla",
  COUNTRY_AQ: "Antarctica",
  COUNTRY_AG: "Antigua and Barbuda",
  COUNTRY_AR: "Argentina",
  COUNTRY_AM: "Armenia",
  COUNTRY_AW: "Aruba",
  COUNTRY_AU: "Australia",
  COUNTRY_AT: "Austria",
  COUNTRY_AZ: "Azerbaijan",
  COUNTRY_BS: "Bahamas",
  COUNTRY_BH: "Bahrain",
  COUNTRY_BD: "Bangladesh",
  COUNTRY_BB: "Barbados",
  COUNTRY_BY: "Belarus",
  COUNTRY_BE: "Belgium",
  COUNTRY_BZ: "Belize",
  COUNTRY_BJ: "Benin",
  COUNTRY_BM: "Bermuda",
  COUNTRY_BT: "Bhutan",
  COUNTRY_BO: "Bolivia",
  COUNTRY_BA: "Bosnia and Herzegovina",
  COUNTRY_BW: "Botswana",
  COUNTRY_BV: "Bouvet Island",
  COUNTRY_BR: "Brazil",
  COUNTRY_IO: "British Indian Ocean Territory",
  COUNTRY_BN: "Brunei Darussalam",
  COUNTRY_BG: "Bulgaria",
  COUNTRY_BF: "Burkina Faso",
  COUNTRY_BI: "Burundi",
  COUNTRY_KH: "Cambodia",
  COUNTRY_CM: "Cameroon",
  COUNTRY_CA: "Canada",
  COUNTRY_CV: "Cape Verde",
  COUNTRY_KY: "Cayman Islands",
  COUNTRY_CF: "Central African Republic",
  COUNTRY_TD: "Chad",
  COUNTRY_CL: "Chile",
  COUNTRY_CN: "China",
  COUNTRY_CX: "Christmas Island",
  COUNTRY_CC: "Cocos (Keeling) Islands",
  COUNTRY_CO: "Colombia",
  COUNTRY_KM: "Comoros",
  COUNTRY_CG: "Congo",
  COUNTRY_CD: "Congo, The Democratic Republic of the",
  COUNTRY_CK: "Cook Islands",
  COUNTRY_CR: "Costa Rica",
  COUNTRY_CI: "Cote D'Ivoire",
  COUNTRY_HR: "Croatia",
  COUNTRY_CU: "Cuba",
  COUNTRY_CY: "Cyprus",
  COUNTRY_CZ: "Czech Republic",
  COUNTRY_DG: "Diego Garcia",
  COUNTRY_DK: "Denmark",
  COUNTRY_DJ: "Djibouti",
  COUNTRY_DM: "Dominica",
  COUNTRY_DO: "Dominican Republic",
  COUNTRY_EC: "Ecuador",
  COUNTRY_EG: "Egypt",
  COUNTRY_SV: "El Salvador",
  COUNTRY_GQ: "Equatorial Guinea",
  COUNTRY_ER: "Eritrea",
  COUNTRY_EE: "Estonia",
  COUNTRY_ET: "Ethiopia",
  COUNTRY_FK: "Falkland Islands (Malvinas)",
  COUNTRY_FO: "Faroe Islands",
  COUNTRY_FJ: "Fiji",
  COUNTRY_FI: "Finland",
  COUNTRY_FR: "France",
  COUNTRY_GF: "French Guiana",
  COUNTRY_PF: "French Polynesia",
  COUNTRY_TF: "French Southern Territories",
  COUNTRY_GA: "Gabon",
  COUNTRY_GM: "Gambia",
  COUNTRY_GE: "Georgia",
  COUNTRY_DE: "Germany",
  COUNTRY_GH: "Ghana",
  COUNTRY_GI: "Gibraltar",
  COUNTRY_GR: "Greece",
  COUNTRY_GL: "Greenland",
  COUNTRY_GD: "Grenada",
  COUNTRY_GP: "Guadeloupe",
  COUNTRY_GU: "Guam",
  COUNTRY_GT: "Guatemala",
  COUNTRY_GG: "Guernsey",
  COUNTRY_GN: "Guinea",
  COUNTRY_GW: "Guinea-Bissau",
  COUNTRY_GY: "Guyana",
  COUNTRY_HT: "Haiti",
  COUNTRY_HM: "Heard Island and Mcdonald Islands",
  COUNTRY_VA: "Holy See (Vatican City State)",
  COUNTRY_HN: "Honduras",
  COUNTRY_HK: "Hong Kong",
  COUNTRY_HU: "Hungary",
  COUNTRY_IS: "Iceland",
  COUNTRY_IN: "India",
  COUNTRY_ID: "Indonesia",
  COUNTRY_IR: "Iran, Islamic Republic Of",
  COUNTRY_IQ: "Iraq",
  COUNTRY_IE: "Ireland",
  COUNTRY_IM: "Isle of Man",
  COUNTRY_IL: "Israel",
  COUNTRY_IT: "Italy",
  COUNTRY_JM: "Jamaica",
  COUNTRY_JP: "Japan",
  COUNTRY_JE: "Jersey",
  COUNTRY_JO: "Jordan",
  COUNTRY_KZ: "Kazakhstan",
  COUNTRY_KE: "Kenya",
  COUNTRY_KI: "Kiribati",
  COUNTRY_KP: "Korea, Democratic People'S Republic of",
  COUNTRY_KR: "Korea, Republic of",
  COUNTRY_XK: "Kosovo",
  COUNTRY_KW: "Kuwait",
  COUNTRY_KG: "Kyrgyzstan",
  COUNTRY_LA: "Lao People'S Democratic Republic",
  COUNTRY_LV: "Latvia",
  COUNTRY_LB: "Lebanon",
  COUNTRY_LS: "Lesotho",
  COUNTRY_LR: "Liberia",
  COUNTRY_LY: "Libyan Arab Jamahiriya",
  COUNTRY_LI: "Liechtenstein",
  COUNTRY_LT: "Lithuania",
  COUNTRY_LU: "Luxembourg",
  COUNTRY_LX: "Luxembourg (Special)",
  COUNTRY_MO: "Macao",
  COUNTRY_MK: "Macedonia, The Former Yugoslav Republic of",
  COUNTRY_MG: "Madagascar",
  COUNTRY_MW: "Malawi",
  COUNTRY_MY: "Malaysia",
  COUNTRY_MV: "Maldives",
  COUNTRY_ML: "Mali",
  COUNTRY_MT: "Malta",
  COUNTRY_MH: "Marshall Islands",
  COUNTRY_MQ: "Martinique",
  COUNTRY_MR: "Mauritania",
  COUNTRY_MU: "Mauritius",
  COUNTRY_YT: "Mayotte",
  COUNTRY_MX: "Mexico",
  COUNTRY_FM: "Micronesia, Federated States of",
  COUNTRY_MD: "Moldova, Republic of",
  COUNTRY_ME: "Montenegro",
  COUNTRY_MF: "Saint Martin",
  COUNTRY_MC: "Monaco",
  COUNTRY_MN: "Mongolia",
  COUNTRY_MS: "Montserrat",
  COUNTRY_MA: "Morocco",
  COUNTRY_MZ: "Mozambique",
  COUNTRY_MM: "Myanmar",
  COUNTRY_NA: "Namibia",
  COUNTRY_NR: "Nauru",
  COUNTRY_NP: "Nepal",
  COUNTRY_NL: "Netherlands",
  COUNTRY_AN: "Netherlands Antilles",
  COUNTRY_NC: "New Caledonia",
  COUNTRY_NZ: "New Zealand",
  COUNTRY_NI: "Nicaragua",
  COUNTRY_NE: "Niger",
  COUNTRY_NG: "Nigeria",
  COUNTRY_NU: "Niue",
  COUNTRY_NF: "Norfolk Island",
  COUNTRY_MP: "Northern Mariana Islands",
  COUNTRY_NO: "Norway",
  COUNTRY_OM: "Oman",
  COUNTRY_PK: "Pakistan",
  COUNTRY_PW: "Palau",
  COUNTRY_PS: "Palestinian Territory, Occupied",
  COUNTRY_PA: "Panama",
  COUNTRY_PG: "Papua New Guinea",
  COUNTRY_PY: "Paraguay",
  COUNTRY_PE: "Peru",
  COUNTRY_PH: "Philippines",
  COUNTRY_PN: "Pitcairn",
  COUNTRY_PL: "Poland",
  COUNTRY_PT: "Portugal",
  COUNTRY_PR: "Puerto Rico",
  COUNTRY_QA: "Qatar",
  COUNTRY_RE: "Reunion",
  COUNTRY_RO: "Romania",
  COUNTRY_RS: "Serbia",
  COUNTRY_RU: "Russian Federation",
  COUNTRY_RW: "Rwanda",
  COUNTRY_SH: "Saint Helena",
  COUNTRY_KN: "Saint Kitts and Nevis",
  COUNTRY_LC: "Saint Lucia",
  COUNTRY_PM: "Saint Pierre and Miquelon",
  COUNTRY_VC: "Saint Vincent and the Grenadines",
  COUNTRY_WS: "Samoa",
  COUNTRY_SM: "San Marino",
  COUNTRY_ST: "Sao Tome and Principe",
  COUNTRY_SA: "Saudi Arabia",
  COUNTRY_SN: "Senegal",
  COUNTRY_CS: "Serbia and Montenegro",
  COUNTRY_SC: "Seychelles",
  COUNTRY_SL: "Sierra Leone",
  COUNTRY_SG: "Singapore",
  COUNTRY_SK: "Slovakia",
  COUNTRY_SI: "Slovenia",
  COUNTRY_SB: "Solomon Islands",
  COUNTRY_SO: "Somalia",
  COUNTRY_SS: "South Sudan",
  COUNTRY_SX: "Sint Maarten",
  COUNTRY_ZA: "South Africa",
  COUNTRY_GS: "South Georgia and the South Sandwich Islands",
  COUNTRY_ES: "Spain",
  COUNTRY_LK: "Sri Lanka",
  COUNTRY_SD: "Sudan",
  COUNTRY_SR: "Suriname",
  COUNTRY_SJ: "Svalbard and Jan Mayen",
  COUNTRY_SZ: "Swaziland",
  COUNTRY_SE: "Sweden",
  COUNTRY_CH: "Switzerland",
  COUNTRY_SY: "Syrian Arab Republic",
  COUNTRY_TA: "Tristan da Cunha",
  COUNTRY_TW: "Taiwan, Province of China",
  COUNTRY_TJ: "Tajikistan",
  COUNTRY_TZ: "Tanzania, United Republic of",
  COUNTRY_TH: "Thailand",
  COUNTRY_TL: "Timor-Leste",
  COUNTRY_TG: "Togo",
  COUNTRY_TK: "Tokelau",
  COUNTRY_TO: "Tonga",
  COUNTRY_TP: "East Timor",
  COUNTRY_TT: "Trinidad and Tobago",
  COUNTRY_TN: "Tunisia",
  COUNTRY_TR: "Turkey",
  COUNTRY_TM: "Turkmenistan",
  COUNTRY_TC: "Turks and Caicos Islands",
  COUNTRY_TV: "Tuvalu",
  COUNTRY_UG: "Uganda",
  COUNTRY_UA: "Ukraine",
  COUNTRY_AE: "United Arab Emirates",
  COUNTRY_GB: "United Kingdom",
  COUNTRY_US: "United States",
  COUNTRY_UM: "United States Minor Outlying Islands",
  COUNTRY_UY: "Uruguay",
  COUNTRY_UZ: "Uzbekistan",
  COUNTRY_VU: "Vanuatu",
  COUNTRY_VE: "Venezuela",
  COUNTRY_VN: "Viet Nam",
  COUNTRY_VG: "Virgin Islands, British",
  COUNTRY_VI: "Virgin Islands, U.S.",
  COUNTRY_WF: "Wallis and Futuna",
  COUNTRY_EH: "Western Sahara",
  COUNTRY_YE: "Yemen",
  COUNTRY_ZM: "Zambia",
  COUNTRY_ZW: "Zimbabwe",
  COUNTRY_AC: "Ascension Island",
  COUNTRY_BL: "Saint Martin country",
  COUNTRY_BQ: "Netherlands Antilles",
  COUNTRY_CW: "Netherlands Antilles",

  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceInbound}`]: "Inkommande röstsamtal skapades",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceOutbound}`]:
    "Utgående röstsamtal avslutades av agent",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceAnswer}`]: "Svara på röstsamtal",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceExternalDial}`]: "Ring externt nummer",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceGetDigits}`]: "Hämta siffror från uppringare",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceHangup}`]: "Lägg på röstsamtal",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceInternalDial}`]:
    "Internt samtal till agenter eller köer",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceMenu}`]: "Röstmeny med alternativ",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoicePlayback}`]: "Spela upp ljud för kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayAlpha}`]: "Läs text för kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayDigits}`]: "Läs siffror för kontakt (en i taget)",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVariableSet}`]: "Ställ in en röstvariabel",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayNumber}`]: "Läs nummer för kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayPhonetic}`]: "Läs bokstäver för kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStartRecording}`]: "Starta inspelning av samtal",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStopRecording}`]: "Stoppa specifik samtalsinspelning",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailInbound}`]: "Inkommande e-postkonversation skapades",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailOutbound}`]: "Utgående e-postkonversation skapades",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartForm}`]: "Konversation skapades genom formulärinlämning",
  [`NODE_SUBTITLE_${JourneyActionEnum.OperationalHours}`]: "Inom eller utanför öppettider",
  [`NODE_SUBTITLE_${JourneyActionEnum.MailAutoResponse}`]: "Autosvar e-post",
  [`NODE_SUBTITLE_${JourneyActionEnum.Queue}`]: "Tilldela till kö",
  [`NODE_SUBTITLE_${JourneyActionEnum.Client}`]: "Lägg till klient i konversation",
  [`NODE_SUBTITLE_${JourneyActionEnum.UnAssign}`]: "Ta bort tilldelning av kö och/eller användare",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceQueue}`]: "Tilldela till kö med rösthanterare",
  [`NODE_SUBTITLE_${JourneyActionEnum.Agent}`]: "Tilldela direkt till agent",
  [`NODE_SUBTITLE_${JourneyActionEnum.Timeout}`]: JourneyActionEnum.Timeout + " standardundertext",
  [`NODE_SUBTITLE_${JourneyActionEnum.RestRequest}`]: "Gör en REST-förfrågan",
  [`NODE_SUBTITLE_${JourneyActionEnum.FetchContactData}`]: "Hämta kontaktdata via REST",
  [`NODE_SUBTITLE_${JourneyActionEnum.EditConversation}`]: "Redigera konversationsegenskaper",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseConversation}`]: "Avsluta och stäng konversation",
  [`NODE_SUBTITLE_${JourneyActionEnum.AddInternalNote}`]:
    "Lägg till intern anteckning i konversation",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseTabs}`]: "Stäng flikar automatiskt från resa",
  [`NODE_SUBTITLE_${JourneyActionEnum.SendMail}`]: "Skicka e-post",
  [`NODE_SUBTITLE_${JourneyActionEnum.ForwardMail}`]: "Vidarebefordra e-post",
  [`NODE_SUBTITLE_${JourneyActionEnum.ReplyLatestInboundMessage}`]:
    "Svara på senaste inkommande e-post eller formulärinlämning",
  [`NODE_SUBTITLE_${JourneyActionEnum.Journey}`]: "Underresa",
  [`NODE_SUBTITLE_${JourneyActionEnum.Rule}`]: "Definiera villkorsregler",
  [`NODE_SUBTITLE_${JourneyActionEnum.RuleContact}`]: "Definiera kontaktvillkorsregler",
  [`NODE_SUBTITLE_${JourneyActionEnum.NoOp}`]: "Ingen åtgärd",
  [`NODE_SUBTITLE_${JourneyActionEnum.When}`]: "Utlöses vid",
  [`NODE_SUBTITLE_${JourneyActionEnum.SetField}`]: "Ställ in konversationsfältvärde",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSetLanguage}`]: "Ställ in röstsamtalets språk",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVoicemail}`]: "Spela in röstmeddelande",
  [`NODE_SUBTITLE_${JourneyActionEnum.OpenBrowserTab}`]: "Öppna webbläsarflik",

  NODE_TITLE_DEFAULT: "Standardtitel för nod",
  NODE_DESCRIPTION_DEFAULT: "Standardbeskrivning för nod",
  LOADING: "Laddar...",
  CUSTOMER_JOURNEY: "Kundresa",
  CONVERSATION: "Konversation",
  CONVERSATIONS: "Konversationer",
  CUSTOMER: "Kund",
  CUSTOMERS: "Kunder",
  STATISTICS: "Statistik",
  REPLY_IN: "Svara i",
  REPLY: "Svara",
  AGO: "sedan",
  SHOWING: "Visar",
  STATS: "Statistik",
  CLOSE: "Stäng",
  CLOSED: "Stängd",
  OPEN: "Öppna",
  DRAFT_REPLY_MESSAGE_CONFIRM: "Vill du spara dina ändringar?",
  TODAY: "Idag",
  YES: "Ja",
  NO: "Nej",
  CONFIRM: "Bekräfta",
  NEW_MESSAGE: "Nytt meddelande",
  TO: "Till",
  DRAFT: "Utkast",
  SEND_REPLY: "Skicka svar",
  SAVE: "Spara",
  TYPE: "Typ",
  ALL: "Alla",
  TICKETS: "Ärenden",
  CONTACTS: "Kontakter",
  SOLUTIONS: "Lösningar",
  FORUMS: "Forum",
  TEAM_NAME: "Teamnamn",
  ANOTHER_TEAM: "Annat team",
  THIRD_TEAM_NAME: "Tredje teamnamn",
  OTHER_TEAM_NAME: "Annat teamnamn",
  FAVORITES: "Favoriter",
  CHAT: "Chatt",
  CALL: "Samtal",
  RETURN: "Återgå",
  SEND: "Skicka",
  RECENTLY_SEARCHED: "Nyligen sökt",
  RECENTLY_VIEWED: "Nyligen visade",
  CLEAR: "Rensa",
  ASSIGN: "Tilldela",
  CHOOSE: "välj",
  AGENT: "Agent",
  ADMIN: "Admin",
  SUPERADMIN: "Superadmin",
  QUEUES: "Köer",
  QUEUE: "Kö",
  DONE: "Klar",
  IN: "i",
  SEARCH: "Sök",
  AND: "Och",
  PREVIOUS_ACTIVITY: "Tidigare aktivitet",
  VIEW: "Visa",
  BY: "av",
  SUPPORT: "Support",
  GENERAL: "Allmänt",
  WRITE: "Skriv",
  HERE: "här",
  ADD: "Lägg till",
  NEW: "Ny",
  AGENTS: "Agenter",
  EDIT: "Redigera",
  TOOLS: "Verktyg",
  EMAIL: "E-post",
  CANCEL: "Avbryt",
  UNAUTHORIZED: "Användarnamn eller lösenord stämmer inte",
  OR: "Eller",
  DISPOSE: "Avsluta",
  CHOOSE_REASON_FOR_DISPOSAL: "Välj anledning för avslut",
  WRITE_A_COMMENT: "Skriv en kommentar",
  USERNAME: "Användarnamn",
  DROP_HERE: "Släpp här",
  PASSWORD: "Lösenord",
  FORGOT_YOUR_PASSWORD: "Glömt ditt lösenord",
  RESET_PASSWORD: "Återställ lösenord",
  REMEMBER_ME: "Kom ihåg mig",
  SIGN_IN: "Logga in",
  SIGN_OUT: "Logga ut",
  DISPLAY_DELETED: "Visa borttagna",
  GLOBAL: "Global",
  WORK_TIME: "Arbetstid",
  INVOICEABLE: "Fakturerbar",
  TITLE: "Titel",
  ORGANIZATION_NOT_FOUND: 'Organisation med id "{{organization_id}}" hittades inte!',
  BETWEEN_FROM_AND_TO: "Mellan {{from}} och {{to}}",
  FROM_FROM_TO_TO: "Från {{from}} till {{to}}",
  FROM_THROUGH_TO: "{{from}} till {{to}}",
  FROM_THROUGH_TO_OF_MONTH: "{{from}} till {{to}} i månaden",
  ON_FROM: "Den {{from}}",
  ON_FROM_OF_MONTH: "Den {{from}} i månaden",
  EVERY_SPECIFIC_DAY: "Varje {{from}}",
  ALL_DAY: "Hela dagen",
  EVERY_DAY: "Varje dag",

  ROLE: "Roll",
  FIRST_NAME: "Förnamn",
  LAST_NAME: "Efternamn",
  AVATAR: "Profilbild",

  ORGANIZATIONS: "Organisationer",
  ORGANIZATION: "Organisation",
  USER: "Användare",
  USERS: "Användare",
  ADMINPROFILES: "Adminprofiler",
  ADMINPROFILE: "Adminprofil",
  SUPERADMIN_PROFILES: "Superadminprofiler",
  SUPERADMINPROFILES: "Superadminprofiler",
  SUPERADMINPROFILE: "Superadminprofil",
  SUPERADMIN_PROFILE: "Superadminprofil",
  AGENTPROFILES: "Agentprofiler",
  AGENTPROFILE: "Agentprofil",
  TEAMS: "Team",
  MAIL_ACCOUNTS: "E-postkonton",
  MAIL_ACCOUNT: "E-postkonto",
  LISTS: "Listor",
  LIST: "Lista",
  JOURNEYS: "Resor",
  JOURNEY: "Resa",
  PAUSES: "Pauser",
  PAUSE: "Paus",
  DISPOSITIONS: "Dispositioner",
  DISPOSITION: "Disposition",
  FIELDS: "Fält",
  FIELD: "Fält",
  FORM_ERROR_STRING__EMPTY: "Får inte vara tom",
  FORM_ERROR_ARRAY__MIN: "Minst {{min}} alternativ krävs",
  FORM_ERROR_ARRAY__MAX: "Maximalt {{max}} alternativ tillåtna",
  FORM_ERROR_ARRAY__LENGTH: "{{lngth}} alternativ krävs",
  FORM_ERROR_ANY__REQUIRED: "Obligatoriskt",
  FORM_ERROR_NUMBER__BASE: "Giltigt nummer krävs",
  FORM_ERROR_STRING__BASE: "Giltig text krävs",
  FORM_ERROR_VALID_OPTION: "Välj ett alternativ",
  FORM_ERROR_ANY__ONLY: "Välj ett giltigt alternativ",
  FORM_ERROR_STRING__URI: "Ogiltig uri",
  FORM_ERROR_REF_NOT_FOUND: "Ett giltigt alternativ måste väljas",
  FORM_ERROR_INVALID_PHONE: "Ogiltigt telefonnummer",
  FORM_ERROR_NUMBER__MIN: "Numret måste vara större än eller lika med {{min}}",
  FORM_ERROR_NUMBER__MAX: "Numret måste vara mindre än eller lika med {{max}}",
  FORM_ERROR_STRING__MIN: "Måste vara minst {{min}} tecken långt",
  FORM_ERROR_STRING__MAX: "Får vara högst {{max}} tecken långt",
  FORM_ERROR_STRING__LENGTH: "Måste vara exakt {{lngth}} tecken långt",
  FORM_ERROR_STRING__EMAIL: "Ogiltig e-postadress",
  FORM_ERROR_STRING__ALPHANUM: "Endast alfanumeriska värden!",
  FORM_ERROR_USERNAME_INVALID_CHARS:
    "Får endast innehålla gemener, siffror och specialtecknen . (punkt) och - (bindestreck)",
  FORM_ERROR_STRING__PATTERN__BASE: "Strängen matchar inte mönstret {{pattern}}",
  FORM_ERROR_DATE__BASE: "Välj ett giltigt datum",
  FORM_ERROR_STRING__IPVERSION: "Ogiltig IP-adress",

  GLOBAL_SEARCH_PLACEHOLDER: "Sök konversationer & kontakter",
  ADD_ITEM: "Lägg till objekt",
  MAIL_CAPACITY: "E-postkapacitet",
  __ORGANIZATIONS: "Organisationer",
  __ORGANIZATIONS__ORGANIZATION_ID: "Organisation",
  __ORGANIZATIONS__ROLE: "Roll",
  __ORGANIZATIONS__ADMINPROFILE_ID: "Adminprofil",
  __ORGANIZATIONS__SUPERADMIN_PROFILE_ID: "Superadminprofil",
  SUBMIT: "Skicka",
  SIGN_IN_WITH_GOOGLE: "Logga in med Google",
  SIGN_IN_WITH_MICROSOFT: "Logga in med Microsoft",
  OPEN_AUTHENTICATOR_AND_SCAN: "Öppna din autentiseringsapp och skanna QR-koden",
  OPEN_AUTHENTICATOR: "Öppna din autentiseringsapp och ange koden",
  ENTER_6_DIGIT_CODE: "Ange 6-siffrig kod",
  FILL_USERNAME_TO_RESET_PASSWORD: "Fyll i användarnamn för att återställa lösenord",
  SOMETHING_WENT_WRING: "Något gick fel, försök igen senare!",

  ENTRY: "Inmatning",
  ASSIGNMENT: "Tilldelning",
  MAIL: "E-post",
  StartMailInbound: "Starta e-post (Inkommande)",
  StartMailOutbound: "Starta e-post (Utgående)",
  StartVoiceInbound: "Starta röstsamtal (Inkommande)",
  StartVoiceOutbound: "Starta röstsamtal (Utgående)",
  VoiceAnswer: "Svara",
  VoiceExternalDial: "Externt samtal",
  VoiceGetDigits: "Hämta siffror",
  VoiceQueue: "Röstkö",
  VoiceVariableSet: "Ställ in variabel",
  VoiceHangup: "Lägg på",
  VoiceInternalDial: "Internt samtal",
  VoiceMenu: "Röstmeny",
  VoicePlayback: "Uppspelning",
  VoiceSayAlpha: "Säg alfabetiskt",
  VoiceSayNumber: "Säg nummer",
  VoiceSayPhonetic: "Säg fonetiskt",
  VoiceSayDigits: "Säg siffror",
  VoiceStartRecording: "Starta inspelning",
  VoiceStopRecording: "Stoppa inspelning",
  VoiceVoicemail: "Röstmeddelande",
  StartForm: "Starta formulär",
  Queue: "Kö",
  EditConversation: "Redigera konversation",
  CloseConversation: "Avsluta konversation",
  AddInternalNote: "Lägg till intern anteckning",
  CloseTabs: "Stäng flikar",
  SendMail: "Skicka e-post",
  ForwardMail: "Vidarebefordra e-post",
  ReplyLatestInboundMessage: "Svara på senaste inkommande meddelande",
  MailAutoResponse: "Automatiskt e-postsvar",
  RestRequest: "REST-förfrågan",
  FetchContactData: "Hämta kontaktdata",
  SetField: "Ställ in fältdata",
  VoiceSetLanguage: "Ställ in språk",
  Rule: "Regel",
  RuleContact: "Regel (kontakt)",
  NoOp: "Ingen åtgärd",
  OperationalHours: "Öppettider",
  Journey: "Journey",
  MY_FILTERS: "Mina filter",
  CREATE_NEW_FILTER: "Skapa nytt filter",
  NEW_FILTER: "Nytt filter",
  DEFAULT_FILTER: "Standardfilter",
  STATUSES: "Status",
  CHANNELS: "Kanaler",
  PRIORITIES: "Prioriteringar",
  TAGS: "Etiketter",
  LAST_MESSAGE_AT: "Senaste meddelande",
  LAST_INBOUND_MESSAGE_AT: "Senaste inkommande meddelande",
  LAST_OUTBOUND_MESSAGE_AT: "Senaste utgående meddelande",
  CREATED_AT: "Skapad",
  CONTACT: "Kontakt",
  __CONTACT_FIRST_NAME: "Förnamn",
  __CONTACT_LAST_NAME: "Efternamn",
  __CONTACT_PHONES: "Telefonnummer",
  __CONTACT_EMAILS: "E-postadresser",
  SETTINGS: "Inställningar",
  PROFILES: "Profiler",
  NOTIFICATIONS: "Aviseringar",
  NewAssignment: "Ny tilldelning",
  NewResponseOnMyConversation: "Nytt svar på mina konversationer",
  ConversationMessagesImTaggedIn: "Konversationsmeddelanden jag är taggad i",
  StatusUpdateOnMyConversation: "Statusuppdatering på min konversation",
  ConversationAssignedToMe: "Konversation tilldelad till mig",
  ConversationAssignedToMyQueues: "Konversation tilldelad till mina köer",
  ReminderOnFirstResponse: "Påminnelse om första svar",
  ReminderOnResolutionTime: "Påminnelse om lösningstid",
  ReminderOnNextResponse: "Påminnelse om nästa svar",
  DELETE_DRAFT: "Radera utkast",
  PERMANENTLY_DELETE_DRAFT: "Radera utkast permanent?",
  ADD_PORT: "Lägg till port",
  ADD_RULE: "Lägg till regel",
  ADD_PROPERTY: "Lägg till egenskap",
  NOT_EQUAL_PHONE_NUMBER: "Inte lika med telefonnummer",
  EQUAL_PHONE_NUMBER: "Lika med telefonnummer",
  INCLUDES_PHONE_NUMBER: "Innehåller telefonnummer",
  NOT_INCLUDES_PHONE_NUMBER: "Innehåller inte telefonnummer",
  ARRAY_LENGTH: "Arraylängd",
  STRICT_EQ: "Lika med",
  NOT_STRICT_EQ: "Inte lika med",
  IS_EMPTY: "Är tom",
  IS_NOT_EMPTY: "Är inte tom",
  CONTAINS: "Innehåller",
  NOT_CONTAINS: "Innehåller inte",
  CHANGED: "Ändrad",
  NOT_EQUAL_NUM: "Inte lika med (nummer)",
  NOT_EQUAL_STR: "Inte lika med (text)",
  EQUAL_NUM: "Lika med (nummer)",
  EQUAL_STR: "Lika med (text)",
  STARTS_WITH: "Börjar med",
  NOT_STARTS_WITH: "Börjar inte med",
  ANY_OF_NUM: "Någon av",
  NONE_OF_NUM: "Ingen av",
  ANY_OF_STR: "Någon av",
  NONE_OF_STR: "Ingen av",
  ENDS_WITH: "Slutar med",
  NOT_ENDS_WITH: "Slutar inte med",
  GT: "Större än",
  GTE: "Större än eller lika med",
  LT: "Mindre än",
  LTE: "Mindre än eller lika med",
  IS_NOT_NULL: "Är inte null",
  IS_NULL: "Är null",
  IS_FALSY: "Är falsk",
  IS_FALSE: "Är false",
  IS_TRULY: "Är sann",
  IS_TRUE: "Är true",
  IS_UNDEFINED: "Är odefinierad",
  IS_DEFINED: "Är definierad",
  INCLUDES: "Inkluderar",
  INCLUDES_NUM: "Inkluderar nummer",
  INCLUDES_STR: "Inkluderar text",
  NOT_INCLUDES: "Inkluderar inte",
  NOT_INCLUDES_NUM: "Inkluderar inte nummer",
  NOT_INCLUDES_STR: "Inkluderar inte text",
  GT_DATETIME: "Större än",
  LT_DATETIME: "Mindre än",
  MAIL_MESSAGE: "E-postmeddelande",
  "DRAFT.ID": "Utkast-id",
  "INTERNAL_NOTE.ID": "Intern anteckning-id",
  "CONVERSATION.ID": "Konversations-id",
  "CONVERSATION.ORGANIZATION_ID": "Organisation",
  "CONVERSATION.TITLE": "Konversationstitel",
  "CONVERSATION.QUEUE_ID": "Konversationskö",
  "CONVERSATION_QUEUE.TITLE": "Konversationsköns titel",
  "CONVERSATION.LAST_INBOUND_MESSAGE_AT": "Senaste inkommande meddelande",
  "CONVERSATION.LAST_OUTBOUND_MESSAGE_AT": "Senaste utgående meddelande",
  "CONVERSATION.LAST_MESSAGE_AT": "Senaste meddelande",
  "CONVERSATION.LAST_MESSAGE_BY": "Senaste meddelande av",
  "CONVERSATION.CLIENT_ID": "Konversationsklient",
  "CONVERSATION_CLIENT.TITLE": "Konversationsklientens titel",
  "CONVERSATION.CONTACT_ID": "Konversationskontakt-id",
  "CONVERSATION.JOURNEY_ID": "Resa",
  "CONVERSATION.DISPOSITION_ID": "Konversationsklassificering",
  "CONVERSATION_DISPOSITION.TITLE": "Konversationsklassificeringens titel",
  "CONVERSATION.SUB_DISPOSITION_ID": "Konversationsunderklassificering",
  "CONVERSATION_SUB_DISPOSITION.TITLE": "Konversationsunderklassificeringens titel",
  "CONVERSATION.THRD_DISPOSITION_ID": "Konversationstredjeklassificering",
  "CONVERSATION_THRD_DISPOSITION.TITLE": "Konversationstredjeklassificeringens titel",
  "CONVERSATION.STATUS": "Konversationsstatus",
  "CONVERSATION.CREATED_AT": "Konversation skapad",
  "CONVERSATION.PRIORITY": "Konversationsprioritet",
  "CONVERSATION.USER_ID": "Konversationsagent-id",
  "USER.ID": "Agent",
  "CONVERSATION.STATUS_AT": "Konversationsstatus ändrad",
  "CONVERSATION.RESPONSE_TIME": "Konversationssvarstid",
  "CONVERSATION.RESOLUTION_TIME": "Konversationslösningstid",
  "CONVERSATION.MAIN_CHANNEL": "Konversationens huvudkanal",
  "CONVERSATION.DIRECTION": "Konversationens startriktning",
  "CONVERSATION.TAGS": "Konversationsetiketter",
  "CONVERSATION.CHANNELS": "Konversationskanaler",
  "CONVERSATION.DATA": "Konversationens anpassade fält",
  "MAIL_MESSAGE.ID": "E-postmeddelande-id",
  "MAIL_MESSAGE.TITLE": "E-postmeddelandetitel",
  "MAIL_MESSAGE.SUBJECT": "E-postämne",
  "MAIL_MESSAGE.MAIL_ACCOUNT_ID": "E-postkonto",
  "MAIL_MESSAGE.CONTACT_EMAIL": "Kontaktens e-postadress",
  "MAIL_MESSAGE.CREATED_AT": "E-postmeddelande skapat",
  "CONTACT.ID": "Kontakt-id",
  "CONTACT.EMAILS": "Kontaktens e-postadresser",
  "CONTACT.PHONES": "Kontaktens telefonnummer",
  "CONTACT.FIRST_NAME": "Kontaktens förnamn",
  "CONTACT.LAST_NAME": "Kontaktens efternamn",
  "CONTACT.DATA": "Kontaktens anpassade fält",
  FORM_SUBMISSION: "Formulärinlämning",
  "FORM_SUBMISSION.ID": "Formulärinlämnings-id",
  "FORM_SUBMISSION.FORM_WEBSITE_ID": "Formulärwebbplats",
  "FORM_SUBMISSION.CONTACT_EMAIL": "Formulärkontaktens e-post",
  "FORM_SUBMISSION.CONTACT_PHONE": "Formulärkontaktens telefon",
  FORM_WEBSITE: "Formulärwebbplats",
  "FORM_WEBSITE.ID": "Formulärwebbplats-id",
  "MAIL_ACCOUNT.ID": "E-postkonto-id",
  "DRAFT.CREATED_AT": "Utkast skapat",
  "INTERNAL_NOTE.CREATED_AT": "Intern anteckning skapad",
  "VOICE_CALL.ID": "Röstsamtals-id",
  "VOICE_CALL.VOICE_ASTERISK_ID": "Röst-asterisk",
  "VOICE_CALL.VOICE_ROUTE_ID": "Röstrutt",
  "VOICE_CALL.QUEUE_ID": "Röstsamtalskö",
  VOICE_CALL_BRIDGES_COUNT: "Antal röstsamtalsbryggor",
  "VOICE_CALL.CONTACT_PHONE": "Röstsamtalskontaktens telefon",
  "VOICE_CALL.CREATED_AT": "Röstsamtal skapat",
  "VOICE_CALL.DIRECTION": "Röstsamtalsriktning",
  "VOICE_CALL.END_TIME": "Röstsamtal avslutat",
  VOICE_CALL_FIRST_ANSWERED_AT: "Röstsamtal först besvarat",
  "VOICE_CALL.VARIABLES": "Röstvariabler",

  "VOICE_CALL_DURATION.TOTAL": "Total samtalstid (sekunder)",
  "VOICE_CALL_DURATION.PRE_QUEUE_TIME": "Tid före kö (sekunder)",
  "VOICE_CALL_DURATION.WAIT_TIME": "Väntetid (sekunder)",
  "VOICE_CALL_DURATION.TALK_TIME": "Samtalstid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Nuvarande samtalsbryggas totala tid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Nuvarande samtalsbryggas tid före kö (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Nuvarande samtalsbryggas väntetid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Nuvarande samtalsbryggas samtalstid (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Första samtalsbryggas totala tid (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME": "Första samtalsbryggas tid före kö (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Första samtalsbryggas väntetid (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Första samtalsbryggas samtalstid (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Sista samtalsbryggas totala tid (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME": "Sista samtalsbryggas tid före kö (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Sista samtalsbryggas väntetid (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Sista samtalsbryggas samtalstid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE.ID": "Nuvarande samtalsbryggas ID",
  "CURRENT_VOICE_CALL_BRIDGE.DIRECTION": "Nuvarande samtalsbryggas riktning",
  "CURRENT_VOICE_CALL_BRIDGE.CREATED_AT": "Nuvarande samtalsbrygga skapad",
  "CURRENT_VOICE_CALL_BRIDGE.ANSWER_TIME": "Nuvarande samtalsbrygga besvarad",
  "CURRENT_VOICE_CALL_BRIDGE.LEAVE_AT": "Nuvarande samtalsbrygga lämnad",
  "CURRENT_VOICE_CALL_BRIDGE.USER_ID": "Nuvarande samtalsbryggas agent",
  "CURRENT_VOICE_CALL_BRIDGE.QUEUE_ID": "Nuvarande samtalsbryggas kö",
  "CURRENT_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Nuvarande samtalsbrygga är extern uppringning",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TWINNED": "Nuvarande samtalsbrygga är twinnad",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFER": "Nuvarande samtalsbrygga är överföring",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Nuvarande samtalsbrygga är överförd",
  "CURRENT_VOICE_CALL_BRIDGE.EXIT_REASON": "Nuvarande samtalsbryggas avslutningsorsak",
  "FIRST_VOICE_CALL_BRIDGE.ID": "Första samtalsbryggas ID",
  "FIRST_VOICE_CALL_BRIDGE.DIRECTION": "Första samtalsbryggas riktning",
  "FIRST_VOICE_CALL_BRIDGE.CREATED_AT": "Första samtalsbrygga skapad",
  "FIRST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Första samtalsbrygga besvarad",
  "FIRST_VOICE_CALL_BRIDGE.LEAVE_AT": "Första samtalsbrygga lämnad",
  "FIRST_VOICE_CALL_BRIDGE.USER_ID": "Första samtalsbryggas agent",
  "FIRST_VOICE_CALL_BRIDGE.QUEUE_ID": "Första samtalsbryggas kö",
  "FIRST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Första samtalsbrygga är extern uppringning",
  "FIRST_VOICE_CALL_BRIDGE.IS_TWINNED": "Första samtalsbrygga är twinnad",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Första samtalsbrygga är överföring",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Första samtalsbrygga är överförd",
  "FIRST_VOICE_CALL_BRIDGE.EXIT_REASON": "Första samtalsbryggas avslutningsorsak",
  "LAST_VOICE_CALL_BRIDGE.ID": "Sista samtalsbryggas ID",
  "LAST_VOICE_CALL_BRIDGE.DIRECTION": "Sista samtalsbryggas riktning",
  "LAST_VOICE_CALL_BRIDGE.CREATED_AT": "Sista samtalsbrygga skapad",
  "LAST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Sista samtalsbrygga besvarad",
  "LAST_VOICE_CALL_BRIDGE.LEAVE_AT": "Sista samtalsbrygga lämnad",
  "LAST_VOICE_CALL_BRIDGE.USER_ID": "Sista samtalsbryggas agent",
  "LAST_VOICE_CALL_BRIDGE.QUEUE_ID": "Sista samtalsbryggas kö",
  "LAST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Sista samtalsbrygga är extern uppringning",
  "LAST_VOICE_CALL_BRIDGE.IS_TWINNED": "Sista samtalsbrygga är twinnad",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Sista samtalsbrygga är överföring",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Sista samtalsbrygga är överförd",
  "LAST_VOICE_CALL_BRIDGE.EXIT_REASON": "Sista samtalsbryggas avslutningsorsak",
  "USER.USERNAME": "Agentens användarnamn",
  "USER.EMAIL": "Agentens e-post",
  "USER.FIRST_NAME": "Agentens förnamn",
  "USER.LAST_NAME": "Agentens efternamn",
  "USER.FULL_NAME": "Agentens fullständiga namn",
  "_QUEUE.AGENTS_READY": "Redo agenter i kö",
  "_QUEUE.AGENTS_PAUSED": "Pausade agenter i kö",
  "_QUEUE.AGENTS_BUSY": "Upptagna agenter i kö",
  "_QUEUE.AGENTS_TOTAL": "Totalt antal agenter i kö",
  "_QUEUE.LONGEST_WAITING": "Längsta väntetid i kö",
  "_QUEUE.WAITING": "Väntande i kö",
  PRIORITY: "Prioritet",
  DISPOSITION_ID: "Klassificering",
  SUB_DISPOSITION_ID: "Underklassificering",
  THRD_DISPOSITION_ID: "Tredjeklassificering",
  DISPOSITION_NOTE: "Klassificeringsanteckning",
  STATUS: "Status",
  ADD_TAG: "Lägg till etikett",
  REMOVE_TAG: "Ta bort etikett",
  STATUS_O: "Öppen",
  STATUS_RO: "Återöppnad",
  STATUS_C: "Stängd",
  STATUS_R: "Löst",
  STATUS_P: "Väntande",
  STATUS_W: "Väntar på tredje part",

  PRIORITY_URGENT: "Brådskande",
  PRIORITY_HIGH: "Hög",
  PRIORITY_MEDIUM: "Medel",
  PRIORITY_LOW: "Låg",
  PRIORITY_U: "Brådskande",
  PRIORITY_H: "Hög",
  PRIORITY_M: "Medel",
  PRIORITY_L: "Låg",

  DIRECTION_INBOUND: "Inkommande",
  DIRECTION_OUTBOUND: "Utgående",
  DIRECTION_INTERNAL: "Intern",
  DIRECTION_I: "Inkommande",
  DIRECTION_O: "Utgående",
  "DIRECTION_-": "Intern",

  EXIT_REASON_REJECT: "Avvisad",
  EXIT_REASON_CONTACTHANGEDUP: "Kontakt lade på",
  EXIT_REASON_TIMEOUT: "Timeout",
  EXIT_REASON_FULL: "Kö full",
  EXIT_REASON_JOINEMPTY: "Kö anslut tom",
  EXIT_REASON_LEAVEEMPTY: "Kö lämna tom",
  EXIT_REASON_JOINUNAVAIL: "Kö anslut otillgänglig",
  EXIT_REASON_LEAVEUNAVAIL: "Kö lämna otillgänglig",

  DEFINED: "Definierad",
  CUSTOM: "Anpassad",

  NEWDRAFT: "Nytt utkast skapat",
  NEWINTERNALNOTE: "Ny intern anteckning skapad",
  CONVERSATIONUPDATED: "Konversation uppdaterad",
  CONVERSATIONCREATED: "Konversation skapad",
  CONTACTUPDATED: "Kontakt uppdaterad",
  CONTACTCREATED: "Kontakt skapad",
  NEWINBOUNDMAILMESSAGE: "Nytt inkommande e-postmeddelande",
  NEWOUTBOUNDMAILMESSAGE: "Nytt utgående e-postmeddelande",
  SINCECONVERSATION: "Sedan konversationsuppdatering",
  NEWINBOUNDVOICECALL: "Nytt inkommande röstsamtal",
  NEWOUTBOUNDVOICECALL: "Nytt utgående röstsamtal",
  NEWINTERNALVOICECALL: "Nytt internt röstsamtal",
  INBOUNDVOICECALLHANGEDUP: "Inkommande röstsamtal avslutat",
  OUTBOUNDVOICECALLHANGEDUP: "Utgående röstsamtal avslutat",
  INTERNALVOICECALLHANGEDUP: "Internt röstsamtal avslutat",
  INBOUNDVOICECALLANSWERED: "Inkommande röstsamtal besvarat",
  OUTBOUNDVOICECALLANSWERED: "Utgående röstsamtal besvarat",
  INTERNALVOICECALLANSWERED: "Internt röstsamtal besvarat",
  NEWINBOUNDVOICECALLBRIDGE: "Ny inkommande samtalsbrygga",
  NEWOUTBOUNDVOICECALLBRIDGE: "Ny utgående samtalsbrygga",
  NEWINTERNALVOICECALLBRIDGE: "Ny intern samtalsbrygga",
  INBOUNDVOICECALLBRIDGELEAVE: "Inkommande samtalsbrygga lämnad",
  OUTBOUNDVOICECALLBRIDGELEAVE: "Utgående samtalsbrygga lämnad",
  INTERNALVOICECALLBRIDGELEAVE: "Intern samtalsbrygga lämnad",
  INBOUNDVOICECALLBRIDGEANSWERED: "Inkommande samtalsbrygga besvarad",
  OUTBOUNDVOICECALLBRIDGEANSWERED: "Utgående samtalsbrygga besvarad",
  INTERNALVOICECALLBRIDGEANSWERED: "Intern samtalsbrygga besvarad",

  CANNED_ANSWER_ID: "Standardsvar",
  CANNED_ANSWER_ID2: "Standardsvar 2",
  CANNED_ANSWER_ID3: "Standardsvar 3",
  CANNED_ANSWER_ID4: "Standardsvar 4",
  MAIL_ACCOUNT_ID: "E-postkonto",
  DESCRIPTION: "Beskrivning",
  URL: "Webbadress",
  STATUS_PORTS: "Statusportar",
  QUERY_PARAMS: "Frågeparametrar",
  HEADERS: "Rubriker",
  BODY: "Innehåll",
  QUEUE_ID: "Kö",
  MILLISECONDS: "Millisekunder",
  TIMEOUT: "Tidsgräns",
  OPERATIONAL_HOURS_ID: "Öppettider",
  JOURNEY_ID: "Journey ID",
  PUBLISH: "Publicera",
  ENTER_FULLSCREEN: "Visa i helskärm",
  EXIT_FULLSCREEN: "Avsluta helskärm",
  RESET: "Återställ",
  APPLY: "Tillämpa",
  RELOAD_FROM_JOURNEY: "Ladda om från resa",
  WHEN: "När",
  WITHIN: "Inom",
  NOT_WITHIN: "Inte inom",
  ERROR: "Fel",
  SUCCESS: "Lyckades",
  OPTION_1: "Alternativ 1",
  OPTION_2: "Alternativ 2",
  OPTION_3: "Alternativ 3",
  OPTION_4: "Alternativ 4",
  OPTION_5: "Alternativ 5",
  OPTION_6: "Alternativ 6",
  OPTION_7: "Alternativ 7",
  OPTION_8: "Alternativ 8",
  OPTION_9: "Alternativ 9",
  RULE_1: "Regel 1",
  RULE_2: "Regel 2",
  RULE_3: "Regel 3",
  RULE_4: "Regel 4",
  RULE_5: "Regel 5",
  RULE_6: "Regel 6",
  RULE_7: "Regel 7",
  RULE_8: "Regel 8",
  RULE_9: "Regel 9",
  OTHERWISE: "Annars",
  TRIGGERED: "Utlöst",
  TIMED_OUT: "Tiden gick ut",
  NEXT: "Nästa",
  EDITED: "Redigerad",
  ASSIGNED: "Tilldelad",
  TIME_RANGE: "Tidsintervall",
  MONTHS: "Månader",
  DAYS: "Dagar",
  DAY_S: "Dag(ar)",
  WEEK_DAYS: "Veckodagar",
  FROM: "Från",
  ALWAYS: "Alltid",
  INVALID_INTERVAL: "Ogiltigt intervall",
  CREATED: "Skapad",
  RESOLVED: "Löst",
  REOPENED: "Återöppnad",
  FIRSTASSIGNED: "Först tilldelad",
  REQUESTERRESPONDED: "Frågeställaren svarade",
  AGENTRESPONDED: "Agenten svarade",
  UNTILFIRSTRESPONSEDUE: "Till första svar förfaller",
  FIRSTRESPONSEOVERDUE: "Första svar försenat",
  UNTILANYRESPONSEDUE: "Till något svar förfaller",
  ANYRESPONSEOVERDUE: "Något svar försenat",
  RESOLUTIONTIMEOVERDUE: "Lösningstid försenad",
  UNTILRESOLUTIONTIME: "Till lösningstid förfaller",
  WAITINGFORTHIRDPARTY: "Väntar på tredje part",
  UNIT: "Enhet",
  TIME: "Tid",
  RESPONSE_TIME: "Svarstid",
  RESOLUTION_TIME: "Lösningstid",
  CONTACT_ID: "Kontakt",
  USER_ID: "Användar-id",
  AGENT_ID: "Agent-id",
  HOURS_SHORT: "t",
  MINUTES_SHORT: "m",
  SECONDS_SHORT: "s",
  DAYS_SHORT: "d",
  YESTERDAY: "Igår",
  JUST_NOW: "Just nu",
  NOUPDATEDIN7DAYS: "Inga uppdateringar på 7 dagar",
  CUSTOMERRESPONDED: "Kunden svarade",
  STATUS_AT: "Status ändrades vid",
  ASSIGNED_AT: "Tilldelad vid",
  FIRST_ASSIGNED_AT: "Först tilldelad vid",
  CONVERSATION_UPDATE_DISPOSITION_ID: "Disposition uppdaterades",
  CONVERSATION_UPDATE_USER_ID: "Agent uppdaterades",
  CONVERSATION_UPDATE_STATUS: "Status ändrades",
  CONVERSATION_UPDATE_FLAG: "Flagga ändrades",
  CONVERSATION_UPDATE_RESOLUTION_TIME: "Lösningstid ändrades",
  CONVERSATION_UPDATE_RESPONSE_TIME: "Svarstid ändrades",
  CONVERSATION_UPDATE_QUEUE: "Kö ändrades",
  CONVERSATION_UPDATE_PRIORITY: "Prioritet ändrades",
  CONVERSATION_UPDATE_CONTACT: "Kontakt ändrades",
  CONVERSATION_NEW_MESSAGE: "Nytt meddelande",
  NEW_CONVERSATION: "Ny konversation",
  MINIMIZE: "Minimera",
  MAXIMIZE: "Maximera",
  MINUTES: "Minuter",
  MINUTE_S: "Minut(er)",
  SECOND_S: "Sekund(er)",
  HOURS: "Timmar",
  HOUR_S: "Timme/timmar",
  LAST_UPDATED: "Senast uppdaterad",
  SUBJECT: "Ämne",
  CC: "Kopia",
  BCC: "Hemlig kopia",
  DISPOSE_AND_CLOSE: "Disponera & Stäng",
  DISPOSE_AND_RESOLVE: "Disponera & Lös",
  ADD_NEW: "Lägg till ny",
  CONFIRM_DELETION: "Är du säker på att du vill ta bort detta objekt?",
  OAUTH_AUTHENTICATION_NOT_FOUND: "Autentisering misslyckades!",
  OAUTH_AUTHENTICATION_NOT_FOUND_CONTENT:
    "En administratör i din organisation måste skapa ett konto med din e-postadress innan du kan logga in!",
  MAIL_ACCOUNT_AUTHENTICATED: "E-postkonto autentiserat!",
  AN_ERROR_OCCURRED: "Ett fel inträffade!",
  ATTACHMENTS: "Bilagor",
  ATTACHMENT: "Bilaga",
  FILE_NOT_FOUND: 'Filen "{{filename}}" kunde inte hittas!',
  NOT_FOUND: "Hittades inte!",
  PAGE_NOT_FOUND: "Sidan hittades inte!",
  PAGE_NOT_FOUND_DESCRIPTION:
    "Sidan du letar efter finns inte! Om du klickade på en länk som tog dig hit, vänligen rapportera problemet till din administratör!",
  NOTIFICATION_A: "Ny tilldelning",
  NOTIFICATION_B: "Nytt svar på min konversation",
  NOTIFICATION_C: "Konversationsmeddelanden jag är taggad i",
  NOTIFICATION_D: "Statusuppdatering på min konversation",
  NOTIFICATION_E: "Konversation tilldelad till mig",
  NOTIFICATION_F: "Konversation tilldelad till mina köer",
  NOTIFICATION_G: "Påminnelse om första svar",
  NOTIFICATION_H: "Påminnelse om lösningstid",
  NOTIFICATION_I: "Påminnelse om nästa svar",
  NOTIFICATION_J: "Systeminfo",
  NOTIFICATION_K: "Systemfel",
  NOTIFICATION_L: "Lösenord går snart ut",
  NOTIFICATION_M: "Larm",
  "NOTIFICATION_-": "Internt samtal",
  MARK_ALL_AS_SEEN: "Markera alla som sedda",
  CANNED_ANSWER: "Standardsvar",
  CONTACT_FIRST_NAME: "Kontaktens förnamn",
  CONTACT_LAST_NAME: "Kontaktens efternamn",
  CONTACT_FULL_NAME: "Kontaktens fullständiga namn",
  CONTACT_EMAIL: "Kontaktens e-post",
  CONTACT_PHONE: "Kontaktens telefon",
  AGENT_FIRST_NAME: "Agentens förnamn",
  AGENT_LAST_NAME: "Agentens efternamn",
  AGENT_FULL_NAME: "Agentens fullständiga namn",
  CONVERSATION__ID: "Konversations-id",
  SELECT_CONTACT: "Välj kontakt",
  MULTIPLE_CONTACTS_WHERE_FOUND: "Flera kontakter hittades!",
  MULTIPLE_CONTACTS_WHERE_FOUND_SELECT_CONTACT: "Flera kontakter hittades! Vilken vill du använda?",
  WHICH_ONE_DO_YOU_WANT_TO_USE: "Vilken vill du använda?",
  EMAILS: "E-postadresser",
  PHONES: "Telefonnummer",
  STRATEGY: "Strategi",
  ROUND_ROUND_ROBIN: "Round Robin",
  VOICE_QUEUE_STRATEGY_RINGALL: "Ring alla",
  VOICE_QUEUE_STRATEGY_ROUNDROBIN: "Round Robin",
  VOICE_QUEUE_STRATEGY_LEASTRECENT: "Minst nyligen",
  VOICE_QUEUE_STRATEGY_FEWESTCALLS: "Minst antal samtal",
  VOICE_QUEUE_STRATEGY_RRMEMORY: "Cirkulär med minne",
  VOICE_QUEUE_STRATEGY_LINEAR: "Linjär",
  VOICE_QUEUE_STRATEGY_WRANDOM: "Viktad slumpmässig",
  RINGALL: "Ring alla",
  LEASTRECENT: "Minst nyligen",
  FEWESTCALLS: "Minst antal samtal",
  RANDOM: "Slumpmässig",
  WRANDOM: "Viktad slumpmässig",
  LINEAR: "Linjär",
  RRMEMORY: "Cirkulär med minne",
  ROUNDROBIN: "Cirkulär",
  QUEUE_STRATEGY_ROUND_ROUND_ROBIN: "Cirkulär",
  QUEUE_STRATEGY_PING_ALL: "Pinga alla",
  QUEUE_STRATEGY_LEAST_RECENT: "Minst nyligen",
  QUEUE_STRATEGY_FEWEST_CONVERSATIONS: "Minst antal konversationer",
  QUEUE_STRATEGY_RANDOM: "Slumpmässig",
  QUEUE_STRATEGY_SKILL_BASED_AGENTS_READY: "Kompetensbaserad agenter redo",
  QUEUE_STRATEGY_SKILL_BASED_TIMEOUT: "Kompetensbaserad timeout",
  CREATE_LIST_ID: "Skapa lista",
  CREATE_LIST_ID_HELP: "Välj lista att lägga till nya kontakter i",
  READ_LISTS: "Läs listor",
  READ_LISTS_HELP:
    "Listor att läsa kontakter från. Både system och agenter kommer att välja kontakter som finns i dessa listor.",
  CONVERSATION_FORM_ID: "Konversationsformulär",
  SLA_POLICY_ID: "SLA-policy",
  INBOUND_SLA_POLICY_ID: "SLA-policy (inkommande)",
  OUTBOUND_SLA_POLICY_ID: "SLA-policy (utgående)",
  REQUIRE_DISPOSITION: "Kräv klassificering",
  SETTINGS_REQUIRE_DISPOSITION_HELP:
    "Aktivera för att tvinga val av klassificering innan konversationen stängs",
  REQUIRE_CLIENT: "Kräv klient",
  SETTINGS_REQUIRE_CLIENT_HELP: "Aktivera för att tvinga val av klient innan konversationen stängs",
  CONVERSATION_ASSIGNMENT: "Konversationstilldelning",
  SETTINGS_CONVERSATION_ASSIGNMENT_HELP:
    "Hur konversationer ska tilldelas till agenter. Manuell tilldelning kommer alltid att vara tillgänglig.",
  ONOPEN: "Vid öppning",
  ONREPLY: "Vid svar",
  ONCLOSE: "Vid stängning",
  MANUALLY: "Manuellt",
  CONVERSATION_ASSIGNMENT_ONOPEN: "Vid öppning",
  CONVERSATION_ASSIGNMENT_ONREPLY: "Vid svar",
  CONVERSATION_ASSIGNMENT_ONCLOSE: "Vid stängning",
  CONVERSATION_ASSIGNMENT_MANUALLY: "Manuellt",
  __DISPOSITIONS: "Klassificeringar",
  __DISPOSITIONS_HELP: "Tillgängliga klassificeringar att välja för konversationer i denna kö.",
  __CANNED_ANSWERS: "Standardsvar",
  __AGENT_SIGNATURES: "Agentsignaturer",
  __OUTBOUND_MAIL_ACCOUNTS: "Utgående e-postkonton",
  __OUTBOUND_MAIL_ACCOUNTS_HELP:
    "Tillgängliga utgående e-postkonton vid skapande av nya utgående e-postkonversationer",
  FORM_NULLABLE_ENABLE: "Definiera värde",
  FORM_NULLABLE_DISABLE: "Inaktivera värde",
  EDIT_QUEUE: "Redigera kö",
  ADD_QUEUE: "Skapa ny kö",
  DELETED: "Borttagen",
  TWO_FACTOR_AUTH: "2FA",
  ORGANIZATION_ID: "Organisation",
  ADMINPROFILE_ID: "Adminprofil",
  SUPERADMIN_PROFILE_ID: "Superadminprofil",
  __PAUSES: "Pauser",
  __QUEUES: "Köer",
  __TEAMS: "Team",
  TEAM: "Team",
  ADDRESS: "Adress",
  INBOUND_JOURNEY_ID: "Inkommande resa",
  OUTBOUND_JOURNEY_ID: "Utgående resa",
  IMAP: "IMAP",
  SMTP: "SMTP",
  HOST: "Värd",
  PORT: "Port",
  NAME: "Namn",
  TLS: "tls/ssl",
  REMOVE_ON_RECEIVE: "Ta bort vid mottagning",
  IMAP_REMOVE_ON_RECEIVE_HELP: "Ta bort e-post från inkorgen när den tas emot",
  BASIC: "Grundläggande",
  XOAUTH2: "Oauth 2",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASIC: "Grundläggande",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICGOOGLE: "Grundläggande (Google)",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICMICROSOFT: "Grundläggande (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2MICROSOFT: "Modern autentisering (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2GOOGLE: "Modern autentisering (Google)",
  JOURNEIES: "Journeys",
  JOURNEY_DRAFTS: "Journey drafts",
  FORM_CHANNEL: "Formulärkanal",
  FORM_WEBSITES: "Formulärwebbplatser",
  TIME_INTERVALS: "Tidsintervall",
  OPERATIONAL_HOURSES: "Öppettider",
  OPERATIONAL_HOURS: "Öppettider",
  SLA_POLICIES: "SLA-policyer",
  CANNED_ANSWERS: "Standardsvar",
  SIGNATURES: "Signaturer",
  FORMS: "Formulär",
  STAFF: "Personal",
  SEARCH_AGENTS: "Sök agenter",
  STATUS_0: "Offline",
  STATUS_1: "Online",
  STATUS_4: "Redo (E-post)",
  STATUS_8: "Redo (Röst)",
  STATUS_512: "Redo",
  STATUS_1024: "Delvis upptagen",
  STATUS_2048: "Hanterar konversation",
  STATUS_4096: "Pingar",
  STATUS_32768: "Paus",
  STATUS_262144: "Upptagen (E-post)",
  STATUS_524288: "Upptagen (Röst)",
  STATUS_1048576: "Upptagen (Formulär)",
  STATUS_33554432: "Upptagen",
  FILTER: "Filter",
  ITEMS: "Objekt",
  ALLOW: "Tillåt",
  CANNEDANSWER: "Standardsvar",
  FORM: "Formulär",
  SIGNATURE: "Signatur",
  SLAPOLICY: "SLA-policy",
  OPERATIONALHOURS: "Öppettider",
  TAG: "Etikett",
  MAILACCOUNT: "E-postkonto",
  READ: "Läs",
  CREATE: "Skapa",
  DELETE: "Ta bort",
  PERMISSIONS: "Behörigheter",
  TIMEINTERVAL: "Tidsintervall",
  LOGIN_STRATEGY: "Inloggningstyp",
  LOGIN_STRATEGY_LOCAL: "Användarnamn / Lösenord",
  LOGIN_STRATEGY_GOOGLE: "Google",
  LOGIN_STRATEGY_MICROSOFT: "Microsoft",
  ADMIN_AGENT: "Admin & Agent",
  FORMWEBSITE: "Formulärwebbplats",
  SCHEMA_NOT_FOUND: "Formuläret kunde inte laddas!",
  ITEM_NOT_FOUND: "Objektet du försöker ladda kunde inte hittas!",
  ADMIN_ORGANIZATIONS_CHANGED_RELOAD:
    "Tilldelade organisationer för din användare har uppdaterats, vänligen ladda om manuellt för att applikationen ska fungera korrekt!",
  LOADING_DEPENDENCIES: "Laddar beroenden",
  PARENT: "Överordnad",
  ACTIVE: "Aktiv",
  ORGANIZATION_2FA_HELP: "Välj roller för att tvinga tvåfaktorsautentisering",
  SECONDS: "Sekunder",
  PROPERTY: "Egenskap",
  VALUE: "Värde",
  SIGNATURE_ID: "Signatur",
  METHOD: "Metod",
  CONDITIONS: "Villkor",
  ADD_CONDITION: "Lägg till villkor",
  FLAG: "Flagga",
  SOURCE: "Källa",
  SINCE: "Sedan",
  JOURNEY_DRAFT: "Resutkast",
  PENDING: "Väntande",
  START_CONVERSATION: "Starta konversation",
  NEW_V_CONVERSATION: "Ny SIP-konversation",
  NEW_M_CONVERSATION: "Ny e-postkonversation",
  CREATE_NEW_CONTACT: "Skapa ny kontakt",
  OR_CREATE_NEW_CONTACT: "eller skapa ny kontakt",
  LOADING_FORM: "Laddar formulär",
  __TAGS: "Etiketter",
  MAIN_CHANNEL: "Huvudkanal",
  CREATE_CONVERSATION: "Skapa konversation",
  CHOOSE_AGENT: "Välj agent",
  CHOOSE_QUEUE: "Välj kö",
  SELECTED: "Vald",
  CHANGE_CONTACT: "Ändra kontakt",
  CREATE_CONTACT: "Skapa kontakt",
  REQUIRED: "Obligatorisk",
  CONVERSATION_STATUS: "Konversationsstatus",
  NO_NAME: "Inget namn",
  DISPLAY_TITLE: "Visningstitel",
  UI_TYPE: "UI-typ",
  VALIDATION: "Validering",
  MIN: "Min",
  CHILD_TYPE: "Undertyp",
  VALID: "Giltig",
  REQUIRED_ON_SAVE: "Obligatorisk vid sparande",
  REQUIRED_ON_CONVERSATION_CLOSE: "Obligatorisk vid konversationsstängning",
  REQUIRED_ON_FORM_SUBMIT: "Obligatorisk vid webbformulärsinlämning",
  ALIAS: "Alias",
  ALIAS__KEY: "Alias / nyckel",
  FIELD_TYPE_DESCRIPTION: "Fälttypen gör det tillgängligt för olika formulärtyper",
  MINIMUM: "Minimum",
  VALIDATION_MINIMUM_NUMBER_HELP: "Minsta nummer som kan fyllas i detta fält",
  VALIDATION_MINIMUM_LIST_HELP: "Minsta antal alternativ",
  VALIDATION_MINIMUM_TEXT_HELP: "Minsta antal tecken",
  SETTINGS_VALIDATION_CHILD_TYPE_HELP: "Typ av listalternativ",
  FIELD_ALIAS_HELP:
    "Värden kommer att lagras med detta unika alias. Endast alfanumeriska tecken och _ är giltiga tecken.",
  OPTIONS: "Alternativ",
  __FIELDS: "Fält",
  WEBSITE: "Webbplats",
  POLICY: "Policy",
  FIRST_RESPONSE: "Första svar",
  EVERY_RESPONSE: "Varje svar",
  URGENT: "Brådskande",
  SLA_POLICY: "SLA-policy",
  SEARCH_RESULTS: "Sökresultat",
  UNASSIGNED: "Ej tilldelad",
  NO_QUEUE: "Ingen kö",
  NO_PAUSE: "Ingen paus",
  NOT_DISPOSED: "Ej klassificerad",
  NO_FLAG: "Ingen flag",
  FLAGS: "Flags",
  INVALID_2FA_SECRET: "Ogiltig kod",
  __TIME_INTERVALS: "Tidsintervall",
  TIME_INTERVAL: "Tidsintervall",
  TIMEZONE: "Tidszon",
  RESET_PASSWORD_DONE: "Ett e-postmeddelande med instruktioner har skickats till dig!",
  PASSWORD_RESET_EMAIL_WILL_BE_SENT:
    "Vi kommer att skicka ett e-postmeddelande med instruktioner till kontot med angivet användarnamn, om det kontot existerar!",
  DISPOSITION_REQUIRED_ON_THIS_QUEUE:
    "Klassificering måste anges vid stängning/lösning av konversation, enligt köinställningar.",
  CLIENT_REQUIRED_ON_THIS_QUEUE:
    "Klient måste anges vid stängning/lösning av konversation, enligt köinställningar.",
  CONVERSATION_FORM_NOT_VALID_ON_SAVE:
    "Konversationsformuläret har obligatoriska fält som måste fyllas i innan du kan redigera konversationen.",
  CONVERSATION_FORM_NOT_VALID_ON_CLOSE:
    "Konversationsformuläret har obligatoriska fält som måste fyllas i innan du kan lösa/stänga konversationen.",
  CONTACT_FORM_NOT_VALID_ON_SAVE:
    "Kontaktformuläret har obligatoriska fält som måste fyllas i innan du kan redigera konversationen.",
  CONTACT_FORM_NOT_VALID_ON_CLOSE:
    "Kontaktformuläret har obligatoriska fält som måste fyllas i innan du kan lösa/stänga konversationen.",
  CONVERSATION_CANNOT_BE_CLOSED_WITHOUT_USER:
    "Konversationen måste ha en tilldelad agent innan stängning/lösning.",
  DISPOSITION_NOTE_NOT_ALLOWED_WHEN_NO_DISPOSITION_SELECTED:
    "Klassificeringsanteckning är inte tillåten utan vald klassificering.",
  QUEUE_MAY_NOT_BE_NULL: "Kö får inte vara odefinierad för konversationen.",
  AGENT_NOT_IN_SELECTED_QUEUE: "Tilldelad agent är inte ansluten till vald kö.",
  CONTACT_ALREADY_SELECTED: "Kontakt redan vald för konversationen",
  QUEUE_NOT_FOUND_ON_CONVERSATION: "En kö måste tilldelas konversationen innan val av kontakt.",
  NO_MESSAGES_FOUND_ON_CONVERSATION: "Inga meddelanden hittades i konversationen.",
  QUEUE_NO_READ_LISTS: "Kön har inga tilldelade läslistor.",
  CONVERSATION_FORM_DATA_CLOSE_REQUIRED_TOOLTIP:
    "Obligatoriskt fält när konversationsstatus sätts till stängd",
  CONVERSATION_FORM_DATA_CLOSE_TOOLTIP:
    "Obligatoriskt fält vid uppdatering av konversationsegenskaper",
  VIEW_CONVERSATION: "Visa konversation",
  START_NEW_MAIL_CONVERSATION: "Starta ny e-postkonversation",
  START_NEW_VOICE_CONVERSATION: "Starta ny röstkonversation",
  NULLABLE: "Nullbar",
  SETTINGS_NULLABLE_HELP: 'Värde kan sättas till "null"',
  UI_DATA_TYPE: "UI / Datatyp",
  LAST_SAVE: "Senast sparad",
  TITLE_MISSING: "Titel saknas",
  SELECT_QUEUE: "Välj kö",
  CREATE_INTERNAL_NOTE: "Skapa intern anteckning",
  SAVE_DRAFT: "Spara utkast",
  NO_SELECTABLE_DISPOSITIONS: "Inga valbara klassificeringar",
  SMTP_PORT_HELP: "Standard: 25, tls: 465",
  IMAP_PORT_HELP: "Standard: 143, tls: 993",
  FORM_ID: "Formulär",
  CHANNEL: "Kanal",
  INTERVAL: "Intervall",
  CANNED_ANSWER_TYPE_A: "Agent",
  CANNED_ANSWER_TYPE_Q: "Kö",
  CANNED_ANSWER_CHANNEL_M: "E-post",
  CANNED_ANSWER_CHANNEL_S: "Sms",
  SIGNATURE_TYPE_A: "Agent",
  SIGNATURE_TYPE_Q: "Kö",
  SLA_TIME_UNIT_H: "Timmar",
  SLA_TIME_UNIT_D: "Dagar",
  FORM_TYPE_CONVERSATION: "Konversation",
  FORM_TYPE_CONTACT: "Kontakt",
  FORM_TYPE_WEBSITE: "Webbplats",
  FIELD_TYPE_CONVERSATION: "Konversation",
  FIELD_TYPE_CONTACT: "Kontakt",
  FIELD_TYPE_FORMSUBMISSION: "Formulärinlämning",
  FORM_ELEMENT_TYPE_EXPOSED_INPUT: "Text",
  FORM_ELEMENT_TYPE_EXPOSED_TEXT: "Lång text",
  FORM_ELEMENT_TYPE_EXPOSED_NUMBER: "Nummer",
  FORM_ELEMENT_TYPE_EXPOSED_SELECT: "Välj",
  FORM_ELEMENT_TYPE_EXPOSED_LIST: "Lista",
  FORM_ELEMENT_TYPE_EXPOSED_SWITCH: "Växel",
  FORM_ELEMENT_TYPE_EXPOSED_RADIO: "Radio",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOXES: "Kryssrutor",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOX: "Kryssruta",
  FORM_ELEMENT_TYPE_EXPOSED_EMAIL: "E-post",
  FORM_ELEMENT_TYPE_EXPOSED_PHONE: "Telefonnummer",
  FORM_ELEMENT_TYPE_EXPOSED_HTML: "Html",
  FORM_ELEMENT_TYPE_EXPOSED_FILE: "Fil",

  FORM_ELEMENT_CHILD_TYPE_EXPOSED_INPUT: "Text",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_NUMBER: "Nummer",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_PHONE: "Telefonnummer",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_EMAIL: "E-post",

  CHANNEL_VOICE: "Röst",
  CHANNEL_VOICE_INBOUND: "Inkommande röstsamtal",
  CHANNEL_VOICE_INTERNAL: "Internt röstsamtal",
  CHANNEL_VOICE_INTERNAL_LIVE: "Pågående internt röstsamtal",
  CHANNEL_VOICE_TRANSFER: "Röstöverföring",
  CHANNEL_VOICE_TRANSFER_LIVE: "Pågående röstöverföring",
  CHANNEL_VOICE_INBOUND_LIVE: "Pågående inkommande röstsamtal",
  CHANNEL_VOICE_OUTBOUND: "Utgående röstsamtal",
  CHANNEL_VOICE_OUTBOUND_LIVE: "Pågående utgående röstsamtal",
  CHANNEL_EDIT: "Redigera",
  CHANNEL_CHAT: "Chatt",
  CHANNEL_CHAT_INBOUND: "Inkommande chattmeddelande",
  CHANNEL_CHAT_OUTBOUND: "Utgående chattmeddelande",
  CHANNEL_MAIL: "E-post",
  CHANNEL_MAIL_INBOUND: "Inkommande e-post",
  CHANNEL_MAIL_OUTBOUND: "Utgående e-post",
  CHANNEL_SMS: "SMS",
  CHANNEL_SMS_INBOUND: "Inkommande SMS",
  CHANNEL_SMS_OUTBOUND: "Utgående SMS",
  CHANNEL_FORM: "Formulär",
  CHANNEL_CHANSPY: "Kanalavlyssning",
  CHANNEL_DISPOSITION_NOTE: "Klassificeringsanteckning",
  INTERNAL_CALL: "Internt samtal",

  CHANNEL_V: "Röst",
  "CHANNEL_-": "Ingen kanal",
  CHANNEL_C: "Chatt",
  CHANNEL_M: "E-post",
  CHANNEL_S: "SMS",
  CHANNEL_F: "Formulär",
  CHANNEL_IN: "Intern anteckning",
  CHANNEL_In: "Intern anteckning",

  ADMINPROFILE_PERMISSION_ID_USER: "Användare",
  ADMINPROFILE_PERMISSION_ID_TEAM: "Team",
  ADMINPROFILE_PERMISSION_ID_ORGANIZATION: "Organisation",
  ADMINPROFILE_PERMISSION_ID_MAILACCOUNT: "E-postkonto",
  ADMINPROFILE_PERMISSION_ID_QUEUE: "Kö",
  ADMINPROFILE_PERMISSION_ID_LIST: "Lista",
  ADMINPROFILE_PERMISSION_ID_ADMINPROFILE: "Administratörsprofil",
  ADMINPROFILE_PERMISSION_ID_AGENTPROFILE: "Agentprofil",
  ADMINPROFILE_PERMISSION_ID_SLAPOLICY: "SLA-policy",
  ADMINPROFILE_PERMISSION_ID_DISPOSITION: "Klassificering",
  ADMINPROFILE_PERMISSION_ID_FORM: "Formulär",
  ADMINPROFILE_PERMISSION_ID_FIELD: "Fält",
  ADMINPROFILE_PERMISSION_ID_FORMWEBSITE: "Formulärwebbplats",
  ADMINPROFILE_PERMISSION_ID_JOURNEY: "Resa",
  ADMINPROFILE_PERMISSION_ID_OPERATIONALHOURS: "Öppettider",
  ADMINPROFILE_PERMISSION_ID_PAUSE: "Paus",
  ADMINPROFILE_PERMISSION_ID_CANNEDANSWER: "Standardsvar",
  ADMINPROFILE_PERMISSION_ID_SIGNATURE: "Signatur",
  ADMINPROFILE_PERMISSION_ID_TAG: "Etikett",
  ADMINPROFILE_PERMISSION_ID_TIMEINTERVAL: "Tidsintervall",
  ADMINPROFILE_PERMISSION_ID_VOICEROUTE: "Röstrutt",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDING: "Röstinspelning",
  ADMINPROFILE_PERMISSION_ID_SOUND: "Ljud",
  ADMINPROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Variabel",
  ADMINPROFILE_PERMISSION_ID_STATSREPORT: "Statistikrapport",
  ADMINPROFILE_PERMISSION_ID_STATSWIDGET: "Statistikwidget",
  ADMINPROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Destinationsbegränsare",
  ADMINPROFILE_PERMISSION_ID_INVOICE: "Faktura",
  ADMINPROFILE_PERMISSION_ID_VOICESHORTCUT: "Röstgenväg",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Röstbrevlåda",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Röstbrevlådemeddelande",
  ADMINPROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Telefonnummerköp",
  ADMINPROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Anpassade mätvärden",
  ADMINPROFILE_PERMISSION_ID_AUDIT: "Granskning",
  ADMINPROFILE_PERMISSION_ID_APIKEY: "API-nyckel",
  ADMINPROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Kontaktanpassad händelse",
  ADMINPROFILE_PERMISSION_ID_CLIENT: "Klient",
  ADMINPROFILE_PERMISSION_ID_CONTACT: "Kontakt",
  ADMINPROFILE_PERMISSION_ID_WALLBOARD: "Anslagstavla",
  ADMINPROFILE_PERMISSION_ID_SKILL: "Kompetens",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Röstinspelningstranskript",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Röstinspelningstranskriptinställningar",
  ADMINPROFILE_PERMISSION_ID_POINTSSYSTEM: "Poängsystem",
  ADMINPROFILE_PERMISSION_ID_GDPR: "Säkerhet & efterlevnad",
  ADMINPROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymisera",
  ADMINPROFILE_PERMISSION_ID_STATSALARM: "Statistiklarm",
  ADMINPROFILE_PERMISSION_ID_VOICECALL: "Röstsamtal",
  ADMINPROFILE_PERMISSION_ID_TEXTMESSAGE: "Textmeddelande",
  SUPERADMIN_PROFILE_PERMISSION_ID_USER: "Användare",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEAM: "Team",
  SUPERADMIN_PROFILE_PERMISSION_ID_ORGANIZATION: "Organisation",
  SUPERADMIN_PROFILE_PERMISSION_ID_MAILACCOUNT: "E-postkonto",
  SUPERADMIN_PROFILE_PERMISSION_ID_QUEUE: "Kö",
  SUPERADMIN_PROFILE_PERMISSION_ID_LIST: "Lista",
  SUPERADMIN_PROFILE_PERMISSION_ID_ADMINPROFILE: "Administratörsprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_AGENTPROFILE: "Agentprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_SLAPOLICY: "SLA-policy",
  SUPERADMIN_PROFILE_PERMISSION_ID_DISPOSITION: "Klassificering",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORM: "Formulär",
  SUPERADMIN_PROFILE_PERMISSION_ID_FIELD: "Fält",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORMWEBSITE: "Formulärwebbplats",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEY: "Resa",
  SUPERADMIN_PROFILE_PERMISSION_ID_OPERATIONALHOURS: "Öppettider",
  SUPERADMIN_PROFILE_PERMISSION_ID_PAUSE: "Paus",
  SUPERADMIN_PROFILE_PERMISSION_ID_CANNEDANSWER: "Standardsvar",
  SUPERADMIN_PROFILE_PERMISSION_ID_SIGNATURE: "Signatur",
  SUPERADMIN_PROFILE_PERMISSION_ID_TAG: "Etikett",
  SUPERADMIN_PROFILE_PERMISSION_ID_TIMEINTERVAL: "Tidsintervall",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEROUTE: "Röstrutt",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDING: "Röstinspelning",
  SUPERADMIN_PROFILE_PERMISSION_ID_SOUND: "Ljud",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Variabel",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSREPORT: "Statistikrapport",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSWIDGET: "Statistikwidget",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEASTERISK: "Röstasterisk",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFF: "Tariff",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFFPARENT: "Tariff parent",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOST: "Månadskostnad för telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOSTPARENT:
    "Överordnad månadskostnad för telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOST: "Startavgift för telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOSTPARENT:
    "Överordnad startavgift för telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_SUPERADMINPROFILE: "Superadministratörsprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICE: "Faktura",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICEPOST: "Fakturapost",
  SUPERADMIN_PROFILE_PERMISSION_ID_PREPAIDCREDIT: "Förskottskredit",
  SUPERADMIN_PROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Destinationsbegränsare",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Röstbrevlåda",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Röstbrevlådemeddelande",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICESHORTCUT: "Röstgenväg",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICETRUNK: "Rösttrunk",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSTOCK: "Telefonnummerlager",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Telefonnummerköp",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Anpassade mätvärden",
  SUPERADMIN_PROFILE_PERMISSION_ID_RELEASE: "Releaser",
  SUPERADMIN_PROFILE_PERMISSION_ID_AUDIT: "Granskning",
  SUPERADMIN_PROFILE_PERMISSION_ID_APIKEY: "API-nyckel",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Kontaktanpassad händelse",
  SUPERADMIN_PROFILE_PERMISSION_ID_CLIENT: "Klient",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACT: "Kontakt",
  SUPERADMIN_PROFILE_PERMISSION_ID_SKILL: "Kompetens",
  SUPERADMIN_PROFILE_PERMISSION_ID_WALLBOARD: "Anslagstavla",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Röstinspelningstranskript",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Röstinspelningstranskriptinställningar",
  SUPERADMIN_PROFILE_PERMISSION_ID_POINTSSYSTEM: "Poängsystem",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPR: "Säkerhet & efterlevnad",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymisera",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSALARM: "Statistiklarm",
  SUPERADMIN_PROFILE_PERMISSION_ID_AIASSISTANT: "AI-assistent",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICECALL: "Röstsamtal",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEXTMESSAGE: "Textmeddelande",

  REPLY_OR_NOTE: "Svara eller anteckna",

  __USERS: "Agenter",
  CONTACT_MANAGEMENT: "Kontakthantering",
  NO_QUEUES_SELECTED:
    "Du har inga köer kopplade till organisationer som är kopplade till dig. Kontakta din organisationsadministratör för att lösa detta problem.",
  SOCKET_DISCONNECTED:
    "Din anslutning till servern har avbrutits. Vänligen uppdatera fönstret.\n\nAvbrott orsakas av instabil internetanslutning eller på grund av inaktivitet.",
  SOCKET_DISCONNECTED_INACTIVITY: "Socket frånkopplad på grund av inaktivitet",
  WARNING_INACTIVITY:
    'Du kommer att loggas ut på grund av inaktivitet. Återgå till atlas instrumentpanel eller tryck på "Jag är tillbaka" för att förhindra att du kopplas från.',
  IAM_BACK: "Jag är tillbaka!",
  NO_EMAIL_ACCESS:
    "Du har inte behörighet att skicka e-post. Kontakta din administratör för mer information.",
  NO_CREATE_CHANNELS: "Du har inte tillgång till några kanaler för att skapa ny konversation.",
  NO_OUTBOUND_QUEUES_ON_CREATE_CONVERSATION:
    "Du har inte tillgång till några köer med utgående rutt. Kontakta din organisationsadministratör för att lösa detta problem.",
  SELECT_AGENTPROFILE_ERROR: "En agentprofil måste väljas för att skapa konversation.",
  SEARCH_AGENT_AND_ASSIGN: "Sök agent och tilldela",
  SEARCH_QUEUE_AND_ASSIGN: "Sök kö och tilldela",
  EDIT_TAGS: "Redigera etiketter",
  SEARCH_TAGS: "Sök etiketter",
  SELECT_DISPOSITION: "Välj klassificering",
  MERGE: "Slå samman",
  MERGE_CONVERSATION: "Slå samman konversation",
  CONVERSATION_MERGE_EXPLANATION:
    "Interaktioner från sekundära ärenden kommer att läggas till i det primära ärendet",
  CONTACT_MERGE_EXPLANATION:
    "Konversationer från sekundär kontakt kommer att läggas till i den primära kontakten",
  SEARCH_CONVERSATION: "Sök konversation",
  SEARCH_CONTACT: "Sök kontakt",
  MERGE_CONTACT: "Slå samman kontakt",
  PRIMARY: "Primär",
  NO_AGENT: "Ingen agent",
  MAIL_ACCOUNT_IMAP_STATE_A: "Autentiserad",
  MAIL_ACCOUNT_IMAP_STATE_N: "Ingen",
  MAIL_ACCOUNT_IMAP_STATE_E: "Fel",
  MAIL_ACCOUNT_IMAP_STATE_U: "Obehörig",
  MAIL_ACCOUNT_IMAP_STATE_C: "Ansluten",
  MAIL_ACCOUNT_IMAP_STATE_CN: "Ansluter",
  MAIL_ACCOUNT_IMAP_STATE_D: "Frånkopplad",
  MAIL_ACCOUNT_SMTP_STATE_A: "Autentiserad",
  MAIL_ACCOUNT_SMTP_STATE_N: "Ingen",
  MAIL_ACCOUNT_SMTP_STATE_E: "Fel",
  MAIL_ACCOUNT_SMTP_STATE_U: "Obehörig",
  MAIL_ACCOUNT_SMTP_STATE_C: "Ansluten",
  MAIL_ACCOUNT_SMTP_STATE_CN: "Ansluter",
  MAIL_ACCOUNT_SMTP_STATE_D: "Frånkopplad",
  IMAP_STATE: "IMAP-status",
  SMTP_STATE: "SMTP-status",
  RECONNECT: "Återanslut",
  PERSONIFY_USER: "Personifiera användare",
  RULES: "Regler",
  JOURNEY_ERROR_LINK_MISSING: "En länk måste vara ansluten till denna port",
  JOURNEY_ERROR_FORM_ERROR:
    "Det finns fel i nodformuläret. Dubbelklicka för att redigera formuläret.",
  JOURNEY_ERROR_DUPLICATE: "Dubbel åtgärd! Ingångsåtgärder får inte förekomma mer än en gång.",
  PUBLISH_JOURNEY: "Publicera Journey",
  NO_ITEMS_WERE_FOUND: "Inga objekt hittades",
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_PHRASE:
    'Kunde inte hitta några konversationer eller kontakter som matchar sökfrasen "{{keyword}}"',
  NO_CONVERSATIONS_WERE_FOUND_BY_PHRASE:
    'Inga konversationer hittades som matchar sökfrasen "{{keyword}}"',
  NO_CONTACTS_WERE_FOUND_BY_PHRASE: 'Inga kontakter hittades som matchar sökfrasen "{{keyword}}"',
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT:
    "Kunde inte hitta några konversationer eller kontakter från vald kontakt",
  NO_CONVERSATIONS_WERE_FOUND_BY_SELECTED_CONTACT: "Inga konversationer hittades från vald kontakt",
  NO_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT: "Inga kontakter hittades från vald kontakt",
  NO_CONVERSATION_FOUND_ON_FILTER: "Inga konversationer hittades som matchar dina filter",
  WITH_SELECTED_DO: "Med valda ändra",
  CHANGE_STATUS: "Ändra status",
  CHANGE_PRIORITY: "Ändra prioritet",
  APPLY_CHANGES_TO_ITEMS: "Tillämpa ändringar på {{count}} objekt",
  APPLY_AND_RESET: "Tillämpa & Återställ",
  ITEMS_FAILED_TO_UPDATE: "Misslyckades att uppdatera {{count}} objekt",
  CHANGES_APPLIED_TO_ITEMS: "Ändringar tillämpade på {{count}} objekt",
  MAIL_HAS_NO_BODY: "Inget e-postinnehåll",
  TIME_PAST: "Tid passerad",
  START_TYPING_TO_SEARCH: "Börja skriva för att söka",
  NOT_REMOVABLE_ENTITY: "Får inte raderas!",
  SHOW_MORE: "Visa mer",
  SHOW_LESS: "Visa mindre",
  UPLOAD: "Ladda upp",
  N_FILES: "{{files}} fil(er)",
  LIST_FILES: "Lista filer",
  LIST_FILE: "Lista fil",
  LIST_ID: "Lista",
  SHEET: "Blad",
  ROW: "Rad",
  SHEET_N: "Blad {{n}}",
  ROW_N: "Rad {{n}}",
  LIST_FILE_FIELDS_HELP: "Ovanstående rader är en förhandsgranskning för att sortera kolumner.",
  WRITE_LISTS: "Skriv listor",
  WRITE_LISTS_HELP: "Listor att skriva importerade kontakter till",
  CLEAN_LISTS: "Rensa listor",
  CLEAN_LISTS_HELP: "Listor att rensa kontakter mot",
  DIALPAD: "Knappsats",
  NO_NOTIFICATIONS: "Du har inga aviseringar!",
  NO_PAUSES_FOUND: "Inga pauser kunde hittas",
  CONNECTION_AT: "Anslutning vid",
  RETRIES: "Försök",
  NO_CONTACT: "Kontakt hittades inte!",
  VOICE_MANAGEMENT: "Rösthantering",
  JOINED: "Ansluten",
  JOINED_QUEUE_DESCRIPTION:
    '"Meddelande" anslöt till kön. Denna port triggas innan försök att distribuera "meddelandet".',
  ON_ASSIGN: "Tilldelad",
  DONE_ANSWERED: "Klar (besvarad)",
  DONE_NO_ANSWER: "Klar (inget svar)",
  ON_ASSIGN_QUEUE_DESCRIPTION: "Konversation tilldelades vald kö",
  DONE_ANSWERED_QUEUE_DESCRIPTION:
    "Röstsamtal lämnade kön på grund av avslut, efter att ha tilldelats en agent",
  DONE_NO_ANSWER_QUEUE_DESCRIPTION:
    "Röstsamtal lämnade kön på grund av timeout eller tom kö (beroende på köinställningarna)",
  HANGUP: "Lägg på",
  RECORD: "Spela in",
  HOLD: "Parkera",
  UNHOLD: "Återuppta",
  TRANSFER: "Överför",
  MUTE: "Tysta",
  UNMUTE: "Aktivera ljud",
  DISTRIBUTING_CALL: "Distribuerar samtal",
  VOICE_ASTERISK: "Asterisk",
  VOICE_ASTERISKS: "Asterisker",
  VOICE: "Röst",
  VOICE_TRUNK: "Trunk",
  VOICE_TRUNKS: "Trunkar",
  VOICE_ROUTE: "Rutt",
  VOICE_ROUTES: "Rutter",
  SOUND: "Ljud",
  SOUNDS: "Ljud",
  JOURNEY_VARIABLE: "Variabel",
  JOURNEY_VARIABLES: "Variabler",
  REST: "Rest",
  FROM_DOMAIN: "Från domän",
  ISO2: "ISO 2",
  VOICE_ASTERISK_ID: "Asterisk",
  INBOUND_VOICE_TRUNK_ID: "Inkommande trunk",
  OUTBOUND_VOICE_TRUNK_ID: "Utgående trunk",
  NUMBER: "Nummer",
  INTERNAL: "Intern",
  VOICE_SETTINGS: "Röstinställningar",
  __OUTBOUND_VOICE_ROUTES: "Utgående röstrutter",
  VOICE_RECORDING_FORMAT_WAV: "Wav",
  VOICE_RECORDING_FORMAT_WAV49: "Wav49",
  VOICE_RECORDING_FORMAT_GSM: "Gsm",
  NEW_CALL: "Nytt samtal",
  START_RECORDING: "Manuell inspelning",
  SETTINGS_START_RECORDING_HELP: "Tillåt agenter att manuellt spela in samtal",
  PLAY_RECORDING: "Spela upp inspelning",
  DOWNLOAD_RECORDING: "Ladda ner inspelning",
  DOWNLOAD_VOICEMAIL: "Ladda ner röstmeddelande",
  PERMISSION_SELF_N: "Ingen",
  PERMISSION_SELF_A: "Alla",
  PERMISSION_SELF_S: "Ägd av användare",
  DOWNLOAD_RECORDING_ERROR: "Något gick fel vid nedladdning av inspelningen.",
  PLAY_RECORDING_ERROR: "Något gick fel vid uppspelning av inspelningen.",
  DOWNLOAD_VOICEMAIL_ERROR: "Något gick fel vid nedladdning av röstmeddelandet.",
  PLAY_VOICEMAIL_ERROR: "Något gick fel vid uppspelning av röstmeddelandet.",
  NO_RESULTS: "Inga resultat",
  VARIABLE_ALIAS: "Variabelalias",
  VOICE_CALL: "Röstsamtal",
  CURRENT_VOICE_CALL_BRIDGE: "Nuvarande samtalsbrygga",
  FIRST_VOICE_CALL_BRIDGE: "Första samtalsbrygga",
  LAST_VOICE_CALL_BRIDGE: "Sista samtalsbrygga",
  HANGED_UP: "Lade på",
  EDGE_ERROR_FROM_NONE_CHANNEL_SPECIFIC_TO_CHANNEL_SPECIFIC:
    "Länkar mellan målnod med specifika kanaler till källnod utan specifik kanal är inte tillåtet",
  EDGE_ERROR_CHANNEL_DONT_EXIST_IN_FROM_NODE:
    "Alla kanaler i målnoden måste finnas tillgängliga i källnoden",
  EDGE_ERROR_CHANNELS_MISMATCH: "Källnodens kanal matchar inte målnodens kanaler!",
  JOURNEY_VARIABLE_ID: "Variabel",
  REST_REQUEST_VARIABLE_HELP:
    "Om lyckat, sätt rest-förfrågans svar till vald variabel. Data kommer att konverteras till sträng.",
  REST_REQUEST_FIELD_HELP:
    "Om lyckat, sätt rest-förfrågans svar till valt fält. Data kommer att konverteras till fälttyp.",
  FIELD_ID: "Fält",
  DATA_TYPE: "Datatyp",
  ACW: "ACW",
  VOICE_SETTINGS_ACW_HELP: "After call work",
  MUSICCLASS: "Väntmusik",
  JOINEMPTY: "Anslut tom",
  FAIL_REASONS: "Felorsaker",
  FULL: "Full",
  LEAVEEMPTY: "Lämna tom",
  JOINUNAVAIL: "Anslut otillgänglig",
  LEAVEUNAVAIL: "Lämna otillgänglig",
  EVENTS: "Händelser",
  EVENT_ASSIGNED_DESCRIPTION: "Röstsamtal tilldelades kö",
  EVENT_JOINED_DESCRIPTION: "Röstsamtal anslöts till kö",
  FAIL_REASON_FULL_DESCRIPTION: "Avslutades då kön var full",
  FAIL_REASON_TIMEOUT_DESCRIPTION: "Avslutades då köns tidsgräns nåddes",
  FAIL_REASON_JOINEMPTY_DESCRIPTION: 'Avslutades på grund av köstatus "JOINEMPTY"',
  FAIL_REASON_LEAVEEMPTY_DESCRIPTION: 'Avslutades på grund av köstatus "LEAVEEMPTY"',
  FAIL_REASON_JOINUNAVAIL_DESCRIPTION: 'Avslutades på grund av köstatus "JOINUNAVAIL"',
  FAIL_REASON_LEAVEUNAVAIL_DESCRIPTION: 'Avslutades på grund av köstatus "LEAVEUNAVAIL"',
  VOICE_QUEUE_QUEUE_STATUS: "Köstatus",
  VOICE_SETTINGS_JOINEMPTY_HELP: "Anslut till kö när tom",
  LEAVEWHENEMPTY: "Lämna när tom",
  VOICE_SETTINGS_LEAVEWHENEMPTY_HELP: "Lämna kö när tom",
  RINGINUSE: "Ring vid upptagen",
  VOICE_SETTINGS_RINGINUSE_HELP:
    "Styr om agenten ska ta emot ytterligare kösamtal även när agenten är upptagen.",
  MAXLEN: "Maximalt antal kösamtal",
  VOICE_SETTINGS_MAXLEN_HELP:
    "Det maximala antalet samtal som tillåts vänta i kö. Samtal som når kön över denna gräns kommer att fortsätta Journey-exekveringsstrategin.",
  VOICE_SETTINGS_TIMEOUT_HELP:
    "Hur länge det ska ringa hos en agent innan agenten anses otillgänglig.",
  WEIGHT: "Vikt",
  VOICE_SETTINGS_WEIGHT_HELP:
    "Anger prioritet för denna kö när dess agenter är medlemmar i flera köer. Ett högre viktvärde innebär att denna kö får prioritet över tillgängliga agenter.",
  AUTOPAUSE: "Autopaus",
  VOICE_SETTINGS_AUTOPAUSE_HELP:
    "Autopaus pausar en kömedlem om de misslyckas med att svara på ett samtal.",
  ANNOUNCE: "Annonsera",
  VOICE_SETTINGS_ANNOUNCE_HELP:
    "Meddelande som spelas upp för den svarande agenten innan samtalet kopplas, vanligtvis för att meddela agenten vilken kö som ringer.",
  ANNOUNCE_FREQUENCY: "Annonseringsfrekvens",
  VOICE_SETTINGS_ANNOUNCE_FREQUENCY:
    "Hur ofta i sekunder som uppringarens position i kön eller den uppskattade väntetiden ska annonseras. Sätt till 0 för att inaktivera.",
  MIN_ANNOUNCE_FREQUENCY: "Minsta annonseringsfrekvens",
  VOICE_SETTINGS_MIN_ANNOUNCE_FREQUENCY_HELP:
    "Minsta tid mellan start av varje annonsering. Detta är användbart för köer där uppringarens position och/eller väntetid ändras snabbt för att undvika konstanta annonseringar.",
  PERIODIC_ANNOUNCE_FREQUENCY: "Periodisk annonseringsfrekvens",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_FREQUENCY_HELP: "Hur ofta periodiska annonseringar ska göras.",
  RANDOM_PERIODIC_ANNOUNCE: "Slumpmässiga periodiska annonseringar",
  VOICE_SETTINGS_RANDOM_PERIODIC_ANNOUNCE_HELP:
    "Spela upp periodiska annonseringar i slumpmässig ordning.",
  RELATIVE_PERIODIC_ANNOUNCE: "Relativa periodiska annonseringar",
  VOICE_SETTINGS_RELATIVE_PERIODIC_ANNOUNCE_HELP:
    "Om satt till ja kommer den periodiska annonseringsfrekvensen att räknas från slutet av varje annonsering istället för från början av varje annonsering.",
  ANNOUNCE_HOLDTIME: "Annonsera väntetid",
  VOICE_SETTINGS_ANNOUNCE_HOLDTIME_HELP:
    "Spela upp den uppskattade väntetiden för uppringare som väntar i kön.",
  ANNOUNCE_POSITION: "Annonsera position",
  VOICE_SETTINGS_ANNOUNCE_POSITION_HELP:
    'Spela upp köpositionen för uppringare som väntar i kön. När du använder alternativen "gräns" eller "mer" måste du också ställa in Annonsera positionsgräns.',
  ANNOUNCE_POSITION_LIMIT: "Annonsera positionsgräns",
  VOICE_SETTINGS_ANNOUNCE_POSITION_LIMIT_HELP:
    'Anger gränsen för när uppringarens position i kön ska spelas upp. Om "Annonsera position" är satt till "gräns" kommer positionen endast att spelas upp för de under den gränsen. Om satt till "mer" kommer den att spelas upp för uppringare över gränsen.',
  ANNOUNCE_POSITION_ONLY_UP: "Annonsera positionsändring",
  VOICE_SETTINGS_ANNOUNCE_POSITION_ONLY_UP_HELP:
    "Annonsera endast uppringarens position om den har förbättrats sedan senaste annonseringen.",
  REPORTHOLDTIME: "Rapportera väntetid",
  VOICE_SETTINGS_REPORTHOLDTIME_HELP:
    "Rapportera väntetiden till den svarande agenten innan samtalet kopplas till agenten.",
  PERIODIC_ANNOUNCE: "Periodiska annonseringar",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_HELP:
    "Välj de periodiska annonseringarna och deras ordning. När den sista annonseringen har spelats upp börjar uppspelningen om från början.",
  ASTERISK_MUSICCLASS_DEFAULT: "Standard",
  ASTERISK_MUSICCLASS_CELTIC: "Keltisk",
  ASTERISK_MUSICCLASS_JAZZ: "Jazz",
  ASTERISK_MUSICCLASS_CHILLOUT: "Avslappning",
  ASTERISK_MUSICCLASS_GOODBYE: "Hejdå",
  ASTERISK_MUSICCLASS_YESTERDAY: "Igår",
  ASTERISK_MUSICCLASS_BLUES: "Blues",
  ASTERISK_MUSICCLASS_UPBEAT: "Uppåt",
  ASTERISK_MUSICCLASS_WALTZ: "Vals",
  ASTERISK_MUSICCLASS_CLAPS: "Applåder",
  ASTERISK_MUSICCLASS_CHEERFUL: "Glad",
  ASTERISK_MUSICCLASS_LULLABY: "Vaggvisa",
  ASTERISK_MUSICCLASS_HAPPY: "Lycklig",
  ASTERISK_MUSICCLASS_HIDDEN: "Dold",
  ASTERISK_AUTOPAUSE_YES: "Ja",
  ASTERISK_AUTOPAUSE_NO: "Nej",
  ASTERISK_AUTOPAUSE_ALL: "Alla",
  ASTERISK_ANNOUNCE_HOLDTIME_YES: "Ja",
  ASTERISK_ANNOUNCE_HOLDTIME_NO: "Nej",
  ASTERISK_ANNOUNCE_HOLDTIME_ONCE: "En gång",
  ASTERISK_ANNOUNCE_POSITION_YES: "Ja",
  ASTERISK_ANNOUNCE_POSITION_NO: "Nej",
  ASTERISK_ANNOUNCE_POSITION_LIMIT: "Gräns",
  ASTERISK_ANNOUNCE_POSITION_MORE: "Mer",
  RECORDING: "Inspelning",
  ERROR_CHILD_HAS_ASTERISK:
    "En eller flera underorganisationer har koppling till asterisk. Överordnad organisation till sådana underorganisationer får inte vara ansluten till asterisk.",
  ERROR_PARENT_HAS_ASTERISK:
    "Förbjudet att välja asterisk när överordnad organisation har koppling till asterisk.",
  ERROR_ORGANIZATION_INACTIVATE_WITH_ACTIVE_CHILD:
    "Organisation med aktiva underorganisationer kan inte inaktiveras. Se till att inaktivera alla underorganisationer innan denna åtgärd utförs.",
  VOICE_VOICEMAIL: "Röstbrevlåda",
  VOICE_VOICEMAILS: "Röstbrevlådor",
  VOICE_VOICEMAIL_ID: "Röstbrevlåda",
  TIMES_TO_RETRY: "Antal återförsök",
  MAX_DIGITS: "Max antal siffror",
  ASTERISK_SOUND_ID: "Ljud",
  GOT_DIGITS: "Fick siffror",
  FAILED: "Misslyckades",
  CHANSPY: "Avlyssna",
  CHANSPIED_ON: "Avlyssnad",
  VOICE_LICENSE: "Röstlicens",
  LOGIN_PAUSE: "Inloggningspaus",
  AUTO_ANSWER: "Automatiskt svar",
  VOICE_SETTINGS_CHANSPY_HELP: 'Tillåt avlyssning av andra användare där "Avlyssnad" är aktiverad',
  VOICE_SETTINGS_CHANSPIED_ON_HELP:
    "Tillåt användare med avlyssningsbehörighet att avlyssna denna användare",
  TWO_FACTOR_AUTH_HELP:
    "Lämna inaktiverad för att läsa organisationens 2fa-inställningar, aktivera för att åsidosätta inställningarna",
  TWO_FACTOR_AUTH_ENABLE_HELP:
    "Aktivera/inaktivera 2fa för denna användare och åsidosätt organisationsinställningar",
  DELAY: "Fördröjning",
  LEAVE_EMPTY_FOR_ALL_QUEUES: "Lämna tomt för alla köer",
  AUTO_ACCEPT: "Automatiskt acceptera",
  ENABLE_TO_SELECT_SPECIFIC_QUEUES: "Aktivera för att acceptera specifika köer",
  AUTO_ACCEPT_HELP: "Acceptera automatiskt mottagna aviseringar",
  ADD_CALL_TO_CONVERSATION: "Lägg till samtal i konversation",
  CALL_SEARCH: "Skriv nummer eller namn",
  SELECT_VOICE_QUEUE_ROUTE: "Välj kö & rutt",
  SOMETHING_WENT_WRONG: "Något gick fel",
  SPY: "Avlyssna",
  NO_CONTACT_SUGGESTIONS_WERE_FOUND: "Inga kontakter hittades att föreslå!",
  MAIL_SETTINGS: "E-postinställningar",
  REPLY_TO: "Svara till",
  REPLIED_TO: "Svarade till",
  NOTE: "Anteckning",
  ANSWER: "Svara",
  CALL_RECORDING: "Samtalsinspelning",
  OUTBOUND_CALL: "Utgående samtal",
  LEAVE_DISABLED_FOR_DEFAULT: "Lämna inaktiverad för standard",
  SELECT_NUMBER: "Välj nummer",
  RINGING: "Ringer",
  STOP: "Stoppa",
  STOP_RECORDING: "Stoppa inspelning",
  DOWNLOAD: "Ladda ner",
  RECORDING_CONVERSATION: "Spelar in konversation",
  RECORDING_VOICEMAIL: "Spelar in röstmeddelande",
  VOICEMAIL: "Röstmeddelande",
  CALL_BACK: "Ring tillbaka",
  WAIT_TIME: "Väntetid",
  TALK_TIME: "Samtalstid",
  CALL_LENGTH: "Samtalslängd",
  MISSED_CALL: "Missat samtal",
  LOCAL_PHONE_NUMBER: "Lokalt telefonnummer",
  PHONE: "Telefon",
  PHONE_NUMBER: "Telefonnummer",
  NOT_ASSIGNED: "Ej tilldelad",
  CONVERSATION_ALREADY_ASSIGNED: "Konversationen är redan tilldelad!",
  OUTBOUND_VOICE_QUEUE_ID: "Utgående kö",
  OUTBOUND_VOICE_QUEUE_ROUTE_ID: "Utgående rutt",
  CONVERSATION_MERGE_FROM:
    "Denna konversation har slagits samman med konversation {{conversation}}",
  CONVERSATION_MERGE_TO: " slogs samman med denna konversation {{conversation}}",
  ON_HOLD: "Parkerad",
  LISTENED_IN: "Avlyssnad",
  LISTEN_ONLY: "Endast lyssna",
  LISTEN_AND_TALK_TO_AGENT: "Lyssna & prata med agent",
  SELECT_AN_OPTION: "Välj ett alternativ",
  ONGOING_CALL: "Pågående samtal",
  INTERNAL_NOTE: "Intern anteckning",
  VIEW_DETAILS: "Visa detaljer",
  CONTAINS_TRANSFER_FROM: "Innehåller överföring från",
  TRANSFERRED_TO: "Överförd till",
  HAS_NOTE: "Har anteckning",
  NOTE_TO: "Anteckning till",
  REPLIED_BY: "Besvarad av",
  SELECT_QUEUE_TO_SELECT_CONTACT: "Kö måste väljas innan kontakt kan väljas!",
  CONTACT_NOT_AUTOMATICALLY_ASSIGNED: "En kontakt kunde inte tilldelas automatiskt!",
  NEW_MAIL_MESSAGE: "Nytt e-postmeddelande",
  UNABLE_TO_PERSONIFY:
    "Kan inte personifiera detta konto. Detta beror troligen på dina behörigheter.",
  USER_CREATE_USERNAME_TAKEN:
    "Användarnamnet du försöker använda är redan taget! Observera att detta användarnamn kan tillhöra en annan organisation!",
  USER_CREATE_EMAIL_TAKEN:
    "E-postadressen du försöker använda är redan tagen! Observera att denna e-postadress kan tillhöra en annan organisation!",
  USER_UPDATE_EMAIL_TAKEN:
    "E-postadressen du försöker använda är redan tagen! Observera att denna e-postadress kan tillhöra en annan organisation!",
  SOCKET_DISCONNECTED_USER_ACTIVE_NOT:
    "En administratör har inaktiverat ditt konto. Du kommer att loggas ut!",
  MAY_NOT_DELETE_YOURSELF: "Du kan inte ta bort dig själv!",
  NO_DATA_CREATED_YET: "Ingen data har skapats än!",
  NO_DATA_MATCHING_YOUR_FILTERS: "Ingen data hittades som matchar dina filter!",
  RESET_FILTERS: "Återställ filter",
  FORM_PREVIEW_NOT_EDITABLE: "Endast förhandsgranskning! Denna egenskap kan inte ändras.",
  AGENTPROFILE_AGENTS: "Agentprofilens agenter",
  ADMINPROFILE_USERS: "Adminprofilens användare",
  QUEUE_AGENTS: "Köagenter",
  NO_USERS_WERE_FOUND: "Inga användare hittades",
  NO_AGENTS_WERE_FOUND: "Inga agenter hittades",
  DRAFT_FROM_MAIL_ACCOUNT_REQUIRED: "Från e-postkonto krävs",
  DRAFT_TO_MAIL_ACCOUNT_REQUIRED: "Till e-postadress krävs",
  MAIL_MESSAGE_BODY_REQUIRED_ON_OUTBOUND: "E-postmeddelande krävs!",
  SMTP_ERROR: "Fel vid sändning av e-post",
  RESEND_EMAIL: "Skicka e-post igen",
  RESEND_INITIATED: "Återutskick initierat",
  MAIL_MESSAGE_NOT_FAILED: "Återutskick endast tillgängligt för misslyckade e-postmeddelanden",
  NO_SUBJECT: "Inget ämne",
  NO_CUSTOM_FILTERS_CREATED: "Inga anpassade filter skapade",
  DRAFT_SEND_CONVERSATION_QUEUE_REQUIRED:
    "Kö måste väljas för konversation innan utkast kan skickas",
  MAIL_ACCOUNT_NOT_IN_queueS: "Valt e-postkonto är inte tillgängligt i köns utgående e-postkonton",
  MAIL_ACCOUNT_REQUIRED: "E-postkonto krävs",
  UNANSWERED_CALL: "Obesvarat samtal",
  SELECT_OR_CREATE_CONTACT: "Välj eller skapa kontakt",
  ROUTING: "Dirigering",
  TRUNK_TYPE_CONNECTEL: "Connectel standard",
  TRUNK_TYPE_CUSTOM: "Anpassad trunk",
  MAIL_ACCOUNT_AUTHENTICATION_FAILED: "E-postkontots autentisering misslyckades",
  AUDIO_NOT_PLAYABLE: "Ljudfil kan inte spelas upp",
  SIP_STATE: "SIP-status",
  SIP_STATE_UNDEFINED: "Ej initierad",
  SIP_STATE_INITIAL: "Ansluter",
  SIP_STATE_UNREGISTERED: "Oregistrerad",
  SIP_STATE_TERMINATED: "Avslutad",
  SIP_STATE_REGISTERED: "Registrerad",
  ACTIVE_IN_QUEUE: "För närvarande aktiv i kö",
  FILENAME: "Fil",
  PRESSED_NONE_VALID: "Tryckte på ogiltigt alternativ",
  PRESSED_1: "Tryckte på 1",
  PRESSED_2: "Tryckte på 2",
  PRESSED_3: "Tryckte på 3",
  PRESSED_4: "Tryckte på 4",
  PRESSED_5: "Tryckte på 5",
  PRESSED_6: "Tryckte på 6",
  PRESSED_7: "Tryckte på 7",
  PRESSED_8: "Tryckte på 8",
  PRESSED_9: "Tryckte på 9",
  "PRESSED_*": "Tryckte på *",
  "PRESSED_#": "Tryckte på #",
  INITIATED: "Initierad",
  RESET_2FA: "Återställ tvåfaktorsautentisering",
  PASSWORD_RESET_SUCCESSFUL: "Lösenordsåterställning lyckades!",
  PASSWORD_RESET_REQUEST_SUCCESSFUL: "Begäran om lösenordsåterställning lyckades!",
  PASSWORD_INVALID: "Lösenordet måste vara minst 6 tecken långt",
  FILL_PASSWORD_TO_RESET: "Fyll i lösenord för att återställa",
  RESET_TOKEN_HAS_EXPIRED:
    "Ogiltig eller utgången token. Vänligen begär en ny lösenordsåterställning.",
  VOICE_TIMELINE_ERROR: "Fel",
  INBOUND_CALL_INITIATED: "Inkommande samtal initierat",
  OUTBOUND_CALL_INITIATED: "Utgående samtal initierat",
  INTERNAL_CALL_INITIATED: "Internt samtal initierat",
  SYSTEM_ANSWER: "System tog emot samtal",
  TIMELINE_JOURNEY_JOB: "Journey-åtgärd",
  RULE_WITH_INDEX_X_MATCHED: 'Regel med index "{{i}}" matchades',
  TRY_X_BUTTON_X_PRESSED: 'Försök {{try}}: Knapp "{{num}}" trycktes',
  TRY_X_DIGITS_X_PRESSED: 'Försök {{try}}: Siffror "{{num}}" trycktes',
  USER_X_PINGED: 'Agent "{{user}}" pingad',
  USER_X_NOTIFICATION_END: 'Agent "{{user}}" ping avslutad',
  NOTIFICATION_X_ACTION: "Åtgärd: {{action}}",
  TIMEOUT_X_SECONDS: "Timeout: {{sec}} sekunder",
  NOTIFICATION_TIMEOUT_X_SECONDS: "Notifikation timeout: {{sec}} sekunder",
  VOICE_QUEUE_ASSIGNED: "Tilldelad till röstsamtalskö",
  VOICE_QUEUE_JOINED: "Ansluten till röstsamtalskö",
  VOICE_QUEUE_DONE: "Lämnade röstsamtalskö",
  NOTIFICATION_ACTION_ACCEPT: "Accepterad",
  NOTIFICATION_ACTION_ACCEPT_FAIL: "Acceptering misslyckades",
  NOTIFICATION_ACTION_REJECT: "Avvisad",
  NOTIFICATION_ACTION_REJECT_FAIL: "Avvisning misslyckades",
  NOTIFICATION_ACTION_TIMEOUT: "Timeout",
  NOTIFICATION_ACTION_ANSWERED_ELSEWHERE: "Besvarad på annat håll",
  NOTIFICATION_ACTION_SYSTEM_HANGED_UP: "System lade på (timeout)",
  NOTIFICATION_ACTION_CONTACT_HANGED_UP: "Kontakt lade på före timeout/svar",
  RECORDING_STARTED_BY_X: 'Inspelning startad av "{{user}}"',
  RECORDING_STOPPED_BY_X: 'Inspelning stoppad av "{{user}}"',
  SYSTEM: "System",
  X_LEFT_CALL: "{{leg}} lämnade samtalet",
  LEFT_CALL_WITH_REASON: 'Lämnade bryggan med anledning "{{reason}}"',
  LEFT_QUEUE_WITH_REASON: 'Lämnade kön "{{queue}}" med anledning "{{reason}}"',
  UNKNOWN: "Okänd",
  USER_ANSWERED: '"{{user}}" svarade',
  QUEUE_X_WITH_TIMEOUT: 'Kö "{{queue}}", med {{timeout}} sekunders timeout',
  AVAILABLE_VOICE_MENU_OPTIONS: 'Tillgängliga alternativ "{{options}}", och "{{retries}}" försök',
  CALL_TRANSFERRED_OUT: "Samtal vidarekopplat ut",
  CALL_TRANSFERRED_IN: "Samtal vidarekopplat in",
  ATTENDED: "Övervakad",
  BLIND: "Blind",
  TRANSFER_TYPE_X: 'Överföringstyp "{{type}}"',
  ROUTE_X: 'Rutt "{{route}}"',
  CONTACT_ANSWERED: "Kontakt svarade",
  BG_COLOR: "Bakgrundsfärg",
  SUB_GROUP: "Undergrupp",
  VARIABLE: "Variabel",
  STATIC: "Statisk",
  KEY: "Nyckel",
  ESCAPE_DIGITS: "Escape-siffra",
  LANGUAGE: "Språk",
  LANGUAGE_SE: "se (Svenska)",
  LANGUAGE_FI: "fi (Finska)",
  LANGUAGE_EN: "en (Engelska)",
  LANGUAGE_DE: "de (Tyska)",
  LANGUAGE_FR: "fr (Franska)",
  LANGUAGE_HI: "hi (Hindi)",
  LANGUAGE_IT: "it (Italienska)",
  LANGUAGE_NO: "no (Norska)",
  LANGUAGE_NL: "nl (Holländska)",
  LANGUAGE_DK: "dk (Danska)",
  LANGUAGE_PL: "pl (Polska)",
  VOICE_LANGUAGE_SE: "se (Svenska)",
  VOICE_LANGUAGE_FI: "fi (Finska)",
  VOICE_LANGUAGE_EN: "en (Engelska)",
  VOICE_LANGUAGE_DE: "de (Tyska)",
  VOICE_LANGUAGE_FR: "fr (Franska)",
  VOICE_LANGUAGE_HI: "hi (Hindi)",
  VOICE_LANGUAGE_IT: "it (Italienska)",
  VOICE_LANGUAGE_NO: "no (Norska)",
  VOICE_LANGUAGE_NL: "nl (Holländska)",
  VOICE_LANGUAGE_DK: "dk (Danska)",
  VOICE_LANGUAGE_PL: "pl (Polska)",
  VOICE_SHORTCUTS: "Röstgenvägar",
  VOICE_SHORTCUT: "Röstgenväg",
  __VOICE_SHORTCUTS: "Snabbnummer",
  HEADER_FILTERS_COUNT: "Återställ {{count}} filter",
  REAUTHENTICATE_ACCOUNT: "Återautentisera",
  IS_LIVE: "Live",
  IS_SHARED: "Delad",
  SHARED: "Delad",
  WITH: "Med",
  ADMINPROFILE_NO_FILTER_ON_CREATION:
    "Adminprofil-behörighet får inte ha något filter vid skapande. Skapa adminprofilen utan filter och lägg till filter vid redigering!",
  ADMINPROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Adminprofilens filter måste innehålla sig själv för tillåtna behörigheter!",
  SUPERADMIN_PROFILE_NO_FILTER_ON_CREATION:
    "Superadmin-profil behörighet får inte ha något filter vid skapande. Skapa superadmin-profilen utan filter och lägg till filter vid redigering!",
  SUPERADMIN_PROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Superadmin-profilens filter måste innehålla sig själv för tillåtna behörigheter!",
  AUDIO_SETTINGS: "Ljudinställningar",
  SELECT_RINGTONE_SPEAKER: "Välj ringsignalhögtalare",
  RINGTONE_SPEAKER: "Ringsignalhögtalare",
  SELECT_VOICE_SPEAKER: "Välj rösthögtalare",
  VOICE_SPEAKER: "Rösthögtalare",
  SELECT_MICROPHONE: "Välj mikrofon",
  ERROR_SETTING_SPEAKER:
    "Ett fel uppstod när högtalaren skulle ställas in, välj en annan högtalare innan du fortsätter!",
  ERROR_SETTING_RINGTONE_SPEAKER:
    "Ett fel uppstod när ringsignalhögtalaren skulle ställas in, välj en annan högtalare innan du fortsätter!",
  NO_SPEAKERS_FOUND: "Inga högtalare hittades!",
  NO_MICROPHONES_FOUND: "Inga mikrofoner hittades!",
  SELECTED_RINGTONE_SPEAKER_NOT_FOUND:
    'Vald ringsignalhögtalare hittades inte! Vi ändrade din ringsignalhögtalare till "systemstandard" tills du gör en ändring!',
  SELECTED_SPEAKER_NOT_FOUND:
    'Vald högtalare hittades inte! Vi ändrade din högtalare till "systemstandard" tills du gör en ändring!',
  SELECTED_MICROPHONE_NOT_FOUND:
    'Vald mikrofon hittades inte! Vi ändrade din mikrofon till "systemstandard" tills du gör en ändring!',
  DEFAULT_MICROPHONE: "Standardmikrofon",
  DEFAULT_SPEAKER: "Standardhögtalare",
  BROWSER_DOES_NOT_SUPPORT_SPEAKER_SET: "Din webbläsare stöder inte val av högtalare",
  UPLOAD_RECORDING: "Ladda upp inspelning",
  RESTART: "Starta om",
  RECORD_VOICE: "Spela in röst",
  RECORDING_UPLOAD_SUCCESSFULL: "Uppladdning lyckades",
  RECORDING_UPLOAD_ERROR: "Ett fel uppstod vid uppladdning",
  WE_COULD_NOT_DETECT_SOUND_FROM_MICROPHONE: "Vi kunde inte upptäcka ljud från din mikrofon",
  ECHO_TEST: "Enhetstest",
  START: "Starta",
  START_TEST: "Starta test",
  STOP_TEST: "Stoppa test",
  ECHO_TEST_SOUND_QUESTION: "Hör du ljudet?",
  ECHO_TEST_MICROPHONE_QUESTION: "Hör du ekot?",
  ECHO_TEST_PASSED: "Bra. Din högtalare och mikrofon verkar fungera som förväntat!",
  PLEASE_CONFIRM_FOLLOWING: "Vänligen bekräfta följande",
  PERIOD: "Period",
  TREND: "Trend",
  ADJUST: "Justera",
  TEXT_INDICATOR: "Indikator",
  METRICS: "Mätvärden",
  GROUP_BY: "Gruppera efter",
  SUB_GROUP_BY: "Undergruppera efter",
  SORT_BY: "Sortera efter",
  DESC: "Fallande",
  SPLIT_BY: "Dela efter",
  SUMMARY: "Sammanfattning",
  MAIN_CHANNELS: "Main channels",
  THREAD_CHANNELS: "Thread channels",
  MESSAGE_CHANNELS: "Message channels",
  ORGANIZATION_IDS: "Organizations",
  CONVERSATION_USER_IDS: "Conversation users",
  THREAD_USER_IDS: "Thread users",
  MESSAGE_USER_IDS: "Message users",
  CONVERSATION_QUEUE_IDS: "Conversation queues",
  THREAD_QUEUE_IDS: "Thread queues",
  MESSAGE_QUEUE_IDS: "Message queues",
  DISPOSITION_IDS: "Dispositions",
  SUB_DISPOSITION_IDS: "Sub dispositions",
  THRD_DISPOSITION_IDS: "Third dispositions",
  PAUSE_IDS: "Pauser",
  TEAM_IDS: "Team",
  THREAD_TEAM_IDS: "Thread teams",
  MESSAGE_TEAM_IDS: "Meddelandeteam",
  MAIL_ACCOUNT_IDS: "E-postkonton",
  VOICE_ROUTE_IDS: "Röstrutter",
  UI: "Gränssnitt",
  TABLE: "Tabell",
  GROUP: "Grupp",
  ODD_ROW: "Udda rad",
  EVEN_ROW: "Jämn rad",
  WIDTH: "Bredd",
  COLOR: "Färg",
  BORDER: "Kant",
  DISPLAY_HEADER: "Visa rubrik",
  LEGEND: "Teckenförklaring",
  PLACEMENT: "Placering",
  CUTOUT: "Utskärning",
  NESTED_METRICS: "Mätvärden",
  SUBTRACT: "Subtrahera",
  METRIC: "Mätvärde",
  VALUE_ARR: "Alternativ",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DATA: "Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFDATA: "Skillnad",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFPERCENTAGE: "Procent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROW: "Pil",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDATA: "Pil + Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFDATA: "Pil + Skillnad",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFPERCENTAGE: "Pil + Procent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICON: "Ikon",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDATA: "Ikon + Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFDATA: "Ikon + Skillnad",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFPERCENTAGE: "Ikon + Procent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_BACKGROUND: "Bakgrund",
  STATS_WIDGET_TYPE_TABLE: "Tabell",
  STATS_WIDGET_TYPE_GRID: "Rutnät",
  STATS_WIDGET_TYPE_BAR: "Stapel",
  STATS_WIDGET_TYPE_LINE: "Linje",
  STATS_WIDGET_TYPE_TEXT: "Text",
  STATS_WIDGET_TYPE_PIE: "Cirkel",
  STATS_WIDGET_TYPE_RADAR: "Radar",
  STATS_WIDGET_TYPE_POLAR: "Polär",
  STATS_WIDGET_TYPE_TIMELINE: "Tidslinje",
  STATS_WIDGET_PERIOD_THIS_MONTH: "Denna månad",
  STATS_WIDGET_PERIOD_LIVE_TODAY: "Idag (Live)",
  STATS_WIDGET_PERIOD_TODAY: "Idag",
  STATS_WIDGET_PERIOD_LAST_24_HOURS: "Senaste 24 timmarna",
  STATS_WIDGET_PERIOD_YESTERDAY: "Igår",
  STATS_WIDGET_PERIOD_THIS_WEEK: "Denna vecka",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN: "Denna vecka (start söndag)",
  STATS_WIDGET_PERIOD_THIS_WEEK_EXCEPT_TODAY: "Denna vecka (förutom idag)",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN_EXCEPT_TODAY: "Denna vecka (start söndag, förutom idag)",
  STATS_WIDGET_PERIOD_LAST_WEEK: "Förra veckan",
  STATS_WIDGET_PERIOD_LAST_7_DAYS: "Senaste 7 dagarna",
  STATS_WIDGET_PERIOD_THIS_MONTH_EXCEPT_TODAY: "Denna månad (förutom idag)",
  STATS_WIDGET_PERIOD_LAST_30_DAYS: "Senaste 30 dagarna",
  STATS_WIDGET_PERIOD_LAST_MONTH: "Förra månaden",
  STATS_WIDGET_PERIOD_CUSTOM: "Anpassad",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_DAY: "Dag(ar)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_WEEK: "Vecka(or)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_MONTH: "Månad(er)",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GT: "Större än",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GTE: "Större än eller lika med",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LT: "Mindre än",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LTE: "Mindre än eller lika med",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NOT_EQUAL_NUM: "Inte lika med",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_EQUAL_NUM: "Lika med",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_ANY_OF_NUM: "Något av valda alternativ",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NONE_OF_NUM: "Inget av valda alternativ",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT: "Större än",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT_TREND: "Större än trendvärde",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE: "Större än eller lika med",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE_TREND: "Större än eller lika med trendvärde",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT: "Mindre än",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT_TREND: "Mindre än trendvärde",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE: "Mindre än eller lika med",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE_TREND: "Mindre än eller lika med trendvärde",
  STATS_WIDGET_THRESHOLD_COMPARISON_NOT_EQUAL_NUM: "Inte lika med",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM: "Lika med",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM_TREND: "Lika med trendvärde",
  STATS_WIDGET_THRESHOLD_COMPARISON_ANY_OF_NUM: "Något av valda alternativ",
  STATS_WIDGET_THRESHOLD_COMPARISON_NONE_OF_NUM: "Inget av valda alternativ",
  METRIC_FORMAT_STATIC: "Statiskt värde",
  METRIC_FORMAT_ValuePCS: "St (Stycken)",
  METRIC_FORMAT_ValuePercent: "% (Procent)",
  METRIC_FORMAT_ValueDecimal: "Decimal",
  METRIC_FORMAT_SECONDS_TO_HH_MM: "TT:mm",
  METRIC_FORMAT_HH_MM: "TT:mm",
  METRIC_FORMAT_SECONDS_TO_HH_MM_SS: "TT:mm:ss",
  METRIC_FORMAT_SECONDS_TO_MM_SS: "mm:ss",
  METRIC_FORMAT_HH_MM_SS: "TT:mm:ss",
  METRIC_FORMAT_COUNTDOWN: "Räknare",
  METRIC_FORMAT_MM_SS: "mm:ss",
  METRIC_FORMAT_YEAR_MONTH_SHORT: "År Månad (kort)",
  METRIC_FORMAT_YEAR_MONTH_LONG: "År Månad",
  METRIC_FORMAT_MONTH_SHORT: "Månad (kort)",
  METRIC_FORMAT_MONTH_LONG: "Månad",
  METRIC_FORMAT_YEAR_WEEK_NUMBER: "År Veckonummer",
  METRIC_FORMAT_WEEK_NUMBER: "Veckonummer",
  METRIC_FORMAT_D_SLASH_M: "Dag/Månad",
  METRIC_FORMAT_D_SLASH_M_HH_mm: "Dag/Månad TT:mm",
  METRIC_FORMAT_YYYYMMDD: "ÅÅÅÅMMDD",
  METRIC_FORMAT_YYYY_MM_DD: "ÅÅÅÅ/MM/DD",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm: "ÅÅÅÅ/MM/DD TT:mm",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm_ss: "ÅÅÅÅ/MM/DD TT:mm:ss",
  METRIC_FORMAT_HOUR_OF_DAY_24H: "Timme på dagen (24H)",
  METRIC_FORMAT_HOUR_OF_DAY_12H: "Timme på dagen (12H)",
  METRIC_FORMAT_WEEKDAY_SHORT: "Veckodag (kort)",
  METRIC_FORMAT_WEEKDAY_LONG: "Veckodag",
  METRIC_FORMAT_HH_mm: "TT:mm",
  METRIC_FORMAT_mm_ss: "mm:ss",
  METRIC_FORMAT_HH_mm_ss: "TT:mm:ss",
  W_WEEK_SHORT: "V",
  PM: "e.m.",
  AM: "f.m.",
  MONTH_SHORT_1: "Jan",
  MONTH_SHORT_2: "Feb",
  MONTH_SHORT_3: "Mar",
  MONTH_SHORT_4: "Apr",
  MONTH_SHORT_5: "Maj",
  MONTH_SHORT_6: "Jun",
  MONTH_SHORT_7: "Jul",
  MONTH_SHORT_8: "Aug",
  MONTH_SHORT_9: "Sep",
  MONTH_SHORT_10: "Okt",
  MONTH_SHORT_11: "Nov",
  MONTH_SHORT_12: "Dec",
  MONTH_LONG_1: "Januari",
  MONTH_LONG_2: "Februari",
  MONTH_LONG_3: "Mars",
  MONTH_LONG_4: "April",
  MONTH_LONG_5: "Maj",
  MONTH_LONG_6: "Juni",
  MONTH_LONG_7: "Juli",
  MONTH_LONG_8: "Augusti",
  MONTH_LONG_9: "September",
  MONTH_LONG_10: "Oktober",
  MONTH_LONG_11: "November",
  MONTH_LONG_12: "December",
  WEEKDAY_SHORT_6: "Sön",
  WEEKDAY_SHORT_0: "Mån",
  WEEKDAY_SHORT_1: "Tis",
  WEEKDAY_SHORT_2: "Ons",
  WEEKDAY_SHORT_3: "Tor",
  WEEKDAY_SHORT_4: "Fre",
  WEEKDAY_SHORT_5: "Lör",
  WEEKDAY_LONG_6: "Söndag",
  WEEKDAY_LONG_0: "Måndag",
  WEEKDAY_LONG_1: "Tisdag",
  WEEKDAY_LONG_2: "Onsdag",
  WEEKDAY_LONG_3: "Torsdag",
  WEEKDAY_LONG_4: "Fredag",
  WEEKDAY_LONG_5: "Lördag",
  ONGOING_CALLS: "Pågående samtal",
  RECORDING_NEEDS_TO_BE_UPLOADED: "Se till att ladda upp inspelningen!",
  RECORDING_STARTED: "Inspelning startad",
  RECORDING_STOPPED: "Inspelning stoppad",
  DUE_DATE: "Förfallodatum",
  MAIL_ACCOUNT_NOT_IN_QUEUES:
    "Valt e-postkonto är inte tillgängligt som utgående e-postkonto för aktuell konversationskö",
  NO_EMAIL_ON_CONTACT: "Det finns inga e-postadresser på kontakten att svara till!",
  FORWARD: "Vidarebefordra",
  LEFT_BRIDGE: "Brygga avslutad",
  DESTINATION_PHONE: "Destination {{phone}}",
  PARAMS_PHONE: "Telefon: {{phone}}",
  PARAMS_JOURNEY: "Resa: {{journey}}",
  CALLEE_ANSWERED: "Mottagaren svarade",
  SOCKET_CONNECTION_ERROR: 'Fel vid anslutning till websocket: "{{error}}"',
  CONTACT_FORM_HAS_NO_FIELDS:
    "Kontaktformuläret har inga fält! En administratör måste lägga till fält i kontaktformuläret som är kopplat till den valda kön (Skapa lista)",
  NOTIFICATION_REQUEST_NOT_SUPPORTED:
    "Din webbläsare stöder inte notifieringar, vänligen byt webbläsare!",
  NOTIFICATION_REQUEST_DENIED:
    "Du har nekat webbläsarnotifieringar. Se till att webbläsarnotifieringar är tillåtna!",
  NOTIFICATION_REQUEST_CATCH:
    "Ett fel uppstod vid begäran om webbläsarnotifieringsbehörigheter! {{error}}",
  INACTIVITY_READY: "Logga ut vid redo-status",
  INACTIVITY_PAUSE: "Logga ut vid paus-status",
  PDF: "pdf",
  IMAGE: "bild",
  STATS_WIDGET: "Widget",
  ADVANCED_OPTIONS: "Avancerade alternativ",
  HIDE: "Dölj",
  NO_SPECIFIC_USER: "Ingen användare",
  NO_SPECIFIC_QUEUE: "Ingen kö",
  NO_SPECIFIC_DISPOSITION: "Ingen disposition",
  NO_SPECIFIC_PAUSE: "Ingen paus",
  NO_SPECIFIC_DIRECTION: "Ingen riktning",
  NO_SPECIFIC_CHANNEL: "Ingen kanal",
  NO_SPECIFIC_ORGANIZATION: "Ingen organisation",
  NO_SPECIFIC_CLIENT: "Ingen klient",
  NO_SPECIFIC_ACCOUNT: "Inget konto",
  USER_ID_X: "Användar-id {{x}}",
  QUEUE_ID_X: "Kö-id {{x}}",
  DISPOSITION_ID_X: "Dispositions-id {{x}}",
  ORGANIZATION_ID_X: "Organisations-id {{x}}",
  CLIENT_ID_X: "Klient-id {{x}}",
  ACCOUNT_ID_X: "Konto-id {{x}}",
  PAUSE_ID_X: "Paus-id {{x}}",
  ALIGNMENT: "Justering",
  THRESHOLDS: "Tröskelvärden",
  BOX_COLOR: "Rutfärg",
  FONT_STYLE: "Textstil",
  FORMAT: "Format",
  USER_SELF: "Personifierad data",
  FONT_STYLE_ITALIC: "Kursiv",
  FONT_STYLE_BOLD: "Fet",
  "FONT_STYLE_BOLD-ITALIC": "Fet-kursiv",
  ALIGNMENT_CENTER: "Centrerad",
  ALIGNMENT_LEFT: "Vänster",
  ALIGNMENT_RIGHT: "Höger",
  BLINK: "Blinka",
  DECIMALS: "Decimaler",
  DIRECTION: "Riktning",
  THREAD_DIRECTION: "Trådriktning",
  MESSAGE_DIRECTION: "Meddelanderiktning",
  CONVERSATION_MAIN_CHANNELS: "Konversationens huvudkanaler",
  CONVERSATION_DIRECTION: "Konversationsriktningar",
  EDIT_ITEM: "Redigera objekt",
  OF_TOTAL: "Av totalt",
  GROUP_POPULAR: "Populära",
  GROUP_OTHERS: "Övriga",
  GROUP_CUSTOM: "Anpassade",
  GROUP_LIVE: "Live",
  STATS_CUSTOM_METRICS: "Anpassade mätvärden",
  STATS_CUSTOM_METRIC: "Anpassat mätvärde",
  METRIC_QUERY_TYPE_SUM: "Addition",
  METRIC_QUERY_TYPE_DIFF: "Subtraktion",
  METRIC_QUERY_TYPE_PERCENT: "Procent",
  METRIC_QUERY_TYPE_AVG: "Genomsnitt",
  METRIC_QUERY_TYPE_MAX: "Maximum",
  METRIC_QUERY_TYPE_MIN: "Minimum",
  METRIC_QUERY_TYPE_LEAST: "Minst",
  METRIC_QUERY_TYPE_GREATEST: "Störst",
  METRIC_QUERY_TYPE_STATIC: "Statisk",
  METRIC_QUERY_TYPE_ADD: "Addition",
  QUERY_TYPE: "Frågetyp",
  DASHBOARDS: "Dashboards",
  DASHBOARD: "Dashboard",
  REPORTS: "Rapporter",
  REPORT: "Rapport",
  CREATE_DASHBOARD: "Skapa dashboard",
  CREATE_REPORT: "Skapa rapport",
  EDIT_DASHBOARD: "Redigera dashboard",
  EDIT_REPORT: "Redigera rapport",
  ALL_DASHBOARDS: "Alla dashboards",
  SHARED_WITH_ME: "Delat med mig",
  PREBUILD_DASHBOARDS: "Förbyggda dashboards",
  ALL_REPORTS: "Alla rapporter",
  PREBUILD_REPORTS: "Förbyggda rapporter",
  ADD_YOUR_FIRST_DASHBOARD: "Lägg till din första dashboard",
  ADD_YOUR_FIRST_DASHBOARD_DESCRIPTION:
    "Börja med en ny tom yta eller välj en av våra förbyggda dashboards",
  NO_SHARED_DASHBOARDS: "Inga delade dashboards",
  ADD_YOUR_PREBUILT_DASHBOARD: "Lägg till förbyggd dashboard",
  ADD_YOUR_PREBUILT_DASHBOARD_DESCRIPTION: "Skapa din egen dashboard från en förbyggd dashboard",
  ADD_YOUR_FIRST_REPORT: "Lägg till din första rapport",
  ADD_YOUR_FIRST_REPORT_DESCRIPTION:
    "Börja med en ny tom yta eller välj en av våra förbyggda rapporter",
  NO_SHARED_REPORTS: "Inga delade rapporter",
  ADD_YOUR_PREBUILT_REPORT: "Lägg till förbyggd rapport",
  ADD_YOUR_PREBUILT_REPORT_DESCRIPTION: "Skapa din egen rapport från en förbyggd rapport",
  NO_DASHBOARDS: "Inga dashboards",
  NO_REPORTS: "Inga rapporter",
  CREATE_FROM_THIS_TEMPLATE: "Skapa från denna mall",
  TIME_THRESHOLD: "Tidströskel",
  AFTER: "Efter",
  CUSTOM_METRIC: "Anpassat mätvärde",
  STATS_CUSTOM_METRIC_ID: "Anpassat mätvärde",
  HEIGHT: "Höjd",
  STATS_USER_DATA_HELP: "Personifierad data visar personifierade värden för detta mätvärde.",
  STATS_OF_TOTAL_HELP: "Procent av detta mätvärde, jämfört med det totala värdet.",
  STATS_REPORTS: "Rapporter & dashboards",
  STATS_WIDGETS: "Widgets",
  OWNER: "Ägare",
  IS_DASHBOARD: "Är dashboard",
  STATS_REPORT: "Rapport",
  ADD_WIDGET: "Lägg till widget",
  FULLSCREEN: "Helskärm",
  CLONED: "Klonad",
  STATS_WIDGET_LEGEND_PLACEMENT_LEFT: "Vänster",
  STATS_WIDGET_LEGEND_PLACEMENT_RIGHT: "Höger",
  STATS_WIDGET_LEGEND_PLACEMENT_TOP: "Topp",
  STATS_WIDGET_LEGEND_PLACEMENT_BOTTOM: "Botten",
  CIRCLE_TOTAL: "Cirkel totalt",
  TOTAL: "Totalt",
  BORDER_COLOR: "Kantfärg",
  LAST_DATA_AT: "Senaste data vid",
  HIDE_TITLE: "Dölj titel",
  OPEN_DASHBOARD: "Öppna Dashboard",
  ADD_PREVIEW_WIDGET: "Lägg till förhandsgranskningswidget",
  SELECT_REPORT: "Välj rapport",
  DEFAULT: "Standard",
  CLONE: "Klona",
  DRAG_n_MOVE: "Dra & Flytta",
  DOWN: "Ner",
  UP: "Upp",
  EXISTING: "Befintlig",
  SELECT_WIDGET: "Välj widget",
  ZOOM_IN: "Zooma in",
  ZOOM_OUT: "Zooma ut",
  RELOAD: "Ladda om",
  RELOAD_DATA: "Ladda om data",
  DISPLAY_VALUES: "Visa värden",
  WIDGET_NOT_FOUND: "Widget hittades inte!",
  WIDGET_NOT_FOUND_DESCRIPTION: "Widget har tagits bort eller delas inte längre med dig!",
  NO_WIDGETS: "Inga widgets!",
  ADD_WIDGET_TO_REPORT: "Lägg till widget i rapport",
  ADD_WIDGET_TO_DASHBOARD: "Lägg till widget i Dashboard",
  REMOVE: "Ta bort",
  REMOVE_ITEM: "Ta bort objekt",
  ADDITION: "Addition",
  SUBTRACTION: "Subtraktion",
  DIVISION: "Division",
  CHANNELS_SETTINGS: "Kanaler från inställningar",
  DIRECTION_SETTINGS: "Riktning från inställningar",
  THREAD_CHANNELS_SETTINGS: "Trådkanaler från inställningar",
  MESSAGE_CHANNELS_SETTINGS: "Meddelandekanaler från inställningar",
  THREAD_DIRECTION_SETTINGS: "Trådriktning från inställningar",
  MESSAGE_DIRECTION_SETTINGS: "Meddelanderiktning från inställningar",
  SELECT_EXISTING: "Välj befintlig",
  EXISTING_WIDGETS: "Befintliga widgets",
  NEW_WIDGET: "Ny widget",
  TEMPLATES: "Mallar",
  SELECT_TEMPLATE: "Välj mall",
  USE_TEMPLATE: "Använd mall",
  SEARCH_TEMPLATES: "Sök mallar",
  SEARCH_WIDGETS: "Sök widgets",
  WIDGET_TEMPLATES: "Widget-mallar",
  WIDGET: "Widget",
  EDIT_WIDGET: "Redigera widget",
  SELECT_DASHBOARD: "Välj dashboard",
  REPORT_NOT_SELECTED: "Rapport ej vald!",
  DASHBOARD_NOT_SELECTED: "dashboard ej vald!",
  TREND_VALUE: "Trendvärde",
  TREND_PERIOD: "Trendperiod",
  MY_CONVERSATIONS: "Mina konversationer",
  MY_AND_UNASSIGNED_CONVERSATIONS: "Mina & otilldelade konversationer",
  CUSTOMER_RESPONDED: "Kund svarade",
  NO_UPDATE_IN_7_DAYS: "Ingen uppdatering på 7 dagar",
  CREATED_BY_ME: "Skapad av mig",
  ALL_UNRESOLVED: "Alla olösta",
  ALL_RESOLVED: "Alla lösta",
  ALL_CONVERSATIONS: "Alla konversationer",
  DIRECTIONS: "Riktningar",
  INTERNAL_HOST: "Intern värd",
  VOICE_ASTERISK_DOWNGRADE_ID: "Nedgradera asterisk",
  VOICE_ASTERISK_DOWNGRADES: "Asterisk-nedgraderingar",
  VOICE_ASTERISK_DOWNGRADE: "Asterisk-nedgradering",
  DOWNGRADED: "Nedgraderad",
  __IS_DOWNGRADED: "Är nedgraderad",
  VOICE_ASTERISK_OPERATIONAL_HOURS: "Asterisk drifttimmar",
  VOICE_ASTERISK_OPERATIONAL_HOURSES: "Asterisk drifttimmar",
  UTC: "UTC",
  VOICE_ASTERISK_TOGGLE_HISTORY: "Asterisk växlingshistorik",
  VOICE_ASTERISK_TOGGLE_HISTORIES: "Asterisk växlingshistorik",
  INITIAL: "Initial",
  DID_TOGGLE: "Växlade",
  DATE: "Datum",
  X_ONGOING_CALLS: "{{calls}} pågående samtal",
  X_USER_STATUSES: "{{users}} anslutna användare",
  PREVENTED_ASTERISK_TOGGLE: "förhindrade asterisk från att växla",
  BOOTING_UP: "Startar upp",
  DOWNGRADE_ASTERISK_OFFLINE: "Nedgradera asterisk offline",
  ASTERISK_STATUS_OFFLINE: "Offline",
  ASTERISK_STATUS_UPANDRUNNING: "Igång",
  ASTERISK_STATUS_TERMINATED: "Avslutad",
  ASTERISK_STATUS_BOOTING: "Startar",
  ASTERISK_STATUS_SYNCING: "Synkroniserar",
  RESYNC: "Omsynkronisera",
  TOGGLE_FAILED: "Växling misslyckades (troligen OPM-begäran)",
  CURRENCY: "Valuta",
  INVOICE_CURRENCY_EUR: "EUR",
  INVOICE_CURRENCY_USD: "USD",
  INVOICE_CURRENCY_SEK: "SEK",
  INVOICE_POST_ADDON: "Faktura",
  INVOICE_POST_LICENSE: "Licens",
  INVOICE_POST_USAGE: "Röstanvändning",
  INVOICE_POST_ALL_LICENSE: "Alla kanaler licens",
  TARIFF_ID: "Taxa",
  VOICE_TARIFF_LIST: "Rösttaxelista",
  PHONENUMBER_MONTHLY_COST_ID: "Telefonnummer månadskostnad",
  PHONENUMBER_MONTHLY_COST_LIST: "Telefonnummer månadskostnadslista",
  PHONENUMBER_SETUP_COST_ID: "Telefonnummer uppstartskostnad",
  PHONENUMBER_SETUP_COST_LIST: "Telefonnummer uppstartskostnadslista",
  INVOICE_POST_VOICE_TARIFF: "Rösttaxa",
  INVOICE_POST_VOICE_PHONENUMBER_MONTHLY_COST: "Telefonnummer månadskostnad",
  INVOICE_POST_VOICE_PHONENUMBER_SETUP_COST: "Telefonnummer uppstartskostnad",
  INVOICE_POST_VOICE_LICENSE: "Röstlicens",
  INVOICE_POST_VOICE_USAGE: "Röstanvändning",
  INVOICE_POST_EMAIL_USAGE: "E-postanvändning",
  INVOICE_POST_EMAIL_LICENSE: "E-postlicens",
  INVOICE_POST_FORM_USAGE: "Formuläranvändning",
  INVOICE_POST_VOICE_NUMBERS_MONTHLY: "Rösttelefonnummer månadskostnader",
  INVOICE_POST_VOICE_NUMBERS_SETUP: "Rösttelefonnummer uppstartskostnader",
  INVOICE_POST_VOICE_RECORDING: "Röstinspelning",
  INVOICE_POST_VOICE_RECORDING_STORAGE: "Röstinspelningslagring",
  CUSTOMER_IDENTIFICATION_NUMBER: "Kundidentifikationsnummer",
  INVOICE_PERIOD_MONTHLY: "Månadsvis",
  INVOICE_PERIOD_EVERY2NDMONTH: "Varannan månad",
  INVOICE_PERIOD_QUARTERLY: "Kvartalsvis",
  INVOICE_PERIOD_HALFYEARLY: "Halvårsvis",
  INVOICE_PERIOD_ANNUALLY: "Årsvis",
  AMOUNT_TYPE: "Typ",
  AMOUNT_PERCENT: "Procent",
  AMOUNT_CURRENCY: "Belopp",
  INVOICE_POST_AMOUNT_TYPE_PERCENT: "Procent",
  INVOICE_POST_AMOUNT_TYPE_CURRENCY: "Belopp",
  INVOICE: "Faktura",
  INVOICES: "Fakturor",
  AMOUNT: "Belopp",
  LOCKED_AT: "Låst",
  PAID_AT: "Betald",
  PERIOD_START: "Periodstart",
  PERIOD_END: "Periodslut",
  INVOICE_POST: "Fakturapost",
  INVOICE_POSTS: "Fakturaposter",
  RE_PROCESS: "Ombearbeta",
  EXCLUDE: "Exkludera",
  INVOICE_ID: "Faktura",
  TARIFF: "Taxa",
  TARIFFS: "Taxor",
  TARIFF_PARENT: "Taxaförälder",
  TARIFF_PARENTS: "Taxaföräldrar",
  OUTBOUND_TARIFFS: "Utgående taxor",
  INBOUND_TARIFF: "Inkommande taxa",
  INTERNAL_TARIFF: "Intern taxa",
  OUTBOUND_ROUNDUP_STRATEGY: "Utgående avrundningsstrategi",
  INBOUND_ROUNDUP_STRATEGY: "Inkommande avrundningsstrategi",
  INTERNAL_ROUNDUP_STRATEGY: "Intern avrundningsstrategi",
  TARIFF_PARENT_ID: "Rösttaxaförälder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_NOROUNDUP: "Ingen avrundning",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST60SECONDS: "Närmaste 60 sekunder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST30SECONDS: "Närmaste 30 sekunder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST10SECONDS: "Närmaste 10 sekunder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEIL15TO60: "Avrunda 15-60 till 60 sekunder",
  PHONENUMBER_MONTHLY_COST_PARENT_ID: "Telefonnummer månadskostnadsförälder",
  PHONENUMBER_MONTHLY_COST: "Telefonnummer månadskostnad",
  PHONENUMBER_MONTHLY_COSTS: "Telefonnummer månadskostnader",
  PHONENUMBER_MONTHLY_COST_PARENT: "Telefonnummer månadskostnadsförälder",
  PHONENUMBER_MONTHLY_COST_PARENTS: "Telefonnummer månadskostnadsföräldrar",
  PHONENUMBER_SETUP_COST_PARENT_ID: "Telefonnummer uppstartskostnadsförälder",
  PHONENUMBER_SETUP_COST: "Telefonnummer uppstartskostnad",
  PHONENUMBER_SETUP_COSTS: "Telefonnummer uppstartskostnader",
  PHONENUMBER_SETUP_COST_PARENT: "Telefonnummer uppstartskostnadsförälder",
  PHONENUMBER_SETUP_COST_PARENTS: "Telefonnummer uppstartskostnadsföräldrar",
  INVOICE_START: "Faktureringsstart",
  INVOICE_END: "Faktureringsslut",
  ADDONS: "Tillägg",
  PROCESSED_AT: "Bearbetad",
  INVOICE_PRINT: "Faktura",
  INVOICE_PDF: "Pdf",
  MOBILE: "Mobil",
  FIXED_LINE: "Fast linje",
  SHARED_COST: "Delad kostnad",
  TOLL_FREE: "Avgiftsfritt",
  DUE_PERIOD: "Förfalloperiod",
  COUNT: "Antal",
  INVOICE_DUE_PERIOD_MONTH: "Månad",
  INVOICE_DUE_PERIOD_DAY: "Dag",
  COMPANY_NAME: "Företagsnamn",
  COMPANY_ADDRESS: "Företagsadress",
  COUNTRY: "Land",
  CITY: "Stad",
  POSTAL_CODE: "Postnummer",
  ADDRESS_ROW1: "Adressrad 1",
  ADDRESS_ROW2: "Adressrad 2",
  MARK_AS_PAID: "Markera som betald",
  MARK_AS_UNPAID: "Markera som obetald",
  INVOICE_DATE: "Fakturadatum",
  AMOUNT_TO_PAY: "Belopp att betala",
  INVOICE_PERIOD: "Fakturaperiod",
  INBOUND: "Inkommande",
  SUB_TOTAL: "Delsumma",
  VAT: "Moms",
  PCS: "st",
  COMPANY_EMAILS: "Företagets e-postadresser",
  PAYMENT_OPTION: "Betalningsalternativ",
  INVOICE_PAYMENT_OPTION_BANKTRANSFER: "Banköverföring",
  INVOICE_PAYMENT_OPTION_CREDITCARD: "Kreditkort",
  LICENSE_COST: "Licenskostnad",
  FORM_MODULE_COST: "Formulärmodulkostnad",
  REPORTING: "Rapportering",
  SURCHARGE: "Tilläggsavgift",
  PREPAY: "Förskottsbetalning",
  COST: "Kostnad",
  INVOICE_POST_AMOUNT_TYPE_MINDEB: "Minimidebitering",
  FINANCE: "Ekonomi",
  PREPAID_CREDIT: "Förskottskredit",
  PREPAID_CREDITS: "Förskottskrediter",
  VALID_FROM: "Giltig från",
  VALID_UNTIL: "Giltig till",
  CREDIT_LEFT: "Kvarvarande kredit",
  OUTBOUND_CALL_RESTRICTED_DESCRIPTION:
    "Utgående samtal till destinationstelefonen begränsades av systemet! Kontakta din systemadministratör för åtkomst!",
  OUTBOUND_CALL_NEGATIVE_BALANCE_DESCRIPTION:
    "Utgående samtal har avslutats på grund av negativt saldo på din organisation!",
  OUTBOUND_CALL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Utgående samtal har avslutats på grund av inaktivitet i organisationen!",
  OUTBOUND_CALL_TOLL_FREE_NOT_SAME_ORIGIN:
    "Utgående samtal till det avgiftsfria destinationsnumret har avslutats eftersom uppringarens och mottagarens ursprungsland måste vara samma!",
  OUTBOUND_EMAIL_NEGATIVE_BALANCE_DESCRIPTION:
    "Utgående e-post har avslutats på grund av negativt saldo på din organisation!",
  OUTBOUND_EMAIL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Utgående e-post har avslutats på grund av inaktivitet i organisationen!",
  DESTINATION_RESTRICTOR: "Destinationsbegränsare",
  DESTINATION_RESTRICTORS: "Destinationsbegränsare",
  DESTINATIONS: "Destinationer",
  STATS_LICENSE_TYPE_ENTRY: "Entry",
  STATS_LICENSE_TYPE_STANDARD: "Standard",
  STATS_LICENSE_TYPE_ENTERPRISE: "Enterprise",
  ALL_CHANNELS: "Alla kanaler",
  VOICE_TARIFF_ROUNDUP_STRATEGY: "Rösttaxa avrundningsstrategi",
  VOICE_OUTBOUND_USAGE: "Röst utgående användning",
  VOICE_USAGE_ROUNDUP_STRATEGY: "Röstanvändning avrundningsstrategi",
  VOICE_INBOUND_USAGE: "Röst inkommande användning",
  VOICE_INTERNAL_USAGE: "Röst intern användning",
  EMAIL_INBOUND_USAGE: "E-post inkommande användning",
  EMAIL_OUTBOUND_USAGE: "E-post utgående användning",
  FORM_INBOUND_USAGE: "Formulär inkommande användning",
  NEGATIVE_BALANCE_LOCK: "Negativt saldo lås",
  PREPAID: "Förskottsbetalning",
  NONE_HANDLED_INVOICE_CANNOT_BE_LOCKED: "Ej hanterad faktura kan inte låsas!",
  LOCKED_INVOICES_MAY_NOT_BE_PATCHED: "Låst faktura kan inte ändras!",
  CREDIT_USED: "Använd kredit",
  INVOICE__DATA__COMPANY_EMAILS: "Fakturamottagarens e-postadresser",
  DASHBOARD_LICENSE: "Dashboardlicens",
  REPORTING_LICENSE: "Rapporteringslicens",
  DASHBOARD_LICENSE_TYPE: "Dashboardlicenstyp",
  REPORTING_LICENSE_TYPE: "Rapporteringslicenstyp",
  STANDARD: "Standard",
  ENTERPRISE: "Enterprise",
  TWINNING: "Twinning",
  ON: "På",
  QUEUE_ON: "Kösamtal",
  DIRECT_ON: "Direktsamtal",
  TWINNED: "Twinnad",
  TWINNED_TO: "Twinnad till",
  RE_PROCESS_TARIFFS: "Ombearbeta taxor",
  RE_PROCESS_TARIFFS_NO_RESET_TARIFF_ID: "Ombearbeta taxakostnader utan att återställa taxalistan",
  RE_PROCESS_TARIFFS_WITH_RESET_TARIFF_ID: "Ombearbeta taxakostnader och återställ taxalistan",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTRY: "Entry",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_STANDARD: "Standard",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTERPRISE: "Enterprise",
  INVOICE_NUMBER: "Fakturanr.",
  CUSTOMER_NUMBER: "Kundnr.",
  SALESPERSON: "Säljare",
  YOUR_REFERENCE: "Er referens",
  PAYMENT_TERMS: "Betalningsvillkor",
  VAT_REGISTRATION_NO: "Momsregistreringsnr.",
  FIN_CHARGE_TERMS: "Dröjsmålsränta",
  INVOICE_ROUNDING: "Fakturaavrundning",
  COMPANY_REFERENCE: "Företagsreferens",
  INTERNET: "Internet",
  E_MAIL: "E-post",
  CORPORATE_IDENTITY_NO: "Organisationsnummer",
  BANKGIRO: "Bankgiro",
  BIC_SWIFT: "BIC/SWIFT",
  PAYMENT_INFO_TEMPLATE: "Betalningsinformationsmall",
  HANDELSBANKEN_BG_SEK: "Handelsbanken bankgiro SEK",
  HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  WIZE_EUR: "Wize EUR",
  NET_AMOUNT: "Nettobelopp",
  SUBTOTAL: "Delsumma",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_BG_SEK: "Handelsbanken Bankgiro SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  INVOICE_PAYMENT_INFO_TEMPLATE_WIZE_EUR: "Wize EUR",
  QUEUE_NOT_FOUND: "Kö hittades inte",
  CONTACT_NOT_FOUND: "Kontakt hittades inte",
  SEND_INVOICE: "Skicka faktura",
  GO_TO_CONVERSATION: "Gå till konversation",
  MISSING_ORGANIZATION_INVOICE_DATA: "Saknar organisationens fakturadata",
  NO_ORGANIZATION_INVOICE_EMAILS:
    "Inga e-postadresser hittades på organisationen för att skapa konversation!",
  INVOICE_ALREADY_LOCKED: "Fakturan är redan låst",
  LOCK_INVOICE: "Lås faktura",
  REGULATION_LOCAL_ADDRESS_PROOF_REQUIRED:
    "Vid köp av telefonnummer, var medveten om att vissa regioner kan kräva att du tillhandahåller bevis på en giltig gatuadress inom det angivna området eller stadsdomänen. Detta kan innebära att skicka in dokument som en nylig faktura eller företagscertifikat som visar motsvarande adress för att säkerställa efterlevnad av lokala bestämmelser.",
  REGULATION_WORLDWIDE_ADDRESS_PROOF_REQUIRED:
    "Vid köp av telefonnummer, var medveten om att denna region kommer att kräva att du tillhandahåller bevis på en giltig gatuadress. Detta kan innebära att skicka in dokument som en nylig faktura eller företagscertifikat som visar motsvarande adress för att säkerställa efterlevnad av lokala bestämmelser.",
  REGULATION_NATIONAL_ADDRESS_PROOF_REQUIRED:
    "Vid köp av telefonnummer, var medveten om att denna region kommer att kräva att du tillhandahåller bevis på en giltig gatuadress inom det angivna landet. Detta kan innebära att skicka in dokument som en nylig faktura eller företagscertifikat som visar motsvarande adress för att säkerställa efterlevnad av lokala bestämmelser.",
  PURCHASE: "Köp",
  SETUP_COST: "Uppstartskostnad",
  MONTHLY_COST: "Månadskostnad",
  REGULATIONS: "Bestämmelser",
  PHONENUMBER_PURCHASE_STATUS_ERROR: "Fel",
  PHONENUMBER_PURCHASE_STATUS_WARNING: "Varning",
  PHONENUMBER_PURCHASE_STATUS_PARTIAL: "Delvis",
  PHONENUMBER_PURCHASE_STATUS_SUCCESS: "Lyckades",
  PHONENUMBER_PURCHASE: "Telefonnummerköp",
  PHONENUMBER_PURCHASES: "Telefonnummerköp",
  PHONENUMBER_PREFIX: "Telefonnummer DID-grupp",
  PHONENUMBER_PREFIXES: "Telefonnummer DID-grupper",
  PHONENUMBER_DID_TYPE_FIXED_LINE: "Fast linje",
  PHONENUMBER_DID_TYPE_MOBILE: "Mobil",
  PHONENUMBER_DID_TYPE_SHARED_COST: "Delad kostnad",
  PHONENUMBER_DID_TYPE_TOLL_FREE: "Avgiftsfritt",
  ALPHA2: "Alpha-2",
  AREA_NAME: "Områdesnamn",
  AREA_CODE: "Riktnummer",
  STOCK: "Lager",
  PHONENUMBER_STOCK: "Telefonnummerlager",
  PHONENUMBER_STOCKS: "Telefonnummerlager",
  BULK_ADD_PHONENUMBERS: "Masslägg till telefonnummer",
  BULK_ADD_PHONENUMBERSTOCK: "Masslägg till telefonnummer till connectel-lager",
  NUMBER_RANGE_START: "Nummerintervall start",
  NUMBER_RANGE_END: "Nummerintervall slut",
  LIST_PHONENUMBERS: "Lista telefonnummer",
  ADD_PHONENUMBERS: "Lägg till telefonnummer",
  VOICE_ROUTE_NUMBER_MAY_NOT_BE_CHANGED: "Nummer får inte ändras!",
  EMERGENCY: "Nödläge",
  ENABLED: "Aktiverad",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_VOXBONE: "Voxbone",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_CONNECTEL: "Connectel",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_THIRD_PARTY: "Tredje part",
  PROVIDER: "Leverantör",
  TERMINATE: "Avsluta",
  TERMINATION: "Avslutning",
  TERMINATED_AT: "Avslutad",
  TERMINATED_BY: "Avslutad av",
  TERMINATES_AT: "Avslutas",
  USER_NOT_FOUND: "Användare-id ({{user_id}}) hittades inte!",
  TERMINATE_WENT_WRONG: "Något gick fel vid avslutning av telefonnummer!",
  VOXBONE_NUMBER_NOT_CANCELLED: "Telefonnumret kunde inte avbrytas!",
  VOXBONE_DID_ID_NOT_FOUND:
    "Leverantören kan inte ändras till Voxbone eftersom numret inte finns i Voxbones databas.",
  VOICE_RECORDING: "Röstinspelning",
  VOICE_RECORDING_STORAGE: "Röstinspelningslagring",
  VOICEMAIL_MESSAGE: "Röstmeddelande",
  VOICE_CALL_RECORDING: "Samtalsinspelning",
  ADD_CUSTOM_INVOICE_POST: "Lägg till anpassad fakturapost",
  BY_PREFIX: "Efter prefix",
  PREFIX: "Prefix",
  DESTINATION_RESTRICTOR_CONFIRM:
    "Aktivering av ytterligare samtalsdestinationer kan leda till oavsiktliga avgifter och ökade kostnader, eftersom samtal till tidigare blockerade länder kommer att tillåtas. Se till att du är medveten om de potentiella ekonomiska konsekvenserna innan du aktiverar nya destinationer.",
  DISPOSITION_MAY_NOT_HAVE_MORE_THAN_3_LEVELS: "Klassificering får inte ha mer än 3 nivåer!",
  TO_ME: "Till mig",
  ASSIGN_CONVERSATION_TO_ME: "Tilldela konversation till mig",
  VOICE_DEVICE_AND_TWINNING_SETTINGS: "Röstenhets- & twinninginställningar",
  VOICE_NOTIFICATION_SOUND: "Röstnotifieringsljud",
  VOICE_NOTIFICATION_MICROPHONE: "Röstmikrofon på/av",
  NO_USER: "Ingen användare",
  NO_DISPOSITION: "Ingen klassificering",
  NO_SUB_DISPOSITION: "Ingen underklassificering",
  NO_THRD_DISPOSITION: "Ingen tredjeklassificering",
  NOT_REQUIRED: "Ej obligatoriskt",
  RESOLVE_IN: "Lös inom",
  REPLY_AT_LATEST: "Svara senast",
  RESOLVE_AT_LATEST: "Lös senast",
  CHANGELOG: "Ändringslogg",
  SWITCH_TO_DARK_THEME: "Byt till mörkt tema",
  SWITCH_TO_LIGHT_THEME: "Byt till ljust tema",
  DISPLAY_CHANGELOG_HISTORY: "Visa ändringsloggshistorik",
  IMPROVEMENT: "Förbättring",
  BUG_FIX: "Buggfix",
  SELECT_ALL: "Markera alla",
  NAV_CUSTOMER_ID: "Kund navision-id",
  CREATE_NEW_JOURNEY_DRAFT: "Skapa nytt reseflödesutkast",
  SHOW_JOURNEY_DRAFTS: "Visa reseflödesutkast",
  DROP_TO_ATTACH_FILE: "Släpp för att bifoga fil",
  NO_PREVIEW_AVAILABLE: "Ingen förhandsgranskning tillgänglig för denna fil!",
  REPLY_TO_CC: "E-post kopia",
  REPLY_TO_BCC: "E-post hemlig kopia",
  LEAVE_EMPTY_FOR_ALL_DAY_EVERYDAY: "Lämna tomt för hela dagen, varje dag",
  RELEASE: "Release",
  RELEASES: "Releases",
  VERSION: "version",
  EXECUTION_AT: "Körning vid",
  EXECUTED_AT: "Kördes vid",
  FULLFILLED: "Uppfylld",
  OUTPUT: "Utdata",
  CLICK_TO_PRINT_TO_CONSOLE: "Klicka för att skriva ut till konsolen",
  PRE_QUEUE_TIME_LONG: "Reseflödestid (tid i reseflödet innan kö eller avslut)",
  AUDIT: "Granskning",
  AUDITS: "Granskningar",
  SYS_USER: "System",
  AUDIT_ACTION_READ: "Läs",
  AUDIT_ACTION_UPDATE: "Uppdatera",
  AUDIT_ACTION_CREATE: "Skapa",
  AUDIT_ACTION_DELETE: "Ta bort",
  AUDIT_ACTION_SOFTDELETE: "Mjuk borttagning",
  AUDIT_SEVERITY_L: "Låg",
  AUDIT_SEVERITY_M: "Medel",
  AUDIT_SEVERITY_H: "Hög",
  ACTION: "Åtgärd",
  SEVERITY: "Allvarlighetsgrad",
  CHANGED_PROPS: "Ändrade egenskaper",
  EMPTY: "Tom",
  MAIL_STATUS: "E-poststatus",
  VOICE_STATUS: "Röststatus",
  UnAssign: "Ta bort tilldelning",
  READONLY_UI: "Skrivskyddad (UI)",
  VOICE_RINGTONE: "Röstsignal",
  NOTIFICATION_RINGTONE: "Aviseringssignal",
  MAX_FILESIZE: "Maximal filstorlek",
  MIMETYPES: "MIME-typer",
  API_KEY: "API-nyckel",
  API_KEYS: "API-nycklar",
  COPY_API_KEY_TO_CLIPBOARD: "Kopiera API-nyckel till urklipp",
  CREATED_BY: "Skapad av",
  CONTACT_CONDITION: "Kontaktvillkor",
  DATA_KEY: "Fältalias",
  COMPARISON: "Jämförelse",
  DISPLAY_QUEUE_CALLER_ID: "Visa könummer",
  MERGE_CONTACT_IF_EXISTS: "Sammanfoga kontaktdata om den finns",
  CUSTOM_DATA: "Anpassad data",
  OVERRIDES_CURRENT: "Åsidosätter nuvarande värde",
  MERGES_AND_REMOVES_DUPLICATED: "Sammanfogar och tar bort dubbletter",
  MERGEANDKEEPEXISTING: "Sammanfoga och behåll befintlig",
  MERGEANDOVERRIDE: "Sammanfoga och åsidosätt befintlig",
  CONVERSATION_OWNER_ONLY: "Endast konversationsagenters flikar",
  INACTIVE_TABS_HIDDEN: "Inaktiva flikar har dolts",
  INACTIVE_TABS_DESCRIPTION:
    "Se till att du inte har mer än 7 flikar öppna genom att stänga dina synliga flikar en efter en!",
  VIEW_EVENT: "Visa händelse",
  EVENT_ORDER: "Ordning",
  CLIENT: "Klient",
  CLIENTS: "Klienter",
  SELECT_CLIENT: "Välj klient",
  CLIENT_ID: "Klient",
  DATA: "Data",
  NO_CLIENT: "Ingen klient",
  TRANSLATIONS: "Översättningar",
  TRANSLATION: "Översättning",
  __SUBMISSION_DISPLAY_FIELDS: "Visningsfält för inlämning",
  ADDITIONAL_QUEUES: "Ytterligare köer",
  HELP_ADDITIONAL_QUEUES: "Välj ytterligare köer som inte är tillgängliga i nuvarande agentprofil.",
  FROM_ADDITIONAL: "Från ytterligare köer",
  FORM_SETTINGS: "Formulärinställningar",
  FORM_CAPACITY: "Formulärkapacitet",
  FIXED_LINE_OR_MOBILE: "Fast linje/mobil",
  ATTACHMENTS_STORAGE: "Bilagalagring",
  PARENTS: "Föräldrar",
  ALL_QUEUES: "Alla köer",
  PROFILE_QUEUES: "Profilköer",
  DATA_CHANGED: "Fältdataändringar",
  FORCE_LOGOUT: "Tvinga utloggning",
  JSON_RESPONSE: "JSON-svar",
  JSON_PATH: "JSON-sökväg",
  JSON_PATH_HELP: "Exempel: data.property, data.array[0].property",
  DELETED_ITEM_REMOVE: "Borttaget objekt måste tas bort från valda objekt!",
  UNASSIGN: "Ta bort tilldelning",
  ALLOW_AGENT_UNASSIGN: "Tillåt agent att ta bort tilldelning",
  ASTERISK_HOURS: "Asterisk-timmar",
  TOGGLE_ASTERISK_HOURS: "Växla asterisk-timmar",
  EXTERNAL_VOICE_RINGTONE: "Extern samtalssignal",
  INTERNAL_VOICE_RINGTONE: "Intern samtalssignal",
  LOAD_MORE: "Ladda mer",
  JOURNEY_TIMELINE: "Reseflödestidslinje",
  TIMELINE: "Tidslinje",
  EMPTY_TIMELINE: "Tom tidslinje",
  GLOBAL_SEARCH_QUEUES: "Globala sökköer",
  PERMANENTLY_DELETE_REPORT: "Ta bort rapport permanent?",
  PERMANENTLY_DELETE_REPORT_DESCRIPTION:
    "Alla unika widgets i denna rapport kommer också att tas bort med denna åtgärd.",
  PERMANENTLY_DELETE_DASHBOARD: "Ta bort Dashboard permanent?",
  PERMANENTLY_DELETE_DASHBOARD_DESCRIPTION:
    "Alla unika widgets i denna Dashboard kommer också att tas bort med denna åtgärd.",
  DELETE_WIDGET: "Ta bort widget",
  DELETE_WIDGET_DESCRIPTION:
    "Widget kommer att tas bort permanent om den inte förekommer i några andra Dashboarder/rapporter.",
  CREATED_AFTER: "Skapad efter",
  LAST_MESSAGE_BY_CONTACT: "Kontakt",
  LAST_MESSAGE_BY_AGENT: "Agent",
  SUBJECT_PREFIX: "Ämnesprefix",
  HIDE_STATS_PREVIEW: "Dölj statistikförhandsvisning",
  DISPLAY_STATS_PREVIEW: "Visa statistikförhandsvisning",
  SOMETHING_WENT_WRONG_CLONING_WIDGET: "Något gick fel vid kloning",
  STATS_WIDGET_CUSTOM_PERIOD_TO_LONG:
    "Widgets anpassade periodstart och slut får inte överstiga 31 dagar.",
  TRANSFER_DASHBOARD: "Överför Dashboard",
  TRANSFER_REPORT: "Överför rapport",
  TRANSFER_TO_REPORT_OWNER: "Överför widget till rapportägare",
  OF: "av",
  WIDGET_HAS_NO_DATA: "Widget har ingen data",
  RULE_OTHERWISE_MATCHED: 'Regel "I annat fall" matchades',
  RULE_X: "Regel {{n}}",
  RULE_X_WAS_MATCHED: '"Regel {{n}}" matchades',
  EDIT_CONTACT: "Redigera kontakt",
  PRECISION: "Precision",
  SKILL: "Kompetens",
  SKILLS: "Kompetenser",
  SKILL_ID: "Kompetens",
  LEVEL_ONE: "Nivå ett",
  LEVEL_TWO: "Nivå två",
  LEVEL_THREE: "Nivå tre",
  SKILL_TIMEOUT_SETTINGS: "Kompetenspaussinställningar",
  TIMEOUT_ONE: "Paus ett",
  TIMEOUT_TWO: "Paus två",
  TIMEOUT_THREE: "Paus tre",
  SKILL_TIMEOUT_ONE_DESCRIPTION:
    "Användarkompetenser nivå två kommer att inkluderas efter denna paus",
  SKILL_TIMEOUT_TWO_DESCRIPTION:
    "Användarkompetenser nivå tre kommer att inkluderas efter denna paus + föregående paus",
  SKILL_TIMEOUT_THREE_DESCRIPTION:
    "Alla kompetenser kommer att inkluderas efter denna paus + föregående pauser",
  QUEUE_TIMEOUT_UNIT_SECOND: "Sekunder",
  QUEUE_TIMEOUT_UNIT_MINUTE: "Minuter",
  QUEUE_TIMEOUT_UNIT_HOUR: "Timmar",
  QUEUE_TIMEOUT_UNIT_DAY: "Dagar",
  DEFAULT_VOICE_COUNTRY: "Standardland för röst",
  NO_SKILL: "Ingen kompetens",
  IS_WAITING: "Väntar",
  IS_ONGOING: "Pågående",
  PREVIEW_PERIOD: "Förhandsgranskningsperiod",
  WALLBOARD: "Wallboard",
  WALLBOARD_KEY: "Wallboard-nyckel",
  STATS_REPORT_ID: "Rapport / Dashboard",
  SHARE_DATA: "Dela data",
  SHARE_DATA_DESCRIPTION:
    "Dela data som användare, kö, disposition, e-postkonto... data med väggpanelsvisare. Observera att delade datapunkter som kö inkluderar alla organisationens köer och inte bara de som finns i widgets. Datadelning bör därför stängas av om du delar väggpanel med externa parter som inte ska ha tillgång till datapunktsinformation.",
  WALLBOARD_NO_MORE_TRIES: "Du har kopplats bort, vänligen uppdatera och försök igen!",
  DISPOSED: "Disponerad",
  STATUS_CHANGED: "Status ändrad",
  ASSIGNED_TO: "Tilldelad till",
  USER_ASSIGNED: "Användare tilldelad",
  USER_UNASSIGNED: "Användare borttagen",
  UNASSIGNED_FROM: "Borttagen från",
  QUEUE_ASSIGNED: "Kö tilldelad",
  TIMELINE_EVENT_TYPE_JOURNEYJOB: "Reseflödesjobb",
  TIMELINE_EVENT_TYPE_VOICE: "Röst",
  TIMELINE_EVENT_TYPE_NOTIFICATION: "Avisering",
  TIMELINE_EVENT_TYPE_AUDIT: "Granskning",
  JOURNEY_TRIGGER_ID: "Utlösare",
  TRANSCRIPT: "Transkript",
  DEFAULT_LANGUAGE: "Standardspråk",
  VIEW_TRANSCRIPT: "Visa transkript",
  DISPLAY_TRANSCRIPT: "Visa transkript",
  CONDITION_AND_OR_AND: "Och",
  CONDITION_AND_OR_OR: "Eller",
  VOICE_RECORDING_TRANSCRIPT: "Rösttranskript",
  WALLBOARDS: "Väggpaneler",
  SLA_RESPONSE: "Svarstid",
  SLA_RESOLUTION: "Lösningstid",
  HAS_SLA: "Har SLA",
  HAS_NOT_SLA: "Har inte SLA",
  EXCEEDED: "Överskriden",
  NOT_EXCEEDED: "Inte överskriden",
  TRANSCRIPT_TRIGGER: "Transkript utlösare på",
  CLICK_TO_TRANSCRIPT: "Klicka",
  RECORDED_BY_JOURNEY: "Inspelningar av reseflöde",
  MANUALLY_RECORDED: "Manuella inspelningar",
  GENERATE_TRANSCRIPT: "Generera transkript",
  VOICE_RECORDING_TRANSCRIPT_SETTING: "Transkriptinställningar",
  VOICE_RECORDING_TRANSCRIPT_SETTINGS: "Transkriptinställningar",
  FIND_REPLACE: "Sök & ersätt",
  FIND: "Sök",
  REPLACE: "Ersätt",
  MODEL: "Modell",
  VOICE_RECORDING_TRANSCRIPT_MODEL_WHISPER: "Whisper",
  VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA: "Nova",
  "VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA-2": "Nova 2",
  VOICE_RECORDING_TRANSCRIPT_MODEL_BASE: "Bas",
  VOICE_RECORDING_TRANSCRIPT_MODEL_ENHANCED: "Förbättrad",
  TRANSCRIPTION_ERROR: "Transkriptionsfel",
  CONFIDENCE: "Tillförlitlighet",
  TRANSCRIPTION_TIME: "Transkriptionstid",
  POINTS_SYSTEMS: "Poängsystem",
  POINTS_SYSTEM: "Poängsystem",
  MAIL_MESSAGE_SENT: "E-postmeddelande skickat",
  INBOUND_VOICE_CALL: "Inkommande röstsamtal",
  OUTBOUND_VOICE_CALL: "Utgående röstsamtal",
  INTERNAL_VOICE_CALL: "Internt röstsamtal",
  OUTBOUND_CONVERSATION_CREATED: "Utgående konversation skapad",
  OUTBOUND_CONVERSATION_RESOLVED: "Utgående konversation löst",
  INBOUND_CONVERSATION_RESOLVED: "Inkommande konversation löst",
  OUTBOUND_CONVERSATION_CLOSED: "Utgående konversation stängd",
  INBOUND_CONVERSATION_CLOSED: "Inkommande konversation stängd",
  POINTS: "Poäng",
  CONVERSATION_SKILL_ID: "Kompetens",
  JOURNEY_TRIGGER: "Reseflödesutlösare",
  JOURNEY_TRIGGERS: "Reseflödesutlösare",
  HIGHLIGHT_IN_JOURNEY: "Markera i reseflöde",
  HELP_CONVERSATION_FILTER_IS_WAITING:
    '"Väntar" avser konversationer som inte har besvarats och fortfarande är i kön.',
  HELP_CONVERSATION_FILTER_IS_ONGOING:
    '"Pågående" avser konversationer som inte är stängda eller lösta och har fått ett svar från en agent (systemets autosvar är exkluderade).',
  POINTS_CREATED: "Skapande",
  POINTS_RESOLVED: "Löst",
  POINTS_CLOSED: "Stängd",
  POINTS_TALK: "Samtalstid",
  POINTS_MAIL_SENT: "Poäng för att skicka e-post",
  TREND_COMPARISON_VALUE: "Trendjämförelsevärde",
  CUSTOM_PERCENT: "Anpassad procentsats",
  STATS_CUSTOM_PERCENT_HELP:
    "Definiera ett nummer för att dela metrikvärdet för att generera och visa ett procentvärde",
  GDPR: "GDPR",
  GDPRS: "GDPRs",
  PASSWORD_MAY_NOT_BE_IN_HISTORY:
    "Valt lösenord har använts nyligen av detta konto och kan för närvarande inte användas igen!",
  PASSWORD_LIFETIME_REACHED:
    "Ditt lösenord har gått ut! Återställ ditt lösenord för att nå applikationen.",
  IP_ADDRESS_NOT_WHITELISTED: "Din IP-adress är inte vitlistad av någon av dina organisationer!",
  PASSWORD_EXPIRY_DESCRIPTION:
    "Lösenordets livstid går ut om mindre än {{days_left}} dagar. Se till att ändra ditt lösenord.",
  PASSWORD_NOT_STRONG:
    "Valt lösenord är inte tillräckligt starkt! Se till att ditt lösenord innehåller minst en gemen och versal bokstav, ett specialtecken och en siffra!",
  FORM_ERROR_PASSWORD_NOT_STRONG:
    "Måste innehålla minst en liten och en stor bokstav, ett specialtecken och en siffra",
  IP_WHITELIST: "IP-vitlista",
  IP_WHITELIST_DESCRIPTION: "Begränsa åtkomst till din organisation via IP-adress.",
  IP_ADDRESSES: "IP-adresser",
  CONNECTEL_INFO_SHARE: "Användning av information",
  LIFETIME: "Livstid",
  HISTORY: "Historik",
  STRONG: "Stark",
  CONNECTEL_INFO_SHARE_DESCRIPTION:
    "Samtycke till att Connectel samlar in anonymiserad statistik och data från din organisation för att förbättra våra tjänster som vi tillhandahåller dig.",
  PASSWORD_STRONG: "Lösenordspolicy",
  PASSWORD_STRONG_DESCRIPTION: "Tvinga användning av starka lösenord.",
  PASSWORD_LIFETIME: "Lösenords livslängd",
  PASSWORD_LIFETIME_DESCRIPTION:
    "Ange max antal dagar ett lösenord får leva innan det måste bytas ut.",
  PASSWORD_HISTORY: "Lösenordshistorik",
  PASSWORD_HISTORY_DESCRIPTION: "Begränsar användningen av tidigare använda lösenord.",
  ANONYMIZATION: "Anonymisering",
  AGENT_ANONYMIZATION_DESCRIPTION:
    "Anonymisera agentnamnet i konversationer och andra datapunkter vid borttagning.",
  EMAIL_ATTACHMENTS: "E-postbilagor",
  FORM_SUBMISSION_ATTACHMENTS: "Formulärbilagor",
  DELETION: "Radering",
  VOICE_RECORDINGS: "Röstinspelningar",
  CONTACT_ANONYMIZATION_DESCRIPTION:
    "Antal dagar som ska behållas och ignoreras av anonymisering. Denna inställning gäller endast inaktiva kontakter och konversationer.",
  CONTACT_DELETION_RECORDINGS_DESCRIPTION:
    "Antal dagar som ska behållas och ignoreras av radering. Denna inställning gäller endast inaktiva kontakter och konversationer.",
  CONTACT_VOICE_RECORDINGS_DESCRIPTION: "Antal dagar som ska behållas och ignoreras av radering.",
  CONTACT_EMAIL_ATTACHMENTS_DESCRIPTION: "Antal dagar som ska behållas och ignoreras av radering.",
  CONTACT_FORM_SUBMISSION_ATTACHMENTS_DESCRIPTION:
    "Antal dagar som ska behållas och ignoreras av radering.",
  ANONYMIZED: "Anonymiserad",
  LOGIN_ERROR: "Inloggning misslyckades",
  ANONYMIZE_CONTACT_AND_CONVERSATIONS: "Anonymisera kontakt",
  CONFIRM_ANONYMIZE: "Bekräfta anonymisering",
  CONFIRM_ANONYMIZE_DESCRIPTION:
    "Genom att anonymisera kontakten anonymiserar du all relaterad data till kontakten eller dess konversationer. Denna åtgärd kan inte ångras!",
  ANONYMIZATION_ERROR: "Anonymiseringsfel",
  ANONYMIZE_CONTACTS: "Anonymisera kontakter",
  TRANSCRIPT_ANONYMIZED: "Transkript anonymiserat",
  OpenBrowserTab: "Öppna webbläsarflik",
  MAX_INACTIVITY_READY: "Maximal inaktivitet för agentprofiler",
  ORGANIZATION_HAS_NOT_VOICE_ASTERISK: "Vald organisation har ingen röst-asterisk tilldelad!",
  SIGNATURE_PREVIEW: "Förhandsgranskning av signatur",
  KEYWORDS: "Nyckelord",
  TAG_KEYWORDS_HELP: "Nyckelord är inte skiftlägeskänsliga.",
  AUTO_TAGS: "Automatiska taggar",
  VOICE_TRANSCRIPT: "Rösttranskript",
  SUB_DISPOSITIONS: "Underklassificeringar",
  THRD_DISPOSITIONS: "Tredjeklassificeringar",
  STATS_ALARMS: "Larm",
  STATS_ALARM: "Larm",
  TEXT: "Text",
  AUTO_TEXT: "Autogenerera text",
  STATS_ALARM_PERIOD_LIVE_TODAY: "Idag (Live)",
  STATS_ALARM_PERIOD_TODAY: "Idag",
  STATS_ALARM_PERIOD_LAST_24_HOURS: "Senaste 24 timmarna",
  STATS_ALARM_PERIOD_THIS_WEEK: "Denna vecka",
  STATS_ALARM_PERIOD_THIS_WEEK_SUN: "Denna vecka (start söndag)",
  STATS_ALARM_PERIOD_LAST_7_DAYS: "Senaste 7 dagarna",
  STATS_ALARM_PERIOD_THIS_MONTH: "Denna månad",
  STATS_ALARM_PERIOD_LAST_30_DAYS: "Senaste 30 dagarna",
  LAST_MATCH_AT: "Senaste matchning",
  MIN_INTERVAL: "Minsta intervall",
  STATS_ALARM_MIN_INTERVAL_HELP:
    "Minsta intervall att vänta efter att en avisering skickats! För att undvika spam eftersom live-mätvärden kan ändras ofta.",
  NOTIFICATION: "Avisering",
  ATLAS_NOTIFICATION: "Atlas-avisering",
  USER_IDS: "Användare",
  HIGHLIGHTED: "Markerad",
  MATCHED_CONDITION: "matchade villkoret",
  SOUND_TYPE_ELEVENLABS: "Text till röst",
  SOUND_TYPE_FILEUPLOAD: "Filuppladdning",
  ELEVENLABS_VOICE_ALICE: "Alice",
  ELEVENLABS_VOICE_ARIA: "Aria",
  ELEVENLABS_VOICE_BILL: "Bill",
  ELEVENLABS_VOICE_BRIAN: "Brian",
  ELEVENLABS_VOICE_CALLUM: "Callum",
  ELEVENLABS_VOICE_CHARLIE: "Charlie",
  ELEVENLABS_VOICE_CHARLOTTE: "Charlotte",
  ELEVENLABS_VOICE_CHRIS: "Chris",
  ELEVENLABS_VOICE_DANIEL: "Daniel",
  ELEVENLABS_VOICE_ERIC: "Eric",
  ELEVENLABS_VOICE_GEORGE: "George",
  ELEVENLABS_VOICE_JESSICA: "Jessica",
  ELEVENLABS_VOICE_LAURA: "Laura",
  ELEVENLABS_VOICE_LIAM: "Liam",
  ELEVENLABS_VOICE_LILY: "Lily",
  ELEVENLABS_VOICE_MATILDA: "Matilda",
  ELEVENLABS_VOICE_RIVER: "River",
  ELEVENLABS_VOICE_ROGER: "Roger",
  ELEVENLABS_VOICE_SARAH: "Sarah",
  ELEVENLABS_VOICE_WILL: "Will",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_CONVERSATIONAL_SWEDISH: "Sanna Hartfield - Svensk konversation",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_SASSY_SWEDISH: "Sanna Hartfield - Kaxig svenska",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_NARRATION_SWEDISH: "Sanna Hartfield - Svensk berättarröst",
  ELEVENLABS_VOICE_JONAS: "Jonas, lugn & informativ svensk röst",
  ELEVENLABS_SETTINGS: "Generatorinställningar",
  QUEUE_SORT_LASTINBOUNDMESSAGEAT: "Senaste inkommande meddelande vid",
  QUEUE_SORT_LASTOUTBOUNDMESSAGEAT: "Senaste utgående meddelande vid",
  QUEUE_SORT_UNHANDLED: "Ohanterade",
  QUEUE_SORT_QUEUEJOINTIME: "Kötid",
  SORT: "Sortera efter",
  EFFECTIVE_WORK_TIME: "Effektiv arbetstid",
  ARTIFICIELL_INTELLIGENCE: "Artificiell intelligens",
  AI_ASSISTANTS: "AI-assistenter",
  AI_ASSISTANT: "AI-assistent",
  KB_UNIQUE_ID: "KB unikt id",
  AI_ASSISTANT_ID: "AI-assistent",
  NO_AI_ASSISTANT_ON_QUEUE: "Ingen AI-assistent på denna kö",
  NEW_MAIL_MESSAGE_WITH_AI: "Nytt e-postmeddelande med AI",
  REPLY_WITH_AI: "Svara med AI",
  AI_RESPONDING: "AI svarar...",
  AI_RESPONSE_CONFIDENCE: "AI-svarets tillförlitlighet",
  AI_REQUEST_KB_ASSISTANT: "AI-förfrågan (Svarsassistans)",
  AI_REQUEST_KB_ASSISTANCE: "AI-förfrågan (Svarsassistans)",
  KbAssistant: "Svarsassistans",
  REQ: "förfr",
  INVOICE_POST_VOICE_RECORDING_TRANSCRIPT: "Rösttranskript",
  TEXT_TO_VOICE_USAGE: "Text till röst-användning",
  QUEUE_SORT_HELP:
    '"Ohanterade" sorterar efter kötid och filtrerar dessutom till endast konversationer som inte har besvarats.',
  FIELD_CONDITIONS: "Fältvillkor",
  FIELD_TO_AFFECT: "Fält att påverka",
  CONDITIONAL_FIELD: "Villkorligt fält",
  THEN: "Då",
  FORBID: "Förbjud",
  MAKE_OPTIONAL: "Gör valfri",
  IF: "Om",
  MAX: "MAX",
  MAXIMUM: "Maximum",
  HELP: "Hjälp",
  HIDE_HELP: "Dölj hjälp",
  VALIDATION_MAXIMUM_NUMBER_HELP: "Maximalt nummer som kan fyllas i detta fält",
  VALIDATION_MAXIMUM_LIST_HELP: "Maximalt antal alternativ",
  VALIDATION_MAXIMUM_TEXT_HELP: "Maximalt antal tecken",
  DEFAULT_VALUE: "Standardvärde",
  IMPORT_FORM_SUBMISSION: "Importera formulärinlämning",
  SAVE_REPLY: "Spara svar",
  EDIT_REPLY: "Redigera svar",
  CREATE_REPLY: "Skapa svar",
  HIDE_REPLY: "Dölj svar",
  IMPORT_CONVERSATION_FORM: "Importera konversationsformulär",
  CUSTOM_ANSWER: "Anpassat",
  FIELD_CUSTOM_ANSWER_HELP:
    "Slutanvändaren kan fylla i anpassat svar i ett textfält. Angivet värde kommer att sättas till alias {field_alias}_custom.",
  CLICK_TO_COPY: "Klicka för att kopiera",
  RECONNECTS_AT: "Återansluter vid",
  DOWNLOAD_EML: "Ladda ner e-postmeddelande .eml",
  USER_SETTINGS: "Användarinställningar",
  ENGLISH: "Engelska",
  SWEDISH: "Svenska",
  DANISH: "Danska",
  NORWEGIAN: "Norska",
  FINNISH: "Finsk",
  GERMAN: "Tyska",
  SHOW_MORE_FIELDS: "Visa fler fält",
  INVALID: "Ogiltligt",
  TOO_SHORT: "För kort",
  TOO_LONG: "För långt",
  ERROR_QUEUE_NO_CREATE_LIST: "Kön har ingen skapa lista",
  TEXT_MESSAGE: "Textmeddelande",
  TEXT_MESSAGE_SOURCE_CREATED: "Textmeddelandekälla skapad",
  TEXT_MESSAGE_SOURCE_CREATED_ERROR: "Textmeddelandekälla skapningsfel",
  TEXT_MESSAGE_SOURCE: "Textmeddelandekälla",
  TEXT_MESSAGE_SOURCES: "Textmeddelandekällor",
  ALPHA_TAG: "Alfatagg",
  SELECT_ORGANIZATION: "Välj organisation",
  MESSAGE: "Meddelande",
  SELECT_SOURCE: "Välj källa",
  SELECT_QUEUE_WITH_CREATE_LIST: "Välj en kö med en skapa lista för att skapa en kontakt",
  BEGIN_TYPING_TO_SEE_CONTACT: "Börja skriva för att se kontakter...",
  MISSING_CONTACT: "Kontakt saknas",
  MISSING_EMAIL: "Email saknas",
  MISSING_SUBJECT: "Ämne saknas",
  MISSING_CONTACT_FORM: " Kontaktformuläret saknas",
  VERIFIED: "Verifierad",
  TEXT_MESSAGE_SOURCE_UPDATED: "Textmeddelandekälla uppdaterad",
  TEXT_MESSAGE_SOURCE_UPDATE_FAILED: "Textmeddelandekälla uppdatering misslyckades",
  SENDER_PHONE_NUMBER: "Avsändarens telefonnummer",
  CONVERSATION_ENUM_ID: "Konversations ID",
  CONVERSATION_ENUM_EMAIL: "Kontakt email",
  CONVERSATION_ENUM_STATUS: "Status",
  CONVERSATION_ENUM_CHANNEL: "Kanal",
  CONVERSATION_ENUM_DIRECTION: "Riktning",
  CONVERSATION_ENUM_QUEUE_NAME: "Könamn",
  CONVERSATION_ENUM_AGENT_NAME: "Agentnamn",
  CONVERSATION_ENUM_DISPOSITION: "Klassificering",
  CONVERSATION_ENUM_CREATED_DATE: "Skapad datum",
  CONVERSATION_ENUM_CALLER_ID: "Anrops ID",
  CONVERSATION_ENUM_ORGANIZATION_ID: "Organisations ID",
  CONVERSATION_ENUM_ORGANIZATION_TITLE: "Organisationstitel",
  DESELECT_ALL: "Avmarkera alla",
  CONVERSATION_DOWNLOAD: "Välj vilka fält som ska inkluderas i nedladdningen",
  NEW_TEXT_MESSAGE: "Nytt textmeddelande",
  SUMMARIZE_CONVERSATION: "Sammanfatta konversation",
  GET_NEW_SUMMARY: "Hämta ny sammanfattning",
  CONVERSATION_SUMMARY: "Sammanfattning",
  OPEN_SUMMARY: "Öppna sammanfattning",
  CLOSE_SUMMARY: "Stäng sammanfattning",
  MESSAGE_SENT_SUCCESSFULLY: "Meddelande skickat",
  MESSAGE_TOO_SHORT: "Meddelandet måste vara minst 10 tecken långt",
  MISSING_SOURCE: "Källa saknas",
  MISSING_PHONE_NUMBER: "Telefonnummer saknas",
  ERROR_SENDING_MESSAGE: "Fel vid skickande av meddelande",
  MISSING_QUEUE: "Kö saknas",
};
