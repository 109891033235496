import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, sv, da, no, fi, de } from "./resources";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      sv: {
        translation: sv,
      },
      da: {
        translation: da,
      },
      no: {
        translation: no,
      },
      fi: {
        translation: fi,
      },
      de: {
        translation: de,
      },
    },
    // Avoids null by falling back to undefined
    lng: localStorage.getItem("atlas-language") ?? undefined,
    fallbackLng: "en",
    react: {
      // https://react.i18next.com/latest/trans-component#trans-props
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i", "a"],
    },

    interpolation: {
      escapeValue: false,
    },
  });
