"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionStartVoiceOutbound = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
exports.JourneyActionStartVoiceOutbound = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.StartVoiceOutbound,
    channels: [enums_1.ChannelEnum.Voice,],
    direction: enums_1.JourneyNodeDirectionEnum.Output,
    getPorts: (node) => {
        return {
            [constants_1.JOURNEY_ACTION_START_VOICE_OUTBOUND_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_START_VOICE_OUTBOUND_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: true,
                index: 0,
                title: 'CREATED',
                color: null,
            }),
        };
    },
});
