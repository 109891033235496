import React from 'react';
import { ISharedDraft, ISharedFullConversation, TypeConversationMessage, } from 'atlas-shared';
import { ConversationMessageReply, } from './conversation.message.reply';
import { THighlightedMessage, useReplyMessages, } from '@Hooks';

interface IProps {
  message?: TypeConversationMessage;
  draft?: ISharedDraft;
  conversation: ISharedFullConversation;
  highlightedMessage?: THighlightedMessage;
}

export const ConversationMessageReplies = ({ message, draft, conversation, highlightedMessage, }: IProps) => {
  const [replyTo, replyFrom, ] = useReplyMessages(message, draft, conversation);

  return <div className='conversation-message-replies'>
    {replyTo.map(rt => <ConversationMessageReply
      key={rt.id}
      ownerMessage={message}
      ownerDraft={draft}
      message={rt}
      highlightedMessage={highlightedMessage}
    />)}
    {replyFrom.map((rf) => (
      <ConversationMessageReply
        key={rf.id}
        ownerMessage={message}
        ownerDraft={draft}
        message={rf}
        from={true}
        highlightedMessage={highlightedMessage}
      />
    ))}
  </div>;
};
