import { JourneyActionEnum, Metrics, GroupMetrics } from "atlas-shared";

export const da = {
  ...Object.fromEntries(
    [...Metrics, ...GroupMetrics]
      .flatMap((metric) => [
        metric.title ? [`METRIC_${metric.alias}`, metric.title] : false,
        metric.description ? [`METRIC_${metric.alias}_DESCRIPTION`, metric.description] : false,
      ])
      .filter(Boolean) as Array<[string, string]>
  ),
  COUNTRY_AF: "Afghanistan",
  COUNTRY_AX: "Åland Islands",
  COUNTRY_AL: "Albania",
  COUNTRY_DZ: "Algeria",
  COUNTRY_AS: "American Samoa",
  COUNTRY_AD: "Andorra",
  COUNTRY_AO: "Angola",
  COUNTRY_AI: "Anguilla",
  COUNTRY_AQ: "Antarctica",
  COUNTRY_AG: "Antigua and Barbuda",
  COUNTRY_AR: "Argentina",
  COUNTRY_AM: "Armenia",
  COUNTRY_AW: "Aruba",
  COUNTRY_AU: "Australia",
  COUNTRY_AT: "Austria",
  COUNTRY_AZ: "Azerbaijan",
  COUNTRY_BS: "Bahamas",
  COUNTRY_BH: "Bahrain",
  COUNTRY_BD: "Bangladesh",
  COUNTRY_BB: "Barbados",
  COUNTRY_BY: "Belarus",
  COUNTRY_BE: "Belgium",
  COUNTRY_BZ: "Belize",
  COUNTRY_BJ: "Benin",
  COUNTRY_BM: "Bermuda",
  COUNTRY_BT: "Bhutan",
  COUNTRY_BO: "Bolivia",
  COUNTRY_BA: "Bosnia and Herzegovina",
  COUNTRY_BW: "Botswana",
  COUNTRY_BV: "Bouvet Island",
  COUNTRY_BR: "Brazil",
  COUNTRY_IO: "British Indian Ocean Territory",
  COUNTRY_BN: "Brunei Darussalam",
  COUNTRY_BG: "Bulgaria",
  COUNTRY_BF: "Burkina Faso",
  COUNTRY_BI: "Burundi",
  COUNTRY_KH: "Cambodia",
  COUNTRY_CM: "Cameroon",
  COUNTRY_CA: "Canada",
  COUNTRY_CV: "Cape Verde",
  COUNTRY_KY: "Cayman Islands",
  COUNTRY_CF: "Central African Republic",
  COUNTRY_TD: "Chad",
  COUNTRY_CL: "Chile",
  COUNTRY_CN: "China",
  COUNTRY_CX: "Christmas Island",
  COUNTRY_CC: "Cocos (Keeling) Islands",
  COUNTRY_CO: "Colombia",
  COUNTRY_KM: "Comoros",
  COUNTRY_CG: "Congo",
  COUNTRY_CD: "Congo, The Democratic Republic of the",
  COUNTRY_CK: "Cook Islands",
  COUNTRY_CR: "Costa Rica",
  COUNTRY_CI: "Cote D'Ivoire",
  COUNTRY_HR: "Croatia",
  COUNTRY_CU: "Cuba",
  COUNTRY_CY: "Cyprus",
  COUNTRY_CZ: "Czech Republic",
  COUNTRY_DG: "Diego Garcia",
  COUNTRY_DK: "Denmark",
  COUNTRY_DJ: "Djibouti",
  COUNTRY_DM: "Dominica",
  COUNTRY_DO: "Dominican Republic",
  COUNTRY_EC: "Ecuador",
  COUNTRY_EG: "Egypt",
  COUNTRY_SV: "El Salvador",
  COUNTRY_GQ: "Equatorial Guinea",
  COUNTRY_ER: "Eritrea",
  COUNTRY_EE: "Estonia",
  COUNTRY_ET: "Ethiopia",
  COUNTRY_FK: "Falkland Islands (Malvinas)",
  COUNTRY_FO: "Faroe Islands",
  COUNTRY_FJ: "Fiji",
  COUNTRY_FI: "Finland",
  COUNTRY_FR: "France",
  COUNTRY_GF: "French Guiana",
  COUNTRY_PF: "French Polynesia",
  COUNTRY_TF: "French Southern Territories",
  COUNTRY_GA: "Gabon",
  COUNTRY_GM: "Gambia",
  COUNTRY_GE: "Georgia",
  COUNTRY_DE: "Germany",
  COUNTRY_GH: "Ghana",
  COUNTRY_GI: "Gibraltar",
  COUNTRY_GR: "Greece",
  COUNTRY_GL: "Greenland",
  COUNTRY_GD: "Grenada",
  COUNTRY_GP: "Guadeloupe",
  COUNTRY_GU: "Guam",
  COUNTRY_GT: "Guatemala",
  COUNTRY_GG: "Guernsey",
  COUNTRY_GN: "Guinea",
  COUNTRY_GW: "Guinea-Bissau",
  COUNTRY_GY: "Guyana",
  COUNTRY_HT: "Haiti",
  COUNTRY_HM: "Heard Island and Mcdonald Islands",
  COUNTRY_VA: "Holy See (Vatican City State)",
  COUNTRY_HN: "Honduras",
  COUNTRY_HK: "Hong Kong",
  COUNTRY_HU: "Hungary",
  COUNTRY_IS: "Iceland",
  COUNTRY_IN: "India",
  COUNTRY_ID: "Indonesia",
  COUNTRY_IR: "Iran, Islamic Republic Of",
  COUNTRY_IQ: "Iraq",
  COUNTRY_IE: "Ireland",
  COUNTRY_IM: "Isle of Man",
  COUNTRY_IL: "Israel",
  COUNTRY_IT: "Italy",
  COUNTRY_JM: "Jamaica",
  COUNTRY_JP: "Japan",
  COUNTRY_JE: "Jersey",
  COUNTRY_JO: "Jordan",
  COUNTRY_KZ: "Kazakhstan",
  COUNTRY_KE: "Kenya",
  COUNTRY_KI: "Kiribati",
  COUNTRY_KP: "Korea, Democratic People'S Republic of",
  COUNTRY_KR: "Korea, Republic of",
  COUNTRY_XK: "Kosovo",
  COUNTRY_KW: "Kuwait",
  COUNTRY_KG: "Kyrgyzstan",
  COUNTRY_LA: "Lao People'S Democratic Republic",
  COUNTRY_LV: "Latvia",
  COUNTRY_LB: "Lebanon",
  COUNTRY_LS: "Lesotho",
  COUNTRY_LR: "Liberia",
  COUNTRY_LY: "Libyan Arab Jamahiriya",
  COUNTRY_LI: "Liechtenstein",
  COUNTRY_LT: "Lithuania",
  COUNTRY_LU: "Luxembourg",
  COUNTRY_LX: "Luxembourg (Special)",
  COUNTRY_MO: "Macao",
  COUNTRY_MK: "Macedonia, The Former Yugoslav Republic of",
  COUNTRY_MG: "Madagascar",
  COUNTRY_MW: "Malawi",
  COUNTRY_MY: "Malaysia",
  COUNTRY_MV: "Maldives",
  COUNTRY_ML: "Mali",
  COUNTRY_MT: "Malta",
  COUNTRY_MH: "Marshall Islands",
  COUNTRY_MQ: "Martinique",
  COUNTRY_MR: "Mauritania",
  COUNTRY_MU: "Mauritius",
  COUNTRY_YT: "Mayotte",
  COUNTRY_MX: "Mexico",
  COUNTRY_FM: "Micronesia, Federated States of",
  COUNTRY_MD: "Moldova, Republic of",
  COUNTRY_ME: "Montenegro",
  COUNTRY_MF: "Saint Martin",
  COUNTRY_MC: "Monaco",
  COUNTRY_MN: "Mongolia",
  COUNTRY_MS: "Montserrat",
  COUNTRY_MA: "Morocco",
  COUNTRY_MZ: "Mozambique",
  COUNTRY_MM: "Myanmar",
  COUNTRY_NA: "Namibia",
  COUNTRY_NR: "Nauru",
  COUNTRY_NP: "Nepal",
  COUNTRY_NL: "Netherlands",
  COUNTRY_AN: "Netherlands Antilles",
  COUNTRY_NC: "New Caledonia",
  COUNTRY_NZ: "New Zealand",
  COUNTRY_NI: "Nicaragua",
  COUNTRY_NE: "Niger",
  COUNTRY_NG: "Nigeria",
  COUNTRY_NU: "Niue",
  COUNTRY_NF: "Norfolk Island",
  COUNTRY_MP: "Northern Mariana Islands",
  COUNTRY_NO: "Norway",
  COUNTRY_OM: "Oman",
  COUNTRY_PK: "Pakistan",
  COUNTRY_PW: "Palau",
  COUNTRY_PS: "Palestinian Territory, Occupied",
  COUNTRY_PA: "Panama",
  COUNTRY_PG: "Papua New Guinea",
  COUNTRY_PY: "Paraguay",
  COUNTRY_PE: "Peru",
  COUNTRY_PH: "Philippines",
  COUNTRY_PN: "Pitcairn",
  COUNTRY_PL: "Poland",
  COUNTRY_PT: "Portugal",
  COUNTRY_PR: "Puerto Rico",
  COUNTRY_QA: "Qatar",
  COUNTRY_RE: "Reunion",
  COUNTRY_RO: "Romania",
  COUNTRY_RS: "Serbia",
  COUNTRY_RU: "Russian Federation",
  COUNTRY_RW: "Rwanda",
  COUNTRY_SH: "Saint Helena",
  COUNTRY_KN: "Saint Kitts and Nevis",
  COUNTRY_LC: "Saint Lucia",
  COUNTRY_PM: "Saint Pierre and Miquelon",
  COUNTRY_VC: "Saint Vincent and the Grenadines",
  COUNTRY_WS: "Samoa",
  COUNTRY_SM: "San Marino",
  COUNTRY_ST: "Sao Tome and Principe",
  COUNTRY_SA: "Saudi Arabia",
  COUNTRY_SN: "Senegal",
  COUNTRY_CS: "Serbia and Montenegro",
  COUNTRY_SC: "Seychelles",
  COUNTRY_SL: "Sierra Leone",
  COUNTRY_SG: "Singapore",
  COUNTRY_SK: "Slovakia",
  COUNTRY_SI: "Slovenia",
  COUNTRY_SB: "Solomon Islands",
  COUNTRY_SO: "Somalia",
  COUNTRY_SS: "South Sudan",
  COUNTRY_SX: "Sint Maarten",
  COUNTRY_ZA: "South Africa",
  COUNTRY_GS: "South Georgia and the South Sandwich Islands",
  COUNTRY_ES: "Spain",
  COUNTRY_LK: "Sri Lanka",
  COUNTRY_SD: "Sudan",
  COUNTRY_SR: "Suriname",
  COUNTRY_SJ: "Svalbard and Jan Mayen",
  COUNTRY_SZ: "Swaziland",
  COUNTRY_SE: "Sweden",
  COUNTRY_CH: "Switzerland",
  COUNTRY_SY: "Syrian Arab Republic",
  COUNTRY_TA: "Tristan da Cunha",
  COUNTRY_TW: "Taiwan, Province of China",
  COUNTRY_TJ: "Tajikistan",
  COUNTRY_TZ: "Tanzania, United Republic of",
  COUNTRY_TH: "Thailand",
  COUNTRY_TL: "Timor-Leste",
  COUNTRY_TG: "Togo",
  COUNTRY_TK: "Tokelau",
  COUNTRY_TO: "Tonga",
  COUNTRY_TP: "East Timor",
  COUNTRY_TT: "Trinidad and Tobago",
  COUNTRY_TN: "Tunisia",
  COUNTRY_TR: "Turkey",
  COUNTRY_TM: "Turkmenistan",
  COUNTRY_TC: "Turks and Caicos Islands",
  COUNTRY_TV: "Tuvalu",
  COUNTRY_UG: "Uganda",
  COUNTRY_UA: "Ukraine",
  COUNTRY_AE: "United Arab Emirates",
  COUNTRY_GB: "United Kingdom",
  COUNTRY_US: "United States",
  COUNTRY_UM: "United States Minor Outlying Islands",
  COUNTRY_UY: "Uruguay",
  COUNTRY_UZ: "Uzbekistan",
  COUNTRY_VU: "Vanuatu",
  COUNTRY_VE: "Venezuela",
  COUNTRY_VN: "Viet Nam",
  COUNTRY_VG: "Virgin Islands, British",
  COUNTRY_VI: "Virgin Islands, U.S.",
  COUNTRY_WF: "Wallis and Futuna",
  COUNTRY_EH: "Western Sahara",
  COUNTRY_YE: "Yemen",
  COUNTRY_ZM: "Zambia",
  COUNTRY_ZW: "Zimbabwe",
  COUNTRY_AC: "Ascension Island",
  COUNTRY_BL: "Saint Martin country",
  COUNTRY_BQ: "Netherlands Antilles",
  COUNTRY_CW: "Netherlands Antilles",

  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceInbound}`]: "Indgående opkald blev oprettet",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceOutbound}`]:
    "Udgående opkald blev afsluttet af agent",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceAnswer}`]: "Besvar opkald",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceExternalDial}`]: "Ring til eksternt nummer",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceGetDigits}`]: "Hent cifre fra opkalder",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceHangup}`]: "Læg opkald på",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceInternalDial}`]: "Internt opkald til agenter eller køer",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceMenu}`]: "Stemmemenu med valgmuligheder",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoicePlayback}`]: "Afspil lyd for kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayAlpha}`]: "Læs tekst for kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayDigits}`]: "Læs cifre for kontakt (et ad gangen)",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVariableSet}`]: "Indstil en stemmevariabel",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayNumber}`]: "Læs nummer for kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayPhonetic}`]: "Læs bogstaver for kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStartRecording}`]: "Start optagelse af opkald",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStopRecording}`]: "Stop specifik opkaldsoptagelse",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailInbound}`]: "Indgående e-mailsamtale blev oprettet",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailOutbound}`]: "Udgående e-mailsamtale blev oprettet",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartForm}`]: "Samtale oprettet via formularindsendelse",
  [`NODE_SUBTITLE_${JourneyActionEnum.OperationalHours}`]: "Inden for eller uden for åbningstid",
  [`NODE_SUBTITLE_${JourneyActionEnum.MailAutoResponse}`]: "Autosvar e-mail",
  [`NODE_SUBTITLE_${JourneyActionEnum.Queue}`]: "Tildel til kø",
  [`NODE_SUBTITLE_${JourneyActionEnum.Client}`]: "Tilføj klient til samtale",
  [`NODE_SUBTITLE_${JourneyActionEnum.UnAssign}`]: "Fjern tildeling af kø og/eller bruger",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceQueue}`]: "Tildel til kø med stemmehåndtering",
  [`NODE_SUBTITLE_${JourneyActionEnum.Agent}`]: "Tildel direkte til agent",
  [`NODE_SUBTITLE_${JourneyActionEnum.Timeout}`]: JourneyActionEnum.Timeout + " standardundertekst",
  [`NODE_SUBTITLE_${JourneyActionEnum.RestRequest}`]: "Lav en REST-forespørgsel",
  [`NODE_SUBTITLE_${JourneyActionEnum.FetchContactData}`]: "Hent kontaktdata via REST",
  [`NODE_SUBTITLE_${JourneyActionEnum.EditConversation}`]: "Rediger samtaleegenskaber",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseConversation}`]: "Afslut og luk samtale",
  [`NODE_SUBTITLE_${JourneyActionEnum.AddInternalNote}`]: "Tilføj intern note til samtale",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseTabs}`]: "Luk faner automatisk fra rejse",
  [`NODE_SUBTITLE_${JourneyActionEnum.SendMail}`]: "Send e-mail",
  [`NODE_SUBTITLE_${JourneyActionEnum.ForwardMail}`]: "Videresend e-mail",
  [`NODE_SUBTITLE_${JourneyActionEnum.ReplyLatestInboundMessage}`]:
    "Svar på seneste indgående e-mail eller formularindsendelse",
  [`NODE_SUBTITLE_${JourneyActionEnum.Journey}`]: "Underrejse",
  [`NODE_SUBTITLE_${JourneyActionEnum.Rule}`]: "Definer betingelsesregler",
  [`NODE_SUBTITLE_${JourneyActionEnum.RuleContact}`]: "Definer kontaktbetingelsesregler",
  [`NODE_SUBTITLE_${JourneyActionEnum.NoOp}`]: "Ingen handling",
  [`NODE_SUBTITLE_${JourneyActionEnum.When}`]: "Udløses ved",
  [`NODE_SUBTITLE_${JourneyActionEnum.SetField}`]: "Indstil samtalefeltværdi",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSetLanguage}`]: "Indstil opkaldets sprog",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVoicemail}`]: "Optag stemmebesked",
  [`NODE_SUBTITLE_${JourneyActionEnum.OpenBrowserTab}`]: "Åbn browserfane",

  NODE_TITLE_DEFAULT: "Standardtitel for node",
  NODE_DESCRIPTION_DEFAULT: "Standardbeskrivelse for node",
  LOADING: "Indlæser...",
  CUSTOMER_JOURNEY: "Kunderejse",
  CONVERSATION: "Samtale",
  CONVERSATIONS: "Samtaler",
  CUSTOMER: "Kunde",
  CUSTOMERS: "Kunder",
  STATISTICS: "Statistik",
  REPLY_IN: "Svar i",
  REPLY: "Svar",
  AGO: "siden",
  SHOWING: "Viser",
  STATS: "Statistik",
  CLOSE: "Luk",
  CLOSED: "Lukket",
  OPEN: "Åbn",
  DRAFT_REPLY_MESSAGE_CONFIRM: "Vil du gemme dine ændringer?",
  TODAY: "I dag",
  YES: "Ja",
  NO: "Nej",
  CONFIRM: "Bekræft",
  NEW_MESSAGE: "Ny besked",
  TO: "Til",
  DRAFT: "Kladde",
  SEND_REPLY: "Send svar",
  SAVE: "Gem",
  TYPE: "Type",
  ALL: "Alle",
  TICKETS: "Sager",
  CONTACTS: "Kontakter",
  SOLUTIONS: "Løsninger",
  FORUMS: "Forum",
  TEAM_NAME: "Teamnavn",
  ANOTHER_TEAM: "Andet team",
  THIRD_TEAM_NAME: "Tredje teamnavn",
  OTHER_TEAM_NAME: "Andet teamnavn",
  FAVORITES: "Favoritter",
  CHAT: "Chat",
  CALL: "Opkald",
  RETURN: "Tilbage",
  SEND: "Send",
  RECENTLY_SEARCHED: "Nyligt søgt",
  RECENTLY_VIEWED: "Nyligt vist",
  CLEAR: "Ryd",
  ASSIGN: "Tildel",
  CHOOSE: "vælg",
  AGENT: "Agent",
  ADMIN: "Admin",
  SUPERADMIN: "Superadmin",
  QUEUES: "Køer",
  QUEUE: "Kø",
  DONE: "Færdig",
  IN: "i",
  SEARCH: "Søg",
  AND: "Og",
  PREVIOUS_ACTIVITY: "Tidligere aktivitet",
  VIEW: "Vis",
  BY: "af",
  SUPPORT: "Support",
  GENERAL: "Generelt",
  WRITE: "Skriv",
  HERE: "her",
  ADD: "Tilføj",
  NEW: "Ny",
  AGENTS: "Agenter",
  EDIT: "Rediger",
  TOOLS: "Værktøjer",
  EMAIL: "E-mail",
  CANCEL: "Annuller",
  UNAUTHORIZED: "Brugernavn eller adgangskode er ikke korrekt",
  OR: "Eller",
  DISPOSE: "Afslut",
  CHOOSE_REASON_FOR_DISPOSAL: "Vælg årsag til afslutning",
  WRITE_A_COMMENT: "Skriv en kommentar",
  USERNAME: "Brugernavn",
  DROP_HERE: "Slip her",
  PASSWORD: "Adgangskode",
  FORGOT_YOUR_PASSWORD: "Glemt din adgangskode",
  RESET_PASSWORD: "Nulstil adgangskode",
  REMEMBER_ME: "Husk mig",
  SIGN_IN: "Log ind",
  SIGN_OUT: "Log ud",
  DISPLAY_DELETED: "Vis slettede",
  GLOBAL: "Global",
  WORK_TIME: "Arbejdstid",
  INVOICEABLE: "Fakturerbar",
  TITLE: "Titel",
  ORGANIZATION_NOT_FOUND: 'Organisation med id "{{organization_id}}" blev ikke fundet!',
  BETWEEN_FROM_AND_TO: "Mellem {{from}} og {{to}}",
  FROM_FROM_TO_TO: "Fra {{from}} til {{to}}",
  FROM_THROUGH_TO: "{{from}} til {{to}}",
  FROM_THROUGH_TO_OF_MONTH: "{{from}} til {{to}} i måneden",
  ON_FROM: "Den {{from}}",
  ON_FROM_OF_MONTH: "Den {{from}} i måneden",
  EVERY_SPECIFIC_DAY: "Hver {{from}}",
  ALL_DAY: "Hele dagen",
  EVERY_DAY: "Hver dag",

  ROLE: "Rolle",
  FIRST_NAME: "Fornavn",
  LAST_NAME: "Efternavn",
  AVATAR: "Profilbillede",

  ORGANIZATIONS: "Organisationer",
  ORGANIZATION: "Organisation",
  USER: "Bruger",
  USERS: "Brugere",
  ADMINPROFILES: "Adminprofiler",
  ADMINPROFILE: "Adminprofil",
  SUPERADMIN_PROFILES: "Superadminprofiler",
  SUPERADMINPROFILES: "Superadminprofiler",
  SUPERADMINPROFILE: "Superadminprofil",
  SUPERADMIN_PROFILE: "Superadminprofil",
  AGENTPROFILES: "Agentprofiler",
  AGENTPROFILE: "Agentprofil",
  TEAMS: "Teams",
  MAIL_ACCOUNTS: "E-mailkonti",
  MAIL_ACCOUNT: "E-mailkonto",
  LISTS: "Lister",
  LIST: "Liste",
  JOURNEYS: "Rejser",
  JOURNEY: "Rejse",
  PAUSES: "Pauser",
  PAUSE: "Pause",
  DISPOSITIONS: "Dispositioner",
  DISPOSITION: "Disposition",
  FIELDS: "Felter",
  FIELD: "Felt",
  FORM_ERROR_STRING__EMPTY: "Må ikke være tom",
  FORM_ERROR_ARRAY__MIN: "Mindst {{min}} valgmuligheder kræves",
  FORM_ERROR_ARRAY__MAX: "Maksimalt {{max}} valgmuligheder tilladt",
  FORM_ERROR_ARRAY__LENGTH: "{{lngth}} valgmuligheder kræves",
  FORM_ERROR_ANY__REQUIRED: "Påkrævet",
  FORM_ERROR_NUMBER__BASE: "Gyldigt nummer kræves",
  FORM_ERROR_STRING__BASE: "Gyldig tekst kræves",
  FORM_ERROR_VALID_OPTION: "Vælg en mulighed",
  FORM_ERROR_ANY__ONLY: "Vælg en gyldig mulighed",
  FORM_ERROR_STRING__URI: "Ugyldig uri",
  FORM_ERROR_REF_NOT_FOUND: "En gyldig mulighed skal vælges",
  FORM_ERROR_INVALID_PHONE: "Ugyldigt telefonnummer",
  FORM_ERROR_NUMBER__MIN: "Nummeret skal være større end eller lig med {{min}}",
  FORM_ERROR_NUMBER__MAX: "Nummeret skal være mindre end eller lig med {{max}}",
  FORM_ERROR_STRING__MIN: "Skal være mindst {{min}} tegn langt",
  FORM_ERROR_STRING__MAX: "Må højst være {{max}} tegn langt",
  FORM_ERROR_STRING__LENGTH: "Skal være præcis {{lngth}} tegn langt",
  FORM_ERROR_STRING__EMAIL: "Ugyldig e-mailadresse",
  FORM_ERROR_STRING__ALPHANUM: "Kun alfanumeriske værdier!",
  FORM_ERROR_USERNAME_INVALID_CHARS:
    "Må kun indeholde små bogstaver, tal og specialtegnene . (punktum) og - (bindestreg)",
  FORM_ERROR_STRING__PATTERN__BASE: "Strengen matcher ikke mønsteret {{pattern}}",
  FORM_ERROR_DATE__BASE: "Vælg en gyldig dato",
  FORM_ERROR_STRING__IPVERSION: "Ugyldig IP-adresse",

  GLOBAL_SEARCH_PLACEHOLDER: "Søg samtaler & kontakter",
  ADD_ITEM: "Tilføj element",
  MAIL_CAPACITY: "E-mailkapacitet",
  __ORGANIZATIONS: "Organisationer",
  __ORGANIZATIONS__ORGANIZATION_ID: "Organisation",
  __ORGANIZATIONS__ROLE: "Rolle",
  __ORGANIZATIONS__ADMINPROFILE_ID: "Adminprofil",
  __ORGANIZATIONS__SUPERADMIN_PROFILE_ID: "Superadminprofil",
  SUBMIT: "Send",
  SIGN_IN_WITH_GOOGLE: "Log ind med Google",
  SIGN_IN_WITH_MICROSOFT: "Log ind med Microsoft",
  OPEN_AUTHENTICATOR_AND_SCAN: "Åbn din godkendelsesapp og scan QR-koden",
  OPEN_AUTHENTICATOR: "Åbn din godkendelsesapp og indtast koden",
  ENTER_6_DIGIT_CODE: "Indtast 6-cifret kode",
  FILL_USERNAME_TO_RESET_PASSWORD: "Udfyld brugernavn for at nulstille adgangskode",
  SOMETHING_WENT_WRING: "Noget gik galt, prøv igen senere!",

  ENTRY: "Indtastning",
  ASSIGNMENT: "Tildeling",
  MAIL: "E-mail",
  StartMailInbound: "Start e-mail (Indgående)",
  StartMailOutbound: "Start e-mail (Udgående)",
  StartVoiceInbound: "Start opkald (Indgående)",
  StartVoiceOutbound: "Start opkald (Udgående)",
  VoiceAnswer: "Besvar",
  VoiceExternalDial: "Eksternt opkald",
  VoiceGetDigits: "Hent cifre",
  VoiceQueue: "Stemmekø",
  VoiceVariableSet: "Indstil variabel",
  VoiceHangup: "Læg på",
  VoiceInternalDial: "Internt opkald",
  VoiceMenu: "Stemmemenu",
  VoicePlayback: "Afspilning",
  VoiceSayAlpha: "Sig alfabetisk",
  VoiceSayNumber: "Sig nummer",
  VoiceSayPhonetic: "Sig fonetisk",
  VoiceSayDigits: "Sig cifre",
  VoiceStartRecording: "Start optagelse",
  VoiceStopRecording: "Stop optagelse",
  VoiceVoicemail: "Stemmebesked",
  StartForm: "Start formular",
  Queue: "Kø",
  EditConversation: "Rediger samtale",
  CloseConversation: "Afslut samtale",
  AddInternalNote: "Tilføj intern note",
  CloseTabs: "Luk faner",
  SendMail: "Send e-mail",
  ForwardMail: "Videresend e-mail",
  ReplyLatestInboundMessage: "Svar på seneste indgående besked",
  MailAutoResponse: "Automatisk e-mailsvar",
  RestRequest: "REST-anmodning",
  FetchContactData: "Hent kontaktdata",
  SetField: "Indstil feltdata",
  VoiceSetLanguage: "Indstil sprog",
  Rule: "Regel",
  RuleContact: "Regel (kontakt)",
  NoOp: "Ingen handling",
  OperationalHours: "Åbningstider",
  Journey: "Journey",
  MY_FILTERS: "Mine filtre",
  CREATE_NEW_FILTER: "Opret nyt filter",
  NEW_FILTER: "Nyt filter",
  DEFAULT_FILTER: "Standardfilter",
  STATUSES: "Status",
  CHANNELS: "Kanaler",
  PRIORITIES: "Prioriteter",
  TAGS: "Tags",
  LAST_MESSAGE_AT: "Seneste besked",
  LAST_INBOUND_MESSAGE_AT: "Seneste indgående besked",
  LAST_OUTBOUND_MESSAGE_AT: "Seneste udgående besked",
  CREATED_AT: "Oprettet",
  CONTACT: "Kontakt",
  __CONTACT_FIRST_NAME: "Fornavn",
  __CONTACT_LAST_NAME: "Efternavn",
  __CONTACT_PHONES: "Telefonnumre",
  __CONTACT_EMAILS: "E-mailadresser",
  SETTINGS: "Indstillinger",
  PROFILES: "Profiler",
  NOTIFICATIONS: "Notifikationer",
  NewAssignment: "Ny tildeling",
  NewResponseOnMyConversation: "Nyt svar på mine samtaler",
  ConversationMessagesImTaggedIn: "Samtalemeddelelser jeg er tagget i",
  StatusUpdateOnMyConversation: "Statusopdatering på min samtale",
  ConversationAssignedToMe: "Samtale tildelt til mig",
  ConversationAssignedToMyQueues: "Samtale tildelt til mine køer",
  ReminderOnFirstResponse: "Påmindelse om første svar",
  ReminderOnResolutionTime: "Påmindelse om løsningstid",
  ReminderOnNextResponse: "Påmindelse om næste svar",
  DELETE_DRAFT: "Slet kladde",
  PERMANENTLY_DELETE_DRAFT: "Slet kladde permanent?",
  ADD_PORT: "Tilføj port",
  ADD_RULE: "Tilføj regel",
  ADD_PROPERTY: "Tilføj egenskab",
  NOT_EQUAL_PHONE_NUMBER: "Ikke lig med telefonnummer",
  EQUAL_PHONE_NUMBER: "Lig med telefonnummer",
  INCLUDES_PHONE_NUMBER: "Indeholder telefonnummer",
  NOT_INCLUDES_PHONE_NUMBER: "Indeholder ikke telefonnummer",
  ARRAY_LENGTH: "Array-længde",
  STRICT_EQ: "Lig med",
  NOT_STRICT_EQ: "Ikke lig med",
  IS_EMPTY: "Er tom",
  IS_NOT_EMPTY: "Er ikke tom",
  CONTAINS: "Indeholder",
  NOT_CONTAINS: "Indeholder ikke",
  CHANGED: "Ændret",
  NOT_EQUAL_NUM: "Ikke lig med (tal)",
  NOT_EQUAL_STR: "Ikke lig med (tekst)",
  EQUAL_NUM: "Lig med (tal)",
  EQUAL_STR: "Lig med (tekst)",
  STARTS_WITH: "Starter med",
  NOT_STARTS_WITH: "Starter ikke med",
  ANY_OF_NUM: "En af",
  NONE_OF_NUM: "Ingen af",
  ANY_OF_STR: "En af",
  NONE_OF_STR: "Ingen af",
  ENDS_WITH: "Slutter med",
  NOT_ENDS_WITH: "Slutter ikke med",
  GT: "Større end",
  GTE: "Større end eller lig med",
  LT: "Mindre end",
  LTE: "Mindre end eller lig med",
  IS_NOT_NULL: "Er ikke null",
  IS_NULL: "Er null",
  IS_FALSY: "Er falsk",
  IS_FALSE: "Er false",
  IS_TRULY: "Er sand",
  IS_TRUE: "Er true",
  IS_UNDEFINED: "Er udefineret",
  IS_DEFINED: "Er defineret",
  INCLUDES: "Inkluderer",
  INCLUDES_NUM: "Inkluderer tal",
  INCLUDES_STR: "Inkluderer tekst",
  NOT_INCLUDES: "Inkluderer ikke",
  NOT_INCLUDES_NUM: "Inkluderer ikke tal",
  NOT_INCLUDES_STR: "Inkluderer ikke tekst",
  GT_DATETIME: "Større end",
  LT_DATETIME: "Mindre end",
  MAIL_MESSAGE: "E-mailbesked",
  "DRAFT.ID": "Kladde-id",
  "INTERNAL_NOTE.ID": "Intern note-id",
  "CONVERSATION.ID": "Samtale-id",
  "CONVERSATION.ORGANIZATION_ID": "Organisation",
  "CONVERSATION.TITLE": "Samtaletitel",
  "CONVERSATION.QUEUE_ID": "Samtalekø",
  "CONVERSATION_QUEUE.TITLE": "Samtalekøens titel",
  "CONVERSATION.LAST_INBOUND_MESSAGE_AT": "Seneste indgående besked",
  "CONVERSATION.LAST_OUTBOUND_MESSAGE_AT": "Seneste udgående besked",
  "CONVERSATION.LAST_MESSAGE_AT": "Seneste besked",
  "CONVERSATION.LAST_MESSAGE_BY": "Seneste besked af",
  "CONVERSATION.CLIENT_ID": "Samtaleklient",
  "CONVERSATION_CLIENT.TITLE": "Samtaleklientens titel",
  "CONVERSATION.CONTACT_ID": "Samtalekontakt-id",
  "CONVERSATION.JOURNEY_ID": "Rejse",
  "CONVERSATION.DISPOSITION_ID": "Samtaleklassificering",
  "CONVERSATION_DISPOSITION.TITLE": "Samtaleklassificeringens titel",
  "CONVERSATION.SUB_DISPOSITION_ID": "Samtaleunderklassificering",
  "CONVERSATION_SUB_DISPOSITION.TITLE": "Samtaleunderklassificeringens titel",
  "CONVERSATION.THRD_DISPOSITION_ID": "Samtaletredjeklassificering",
  "CONVERSATION_THRD_DISPOSITION.TITLE": "Samtaletredjeklassificeringens titel",
  "CONVERSATION.STATUS": "Samtalestatus",
  "CONVERSATION.CREATED_AT": "Samtale oprettet",
  "CONVERSATION.PRIORITY": "Samtaleprioritet",
  "CONVERSATION.USER_ID": "Samtaleagent-id",
  "USER.ID": "Agent",
  "CONVERSATION.STATUS_AT": "Samtalestatus ændret",
  "CONVERSATION.RESPONSE_TIME": "Samtalesvarstid",
  "CONVERSATION.RESOLUTION_TIME": "Samtaleløsningstid",
  "CONVERSATION.MAIN_CHANNEL": "Samtalens hovedkanal",
  "CONVERSATION.DIRECTION": "Samtalens startretning",
  "CONVERSATION.TAGS": "Samtaletags",
  "CONVERSATION.CHANNELS": "Samtalekanaler",
  "CONVERSATION.DATA": "Samtalens tilpassede felter",
  "MAIL_MESSAGE.ID": "E-mailbesked-id",
  "MAIL_MESSAGE.TITLE": "E-mailbeskedtitel",
  "MAIL_MESSAGE.SUBJECT": "E-mailemne",
  "MAIL_MESSAGE.MAIL_ACCOUNT_ID": "E-mailkonto",
  "MAIL_MESSAGE.CONTACT_EMAIL": "Kontaktens e-mailadresse",
  "MAIL_MESSAGE.CREATED_AT": "E-mailbesked oprettet",
  "CONTACT.ID": "Kontakt-id",
  "CONTACT.EMAILS": "Kontaktens e-mailadresser",
  "CONTACT.PHONES": "Kontaktens telefonnumre",
  "CONTACT.FIRST_NAME": "Kontaktens fornavn",
  "CONTACT.LAST_NAME": "Kontaktens efternavn",
  "CONTACT.DATA": "Kontaktens tilpassede felter",
  FORM_SUBMISSION: "Formularindsendelse",
  "FORM_SUBMISSION.ID": "Formularindsendelse-id",
  "FORM_SUBMISSION.FORM_WEBSITE_ID": "Formularwebsite",
  "FORM_SUBMISSION.CONTACT_EMAIL": "Formularkontaktens e-mail",
  "FORM_SUBMISSION.CONTACT_PHONE": "Formularkontaktens telefon",
  FORM_WEBSITE: "Formularwebsite",
  "FORM_WEBSITE.ID": "Formularwebsite-id",
  "MAIL_ACCOUNT.ID": "E-mailkonto-id",
  "DRAFT.CREATED_AT": "Kladde oprettet",
  "INTERNAL_NOTE.CREATED_AT": "Intern note oprettet",
  "VOICE_CALL.ID": "Stemmeopkald-id",
  "VOICE_CALL.VOICE_ASTERISK_ID": "Stemme-asterisk",
  "VOICE_CALL.VOICE_ROUTE_ID": "Stemmerute",
  "VOICE_CALL.QUEUE_ID": "Stemmeopkaldskø",
  VOICE_CALL_BRIDGES_COUNT: "Antal stemmeopkaldsbroer",
  "VOICE_CALL.CONTACT_PHONE": "Stemmeopkaldskontaktens telefon",
  "VOICE_CALL.CREATED_AT": "Stemmeopkald oprettet",
  "VOICE_CALL.DIRECTION": "Stemmeopkaldsretning",
  "VOICE_CALL.END_TIME": "Stemmeopkald afsluttet",
  VOICE_CALL_FIRST_ANSWERED_AT: "Stemmeopkald først besvaret",
  "VOICE_CALL.VARIABLES": "Stemmevariabler",

  "VOICE_CALL_DURATION.TOTAL": "Total opkaldstid (sekunder)",
  "VOICE_CALL_DURATION.PRE_QUEUE_TIME": "Tid før kø (sekunder)",
  "VOICE_CALL_DURATION.WAIT_TIME": "Ventetid (sekunder)",
  "VOICE_CALL_DURATION.TALK_TIME": "Taletid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Nuværende opkaldsbros totale tid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Nuværende opkaldsbros tid før kø (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Nuværende opkaldsbros ventetid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Nuværende opkaldsbros taletid (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Første opkaldsbros totale tid (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME": "Første opkaldsbros tid før kø (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Første opkaldsbros ventetid (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Første opkaldsbros taletid (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Sidste opkaldsbros totale tid (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME": "Sidste opkaldsbros tid før kø (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Sidste opkaldsbros ventetid (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Sidste opkaldsbros taletid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE.ID": "Nuværende opkaldsbros ID",
  "CURRENT_VOICE_CALL_BRIDGE.DIRECTION": "Nuværende opkaldsbros retning",
  "CURRENT_VOICE_CALL_BRIDGE.CREATED_AT": "Nuværende opkaldsbro oprettet",
  "CURRENT_VOICE_CALL_BRIDGE.ANSWER_TIME": "Nuværende opkaldsbro besvaret",
  "CURRENT_VOICE_CALL_BRIDGE.LEAVE_AT": "Nuværende opkaldsbro forladt",
  "CURRENT_VOICE_CALL_BRIDGE.USER_ID": "Nuværende opkaldsbros agent",
  "CURRENT_VOICE_CALL_BRIDGE.QUEUE_ID": "Nuværende opkaldsbros kø",
  "CURRENT_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Nuværende opkaldsbro er eksternt opkald",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TWINNED": "Nuværende opkaldsbro er twinnet",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFER": "Nuværende opkaldsbro er overførsel",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Nuværende opkaldsbro er overført",
  "CURRENT_VOICE_CALL_BRIDGE.EXIT_REASON": "Nuværende opkaldsbros afslutningsårsag",
  "FIRST_VOICE_CALL_BRIDGE.ID": "Første opkaldsbros ID",
  "FIRST_VOICE_CALL_BRIDGE.DIRECTION": "Første opkaldsbros retning",
  "FIRST_VOICE_CALL_BRIDGE.CREATED_AT": "Første opkaldsbro oprettet",
  "FIRST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Første opkaldsbro besvaret",
  "FIRST_VOICE_CALL_BRIDGE.LEAVE_AT": "Første opkaldsbro forladt",
  "FIRST_VOICE_CALL_BRIDGE.USER_ID": "Første opkaldsbros agent",
  "FIRST_VOICE_CALL_BRIDGE.QUEUE_ID": "Første opkaldsbros kø",
  "FIRST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Første opkaldsbro er eksternt opkald",
  "FIRST_VOICE_CALL_BRIDGE.IS_TWINNED": "Første opkaldsbro er twinnet",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Første opkaldsbro er overførsel",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Første opkaldsbro er overført",
  "FIRST_VOICE_CALL_BRIDGE.EXIT_REASON": "Første opkaldsbros afslutningsårsag",
  "LAST_VOICE_CALL_BRIDGE.ID": "Sidste opkaldsbros ID",
  "LAST_VOICE_CALL_BRIDGE.DIRECTION": "Sidste opkaldsbros retning",
  "LAST_VOICE_CALL_BRIDGE.CREATED_AT": "Sidste opkaldsbro oprettet",
  "LAST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Sidste opkaldsbro besvaret",
  "LAST_VOICE_CALL_BRIDGE.LEAVE_AT": "Sidste opkaldsbro forladt",
  "LAST_VOICE_CALL_BRIDGE.USER_ID": "Sidste opkaldsbros agent",
  "LAST_VOICE_CALL_BRIDGE.QUEUE_ID": "Sidste opkaldsbros kø",
  "LAST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Sidste opkaldsbro er eksternt opkald",
  "LAST_VOICE_CALL_BRIDGE.IS_TWINNED": "Sidste opkaldsbro er twinnet",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Sidste opkaldsbro er overførsel",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Sidste opkaldsbro er overført",
  "LAST_VOICE_CALL_BRIDGE.EXIT_REASON": "Sidste opkaldsbros afslutningsårsag",
  "USER.USERNAME": "Agentens brugernavn",
  "USER.EMAIL": "Agentens e-mail",
  "USER.FIRST_NAME": "Agentens fornavn",
  "USER.LAST_NAME": "Agentens efternavn",
  "USER.FULL_NAME": "Agentens fulde navn",
  "_QUEUE.AGENTS_READY": "Klar agenter i kø",
  "_QUEUE.AGENTS_PAUSED": "Pauserede agenter i kø",
  "_QUEUE.AGENTS_BUSY": "Optagne agenter i kø",
  "_QUEUE.AGENTS_TOTAL": "Samlet antal agenter i kø",
  "_QUEUE.LONGEST_WAITING": "Længste ventetid i kø",
  "_QUEUE.WAITING": "Ventende i kø",
  PRIORITY: "Prioritet",
  DISPOSITION_ID: "Klassificering",
  SUB_DISPOSITION_ID: "Underklassificering",
  THRD_DISPOSITION_ID: "Tredjeklassificering",
  DISPOSITION_NOTE: "Klassificeringsnotat",
  STATUS: "Status",
  ADD_TAG: "Tilføj tag",
  REMOVE_TAG: "Fjern tag",
  STATUS_O: "Åben",
  STATUS_RO: "Genåbnet",
  STATUS_C: "Lukket",
  STATUS_R: "Løst",
  STATUS_P: "Afventende",
  STATUS_W: "Venter på tredjepart",

  PRIORITY_URGENT: "Haster",
  PRIORITY_HIGH: "Høj",
  PRIORITY_MEDIUM: "Medium",
  PRIORITY_LOW: "Lav",
  PRIORITY_U: "Haster",
  PRIORITY_H: "Høj",
  PRIORITY_M: "Medium",
  PRIORITY_L: "Lav",

  DIRECTION_INBOUND: "Indgående",
  DIRECTION_OUTBOUND: "Udgående",
  DIRECTION_INTERNAL: "Intern",
  DIRECTION_I: "Indgående",
  DIRECTION_O: "Udgående",
  "DIRECTION_-": "Intern",

  EXIT_REASON_REJECT: "Afvist",
  EXIT_REASON_CONTACTHANGEDUP: "Kontakt lagde på",
  EXIT_REASON_TIMEOUT: "Timeout",
  EXIT_REASON_FULL: "Kø fuld",
  EXIT_REASON_JOINEMPTY: "Kø tilslut tom",
  EXIT_REASON_LEAVEEMPTY: "Kø forlad tom",
  EXIT_REASON_JOINUNAVAIL: "Kø tilslut utilgængelig",
  EXIT_REASON_LEAVEUNAVAIL: "Kø forlad utilgængelig",

  DEFINED: "Defineret",
  CUSTOM: "Tilpasset",

  NEWDRAFT: "Ny kladde oprettet",
  NEWINTERNALNOTE: "Ny intern note oprettet",
  CONVERSATIONUPDATED: "Samtale opdateret",
  CONVERSATIONCREATED: "Samtale oprettet",
  CONTACTUPDATED: "Kontakt opdateret",
  CONTACTCREATED: "Kontakt oprettet",
  NEWINBOUNDMAILMESSAGE: "Ny indgående e-mailbesked",
  NEWOUTBOUNDMAILMESSAGE: "Ny udgående e-mailbesked",
  SINCECONVERSATION: "Siden samtaleopdatering",
  NEWINBOUNDVOICECALL: "Nyt indgående stemmeopkald",
  NEWOUTBOUNDVOICECALL: "Nyt udgående stemmeopkald",
  NEWINTERNALVOICECALL: "Nyt internt stemmeopkald",
  INBOUNDVOICECALLHANGEDUP: "Indgående stemmeopkald afsluttet",
  OUTBOUNDVOICECALLHANGEDUP: "Udgående stemmeopkald afsluttet",
  INTERNALVOICECALLHANGEDUP: "Internt stemmeopkald afsluttet",
  INBOUNDVOICECALLANSWERED: "Indgående stemmeopkald besvaret",
  OUTBOUNDVOICECALLANSWERED: "Udgående stemmeopkald besvaret",
  INTERNALVOICECALLANSWERED: "Internt stemmeopkald besvaret",
  NEWINBOUNDVOICECALLBRIDGE: "Ny indgående opkaldsbro",
  NEWOUTBOUNDVOICECALLBRIDGE: "Ny udgående opkaldsbro",
  NEWINTERNALVOICECALLBRIDGE: "Ny intern opkaldsbro",
  INBOUNDVOICECALLBRIDGELEAVE: "Indgående opkaldsbro forladt",
  OUTBOUNDVOICECALLBRIDGELEAVE: "Udgående opkaldsbro forladt",
  INTERNALVOICECALLBRIDGELEAVE: "Intern opkaldsbro forladt",
  INBOUNDVOICECALLBRIDGEANSWERED: "Indgående opkaldsbro besvaret",
  OUTBOUNDVOICECALLBRIDGEANSWERED: "Udgående opkaldsbro besvaret",
  INTERNALVOICECALLBRIDGEANSWERED: "Intern opkaldsbro besvaret",

  CANNED_ANSWER_ID: "Standardsvar",
  CANNED_ANSWER_ID2: "Standardsvar 2",
  CANNED_ANSWER_ID3: "Standardsvar 3",
  CANNED_ANSWER_ID4: "Standardsvar 4",
  MAIL_ACCOUNT_ID: "E-mailkonto",
  DESCRIPTION: "Beskrivelse",
  URL: "Webadresse",
  STATUS_PORTS: "Statusporte",
  QUERY_PARAMS: "Forespørgselsparametre",
  HEADERS: "Headers",
  BODY: "Indhold",
  QUEUE_ID: "Kø",
  MILLISECONDS: "Millisekunder",
  TIMEOUT: "Timeout",
  OPERATIONAL_HOURS_ID: "Åbningstider",
  JOURNEY_ID: "Journey ID",
  PUBLISH: "Publicer",
  ENTER_FULLSCREEN: "Vis i fuld skærm",
  EXIT_FULLSCREEN: "Afslut fuld skærm",
  RESET: "Nulstil",
  APPLY: "Anvend",
  RELOAD_FROM_JOURNEY: "Genindlæs fra rejse",
  WHEN: "Når",
  WITHIN: "Inden for",
  NOT_WITHIN: "Ikke inden for",
  ERROR: "Fejl",
  SUCCESS: "Succes",
  OPTION_1: "Mulighed 1",
  OPTION_2: "Mulighed 2",
  OPTION_3: "Mulighed 3",
  OPTION_4: "Mulighed 4",
  OPTION_5: "Mulighed 5",
  OPTION_6: "Mulighed 6",
  OPTION_7: "Mulighed 7",
  OPTION_8: "Mulighed 8",
  OPTION_9: "Mulighed 9",
  RULE_1: "Regel 1",
  RULE_2: "Regel 2",
  RULE_3: "Regel 3",
  RULE_4: "Regel 4",
  RULE_5: "Regel 5",
  RULE_6: "Regel 6",
  RULE_7: "Regel 7",
  RULE_8: "Regel 8",
  RULE_9: "Regel 9",
  OTHERWISE: "Ellers",
  TRIGGERED: "Udløst",
  TIMED_OUT: "Tiden udløb",
  NEXT: "Næste",
  EDITED: "Redigeret",
  ASSIGNED: "Tildelt",
  TIME_RANGE: "Tidsinterval",
  MONTHS: "Måneder",
  DAYS: "Dage",
  DAY_S: "Dag(e)",
  WEEK_DAYS: "Ugedage",
  FROM: "Fra",
  ALWAYS: "Altid",
  INVALID_INTERVAL: "Ugyldigt interval",
  CREATED: "Oprettet",
  RESOLVED: "Løst",
  REOPENED: "Genåbnet",
  FIRSTASSIGNED: "Først tildelt",
  REQUESTERRESPONDED: "Anmoder svarede",
  AGENTRESPONDED: "Agent svarede",
  UNTILFIRSTRESPONSEDUE: "Indtil første svar forfalder",
  FIRSTRESPONSEOVERDUE: "Første svar forsinket",
  UNTILANYRESPONSEDUE: "Indtil ethvert svar forfalder",
  ANYRESPONSEOVERDUE: "Ethvert svar forsinket",
  RESOLUTIONTIMEOVERDUE: "Løsningstid forsinket",
  UNTILRESOLUTIONTIME: "Indtil løsningstid forfalder",
  WAITINGFORTHIRDPARTY: "Venter på tredjepart",
  UNIT: "Enhed",
  TIME: "Tid",
  RESPONSE_TIME: "Svartid",
  RESOLUTION_TIME: "Løsningstid",
  CONTACT_ID: "Kontakt",
  USER_ID: "Bruger-id",
  AGENT_ID: "Agent-id",
  HOURS_SHORT: "t",
  MINUTES_SHORT: "m",
  SECONDS_SHORT: "s",
  DAYS_SHORT: "d",
  YESTERDAY: "I går",
  JUST_NOW: "Lige nu",
  NOUPDATEDIN7DAYS: "Ingen opdateringer i 7 dage",
  CUSTOMERRESPONDED: "Kunden svarede",
  STATUS_AT: "Status ændret ved",
  ASSIGNED_AT: "Tildelt ved",
  FIRST_ASSIGNED_AT: "Først tildelt ved",
  CONVERSATION_UPDATE_DISPOSITION_ID: "Disposition opdateret",
  CONVERSATION_UPDATE_USER_ID: "Agent opdateret",
  CONVERSATION_UPDATE_STATUS: "Status ændret",
  CONVERSATION_UPDATE_FLAG: "Flag ændret",
  CONVERSATION_UPDATE_RESOLUTION_TIME: "Løsningstid ændret",
  CONVERSATION_UPDATE_RESPONSE_TIME: "Svartid ændret",
  CONVERSATION_UPDATE_QUEUE: "Kø ændret",
  CONVERSATION_UPDATE_PRIORITY: "Prioritet ændret",
  CONVERSATION_UPDATE_CONTACT: "Kontakt ændret",
  CONVERSATION_NEW_MESSAGE: "Ny besked",
  NEW_CONVERSATION: "Ny samtale",
  MINIMIZE: "Minimer",
  MAXIMIZE: "Maksimer",
  MINUTES: "Minutter",
  MINUTE_S: "Minut(ter)",
  SECOND_S: "Sekund(er)",
  HOURS: "Timer",
  HOUR_S: "Time(r)",
  LAST_UPDATED: "Sidst opdateret",
  SUBJECT: "Emne",
  CC: "Cc",
  BCC: "Bcc",
  DISPOSE_AND_CLOSE: "Klassificer & Luk",
  DISPOSE_AND_RESOLVE: "Klassificer & Løs",
  ADD_NEW: "Tilføj ny",
  CONFIRM_DELETION: "Er du sikker på, at du vil slette dette element?",
  OAUTH_AUTHENTICATION_NOT_FOUND: "Godkendelse mislykkedes!",
  OAUTH_AUTHENTICATION_NOT_FOUND_CONTENT:
    "En administrator i din organisation skal oprette en konto med din e-mailadresse, før du kan logge ind!",
  MAIL_ACCOUNT_AUTHENTICATED: "E-mailkonto godkendt!",
  AN_ERROR_OCCURRED: "Der opstod en fejl!",
  ATTACHMENTS: "Vedhæftninger",
  ATTACHMENT: "Vedhæftning",
  FILE_NOT_FOUND: 'Filen "{{filename}}" blev ikke fundet!',
  NOT_FOUND: "Ikke fundet!",
  PAGE_NOT_FOUND: "Siden blev ikke fundet!",
  PAGE_NOT_FOUND_DESCRIPTION:
    "Siden du leder efter findes ikke! Hvis du klikkede på et link der bragte dig hertil, så rapporter venligst problemet til din administrator!",
  NOTIFICATION_A: "Ny tildeling",
  NOTIFICATION_B: "Nyt svar på min samtale",
  NOTIFICATION_C: "Samtalemeddelelser jeg er tagget i",
  NOTIFICATION_D: "Statusopdatering på min samtale",
  NOTIFICATION_E: "Samtale tildelt til mig",
  NOTIFICATION_F: "Samtale tildelt til mine køer",
  NOTIFICATION_G: "Påmindelse om første svar",
  NOTIFICATION_H: "Påmindelse om løsningstid",
  NOTIFICATION_I: "Påmindelse om næste svar",
  NOTIFICATION_J: "Systeminfo",
  NOTIFICATION_K: "Systemfejl",
  NOTIFICATION_L: "Adgangskode udløber snart",
  NOTIFICATION_M: "Alarm",
  "NOTIFICATION_-": "Internt opkald",
  MARK_ALL_AS_SEEN: "Marker alle som set",
  CANNED_ANSWER: "Standardsvar",
  CONTACT_FIRST_NAME: "Kontaktens fornavn",
  CONTACT_LAST_NAME: "Kontaktens efternavn",
  CONTACT_FULL_NAME: "Kontaktens fulde navn",
  CONTACT_EMAIL: "Kontaktens e-mail",
  CONTACT_PHONE: "Kontaktens telefon",
  AGENT_FIRST_NAME: "Agentens fornavn",
  AGENT_LAST_NAME: "Agentens efternavn",
  AGENT_FULL_NAME: "Agentens fulde navn",
  CONVERSATION__ID: "Samtale-id",
  SELECT_CONTACT: "Vælg kontakt",
  MULTIPLE_CONTACTS_WHERE_FOUND: "Flere kontakter blev fundet!",
  MULTIPLE_CONTACTS_WHERE_FOUND_SELECT_CONTACT:
    "Flere kontakter blev fundet! Hvilken vil du bruge?",
  WHICH_ONE_DO_YOU_WANT_TO_USE: "Hvilken vil du bruge?",
  EMAILS: "E-mailadresser",
  PHONES: "Telefonnumre",
  STRATEGY: "Strategi",
  ROUND_ROUND_ROBIN: "Round Robin",
  VOICE_QUEUE_STRATEGY_RINGALL: "Ring til alle",
  VOICE_QUEUE_STRATEGY_ROUNDROBIN: "Round Robin",
  VOICE_QUEUE_STRATEGY_LEASTRECENT: "Mindst nylig",
  VOICE_QUEUE_STRATEGY_FEWESTCALLS: "Færrest opkald",
  VOICE_QUEUE_STRATEGY_RRMEMORY: "Cirkulær med hukommelse",
  VOICE_QUEUE_STRATEGY_LINEAR: "Lineær",
  VOICE_QUEUE_STRATEGY_WRANDOM: "Vægtet tilfældig",
  RINGALL: "Ring til alle",
  LEASTRECENT: "Mindst nylig",
  FEWESTCALLS: "Færrest opkald",
  RANDOM: "Tilfældig",
  WRANDOM: "Vægtet tilfældig",
  LINEAR: "Lineær",
  RRMEMORY: "Cirkulær med hukommelse",
  ROUNDROBIN: "Cirkulær",
  QUEUE_STRATEGY_ROUND_ROUND_ROBIN: "Cirkulær",
  QUEUE_STRATEGY_PING_ALL: "Ping alle",
  QUEUE_STRATEGY_LEAST_RECENT: "Mindst nylig",
  QUEUE_STRATEGY_FEWEST_CONVERSATIONS: "Færrest samtaler",
  QUEUE_STRATEGY_RANDOM: "Tilfældig",
  QUEUE_STRATEGY_SKILL_BASED_AGENTS_READY: "Kompetencebaseret agenter klar",
  QUEUE_STRATEGY_SKILL_BASED_TIMEOUT: "Kompetencebaseret timeout",
  CREATE_LIST_ID: "Opret liste",
  CREATE_LIST_ID_HELP: "Vælg liste at tilføje nye kontakter til",
  READ_LISTS: "Læs lister",
  READ_LISTS_HELP:
    "Lister at læse kontakter fra. Både system og agenter vil vælge kontakter der findes i disse lister.",
  CONVERSATION_FORM_ID: "Samtaleformular",
  SLA_POLICY_ID: "SLA-politik",
  INBOUND_SLA_POLICY_ID: "SLA-politik (indgående)",
  OUTBOUND_SLA_POLICY_ID: "SLA-politik (udgående)",
  REQUIRE_DISPOSITION: "Kræv klassificering",
  SETTINGS_REQUIRE_DISPOSITION_HELP:
    "Aktivér for at tvinge valg af klassificering før samtalen lukkes",
  REQUIRE_CLIENT: "Kræv klient",
  SETTINGS_REQUIRE_CLIENT_HELP: "Aktivér for at tvinge valg af klient før samtalen lukkes",
  CONVERSATION_ASSIGNMENT: "Samtaletildeling",
  SETTINGS_CONVERSATION_ASSIGNMENT_HELP:
    "Hvordan samtaler skal tildeles til agenter. Manuel tildeling vil altid være tilgængelig.",
  ONOPEN: "Ved åbning",
  ONREPLY: "Ved svar",
  ONCLOSE: "Ved lukning",
  MANUALLY: "Manuelt",
  CONVERSATION_ASSIGNMENT_ONOPEN: "Ved åbning",
  CONVERSATION_ASSIGNMENT_ONREPLY: "Ved svar",
  CONVERSATION_ASSIGNMENT_ONCLOSE: "Ved lukning",
  CONVERSATION_ASSIGNMENT_MANUALLY: "Manuelt",
  __DISPOSITIONS: "Klassificeringer",
  __DISPOSITIONS_HELP: "Tilgængelige klassificeringer at vælge for samtaler i denne kø.",
  __CANNED_ANSWERS: "Standardsvar",
  __AGENT_SIGNATURES: "Agentsignaturer",
  __OUTBOUND_MAIL_ACCOUNTS: "Udgående e-mailkonti",
  __OUTBOUND_MAIL_ACCOUNTS_HELP:
    "Tilgængelige udgående e-mailkonti ved oprettelse af nye udgående e-mailsamtaler",
  FORM_NULLABLE_ENABLE: "Definer værdi",
  FORM_NULLABLE_DISABLE: "Deaktiver værdi",
  EDIT_QUEUE: "Rediger kø",
  ADD_QUEUE: "Opret ny kø",
  DELETED: "Slettet",
  TWO_FACTOR_AUTH: "2FA",
  ORGANIZATION_ID: "Organisation",
  ADMINPROFILE_ID: "Adminprofil",
  SUPERADMIN_PROFILE_ID: "Superadminprofil",
  __PAUSES: "Pauser",
  __QUEUES: "Køer",
  __TEAMS: "Teams",
  TEAM: "Team",
  ADDRESS: "Adresse",
  INBOUND_JOURNEY_ID: "Indgående rejse",
  OUTBOUND_JOURNEY_ID: "Udgående rejse",
  IMAP: "IMAP",
  SMTP: "SMTP",
  HOST: "Vært",
  PORT: "Port",
  NAME: "Navn",
  TLS: "tls/ssl",
  REMOVE_ON_RECEIVE: "Fjern ved modtagelse",
  IMAP_REMOVE_ON_RECEIVE_HELP: "Fjern e-mail fra indbakken når den modtages",
  BASIC: "Grundlæggende",
  XOAUTH2: "Oauth 2",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASIC: "Grundlæggende",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICGOOGLE: "Grundlæggende (Google)",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICMICROSOFT: "Grundlæggende (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2MICROSOFT: "Moderne autentificering (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2GOOGLE: "Moderne autentificering (Google)",
  JOURNEIES: "Journeys",
  JOURNEY_DRAFTS: "Journey drafts",
  FORM_CHANNEL: "Formularkanal",
  FORM_WEBSITES: "Formularwebsites",
  TIME_INTERVALS: "Tidsintervaller",
  OPERATIONAL_HOURSES: "Åbningstider",
  OPERATIONAL_HOURS: "Åbningstider",
  SLA_POLICIES: "SLA-politikker",
  CANNED_ANSWERS: "Standardsvar",
  SIGNATURES: "Signaturer",
  FORMS: "Formularer",
  STAFF: "Personale",
  SEARCH_AGENTS: "Søg agenter",
  STATUS_0: "Offline",
  STATUS_1: "Online",
  STATUS_4: "Klar (E-mail)",
  STATUS_8: "Klar (Tale)",
  STATUS_512: "Klar",
  STATUS_1024: "Delvist optaget",
  STATUS_2048: "Håndterer samtale",
  STATUS_4096: "Pinger",
  STATUS_32768: "Pause",
  STATUS_262144: "Optaget (E-mail)",
  STATUS_524288: "Optaget (Tale)",
  STATUS_1048576: "Optaget (Formular)",
  STATUS_33554432: "Optaget",
  FILTER: "Filter",
  ITEMS: "Elementer",
  ALLOW: "Tillad",
  CANNEDANSWER: "Standardsvar",
  FORM: "Formular",
  SIGNATURE: "Signatur",
  SLAPOLICY: "SLA-politik",
  OPERATIONALHOURS: "Åbningstider",
  TAG: "Tag",
  MAILACCOUNT: "E-mailkonto",
  READ: "Læs",
  CREATE: "Opret",
  DELETE: "Slet",
  PERMISSIONS: "Tilladelser",
  TIMEINTERVAL: "Tidsinterval",
  LOGIN_STRATEGY: "Login-type",
  LOGIN_STRATEGY_LOCAL: "Brugernavn / Adgangskode",
  LOGIN_STRATEGY_GOOGLE: "Google",
  LOGIN_STRATEGY_MICROSOFT: "Microsoft",
  ADMIN_AGENT: "Admin & Agent",
  FORMWEBSITE: "Formularwebsite",
  SCHEMA_NOT_FOUND: "Formularen kunne ikke indlæses!",
  ITEM_NOT_FOUND: "Elementet du forsøger at indlæse kunne ikke findes!",
  ADMIN_ORGANIZATIONS_CHANGED_RELOAD:
    "Tildelte organisationer for din bruger er blevet opdateret, genindlæs venligst manuelt for at applikationen fungerer korrekt!",
  LOADING_DEPENDENCIES: "Indlæser afhængigheder",
  PARENT: "Overordnet",
  ACTIVE: "Aktiv",
  ORGANIZATION_2FA_HELP: "Vælg roller for at tvinge tofaktorgodkendelse",
  SECONDS: "Sekunder",
  PROPERTY: "Egenskab",
  VALUE: "Værdi",
  SIGNATURE_ID: "Signatur",
  METHOD: "Metode",
  CONDITIONS: "Betingelser",
  ADD_CONDITION: "Tilføj betingelse",
  FLAG: "Flag",
  SOURCE: "Kilde",
  SINCE: "Siden",
  JOURNEY_DRAFT: "Rejseudkast",
  PENDING: "Afventer",
  START_CONVERSATION: "Start samtale",
  NEW_V_CONVERSATION: "Ny SIP-samtale",
  NEW_M_CONVERSATION: "Ny e-mailsamtale",
  CREATE_NEW_CONTACT: "Opret ny kontakt",
  OR_CREATE_NEW_CONTACT: "eller opret ny kontakt",
  LOADING_FORM: "Indlæser formular",
  __TAGS: "Tags",
  MAIN_CHANNEL: "Hovedkanal",
  CREATE_CONVERSATION: "Opret samtale",
  CHOOSE_AGENT: "Vælg agent",
  CHOOSE_QUEUE: "Vælg kø",
  SELECTED: "Valgt",
  CHANGE_CONTACT: "Skift kontakt",
  CREATE_CONTACT: "Opret kontakt",
  REQUIRED: "Påkrævet",
  CONVERSATION_STATUS: "Samtalestatus",
  NO_NAME: "Intet navn",
  DISPLAY_TITLE: "Visningstitel",
  UI_TYPE: "UI-type",
  VALIDATION: "Validering",
  MIN: "Min",
  CHILD_TYPE: "Undertype",
  VALID: "Gyldig",
  REQUIRED_ON_SAVE: "Påkrævet ved gemning",
  REQUIRED_ON_CONVERSATION_CLOSE: "Påkrævet ved samtalelukning",
  REQUIRED_ON_FORM_SUBMIT: "Påkrævet ved webformularindsendelse",
  ALIAS: "Alias",
  ALIAS__KEY: "Alias / nøgle",
  FIELD_TYPE_DESCRIPTION: "Felttypen gør det tilgængeligt for forskellige formulartyper",
  MINIMUM: "Minimum",
  VALIDATION_MINIMUM_NUMBER_HELP: "Mindste tal der kan udfyldes i dette felt",
  VALIDATION_MINIMUM_LIST_HELP: "Mindste antal valgmuligheder",
  VALIDATION_MINIMUM_TEXT_HELP: "Mindste antal tegn",
  SETTINGS_VALIDATION_CHILD_TYPE_HELP: "Type af listevalgmulighed",
  FIELD_ALIAS_HELP:
    "Værdier vil blive gemt med dette unikke alias. Kun alfanumeriske tegn og _ er gyldige tegn.",
  OPTIONS: "Valgmuligheder",
  __FIELDS: "Felter",
  WEBSITE: "Website",
  POLICY: "Politik",
  FIRST_RESPONSE: "Første svar",
  EVERY_RESPONSE: "Hvert svar",
  URGENT: "Haster",
  SLA_POLICY: "SLA-politik",
  SEARCH_RESULTS: "Søgeresultater",
  UNASSIGNED: "Ikke tildelt",
  NO_QUEUE: "Ingen kø",
  NO_PAUSE: "Ingen pause",
  NOT_DISPOSED: "Ikke klassificeret",
  NO_FLAG: "Intet flag",
  FLAGS: "Flags",
  INVALID_2FA_SECRET: "Ugyldig kode",
  __TIME_INTERVALS: "Tidsintervaller",
  TIME_INTERVAL: "Tidsinterval",
  TIMEZONE: "Tidszone",
  RESET_PASSWORD_DONE: "En e-mail med instruktioner er blevet sendt til dig!",
  PASSWORD_RESET_EMAIL_WILL_BE_SENT:
    "Vi sender en e-mail med instruktioner til kontoen med det angivne brugernavn, hvis kontoen eksisterer!",
  DISPOSITION_REQUIRED_ON_THIS_QUEUE:
    "Klassificering skal angives ved lukning/løsning af samtale, ifølge køindstillinger.",
  CLIENT_REQUIRED_ON_THIS_QUEUE:
    "Klient skal angives ved lukning/løsning af samtale, ifølge køindstillinger.",
  CONVERSATION_FORM_NOT_VALID_ON_SAVE:
    "Samtaleformularen har obligatoriske felter, der skal udfyldes, før du kan redigere samtalen.",
  CONVERSATION_FORM_NOT_VALID_ON_CLOSE:
    "Samtaleformularen har obligatoriske felter, der skal udfyldes, før du kan løse/lukke samtalen.",
  CONTACT_FORM_NOT_VALID_ON_SAVE:
    "Kontaktformularen har obligatoriske felter, der skal udfyldes, før du kan redigere samtalen.",
  CONTACT_FORM_NOT_VALID_ON_CLOSE:
    "Kontaktformularen har obligatoriske felter, der skal udfyldes, før du kan løse/lukke samtalen.",
  CONVERSATION_CANNOT_BE_CLOSED_WITHOUT_USER:
    "Samtalen skal have en tildelt agent før lukning/løsning.",
  DISPOSITION_NOTE_NOT_ALLOWED_WHEN_NO_DISPOSITION_SELECTED:
    "Klassificeringsnotat er ikke tilladt uden valgt klassificering.",
  QUEUE_MAY_NOT_BE_NULL: "Kø må ikke være udefineret for samtalen.",
  AGENT_NOT_IN_SELECTED_QUEUE: "Tildelt agent er ikke tilknyttet den valgte kø.",
  CONTACT_ALREADY_SELECTED: "Kontakt allerede valgt for samtalen",
  QUEUE_NOT_FOUND_ON_CONVERSATION: "En kø skal tildeles samtalen før valg af kontakt.",
  NO_MESSAGES_FOUND_ON_CONVERSATION: "Ingen beskeder fundet i samtalen.",
  QUEUE_NO_READ_LISTS: "Køen har ingen tildelte læselister.",
  CONVERSATION_FORM_DATA_CLOSE_REQUIRED_TOOLTIP:
    "Obligatorisk felt når samtalestatus sættes til lukket",
  CONVERSATION_FORM_DATA_CLOSE_TOOLTIP: "Obligatorisk felt ved opdatering af samtaleegenskaber",
  VIEW_CONVERSATION: "Vis samtale",
  START_NEW_MAIL_CONVERSATION: "Start ny e-mailsamtale",
  START_NEW_VOICE_CONVERSATION: "Start ny stemmesamtale",
  NULLABLE: "Kan være null",
  SETTINGS_NULLABLE_HELP: 'Værdi kan sættes til "null"',
  UI_DATA_TYPE: "UI / Datatype",
  LAST_SAVE: "Sidst gemt",
  TITLE_MISSING: "Titel mangler",
  SELECT_QUEUE: "Vælg kø",
  CREATE_INTERNAL_NOTE: "Opret intern note",
  SAVE_DRAFT: "Gem kladde",
  NO_SELECTABLE_DISPOSITIONS: "Ingen valgbare klassificeringer",
  SMTP_PORT_HELP: "Standard: 25, tls: 465",
  IMAP_PORT_HELP: "Standard: 143, tls: 993",
  FORM_ID: "Formular",
  CHANNEL: "Kanal",
  INTERVAL: "Interval",
  CANNED_ANSWER_TYPE_A: "Agent",
  CANNED_ANSWER_TYPE_Q: "Kø",
  CANNED_ANSWER_CHANNEL_M: "E-mail",
  CANNED_ANSWER_CHANNEL_S: "Sms",
  SIGNATURE_TYPE_A: "Agent",
  SIGNATURE_TYPE_Q: "Kø",
  SLA_TIME_UNIT_H: "Timer",
  SLA_TIME_UNIT_D: "Dage",
  FORM_TYPE_CONVERSATION: "Samtale",
  FORM_TYPE_CONTACT: "Kontakt",
  FORM_TYPE_WEBSITE: "Hjemmeside",
  FIELD_TYPE_CONVERSATION: "Samtale",
  FIELD_TYPE_CONTACT: "Kontakt",
  FIELD_TYPE_FORMSUBMISSION: "Formularindsendelse",
  FORM_ELEMENT_TYPE_EXPOSED_INPUT: "Tekst",
  FORM_ELEMENT_TYPE_EXPOSED_TEXT: "Lang tekst",
  FORM_ELEMENT_TYPE_EXPOSED_NUMBER: "Nummer",
  FORM_ELEMENT_TYPE_EXPOSED_SELECT: "Vælg",
  FORM_ELEMENT_TYPE_EXPOSED_LIST: "Liste",
  FORM_ELEMENT_TYPE_EXPOSED_SWITCH: "Kontakt",
  FORM_ELEMENT_TYPE_EXPOSED_RADIO: "Radio",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOXES: "Afkrydsningsfelter",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOX: "Afkrydsningsfelt",
  FORM_ELEMENT_TYPE_EXPOSED_EMAIL: "E-mail",
  FORM_ELEMENT_TYPE_EXPOSED_PHONE: "Telefonnummer",
  FORM_ELEMENT_TYPE_EXPOSED_HTML: "Html",
  FORM_ELEMENT_TYPE_EXPOSED_FILE: "Fil",

  FORM_ELEMENT_CHILD_TYPE_EXPOSED_INPUT: "Tekst",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_NUMBER: "Nummer",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_PHONE: "Telefonnummer",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_EMAIL: "E-mail",

  CHANNEL_VOICE: "Stemme",
  CHANNEL_VOICE_INBOUND: "Indgående stemmeopkald",
  CHANNEL_VOICE_INTERNAL: "Internt stemmeopkald",
  CHANNEL_VOICE_INTERNAL_LIVE: "Igangværende internt stemmeopkald",
  CHANNEL_VOICE_TRANSFER: "Stemmeoverførsel",
  CHANNEL_VOICE_TRANSFER_LIVE: "Igangværende stemmeoverførsel",
  CHANNEL_VOICE_INBOUND_LIVE: "Igangværende indgående stemmeopkald",
  CHANNEL_VOICE_OUTBOUND: "Udgående stemmeopkald",
  CHANNEL_VOICE_OUTBOUND_LIVE: "Igangværende udgående stemmeopkald",
  CHANNEL_EDIT: "Rediger",
  CHANNEL_CHAT: "Chat",
  CHANNEL_CHAT_INBOUND: "Indgående chatbesked",
  CHANNEL_CHAT_OUTBOUND: "Udgående chatbesked",
  CHANNEL_MAIL: "E-mail",
  CHANNEL_MAIL_INBOUND: "Indgående e-mail",
  CHANNEL_MAIL_OUTBOUND: "Udgående e-mail",
  CHANNEL_SMS: "SMS",
  CHANNEL_SMS_INBOUND: "Indgående SMS",
  CHANNEL_SMS_OUTBOUND: "Udgående SMS",
  CHANNEL_FORM: "Formular",
  CHANNEL_CHANSPY: "Kanalaflytning",
  CHANNEL_DISPOSITION_NOTE: "Klassificeringsnotat",
  INTERNAL_CALL: "Internt opkald",

  CHANNEL_V: "Stemme",
  "CHANNEL_-": "Ingen kanal",
  CHANNEL_C: "Chat",
  CHANNEL_M: "E-mail",
  CHANNEL_S: "SMS",
  CHANNEL_F: "Formular",
  CHANNEL_IN: "Intern note",
  CHANNEL_In: "Intern note",

  ADMINPROFILE_PERMISSION_ID_USER: "Bruger",
  ADMINPROFILE_PERMISSION_ID_TEAM: "Team",
  ADMINPROFILE_PERMISSION_ID_ORGANIZATION: "Organisation",
  ADMINPROFILE_PERMISSION_ID_MAILACCOUNT: "E-mailkonto",
  ADMINPROFILE_PERMISSION_ID_QUEUE: "Kø",
  ADMINPROFILE_PERMISSION_ID_LIST: "Liste",
  ADMINPROFILE_PERMISSION_ID_ADMINPROFILE: "Administratorprofil",
  ADMINPROFILE_PERMISSION_ID_AGENTPROFILE: "Agentprofil",
  ADMINPROFILE_PERMISSION_ID_SLAPOLICY: "SLA-politik",
  ADMINPROFILE_PERMISSION_ID_DISPOSITION: "Klassificering",
  ADMINPROFILE_PERMISSION_ID_FORM: "Formular",
  ADMINPROFILE_PERMISSION_ID_FIELD: "Felt",
  ADMINPROFILE_PERMISSION_ID_FORMWEBSITE: "Formularhjemmeside",
  ADMINPROFILE_PERMISSION_ID_JOURNEY: "Rejse",
  ADMINPROFILE_PERMISSION_ID_OPERATIONALHOURS: "Åbningstider",
  ADMINPROFILE_PERMISSION_ID_PAUSE: "Pause",
  ADMINPROFILE_PERMISSION_ID_CANNEDANSWER: "Standardsvar",
  ADMINPROFILE_PERMISSION_ID_SIGNATURE: "Signatur",
  ADMINPROFILE_PERMISSION_ID_TAG: "Tag",
  ADMINPROFILE_PERMISSION_ID_TIMEINTERVAL: "Tidsinterval",
  ADMINPROFILE_PERMISSION_ID_VOICEROUTE: "Stemmerute",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDING: "Stemmeoptagelse",
  ADMINPROFILE_PERMISSION_ID_SOUND: "Lyd",
  ADMINPROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Variabel",
  ADMINPROFILE_PERMISSION_ID_STATSREPORT: "Statistikrapport",
  ADMINPROFILE_PERMISSION_ID_STATSWIDGET: "Statistikwidget",
  ADMINPROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Destinationsbegrænser",
  ADMINPROFILE_PERMISSION_ID_INVOICE: "Faktura",
  ADMINPROFILE_PERMISSION_ID_VOICESHORTCUT: "Stemmegenvej",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Stemmebesked",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Stemmebeskedmeddelelse",
  ADMINPROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Telefonnummerkøb",
  ADMINPROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Tilpassede målinger",
  ADMINPROFILE_PERMISSION_ID_AUDIT: "Revision",
  ADMINPROFILE_PERMISSION_ID_APIKEY: "API-nøgle",
  ADMINPROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Kontakttilpasset hændelse",
  ADMINPROFILE_PERMISSION_ID_CLIENT: "Klient",
  ADMINPROFILE_PERMISSION_ID_CONTACT: "Kontakt",
  ADMINPROFILE_PERMISSION_ID_WALLBOARD: "Wallboard",
  ADMINPROFILE_PERMISSION_ID_SKILL: "Kompetence",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Stemmeoptagelsestranskript",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Stemmeoptagelsestranskriptindstillinger",
  ADMINPROFILE_PERMISSION_ID_POINTSSYSTEM: "Pointsystem",
  ADMINPROFILE_PERMISSION_ID_GDPR: "Sikkerhed & overholdelse",
  ADMINPROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymiser",
  ADMINPROFILE_PERMISSION_ID_STATSALARM: "Statistikalarm",
  ADMINPROFILE_PERMISSION_ID_VOICECALL: "Stemmeopkald",
  ADMINPROFILE_PERMISSION_ID_TEXTMESSAGE: "Tekstbesked",

  SUPERADMIN_PROFILE_PERMISSION_ID_USER: "Bruger",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEAM: "Team",
  SUPERADMIN_PROFILE_PERMISSION_ID_ORGANIZATION: "Organisation",
  SUPERADMIN_PROFILE_PERMISSION_ID_MAILACCOUNT: "E-mailkonto",
  SUPERADMIN_PROFILE_PERMISSION_ID_QUEUE: "Kø",
  SUPERADMIN_PROFILE_PERMISSION_ID_LIST: "Liste",
  SUPERADMIN_PROFILE_PERMISSION_ID_ADMINPROFILE: "Administratorprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_AGENTPROFILE: "Agentprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_SLAPOLICY: "SLA-politik",
  SUPERADMIN_PROFILE_PERMISSION_ID_DISPOSITION: "Klassificering",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORM: "Formular",
  SUPERADMIN_PROFILE_PERMISSION_ID_FIELD: "Felt",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORMWEBSITE: "Formularhjemmeside",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEY: "Rejse",
  SUPERADMIN_PROFILE_PERMISSION_ID_OPERATIONALHOURS: "Åbningstider",
  SUPERADMIN_PROFILE_PERMISSION_ID_PAUSE: "Pause",
  SUPERADMIN_PROFILE_PERMISSION_ID_CANNEDANSWER: "Standardsvar",
  SUPERADMIN_PROFILE_PERMISSION_ID_SIGNATURE: "Signatur",
  SUPERADMIN_PROFILE_PERMISSION_ID_TAG: "Tag",
  SUPERADMIN_PROFILE_PERMISSION_ID_TIMEINTERVAL: "Tidsinterval",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEROUTE: "Stemmerute",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDING: "Stemmeoptagelse",
  SUPERADMIN_PROFILE_PERMISSION_ID_SOUND: "Lyd",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Variabel",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSREPORT: "Statistikrapport",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSWIDGET: "Statistikwidget",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEASTERISK: "Stemmeasterisk",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFF: "Tarif",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFFPARENT: "Tarif parent",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOST: "Månedlig omkostning for telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOSTPARENT:
    "Overordnet månedlig omkostning for telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOST: "Opstartsgebyr for telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOSTPARENT:
    "Overordnet opstartsgebyr for telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_SUPERADMINPROFILE: "Superadministratorprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICE: "Faktura",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICEPOST: "Fakturapost",
  SUPERADMIN_PROFILE_PERMISSION_ID_PREPAIDCREDIT: "Forudbetalt kredit",
  SUPERADMIN_PROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Destinationsbegrænser",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Stemmebesked",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Stemmebeskedmeddelelse",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICESHORTCUT: "Stemmegenvej",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICETRUNK: "Stemmetrunk",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSTOCK: "Telefonnummerlager",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Telefonnummerkøb",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Tilpassede målinger",
  SUPERADMIN_PROFILE_PERMISSION_ID_RELEASE: "Udgivelser",
  SUPERADMIN_PROFILE_PERMISSION_ID_AUDIT: "Revision",
  SUPERADMIN_PROFILE_PERMISSION_ID_APIKEY: "API-nøgle",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Kontakttilpasset hændelse",
  SUPERADMIN_PROFILE_PERMISSION_ID_CLIENT: "Klient",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACT: "Kontakt",
  SUPERADMIN_PROFILE_PERMISSION_ID_SKILL: "Kompetence",
  SUPERADMIN_PROFILE_PERMISSION_ID_WALLBOARD: "Wallboard",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Stemmeoptagelsestranskript",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Stemmeoptagelsestranskriptindstillinger",
  SUPERADMIN_PROFILE_PERMISSION_ID_POINTSSYSTEM: "Pointsystem",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPR: "Sikkerhed & overholdelse",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymiser",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSALARM: "Statistikalarm",
  SUPERADMIN_PROFILE_PERMISSION_ID_AIASSISTANT: "AI-assistent",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICECALL: "Stemmeopkald",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEXTMESSAGE: "Tekstbesked",
  REPLY_OR_NOTE: "Svar eller note",

  __USERS: "Agenter",
  CONTACT_MANAGEMENT: "Kontakthåndtering",
  NO_QUEUES_SELECTED:
    "Du har ingen køer tilknyttet organisationer, der er tilknyttet dig. Kontakt din organisationsadministrator for at løse dette problem.",
  SOCKET_DISCONNECTED:
    "Din forbindelse til serveren er blevet afbrudt. Opdater venligst vinduet.\n\nAfbrydelser skyldes ustabil internetforbindelse eller på grund af inaktivitet.",
  SOCKET_DISCONNECTED_INACTIVITY: "Socket afbrudt på grund af inaktivitet",
  WARNING_INACTIVITY:
    'Du vil blive logget ud på grund af inaktivitet. Gå tilbage til atlas dashboard eller tryk på "Jeg er tilbage" for at forhindre afbrydelse.',
  IAM_BACK: "Jeg er tilbage!",
  NO_EMAIL_ACCESS:
    "Du har ikke tilladelse til at sende e-mails. Kontakt din administrator for mere information.",
  NO_CREATE_CHANNELS: "Du har ikke adgang til nogen kanaler for at oprette ny samtale.",
  NO_OUTBOUND_QUEUES_ON_CREATE_CONVERSATION:
    "Du har ikke adgang til nogen køer med udgående rute. Kontakt din organisationsadministrator for at løse dette problem.",
  SELECT_AGENTPROFILE_ERROR: "En agentprofil skal vælges for at oprette samtale.",
  SEARCH_AGENT_AND_ASSIGN: "Søg agent og tildel",
  SEARCH_QUEUE_AND_ASSIGN: "Søg kø og tildel",
  EDIT_TAGS: "Rediger tags",
  SEARCH_TAGS: "Søg tags",
  SELECT_DISPOSITION: "Vælg klassificering",
  MERGE: "Flet",
  MERGE_CONVERSATION: "Flet samtale",
  CONVERSATION_MERGE_EXPLANATION:
    "Interaktioner fra sekundære sager vil blive tilføjet til den primære sag",
  CONTACT_MERGE_EXPLANATION:
    "Samtaler fra sekundær kontakt vil blive tilføjet til den primære kontakt",
  SEARCH_CONVERSATION: "Søg samtale",
  SEARCH_CONTACT: "Søg kontakt",
  MERGE_CONTACT: "Flet kontakt",
  PRIMARY: "Primær",
  NO_AGENT: "Ingen agent",
  MAIL_ACCOUNT_IMAP_STATE_A: "Godkendt",
  MAIL_ACCOUNT_IMAP_STATE_N: "Ingen",
  MAIL_ACCOUNT_IMAP_STATE_E: "Fejl",
  MAIL_ACCOUNT_IMAP_STATE_U: "Uautoriseret",
  MAIL_ACCOUNT_IMAP_STATE_C: "Forbundet",
  MAIL_ACCOUNT_IMAP_STATE_CN: "Forbinder",
  MAIL_ACCOUNT_IMAP_STATE_D: "Afbrudt",
  MAIL_ACCOUNT_SMTP_STATE_A: "Godkendt",
  MAIL_ACCOUNT_SMTP_STATE_N: "Ingen",
  MAIL_ACCOUNT_SMTP_STATE_E: "Fejl",
  MAIL_ACCOUNT_SMTP_STATE_U: "Uautoriseret",
  MAIL_ACCOUNT_SMTP_STATE_C: "Forbundet",
  MAIL_ACCOUNT_SMTP_STATE_CN: "Forbinder",
  MAIL_ACCOUNT_SMTP_STATE_D: "Afbrudt",
  IMAP_STATE: "IMAP-status",
  SMTP_STATE: "SMTP-status",
  RECONNECT: "Genopret forbindelse",
  PERSONIFY_USER: "Personificér bruger",
  RULES: "Regler",
  JOURNEY_ERROR_LINK_MISSING: "Et link skal være forbundet til denne port",
  JOURNEY_ERROR_FORM_ERROR: "Der er fejl i nodeformularen. Dobbeltklik for at redigere formularen.",
  JOURNEY_ERROR_DUPLICATE:
    "Dobbelt handling! Indgangshandlinger må ikke forekomme mere end én gang.",
  PUBLISH_JOURNEY: "Publicer Journey",
  NO_ITEMS_WERE_FOUND: "Ingen elementer blev fundet",
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_PHRASE:
    'Kunne ikke finde nogen samtaler eller kontakter der matcher søgeudtrykket "{{keyword}}"',
  NO_CONVERSATIONS_WERE_FOUND_BY_PHRASE:
    'Ingen samtaler blev fundet der matcher søgeudtrykket "{{keyword}}"',
  NO_CONTACTS_WERE_FOUND_BY_PHRASE:
    'Ingen kontakter blev fundet der matcher søgeudtrykket "{{keyword}}"',
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT:
    "Kunne ikke finde nogen samtaler eller kontakter fra valgt kontakt",
  NO_CONVERSATIONS_WERE_FOUND_BY_SELECTED_CONTACT: "Ingen samtaler blev fundet fra valgt kontakt",
  NO_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT: "Ingen kontakter blev fundet fra valgt kontakt",
  NO_CONVERSATION_FOUND_ON_FILTER: "Ingen samtaler blev fundet der matcher dine filtre",
  WITH_SELECTED_DO: "Med valgte ændre",
  CHANGE_STATUS: "Ændre status",
  CHANGE_PRIORITY: "Ændre prioritet",
  APPLY_CHANGES_TO_ITEMS: "Anvend ændringer på {{count}} elementer",
  APPLY_AND_RESET: "Anvend & Nulstil",
  ITEMS_FAILED_TO_UPDATE: "Kunne ikke opdatere {{count}} elementer",
  CHANGES_APPLIED_TO_ITEMS: "Ændringer anvendt på {{count}} elementer",
  MAIL_HAS_NO_BODY: "Ingen e-mail indhold",
  TIME_PAST: "Tid gået",
  START_TYPING_TO_SEARCH: "Begynd at skrive for at søge",
  NOT_REMOVABLE_ENTITY: "Må ikke slettes!",
  SHOW_MORE: "Vis mere",
  SHOW_LESS: "Vis mindre",
  UPLOAD: "Upload",
  N_FILES: "{{files}} fil(er)",
  LIST_FILES: "List filer",
  LIST_FILE: "List fil",
  LIST_ID: "Liste",
  SHEET: "Ark",
  ROW: "Række",
  SHEET_N: "Ark {{n}}",
  ROW_N: "Række {{n}}",
  LIST_FILE_FIELDS_HELP: "Ovenstående rækker er en forhåndsvisning til at sortere kolonner.",
  WRITE_LISTS: "Skriv lister",
  WRITE_LISTS_HELP: "Lister at skrive importerede kontakter til",
  CLEAN_LISTS: "Rens lister",
  CLEAN_LISTS_HELP: "Lister at rense kontakter mod",
  DIALPAD: "Tastatur",
  NO_NOTIFICATIONS: "Du har ingen notifikationer!",
  NO_PAUSES_FOUND: "Ingen pauser kunne findes",
  CONNECTION_AT: "Forbindelse ved",
  RETRIES: "Forsøg",
  NO_CONTACT: "Kontakt blev ikke fundet!",
  VOICE_MANAGEMENT: "Stemmehåndtering",
  JOINED: "Tilsluttet",
  JOINED_QUEUE_DESCRIPTION:
    '"Besked" tilsluttede køen. Denne port udløses før forsøg på at distribuere "beskeden".',
  ON_ASSIGN: "Tildelt",
  DONE_ANSWERED: "Færdig (besvaret)",
  DONE_NO_ANSWER: "Færdig (intet svar)",
  ON_ASSIGN_QUEUE_DESCRIPTION: "Samtale blev tildelt valgt kø",
  DONE_ANSWERED_QUEUE_DESCRIPTION:
    "Stemmeopkald forlod køen på grund af afslutning, efter at være blevet tildelt en agent",
  DONE_NO_ANSWER_QUEUE_DESCRIPTION:
    "Stemmeopkald forlod køen på grund af timeout eller tom kø (afhængigt af køindstillingerne)",
  HANGUP: "Læg på",
  RECORD: "Optag",
  HOLD: "Parker",
  UNHOLD: "Genoptag",
  TRANSFER: "Overfør",
  MUTE: "Lydløs",
  UNMUTE: "Aktiver lyd",
  DISTRIBUTING_CALL: "Distribuerer opkald",
  VOICE_ASTERISK: "Asterisk",
  VOICE_ASTERISKS: "Asterisker",
  VOICE: "Stemme",
  VOICE_TRUNK: "Trunk",
  VOICE_TRUNKS: "Trunks",
  VOICE_ROUTE: "Rute",
  VOICE_ROUTES: "Ruter",
  SOUND: "Lyd",
  SOUNDS: "Lyde",
  JOURNEY_VARIABLE: "Variabel",
  JOURNEY_VARIABLES: "Variabler",
  REST: "Rest",
  FROM_DOMAIN: "Fra domæne",
  ISO2: "ISO 2",
  VOICE_ASTERISK_ID: "Asterisk",
  INBOUND_VOICE_TRUNK_ID: "Indgående trunk",
  OUTBOUND_VOICE_TRUNK_ID: "Udgående trunk",
  NUMBER: "Nummer",
  INTERNAL: "Intern",
  VOICE_SETTINGS: "Stemmeindstillinger",
  __OUTBOUND_VOICE_ROUTES: "Udgående stemmeruter",
  VOICE_RECORDING_FORMAT_WAV: "Wav",
  VOICE_RECORDING_FORMAT_WAV49: "Wav49",
  VOICE_RECORDING_FORMAT_GSM: "Gsm",
  NEW_CALL: "Nyt opkald",
  START_RECORDING: "Manuel optagelse",
  SETTINGS_START_RECORDING_HELP: "Tillad agenter at optage opkald manuelt",
  PLAY_RECORDING: "Afspil optagelse",
  DOWNLOAD_RECORDING: "Download optagelse",
  DOWNLOAD_VOICEMAIL: "Download voicemail",
  PERMISSION_SELF_N: "Ingen",
  PERMISSION_SELF_A: "Alle",
  PERMISSION_SELF_S: "Ejet af bruger",
  DOWNLOAD_RECORDING_ERROR: "Noget gik galt ved download af optagelsen.",
  PLAY_RECORDING_ERROR: "Noget gik galt ved afspilning af optagelsen.",
  DOWNLOAD_VOICEMAIL_ERROR: "Noget gik galt ved download af voicemail.",
  PLAY_VOICEMAIL_ERROR: "Noget gik galt ved afspilning af voicemail.",
  NO_RESULTS: "Ingen resultater",
  VARIABLE_ALIAS: "Variabelalias",
  VOICE_CALL: "Stemmeopkald",
  CURRENT_VOICE_CALL_BRIDGE: "Nuværende opkaldsbro",
  FIRST_VOICE_CALL_BRIDGE: "Første opkaldsbro",
  LAST_VOICE_CALL_BRIDGE: "Sidste opkaldsbro",
  HANGED_UP: "Lagde på",
  EDGE_ERROR_FROM_NONE_CHANNEL_SPECIFIC_TO_CHANNEL_SPECIFIC:
    "Links mellem målnode med specifikke kanaler til kildenode uden specifik kanal er ikke tilladt",
  EDGE_ERROR_CHANNEL_DONT_EXIST_IN_FROM_NODE:
    "Alle kanaler i målnoden skal være tilgængelige i kildenoden",
  EDGE_ERROR_CHANNELS_MISMATCH: "Kildenodens kanal matcher ikke målnodens kanaler!",
  JOURNEY_VARIABLE_ID: "Variabel",
  REST_REQUEST_VARIABLE_HELP:
    "Hvis vellykket, sæt rest-anmodningens svar til valgt variabel. Data vil blive konverteret til streng.",
  REST_REQUEST_FIELD_HELP:
    "Hvis vellykket, sæt rest-anmodningens svar til valgt felt. Data vil blive konverteret til felttype.",
  FIELD_ID: "Felt",
  DATA_TYPE: "Datatype",
  ACW: "ACW",
  VOICE_SETTINGS_ACW_HELP: "After call work",
  MUSICCLASS: "Ventemusik",
  JOINEMPTY: "Tilslut tom",
  FAIL_REASONS: "Fejlårsager",
  FULL: "Fuld",
  LEAVEEMPTY: "Forlad tom",
  JOINUNAVAIL: "Tilslut utilgængelig",
  LEAVEUNAVAIL: "Forlad utilgængelig",
  EVENTS: "Begivenheder",
  EVENT_ASSIGNED_DESCRIPTION: "Stemmeopkald blev tildelt kø",
  EVENT_JOINED_DESCRIPTION: "Stemmeopkald tilsluttede kø",
  FAIL_REASON_FULL_DESCRIPTION: "Afsluttet da køen var fuld",
  FAIL_REASON_TIMEOUT_DESCRIPTION: "Afsluttet da køens tidsgrænse blev nået",
  FAIL_REASON_JOINEMPTY_DESCRIPTION: 'Afsluttet på grund af køstatus "JOINEMPTY"',
  FAIL_REASON_LEAVEEMPTY_DESCRIPTION: 'Afsluttet på grund af køstatus "LEAVEEMPTY"',
  FAIL_REASON_JOINUNAVAIL_DESCRIPTION: 'Afsluttet på grund af køstatus "JOINUNAVAIL"',
  FAIL_REASON_LEAVEUNAVAIL_DESCRIPTION: 'Afsluttet på grund af køstatus "LEAVEUNAVAIL"',
  VOICE_QUEUE_QUEUE_STATUS: "Køstatus",
  VOICE_SETTINGS_JOINEMPTY_HELP: "Tilslut til kø når tom",
  LEAVEWHENEMPTY: "Forlad når tom",
  VOICE_SETTINGS_LEAVEWHENEMPTY_HELP: "Forlad kø når tom",
  RINGINUSE: "Ring ved optaget",
  VOICE_SETTINGS_RINGINUSE_HELP:
    "Styrer om agenten skal modtage yderligere køopkald selv når agenten er optaget.",
  MAXLEN: "Maksimalt antal køopkald",
  VOICE_SETTINGS_MAXLEN_HELP:
    "Det maksimale antal opkald der tillades at vente i kø. Opkald der når køen over denne grænse vil fortsætte Journey-eksekveringsstrategien.",
  VOICE_SETTINGS_TIMEOUT_HELP:
    "Hvor længe det skal ringe hos en agent før agenten anses for utilgængelig.",
  WEIGHT: "Vægt",
  VOICE_SETTINGS_WEIGHT_HELP:
    "Angiver prioritet for denne kø når dens agenter er medlemmer af flere køer. En højere vægtværdi betyder at denne kø får prioritet over tilgængelige agenter.",
  AUTOPAUSE: "Autopause",
  VOICE_SETTINGS_AUTOPAUSE_HELP:
    "Autopause sætter et kømedlem på pause hvis de ikke besvarer et opkald.",
  ANNOUNCE: "Annoncer",
  VOICE_SETTINGS_ANNOUNCE_HELP:
    "Meddelelse der afspilles for den svarende agent før opkaldet forbindes, typisk for at informere agenten om hvilken kø der ringer.",
  ANNOUNCE_FREQUENCY: "Annoncefrekvens",
  VOICE_SETTINGS_ANNOUNCE_FREQUENCY:
    "Hvor ofte i sekunder opkalderens position i køen eller den estimerede ventetid skal annonceres. Sæt til 0 for at deaktivere.",
  MIN_ANNOUNCE_FREQUENCY: "Minimum annoncefrekvens",
  VOICE_SETTINGS_MIN_ANNOUNCE_FREQUENCY_HELP:
    "Minimum tid mellem start af hver annoncering. Dette er nyttigt for køer hvor opkalderens position og/eller ventetid ændrer sig hurtigt for at undgå konstante annonceringer.",
  PERIODIC_ANNOUNCE_FREQUENCY: "Periodisk annoncefrekvens",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_FREQUENCY_HELP:
    "Hvor ofte periodiske annonceringer skal foretages.",
  RANDOM_PERIODIC_ANNOUNCE: "Tilfældige periodiske annonceringer",
  VOICE_SETTINGS_RANDOM_PERIODIC_ANNOUNCE_HELP:
    "Afspil periodiske annonceringer i tilfældig rækkefølge.",
  RELATIVE_PERIODIC_ANNOUNCE: "Relative periodiske annonceringer",
  VOICE_SETTINGS_RELATIVE_PERIODIC_ANNOUNCE_HELP:
    "Hvis sat til ja vil den periodiske annoncefrekvens blive talt fra slutningen af hver annoncering i stedet for fra starten af hver annoncering.",
  ANNOUNCE_HOLDTIME: "Annoncer ventetid",
  VOICE_SETTINGS_ANNOUNCE_HOLDTIME_HELP:
    "Afspil den estimerede ventetid for opkaldere der venter i køen.",
  ANNOUNCE_POSITION: "Annoncer position",
  VOICE_SETTINGS_ANNOUNCE_POSITION_HELP:
    'Afspil køpositionen for opkaldere der venter i køen. Når du bruger mulighederne "grænse" eller "mere" skal du også indstille Annoncer positionsgrænse.',
  ANNOUNCE_POSITION_LIMIT: "Annoncer positionsgrænse",
  VOICE_SETTINGS_ANNOUNCE_POSITION_LIMIT_HELP:
    'Angiver grænsen for hvornår opkalderens position i køen skal afspilles. Hvis "Annoncer position" er sat til "grænse" vil positionen kun blive afspillet for dem under grænsen. Hvis sat til "mere" vil den blive afspillet for opkaldere over grænsen.',
  ANNOUNCE_POSITION_ONLY_UP: "Annoncer positionsændring",
  VOICE_SETTINGS_ANNOUNCE_POSITION_ONLY_UP_HELP:
    "Annoncer kun opkalderens position hvis den er forbedret siden sidste annoncering.",
  REPORTHOLDTIME: "Rapporter ventetid",
  VOICE_SETTINGS_REPORTHOLDTIME_HELP:
    "Rapporter ventetiden til den svarende agent før opkaldet forbindes til agenten.",
  PERIODIC_ANNOUNCE: "Periodiske annonceringer",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_HELP:
    "Vælg de periodiske annonceringer og deres rækkefølge. Når den sidste annoncering er afspillet, starter afspilningen forfra.",
  ASTERISK_MUSICCLASS_DEFAULT: "Standard",
  ASTERISK_MUSICCLASS_CELTIC: "Keltisk",
  ASTERISK_MUSICCLASS_JAZZ: "Jazz",
  ASTERISK_MUSICCLASS_CHILLOUT: "Afslapning",
  ASTERISK_MUSICCLASS_GOODBYE: "Farvel",
  ASTERISK_MUSICCLASS_YESTERDAY: "I går",
  ASTERISK_MUSICCLASS_BLUES: "Blues",
  ASTERISK_MUSICCLASS_UPBEAT: "Opadgående",
  ASTERISK_MUSICCLASS_WALTZ: "Vals",
  ASTERISK_MUSICCLASS_CLAPS: "Klap",
  ASTERISK_MUSICCLASS_CHEERFUL: "Glad",
  ASTERISK_MUSICCLASS_LULLABY: "Vuggevise",
  ASTERISK_MUSICCLASS_HAPPY: "Lykkelig",
  ASTERISK_MUSICCLASS_HIDDEN: "Skjult",
  ASTERISK_AUTOPAUSE_YES: "Ja",
  ASTERISK_AUTOPAUSE_NO: "Nej",
  ASTERISK_AUTOPAUSE_ALL: "Alle",
  ASTERISK_ANNOUNCE_HOLDTIME_YES: "Ja",
  ASTERISK_ANNOUNCE_HOLDTIME_NO: "Nej",
  ASTERISK_ANNOUNCE_HOLDTIME_ONCE: "En gang",
  ASTERISK_ANNOUNCE_POSITION_YES: "Ja",
  ASTERISK_ANNOUNCE_POSITION_NO: "Nej",
  ASTERISK_ANNOUNCE_POSITION_LIMIT: "Grænse",
  ASTERISK_ANNOUNCE_POSITION_MORE: "Mere",
  RECORDING: "Optagelse",
  ERROR_CHILD_HAS_ASTERISK:
    "En eller flere underorganisationer har forbindelse til asterisk. Overordnet organisation til sådanne underorganisationer må ikke være tilsluttet asterisk.",
  ERROR_PARENT_HAS_ASTERISK:
    "Forbudt at vælge asterisk når overordnet organisation har forbindelse til asterisk.",
  ERROR_ORGANIZATION_INACTIVATE_WITH_ACTIVE_CHILD:
    "Organisation med aktive underorganisationer kan ikke deaktiveres. Sørg for at deaktivere alle underorganisationer før denne handling udføres.",
  VOICE_VOICEMAIL: "Voicemail",
  VOICE_VOICEMAILS: "Voicemails",
  VOICE_VOICEMAIL_ID: "Voicemail",
  TIMES_TO_RETRY: "Antal genforsøg",
  MAX_DIGITS: "Maks antal cifre",
  ASTERISK_SOUND_ID: "Lyd",
  GOT_DIGITS: "Fik cifre",
  FAILED: "Mislykkedes",
  CHANSPY: "Aflyt",
  CHANSPIED_ON: "Aflyttet",
  VOICE_LICENSE: "Stemmelicens",
  LOGIN_PAUSE: "Login pause",
  AUTO_ANSWER: "Automatisk svar",
  VOICE_SETTINGS_CHANSPY_HELP: 'Tillad aflytning af andre brugere hvor "Aflyttet" er aktiveret',
  VOICE_SETTINGS_CHANSPIED_ON_HELP:
    "Tillad brugere med aflytningsrettigheder at aflytte denne bruger",
  TWO_FACTOR_AUTH_HELP:
    "Lad være inaktiv for at læse organisationens 2fa-indstillinger, aktiver for at tilsidesætte indstillingerne",
  TWO_FACTOR_AUTH_ENABLE_HELP:
    "Aktiver/deaktiver 2fa for denne bruger og tilsidesæt organisationsindstillinger",
  DELAY: "Forsinkelse",
  LEAVE_EMPTY_FOR_ALL_QUEUES: "Lad være tom for alle køer",
  AUTO_ACCEPT: "Automatisk accept",
  ENABLE_TO_SELECT_SPECIFIC_QUEUES: "Aktiver for at acceptere specifikke køer",
  AUTO_ACCEPT_HELP: "Accepter automatisk modtagne notifikationer",
  ADD_CALL_TO_CONVERSATION: "Tilføj opkald til samtale",
  CALL_SEARCH: "Skriv nummer eller navn",
  SELECT_VOICE_QUEUE_ROUTE: "Vælg kø & rute",
  SOMETHING_WENT_WRONG: "Noget gik galt",
  SPY: "Aflyt",
  NO_CONTACT_SUGGESTIONS_WERE_FOUND: "Ingen kontakter blev fundet at foreslå!",
  MAIL_SETTINGS: "E-mail-indstillinger",
  REPLY_TO: "Svar til",
  REPLIED_TO: "Svarede til",
  NOTE: "Note",
  ANSWER: "Svar",
  CALL_RECORDING: "Opkaldsoptagelse",
  OUTBOUND_CALL: "Udgående opkald",
  LEAVE_DISABLED_FOR_DEFAULT: "Lad være deaktiveret for standard",
  SELECT_NUMBER: "Vælg nummer",
  RINGING: "Ringer",
  STOP: "Stop",
  STOP_RECORDING: "Stop optagelse",
  DOWNLOAD: "Download",
  RECORDING_CONVERSATION: "Optager samtale",
  RECORDING_VOICEMAIL: "Optager voicemail",
  VOICEMAIL: "Voicemail",
  CALL_BACK: "Ring tilbage",
  WAIT_TIME: "Ventetid",
  TALK_TIME: "Taletid",
  CALL_LENGTH: "Opkaldslængde",
  MISSED_CALL: "Mistet opkald",
  LOCAL_PHONE_NUMBER: "Lokalt telefonnummer",
  PHONE: "Telefon",
  PHONE_NUMBER: "Telefonnummer",
  NOT_ASSIGNED: "Ikke tildelt",
  CONVERSATION_ALREADY_ASSIGNED: "Samtalen er allerede tildelt!",
  OUTBOUND_VOICE_QUEUE_ID: "Udgående kø",
  OUTBOUND_VOICE_QUEUE_ROUTE_ID: "Udgående rute",
  CONVERSATION_MERGE_FROM: "Denne samtale er blevet sammenlagt med samtale {{conversation}}",
  CONVERSATION_MERGE_TO: " blev sammenlagt med denne samtale {{conversation}}",
  ON_HOLD: "På hold",
  LISTENED_IN: "Aflyttet",
  LISTEN_ONLY: "Kun lyt",
  LISTEN_AND_TALK_TO_AGENT: "Lyt & tal med agent",
  SELECT_AN_OPTION: "Vælg en mulighed",
  ONGOING_CALL: "Igangværende opkald",
  INTERNAL_NOTE: "Intern note",
  VIEW_DETAILS: "Vis detaljer",
  CONTAINS_TRANSFER_FROM: "Indeholder overførsel fra",
  TRANSFERRED_TO: "Overført til",
  HAS_NOTE: "Har note",
  NOTE_TO: "Note til",
  REPLIED_BY: "Besvaret af",
  SELECT_QUEUE_TO_SELECT_CONTACT: "Kø skal vælges før kontakt kan vælges!",
  CONTACT_NOT_AUTOMATICALLY_ASSIGNED: "En kontakt kunne ikke tildeles automatisk!",
  NEW_MAIL_MESSAGE: "Ny e-mail besked",
  UNABLE_TO_PERSONIFY:
    "Kan ikke personificere denne konto. Dette skyldes sandsynligvis dine rettigheder.",
  USER_CREATE_USERNAME_TAKEN:
    "Brugernavnet du forsøger at bruge er allerede taget! Bemærk at dette brugernavn kan tilhøre en anden organisation!",
  USER_CREATE_EMAIL_TAKEN:
    "E-mailadressen du forsøger at bruge er allerede taget! Bemærk at denne e-mailadresse kan tilhøre en anden organisation!",
  USER_UPDATE_EMAIL_TAKEN:
    "E-mailadressen du forsøger at bruge er allerede taget! Bemærk at denne e-mailadresse kan tilhøre en anden organisation!",
  SOCKET_DISCONNECTED_USER_ACTIVE_NOT:
    "En administrator har deaktiveret din konto. Du vil blive logget ud!",
  MAY_NOT_DELETE_YOURSELF: "Du kan ikke slette dig selv!",
  NO_DATA_CREATED_YET: "Ingen data er oprettet endnu!",
  NO_DATA_MATCHING_YOUR_FILTERS: "Ingen data matcher dine filtre!",
  RESET_FILTERS: "Nulstil filtre",
  FORM_PREVIEW_NOT_EDITABLE: "Kun forhåndsvisning! Denne egenskab kan ikke ændres.",
  AGENTPROFILE_AGENTS: "Agentprofilens agenter",
  ADMINPROFILE_USERS: "Adminprofilens brugere",
  QUEUE_AGENTS: "Køagenter",
  NO_USERS_WERE_FOUND: "Ingen brugere blev fundet",
  NO_AGENTS_WERE_FOUND: "Ingen agenter blev fundet",
  DRAFT_FROM_MAIL_ACCOUNT_REQUIRED: "Fra e-mailkonto kræves",
  DRAFT_TO_MAIL_ACCOUNT_REQUIRED: "Til e-mailadresse kræves",
  MAIL_MESSAGE_BODY_REQUIRED_ON_OUTBOUND: "E-mailbesked kræves!",
  SMTP_ERROR: "Fejl ved afsendelse af e-mail",
  RESEND_EMAIL: "Send e-mail igen",
  RESEND_INITIATED: "Genafsendelse initieret",
  MAIL_MESSAGE_NOT_FAILED: "Genafsendelse kun tilgængelig for mislykkede e-mails",
  NO_SUBJECT: "Intet emne",
  NO_CUSTOM_FILTERS_CREATED: "Ingen tilpassede filtre oprettet",
  DRAFT_SEND_CONVERSATION_QUEUE_REQUIRED: "Kø skal vælges for samtale før kladde kan sendes",
  MAIL_ACCOUNT_NOT_IN_queueS: "Valgt e-mailkonto er ikke tilgængelig i køens udgående e-mailkonti",
  MAIL_ACCOUNT_REQUIRED: "E-mailkonto kræves",
  UNANSWERED_CALL: "Ubesvaret opkald",
  SELECT_OR_CREATE_CONTACT: "Vælg eller opret kontakt",
  ROUTING: "Routing",
  TRUNK_TYPE_CONNECTEL: "Connectel standard",
  TRUNK_TYPE_CUSTOM: "Tilpasset trunk",
  MAIL_ACCOUNT_AUTHENTICATION_FAILED: "E-mailkonto autentificering mislykkedes",
  AUDIO_NOT_PLAYABLE: "Lydfil kan ikke afspilles",
  SIP_STATE: "SIP-status",
  SIP_STATE_UNDEFINED: "Ikke initieret",
  SIP_STATE_INITIAL: "Forbinder",
  SIP_STATE_UNREGISTERED: "Uregistreret",
  SIP_STATE_TERMINATED: "Afsluttet",
  SIP_STATE_REGISTERED: "Registreret",
  ACTIVE_IN_QUEUE: "Aktuelt aktiv i kø",
  FILENAME: "Fil",
  PRESSED_NONE_VALID: "Trykkede på ugyldig mulighed",
  PRESSED_1: "Trykkede på 1",
  PRESSED_2: "Trykkede på 2",
  PRESSED_3: "Trykkede på 3",
  PRESSED_4: "Trykkede på 4",
  PRESSED_5: "Trykkede på 5",
  PRESSED_6: "Trykkede på 6",
  PRESSED_7: "Trykkede på 7",
  PRESSED_8: "Trykkede på 8",
  PRESSED_9: "Trykkede på 9",
  "PRESSED_*": "Trykkede på *",
  "PRESSED_#": "Trykkede på #",
  INITIATED: "Initieret",
  RESET_2FA: "Nulstil tofaktorautentificering",
  PASSWORD_RESET_SUCCESSFUL: "Nulstilling af adgangskode lykkedes!",
  PASSWORD_RESET_REQUEST_SUCCESSFUL: "Anmodning om nulstilling af adgangskode lykkedes!",
  PASSWORD_INVALID: "Adgangskoden skal være mindst 6 tegn lang",
  FILL_PASSWORD_TO_RESET: "Udfyld adgangskode for at nulstille",
  RESET_TOKEN_HAS_EXPIRED:
    "Ugyldig eller udløbet token. Anmod venligst om en ny nulstilling af adgangskode.",
  VOICE_TIMELINE_ERROR: "Fejl",
  INBOUND_CALL_INITIATED: "Indgående opkald initieret",
  OUTBOUND_CALL_INITIATED: "Udgående opkald initieret",
  INTERNAL_CALL_INITIATED: "Internt opkald initieret",
  SYSTEM_ANSWER: "System modtog opkald",
  TIMELINE_JOURNEY_JOB: "Journey-handling",
  RULE_WITH_INDEX_X_MATCHED: 'Regel med indeks "{{i}}" matchede',
  TRY_X_BUTTON_X_PRESSED: 'Forsøg {{try}}: Knap "{{num}}" blev trykket',
  TRY_X_DIGITS_X_PRESSED: 'Forsøg {{try}}: Cifre "{{num}}" blev trykket',
  USER_X_PINGED: 'Agent "{{user}}" pinget',
  USER_X_NOTIFICATION_END: 'Agent "{{user}}" ping afsluttet',
  NOTIFICATION_X_ACTION: "Handling: {{action}}",
  TIMEOUT_X_SECONDS: "Timeout: {{sec}} sekunder",
  NOTIFICATION_TIMEOUT_X_SECONDS: "Notifikation timeout: {{sec}} sekunder",
  VOICE_QUEUE_ASSIGNED: "Tildelt til stemmeopkaldskø",
  VOICE_QUEUE_JOINED: "Tilsluttet til stemmeopkaldskø",
  VOICE_QUEUE_DONE: "Forlod stemmeopkaldskø",
  NOTIFICATION_ACTION_ACCEPT: "Accepteret",
  NOTIFICATION_ACTION_ACCEPT_FAIL: "Acceptering mislykkedes",
  NOTIFICATION_ACTION_REJECT: "Afvist",
  NOTIFICATION_ACTION_REJECT_FAIL: "Afvisning mislykkedes",
  NOTIFICATION_ACTION_TIMEOUT: "Timeout",
  NOTIFICATION_ACTION_ANSWERED_ELSEWHERE: "Besvaret andetsteds",
  NOTIFICATION_ACTION_SYSTEM_HANGED_UP: "System lagde på (timeout)",
  NOTIFICATION_ACTION_CONTACT_HANGED_UP: "Kontakt lagde på før timeout/svar",
  RECORDING_STARTED_BY_X: 'Optagelse startet af "{{user}}"',
  RECORDING_STOPPED_BY_X: 'Optagelse stoppet af "{{user}}"',
  SYSTEM: "System",
  X_LEFT_CALL: "{{leg}} forlod opkaldet",
  LEFT_CALL_WITH_REASON: 'Forlod broen med årsag "{{reason}}"',
  LEFT_QUEUE_WITH_REASON: 'Forlod køen "{{queue}}" med årsag "{{reason}}"',
  UNKNOWN: "Ukendt",
  USER_ANSWERED: '"{{user}}" svarede',
  QUEUE_X_WITH_TIMEOUT: 'Kø "{{queue}}", med {{timeout}} sekunders timeout',
  AVAILABLE_VOICE_MENU_OPTIONS: 'Tilgængelige muligheder "{{options}}", og "{{retries}}" forsøg',
  CALL_TRANSFERRED_OUT: "Opkald viderestillet ud",
  CALL_TRANSFERRED_IN: "Opkald viderestillet ind",
  ATTENDED: "Overvåget",
  BLIND: "Blind",
  TRANSFER_TYPE_X: 'Overførselstype "{{type}}"',
  ROUTE_X: 'Rute "{{route}}"',
  CONTACT_ANSWERED: "Kontakt svarede",
  BG_COLOR: "Baggrundsfarve",
  SUB_GROUP: "Undergruppe",
  VARIABLE: "Variabel",
  STATIC: "Statisk",
  KEY: "Nøgle",
  ESCAPE_DIGITS: "Escape-ciffer",
  LANGUAGE: "Sprog",
  LANGUAGE_SE: "se (Svensk)",
  LANGUAGE_FI: "fi (Finsk)",
  LANGUAGE_EN: "en (Engelsk)",
  LANGUAGE_DE: "de (Tysk)",
  LANGUAGE_FR: "fr (Fransk)",
  LANGUAGE_HI: "hi (Hindi)",
  LANGUAGE_IT: "it (Italiensk)",
  LANGUAGE_NO: "no (Norsk)",
  LANGUAGE_NL: "nl (Hollandsk)",
  LANGUAGE_DK: "dk (Dansk)",
  LANGUAGE_PL: "pl (Polsk)",
  VOICE_LANGUAGE_SE: "se (Svensk)",
  VOICE_LANGUAGE_FI: "fi (Finsk)",
  VOICE_LANGUAGE_EN: "en (Engelsk)",
  VOICE_LANGUAGE_DE: "de (Tysk)",
  VOICE_LANGUAGE_FR: "fr (Fransk)",
  VOICE_LANGUAGE_HI: "hi (Hindi)",
  VOICE_LANGUAGE_IT: "it (Italiensk)",
  VOICE_LANGUAGE_NO: "no (Norsk)",
  VOICE_LANGUAGE_NL: "nl (Hollandsk)",
  VOICE_LANGUAGE_DK: "dk (Dansk)",
  VOICE_LANGUAGE_PL: "pl (Polsk)",
  VOICE_SHORTCUTS: "Stemme genveje",
  VOICE_SHORTCUT: "Stemme genvej",
  __VOICE_SHORTCUTS: "Hurtigkald",
  HEADER_FILTERS_COUNT: "Nulstil {{count}} filtre",
  REAUTHENTICATE_ACCOUNT: "Genautentificer",
  IS_LIVE: "Live",
  IS_SHARED: "Delt",
  SHARED: "Delt",
  WITH: "Med",
  ADMINPROFILE_NO_FILTER_ON_CREATION:
    "Adminprofil-rettighed må ikke have noget filter ved oprettelse. Opret adminprofilen uden filter og tilføj filter ved redigering!",
  ADMINPROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Adminprofilens filter skal indeholde sig selv for tilladte rettigheder!",
  SUPERADMIN_PROFILE_NO_FILTER_ON_CREATION:
    "Superadmin-profil rettighed må ikke have noget filter ved oprettelse. Opret superadmin-profilen uden filter og tilføj filter ved redigering!",
  SUPERADMIN_PROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Superadmin-profilens filter skal indeholde sig selv for tilladte rettigheder!",
  AUDIO_SETTINGS: "Lydindstillinger",
  SELECT_RINGTONE_SPEAKER: "Vælg ringetone højttaler",
  RINGTONE_SPEAKER: "Ringetone højttaler",
  SELECT_VOICE_SPEAKER: "Vælg stemme højttaler",
  VOICE_SPEAKER: "Stemme højttaler",
  SELECT_MICROPHONE: "Vælg mikrofon",
  ERROR_SETTING_SPEAKER:
    "Der opstod en fejl ved indstilling af højttaleren, vælg en anden højttaler før du fortsætter!",
  ERROR_SETTING_RINGTONE_SPEAKER:
    "Der opstod en fejl ved indstilling af ringetone højttaleren, vælg en anden højttaler før du fortsætter!",
  NO_SPEAKERS_FOUND: "Ingen højttalere fundet!",
  NO_MICROPHONES_FOUND: "Ingen mikrofoner fundet!",
  SELECTED_RINGTONE_SPEAKER_NOT_FOUND:
    'Valgt ringetone højttaler blev ikke fundet! Vi ændrede din ringetone højttaler til "systemstandard" indtil du foretager en ændring!',
  SELECTED_SPEAKER_NOT_FOUND:
    'Valgt højttaler blev ikke fundet! Vi ændrede din højttaler til "systemstandard" indtil du foretager en ændring!',
  SELECTED_MICROPHONE_NOT_FOUND:
    'Valgt mikrofon blev ikke fundet! Vi ændrede din mikrofon til "systemstandard" indtil du foretager en ændring!',
  DEFAULT_MICROPHONE: "Standardmikrofon",
  DEFAULT_SPEAKER: "Standardhøjttaler",
  BROWSER_DOES_NOT_SUPPORT_SPEAKER_SET: "Din browser understøtter ikke valg af højttaler",
  UPLOAD_RECORDING: "Upload optagelse",
  RESTART: "Genstart",
  RECORD_VOICE: "Optag stemme",
  RECORDING_UPLOAD_SUCCESSFULL: "Upload lykkedes",
  RECORDING_UPLOAD_ERROR: "Der opstod en fejl under upload",
  WE_COULD_NOT_DETECT_SOUND_FROM_MICROPHONE: "Vi kunne ikke registrere lyd fra din mikrofon",
  ECHO_TEST: "Enhedstest",
  START: "Start",
  START_TEST: "Start test",
  STOP_TEST: "Stop test",
  ECHO_TEST_SOUND_QUESTION: "Kan du høre lyden?",
  ECHO_TEST_MICROPHONE_QUESTION: "Kan du høre ekkoet?",
  ECHO_TEST_PASSED: "Godt. Din højttaler og mikrofon ser ud til at fungere som forventet!",
  PLEASE_CONFIRM_FOLLOWING: "Bekræft venligst følgende",
  PERIOD: "Periode",
  TREND: "Tendens",
  ADJUST: "Juster",
  TEXT_INDICATOR: "Indikator",
  METRICS: "Målinger",
  GROUP_BY: "Gruppér efter",
  SUB_GROUP_BY: "Undergruppér efter",
  SORT_BY: "Sortér efter",
  DESC: "Faldende",
  SPLIT_BY: "Del efter",
  SUMMARY: "Sammenfatning",
  MAIN_CHANNELS: "Main channels",
  THREAD_CHANNELS: "Thread channels",
  MESSAGE_CHANNELS: "Message channels",
  ORGANIZATION_IDS: "Organizations",
  CONVERSATION_USER_IDS: "Conversation users",
  THREAD_USER_IDS: "Thread users",
  MESSAGE_USER_IDS: "Message users",
  CONVERSATION_QUEUE_IDS: "Conversation queues",
  THREAD_QUEUE_IDS: "Thread queues",
  MESSAGE_QUEUE_IDS: "Message queues",
  DISPOSITION_IDS: "Dispositions",
  SUB_DISPOSITION_IDS: "Sub dispositions",
  THRD_DISPOSITION_IDS: "Third dispositions",
  PAUSE_IDS: "Pauser",
  TEAM_IDS: "Team",
  THREAD_TEAM_IDS: "Thread teams",
  MESSAGE_TEAM_IDS: "Beskedteams",
  MAIL_ACCOUNT_IDS: "E-mailkonti",
  VOICE_ROUTE_IDS: "Stemmeruter",
  UI: "Brugerflade",
  TABLE: "Tabel",
  GROUP: "Gruppe",
  ODD_ROW: "Ulige række",
  EVEN_ROW: "Lige række",
  WIDTH: "Bredde",
  COLOR: "Farve",
  BORDER: "Kant",
  DISPLAY_HEADER: "Vis overskrift",
  LEGEND: "Forklaring",
  PLACEMENT: "Placering",
  CUTOUT: "Udskæring",
  NESTED_METRICS: "Målinger",
  SUBTRACT: "Fratræk",
  METRIC: "Måling",
  VALUE_ARR: "Muligheder",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DATA: "Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFDATA: "Forskel",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFPERCENTAGE: "Procent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROW: "Pil",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDATA: "Pil + Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFDATA: "Pil + Forskel",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFPERCENTAGE: "Pil + Procent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICON: "Ikon",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDATA: "Ikon + Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFDATA: "Ikon + Forskel",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFPERCENTAGE: "Ikon + Procent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_BACKGROUND: "Baggrund",
  STATS_WIDGET_TYPE_TABLE: "Tabel",
  STATS_WIDGET_TYPE_GRID: "Gitter",
  STATS_WIDGET_TYPE_BAR: "Søjle",
  STATS_WIDGET_TYPE_LINE: "Linje",
  STATS_WIDGET_TYPE_TEXT: "Tekst",
  STATS_WIDGET_TYPE_PIE: "Cirkel",
  STATS_WIDGET_TYPE_RADAR: "Radar",
  STATS_WIDGET_TYPE_POLAR: "Polær",
  STATS_WIDGET_TYPE_TIMELINE: "Tidslinje",
  STATS_WIDGET_PERIOD_THIS_MONTH: "Denne måned",
  STATS_WIDGET_PERIOD_LIVE_TODAY: "I dag (Live)",
  STATS_WIDGET_PERIOD_TODAY: "I dag",
  STATS_WIDGET_PERIOD_LAST_24_HOURS: "Sidste 24 timer",
  STATS_WIDGET_PERIOD_YESTERDAY: "I går",
  STATS_WIDGET_PERIOD_THIS_WEEK: "Denne uge",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN: "Denne uge (start søndag)",
  STATS_WIDGET_PERIOD_THIS_WEEK_EXCEPT_TODAY: "Denne uge (undtagen i dag)",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN_EXCEPT_TODAY: "Denne uge (start søndag, undtagen i dag)",
  STATS_WIDGET_PERIOD_LAST_WEEK: "Sidste uge",
  STATS_WIDGET_PERIOD_LAST_7_DAYS: "Sidste 7 dage",
  STATS_WIDGET_PERIOD_THIS_MONTH_EXCEPT_TODAY: "Denne måned (undtagen i dag)",
  STATS_WIDGET_PERIOD_LAST_30_DAYS: "Sidste 30 dage",
  STATS_WIDGET_PERIOD_LAST_MONTH: "Sidste måned",
  STATS_WIDGET_PERIOD_CUSTOM: "Tilpasset",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_DAY: "Dag(e)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_WEEK: "Uge(r)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_MONTH: "Måned(er)",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GT: "Større end",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GTE: "Større end eller lig med",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LT: "Mindre end",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LTE: "Mindre end eller lig med",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NOT_EQUAL_NUM: "Ikke lig med",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_EQUAL_NUM: "Lig med",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_ANY_OF_NUM: "En af de valgte muligheder",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NONE_OF_NUM: "Ingen af de valgte muligheder",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT: "Større end",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT_TREND: "Større end tendensværdi",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE: "Større end eller lig med",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE_TREND: "Større end eller lig med tendensværdi",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT: "Mindre end",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT_TREND: "Mindre end tendensværdi",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE: "Mindre end eller lig med",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE_TREND: "Mindre end eller lig med tendensværdi",
  STATS_WIDGET_THRESHOLD_COMPARISON_NOT_EQUAL_NUM: "Ikke lig med",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM: "Lig med",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM_TREND: "Lig med tendensværdi",
  STATS_WIDGET_THRESHOLD_COMPARISON_ANY_OF_NUM: "En af de valgte muligheder",
  STATS_WIDGET_THRESHOLD_COMPARISON_NONE_OF_NUM: "Ingen af de valgte muligheder",
  METRIC_FORMAT_STATIC: "Statisk værdi",
  METRIC_FORMAT_ValuePCS: "Stk (Stykker)",
  METRIC_FORMAT_ValuePercent: "% (Procent)",
  METRIC_FORMAT_ValueDecimal: "Decimal",
  METRIC_FORMAT_SECONDS_TO_HH_MM: "TT:mm",
  METRIC_FORMAT_HH_MM: "TT:mm",
  METRIC_FORMAT_SECONDS_TO_HH_MM_SS: "TT:mm:ss",
  METRIC_FORMAT_SECONDS_TO_MM_SS: "mm:ss",
  METRIC_FORMAT_HH_MM_SS: "TT:mm:ss",
  METRIC_FORMAT_COUNTDOWN: "Nedtælling",
  METRIC_FORMAT_MM_SS: "mm:ss",
  METRIC_FORMAT_YEAR_MONTH_SHORT: "År Måned (kort)",
  METRIC_FORMAT_YEAR_MONTH_LONG: "År Måned",
  METRIC_FORMAT_MONTH_SHORT: "Måned (kort)",
  METRIC_FORMAT_MONTH_LONG: "Måned",
  METRIC_FORMAT_YEAR_WEEK_NUMBER: "År Ugenummer",
  METRIC_FORMAT_WEEK_NUMBER: "Ugenummer",
  METRIC_FORMAT_D_SLASH_M: "Dag/Måned",
  METRIC_FORMAT_D_SLASH_M_HH_mm: "Dag/Måned TT:mm",
  METRIC_FORMAT_YYYYMMDD: "ÅÅÅÅMMDD",
  METRIC_FORMAT_YYYY_MM_DD: "ÅÅÅÅ/MM/DD",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm: "ÅÅÅÅ/MM/DD TT:mm",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm_ss: "ÅÅÅÅ/MM/DD TT:mm:ss",
  METRIC_FORMAT_HOUR_OF_DAY_24H: "Time på dagen (24T)",
  METRIC_FORMAT_HOUR_OF_DAY_12H: "Time på dagen (12T)",
  METRIC_FORMAT_WEEKDAY_SHORT: "Ugedag (kort)",
  METRIC_FORMAT_WEEKDAY_LONG: "Ugedag",
  METRIC_FORMAT_HH_mm: "TT:mm",
  METRIC_FORMAT_mm_ss: "mm:ss",
  METRIC_FORMAT_HH_mm_ss: "TT:mm:ss",
  W_WEEK_SHORT: "U",
  PM: "e.m.",
  AM: "f.m.",
  MONTH_SHORT_1: "Jan",
  MONTH_SHORT_2: "Feb",
  MONTH_SHORT_3: "Mar",
  MONTH_SHORT_4: "Apr",
  MONTH_SHORT_5: "Maj",
  MONTH_SHORT_6: "Jun",
  MONTH_SHORT_7: "Jul",
  MONTH_SHORT_8: "Aug",
  MONTH_SHORT_9: "Sep",
  MONTH_SHORT_10: "Okt",
  MONTH_SHORT_11: "Nov",
  MONTH_SHORT_12: "Dec",
  MONTH_LONG_1: "Januar",
  MONTH_LONG_2: "Februar",
  MONTH_LONG_3: "Marts",
  MONTH_LONG_4: "April",
  MONTH_LONG_5: "Maj",
  MONTH_LONG_6: "Juni",
  MONTH_LONG_7: "Juli",
  MONTH_LONG_8: "August",
  MONTH_LONG_9: "September",
  MONTH_LONG_10: "Oktober",
  MONTH_LONG_11: "November",
  MONTH_LONG_12: "December",
  WEEKDAY_SHORT_6: "Søn",
  WEEKDAY_SHORT_0: "Man",
  WEEKDAY_SHORT_1: "Tir",
  WEEKDAY_SHORT_2: "Ons",
  WEEKDAY_SHORT_3: "Tor",
  WEEKDAY_SHORT_4: "Fre",
  WEEKDAY_SHORT_5: "Lør",
  WEEKDAY_LONG_6: "Søndag",
  WEEKDAY_LONG_0: "Mandag",
  WEEKDAY_LONG_1: "Tirsdag",
  WEEKDAY_LONG_2: "Onsdag",
  WEEKDAY_LONG_3: "Torsdag",
  WEEKDAY_LONG_4: "Fredag",
  WEEKDAY_LONG_5: "Lørdag",
  ONGOING_CALLS: "Igangværende opkald",
  RECORDING_NEEDS_TO_BE_UPLOADED: "Sørg for at uploade optagelsen!",
  RECORDING_STARTED: "Optagelse startet",
  RECORDING_STOPPED: "Optagelse stoppet",
  DUE_DATE: "Forfaldsdato",
  MAIL_ACCOUNT_NOT_IN_QUEUES:
    "Valgt e-mailkonto er ikke tilgængelig som udgående e-mailkonto for aktuel samtalekø",
  NO_EMAIL_ON_CONTACT: "Der er ingen e-mailadresser på kontakten at svare til!",
  FORWARD: "Videresend",
  LEFT_BRIDGE: "Bro afsluttet",
  DESTINATION_PHONE: "Destination {{phone}}",
  PARAMS_PHONE: "Telefon: {{phone}}",
  PARAMS_JOURNEY: "Rejse: {{journey}}",
  CALLEE_ANSWERED: "Modtageren svarede",
  SOCKET_CONNECTION_ERROR: 'Fejl ved forbindelse til websocket: "{{error}}"',
  CONTACT_FORM_HAS_NO_FIELDS:
    "Kontaktformularen har ingen felter! En administrator skal tilføje felter i kontaktformularen, der er knyttet til den valgte kø (Opret liste)",
  NOTIFICATION_REQUEST_NOT_SUPPORTED:
    "Din browser understøtter ikke notifikationer, skift venligst browser!",
  NOTIFICATION_REQUEST_DENIED:
    "Du har afvist browsernotifikationer. Sørg for at browsernotifikationer er tilladt!",
  NOTIFICATION_REQUEST_CATCH:
    "Der opstod en fejl ved anmodning om browsernotifikationstilladelser! {{error}}",
  INACTIVITY_READY: "Log ud ved klar-status",
  INACTIVITY_PAUSE: "Log ud ved pause-status",
  PDF: "pdf",
  IMAGE: "billede",
  STATS_WIDGET: "Widget",
  ADVANCED_OPTIONS: "Avancerede indstillinger",
  HIDE: "Skjul",
  NO_SPECIFIC_USER: "Ingen bruger",
  NO_SPECIFIC_QUEUE: "Ingen kø",
  NO_SPECIFIC_DISPOSITION: "Ingen disposition",
  NO_SPECIFIC_PAUSE: "Ingen pause",
  NO_SPECIFIC_DIRECTION: "Ingen retning",
  NO_SPECIFIC_CHANNEL: "Ingen kanal",
  NO_SPECIFIC_ORGANIZATION: "Ingen organisation",
  NO_SPECIFIC_CLIENT: "Ingen klient",
  NO_SPECIFIC_ACCOUNT: "Ingen konto",
  USER_ID_X: "Bruger-id {{x}}",
  QUEUE_ID_X: "Kø-id {{x}}",
  DISPOSITION_ID_X: "Dispositions-id {{x}}",
  ORGANIZATION_ID_X: "Organisations-id {{x}}",
  CLIENT_ID_X: "Klient-id {{x}}",
  ACCOUNT_ID_X: "Konto-id {{x}}",
  PAUSE_ID_X: "Pause-id {{x}}",
  ALIGNMENT: "Justering",
  THRESHOLDS: "Tærskelværdier",
  BOX_COLOR: "Boksfarve",
  FONT_STYLE: "Tekststil",
  FORMAT: "Format",
  USER_SELF: "Personificeret data",
  FONT_STYLE_ITALIC: "Kursiv",
  FONT_STYLE_BOLD: "Fed",
  "FONT_STYLE_BOLD-ITALIC": "Fed-kursiv",
  ALIGNMENT_CENTER: "Centreret",
  ALIGNMENT_LEFT: "Venstre",
  ALIGNMENT_RIGHT: "Højre",
  BLINK: "Blink",
  DECIMALS: "Decimaler",
  DIRECTION: "Retning",
  THREAD_DIRECTION: "Trådretning",
  MESSAGE_DIRECTION: "Beskedretning",
  CONVERSATION_MAIN_CHANNELS: "Samtalens hovedkanaler",
  CONVERSATION_DIRECTION: "Samtaleretninger",
  EDIT_ITEM: "Rediger element",
  OF_TOTAL: "Af total",
  GROUP_POPULAR: "Populære",
  GROUP_OTHERS: "Øvrige",
  GROUP_CUSTOM: "Tilpassede",
  GROUP_LIVE: "Live",
  STATS_CUSTOM_METRICS: "Tilpassede målinger",
  STATS_CUSTOM_METRIC: "Tilpasset måling",
  METRIC_QUERY_TYPE_SUM: "Addition",
  METRIC_QUERY_TYPE_DIFF: "Subtraktion",
  METRIC_QUERY_TYPE_PERCENT: "Procent",
  METRIC_QUERY_TYPE_AVG: "Gennemsnit",
  METRIC_QUERY_TYPE_MAX: "Maksimum",
  METRIC_QUERY_TYPE_MIN: "Minimum",
  METRIC_QUERY_TYPE_LEAST: "Mindst",
  METRIC_QUERY_TYPE_GREATEST: "Størst",
  METRIC_QUERY_TYPE_STATIC: "Statisk",
  METRIC_QUERY_TYPE_ADD: "Addition",
  QUERY_TYPE: "Forespørgselstype",
  DASHBOARDS: "Dashboards",
  DASHBOARD: "Dashboard",
  REPORTS: "Rapporter",
  REPORT: "Rapport",
  CREATE_DASHBOARD: "Opret dashboard",
  CREATE_REPORT: "Opret rapport",
  EDIT_DASHBOARD: "Rediger dashboard",
  EDIT_REPORT: "Rediger rapport",
  ALL_DASHBOARDS: "Alle dashboards",
  SHARED_WITH_ME: "Delt med mig",
  PREBUILD_DASHBOARDS: "Forudbyggede dashboards",
  ALL_REPORTS: "Alle rapporter",
  PREBUILD_REPORTS: "Forudbyggede rapporter",
  ADD_YOUR_FIRST_DASHBOARD: "Tilføj dit første dashboard",
  ADD_YOUR_FIRST_DASHBOARD_DESCRIPTION:
    "Start med en ny tom flade eller vælg et af vores forudbyggede dashboards",
  NO_SHARED_DASHBOARDS: "Ingen delte dashboards",
  ADD_YOUR_PREBUILT_DASHBOARD: "Tilføj forudbygget dashboard",
  ADD_YOUR_PREBUILT_DASHBOARD_DESCRIPTION: "Opret dit eget dashboard fra et forudbygget dashboard",
  ADD_YOUR_FIRST_REPORT: "Tilføj din første rapport",
  ADD_YOUR_FIRST_REPORT_DESCRIPTION:
    "Start med en ny tom flade eller vælg en af vores forudbyggede rapporter",
  NO_SHARED_REPORTS: "Ingen delte rapporter",
  ADD_YOUR_PREBUILT_REPORT: "Tilføj forudbygget rapport",
  ADD_YOUR_PREBUILT_REPORT_DESCRIPTION: "Opret din egen rapport fra en forudbygget rapport",
  NO_DASHBOARDS: "Ingen dashboards",
  NO_REPORTS: "Ingen rapporter",
  CREATE_FROM_THIS_TEMPLATE: "Opret fra denne skabelon",
  TIME_THRESHOLD: "Tidstærskel",
  AFTER: "Efter",
  CUSTOM_METRIC: "Tilpasset måling",
  STATS_CUSTOM_METRIC_ID: "Tilpasset måling",
  HEIGHT: "Højde",
  STATS_USER_DATA_HELP: "Personificeret data viser personificerede værdier for denne måling.",
  STATS_OF_TOTAL_HELP: "Procent af denne måling, sammenlignet med den totale værdi.",
  STATS_REPORTS: "Rapporter & dashboards",
  STATS_WIDGETS: "Widgets",
  OWNER: "Ejer",
  IS_DASHBOARD: "Er dashboard",
  STATS_REPORT: "Rapport",
  ADD_WIDGET: "Tilføj widget",
  FULLSCREEN: "Fuld skærm",
  CLONED: "Klonet",
  STATS_WIDGET_LEGEND_PLACEMENT_LEFT: "Venstre",
  STATS_WIDGET_LEGEND_PLACEMENT_RIGHT: "Højre",
  STATS_WIDGET_LEGEND_PLACEMENT_TOP: "Top",
  STATS_WIDGET_LEGEND_PLACEMENT_BOTTOM: "Bund",
  CIRCLE_TOTAL: "Cirkel total",
  TOTAL: "Total",
  BORDER_COLOR: "Kantfarve",
  LAST_DATA_AT: "Seneste data ved",
  HIDE_TITLE: "Skjul titel",
  OPEN_DASHBOARD: "Åbn Dashboard",
  ADD_PREVIEW_WIDGET: "Tilføj forhåndsvisningswidget",
  SELECT_REPORT: "Vælg rapport",
  DEFAULT: "Standard",
  CLONE: "Klon",
  DRAG_n_MOVE: "Træk & Flyt",
  DOWN: "Ned",
  UP: "Op",
  EXISTING: "Eksisterende",
  SELECT_WIDGET: "Vælg widget",
  ZOOM_IN: "Zoom ind",
  ZOOM_OUT: "Zoom ud",
  RELOAD: "Genindlæs",
  RELOAD_DATA: "Genindlæs data",
  DISPLAY_VALUES: "Vis værdier",
  WIDGET_NOT_FOUND: "Widget ikke fundet!",
  WIDGET_NOT_FOUND_DESCRIPTION: "Widget er blevet fjernet eller deles ikke længere med dig!",
  NO_WIDGETS: "Ingen widgets!",
  ADD_WIDGET_TO_REPORT: "Tilføj widget til rapport",
  ADD_WIDGET_TO_DASHBOARD: "Tilføj widget til Dashboard",
  REMOVE: "Fjern",
  REMOVE_ITEM: "Fjern element",
  ADDITION: "Addition",
  SUBTRACTION: "Subtraktion",
  DIVISION: "Division",
  CHANNELS_SETTINGS: "Kanaler fra indstillinger",
  DIRECTION_SETTINGS: "Retning fra indstillinger",
  THREAD_CHANNELS_SETTINGS: "Trådkanaler fra indstillinger",
  MESSAGE_CHANNELS_SETTINGS: "Beskedkanaler fra indstillinger",
  THREAD_DIRECTION_SETTINGS: "Trådretning fra indstillinger",
  MESSAGE_DIRECTION_SETTINGS: "Beskedretning fra indstillinger",
  SELECT_EXISTING: "Vælg eksisterende",
  EXISTING_WIDGETS: "Eksisterende widgets",
  NEW_WIDGET: "Ny widget",
  TEMPLATES: "Skabeloner",
  SELECT_TEMPLATE: "Vælg skabelon",
  USE_TEMPLATE: "Brug skabelon",
  SEARCH_TEMPLATES: "Søg skabeloner",
  SEARCH_WIDGETS: "Søg widgets",
  WIDGET_TEMPLATES: "Widget-skabeloner",
  WIDGET: "Widget",
  EDIT_WIDGET: "Rediger widget",
  SELECT_DASHBOARD: "Vælg dashboard",
  REPORT_NOT_SELECTED: "Rapport ikke valgt!",
  DASHBOARD_NOT_SELECTED: "Dashboard ikke valgt!",
  TREND_VALUE: "Trendværdi",
  TREND_PERIOD: "Trendperiode",
  MY_CONVERSATIONS: "Mine samtaler",
  MY_AND_UNASSIGNED_CONVERSATIONS: "Mine & ikke-tildelte samtaler",
  CUSTOMER_RESPONDED: "Kunde svarede",
  NO_UPDATE_IN_7_DAYS: "Ingen opdatering i 7 dage",
  CREATED_BY_ME: "Oprettet af mig",
  ALL_UNRESOLVED: "Alle uløste",
  ALL_RESOLVED: "Alle løste",
  ALL_CONVERSATIONS: "Alle samtaler",
  DIRECTIONS: "Retninger",
  INTERNAL_HOST: "Intern vært",
  VOICE_ASTERISK_DOWNGRADE_ID: "Nedgrader asterisk",
  VOICE_ASTERISK_DOWNGRADES: "Asterisk-nedgraderinger",
  VOICE_ASTERISK_DOWNGRADE: "Asterisk-nedgradering",
  DOWNGRADED: "Nedgraderet",
  __IS_DOWNGRADED: "Er nedgraderet",
  VOICE_ASTERISK_OPERATIONAL_HOURS: "Asterisk driftstimer",
  VOICE_ASTERISK_OPERATIONAL_HOURSES: "Asterisk driftstimer",
  UTC: "UTC",
  VOICE_ASTERISK_TOGGLE_HISTORY: "Asterisk skiftehistorik",
  VOICE_ASTERISK_TOGGLE_HISTORIES: "Asterisk skiftehistorik",
  INITIAL: "Initial",
  DID_TOGGLE: "Skiftede",
  DATE: "Dato",
  X_ONGOING_CALLS: "{{calls}} igangværende opkald",
  X_USER_STATUSES: "{{users}} tilsluttede brugere",
  PREVENTED_ASTERISK_TOGGLE: "forhindrede asterisk i at skifte",
  BOOTING_UP: "Starter op",
  DOWNGRADE_ASTERISK_OFFLINE: "Nedgrader asterisk offline",
  ASTERISK_STATUS_OFFLINE: "Offline",
  ASTERISK_STATUS_UPANDRUNNING: "I gang",
  ASTERISK_STATUS_TERMINATED: "Afsluttet",
  ASTERISK_STATUS_BOOTING: "Starter",
  ASTERISK_STATUS_SYNCING: "Synkroniserer",
  RESYNC: "Gensynkroniser",
  TOGGLE_FAILED: "Skift mislykkedes (sandsynligvis OPM-anmodning)",
  CURRENCY: "Valuta",
  INVOICE_CURRENCY_EUR: "EUR",
  INVOICE_CURRENCY_USD: "USD",
  INVOICE_CURRENCY_SEK: "SEK",
  INVOICE_POST_ADDON: "Faktura",
  INVOICE_POST_LICENSE: "Licens",
  INVOICE_POST_USAGE: "Stemmebrug",
  INVOICE_POST_ALL_LICENSE: "Alle kanaler licens",
  TARIFF_ID: "Takst",
  VOICE_TARIFF_LIST: "Stemmetakstliste",
  PHONENUMBER_MONTHLY_COST_ID: "Telefonnummer månedlig omkostning",
  PHONENUMBER_MONTHLY_COST_LIST: "Telefonnummer månedlig omkostningsliste",
  PHONENUMBER_SETUP_COST_ID: "Telefonnummer opsætningsomkostning",
  PHONENUMBER_SETUP_COST_LIST: "Telefonnummer opsætningsomkostningsliste",
  INVOICE_POST_VOICE_TARIFF: "Stemmetakst",
  INVOICE_POST_VOICE_PHONENUMBER_MONTHLY_COST: "Telefonnummer månedlig omkostning",
  INVOICE_POST_VOICE_PHONENUMBER_SETUP_COST: "Telefonnummer opsætningsomkostning",
  INVOICE_POST_VOICE_LICENSE: "Stemmelicens",
  INVOICE_POST_VOICE_USAGE: "Stemmebrug",
  INVOICE_POST_EMAIL_USAGE: "E-mailbrug",
  INVOICE_POST_EMAIL_LICENSE: "E-maillicens",
  INVOICE_POST_FORM_USAGE: "Formularbrug",
  INVOICE_POST_VOICE_NUMBERS_MONTHLY: "Stemmetelefonnumre månedlige omkostninger",
  INVOICE_POST_VOICE_NUMBERS_SETUP: "Stemmetelefonnumre opsætningsomkostninger",
  INVOICE_POST_VOICE_RECORDING: "Stemmeoptagelse",
  INVOICE_POST_VOICE_RECORDING_STORAGE: "Stemmeoptagelseslagring",
  CUSTOMER_IDENTIFICATION_NUMBER: "Kundeidentifikationsnummer",
  INVOICE_PERIOD_MONTHLY: "Månedligt",
  INVOICE_PERIOD_EVERY2NDMONTH: "Hver anden måned",
  INVOICE_PERIOD_QUARTERLY: "Kvartalsvis",
  INVOICE_PERIOD_HALFYEARLY: "Halvårligt",
  INVOICE_PERIOD_ANNUALLY: "Årligt",
  AMOUNT_TYPE: "Type",
  AMOUNT_PERCENT: "Procent",
  AMOUNT_CURRENCY: "Beløb",
  INVOICE_POST_AMOUNT_TYPE_PERCENT: "Procent",
  INVOICE_POST_AMOUNT_TYPE_CURRENCY: "Beløb",
  INVOICE: "Faktura",
  INVOICES: "Fakturaer",
  AMOUNT: "Beløb",
  LOCKED_AT: "Låst",
  PAID_AT: "Betalt",
  PERIOD_START: "Periodestart",
  PERIOD_END: "Periodeslut",
  INVOICE_POST: "Fakturapost",
  INVOICE_POSTS: "Fakturaposter",
  RE_PROCESS: "Genbehandl",
  EXCLUDE: "Ekskluder",
  INVOICE_ID: "Faktura",
  TARIFF: "Tariff",
  TARIFFS: "Tarriffs",
  TARIFF_PARENT: "Tariff parent",
  TARIFF_PARENTS: "Tariff parents",
  OUTBOUND_TARIFFS: "Udgående tarifs",
  INBOUND_TARIFF: "Indgående tariff",
  INTERNAL_TARIFF: "Intern tariff",
  OUTBOUND_ROUNDUP_STRATEGY: "Udgående afrundingsstrategi",
  INBOUND_ROUNDUP_STRATEGY: "Indgående afrundingsstrategi",
  INTERNAL_ROUNDUP_STRATEGY: "Intern afrundingsstrategi",
  TARIFF_PARENT_ID: "Tariff parent",
  VOICE_TARIFF_ROUNDUP_STRATEGY_NOROUNDUP: "Ingen afrunding",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST60SECONDS: "Nærmeste 60 sekunder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST30SECONDS: "Nærmeste 30 sekunder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST10SECONDS: "Nærmeste 10 sekunder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEIL15TO60: "Afrund 15-60 til 60 sekunder",
  PHONENUMBER_MONTHLY_COST_PARENT_ID: "Telefonnummer månedlig omkostningsforælder",
  PHONENUMBER_MONTHLY_COST: "Telefonnummer månedlig omkostning",
  PHONENUMBER_MONTHLY_COSTS: "Telefonnummer månedlige omkostninger",
  PHONENUMBER_MONTHLY_COST_PARENT: "Telefonnummer månedlig omkostningsforælder",
  PHONENUMBER_MONTHLY_COST_PARENTS: "Telefonnummer månedlige omkostningsforældre",
  PHONENUMBER_SETUP_COST_PARENT_ID: "Telefonnummer opsætningsomkostningsforælder",
  PHONENUMBER_SETUP_COST: "Telefonnummer opsætningsomkostning",
  PHONENUMBER_SETUP_COSTS: "Telefonnummer opsætningsomkostninger",
  PHONENUMBER_SETUP_COST_PARENT: "Telefonnummer opsætningsomkostningsforælder",
  PHONENUMBER_SETUP_COST_PARENTS: "Telefonnummer opsætningsomkostningsforældre",
  INVOICE_START: "Faktureringsstart",
  INVOICE_END: "Faktureringslut",
  ADDONS: "Tilføjelser",
  PROCESSED_AT: "Behandlet",
  INVOICE_PRINT: "Faktura",
  INVOICE_PDF: "Pdf",
  MOBILE: "Mobil",
  FIXED_LINE: "Fastnet",
  SHARED_COST: "Delt omkostning",
  TOLL_FREE: "Gratis",
  DUE_PERIOD: "Forfaldperiode",
  COUNT: "Antal",
  INVOICE_DUE_PERIOD_MONTH: "Måned",
  INVOICE_DUE_PERIOD_DAY: "Dag",
  COMPANY_NAME: "Firmanavn",
  COMPANY_ADDRESS: "Firmaadresse",
  COUNTRY: "Land",
  CITY: "By",
  POSTAL_CODE: "Postnummer",
  ADDRESS_ROW1: "Adresselinje 1",
  ADDRESS_ROW2: "Adresselinje 2",
  MARK_AS_PAID: "Marker som betalt",
  MARK_AS_UNPAID: "Marker som ubetalt",
  INVOICE_DATE: "Fakturadato",
  AMOUNT_TO_PAY: "Beløb at betale",
  INVOICE_PERIOD: "Fakturaperiode",
  INBOUND: "Indgående",
  SUB_TOTAL: "Subtotal",
  VAT: "Moms",
  PCS: "stk",
  COMPANY_EMAILS: "Firmaets e-mailadresser",
  PAYMENT_OPTION: "Betalingsmulighed",
  INVOICE_PAYMENT_OPTION_BANKTRANSFER: "Bankoverførsel",
  INVOICE_PAYMENT_OPTION_CREDITCARD: "Kreditkort",
  LICENSE_COST: "Licensomkostning",
  FORM_MODULE_COST: "Formularmodulomkostning",
  REPORTING: "Rapportering",
  SURCHARGE: "Tillægsgebyr",
  PREPAY: "Forudbetaling",
  COST: "Omkostning",
  INVOICE_POST_AMOUNT_TYPE_MINDEB: "Minimumdebitering",
  FINANCE: "Økonomi",
  PREPAID_CREDIT: "Forudbetalt kredit",
  PREPAID_CREDITS: "Forudbetalte kreditter",
  VALID_FROM: "Gyldig fra",
  VALID_UNTIL: "Gyldig til",
  CREDIT_LEFT: "Resterende kredit",
  OUTBOUND_CALL_RESTRICTED_DESCRIPTION:
    "Udgående opkald til destinationstelefonen blev begrænset af systemet! Kontakt din systemadministrator for adgang!",
  OUTBOUND_CALL_NEGATIVE_BALANCE_DESCRIPTION:
    "Udgående opkald er blevet afsluttet på grund af negativ saldo på din organisation!",
  OUTBOUND_CALL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Udgående opkald er blevet afsluttet på grund af inaktivitet i organisationen!",
  OUTBOUND_CALL_TOLL_FREE_NOT_SAME_ORIGIN:
    "Udgående opkald til det gratis destinationsnummer er blevet afsluttet, da opkalderens og modtagerens oprindelsesland skal være det samme!",
  OUTBOUND_EMAIL_NEGATIVE_BALANCE_DESCRIPTION:
    "Udgående e-mail er blevet afsluttet på grund af negativ saldo på din organisation!",
  OUTBOUND_EMAIL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Udgående e-mail er blevet afsluttet på grund af inaktivitet i organisationen!",
  DESTINATION_RESTRICTOR: "Destinationsbegrænser",
  DESTINATION_RESTRICTORS: "Destinationsbegrænsere",
  DESTINATIONS: "Destinationer",
  STATS_LICENSE_TYPE_ENTRY: "Entry",
  STATS_LICENSE_TYPE_STANDARD: "Standard",
  STATS_LICENSE_TYPE_ENTERPRISE: "Enterprise",
  ALL_CHANNELS: "Alle kanaler",
  VOICE_TARIFF_ROUNDUP_STRATEGY: "Stemmetakst afrundingsstrategi",
  VOICE_OUTBOUND_USAGE: "Stemme udgående brug",
  VOICE_USAGE_ROUNDUP_STRATEGY: "Stemmebrug afrundingsstrategi",
  VOICE_INBOUND_USAGE: "Stemme indgående brug",
  VOICE_INTERNAL_USAGE: "Stemme intern brug",
  EMAIL_INBOUND_USAGE: "E-mail indgående brug",
  EMAIL_OUTBOUND_USAGE: "E-mail udgående brug",
  FORM_INBOUND_USAGE: "Formular indgående brug",
  NEGATIVE_BALANCE_LOCK: "Negativ saldo lås",
  PREPAID: "Forudbetalt",
  NONE_HANDLED_INVOICE_CANNOT_BE_LOCKED: "Ikke håndteret faktura kan ikke låses!",
  LOCKED_INVOICES_MAY_NOT_BE_PATCHED: "Låst faktura kan ikke ændres!",
  CREDIT_USED: "Brugt kredit",
  INVOICE__DATA__COMPANY_EMAILS: "Fakturamodtagerens e-mailadresser",
  DASHBOARD_LICENSE: "Dashboard-licens",
  REPORTING_LICENSE: "Rapporteringslicens",
  DASHBOARD_LICENSE_TYPE: "Dashboard-licenstype",
  REPORTING_LICENSE_TYPE: "Rapporteringslicenstype",
  STANDARD: "Standard",
  ENTERPRISE: "Enterprise",
  TWINNING: "Twinning",
  ON: "På",
  QUEUE_ON: "Køopkald",
  DIRECT_ON: "Direkte opkald",
  TWINNED: "Twinnet",
  TWINNED_TO: "Twinnet til",
  RE_PROCESS_TARIFFS: "Genbehandl tarifs",
  RE_PROCESS_TARIFFS_NO_RESET_TARIFF_ID:
    "Genbehandl tariffomkostninger uden at nulstille tarifflisten",
  RE_PROCESS_TARIFFS_WITH_RESET_TARIFF_ID: "Genbehandl tariffomkostninger og nulstil tarifflisten",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTRY: "Entry",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_STANDARD: "Standard",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTERPRISE: "Enterprise",
  INVOICE_NUMBER: "Fakturanr.",
  CUSTOMER_NUMBER: "Kundenr.",
  SALESPERSON: "Sælger",
  YOUR_REFERENCE: "Din reference",
  PAYMENT_TERMS: "Betalingsbetingelser",
  VAT_REGISTRATION_NO: "Momsregistreringsnr.",
  FIN_CHARGE_TERMS: "Morarente",
  INVOICE_ROUNDING: "Fakturaafrunding",
  COMPANY_REFERENCE: "Virksomhedsreference",
  INTERNET: "Internet",
  E_MAIL: "E-mail",
  CORPORATE_IDENTITY_NO: "CVR-nummer",
  BANKGIRO: "Bankgiro",
  BIC_SWIFT: "BIC/SWIFT",
  PAYMENT_INFO_TEMPLATE: "Betalingsinformationsskabelon",
  HANDELSBANKEN_BG_SEK: "Handelsbanken bankgiro SEK",
  HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  WIZE_EUR: "Wize EUR",
  NET_AMOUNT: "Nettobeløb",
  SUBTOTAL: "Subtotal",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_BG_SEK: "Handelsbanken Bankgiro SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  INVOICE_PAYMENT_INFO_TEMPLATE_WIZE_EUR: "Wize EUR",
  QUEUE_NOT_FOUND: "Kø blev ikke fundet",
  CONTACT_NOT_FOUND: "Kontakt blev ikke fundet",
  SEND_INVOICE: "Send faktura",
  GO_TO_CONVERSATION: "Gå til samtale",
  MISSING_ORGANIZATION_INVOICE_DATA: "Mangler organisationens fakturadata",
  NO_ORGANIZATION_INVOICE_EMAILS:
    "Ingen e-mailadresser fundet på organisationen til at oprette samtale!",
  INVOICE_ALREADY_LOCKED: "Fakturaen er allerede låst",
  LOCK_INVOICE: "Lås faktura",
  REGULATION_LOCAL_ADDRESS_PROOF_REQUIRED:
    "Ved køb af telefonnumre skal du være opmærksom på, at visse regioner kan kræve, at du fremlægger bevis for en gyldig gadeadresse inden for det angivne område eller bydomæne. Dette kan indebære at indsende dokumenter som en nylig faktura eller virksomhedscertifikat, der viser den tilsvarende adresse for at sikre overholdelse af lokale bestemmelser.",
  REGULATION_WORLDWIDE_ADDRESS_PROOF_REQUIRED:
    "Ved køb af telefonnumre skal du være opmærksom på, at denne region vil kræve, at du fremlægger bevis for en gyldig gadeadresse. Dette kan indebære at indsende dokumenter som en nylig faktura eller virksomhedscertifikat, der viser den tilsvarende adresse for at sikre overholdelse af lokale bestemmelser.",
  REGULATION_NATIONAL_ADDRESS_PROOF_REQUIRED:
    "Ved køb af telefonnumre skal du være opmærksom på, at denne region vil kræve, at du fremlægger bevis for en gyldig gadeadresse inden for det angivne land. Dette kan indebære at indsende dokumenter som en nylig faktura eller virksomhedscertifikat, der viser den tilsvarende adresse for at sikre overholdelse af lokale bestemmelser.",
  PURCHASE: "Køb",
  SETUP_COST: "Opstartsomkostning",
  MONTHLY_COST: "Månedlig omkostning",
  REGULATIONS: "Bestemmelser",
  PHONENUMBER_PURCHASE_STATUS_ERROR: "Fejl",
  PHONENUMBER_PURCHASE_STATUS_WARNING: "Advarsel",
  PHONENUMBER_PURCHASE_STATUS_PARTIAL: "Delvis",
  PHONENUMBER_PURCHASE_STATUS_SUCCESS: "Lykkedes",
  PHONENUMBER_PURCHASE: "Telefonnummerkøb",
  PHONENUMBER_PURCHASES: "Telefonnummerkøb",
  PHONENUMBER_PREFIX: "Telefonnummer DID-gruppe",
  PHONENUMBER_PREFIXES: "Telefonnummer DID-grupper",
  PHONENUMBER_DID_TYPE_FIXED_LINE: "Fastnet",
  PHONENUMBER_DID_TYPE_MOBILE: "Mobil",
  PHONENUMBER_DID_TYPE_SHARED_COST: "Delt omkostning",
  PHONENUMBER_DID_TYPE_TOLL_FREE: "Gratis",
  ALPHA2: "Alpha-2",
  AREA_NAME: "Områdenavn",
  AREA_CODE: "Områdekode",
  STOCK: "Lager",
  PHONENUMBER_STOCK: "Telefonnummerlager",
  PHONENUMBER_STOCKS: "Telefonnummerlagre",
  BULK_ADD_PHONENUMBERS: "Massetilføj telefonnumre",
  BULK_ADD_PHONENUMBERSTOCK: "Massetilføj telefonnumre til connectel-lager",
  NUMBER_RANGE_START: "Nummerinterval start",
  NUMBER_RANGE_END: "Nummerinterval slut",
  LIST_PHONENUMBERS: "List telefonnumre",
  ADD_PHONENUMBERS: "Tilføj telefonnumre",
  VOICE_ROUTE_NUMBER_MAY_NOT_BE_CHANGED: "Nummer må ikke ændres!",
  EMERGENCY: "Nødsituation",
  ENABLED: "Aktiveret",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_VOXBONE: "Voxbone",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_CONNECTEL: "Connectel",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_THIRD_PARTY: "Tredjepart",
  PROVIDER: "Udbyder",
  TERMINATE: "Afslut",
  TERMINATION: "Afslutning",
  TERMINATED_AT: "Afsluttet",
  TERMINATED_BY: "Afsluttet af",
  TERMINATES_AT: "Afsluttes",
  USER_NOT_FOUND: "Bruger-id ({{user_id}}) blev ikke fundet!",
  TERMINATE_WENT_WRONG: "Noget gik galt ved afslutning af telefonnummer!",
  VOXBONE_NUMBER_NOT_CANCELLED: "Telefonnummeret kunne ikke annulleres!",
  VOXBONE_DID_ID_NOT_FOUND:
    "Udbyderen kan ikke ændres til Voxbone, da nummeret ikke findes i Voxbones database.",
  VOICE_RECORDING: "Stemmeoptagelse",
  VOICE_RECORDING_STORAGE: "Stemmeoptagelseslager",
  VOICEMAIL_MESSAGE: "Voicemail-besked",
  VOICE_CALL_RECORDING: "Opkaldsoptagelse",
  ADD_CUSTOM_INVOICE_POST: "Tilføj tilpasset fakturapost",
  BY_PREFIX: "Efter præfiks",
  PREFIX: "Præfiks",
  DESTINATION_RESTRICTOR_CONFIRM:
    "Aktivering af yderligere opkaldsdestinationer kan føre til utilsigtede gebyrer og øgede omkostninger, da opkald til tidligere blokerede lande vil blive tilladt. Sørg for at være opmærksom på de potentielle økonomiske konsekvenser, før du aktiverer nye destinationer.",
  DISPOSITION_MAY_NOT_HAVE_MORE_THAN_3_LEVELS: "Klassificering må ikke have mere end 3 niveauer!",
  TO_ME: "Til mig",
  ASSIGN_CONVERSATION_TO_ME: "Tildel samtale til mig",
  VOICE_DEVICE_AND_TWINNING_SETTINGS: "Stemmeenhed- & twinningindstillinger",
  VOICE_NOTIFICATION_SOUND: "Stemmenotifikationslyd",
  VOICE_NOTIFICATION_MICROPHONE: "Stemmemikrofon til/fra",
  NO_USER: "Ingen bruger",
  NO_DISPOSITION: "Ingen klassificering",
  NO_SUB_DISPOSITION: "Ingen underklassificering",
  NO_THRD_DISPOSITION: "Ingen tredjeklassificering",
  NOT_REQUIRED: "Ikke påkrævet",
  RESOLVE_IN: "Løs inden",
  REPLY_AT_LATEST: "Svar senest",
  RESOLVE_AT_LATEST: "Løs senest",
  CHANGELOG: "Ændringslog",
  SWITCH_TO_DARK_THEME: "Skift til mørkt tema",
  SWITCH_TO_LIGHT_THEME: "Skift til lyst tema",
  DISPLAY_CHANGELOG_HISTORY: "Vis ændringsloghistorik",
  IMPROVEMENT: "Forbedring",
  BUG_FIX: "Fejlrettelse",
  SELECT_ALL: "Vælg alle",
  NAV_CUSTOMER_ID: "Kunde navision-id",
  CREATE_NEW_JOURNEY_DRAFT: "Opret nyt rejseforløbsudkast",
  SHOW_JOURNEY_DRAFTS: "Vis rejseforløbsudkast",
  DROP_TO_ATTACH_FILE: "Slip for at vedhæfte fil",
  NO_PREVIEW_AVAILABLE: "Ingen forhåndsvisning tilgængelig for denne fil!",
  REPLY_TO_CC: "E-mail kopi",
  REPLY_TO_BCC: "E-mail blind kopi",
  LEAVE_EMPTY_FOR_ALL_DAY_EVERYDAY: "Lad være tom for hele dagen, hver dag",
  RELEASE: "Release",
  RELEASES: "Releases",
  VERSION: "version",
  EXECUTION_AT: "Kørsel ved",
  EXECUTED_AT: "Kørt ved",
  FULLFILLED: "Opfyldt",
  OUTPUT: "Output",
  CLICK_TO_PRINT_TO_CONSOLE: "Klik for at udskrive til konsollen",
  PRE_QUEUE_TIME_LONG: "Rejseforløbstid (tid i rejseforløbet før kø eller afslutning)",
  AUDIT: "Revision",
  AUDITS: "Revisioner",
  SYS_USER: "System",
  AUDIT_ACTION_READ: "Læs",
  AUDIT_ACTION_UPDATE: "Opdater",
  AUDIT_ACTION_CREATE: "Opret",
  AUDIT_ACTION_DELETE: "Slet",
  AUDIT_ACTION_SOFTDELETE: "Blød sletning",
  AUDIT_SEVERITY_L: "Lav",
  AUDIT_SEVERITY_M: "Medium",
  AUDIT_SEVERITY_H: "Høj",
  ACTION: "Handling",
  SEVERITY: "Alvorlighed",
  CHANGED_PROPS: "Ændrede egenskaber",
  EMPTY: "Tom",
  MAIL_STATUS: "E-mailstatus",
  VOICE_STATUS: "Stemmestatus",
  UnAssign: "Fjern tildeling",
  READONLY_UI: "Skrivebeskyttet (UI)",
  VOICE_RINGTONE: "Stemmesignal",
  NOTIFICATION_RINGTONE: "Notifikationssignal",
  MAX_FILESIZE: "Maksimal filstørrelse",
  MIMETYPES: "MIME-typer",
  API_KEY: "API-nøgle",
  API_KEYS: "API-nøgler",
  COPY_API_KEY_TO_CLIPBOARD: "Kopier API-nøgle til udklipsholder",
  CREATED_BY: "Oprettet af",
  CONTACT_CONDITION: "Kontaktbetingelse",
  DATA_KEY: "Feltalias",
  COMPARISON: "Sammenligning",
  DISPLAY_QUEUE_CALLER_ID: "Vis kønummer",
  MERGE_CONTACT_IF_EXISTS: "Flet kontaktdata hvis den findes",
  CUSTOM_DATA: "Tilpasset data",
  OVERRIDES_CURRENT: "Tilsidesætter nuværende værdi",
  MERGES_AND_REMOVES_DUPLICATED: "Fletter og fjerner dubletter",
  MERGEANDKEEPEXISTING: "Flet og behold eksisterende",
  MERGEANDOVERRIDE: "Flet og tilsidesæt eksisterende",
  CONVERSATION_OWNER_ONLY: "Kun samtaleagenters faner",
  INACTIVE_TABS_HIDDEN: "Inaktive faner er skjult",
  INACTIVE_TABS_DESCRIPTION:
    "Sørg for at du ikke har mere end 7 faner åbne ved at lukke dine synlige faner en efter en!",
  VIEW_EVENT: "Vis begivenhed",
  EVENT_ORDER: "Rækkefølge",
  CLIENT: "Klient",
  CLIENTS: "Klienter",
  SELECT_CLIENT: "Vælg klient",
  CLIENT_ID: "Klient",
  DATA: "Data",
  NO_CLIENT: "Ingen klient",
  TRANSLATIONS: "Oversættelser",
  TRANSLATION: "Oversættelse",
  __SUBMISSION_DISPLAY_FIELDS: "Visningsfelter for indsendelse",
  ADDITIONAL_QUEUES: "Yderligere køer",
  HELP_ADDITIONAL_QUEUES: "Vælg yderligere køer som ikke er tilgængelige i nuværende agentprofil.",
  FROM_ADDITIONAL: "Fra yderligere køer",
  FORM_SETTINGS: "Formularindstillinger",
  FORM_CAPACITY: "Formularkapacitet",
  FIXED_LINE_OR_MOBILE: "Fastnet/mobil",
  ATTACHMENTS_STORAGE: "Vedhæftningslager",
  PARENTS: "Forældre",
  ALL_QUEUES: "Alle køer",
  PROFILE_QUEUES: "Profilkøer",
  DATA_CHANGED: "Feltdataændringer",
  FORCE_LOGOUT: "Tving log ud",
  JSON_RESPONSE: "JSON-svar",
  JSON_PATH: "JSON-sti",
  JSON_PATH_HELP: "Eksempel: data.property, data.array[0].property",
  DELETED_ITEM_REMOVE: "Slettet element skal fjernes fra valgte elementer!",
  UNASSIGN: "Fjern tildeling",
  ALLOW_AGENT_UNASSIGN: "Tillad agent at fjerne tildeling",
  ASTERISK_HOURS: "Asterisk-timer",
  TOGGLE_ASTERISK_HOURS: "Skift asterisk-timer",
  EXTERNAL_VOICE_RINGTONE: "Ekstern opkaldstone",
  INTERNAL_VOICE_RINGTONE: "Intern opkaldstone",
  LOAD_MORE: "Indlæs mere",
  JOURNEY_TIMELINE: "Rejseforløbstidslinje",
  TIMELINE: "Tidslinje",
  EMPTY_TIMELINE: "Tom tidslinje",
  GLOBAL_SEARCH_QUEUES: "Globale søgekøer",
  PERMANENTLY_DELETE_REPORT: "Slet rapport permanent?",
  PERMANENTLY_DELETE_REPORT_DESCRIPTION:
    "Alle unikke widgets i denne rapport vil også blive slettet med denne handling.",
  PERMANENTLY_DELETE_DASHBOARD: "Slet Dashboard permanent?",
  PERMANENTLY_DELETE_DASHBOARD_DESCRIPTION:
    "Alle unikke widgets i dette Dashboard vil også blive slettet med denne handling.",
  DELETE_WIDGET: "Slet widget",
  DELETE_WIDGET_DESCRIPTION:
    "Widget vil blive slettet permanent hvis den ikke forekommer i andre Dashboards/rapporter.",
  CREATED_AFTER: "Oprettet efter",
  LAST_MESSAGE_BY_CONTACT: "Kontakt",
  LAST_MESSAGE_BY_AGENT: "Agent",
  SUBJECT_PREFIX: "Emne præfiks",
  HIDE_STATS_PREVIEW: "Skjul statistikforhåndsvisning",
  DISPLAY_STATS_PREVIEW: "Vis statistikforhåndsvisning",
  SOMETHING_WENT_WRONG_CLONING_WIDGET: "Noget gik galt ved kloning",
  STATS_WIDGET_CUSTOM_PERIOD_TO_LONG:
    "Widgets tilpassede periodestart og slut må ikke overstige 31 dage.",
  TRANSFER_DASHBOARD: "Overfør Dashboard",
  TRANSFER_REPORT: "Overfør rapport",
  TRANSFER_TO_REPORT_OWNER: "Overfør widget til rapportejer",
  OF: "af",
  WIDGET_HAS_NO_DATA: "Widget har ingen data",
  RULE_OTHERWISE_MATCHED: 'Regel "Ellers" matchede',
  RULE_X: "Regel {{n}}",
  RULE_X_WAS_MATCHED: '"Regel {{n}}" matchede',
  EDIT_CONTACT: "Rediger kontakt",
  PRECISION: "Præcision",
  SKILL: "Kompetence",
  SKILLS: "Kompetencer",
  SKILL_ID: "Kompetence",
  LEVEL_ONE: "Niveau et",
  LEVEL_TWO: "Niveau to",
  LEVEL_THREE: "Niveau tre",
  SKILL_TIMEOUT_SETTINGS: "Kompetencetimeoutindstillinger",
  TIMEOUT_ONE: "Timeout et",
  TIMEOUT_TWO: "Timeout to",
  TIMEOUT_THREE: "Timeout tre",
  SKILL_TIMEOUT_ONE_DESCRIPTION:
    "Brugerkompetencer niveau to vil blive inkluderet efter denne timeout",
  SKILL_TIMEOUT_TWO_DESCRIPTION:
    "Brugerkompetencer niveau tre vil blive inkluderet efter denne timeout + forrige timeout",
  SKILL_TIMEOUT_THREE_DESCRIPTION:
    "Alle kompetencer vil blive inkluderet efter denne timeout + forrige timeouts",
  QUEUE_TIMEOUT_UNIT_SECOND: "Sekunder",
  QUEUE_TIMEOUT_UNIT_MINUTE: "Minutter",
  QUEUE_TIMEOUT_UNIT_HOUR: "Timer",
  QUEUE_TIMEOUT_UNIT_DAY: "Dage",
  DEFAULT_VOICE_COUNTRY: "Standardland for stemme",
  NO_SKILL: "Ingen kompetence",
  IS_WAITING: "Venter",
  IS_ONGOING: "Igangværende",
  PREVIEW_PERIOD: "Forhåndsvisningsperiode",
  WALLBOARD: "Wallboard",
  WALLBOARD_KEY: "Wallboard-nøgle",
  STATS_REPORT_ID: "Rapport / Dashboard",
  SHARE_DATA: "Del data",
  SHARE_DATA_DESCRIPTION:
    "Del data som bruger, kø, disposition, e-mailkonto... data med wallboard-fremvisere. Bemærk at delte datapunkter som kø inkluderer alle organisationens køer og ikke kun dem der findes i widgets. Datadeling bør derfor slås fra hvis du deler wallboard med eksterne parter som ikke skal have adgang til datapunktsinformation.",
  WALLBOARD_NO_MORE_TRIES: "Du er blevet frakoblet, opdater venligst og prøv igen!",
  DISPOSED: "Disponeret",
  STATUS_CHANGED: "Status ændret",
  ASSIGNED_TO: "Tildelt til",
  USER_ASSIGNED: "Bruger tildelt",
  USER_UNASSIGNED: "Bruger fjernet",
  UNASSIGNED_FROM: "Fjernet fra",
  QUEUE_ASSIGNED: "Kø tildelt",
  TIMELINE_EVENT_TYPE_JOURNEYJOB: "Rejseforløbsjob",
  TIMELINE_EVENT_TYPE_VOICE: "Stemme",
  TIMELINE_EVENT_TYPE_NOTIFICATION: "Notifikation",
  TIMELINE_EVENT_TYPE_AUDIT: "Revision",
  JOURNEY_TRIGGER_ID: "Udløser",
  TRANSCRIPT: "Transskript",
  DEFAULT_LANGUAGE: "Standardsprog",
  VIEW_TRANSCRIPT: "Vis transskript",
  DISPLAY_TRANSCRIPT: "Vis transskript",
  CONDITION_AND_OR_AND: "Og",
  CONDITION_AND_OR_OR: "Eller",
  VOICE_RECORDING_TRANSCRIPT: "Stemmetransskript",
  WALLBOARDS: "Wallbards",
  SLA_RESPONSE: "Svartid",
  SLA_RESOLUTION: "Løsningstid",
  HAS_SLA: "Har SLA",
  HAS_NOT_SLA: "Har ikke SLA",
  EXCEEDED: "Overskredet",
  NOT_EXCEEDED: "Ikke overskredet",
  TRANSCRIPT_TRIGGER: "Transskript udløser på",
  CLICK_TO_TRANSCRIPT: "Klik",
  RECORDED_BY_JOURNEY: "Optagelser af journey",
  MANUALLY_RECORDED: "Manuelle optagelser",
  GENERATE_TRANSCRIPT: "Generer transskript",
  VOICE_RECORDING_TRANSCRIPT_SETTING: "Transskriptindstillinger",
  VOICE_RECORDING_TRANSCRIPT_SETTINGS: "Transskriptindstillinger",
  FIND_REPLACE: "Søg & erstat",
  FIND: "Søg",
  REPLACE: "Erstat",
  MODEL: "Model",
  VOICE_RECORDING_TRANSCRIPT_MODEL_WHISPER: "Whisper",
  VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA: "Nova",
  "VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA-2": "Nova 2",
  VOICE_RECORDING_TRANSCRIPT_MODEL_BASE: "Basis",
  VOICE_RECORDING_TRANSCRIPT_MODEL_ENHANCED: "Forbedret",
  TRANSCRIPTION_ERROR: "Transskriptionsfejl",
  CONFIDENCE: "Pålidelighed",
  TRANSCRIPTION_TIME: "Transskriptionstid",
  POINTS_SYSTEMS: "Pointsystem",
  POINTS_SYSTEM: "Pointsystem",
  MAIL_MESSAGE_SENT: "E-mailmeddelelse sendt",
  INBOUND_VOICE_CALL: "Indgående stemmeopkald",
  OUTBOUND_VOICE_CALL: "Udgående stemmeopkald",
  INTERNAL_VOICE_CALL: "Internt stemmeopkald",
  OUTBOUND_CONVERSATION_CREATED: "Udgående samtale oprettet",
  OUTBOUND_CONVERSATION_RESOLVED: "Udgående samtale løst",
  INBOUND_CONVERSATION_RESOLVED: "Indgående samtale løst",
  OUTBOUND_CONVERSATION_CLOSED: "Udgående samtale lukket",
  INBOUND_CONVERSATION_CLOSED: "Indgående samtale lukket",
  POINTS: "Point",
  CONVERSATION_SKILL_ID: "Kompetence",
  JOURNEY_TRIGGER: "Rejseforløbsudløser",
  JOURNEY_TRIGGERS: "Rejseforløbsudløsere",
  HIGHLIGHT_IN_JOURNEY: "Markér i rejseforløb",
  HELP_CONVERSATION_FILTER_IS_WAITING:
    '"Venter" henviser til samtaler, der ikke er besvaret og stadig er i køen.',
  HELP_CONVERSATION_FILTER_IS_ONGOING:
    '"Igangværende" henviser til samtaler, der ikke er lukket eller løst og har fået et svar fra en agent (systemets autosvar er ekskluderet).',
  POINTS_CREATED: "Oprettelse",
  POINTS_RESOLVED: "Løst",
  POINTS_CLOSED: "Lukket",
  POINTS_TALK: "Taletid",
  POINTS_MAIL_SENT: "Point for at sende e-mail",
  TREND_COMPARISON_VALUE: "Trendsammenligningsværdi",
  CUSTOM_PERCENT: "Tilpasset procentsats",
  STATS_CUSTOM_PERCENT_HELP:
    "Definer et tal for at dele metrikværdien for at generere og vise en procentværdi",
  GDPR: "GDPR",
  GDPRS: "GDPRs",
  PASSWORD_MAY_NOT_BE_IN_HISTORY:
    "Valgt adgangskode er blevet brugt for nylig af denne konto og kan ikke bruges igen på nuværende tidspunkt!",
  PASSWORD_LIFETIME_REACHED:
    "Din adgangskode er udløbet! Nulstil din adgangskode for at få adgang til applikationen.",
  IP_ADDRESS_NOT_WHITELISTED: "Din IP-adresse er ikke whitelistet af nogen af dine organisationer!",
  PASSWORD_EXPIRY_DESCRIPTION:
    "Adgangskodens levetid udløber om mindre end {{days_left}} dage. Sørg for at ændre din adgangskode.",
  PASSWORD_NOT_STRONG:
    "Valgt adgangskode er ikke stærk nok! Sørg for at din adgangskode indeholder mindst et lille og stort bogstav, et specialtegn og et tal!",
  FORM_ERROR_PASSWORD_NOT_STRONG:
    "Skal indeholde mindst et lille og et stort bogstav, et specialtegn og et tal",
  IP_WHITELIST: "IP-whitelist",
  IP_WHITELIST_DESCRIPTION: "Begræns adgang til din organisation via IP-adresse.",
  IP_ADDRESSES: "IP-adresser",
  CONNECTEL_INFO_SHARE: "Brug af information",
  LIFETIME: "Levetid",
  HISTORY: "Historik",
  STRONG: "Stærk",
  CONNECTEL_INFO_SHARE_DESCRIPTION:
    "Samtykke til at Connectel indsamler anonymiseret statistik og data fra din organisation for at forbedre vores tjenester, som vi leverer til dig.",
  PASSWORD_STRONG: "Adgangskodepolitik",
  PASSWORD_STRONG_DESCRIPTION: "Tving brug af stærke adgangskoder.",
  PASSWORD_LIFETIME: "Adgangskodes levetid",
  PASSWORD_LIFETIME_DESCRIPTION:
    "Angiv maks antal dage en adgangskode må leve, før den skal udskiftes.",
  PASSWORD_HISTORY: "Adgangskodehistorik",
  PASSWORD_HISTORY_DESCRIPTION: "Begrænser brugen af tidligere anvendte adgangskoder.",
  ANONYMIZATION: "Anonymisering",
  AGENT_ANONYMIZATION_DESCRIPTION:
    "Anonymiser agentnavnet i samtaler og andre datapunkter ved sletning.",
  EMAIL_ATTACHMENTS: "E-mailvedhæftninger",
  FORM_SUBMISSION_ATTACHMENTS: "Formularvedhæftninger",
  DELETION: "Sletning",
  VOICE_RECORDINGS: "Stemmeoptagelser",
  CONTACT_ANONYMIZATION_DESCRIPTION:
    "Antal dage der skal beholdes og ignoreres af anonymisering. Denne indstilling gælder kun inaktive kontakter og samtaler.",
  CONTACT_DELETION_RECORDINGS_DESCRIPTION:
    "Antal dage der skal beholdes og ignoreres af sletning. Denne indstilling gælder kun inaktive kontakter og samtaler.",
  CONTACT_VOICE_RECORDINGS_DESCRIPTION: "Antal dage der skal beholdes og ignoreres af sletning.",
  CONTACT_EMAIL_ATTACHMENTS_DESCRIPTION: "Antal dage der skal beholdes og ignoreres af sletning.",
  CONTACT_FORM_SUBMISSION_ATTACHMENTS_DESCRIPTION:
    "Antal dage der skal beholdes og ignoreres af sletning.",
  ANONYMIZED: "Anonymiseret",
  LOGIN_ERROR: "Login mislykkedes",
  ANONYMIZE_CONTACT_AND_CONVERSATIONS: "Anonymiser kontakt",
  CONFIRM_ANONYMIZE: "Bekræft anonymisering",
  CONFIRM_ANONYMIZE_DESCRIPTION:
    "Ved at anonymisere kontakten anonymiserer du alle relaterede data til kontakten eller dens samtaler. Denne handling kan ikke fortrydes!",
  ANONYMIZATION_ERROR: "Anonymiseringsfejl",
  ANONYMIZE_CONTACTS: "Anonymiser kontakter",
  TRANSCRIPT_ANONYMIZED: "Transskript anonymiseret",
  OpenBrowserTab: "Åbn browserfane",
  MAX_INACTIVITY_READY: "Maksimal inaktivitet for agentprofiler",
  ORGANIZATION_HAS_NOT_VOICE_ASTERISK: "Valgt organisation har ingen stemme-asterisk tildelt!",
  SIGNATURE_PREVIEW: "Forhåndsvisning af signatur",
  KEYWORDS: "Nøgleord",
  TAG_KEYWORDS_HELP: "Nøgleord er ikke versalfølsomme.",
  AUTO_TAGS: "Automatiske tags",
  VOICE_TRANSCRIPT: "Stemmetransskript",
  SUB_DISPOSITIONS: "Underklassificeringer",
  THRD_DISPOSITIONS: "Tredjeklassificeringer",
  STATS_ALARMS: "Alarmer",
  STATS_ALARM: "Alarm",
  TEXT: "Tekst",
  AUTO_TEXT: "Autogenerer tekst",
  STATS_ALARM_PERIOD_LIVE_TODAY: "I dag (Live)",
  STATS_ALARM_PERIOD_TODAY: "I dag",
  STATS_ALARM_PERIOD_LAST_24_HOURS: "Seneste 24 timer",
  STATS_ALARM_PERIOD_THIS_WEEK: "Denne uge",
  STATS_ALARM_PERIOD_THIS_WEEK_SUN: "Denne uge (start søndag)",
  STATS_ALARM_PERIOD_LAST_7_DAYS: "Seneste 7 dage",
  STATS_ALARM_PERIOD_THIS_MONTH: "Denne måned",
  STATS_ALARM_PERIOD_LAST_30_DAYS: "Seneste 30 dage",
  LAST_MATCH_AT: "Seneste match",
  MIN_INTERVAL: "Mindste interval",
  STATS_ALARM_MIN_INTERVAL_HELP:
    "Mindste interval at vente efter at en notifikation er sendt! For at undgå spam da live-måleværdier kan ændre sig ofte.",
  NOTIFICATION: "Notifikation",
  ATLAS_NOTIFICATION: "Atlas-notifikation",
  USER_IDS: "Brugere",
  HIGHLIGHTED: "Markeret",
  MATCHED_CONDITION: "matchede betingelsen",
  SOUND_TYPE_ELEVENLABS: "Tekst til tale",
  SOUND_TYPE_FILEUPLOAD: "Filupload",
  ELEVENLABS_VOICE_ALICE: "Alice",
  ELEVENLABS_VOICE_ARIA: "Aria",
  ELEVENLABS_VOICE_BILL: "Bill",
  ELEVENLABS_VOICE_BRIAN: "Brian",
  ELEVENLABS_VOICE_CALLUM: "Callum",
  ELEVENLABS_VOICE_CHARLIE: "Charlie",
  ELEVENLABS_VOICE_CHARLOTTE: "Charlotte",
  ELEVENLABS_VOICE_CHRIS: "Chris",
  ELEVENLABS_VOICE_DANIEL: "Daniel",
  ELEVENLABS_VOICE_ERIC: "Eric",
  ELEVENLABS_VOICE_GEORGE: "George",
  ELEVENLABS_VOICE_JESSICA: "Jessica",
  ELEVENLABS_VOICE_LAURA: "Laura",
  ELEVENLABS_VOICE_LIAM: "Liam",
  ELEVENLABS_VOICE_LILY: "Lily",
  ELEVENLABS_VOICE_MATILDA: "Matilda",
  ELEVENLABS_VOICE_RIVER: "River",
  ELEVENLABS_VOICE_ROGER: "Roger",
  ELEVENLABS_VOICE_SARAH: "Sarah",
  ELEVENLABS_VOICE_WILL: "Will",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_CONVERSATIONAL_SWEDISH: "Sanna Hartfield - Dansk samtale",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_SASSY_SWEDISH: "Sanna Hartfield - Fræk dansk",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_NARRATION_SWEDISH: "Sanna Hartfield - Dansk fortællerstemme",
  ELEVENLABS_VOICE_JONAS: "Jonas, rolig & informativ dansk stemme",
  ELEVENLABS_SETTINGS: "Generatorindstillinger",
  QUEUE_SORT_LASTINBOUNDMESSAGEAT: "Seneste indgående besked ved",
  QUEUE_SORT_LASTOUTBOUNDMESSAGEAT: "Seneste udgående besked ved",
  QUEUE_SORT_UNHANDLED: "Uhåndterede",
  QUEUE_SORT_QUEUEJOINTIME: "Kø-tid",
  SORT: "Sortér efter",
  EFFECTIVE_WORK_TIME: "Effektiv arbejdstid",
  ARTIFICIELL_INTELLIGENCE: "Kunstig intelligens",
  AI_ASSISTANTS: "AI-assistenter",
  AI_ASSISTANT: "AI-assistent",
  KB_UNIQUE_ID: "KB unikt id",
  AI_ASSISTANT_ID: "AI-assistent",
  NO_AI_ASSISTANT_ON_QUEUE: "Ingen AI-assistent på denne kø",
  NEW_MAIL_MESSAGE_WITH_AI: "Ny e-mailbesked med AI",
  REPLY_WITH_AI: "Svar med AI",
  AI_RESPONDING: "AI svarer...",
  AI_RESPONSE_CONFIDENCE: "AI-svarets pålidelighed",
  AI_REQUEST_KB_ASSISTANT: "AI-forespørgsel (Svarassistent)",
  AI_REQUEST_KB_ASSISTANCE: "AI-forespørgsel (Svarassistance)",
  KbAssistant: "Svarassistent",
  REQ: "forespørgsel",
  INVOICE_POST_VOICE_RECORDING_TRANSCRIPT: "Stemmetransskript",
  TEXT_TO_VOICE_USAGE: "Tekst til tale-brug",
  QUEUE_SORT_HELP:
    '"Uhåndterede" sorterer efter kø-tid og filtrerer desuden til kun samtaler, der ikke er besvaret.',
  FIELD_CONDITIONS: "Feltbetingelser",
  FIELD_TO_AFFECT: "Felt at påvirke",
  CONDITIONAL_FIELD: "Betinget felt",
  THEN: "Så",
  FORBID: "Forbyd",
  MAKE_OPTIONAL: "Gør valgfri",
  IF: "Hvis",
  MAX: "MAKS",
  MAXIMUM: "Maksimum",
  HELP: "Hjælp",
  HIDE_HELP: "Skjul hjælp",
  VALIDATION_MAXIMUM_NUMBER_HELP: "Maksimalt tal der kan udfyldes i dette felt",
  VALIDATION_MAXIMUM_LIST_HELP: "Maksimalt antal valgmuligheder",
  VALIDATION_MAXIMUM_TEXT_HELP: "Maksimalt antal tegn",
  DEFAULT_VALUE: "Standardværdi",
  IMPORT_FORM_SUBMISSION: "Importér formularindsendelse",
  SAVE_REPLY: "Gem svar",
  EDIT_REPLY: "Redigér svar",
  CREATE_REPLY: "Opret svar",
  HIDE_REPLY: "Skjul svar",
  IMPORT_CONVERSATION_FORM: "Importér samtaleformular",
  CUSTOM_ANSWER: "Tilpasset",
  FIELD_CUSTOM_ANSWER_HELP:
    "Slutbrugeren kan udfylde tilpasset svar i et tekstfelt. Angivet værdi vil blive sat til alias {field_alias}_custom.",
  CLICK_TO_COPY: "Klik for at kopiere",
  RECONNECTS_AT: "Genopretter forbindelse ved",
  DOWNLOAD_EML: "Download e-mailbesked .eml",
  USER_SETTINGS: "Brugerindstillinger",
  ENGLISH: "Engelsk",
  SWEDISH: "Svensk",
  DANISH: "Dansk",
  NORWEGIAN: "Norsk",
  FINNISH: "Finsk",
  GERMAN: "Tysk",
  SHOW_MORE_FIELDS: "Vis flere felter",
  INVALID: "Ugyldigt",
  TOO_SHORT: "For kort",
  TOO_LONG: "For langt",
  ERROR_QUEUE_NO_CREATE_LIST: "Køen har ingen opretteliste",
  TEXT_MESSAGE: "Tekstbesked",
  TEXT_MESSAGE_SOURCE_CREATED: "Tekstbesked-kilde oprettet",
  TEXT_MESSAGE_SOURCE_CREATED_ERROR: "Fejl ved oprettelse af tekstbesked-kilde",
  TEXT_MESSAGE_SOURCE: "Tekstbesked-kilde",
  TEXT_MESSAGE_SOURCES: "Tekstbesked-kilder",
  ALPHA_TAG: "Alfa-tag",
  SELECT_ORGANIZATION: "Vælg organisation",
  MESSAGE: "Besked",
  SELECT_SOURCE: "Vælg kilde",
  SELECT_QUEUE_WITH_CREATE_LIST: "Vælg en kø med en opretteliste for at oprette en kontakt",
  BEGIN_TYPING_TO_SEE_CONTACT: "Begynd at skrive for at se kontakter...",
  MISSING_CONTACT: "Kontakt mangler",
  MISSING_EMAIL: "E-mail mangler",
  MISSING_SUBJECT: "Emne mangler",
  MISSING_CONTACT_FORM: " Kontaktformularen mangler",
  VERIFIED: "Verificeret",
  TEXT_MESSAGE_SOURCE_UPDATED: "Tekstbesked-kilde opdateret",
  TEXT_MESSAGE_SOURCE_UPDATE_FAILED: "Opdatering af tekstbesked-kilde mislykkedes",
  SENDER_PHONE_NUMBER: "Afsenders telefonnummer",
  CONVERSATION_ENUM_ID: "Samtale id",
  CONVERSATION_ENUM_EMAIL: "Kontakt email",
  CONVERSATION_ENUM_STATUS: "Status",
  CONVERSATION_ENUM_CHANNEL: "Kanal",
  CONVERSATION_ENUM_DIRECTION: "Retning",
  CONVERSATION_ENUM_QUEUE_NAME: "Kønavn",
  CONVERSATION_ENUM_AGENT_NAME: "Agentnavn",
  CONVERSATION_ENUM_DISPOSITION: "Klassificering",
  CONVERSATION_ENUM_CREATED_DATE: "Oprettelsesdato",
  CONVERSATION_ENUM_CALLER_ID: "Opkalders ID",
  CONVERSATION_ENUM_ORGANIZATION_ID: "Organisations-id",
  CONVERSATION_ENUM_ORGANIZATION_TITLE: "Organisationstitel",
  DESELECT_ALL: "Fravælg alle",
  CONVERSATION_DOWNLOAD: "Vælg felter, der skal inkluderes i download",
  NEW_TEXT_MESSAGE: "Ny tekstbesked",
  SUMMARIZE_CONVERSATION: "Sammenfatte samtale",
  GET_NEW_SUMMARY: "Hent ny sammenfattelse",
  CONVERSATION_SUMMARY: "Sammenfattelse",
  OPEN_SUMMARY: "Åben sammenfattelse",
  CLOSE_SUMMARY: "Luk sammenfattelse",
  MESSAGE_SENT_SUCCESSFULLY: "Beskeden er blevet sendt",
  MESSAGE_TOO_SHORT: "Beskeden skal være mindst 10 tegn lang",
  MISSING_SOURCE: "Kilde mangler",
  MISSING_PHONE_NUMBER: "Telefonnummer mangler",
  ERROR_SENDING_MESSAGE: "Fejl ved afsendelse af besked",
  MISSING_QUEUE: "Kø mangler",
};
