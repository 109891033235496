import { checkFlag } from "@Api/featureFlagApi";
import { RestRequest } from "@Utils";
import {
  IPreviewVoiceChanspy,
  ISharedVoiceChanspy,
  ISharedCreateVoiceChanspy,
  ISharedPatchVoiceChanspy,
} from "atlas-shared";

export const fetchVoiceChanspies = async (): Promise<Array<IPreviewVoiceChanspy>> => {
  return RestRequest.get<Array<IPreviewVoiceChanspy>>("voice_chanspy", {
    socket_id: global.socketId,
  });
};

export const fetchVoiceChanspy = async (
  id: IPreviewVoiceChanspy["id"]
): Promise<ISharedVoiceChanspy> => {
  return RestRequest.get<ISharedVoiceChanspy>(`voice_chanspy/${id}`, {
    socket_id: global.socketId,
  });
};

export const createVoiceChanspy = async (payload: ISharedCreateVoiceChanspy) => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/chanspy/", {
      body: JSON.stringify({ ...payload }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then((v) => v.json());
  } else {
    return RestRequest.post<any, ISharedVoiceChanspy>("voice_chanspy", payload, {
      socket_id: global.socketId,
    });
  }
};

export const patchVoiceChanspy = (
  id: IPreviewVoiceChanspy["id"],
  payload: ISharedPatchVoiceChanspy
) => {
  return RestRequest.patch<any, ISharedVoiceChanspy>(`voice_chanspy/${id}`, payload, {
    socket_id: global.socketId,
  });
};

export const getVoiceChanspyCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>("voice_chanspy/create/schema");
};

export const getVoiceChanspyPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>("voice_chanspy/patch/schema");
};
