import React, { useCallback, useMemo, useState } from "react";
import "./conversation.message.draft.scss";
import { IPreviewForm, ISharedDraft, ISharedFullConversation, Undefinable } from "atlas-shared";
import { deleteDraft } from "@Api";
import { useTranslation } from "react-i18next";
import { ConversationMessageReplies, ConversationReply } from "@Components";
import { ConversationMessageDraftTyping } from "./conversation.message.draft.typing";
import { THighlightedMessage } from "@Hooks";
import { htmlElementOnDropFiles } from "@Utils";
import { IAuth } from "@Store";

interface IProps {
  conversation: ISharedFullConversation;
  draft: ISharedDraft;
  conversationForm: Undefinable<IPreviewForm>;
  editable: boolean;
  auth: IAuth;
  highlightedMessage: THighlightedMessage;
  fetchTextMessages: () => void;
}

export const ConversationMessageDraft = React.memo(
  ({
    conversation,
    draft,
    editable,
    highlightedMessage,
    auth,
    conversationForm,
    fetchTextMessages,
  }: IProps) => {
    const { t } = useTranslation();
    const [dragOver, setDragOver] = useState<boolean>(false);
    const onDrop = useMemo(() => {
      if (editable && draft)
        return htmlElementOnDropFiles(`draft/${draft.id}/attachment`, "attachment", () =>
          setDragOver(false)
        );

      return null;
    }, [draft, editable]);
    const onDragOver = useCallback(
      (event) => {
        if (!editable) return;

        if (event.target?.className?.toString().includes("ant-upload-button")) {
          if (dragOver) setDragOver(false);

          return;
        }

        if (!dragOver) setDragOver(true);

        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = "move";
      },
      [editable, dragOver]
    );
    const onDragLeave = useCallback(() => setDragOver(false), []);

    if (!onDrop && editable) return <></>;

    return (
      <div
        className={`conversation-message conversation-message-draft${editable ? " editable" : ""}`}
        onDrop={onDrop || undefined}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDragEnd={onDragLeave}
      >
        <div className="conversation-message-content">
          {dragOver && <div className="drop-info">{t("DROP_TO_ATTACH_FILE")}</div>}
          {editable ? (
            <ConversationReply
              conversation={conversation}
              conversationForm={conversationForm}
              draft={draft}
              auth={auth}
              onDelete={() => deleteDraft(draft.id)}
              fetchTextMessages={fetchTextMessages}
            />
          ) : (
            <ConversationMessageDraftTyping t={t} draft={draft} />
          )}
        </div>
        <ConversationMessageReplies
          draft={draft}
          conversation={conversation}
          highlightedMessage={highlightedMessage}
        />
      </div>
    );
  },
  (prev, curr) => {
    return (
      curr.draft.body === prev.draft.body &&
      curr.draft.attachments?.length === prev.draft.attachments?.length &&
      prev.conversationForm?.id === curr.conversationForm?.id &&
      JSON.stringify(curr.conversation.data || {}) === JSON.stringify(prev.conversation.data || {})
    );
  }
);
