import React from "react";
import "./header.create.conversation.scss";
import { PlusIcon } from "@Assets/icons";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import { Icon } from "@Components";
import { HeaderCreateConversationPopover } from "./header.create.conversation.popover";

export const HeaderCreateConversation = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div className={"create-conversation-wrapper"}>
      <Popover
        content={<HeaderCreateConversationPopover />}
        title={t("START_CONVERSATION")}
        overlayClassName={"create-conversation-popover"}
        trigger={"hover"}
        placement={"bottomRight"}
      >
        <div className={"create-conversation"}>
          <Icon icon={PlusIcon} iconProps={{ size: 18 }} />
        </div>
      </Popover>
    </div>
  );
});
