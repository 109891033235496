// @ts-nocheck
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormElement,
  IFormElementArray,
  IFormElementObject,
  IFormElements,
  IFormElementUnknown,
  IFormOptions,
  Nullable,
} from "./Interfaces";
import {
  ArraySchema,
  fromJson,
  ObjectSchema,
  Schema as JSONSchema,
  Schema,
  toJson,
} from "json-joi-converter";
import * as Joi from "joi";
import { ValidationError, ValidationErrorItem, ValidationOptions } from "joi";
import cloneDeep from "lodash/cloneDeep";

export * from "./Interfaces";

function isObject(value: any) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

export class JSONForm {
  public errors: ValidationError["details"];

  private validation_schema: Joi.AnySchema = Joi.any();

  private json_schema: Schema = { type: "any" };

  private array_formatted: Schema = { type: "any" };

  private values: any = null;

  private files: Record<string, any> = {};

  private initialValues: any = {};

  private new_form: Nullable<IForm> = null;

  private new_validation_schema: Joi.AnySchema = Joi.any();

  private validated_form: Nullable<IForm> = null;

  private valuesChanged: Function;

  private customErrors: Record<string, Array<ValidationErrorItem>> = {};

  private flattenedCustomErrors: Array<ValidationErrorItem> = [];

  constructor(
    private form: IForm,
    validation_schema: Nullable<Joi.AnySchema>,
    json_schema?: Schema,
    private options: IFormOptions = {}
  ) {
    // @ts-ignore
    this.validation_schema = validation_schema;
    // @ts-ignore
    this.json_schema = json_schema;
    this.form.ui_type = FormElementTypes.SECTION;

    if (!form.elements && !form.ref) form.ref = "/";

    if (!this.validation_schema && !this.json_schema)
      throw new Error(
        "At least one of validation_schema or json_schema needs to be defined!"
      );

    if (this.validation_schema && !this.json_schema)
      this.json_schema = toJson(this.validation_schema);

    if (this.json_schema && !this.validation_schema)
      this.validation_schema = fromJson(this.json_schema);
  }

  public setOptions(options: IFormOptions) {
    this.options = options;
    this.setValues(this.values);
    return this;
  }

  public addFile(key, file) {
    this.files[key] = file;
    return this;
  }

  public withDefaults(values: Record<string, any>) {
    return this.validation_schema.validate(values, { abortEarly: false }).value;
  }

  public getValues() {
    return this.values;
  }

  public getInitialValues() {
    return this.initialValues;
  }

  public setValues(
    values: Record<string, any>,
    initialValues: boolean = false
  ) {
    this.validated_form = null;
    this.values = cloneDeep(values);
    if (initialValues) this.initialValues = cloneDeep(values);

    const { value, schema } = this.arrayFormat(
      cloneDeep(this.json_schema),
      this.values
    );

    this.array_formatted = schema;

    this.values = value;

    this.new_form = this.populateForm(
      cloneDeep(this.form),
      this.array_formatted
    ) as IForm;
    this.new_validation_schema = fromJson(this.array_formatted);

    return this;
  }

  public getForm(include_errors: boolean = false): IForm {
    if (include_errors) {
      if (!this.validated_form)
        throw new Error(
          "GeneralForm needs to be validated before getting form with errors included!"
        );

      return this.validated_form;
    }

    if (!this.new_form) throw new Error("no values has been set to form!");

    return this.new_form as IForm;
  }

  public getSchema() {
    return this.new_validation_schema;
  }

  public validate(set: boolean = false, options: ValidationOptions = {}) {
    this.validated_form = this.new_form;
    const validation = this.new_validation_schema.validate(this.values, {
      abortEarly: false,
      ...options,
    });

    if (set) this.setValues(validation.value);

    if (validation.error || this.flattenedCustomErrors.length)
      this.attachErrors(
        [...(validation.error?.details || []), ...this.flattenedCustomErrors],
        this.validated_form as IForm
      );

    // console.log('this.errors', this.errors, this.values, toJson(this.new_validation_schema), this.new_form, validation.value, set, validation.error);
    this.errors = validation?.error?.details;

    return this;
  }

  get is_valid() {
    return (
      !(this.errors || []).length && !(this.flattenedCustomErrors || []).length
    );
  }

  private attachErrors(errors: ValidationError["details"], form: IForm) {
    errors.forEach((err) => {
      //if (err.type.includes('.unknown')) return;
      const { path, type, context, message } = err;
      const ref = err.path.join(".");
      let elm = this.findElementFromRef(ref, form);

      if (!elm && err.path.length > 1) {
        elm = this.findElementFromRef(
          err.path.slice(0, err.path.length - 1).join("."),
          form
        );
      }

      if (!elm) {
        return;
        //throw new Error(`Elm ${ref} not found`);
      }

      if (!elm.errors) elm.errors = [];

      elm.errors.push({ path, type, context, message });
    });
  }

  // private addDefaults(schema: Joi.AnySchema): void {
  //   const { value: values } = schema.validate(this.values, {
  //     abortEarly: false
  //   });
  //
  //   console.log('adding default', this.values, values, toJson(schema));
  //
  //   this.values = values;
  // }

  private arrayFormat(
    schema: JSONSchema,
    values: any,
    path: Array<string | number> = []
  ): JSONSchema {
    if (!schema.meta) schema.meta = {};

    if (path.length) schema.meta.path = path.join(".");

    if (values === undefined && schema.default !== undefined)
      values = schema.default;

    if (schema.type === "object") {
      const objectSchema: ObjectSchema = schema as ObjectSchema;

      for (let key in objectSchema.properties) {
        const { value, schema } = this.arrayFormat(
          objectSchema.properties[key],
          values?.[key],
          [...path, key]
        );

        objectSchema.properties[key] = schema;

        if (values === undefined && value !== undefined) {
          values = { [key]: value };
        }

        if (!schema) {
          delete objectSchema.properties[key];
          //if (values?.[key] !== undefined)
          if (values !== null) delete values[key];
        }
      }
    } else if (schema.type === "array") {
      const arraySchema: ArraySchema = schema as ArraySchema;

      arraySchema.meta.path = path.join(".");
      if (Array.isArray((schema as ArraySchema).items))
        throw new Error("Multiple schemas in array items is not supported!");

      if (
        !Array.isArray((schema as ArraySchema).items) &&
        "items" in arraySchema
      ) {
        // iterating one schema per array item in ordered property
        const formatted = (values || []).map((value: any, index: number) => {
          return this.arrayFormat(
            cloneDeep(arraySchema.items) as JSONSchema,
            value,
            [...path, index]
          );
        });

        arraySchema.ordered = formatted.map((f) => f.schema);
        values = formatted.map((f) => f.value);

        if (arraySchema?.items) {
          const { schema: assignedSchema } = this.assignWhen(
            arraySchema.items,
            values,
            path
          );

          if (assignedSchema.valid) arraySchema.valid = assignedSchema.valid;
        }

        arraySchema.meta.complex_data_type = ["array", "object"].includes(
          arraySchema.items.type
        );

        if (arraySchema.meta.complex_data_type) {
          arraySchema.meta.items_default = {};
          Object.entries(arraySchema.items?.properties || {}).forEach(
            ([key, val]) => {
              if (val.default !== undefined)
                arraySchema.meta.items_default[key] = val.default;
            }
          );
        }

        delete arraySchema.items;

        const elm = this.findElementFromRef(arraySchema.meta.path, this.form);

        if (elm && !("addItems" in elm)) {
          (elm as IFormElementArray).addItems =
            !arraySchema.max || !values || values.length < arraySchema.max;
        }
      }
    }

    const { schema: assignedSchema, values: assignedValues } = this.assignWhen(
      schema,
      values,
      path
    );

    schema = assignedSchema;
    values = assignedValues;

    if (!schema || schema.forbidden)
      return { value: undefined, schema: undefined };

    if (values === undefined && schema.default !== undefined)
      values = schema.default;

    return { value: values, schema };
  }

  private assignWhen(schema, values, path) {
    if ((schema.when || []).length)
      schema.when.forEach((when) => {
        let ref_value = this.findValueFromReference(when.reference, path);

        // if (!ref_value && ref_value === undefined)
        //   ref_value = 'iiiiiiiiiiiiiiiiiiiii';

        const validation = fromJson(when.is).validate(ref_value);
        const is_valid: boolean = !("error" in validation);
        const additional_params = is_valid ? when.then : when.otherwise;

        delete schema.when;

        if (additional_params) {
          ["min", "max", "length"].forEach((param) => {
            if (param in additional_params)
              schema[param] = additional_params[param];
          });
          ["valid"].forEach((param) => {
            if ((additional_params[param] || []).length) {
              schema[param] = [
                ...(schema[param] || []),
                ...additional_params[param],
              ];
            }
          });
          ["required", "optional", "forbidden"].forEach((param) => {
            if (additional_params[param] !== undefined)
              schema[param] = additional_params[param];
          });
          if (additional_params.type !== "any")
            schema.type = additional_params.type;

          ["meta"].forEach((param) => {
            if (additional_params[param]) {
              schema[param] = {
                ...(schema[param] || {}),
                ...additional_params[param],
              };
            }
          });
          if (additional_params.when) {
            schema.when = additional_params.when;
          }
        }

        if (schema.when) {
          const formatted = this.arrayFormat(schema, values, path);

          schema = formatted.schema;
          values = formatted.value;
        }
      });

    return {
      schema,
      values,
    };
  }

  private populateForm(
    form: IFormElements,
    schema: JSONSchema,
    rel_schema: Nullable<JSONSchema> = null
  ): IFormElement {
    if (!rel_schema) rel_schema = schema;

    let elm: Nullable<JSONSchema> = null;

    if ("ref" in form)
      elm = form.ref
        ? this.findSchemaFromRef(form.ref as string, schema, rel_schema)
        : rel_schema;

    // generate map_items if not defined in form schema
    if (
      elm?.type === "array" &&
      !("map_items" in form) /* && elm?.meta.complex_data_type*/
    ) {
      if (elm?.meta.complex_data_type) {
        (form as IFormElementArray).map_items = {
          default: elm.meta?.items_default || undefined,
          elements: [] as Array<IFormElementUnknown>,
          ui_type: FormElementTypes.SECTION,
          ui_layout: FormLayoutTypes.INLINE,
        };

        if (
          !(form as IFormElementArray).map_items.default &&
          (elm as ArraySchema)?.ordered?.[0]
        )
          Object.entries(
            ((elm as ArraySchema)?.ordered?.[0] as ObjectSchema)?.properties ||
              {}
          ).map(([ref, props]) => {
            (form as IFormElementArray).map_items.elements.push({ ref });
            if ("default" in props)
              (form as IFormElementArray).map_items.default[ref] =
                props.default;
          });
      } else
        (form as IFormElementArray).map_items = {
          element: { ref: "" },
        };
    }

    if (elm?.type === "object" && !("elements" in form)) {
      (form as IFormElementObject).elements = Object.keys(
        (elm as ObjectSchema).properties
      ).map((ref) => ({ ref }));
    }

    // populate items from map_items mapped from array of values
    if (form.hasOwnProperty("map_items")) {
      if (!form.ref)
        throw new Error(
          "Missing form reference on element where map_items is defined!"
        );

      // when element has been sanitized!
      if (!elm) {
        // @ts-ignore
        form = undefined;
        //throw new Error(`Elm with ref ${form.ref} not found!`);
      }

      if (form) {
        form.items = (
          ((elm as ArraySchema).ordered as Array<JSONSchema>) || []
        ).map((e, index) => {
          let sub_form = (form as IFormElementArray).map_items as IFormElement;

          if (
            elm?.meta.complex_data_type &&
            (elm as ArraySchema)?.ordered?.[index]
          ) {
            sub_form = {
              default: {},
              elements: [] as Array<IFormElementUnknown>,
              ui_type: FormElementTypes.SECTION,
              ui_layout: FormLayoutTypes.INLINE,
            };
            Object.entries(
              ((elm as ArraySchema)?.ordered?.[index] as ObjectSchema)
                ?.properties || {}
            ).map(([ref, props]) => {
              sub_form.elements.push({ ref });
              if ("default" in props) sub_form.default[ref] = props.default;
            });
          }

          return this.populateForm(
            cloneDeep(sub_form),
            schema,
            (elm as ArraySchema).ordered?.[index] as JSONSchema
          );
        });
        // this wasnt commented...
        // delete (form as IFormElementArray).map_items;
      }
    }

    if (
      elm?.meta?.hide ||
      ((elm?.valid || []).length === 1 && elm.valid[0] === null)
    )
      form.hidden = true;

    if (elm?.meta?.default_un_null)
      form.default_un_null = elm.meta.default_un_null;

    if (form && "ref" in form) {
      if (elm) {
        [
          "min",
          "label",
          "max",
          "default",
          "type",
          "valid",
          "invalid",
          "required",
        ].forEach((k) => {
          // @ts-ignore
          if (k in elm) {
            // @ts-ignore
            if (!form[k])
              // @ts-ignore
              form[k] = elm[k];
          }
        });

        if (elm.meta?.phone) form.data_type = "phone";

        if (elm.allow?.includes(null) /* || elm.valid?.includes(null)*/)
          form.nullable = true;

        if (elm.valid?.includes(null) && elm.type !== "array")
          form.nullable = true;

        if (elm.meta?.path) form.path = elm.meta.path;

        const base_options = form.path
          ? this.options[this.getOptionsPath(form.path)]
          : false;
        let has_base_options = !!base_options;

        if (typeof base_options === "function") {
          const path_arr = (elm.meta?.path || "").split(".");
          const parent_path =
            path_arr.length > 1
              ? path_arr.slice(0, path_arr.length - 1).join(".")
              : "";

          has_base_options =
            base_options(
              this.values,
              this.findValueFromPath(parent_path),
              this.findValueFromPath(elm.meta?.path)
            ) !== null;
        }

        if (!form.ui_type)
          switch (form.type) {
            case "boolean":
              form.ui_type = FormElementTypes.SWITCH;
              break;
            case "any":
            case "symbol":
            case "string":
              form.ui_type =
                form.valid || has_base_options
                  ? FormElementTypes.SELECT
                  : form.ref?.endsWith("password")
                  ? FormElementTypes.PASSWORD
                  : FormElementTypes.INPUT;
              break;
            case "number":
              form.ui_type =
                form.valid || has_base_options
                  ? FormElementTypes.SELECT
                  : FormElementTypes.NUMBER;
              if (elm.precision) form.precision = elm.precision;

              break;
            case "array":
              form.ui_type = (form as IFormElementArray).map_items?.elements
                ? FormElementTypes.LIST
                : FormElementTypes.TYPEAHEAD;
              break;
            case "object":
              form.ui_type = FormElementTypes.SECTION;
              break;
            case "date":
              form.ui_type = FormElementTypes.DATETIME;
              break;
            default:
              throw new Error(
                `type not defined when setting ui_type for ${form.meta.path}`
              );
          }

        // remove temporary valid on array (copied from array items)
        if (elm.type === "array") delete elm.valid;

        rel_schema = elm;
      } else {
        // @ts-ignore
        form = undefined;
      }
    }
    // if (form && form.type === FormElementTypes.ARRAY) {
    //   (form as IFormElementArray).addItems = true;
    // }

    if (form && form.hasOwnProperty("elements")) {
      if (Array.isArray((form as IFormElementArray).elements)) {
        (form as IFormElementArray).elements = (
          (form as IFormElementArray).elements as Array<IFormElements>
        )
          .map((elm, i) => {
            return this.populateForm(elm, schema, rel_schema);
          })
          .filter((e) => e);
        if (
          !((form as IFormElementArray).elements as Array<IFormElements>).length
        ) {
          // @ts-ignore
          form = undefined;
        }
      } else throw new Error("elements is not array!");
    }

    if (form && form.element) {
      (form as IFormElementArray).element = this.populateForm(
        form.element,
        schema,
        rel_schema
      );
    }

    // set defaults
    if (form) {
      const defaults = {
        [FormElementTypes.TYPEAHEAD]: {
          searchable: true,
          multiple: true,
          duplicate: false,
          createOption: false,
          mode: "multiple",
        },
        [FormElementTypes.SECTION]: {
          ui_layout: elm?.ui_layout || FormLayoutTypes.HORIZONTAL,
        },
      };

      // @ts-ignore
      for (let key in defaults[form.ui_type] || {}) {
        if (!form.hasOwnProperty(key)) {
          // @ts-ignore
          form[key] = defaults[form.ui_type][key];
        }
      }
    }

    return form;
  }

  private getOptionsPath(path: string) {
    return path
      .split(".")
      .filter((c) => isNaN(c))
      .join("_");
  }

  private findSchemaFromRef(
    ref: string,
    schema: JSONSchema,
    rel_schema: Nullable<JSONSchema> = null
  ): JSONSchema {
    const fromRoot = ref.substr(0, 1) === "/";
    let root: any = fromRoot ? schema : rel_schema;

    if (fromRoot && ref.length === 1) return root;

    if (fromRoot) ref = ref.substr(1, ref.length);

    try {
      ref.split(".").forEach((r) => {
        const isNumeric = !isNaN(parseInt(r));

        if (!isNumeric) {
          root = root.properties[r];
        } else {
          root = root.ordered ? root.ordered[r] : root.items[r];
        }
      });
    } catch (e) {
      if (!root && rel_schema) {
        return this.findSchemaFromRef(ref, rel_schema);
      }
    }

    return root;
  }

  private findElementFromRef(
    ref: string,
    schema: IFormElements | Array<IFormElements>
  ): Nullable<IFormElement> {
    if (Array.isArray(schema)) {
      // const found = schema.find(s => s.fixed_ref === ref);
      // if (found)
      //   return found;
      for (let s of schema) {
        let found = this.findElementFromRef(ref, s);

        if (found) return found;
      }
      return null;
    } else if (isObject(schema)) {
      if (schema.path === ref || schema.ref === ref) return schema;

      for (let key in schema) {
        if (!["elements", "items", "element"].includes(key)) continue;

        // @ts-ignore
        const elm = this.findElementFromRef(ref, schema[key]);

        if (elm) return elm;
      }
    }

    return null;
  }

  getOptions(): IFormOptions {
    return this.options;
  }

  private findValueFromReference(reference: any, from: Array<string>) {
    const path =
      reference.ancestor === "root"
        ? reference["$ref"].split(".")
        : [
            ...from.slice(0, from.length - reference.ancestor),
            ...reference["$ref"].split("."),
          ];
    let values = this.values;

    for (const p of path) {
      values = values[p];
      if (!values) break;
    }

    return values;
  }

  findValueFromPath(str_path?: string) {
    let values = this.values;

    if (!str_path) return values;

    const path = str_path.split(".");

    for (const p of path) {
      values = values[p];
      if (!values) break;
    }

    return values;
  }

  setValueByPath(str_path?: string, value: any) {
    const path = (str_path || "").split(".");
    const key = path.pop();

    const l_value = this.findValueFromPath(path.join("."));

    if (!l_value) {
      this.setValueByPath(path.join("."), {});
      return this.setValueByPath([...path, key].join("."), value);
    }

    l_value[key] = value;

    this.setValues(this.values).validate();

    this.valuesChanged(this.values, value, this);

    return this;
  }

  setErrorByPath(str_path: string, path: Array<string>, errors: Array<string>) {
    this.customErrors[str_path] = errors.map((message) => ({
      type: "custom",
      message,
      path,
    }));
    this.flattenedCustomErrors = Object.values(this.customErrors).flat();
  }

  onValuesChange(onValuesChange: Function) {
    this.valuesChanged = onValuesChange;
    return this;
  }

  pushValue(str_path, value) {
    const l_value = this.findValueFromPath(str_path);

    if (!l_value) {
      this.setValueByPath(str_path, [value]);
      return this;
    }

    if (Array.isArray(l_value)) {
      l_value.push(value);

      this.setValues(this.values).validate();
      this.valuesChanged(this.values, value, this);
    }

    return this;
  }

  dropValue(str_path, index: number) {
    const l_value = this.findValueFromPath(str_path);

    if (Array.isArray(l_value)) {
      l_value.splice(index, 1);

      this.setValues(this.values).validate();
      this.valuesChanged(this.values, l_value, this);
    }

    return this;
  }
}
