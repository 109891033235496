"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.journeyDefaultInputPort = exports.journeyAction = exports.settingsValidationSchema = void 0;
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const settingsValidationSchema = (properties) => {
    const schema = {
        type: 'object',
        properties: {
            title: {
                type: 'string',
                allow: [null,],
                default: null,
            },
            description: {
                type: 'string',
                allow: [null,],
                default: null,
            },
        },
    };
    if (properties) {
        const defaults = {};
        Object.entries(properties).forEach(([key, schema,]) => {
            if (schema.default !== undefined)
                defaults[key] = schema.default;
        });
        schema.properties.properties = {
            type: 'object',
            default: defaults,
            properties,
            required: true,
        };
    }
    return schema;
};
exports.settingsValidationSchema = settingsValidationSchema;
const journeyAction = (props) => {
    return Object.assign({ channels: null, direction: enums_1.JourneyNodeDirectionEnum.InputOutput, settings_schema: (0, exports.settingsValidationSchema)(), icon: 'Setting' }, props);
};
exports.journeyAction = journeyAction;
exports.journeyDefaultInputPort = {
    [constants_1.JOURNEY_ACTION_PORT_INPUT]: {
        id: constants_1.JOURNEY_ACTION_PORT_INPUT,
        type: enums_1.JourneyPortDirectionEnum.Input,
        removable: false,
        required: true,
        index: 0,
        title: null,
        description: null,
        color: null,
    },
};
