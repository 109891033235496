import {
  IPreviewContact,
  IPreviewConversation,
  IPreviewOrganization,
  IPreviewUser,
  Nullable
  , IPreviewVoiceRoute, IElasticPreviewContact, IElasticPreviewConversation, ISharedOrganization,
} from 'atlas-shared';
import React, { ReactElement, } from 'react';
import { IconText, Avatar, } from '@Components';
import { MailIcon, PhoneIcon, } from '@Assets/icons';
import { getContactFullName, } from './contact';
import { DisplayPhoneNumber, translatePhoneNumber, } from '@Utils/phone';
import { IOrganizationStore, } from '@Store';

export interface IOption {
  key: number | string | null;
  title: string;
  display?: ReactElement;
  children?: Array<IOption>;
  entity?: any;
  group?: string;
  group_order?: number;
  description?: string;
  disabled?: boolean;
  deleted?: boolean;
}

export interface IMention {
  value: string;
  label: string;
}

export interface IOptionEntity {
  id: number;
  title: string;
  global?: boolean;
  deleted_at?: Nullable<string>;
  children?: Array<any>;
  organization_id?: ISharedOrganization['id'];
}

export interface IOptionKeyPrefix {
  prefix?: string;
  delimiter?: string;
}

export const entityToOption = (entity: IOptionEntity | IPreviewOrganization | IPreviewUser | IPreviewContact | IPreviewVoiceRoute | IElasticPreviewConversation | IElasticPreviewContact, type?: string, organization?: IPreviewOrganization, display: boolean = true, _key_prefix: Nullable<IOptionKeyPrefix> = null): IOption => {
  const key_prefix = _key_prefix ? { ..._key_prefix, } : _key_prefix;
  const option: IOption = {
    key: entity.id,
    title: 'Option',
    entity,
    deleted: !!(entity as IOptionEntity).deleted_at,
  };

  if (key_prefix) {
    option.key = `${key_prefix.prefix || ''}${key_prefix.prefix ? key_prefix.delimiter || '-' : ''}${entity.id}`;
    key_prefix.prefix = option.key as string;
  }

  if (type && ['user', 'user_reverse', ].includes(type)) {
    const user: IPreviewUser = entity as IPreviewUser;

    option.title = `${user.first_name} ${user.last_name} (${user.username})`;
    if (display)
      option.display = <span style={{ display: 'flex', alignItems: 'center', flexGrow: 1, }}><Avatar user={user} size={18} /><span className='option-title'><span>{user.first_name} {user.last_name} ({user.username})</span></span></span>;
  }
  else if (type === 'contact' || type === 'contact_primary') {
    const contact: IPreviewContact = entity as IPreviewContact;

    option.title = `#${contact.id}: ${[contact.first_name, contact.last_name].filter(Boolean).join(" ")}`;
    if (display)
      option.display = <span style={{ display: 'flex', flexDirection: 'column', }}>
        <div className='row-1'>
          <span className='id'>{contact.id}</span>
          <span className='title'>{getContactFullName(contact, t => t)}</span>
          {type === 'contact_primary' && <span className='primary'>{'PRIMARY'}</span>}
        </div>
        <div className='row-2'>
          {contact.emails.length > 0 && <IconText icon={MailIcon} text={contact.emails.join(', ')} style={{ marginRight: 5, }} />}
          {contact.phones.length > 0 && <IconText icon={PhoneIcon} text={contact.phones.map(phone => phone.split(' ')[0]).join(', ')} />}
        </div>
      </span>;
  }
  else if (type === 'conversation') {
    const conversation: IPreviewConversation = entity as IPreviewConversation;

    option.title = `#${conversation.id}: ${conversation.title}`;
  }
  else if (type === 'voice_route') {
    option.title = (entity as IPreviewVoiceRoute).number;
    option.display = <DisplayPhoneNumber t={(s) => s} phonenumber={translatePhoneNumber((entity as IPreviewVoiceRoute).number)} />;
  }
  else if ((entity as IOptionEntity).title)
    option.title = (entity as IOptionEntity).title;

  const children = (entity as IOptionEntity).children;

  if (children)
    option.children = children.map(child => entityToOption(child, type, organization, display, key_prefix));

  if (organization && (entity as any).organization_id) {
    if (display)
      option.display = <>{option.title} <span style={{ opacity: .35, }}>({organization.title})</span></>;

    option.title += ` (${organization.title})`;
  }

  return option;
};

export const entitiesToOptions = <T extends IOptionEntity | IPreviewOrganization | IPreviewUser | IPreviewContact | IElasticPreviewConversation | IElasticPreviewContact>(entities: Array<T>, type?: string, customFilter?: (item: T) => boolean, display: boolean = true, key_prefix: Nullable<IOptionKeyPrefix> = null): Array<IOption> => {
  const options: Array<IOption> = [];

  entities.forEach(entity => !(entity as IOptionEntity).deleted_at && (!customFilter || customFilter(entity)) && options.push(entityToOption(entity, type, undefined, display, key_prefix)));

  return options;
};

export const entitiesToOptionsWithOrganization = <T extends IOptionEntity>(organizations: Nullable<IOrganizationStore>, entities: Array<T>, type?: string, customFilter?: (item: T) => boolean, display: boolean = true, key_prefix: Nullable<IOptionKeyPrefix> = null): Array<IOption> => {
  const options: Array<IOption> = [];

  entities.forEach(entity => !(entity as IOptionEntity).deleted_at && (!customFilter || customFilter(entity)) && options.push(entityToOption(entity, type, organizations && entity.organization_id ? organizations.dict[entity.organization_id] : undefined, display, key_prefix)));

  return options;
};
