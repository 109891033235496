"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionVoiceQueue = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const misc_1 = require("../../misc");
const fail_reasons = (0, misc_1.EnumValues)(enums_1.VoiceCallQueueFailExitReasonEnum);
const events = [
    'JOINED',
    'ASSIGNED',
];
exports.JourneyActionVoiceQueue = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.VoiceQueue,
    channels: [enums_1.ChannelEnum.Voice,],
    settings_schema: (0, base_1.settingsValidationSchema)({
        queue_id: {
            type: 'number',
        },
        client_id: {
            type: 'number',
            default: null,
            allow: [null,],
        },
        timeout: {
            type: 'number',
            allow: [null,],
            default: null,
        },
        events: {
            type: 'object',
            properties: Object.fromEntries(events.map(event => ([event, { type: 'boolean', default: false, },]))),
        },
        fail_reasons: {
            type: 'object',
            properties: Object.fromEntries(fail_reasons.map(fail_reason => ([fail_reason, { type: 'boolean', default: false, },]))),
        },
    }),
    getPorts: (node) => {
        const ports = Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_QUEUE_DONE_ANSWERED_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_QUEUE_DONE_ANSWERED_OUTPUT,
                required: true,
                index: 2,
                title: 'DONE',
                description: 'DONE_ANSWERED_QUEUE_DESCRIPTION',
                color: 'var(--green)',
            }), [constants_1.JOURNEY_ACTION_QUEUE_DONE_NO_ANSWER_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_QUEUE_DONE_NO_ANSWER_OUTPUT,
                required: true,
                index: 3,
                title: 'DONE',
                description: 'DONE_NO_ANSWER_QUEUE_DESCRIPTION',
                color: 'var(--red)',
            }) });
        console.log('node.properties', node.properties);
        events.forEach((event, i) => {
            var _a, _b;
            if (!((_b = (_a = node.properties) === null || _a === void 0 ? void 0 : _a.events) === null || _b === void 0 ? void 0 : _b[event]))
                return;
            const id = `${constants_1.JOURNEY_ACTION_QUEUE_EVENT_OUTPUT}_${event.toUpperCase()}`;
            ports[id] = {
                id,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: true,
                title: event.toUpperCase(),
                description: `EVENT_${event.toUpperCase()}_DESCRIPTION`,
                index: i + 5,
                color: null,
            };
        });
        fail_reasons.forEach((fail_reason, i) => {
            var _a, _b;
            if (!((_b = (_a = node.properties) === null || _a === void 0 ? void 0 : _a.fail_reasons) === null || _b === void 0 ? void 0 : _b[fail_reason]))
                return;
            const id = `${constants_1.JOURNEY_ACTION_QUEUE_DONE_NO_ANSWER_OUTPUT}_${fail_reason.toUpperCase()}`;
            ports[id] = {
                id,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: true,
                title: fail_reason.toUpperCase(),
                description: `FAIL_REASON_${fail_reason.toUpperCase()}_DESCRIPTION`,
                index: i + 10,
                color: 'var(--red)',
            };
        });
        return ports;
    },
});
