"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryTypeToUnitType = exports.getMaxUserStatusGroup = exports.getMaxUserStatus = exports.MessageAnsweredAbandonedWithinArray = exports.ThreadAnsweredAbandonedWithinArray = exports.AnsweredAbandonedWithinArray = exports.MaxWaitingOngoingArray = void 0;
const bitwise_1 = require("./bitwise");
const enums_1 = require("../enums");
exports.MaxWaitingOngoingArray = [0, 15, 30, 45,];
exports.AnsweredAbandonedWithinArray = [3, 6, 24, 48,].map(h => h * 60 * 60);
exports.ThreadAnsweredAbandonedWithinArray = [15, 30, 45, 60, 180, 300, ...exports.AnsweredAbandonedWithinArray,];
exports.MessageAnsweredAbandonedWithinArray = [5, 10, 20, 30, 60, 180, 300, ...exports.AnsweredAbandonedWithinArray,];
const getMaxUserStatus = (status, statuses = [enums_1.UserStatusStatusEnum.Busy, enums_1.UserStatusStatusEnum.Paused, enums_1.UserStatusStatusEnum.HandlingConversation, enums_1.UserStatusStatusEnum.PartialBusy, enums_1.UserStatusStatusEnum.Idle, enums_1.UserStatusStatusEnum.Online,]) => {
    if (status && status > 0) {
        for (let s of statuses)
            if ((0, bitwise_1.bitwiseIncludes)(status, s))
                return s;
    }
    return enums_1.UserStatusStatusEnum.Offline;
};
exports.getMaxUserStatus = getMaxUserStatus;
const getMaxUserStatusGroup = (status) => {
    return (0, exports.getMaxUserStatus)(status, [enums_1.UserStatusStatusEnum.Busy, enums_1.UserStatusStatusEnum.Paused, enums_1.UserStatusStatusEnum.Idle, enums_1.UserStatusStatusEnum.Online,]);
};
exports.getMaxUserStatusGroup = getMaxUserStatusGroup;
const queryTypeToUnitType = (query_type) => {
    switch (query_type) {
        case enums_1.MetricQueryTypeEnum.Max:
        case enums_1.MetricQueryTypeEnum.Min:
        case enums_1.MetricQueryTypeEnum.SumMax:
        case enums_1.MetricQueryTypeEnum.Sum:
        case enums_1.MetricQueryTypeEnum.Diff:
        case enums_1.MetricQueryTypeEnum.Least:
        case enums_1.MetricQueryTypeEnum.Greatest:
            return enums_1.MetricUnitTypeEnum.Count;
        case enums_1.MetricQueryTypeEnum.Static:
            return enums_1.MetricUnitTypeEnum.String;
        case enums_1.MetricQueryTypeEnum.Percent:
            return enums_1.MetricUnitTypeEnum.Percent;
        case enums_1.MetricQueryTypeEnum.Avg:
            return enums_1.MetricUnitTypeEnum.Decimal;
    }
    return enums_1.MetricUnitTypeEnum.Count;
};
exports.queryTypeToUnitType = queryTypeToUnitType;
