"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionRestRequest = exports.REST_REQUEST_PARAMS_SCHEMA = exports.REST_REQUEST_VALUE_OPTIONS = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
exports.REST_REQUEST_VALUE_OPTIONS = [
    'conversation.id',
    'conversation.organization_id',
    'conversation.title',
    'conversation_queue.title',
    'conversation.queue_id',
    'conversation_client.title',
    'conversation.client_id',
    'conversation.contact_id',
    'conversation.journey_id',
    'conversation.disposition_id',
    'conversation_disposition.title',
    'conversation.sub_disposition_id',
    'conversation_sub_disposition.title',
    'conversation.thrd_disposition_id',
    'conversation_thrd_disposition.title',
    'conversation.status',
    'conversation.priority',
    'conversation.user_id',
    'conversation.status_at',
    'conversation.response_time',
    'conversation.resolution_time',
    'conversation.main_channel',
    'conversation.direction',
    'conversation.tags',
    'conversation.channels',
    'conversation.created_at',
    'conversation.data',
    'voice_call.id',
    'voice_call.direction',
    'voice_call.voice_asterisk_id',
    'voice_call.queue_id',
    'voice_call.voice_route_id',
    'voice_call.contact_phone',
    'voice_call.created_at',
    'voice_call.end_time',
    'voice_call.variables',
    'voice_call_first_answered_at',
    'voice_call_bridges_count',
    'current_voice_call_bridge.id',
    'current_voice_call_bridge.direction',
    'current_voice_call_bridge.created_at',
    'current_voice_call_bridge.answer_time',
    'current_voice_call_bridge.leave_at',
    'current_voice_call_bridge.user_id',
    'current_voice_call_bridge.queue_id',
    'current_voice_call_bridge.is_external',
    'current_voice_call_bridge.is_transfer',
    'current_voice_call_bridge.is_transferred',
    'current_voice_call_bridge.exit_reason',
    'first_voice_call_bridge.id',
    'first_voice_call_bridge.direction',
    'first_voice_call_bridge.created_at',
    'first_voice_call_bridge.answer_time',
    'first_voice_call_bridge.leave_at',
    'first_voice_call_bridge.user_id',
    'first_voice_call_bridge.queue_id',
    'first_voice_call_bridge.is_external',
    'first_voice_call_bridge.is_transfer',
    'first_voice_call_bridge.is_transferred',
    'first_voice_call_bridge.exit_reason',
    'last_voice_call_bridge.id',
    'last_voice_call_bridge.direction',
    'last_voice_call_bridge.created_at',
    'last_voice_call_bridge.answer_time',
    'last_voice_call_bridge.leave_at',
    'last_voice_call_bridge.user_id',
    'last_voice_call_bridge.queue_id',
    'last_voice_call_bridge.is_external',
    'last_voice_call_bridge.is_transfer',
    'last_voice_call_bridge.is_transferred',
    'last_voice_call_bridge.exit_reason',
    'mail_message.id',
    'mail_message.mail_account_id',
    'mail_message.subject',
    'mail_message.contact_email',
    'mail_message.created_at',
    'contact.id',
    'contact.first_name',
    'contact.last_name',
    'contact.emails',
    'contact.phones',
    'contact.data',
    'form_submission.id',
    'form_submission.form_website_id',
    'form_submission.contact_email',
    'form_submission.contact_phone',
    'draft.id',
    'draft.created_at',
    'internal_note.id',
    'internal_note.created_at',
    'user.id',
    'user.username',
    'user.email',
    'user.first_name',
    'user.last_name',
    'user.full_name',
];
const REST_REQUEST_PARAMS_SCHEMA = (group) => ({
    type: 'array',
    items: {
        type: 'object',
        properties: {
            key: { type: 'string', required: true, },
            type: { type: 'string', valid: ['custom', 'defined',], default: 'defined', required: true, },
            value: {
                type: 'string',
                required: true,
                when: {
                    reference: { '$ref': 'type', },
                    is: {
                        type: 'any',
                        valid: ['defined',],
                    },
                    then: {
                        type: 'any',
                        valid: group === 'body' ? exports.REST_REQUEST_VALUE_OPTIONS : exports.REST_REQUEST_VALUE_OPTIONS.filter(o => o.includes('.')),
                    },
                },
            },
            data_key: {
                type: 'string',
                when: [
                    {
                        reference: { '$ref': 'value', },
                        is: {
                            type: 'string',
                            required: true,
                            valid: [
                                'conversation.data',
                                'contact.data',
                            ],
                        },
                        then: {
                            type: 'any',
                            required: true,
                        },
                        otherwise: {
                            type: 'any',
                            forbidden: true,
                        },
                    },
                ],
            },
            variable_alias: {
                type: 'string',
                when: {
                    reference: { '$ref': 'value', },
                    is: {
                        type: 'any',
                        not: ['voice_call.variables',],
                    },
                    then: {
                        type: 'any',
                        forbidden: true,
                    },
                    otherwise: {
                        type: 'any',
                        required: true,
                    },
                },
            },
        },
    },
});
exports.REST_REQUEST_PARAMS_SCHEMA = REST_REQUEST_PARAMS_SCHEMA;
exports.JourneyActionRestRequest = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.RestRequest,
    initialValues: {
        status_ports: [],
    },
    settings_schema: (0, base_1.settingsValidationSchema)({
        url: {
            type: 'string',
            uri: true,
            required: true,
        },
        method: {
            type: 'string',
            valid: ['get', 'post', 'update', 'put', 'delete',],
            required: true,
        },
        json_response: {
            type: 'boolean',
            required: true,
            default: false,
        },
        journey_variables: {
            type: 'array',
            optional: true,
            items: {
                type: 'object',
                properties: {
                    json_path: {
                        type: 'string',
                    },
                    journey_variable_id: {
                        type: 'number',
                    },
                },
            },
            when: {
                reference: 'json_response',
                is: {
                    type: 'any',
                    valid: [false,],
                    required: true,
                },
                then: {
                    type: 'array',
                    forbidden: true,
                },
            },
        },
        fields: {
            type: 'array',
            optional: true,
            items: {
                type: 'object',
                properties: {
                    json_path: {
                        type: 'string',
                    },
                    field_id: {
                        type: 'number',
                    },
                },
            },
            when: {
                reference: 'json_response',
                is: {
                    type: 'any',
                    valid: [false,],
                    required: true,
                },
                then: {
                    type: 'array',
                    forbidden: true,
                },
            },
        },
        journey_variable_id: {
            type: 'number',
            optional: true,
            when: {
                reference: 'json_response',
                is: {
                    type: 'any',
                    valid: [true,],
                    required: true,
                },
                then: {
                    type: 'number',
                    forbidden: true,
                },
            },
        },
        field_id: {
            type: 'number',
            optional: true,
            when: {
                reference: 'json_response',
                is: {
                    type: 'any',
                    valid: [true,],
                    required: true,
                },
                then: {
                    type: 'number',
                    forbidden: true,
                },
            },
        },
        status_ports: {
            type: 'array',
            items: {
                type: 'number',
            },
        },
        query_params: (0, exports.REST_REQUEST_PARAMS_SCHEMA)('query_params'),
        headers: (0, exports.REST_REQUEST_PARAMS_SCHEMA)('headers'),
        body: Object.assign(Object.assign({}, (0, exports.REST_REQUEST_PARAMS_SCHEMA)('body')), { when: {
                reference: { '$ref': 'method', },
                is: {
                    type: 'any',
                    valid: ['get',],
                },
                then: { type: 'any', forbidden: true, },
            } }),
    }),
    getPorts: (node) => {
        const properties = node.properties;
        const new_ports = Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_REST_REQUEST_ERROR_STATUS_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_REST_REQUEST_ERROR_STATUS_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: false,
                index: 0,
                title: 'ERROR',
                color: '#d91010',
            }), [constants_1.JOURNEY_ACTION_REST_REQUEST_SUCCESS_STATUS_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_REST_REQUEST_SUCCESS_STATUS_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: false,
                index: 1,
                title: 'SUCCESS',
                color: '#5bbf1c',
            }) });
        properties.status_ports.forEach((status_code, index) => {
            const id = `${constants_1.JOURNEY_ACTION_REST_REQUEST_DEFINED_STATUS_OUTPUT}_${status_code}`;
            new_ports[id] = (0, utils_1.journeyNodePortAddDefault)({
                id,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: true,
                required: true,
                index: index + 2,
                title: status_code + '',
                color: (status_code >= 200 && status_code < 300) ? '#5bbf1c' : (status_code >= 400 && status_code < 600) ? '#d91010' : null,
            });
        });
        return new_ports;
    },
});
