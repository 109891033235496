import React, { useMemo } from "react";
import { useAuth, useIsSocketDisconnected } from "@Hooks";
import "./header.scss";
import { HeaderNotifications } from "./header.notifications";
import { HeaderCreateConversation } from "./header.create.conversation";
import { canSend, ChannelEnum } from "atlas-shared";
import { HeaderAuthUser } from "./header.auth.user";
import { HeaderCallBar } from "./header.call.bar/header.call.bar";
import { HeaderMenu } from "./header.menu/header.menu";
import { HeaderSearch } from "./header.search";
import { HeaderUserPresence } from "./header.user.presence";
import { HeaderUserStatus } from "./header.user.status";
import { HeaderAdminMenu } from "./header.admin.menu";
import { HeaderLogo } from "./header.logo/header.logo";
import { useTranslation } from "react-i18next";

interface IProps {
  onToggleMenu: () => void;
}

export const Header = React.memo(({ onToggleMenu }: IProps) => {
  const auth = useAuth();
  const isSocketDisconnected = useIsSocketDisconnected();
  const { t } = useTranslation();

  const canCreateConversation = useMemo(() => {
    return canSend(auth.user, ChannelEnum.Mail) || canSend(auth.user, ChannelEnum.Sms);
  }, [auth.user]);

  return (
    <header className="main-header">
      {isSocketDisconnected && (
        <div className="socket-disconnected">
          <span>{t("SOCKET_DISCONNECTED")}</span>
        </div>
      )}
      <div className="header-contents">
        {auth.is_admin && (
          <div className="col">
            <HeaderAdminMenu onToggleMenu={onToggleMenu} />
          </div>
        )}
        <div className="col">
          <HeaderLogo auth={auth} t={t} />
        </div>
        <div className="col">
          <HeaderMenu auth={auth} />
        </div>
        <div className="col" style={{ flexGrow: 1 }}>
          <HeaderSearch />
        </div>
        {auth.user.main && auth.is_agent && canCreateConversation && (
          <div className="col" style={{ display: "flex", justifyContent: "center" }}>
            <HeaderCreateConversation />
          </div>
        )}
        {(!auth.is_agent || !auth.user.main) && <div className="col empty" />}
        {auth.user.main && auth.user_status.agentprofile_id && (
          <div className="col">
            <HeaderCallBar />
          </div>
        )}
        {auth.user.main && (
          <div className="col">
            <HeaderUserPresence />
          </div>
        )}
        {auth.user.main && auth.is_agent && (
          <div className="col">
            <HeaderUserStatus />
          </div>
        )}
        <div className="col">
          <HeaderNotifications />
        </div>
        <div className="col">
          <HeaderAuthUser />
        </div>
      </div>
    </header>
  );
});
