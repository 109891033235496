import React, { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ChannelEnum } from "atlas-shared";
import { ConversationList, ConversationView, CreateEmailConversation } from "@Components";
import { CreateTextMessageConversation } from "@Components/dashboard/create-conversation/components/CreateTextMessageConversation";

interface IProps {
  create_conversation?: boolean;
}

interface ParsedParams {
  channel: ChannelEnum | undefined;
  queueId: string | undefined;
  organizationId: string | undefined;
  contactId: number | undefined;
}

const parseSearchParams = (searchParams: URLSearchParams): ParsedParams => {
  const channel = (searchParams.get("channel") as ChannelEnum) || undefined;
  const queueId = searchParams.get("queue_id") || undefined;
  const organizationId = searchParams.get("organization_id") || undefined;
  const contactId = searchParams.get("contact_id");

  return {
    channel,
    queueId,
    organizationId,
    contactId: contactId ? Number(contactId) : undefined,
  };
};

export const DashboardLayout = React.memo(({ create_conversation = false }: IProps) => {
  const { id, organization_id } = useParams<"id" | "organization_id">();
  const [searchParams] = useSearchParams();

  const parsedParams = useMemo(() => parseSearchParams(searchParams), [searchParams]);

  const currentId = id ? Number(id) : 0;
  const showConversationView = id && organization_id;
  const showEmailCreation = !id && create_conversation && parsedParams.channel === ChannelEnum.Mail;
  const showSmsCreation = !id && create_conversation && parsedParams.channel === ChannelEnum.Sms;

  return (
    <div className={`inbox-container${id ? " with-id" : ""}`}>
      <ConversationList shortened={Boolean(id) || create_conversation} current={currentId} />

      {showConversationView && (
        <ConversationView organization_id={+organization_id} conversation_id={+id} />
      )}

      {showEmailCreation && (
        <CreateEmailConversation
          channel={parsedParams.channel}
          queue_id={parsedParams.queueId}
          organization_id={parsedParams.organizationId}
          contact_id={parsedParams.contactId}
        />
      )}

      {showSmsCreation && <CreateTextMessageConversation />}
    </div>
  );
});
