import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./notification.scss";
import { ArrowRightCircleIcon, WarningIcon } from "@Assets/icons";
import {
  getButtonProps,
  getNotificationActions,
  IPreviewNotification,
  IPreviewQueue,
  IPreviewUser,
  ISharedNotificationUser,
  ISharedQueue,
  NotificationActionEnum,
  Nullable,
  Undefinable,
  ChannelEnum,
  ISharedNotification,
  NotificationSubjectEnum,
} from "atlas-shared";
import Text from "antd/es/typography/Text";
import { useTranslation } from "react-i18next";
import {
  AlertError,
  BrowserNotification,
  getFullName,
  RemoveBrowserNotification,
  RestRequest,
  timeSince,
  TranslateAndDisplayPhoneNumber,
} from "@Utils";
import { patchNotificationUser } from "@Api";
import { Invitation, SessionState } from "sip.js";
import { getSipUsersWrapper, IVoiceSessionObject } from "SIP";
import { IAuth, IGlobalKeyPressed } from "@Store";
import { useKeyPressed, useQueues, useUsers, useVisibilityChange } from "@Hooks";
import { Button, ChannelIcon, CircleProgress, generateText, IconText } from "@Components";
import parsePhoneNumber from "libphonenumber-js";
import { useStableNavigate } from "../../context/StableNavigateContext";
import { checkFlag } from "@Api/featureFlagApi";

interface IProps {
  notification_user: ISharedNotificationUser;
  header: boolean;
  auth: IAuth;
}

const getNotificationSession = (
  isVoiceCall,
  notification: IPreviewNotification,
  sip_user_wrapper
): Nullable<Invitation> => {
  let session: Undefinable<IVoiceSessionObject>;

  if (isVoiceCall && notification?.payload?.vid)
    session = sip_user_wrapper
      .getSipUser(notification.payload.vid)
      .getSipUser()
      ?.getSessionByPreviewNotification(notification);

  return session ? (session?.session as Invitation) : null;
};

export const Notification = React.memo(({ notification_user, header, auth }: IProps) => {
  const navigate = useStableNavigate();
  const { notification } = notification_user;
  const { conversation } = notification || {};
  // const [seen, setSeen] = useState<boolean>(!!notification_user.seen_at);
  const { t } = useTranslation();
  const queues = useQueues();
  const users = useUsers();
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [percentage, setPercentage] = useState<number>(0);
  const [buttons, setButtons] = useState<
    Array<{ subject: NotificationActionEnum; action: string; title: string; onClick: () => {} }>
  >([]);
  const sip_user_wrapper = getSipUsersWrapper();
  const isVoiceCall = useMemo(
    () =>
      notification?.channel === ChannelEnum.Voice &&
      sip_user_wrapper &&
      notification?.payload?.uid &&
      notification?.payload?.vid,
    [notification, sip_user_wrapper]
  );
  const [queue, setQueue] = useState<IPreviewQueue>();
  const [phone, setPhone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [caller, setCaller] = useState<IPreviewUser>();
  const [browserNotification, setBrowserNotification] = useState(false);
  const isVisible = useVisibilityChange();
  const keyPressed = useKeyPressed();
  const isStatsAlarm = notification?.subject === NotificationSubjectEnum.StatsAlarm;
  // const { isIntersecting, cancelIntersecting } = useOnScreen(ref);

  // useEffect(() => {
  //   console.log('isIntersecting', notification_user.id, isIntersecting);
  //
  //   if (!isIntersecting || !header || seen)
  //     return;
  //
  //   if (!header || isIntersecting)
  //     cancelIntersecting();
  //
  //   if (isIntersecting)  {
  //     setSeen(true);
  //     //patchNotificationUser(notification_user.id, { seen_at: new Date().toISOString() });
  //   }
  // }, [isIntersecting]);

  const performKeyPress = useCallback(
    (keyPressed: IGlobalKeyPressed) => {
      if (keyPressed && !keyPressed.contentEditable) {
        if (keyPressed.code === "Escape")
          (ref?.current?.querySelector(".buttons .ant-btn.reject") as HTMLElement)?.click();
        else if (keyPressed.code === "Enter")
          (ref?.current?.querySelector(".buttons .ant-btn.accept") as HTMLElement)?.click();
      }
    },
    [notification]
  );

  useEffect(() => {
    if (keyPressed) performKeyPress(keyPressed);
  }, [keyPressed]);

  useEffect(() => {
    if (!notification) return;

    if (
      !isVisible &&
      buttons.length &&
      !browserNotification &&
      notification.channel &&
      [ChannelEnum.Voice, ChannelEnum.Mail].includes(notification.channel)
    ) {
      setBrowserNotification(true);
      let displayPhone = (phone && parsePhoneNumber(phone)?.formatInternational()) || "";

      if (!displayPhone && caller) displayPhone = getFullName(caller);

      BrowserNotification(
        notification.id,
        t(`NOTIFICATION_${notification.subject}`),
        `#${notification.conversation_id}\n${displayPhone ? `${displayPhone}\n` : ""}${
          notification.channel !== ChannelEnum.Voice ? `${conversation?.title}\n` : ""
        }${
          queue?.voice_settings?.strategy && !notification?.payload?.direct
            ? `${t("QUEUE")}: ${queue.title}`
            : ""
        }`,
        buttons,
        `/icons/channels/${notification.channel}-${notification.direction}.png`
      );
    }

    if (isVisible && browserNotification) {
      RemoveBrowserNotification(notification.id);
      setBrowserNotification(false);
    }
  }, [isVisible, browserNotification, notification, buttons, phone, caller, queue]);

  useEffect(() => {
    return () => {
      if (notification) RemoveBrowserNotification(notification.id);
    };
  }, [notification]);

  useEffect(() => {
    if (!header && notification_user.notification)
      setButtons(
        getNotificationActions(notification_user.notification.subject).map((subject) => {
          return {
            subject,
            action: subject,
            title: t(subject),
            onClick: async () => {
              const buttonProps = getButtonProps(notification_user, subject);
              const { request, redirect } = buttonProps;

              let session = !notification_user.notification?.payload?.ari
                ? getNotificationSession(
                    isVoiceCall,
                    notification_user.notification as ISharedNotification,
                    sip_user_wrapper
                  )
                : null;
              const isARI = notification_user.notification?.payload?.ari;

              if (session?.state === SessionState.Initial) {
                if (subject === NotificationActionEnum.Accept) await session.accept();
                else await session.reject();
              }

              if (!session && request) {
                try {
                  if (isARI && await checkFlag("voice.ari", false)) {
                    await fetch(
                      process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/response",
                      {
                        body: JSON.stringify({
                          status: subject,
                          notification: { id: notification_user.notification?.id },
                        }),
                        method: "POST",
                        headers: {
                          "Content-type": "application/json",
                        },
                      }
                    );
                  } else {
                    await RestRequest[request.method](request.path, request.payload);
                  }
                } catch (err: any) {
                  AlertError(t, {
                    content: t(err?.toString()),
                  });
                }
              }

              if (redirect) navigate(redirect);
              else if (
                notification?.conversation_id &&
                (window.location.pathname.startsWith("/admin") ||
                  window.location.pathname.startsWith("/stats")) &&
                [
                  NotificationActionEnum.Accept,
                  NotificationActionEnum.OK,
                  NotificationActionEnum.GoToConversation,
                ].includes(subject)
              )
                navigate(
                  `/dashboard/conversation/${notification.organization_id}/${notification.conversation_id}`
                );
            },
          };
        })
      );
  }, [notification_user?.notification, header]);

  useEffect(() => {
    if (!caller && users.loaded && notification?.payload) {
      const { caller: _caller } = notification.payload;

      if (_caller) setCaller(users.users.find((u) => u.id === _caller));
    }
  }, [notification, setCaller, users, caller]);

  useEffect(() => {
    if (queues.loaded && notification?.queue_id) setQueue(queues.dict[notification.queue_id]);
  }, [notification, setQueue, queues, queue]);

  useEffect(() => {
    setEmail(notification?.payload?.email);
    setPhone(notification?.payload?.phone);
  }, [notification]);

  useEffect(() => {
    if (!queue) return;

    const auto_accept: Nullable<{
      delay: number;
      queues: Nullable<Array<ISharedQueue["id"]>>;
    }> = notification?.channel && auth.user.settings?.auto_accept?.[notification?.channel];

    if (auto_accept && notification) {
      if (auto_accept.queues !== null && !auto_accept.queues.includes(queue.id)) return;

      const timer = setTimeout(() => {
        if (isVoiceCall) {
          let session = getNotificationSession(isVoiceCall, notification, sip_user_wrapper);

          // window must be focused in order for this to work
          if (session?.state === SessionState.Initial) session.accept();
        } else
          buttons.find((button) => button.subject === NotificationActionEnum.Accept)?.onClick?.();
      }, (auto_accept?.delay || 0) * 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [queue, auth, isVoiceCall, notification, sip_user_wrapper, buttons]);

  useEffect(() => {
    if (header) return;

    const from = new Date(notification_user.created_at);
    const interval = setInterval(() => {
      if (!notification?.timeout) return;

      const to = new Date(from.getTime() + notification.timeout * 1000);
      const left = Math.floor((to.getTime() - Date.now()) / 1000);
      const percent = Math.floor((left / notification.timeout) * 100);

      setPercentage(percent);
    }, 1000);

    return () => clearInterval(interval);
  }, [notification?.timeout, header, notification_user.created_at]);

  if (!notification) return <></>;

  return (
    <div
      ref={ref}
      className={`${header ? "header-notification" : "notification"} ${
        notification_user.seen_at ? "seen" : "not-seen"
      } notification-type-${notification.subject}`}
      onClick={() => {
        if (header) {
          notification.conversation_id &&
            navigate(
              `/dashboard/conversation/${notification.organization_id}/${notification.conversation_id}`
            );
          if (!notification_user.seen_at)
            patchNotificationUser(notification_user.organization_id, notification_user.id, {
              seen_at: new Date().toISOString(),
            });
        }
      }}
    >
      <div className={"header"}>
        {(notification.channel || isStatsAlarm) && (
          <div className={"channel-icon-wrp"}>
            <div className={"channel-icon"}>
              {header && !notification_user.seen_at && <span className="dot"></span>}
              {!header && <CircleProgress percentage={percentage} colour={"#39c660"} size={36} />}
              {notification.channel && (
                <ChannelIcon
                  channel={{ c: notification.channel, d: notification.direction }}
                  size={14}
                  padding={7}
                />
              )}
              {isStatsAlarm && (
                <WarningIcon size={30} style={{ color: "var(--orange-light)", strokeWidth: 2 }} />
              )}
            </div>
          </div>
        )}
        <div className={"texts"}>
          <Text className={"title"}>
            {t(`NOTIFICATION_${notification.subject}`, notification.payload || {})}
          </Text>
          <Text className={"description"}>
            {[
              <div className="conversation">
                {notification.conversation_id && (
                  <div className="conversation-id">#{notification.conversation_id}</div>
                )}
                {(header || (!header && isStatsAlarm)) && (
                  <div className="title">
                    {notification.title}
                    {!notification.title && isStatsAlarm && generateText(t, notification)}
                  </div>
                )}
                {!header && notification.channel !== ChannelEnum.Voice && (
                  <div className="title">{conversation?.title}</div>
                )}
              </div>,
              phone && (
                <div className="phone">
                  <TranslateAndDisplayPhoneNumber
                    t={t}
                    phone_number={phone}
                    style={{ paddingRight: 5 }}
                  />
                </div>
              ),
              email && <div className="email">{email}</div>,
              queue?.voice_settings?.strategy && !notification?.payload?.direct && (
                <IconText
                  iconProps={{ size: 16 }}
                  text={queue?.title}
                  icon={ArrowRightCircleIcon}
                  style={{ paddingRight: 5 }}
                />
              ),
              notification?.payload?.description && (
                <div className="description">{t(notification.payload.description)}</div>
              ),
              !phone && caller && (
                <IconText
                  iconProps={{ size: 16 }}
                  text={getFullName(caller)}
                  icon={ArrowRightCircleIcon}
                />
              ),
              notification.subject === NotificationSubjectEnum.PasswordLifetimeExpiryClose && (
                <div>{t("PASSWORD_EXPIRY_DESCRIPTION", notification.payload || {})}</div>
              ),
            ].filter(Boolean)}
          </Text>
          {header && (
            <Text className={"time"}>
              {timeSince(t, new Date(notification_user.created_at), true)}
            </Text>
          )}
        </div>
      </div>
      {!header && notification_user.notification && (
        <div className={"buttons"}>
          {buttons.map((button) => {
            return (
              <Button onClick={button.onClick} className={button.action.toLowerCase()}>
                {button.title}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
});
