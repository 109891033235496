import { IPreviewVoiceChanspy } from "atlas-shared/dist";
import { checkFlag } from "./featureFlagApi";
import { RestRequest } from "@Utils";

export * from "./abstract/voice_chanspy.api.abstract";

export const hangupVoiceChanspy = async (
  id: IPreviewVoiceChanspy["id"]
): Promise<IPreviewVoiceChanspy> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/chanspy/" + id, {
      body: JSON.stringify({ id }),
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }).then((v) => v.json());
  } else {
    return RestRequest.delete<IPreviewVoiceChanspy>(`voice_chanspy/${id}`);
  }
};
