import { useState } from "react";
import { useAsync } from "@Hooks/abstract/useAsync";
import {
  FeatureFlagOrganization,
  getFeatureFlags,
  GetFeatureFlagsOrganization,
  IFeatureFlag,
} from "@Api/featureFlagApi";
import { FeatureFlagContext, FlagRecord } from "../context/FeatureFlagContext";
import { IAuth } from "@Store";

export interface IFeatureFlagProviderProps {
  auth: IAuth;
  children: React.ReactNode;
}

export const FeatureFlagProvider = (props: IFeatureFlagProviderProps) => {
  const [flags, setFlags] = useState<FlagRecord>();
  const { data: featureFlagOrganization } = useAsync(GetFeatureFlagsOrganization, {
    skip: !props.auth,
  });
  const { data: featureFlags } = useAsync(getFeatureFlags, { skip: !props.auth });

  const mergeFlags = (
    featureFlags: IFeatureFlag[] | null,
    featureFlagsOrganization: FeatureFlagOrganization[]
  ): FlagRecord => {
    if (!featureFlags || !featureFlagOrganization) return {};

    const flags: FlagRecord = {};
    for (const flag of featureFlags) {
      flags[flag.identifier] = flag.defaultValue;
    }
    for (const flag of featureFlagsOrganization) {
      flags[flag.flag.identifier] = flag.value;
    }
    return flags;
  };

  const mergedFlags = mergeFlags(featureFlags, featureFlagOrganization || []);

  return (
    <FeatureFlagContext.Provider value={{ flags: mergedFlags, setFlags }}>
      {props.children}
    </FeatureFlagContext.Provider>
  );
};
