import { useCallback, useEffect, useState } from "react";
import { searchContact } from "@Api";
import { entityToOption, IOption } from "@Utils";
import { Nullable } from "atlas-shared";
import { debounce } from "lodash";

interface UseContactSearchArgs {
  organization_id?: number;
  agentprofile_id?: Nullable<number>;
  paramsContact?: { contact_id: string; contact_email: string };
  valueKey?: "contact_id" | "contact_email" | "contact_number";
  onContactSelect?: (
    contactId: Nullable<number>,
    contactEmail: string,
    contactNumber: string
  ) => void;
}

export const useContactSearch = ({
  organization_id,
  agentprofile_id,
  onContactSelect,
  paramsContact,
  valueKey = "contact_id",
}: UseContactSearchArgs) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState<IOption[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleSearch = async (searchValue: string) => {
    setSearchTerm(searchValue);
    if (searchValue.length < 2) return;
    const result = await searchContact(
      searchValue,
      10,
      [], // MARK: Where do excluded contacts come from?
      agentprofile_id || null,
      organization_id
    );

    setOptions(result.items.map(({ item }) => entityToOption(item, "contact")));
  };

  const handleChange = (value: string) => {
    if (!onContactSelect) return;

    const selectedContact = options.find((option) => {
      if (valueKey === "contact_email") {
        return option.entity.emails[0] === value;
      } else if (valueKey === "contact_number") {
        return option.entity.phones[0]?.split(" ")[0] === value;
      }
      return option.key === value;
    });

    if (!selectedContact) return;

    onContactSelect(
      Number(selectedContact.entity.id),
      selectedContact.entity.emails[0] || "",
      selectedContact.entity.phones[0]?.split(" ")[0] || ""
    );
  };

  const debouncedSearch = useCallback(
    debounce(
      (value) => {
        handleSearch(value);
      },
      500,
      { leading: true }
    ),
    []
  );

  useEffect(() => {
    if (paramsContact && onContactSelect) {
      const initialOption = {
        key: paramsContact.contact_id,
        title: paramsContact.contact_email,
        entity: {
          id: Number(paramsContact.contact_id),
          emails: [paramsContact.contact_email],
          phones: [""],
        },
      };

      setOptions([initialOption]);
      onContactSelect(Number(paramsContact.contact_id), paramsContact.contact_email, "");
    }
  }, []);

  return {
    searchTerm,
    options,
    dropdownVisible,
    setDropdownVisible,
    handleSearch: debouncedSearch,
    handleChange,
  };
};
