import React, { useEffect, useMemo, useState } from "react";
import "./admin-menu.scss";
import { Menu } from "antd";
import { AdminMenuAnimator } from "./admin.menu.animator";
import { PermissionEnum, CrudEnum } from "atlas-shared";
import {
  CpuIcon,
  FormIcon,
  MailIcon,
  MergeIcon,
  PdfIcon,
  PhoneIcon,
  ShieldIcon,
  SmsIcon,
  SuperadminIcon,
  ToolIcon,
  UserIcon,
  UsersIcon,
  WidgetPieIcon,
} from "@Assets/icons";
import { IAbilities } from "@Store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  visible: boolean;
  abilities: IAbilities;
  setVisible: (visible: boolean) => void;
}

const menu = [
  {
    key: "user",
    icon: UserIcon,
    title: "STAFF",
    subs: [
      { key: "users", permission: PermissionEnum.User, title: "USERS" },
      {
        key: "agentprofiles",
        permission: PermissionEnum.Agentprofile,
        title: "AGENTPROFILES",
      },
      {
        key: "adminprofiles",
        permission: PermissionEnum.Adminprofile,
        title: "ADMINPROFILES",
      },
      {
        key: "superadmin_profiles",
        permission: PermissionEnum.SuperadminProfile,
        title: "SUPERADMINPROFILES",
      },
      {
        key: "organizations",
        permission: PermissionEnum.Organization,
        title: "ORGANIZATIONS",
      },
      { key: "teams", permission: PermissionEnum.Team, title: "TEAMS" },
      { key: "clients", permission: PermissionEnum.Team, title: "CLIENTS" },
      {
        key: "api_keys",
        permission: PermissionEnum.ApiKey,
        title: "API_KEYS",
      },
    ],
  },
  {
    key: "gdpr",
    icon: ShieldIcon,
    title: "GDPR",
    subs: [{ key: "gdprs", permission: PermissionEnum.Gdpr, title: "GDPRS" }],
  },
  {
    key: "ai",
    icon: CpuIcon,
    title: "ARTIFICIELL_INTELLIGENCE",
    subs: [
      {
        key: "ai_assistants",
        permission: PermissionEnum.AiAssistant,
        title: "AI_ASSISTANTS",
      },
    ],
  },
  {
    key: "finance",
    icon: PdfIcon,
    title: "FINANCE",
    subs: [
      {
        key: "invoices",
        permission: PermissionEnum.Invoice,
        title: "INVOICES",
      },
      {
        key: "tariffs",
        permission: PermissionEnum.Tariff,
        title: "TARIFFS",
      },
      {
        key: "tariff_parents",
        permission: PermissionEnum.TariffParent,
        title: "TARIFF_PARENTS",
      },
      {
        key: "phonenumber_monthly_costs",
        permission: PermissionEnum.PhonenumberMonthlyCost,
        title: "PHONENUMBER_MONTHLY_COST",
      },
      {
        key: "phonenumber_monthly_cost_parents",
        permission: PermissionEnum.PhonenumberMonthlyCostParent,
        title: "PHONENUMBER_MONTHLY_COST_PARENTS",
      },
      {
        key: "phonenumber_setup_costs",
        permission: PermissionEnum.PhonenumberSetupCost,
        title: "PHONENUMBER_SETUP_COST",
      },
      {
        key: "phonenumber_setup_cost_parents",
        permission: PermissionEnum.PhonenumberSetupCostParent,
        title: "PHONENUMBER_SETUP_COST_PARENTS",
      },
      {
        key: "prepaid_credits",
        permission: PermissionEnum.PrepaidCredit,
        title: "PREPAID_CREDITS",
      },
    ],
  },
  {
    key: "routing",
    icon: MergeIcon,
    title: "ROUTING",
    subs: [
      { key: "queues", permission: PermissionEnum.Queue, title: "QUEUES" },
      { key: "skills", permission: PermissionEnum.Skill, title: "SKILLS" },
      {
        key: "journeies",
        permission: PermissionEnum.Journey,
        title: "JOURNEIES",
      },
      {
        key: "journey_drafts",
        permission: PermissionEnum.Journey,
        title: "JOURNEY_DRAFTS",
      },
      {
        key: "journey_triggers",
        permission: PermissionEnum.Journey,
        title: "JOURNEY_TRIGGERS",
      },
      {
        key: "destination_restrictors",
        permission: PermissionEnum.DestinationRestrictor,
        title: "DESTINATION_RESTRICTOR",
      },
    ],
  },
  {
    key: "voice",
    icon: PhoneIcon,
    title: "VOICE",
    subs: [
      {
        key: "voice_asterisks",
        permission: PermissionEnum.VoiceAsterisk,
        title: "VOICE_ASTERISKS",
      },
      {
        key: "voice_asterisk_downgrades",
        permission: PermissionEnum.VoiceAsterisk,
        title: "VOICE_ASTERISK_DOWNGRADES",
      },
      {
        key: "voice_trunks",
        permission: PermissionEnum.VoiceTrunk,
        title: "VOICE_TRUNKS",
      },
      {
        key: "voice_routes",
        permission: PermissionEnum.VoiceRoute,
        title: "VOICE_ROUTES",
      },
      {
        key: "phonenumber_prefixes/search",
        permission: PermissionEnum.PhonenumberPurchase,
        title: "PHONENUMBER_PREFIXES",
      },
      {
        key: "phonenumber_purchases",
        permission: PermissionEnum.PhonenumberPurchase,
        title: "PHONENUMBER_PURCHASES",
      },
      {
        key: "voice_voicemails",
        permission: PermissionEnum.VoiceVoicemail,
        title: "VOICE_VOICEMAILS",
      },
      { key: "sounds", permission: PermissionEnum.Sound, title: "SOUNDS" },
      {
        key: "voice_shortcuts",
        permission: PermissionEnum.VoiceShortcut,
        title: "VOICE_SHORTCUTS",
      },
      {
        key: "voice_recording_transcript_settings",
        permission: PermissionEnum.VoiceRecordingTranscriptSetting,
        title: "VOICE_RECORDING_TRANSCRIPT_SETTINGS",
      },
    ],
  },
  {
    key: "mail",
    icon: MailIcon,
    title: "MAIL",
    subs: [
      {
        key: "mail_accounts",
        permission: PermissionEnum.MailAccount,
        title: "MAIL_ACCOUNTS",
      },
    ],
  },
  {
    key: "text_message",
    icon: SmsIcon,
    title: "TEXT_MESSAGE",
    subs: [
      {
        key: "text_message_sources",
        permission: PermissionEnum.TextMessage,
        title: "TEXT_MESSAGE_SOURCES",
      },
    ],
  },
  {
    key: "contact_management",
    icon: UsersIcon,
    title: "CONTACT_MANAGEMENT",
    subs: [{ key: "lists", permission: PermissionEnum.List, title: "LISTS" }],
  },
  {
    key: "form_channel",
    icon: FormIcon,
    title: "FORM_CHANNEL",
    subs: [
      {
        key: "form_websites",
        permission: PermissionEnum.FormWebsite,
        title: "FORM_WEBSITES",
      },
    ],
  },
  {
    key: "tools",
    icon: ToolIcon,
    title: "TOOLS",
    subs: [
      {
        key: "dispositions",
        permission: PermissionEnum.Disposition,
        title: "DISPOSITIONS",
      },
      { key: "tags", permission: PermissionEnum.Tag, title: "TAGS" },
      { key: "pauses", permission: PermissionEnum.Pause, title: "PAUSES" },
      { key: "fields", permission: PermissionEnum.Field, title: "FIELDS" },
      { key: "forms", permission: PermissionEnum.Form, title: "FORMS" },
      {
        key: "time_intervals",
        permission: PermissionEnum.TimeInterval,
        title: "TIME_INTERVALS",
      },
      {
        key: "operational_hourses",
        permission: PermissionEnum.OperationalHours,
        title: "OPERATIONAL_HOURS",
      },
      {
        key: "sla_policies",
        permission: PermissionEnum.SlaPolicy,
        title: "SLA_POLICIES",
      },
      {
        key: "canned_answers",
        permission: PermissionEnum.CannedAnswer,
        title: "CANNED_ANSWERS",
      },
      {
        key: "signatures",
        permission: PermissionEnum.Signature,
        title: "SIGNATURES",
      },
      {
        key: "journey_variables",
        permission: PermissionEnum.JourneyVariable,
        title: "JOURNEY_VARIABLES",
      },
    ],
  },
  {
    key: "statistics",
    icon: WidgetPieIcon,
    title: "STATISTICS",
    subs: [
      {
        key: "stats_alarms",
        permission: PermissionEnum.StatsAlarm,
        title: "STATS_ALARMS",
      },
      {
        key: "points_systems",
        permission: PermissionEnum.PointsSystem,
        title: "POINTS_SYSTEMS",
      },
      {
        key: "stats_reports",
        permission: PermissionEnum.StatsReport,
        title: "STATS_REPORTS",
      },
      {
        key: "stats_widgets",
        permission: PermissionEnum.StatsWidget,
        title: "STATS_WIDGETS",
      },
      {
        key: "stats_custom_metrics",
        permission: PermissionEnum.StatsCustomMetric,
        title: "STATS_CUSTOM_METRICS",
      },
      {
        key: "wallboards",
        permission: PermissionEnum.Wallboard,
        title: "WALLBOARD",
      },
    ],
  },
  {
    key: "superadmin",
    icon: SuperadminIcon,
    title: "SUPERADMIN",
    subs: [
      {
        key: "releases",
        permission: PermissionEnum.Release,
        title: "RELEASES",
      },
    ],
  },
];

export const AdminMenu = React.memo(
  ({ visible, setVisible, abilities }: IProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]);

    const [defaultOpenKeys, setDefaultOpenKeys] = useState<Array<string>>([]);

    useEffect(() => {
      const key = window.location.pathname.replace("/admin/", "");
      const section = menu.find((section) => section.subs.some((sub) => sub.key === key));

      if (key) setDefaultSelectedKeys([key]);

      if (section) setDefaultOpenKeys([section.key]);

      setLoaded(true);
    }, [menu]);

    const rendered = useMemo(() => {
      return (
        <AdminMenuAnimator visible={visible} setVisible={setVisible}>
          <Menu
            defaultSelectedKeys={defaultSelectedKeys}
            defaultOpenKeys={defaultOpenKeys}
            mode="inline"
            className={`menu ${visible ? " active" : ""}`}
          >
            {menu.map((section) => {
              const subs = section.subs
                .map(({ title, key, permission }) => {
                  return (
                    abilities.canImplicit(null, CrudEnum.Read, permission) && (
                      <Menu.Item onClick={() => navigate(`/admin/${key}`)} key={key}>
                        {t(title)}
                      </Menu.Item>
                    )
                  );
                })
                .filter(Boolean);
              const Icon = section.icon;

              if (subs.length)
                return (
                  <Menu.SubMenu
                    key={section.key}
                    icon={<Icon size={18} />}
                    title={t(section.title)}
                  >
                    {subs}
                  </Menu.SubMenu>
                );

              return <React.Fragment key={section.key}></React.Fragment>;
            })}
          </Menu>
        </AdminMenuAnimator>
      );
    }, [menu, visible, defaultSelectedKeys, defaultOpenKeys, abilities]);

    return !loaded ? <></> : rendered;
  },
  (prev, next) =>
    JSON.stringify(prev.abilities) === JSON.stringify(next.abilities) &&
    prev.visible === next.visible
);
