import { lazy, memo, useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes, useParams } from "react-router-dom";
import "@Utils/i18n";
import { AuthorizedRoute } from "@Containers";
import { useAuth } from "@Hooks";
import { actionSetGlobalSearch, useAppDispatch } from "@Store";
import { AlertError, InfoModal } from "@Utils";
import { useTranslation } from "react-i18next";
import {
  fetchDownloadMailMessageAttachment,
  fetchGlobalData,
  fetchMailMessageAttachmentBlobStatic,
} from "@Api";
import { CacheGlobalSubjectEnum, toPlural } from "atlas-shared";
import { Suspended } from "./suspended";
import DashboardPage from "@Pages/agent/dashboard.page";
import { StableNavigateContextProvider } from "./context/StableNavigateContext";
import { AgentprofileAgentsListView } from "@Components/page-views/agentprofile/agentprofile.agents.list";
import { AdminprofileUsersListView } from "@Components/page-views/adminprofile/adminprofile.users.list";
import { QueueAgentsListView } from "@Components/page-views/queue/queue.agents.list";
import StatsReportsPage from "@Pages/stats/stats_reports.page";
import StatsReportPage from "@Pages/stats/stats_report.page";
import InvoicePdf from "@Pages/admin/invoice/pdf";
import { PasswordResetForm } from "@Components/user/user-settings";
import { FeatureFlagProvider } from "./providers/FeatureFlagProvider";

const PhonenumberStockFormBulkAddPage = lazy(
  () => import("./pages/admin/phonenumber_stock/bulk.add")
);
const LoginPage = lazy(() => import("./pages/auth/login"));
const WallboardPage = lazy(() => import("./pages/wallboard/wallboard.page"));
const ResetPasswordPage = lazy(() => import("./pages/auth/reset-password"));
const RequestResetPasswordPage = lazy(() => import("./pages/auth/request-reset-password"));
const FormTest = lazy(() => import("./pages/admin/test"));
const NotFoundPage = lazy(() => import("@Pages/not.found/not.found"));
const SearchPage = lazy(() => import("@Pages/agent/search.page"));
const CreateConversation = lazy(() => import("./pages/agent/create.conversation"));
const AuditPage = lazy(() => import("./pages/admin/audit/page"));

const EditPasswordPage = () => {
  const params = useParams();

  return <PasswordResetForm user_id={Number(params.user_id)} />;
};

const App = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const query = new URLSearchParams(window.location.search);
  const redirectTo = query.get("redirect_to");

  useEffect(() => {
    dispatch(actionSetGlobalSearch(query.get("keyword") || ""));

    // server redirect errors
    const e = query.get("e");

    if (e) AlertError(t, { content: t(`${e}_CONTENT`, ""), title: t(e) });

    const error = query.get("error");

    if (error) AlertError(t, { content: error, title: t("AN_ERROR_OCCURRED") });

    const info = query.get("info");

    if (info) InfoModal(t, { content: t(info) });

    // const input = e || error || info;
    //
    // if (input && ['MAIL_ACCOUNT_AUTHENTICATED', 'MAIL_ACCOUNT_AUTHENTICATION_FAILED'].includes(input))
    //   navigate('/admin/mail_accounts');

    const d = query.get("d");

    if (d)
      fetchGlobalData<{ access_token: string; refresh_token: string }>(d).then(
        (res) => {
          if (
            res?.subject === CacheGlobalSubjectEnum.OAUTH_RESPONSE &&
            res?.payload?.access_token
          ) {
            localStorage.setItem("atlas-auth", JSON.stringify(res.payload));
            window.location.pathname = "/";
          }
        },
        (rejected) => {}
      );

    global.fetchDownloadMailMessageAttachment = fetchDownloadMailMessageAttachment;
    global.fetchMailMessageAttachmentBlobStatic = fetchMailMessageAttachmentBlobStatic;
  }, [dispatch, t]);

  return (
    <>
      <FeatureFlagProvider auth={auth}>
        <Router>
          <StableNavigateContextProvider>
            <Routes>
              {!auth ? (
                <Route
                  path="/login"
                  element={
                    <Suspended>
                      <LoginPage redirectTo={redirectTo ?? undefined} />
                    </Suspended>
                  }
                />
              ) : (
                <></>
              )}
              <Route
                path="/wallboard"
                element={
                  <Suspended>
                    <WallboardPage />
                  </Suspended>
                }
              />
              {!auth ? (
                <Route
                  path="/request-reset-password"
                  element={
                    <Suspended>
                      <RequestResetPasswordPage />
                    </Suspended>
                  }
                />
              ) : (
                <></>
              )}
              {!auth ? (
                <Route
                  path="/reset-password/:username/:token"
                  element={
                    <Suspended>
                      <ResetPasswordPage />
                    </Suspended>
                  }
                />
              ) : (
                <></>
              )}
              <Route element={<AuthorizedRoute />}>
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route
                  path="/dashboard"
                  element={
                    <Suspended>
                      <DashboardPage />
                    </Suspended>
                  }
                />
                <Route
                  path="/dashboard/conversation/:organization_id/:id"
                  element={
                    <Suspended>
                      <DashboardPage />
                    </Suspended>
                  }
                />
                <Route
                  path="/dashboard/conversation/:organization_id/:id/event/:contact_custom_event_id"
                  element={
                    <Suspended>
                      <DashboardPage />
                    </Suspended>
                  }
                />
                <Route
                  path="/dashboard/conversation/:organization_id/:id/conversation/:secondary_conversation_id"
                  element={
                    <Suspended>
                      <DashboardPage />
                    </Suspended>
                  }
                />
                <Route
                  path="/dashboard/search"
                  element={
                    <Suspended>
                      <SearchPage />
                    </Suspended>
                  }
                />
                <Route
                  path="/dashboard/search/:organization_id/:id"
                  element={
                    <Suspended>
                      <SearchPage />
                    </Suspended>
                  }
                />
                <Route
                  path="/stats"
                  element={
                    <Suspended>
                      <StatsReportsPage />
                    </Suspended>
                  }
                />
                <Route
                  path="/stats/:report_id/:type"
                  element={
                    <Suspended>
                      <StatsReportPage />
                    </Suspended>
                  }
                />
                <Route
                  path="/stats/:report_id/:type"
                  element={
                    <Suspended>
                      <StatsReportPage />
                    </Suspended>
                  }
                />
                <Route
                  path="/create/conversation"
                  element={
                    <Suspended>
                      <CreateConversation />
                    </Suspended>
                  }
                />
                <Route
                  path="/test"
                  element={
                    <Suspended>
                      <FormTest />
                    </Suspended>
                  }
                />
                <Route
                  path="/admin/agentprofile/:id/agents"
                  element={
                    <Suspended>
                      <AgentprofileAgentsListView />
                    </Suspended>
                  }
                />
                <Route
                  path="/admin/adminprofile/:id/users"
                  element={
                    <Suspended>
                      <AdminprofileUsersListView />
                    </Suspended>
                  }
                />
                <Route
                  path="/admin/queue/:id/agents"
                  element={
                    <Suspended>
                      <QueueAgentsListView />
                    </Suspended>
                  }
                />
                <Route
                  path="/admin/invoice/:id"
                  element={
                    <Suspended>
                      <InvoicePdf />
                    </Suspended>
                  }
                />
                <Route
                  path="/admin/user/:user_id/edit/password"
                  element={
                    <Suspended>
                      <EditPasswordPage></EditPasswordPage>
                    </Suspended>
                  }
                />
                <Route
                  path="/admin/phonenumber_stock/bulk/add"
                  element={
                    <Suspended>
                      <PhonenumberStockFormBulkAddPage />
                    </Suspended>
                  }
                />
                <Route
                  path="/admin/audit/page"
                  element={
                    <Suspended>
                      <AuditPage />
                    </Suspended>
                  }
                />
                {[
                  "audit",
                  "team",
                  "organization",
                  "pause",
                  "list",
                  "list_file",
                  "queue",
                  "journey",
                  "journey_draft",
                  "agentprofile",
                  "user",
                  "adminprofile",
                  "superadmin_profile",
                  "mail_account",
                  "journey",
                  "field",
                  "form",
                  "disposition",
                  "tag",
                  "time_interval",
                  "operational_hours",
                  "sla_policy",
                  "canned_answer",
                  "signature",
                  "form_website",
                  "voice_asterisk",
                  "voice_asterisk_downgrade",
                  "voice_asterisk_operational_hours",
                  "voice_asterisk_toggle_history",
                  "voice_trunk",
                  "voice_route",
                  "sound",
                  "journey_variable",
                  "voice_voicemail",
                  "stats_report",
                  "stats_widget",
                  "voice_voicemail",
                  "voice_shortcut",
                  "stats_custom_metric",
                  "tariff",
                  "tariff_parent",
                  "phonenumber_monthly_cost",
                  "phonenumber_monthly_cost_parent",
                  "phonenumber_setup_cost",
                  "phonenumber_setup_cost_parent",
                  "invoice",
                  "invoice_post",
                  "prepaid_credit",
                  "destination_restrictor",
                  "phonenumber_prefix",
                  "phonenumber_stock",
                  "phonenumber_purchase",
                  "release",
                  "api_key",
                  "client",
                  "skill",
                  "wallboard",
                  "voice_recording_transcript_setting",
                  "points_system",
                  "journey_trigger",
                  "gdpr",
                  "stats_alarm",
                  "ai_assistant",
                  "text_message_source",
                ].map((entity) => {
                  const ListComponent = lazy(() => import(`./pages/admin/${entity}/list`));
                  const SearchComponent = lazy(() => import(`./pages/admin/${entity}/search`));
                  const AddComponent = lazy(() => import(`./pages/admin/${entity}/add`));
                  const EditComponent = lazy(() => import(`./pages/admin/${entity}/edit`));

                  return [
                    entity !== "invoice" && (
                      <Route
                        path={`/admin/${entity}/add`}
                        element={
                          <Suspended>
                            <AddComponent />
                          </Suspended>
                        }
                      />
                    ),
                    <Route
                      path={`/admin/${entity}/:id/edit`}
                      element={
                        <Suspended>
                          <EditComponent />
                        </Suspended>
                      }
                    />,
                    <Route
                      path={`/admin/${toPlural(entity)}`}
                      element={
                        <Suspended>
                          <ListComponent />
                        </Suspended>
                      }
                    />,
                    <Route
                      path={`/admin/${toPlural(entity)}/search`}
                      element={
                        <Suspended>
                          <SearchComponent />
                        </Suspended>
                      }
                    />,
                  ];
                })}
                <Route
                  path="*"
                  element={
                    auth ? (
                      <Suspended>
                        <NotFoundPage />
                      </Suspended>
                    ) : (
                      <Navigate to="/login" replace />
                    )
                  }
                />
              </Route>
            </Routes>
          </StableNavigateContextProvider>
        </Router>
      </FeatureFlagProvider>
    </>
  );
};

export default memo(App);
