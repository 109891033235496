import { Button, Form, Select, Typography, TreeSelect, message, Row, Col, Card } from "antd";
import { t } from "i18next";
import { useAuth, useCannedAnswers } from "@Hooks";
import { ITextMessage, ISharedCreateConversation } from "atlas-shared";
import { ContactSearchSelect } from "./ContactSearchSelect";
import { useFormOptions } from "../hooks";
import { useTextMessageSources } from "../hooks/useTextMessageSources";
import { countTotalOrganizations } from "../utils/organization";
import { useMemo, useState, useEffect } from "react";
import { sendTextMessage } from "@Api/textMessageApi";
import { SendIcon, ArrowRightIcon } from "@Assets";
import style from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { EditorFroala } from "@Components/editor-froala";

type FormValues = Omit<ITextMessage, "source"> & {
  organization_id?: number;
  queue_id?: string;
  source_id?: string;
  contact_id?: string | number;
};

export const CreateTextMessageConversation = () => {
  const { Title } = Typography;
  const auth = useAuth();
  const navigate = useNavigate();
  const { canned_answers } = useCannedAnswers();
  const [form] = Form.useForm<FormValues>();
  const [messageLength, setMessageLength] = useState(0);
  const MAX_MESSAGE_LENGTH = 320;
  const MIN_MESSAGE_LENGTH = 1;

  const { options: sourceSelectOptions, loading: sourcesLoading } = useTextMessageSources();

  const { organizationOptions, queueOptions } = useFormOptions({
    values: {
      organization_id: auth.user.__organizations[0]?.organization_id,
    } as Partial<ISharedCreateConversation>,
  });

  const initialQueue = queueOptions.length > 0 ? queueOptions[0] : undefined;
  const initialSource = sourceSelectOptions.length > 0 ? sourceSelectOptions[0] : undefined;

  const initialValues = useMemo(
    () => ({
      organization_id: auth.user.__organizations[0]?.organization_id,
      queue_id: initialQueue?.id?.toString(),
      source_id: initialSource?.value?.toString(),
    }),
    [auth.user.__organizations, initialQueue?.id, initialSource?.value]
  );

  const handleOrganizationChange = (value: string) => {
    const newOrgId = Number(value);
    form.setFieldsValue({
      organization_id: newOrgId,
      queue_id: undefined,
      source_id: undefined,
    });
  };

  const validateFormValues = (formValues: FormValues) => {
    if (!formValues.queue_id) {
      message.error(t("MISSING_QUEUE"));
      return false;
    }
    if (!formValues.source_id) {
      message.error(t("MISSING_SOURCE"));
      return false;
    }
    if (!formValues.number) {
      message.error(t("MISSING_PHONE_NUMBER"));
      return false;
    }
    if (!formValues.text || formValues.text.length < MIN_MESSAGE_LENGTH) {
      message.error(t("MESSAGE_TOO_SHORT"));
      return false;
    }

    return true;
  };

  // Remove html from text message
  const handleEditorChange = (value: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = value;
    const plainText = tempDiv.textContent || tempDiv.innerText || "";
    const trimmedText = plainText.trim();

    setMessageLength(trimmedText.length);
    form.setFieldValue("text", trimmedText);
  };

  const handleFinish = async () => {
    const formValues = form.getFieldsValue() as FormValues;

    if (!validateFormValues(formValues)) {
      return;
    }

    if (messageLength > MAX_MESSAGE_LENGTH) {
      message.error(t("MESSAGE_TOO_LONG"));
      return;
    }

    try {
      const payload = {
        number: formValues.number,
        text: formValues.text,
        queue: { id: Number(formValues.queue_id) },
      };

      const textMessage = await sendTextMessage(String(formValues.source_id), payload);
      const conversation = textMessage.data.conversation;

      message.success(t("MESSAGE_SENT_SUCCESSFULLY"));
      form.resetFields();
      setMessageLength(0);
      navigate(`/dashboard/conversation/${conversation?.organization_id}/${conversation?.id}`);
    } catch (error) {
      message.error(t("ERROR_SENDING_MESSAGE"));
      console.error("Error sending message:", error);
    }
  };

  const queueSelectOptions = useMemo(() => {
    return queueOptions.map((queue) => ({
      value: queue.id.toString(),
      label: queue.title,
    }));
  }, [queueOptions]);

  useEffect(() => {
    if (initialValues.organization_id || initialValues.queue_id || initialValues.source_id) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  return (
    <div className={"create-conversation-form " + style.contacts}>
      <Form form={form} onFinish={handleFinish} layout="horizontal">
        <Form.Item name="number" required hidden />
        <Card
          style={{ padding: "1.2em", backgroundColor: "transparent" }}
          headStyle={{ backgroundColor: "transparent" }}
          extra={
            <Row align={"middle"} gutter={6}>
              <Col>
                {countTotalOrganizations(organizationOptions) > 1 && (
                  <Form.Item name="organization_id" required>
                    <TreeSelect
                      className="organization"
                      treeData={organizationOptions}
                      treeDefaultExpandAll
                      placeholder={t("SELECT_ORGANIZATION")}
                      onChange={handleOrganizationChange}
                      fieldNames={{ label: "title", value: "key", children: "children" }}
                      showSearch
                      treeNodeFilterProp="title"
                      style={{ minWidth: "20em" }}
                      size="small"
                    />
                  </Form.Item>
                )}
              </Col>
              {form.getFieldValue("organization_id") && (
                <>
                  <Col>
                    <Form.Item name="queue_id" required>
                      <Select
                        options={queueSelectOptions}
                        placeholder={t("SELECT_QUEUE")}
                        style={{ minWidth: "10em" }}
                        size="small"
                        optionFilterProp="label"
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          }
          title={<Title level={1}>{t("CREATE_CONVERSATION")}</Title>}
          bordered={false}
        >
          <div>
            <Row gutter={24} style={{ marginBottom: "0px" }} justify="center" align="middle">
              <Col>
                <Form.Item name="source_id" required>
                  <Select
                    options={sourceSelectOptions}
                    placeholder={t("SELECT_SOURCE")}
                    loading={sourcesLoading}
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col>
                <ArrowRightIcon />
              </Col>
              <Col flex={1}>
                <div style={{ position: "relative" }}>
                  <ContactSearchSelect
                    valueKey="contact_number"
                    organizationId={form.getFieldValue("organization_id")}
                    agentprofileId={auth.user.__agentprofiles[0]}
                    required={true}
                    selectedQueue={
                      queueOptions.find(
                        (queue) => queue.id.toString() === form.getFieldValue("queue_id")
                      ) || initialQueue
                    }
                    onContactSelect={(contact_id, contact_email, contact_number) => {
                      form.setFieldsValue({
                        number: contact_number ?? "",
                        contact_id: contact_number ?? "",
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>
              <Col flex={1}>
                <Form.Item name="text" required>
                  <EditorFroala
                    disableRichText={true}
                    maxLength={MAX_MESSAGE_LENGTH}
                    placeholder={t("MESSAGE")}
                    onChangeTrigger={handleEditorChange}
                    canned_answers={canned_answers}
                    editorToggle={null}
                  />
                </Form.Item>
                {messageLength > MAX_MESSAGE_LENGTH && (
                  <div style={{ color: "red" }}>Max {MAX_MESSAGE_LENGTH}</div>
                )}
              </Col>
            </Row>
          </div>

          <Row align={"bottom"} justify={"space-between"}>
            <Col>
              <Row gutter={12} align={"bottom"}>
                <Col>
                  <Button
                    onClick={handleFinish}
                    className="antd-default-styling"
                    type="primary"
                    icon={<SendIcon />}
                    disabled={messageLength > MAX_MESSAGE_LENGTH}
                    style={{ gap: 8 }}
                  >
                    {t("SEND")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};
