import { useState } from "react";
import { ISharedCreateContact, IPreviewQueue } from "atlas-shared";
import { createContactByList } from "@Api";

interface UseContactCreationProps {
  organization_id?: number;
  selectedQueue?: IPreviewQueue;
}

export const useContactCreation = ({ organization_id, selectedQueue }: UseContactCreationProps) => {
  const [showAddContact, setShowAddContact] = useState(false);
  const [newContact, setNewContact] = useState<Partial<ISharedCreateContact>>({});

  const createNewContact = async (isValid: boolean, listId: number) => {
    if (!organization_id || !isValid || !listId) return;

    const phones = newContact?.phones?.[0] === "" ? [] : newContact.phones;

    try {
      const contactData = {
        organization_id,
        __contact_emails: newContact.emails || [],
        __contact_phones: phones || [],
        __contact_first_name: newContact.first_name || null,
        __contact_last_name: newContact.last_name || null,
        __lists: [listId],
      };

      setShowAddContact(false);
      setNewContact({});
      const contact = await createContactByList(listId, contactData);
      return contact;
    } catch (error) {
      console.error("Error creating contact:", error);
      return undefined;
    }
  };

  return {
    showAddContact,
    setShowAddContact,
    newContact,
    setNewContact,
    createNewContact,
  };
};
