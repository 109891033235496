import React, { useEffect, useMemo, useState, } from 'react';
import { Input, } from 'antd';
import './header.user.presence.list.scss';
import { SearchIcon, SmartphoneIcon, } from '@Assets/icons';
import { getFullName, isSuperAdmin, pickVoiceCall, TranslateAndDisplayPhoneNumber, VoiceCallTitle, } from '@Utils';
import { IPreviewTeam, IPreviewUser, hasVoiceLicense, DirectionEnum, } from 'atlas-shared';
import { useTranslation, } from 'react-i18next';
import { useAuth, useTeams, useUsers, useUserStatuses, useVoiceAsterisks, } from '@Hooks';
import { unsubscribeEntity, } from '@Api/global.api';
import { actionFetchUserStatuses, IGlobalCallInterface, IGlobalCallSpyInterface, useAppDispatch, } from '@Store';
import { Icon, Avatar, StatusColor, } from '@Components';

export const HeaderUserPresenceList = React.memo(() => {

  const { t, } = useTranslation();
  const teams = useTeams();
  const users = useUsers();
  const userStatuses = useUserStatuses();
  const [search, setSearch, ] = useState<string>('');
  const allTeams = { id: 0, title: t('ALL'), } as IPreviewTeam;
  const [selectedTeam, setSelectedTeam, ] = useState<IPreviewTeam>(allTeams);
  const [filteredUsers, setFilteredUsers, ] = useState<Array<IPreviewUser>>([]);
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const voice_asterisks = useVoiceAsterisks();
  const voiceLicense = useMemo(() => hasVoiceLicense(auth.user), [auth.user, ]);

  useEffect(() => {
    setFilteredUsers(users.users.filter(user => !user.deleted_at
      && user.id !== auth.user.id
      && !isSuperAdmin(user)
      && (!selectedTeam.id || selectedTeam.__users.includes(user.id))
      && (!search || (user.first_name || '').toLowerCase().includes(search) || (user.last_name || '').toLowerCase().includes(search))
    ));
  }, [users.users, search, selectedTeam, auth.user.id, ]);

  useEffect(() => {

    if (userStatuses.loaded)
      dispatch(actionFetchUserStatuses());

    return () => {
      unsubscribeEntity('user_status', 'main');
    };
  }, [dispatch, userStatuses.loaded, ]);

  if (!userStatuses.loaded)
    return <></>;

  return <div className={'user-presence'} >
    <div className={'header'} >
      <ul className={'users-types-wrp'} >
        {[
          allTeams,
          ...teams.teams,
        ].filter(team => !team.deleted_at).map(team => <li key={team.id} onClick={() => setSelectedTeam(team)} className={team.id === selectedTeam.id ? 'active' : ''} >
          {team.title}
        </li>)}
      </ul>
    </div>
    <div className='search'>
      <Input placeholder={`${t('SEARCH_AGENTS')}...`} onChange={e => setSearch((e.target.value || '').toLowerCase())} />
      <Icon icon={SearchIcon} />
    </div>
    <div className={'users'} >
      {filteredUsers.map(user => {
        const user_status = userStatuses.dict[user.id];
        const overlapping_voice_asterisks = user_status.__current_voice_asterisks.filter(voice_asterisk_item => auth.user_status.__current_voice_asterisks.some(_voice_asterisk_item => _voice_asterisk_item.vaid === voice_asterisk_item.vaid));

        if (!user_status?.status && !user.voice_settings.twinning?.on)
          return <React.Fragment key={user.id}></React.Fragment>;

        return <div className={'user'} key={user.id}>
          <div className={'col user'} >
            <Avatar user={user} size={28} />
            <div className={'name'} >
              {getFullName(user)}
            </div>
          </div>
          <div className={'col status'}>
            <StatusColor status={user_status.status} pause_id={user_status.pause_id}/>
            {!user_status?.status && user.voice_settings.twinning?.on && user.voice_settings.twinning?.phone && <Icon className='twin-icon' tooltip={{ title: <div>{t('TWINNED_TO')}: <TranslateAndDisplayPhoneNumber t={t} phone_number={user.voice_settings.twinning.phone} /></div>, }} icon={SmartphoneIcon} />}
          </div>
          <div className={'col buttons'}>
            {!!user_status.status && auth.user.main && voiceLicense && hasVoiceLicense(user) && user_status.__current_voice_asterisks.length > 0 && <button onClick={() => {

              const calls = overlapping_voice_asterisks.map(overlapping_voice_asterisk => {
                return {
                  direction: DirectionEnum.Internal,
                  title: <VoiceCallTitle title={getFullName(user)} voice_asterisk_id={overlapping_voice_asterisk.vaid} />,
                  voice_asterisk_id: overlapping_voice_asterisk.vaid,
                  numbers: [`u-${user.id}-${overlapping_voice_asterisk.apid || 0}-q-00`, ],
                  requires_route: false,
                  callee_user_id: user_status.user_id,
                  callee_agentprofile_id: overlapping_voice_asterisk.apid || 0,
                  agentprofile_id: overlapping_voice_asterisk.apid || 0,
                };
              });

              pickVoiceCall(dispatch, calls);

            }}>
              {t('CALL')}
            </button>}
            {!!user_status.status && user_status.__current_voice_asterisks.length > 0 && user_status.agentprofile_id && auth.user.voice_settings.chanspy && user.voice_settings.chanspied_on && <button onClick={() => {

              const calls = overlapping_voice_asterisks.map(overlapping_voice_asterisk => {
                const spy_base: IGlobalCallInterface = {
                  organization_id: user.__organizations[0].organization_id,
                  direction: DirectionEnum.Internal,
                  voice_asterisk_id: overlapping_voice_asterisk.vaid,
                  numbers: ['spy', ],
                  requires_route: false,
                  headers: [
                    `X-SPY-ON: ${user.id}-${user_status.agentprofile_id}`,
                  ],
                  spy: {
                    user_id: auth.user.id,
                    spy_agentprofile_id: auth.user_status.agentprofile_id,
                    spyee_user_id: user.id,
                    spyee_agentprofile_id: user_status.agentprofile_id,
                    flags: 'qb',
                  },
                };

                return [
                  {
                    ...spy_base,
                    title: <VoiceCallTitle title={getFullName(user)} voice_asterisk_id={overlapping_voice_asterisk.vaid} postFix={t('LISTEN_ONLY')} />,
                    headers: [
                      ...(spy_base.headers || []),
                      'X-FLAGS: qb',
                    ],
                  },
                  {
                    ...spy_base,
                    title: <VoiceCallTitle title={getFullName(user)} voice_asterisk_id={overlapping_voice_asterisk.vaid} postFix={t('LISTEN_AND_TALK_TO_AGENT')} />,
                    headers: [
                      ...(spy_base.headers || []),
                      'X-FLAGS: qbw',
                    ],
                    spy: {
                      ...(spy_base.spy as IGlobalCallSpyInterface),
                      flags: 'qbw',
                    },
                  },
                ];
              }).flat();

              pickVoiceCall(dispatch, calls);

            }}>
              {t('SPY')}
            </button>}
            { /*
                  <button>
                    <StarIcon size={11} style={{ color:'var(--COLOR-000533)' }} />
                  </button>
                  */}
          </div>
        </div>;
      })}

    </div>
  </div>;
});
