"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionVoiceGetDigits = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
exports.JourneyActionVoiceGetDigits = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.VoiceGetDigits,
    channels: [enums_1.ChannelEnum.Voice,],
    direction: enums_1.JourneyNodeDirectionEnum.Output,
    settings_schema: (0, base_1.settingsValidationSchema)({
        asterisk_sound_id: {
            type: 'number',
            required: true,
        },
        timeout: {
            type: 'number',
            required: true,
            default: 30000,
        },
        max_digits: {
            type: 'number',
            required: true,
        },
        retries: {
            type: 'number',
            required: true,
            default: 1,
        },
        journey_variable_id: {
            type: 'number',
            required: true,
        },
    }),
    initialValues: {
        timeout: 30000,
        retries: 1,
    },
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_VOICE_GET_DIGITS_SUCCESS_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_VOICE_GET_DIGITS_SUCCESS_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: true,
                index: 0,
                title: 'GOT_DIGITS',
                color: '#5bbf1c',
            }), [constants_1.JOURNEY_ACTION_VOICE_GET_DIGITS_FAIL_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_VOICE_GET_DIGITS_FAIL_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: true,
                index: 0,
                title: 'FAILED',
                color: '#d91010',
            }) });
    },
});
