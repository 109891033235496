import { createContext } from "react";

export type FlagRecord = Record<string, boolean>;

export type TFeatureFlagContext = {
  flags: FlagRecord | undefined;
  setFlags: (flags: FlagRecord) => void;
};

export const FeatureFlagContext = createContext<TFeatureFlagContext | null>(null);
