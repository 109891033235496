"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionSetField = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const misc_1 = require("../../misc");
exports.JourneyActionSetField = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.SetField,
    channels: (0, misc_1.EnumValues)(enums_1.ChannelEnum),
    direction: enums_1.JourneyNodeDirectionEnum.Output,
    settings_schema: (0, base_1.settingsValidationSchema)({
        field_id: {
            type: 'number',
            required: true,
        },
        data_type: {
            type: 'string',
            valid: ['variable', 'static',],
            required: true,
        },
        journey_variable_id: {
            type: 'number',
            optional: true,
            when: [
                {
                    reference: { '$ref': 'data_type', },
                    is: {
                        type: 'string',
                        required: true,
                        valid: [
                            'variable',
                        ],
                    },
                    then: {
                        type: 'any',
                        required: true,
                    },
                    otherwise: {
                        type: 'any',
                        forbidden: true,
                    },
                },
            ],
        },
        value: {
            type: 'string',
            optional: true,
            when: [
                {
                    reference: { '$ref': 'data_type', },
                    is: {
                        type: 'string',
                        required: true,
                        valid: [
                            'static',
                        ],
                    },
                    then: {
                        type: 'any',
                        required: true,
                    },
                    otherwise: {
                        type: 'any',
                        forbidden: true,
                    },
                },
            ],
        },
    }),
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_VOICE_VARIABLE_SET_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_VOICE_VARIABLE_SET_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: false,
                index: 0,
                title: 'DONE',
                color: null,
            }) });
    },
});
