import React, { useEffect, useMemo } from "react";

import {
  IStatsReportChildProps,
  IWithStatsReportProps,
  withStatsReport,
} from "@Hocs/with.stats_report";
import { StatsWidgetView } from "@Components/stats/widget-types/stats.widget.view";
import { useTranslation } from "react-i18next";
import "./stats_report.layout.scss";
import "./stats_report.report.layout.scss";
import { useIsPrint, useWindowSize } from "@Hooks";
import { uniq } from "@Utils";
import { patchStatsReportWidget } from "@Api";
import { HeaderLogo } from "@Components/header/header.logo/header.logo";

export const StatsReportsReport = ({
  report_id,
  widgets,
  report,
  form,
  addBtn,
  widgetForm,
  setWidgetForm,
  auth,
  screenshotBtn,
  screenshotWidgetBtn,
  setWidgetClone,
}: IStatsReportChildProps & IWithStatsReportProps) => {
  const { t } = useTranslation();
  const isPrint = useIsPrint();

  const reportPngBtn = useMemo(() => screenshotBtn("png"), []);
  const reportPdfBtn = useMemo(() => screenshotBtn("pdf"), []);
  const [windowWidth, windowHeight] = useWindowSize();

  useEffect(() => {
    if (report.user_id !== auth.user.id) return;

    const unique_orders = uniq(widgets.map(({ settings }) => settings.position));

    if (unique_orders.length !== widgets.length) {
      const sorted = [...widgets].sort(
        (a, b) => (a.settings.position || 0) - (b.settings.position || 0)
      );

      sorted.map((widget, index) => {
        const new_position = index + 1;

        if (widget.settings.position != new_position)
          patchStatsReportWidget(widget.id, {
            settings: {
              ...widget.settings,
              position: index + 1,
            },
          });
      });
    }
  }, []);

  return (
    <div className={"stats-report stats-report-report"}>
      <div className="a4">
        {!isPrint && (
          <div className="header">
            <HeaderLogo auth={auth} t={t} hideVersion={true} />
            <h1>
              <span>{t("REPORT")}</span> {report.title}
            </h1>
            <div className="buttons">
              {addBtn}
              {reportPngBtn}
              {reportPdfBtn}
            </div>
          </div>
        )}
        <div className="widgets">
          {widgets.map((widget, index) => (
            <div className="widget" key={widget.id}>
              <StatsWidgetView
                t={t}
                auth={auth}
                report={report}
                is_preview={false}
                widgets={widgets}
                stats_report_widget={widget}
                setWidgetForm={setWidgetForm}
                setWidgetClone={setWidgetClone}
                index={index}
                width={Math.min(windowWidth, 1580) - 70}
                screenshotWidgetBtn={screenshotWidgetBtn}
                is_print={isPrint}
                is_first={index === 0}
                is_last={index === widgets.length - 1}
              />
            </div>
          ))}
        </div>
      </div>
      {form}
    </div>
  );
};

export const StatsReportsReportLayout = React.memo(withStatsReport(StatsReportsReport));
