"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyHandlerMicroservicePatterns = void 0;
var JourneyHandlerMicroservicePatterns;
(function (JourneyHandlerMicroservicePatterns) {
    JourneyHandlerMicroservicePatterns["CallConversationInitiated"] = "CallConversationInitiated";
    JourneyHandlerMicroservicePatterns["CallConversationCreated"] = "CallConversationCreated";
    JourneyHandlerMicroservicePatterns["CallActionReceived"] = "CallActionReceived";
    JourneyHandlerMicroservicePatterns["UnknownCallActionReceived"] = "UnknownCallActionReceived";
    JourneyHandlerMicroservicePatterns["CallEventReceived"] = "CallEventReceived";
    JourneyHandlerMicroservicePatterns["MailConversationCreated"] = "MailConversationCreated";
    JourneyHandlerMicroservicePatterns["FormConversationCreated"] = "FormConversationCreated";
    JourneyHandlerMicroservicePatterns["ExecuteJourneyAction"] = "ExecuteJourneyAction";
    JourneyHandlerMicroservicePatterns["ExecuteWhenSince"] = "ExecuteWhenSince";
    JourneyHandlerMicroservicePatterns["DraftInsert"] = "DraftInsert";
    JourneyHandlerMicroservicePatterns["InternalNoteInsert"] = "InternalNoteInsert";
    JourneyHandlerMicroservicePatterns["ConversationUpdate"] = "ConversationUpdate";
    JourneyHandlerMicroservicePatterns["ConversationCreate"] = "ConversationCreate";
    JourneyHandlerMicroservicePatterns["ContactUpdate"] = "ContactUpdate";
    JourneyHandlerMicroservicePatterns["ContactCreate"] = "ContactCreate";
    JourneyHandlerMicroservicePatterns["MailMessageInsert"] = "MailMessageInsert";
    JourneyHandlerMicroservicePatterns["AsteriskStarted"] = "AsteriskStarted";
    JourneyHandlerMicroservicePatterns["VoiceCallInsert"] = "VoiceCallInsert";
    JourneyHandlerMicroservicePatterns["VoiceCallAnswered"] = "VoiceCallAnswered";
    JourneyHandlerMicroservicePatterns["VoiceCallHangedUp"] = "VoiceCallHangedUp";
    JourneyHandlerMicroservicePatterns["VoiceCallBridgeInsert"] = "VoiceCallBridgeInsert";
    JourneyHandlerMicroservicePatterns["VoiceCallBridgeAnswered"] = "VoiceCallBridgeAnswered";
    JourneyHandlerMicroservicePatterns["VoiceCallBridgeLeave"] = "VoiceCallBridgeLeave";
    JourneyHandlerMicroservicePatterns["MovedVoiceRecordingToS3"] = "MovedVoiceRecordingToS3";
    JourneyHandlerMicroservicePatterns["MovedVoiceVoicemailToS3"] = "MovedVoiceVoicemailToS3";
    JourneyHandlerMicroservicePatterns["VoiceRecordingTranscript"] = "VoiceRecordingTranscript";
    JourneyHandlerMicroservicePatterns["AriInternalVoiceCallHangedUp"] = "AriInternalVoiceCallHangedUp";
    JourneyHandlerMicroservicePatterns["AriVoiceCallHangedUp"] = "AriVoiceCallHangedUp";
    JourneyHandlerMicroservicePatterns["AriVoiceCallBridgeHangedUp"] = "AriVoiceCallBridgeHangedUp";
    JourneyHandlerMicroservicePatterns["AriVoiceCallBridgeAnswered"] = "AriVoiceCallBridgeAnswered";
    JourneyHandlerMicroservicePatterns["AriInternalVoiceCallAnswered"] = "AriInternalVoiceCallAnswered";
})(JourneyHandlerMicroservicePatterns = exports.JourneyHandlerMicroservicePatterns || (exports.JourneyHandlerMicroservicePatterns = {}));
