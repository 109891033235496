"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockPreviewUserTwo = exports.mockPreviewUserOne = exports.mockSharedUserOne = void 0;
const enums_1 = require("../../utilities/enums");
const date_1 = require("../utils/date");
const utils_1 = require("../utils");
const agentprofile_1 = require("./agentprofile");
const organization_1 = require("./organization");
const base = Object.assign({ id: 10000, username: 'user.one', login_strategy: enums_1.LoginStrategyEnum.Local, first_name: 'Agent', last_name: 'One', active: true, email: 'agent@one.com', two_factor_auth: false, avatar: null, md5_secret: null, mail_capacity: 5, has2fa: false, settings: {
        auto_accept: null,
        stats_reports: [],
    }, form_capacity: 4, voice_license: true, voice_settings: {
        chanspy: false,
        chanspied_on: false,
        twinning: {
            on: true,
            phone: '',
            queue_on: true,
            direct_on: true,
            display_queue_caller_id: false,
        },
    }, login_pause: null, dashboard_license: false, reporting_license: false, __agentprofiles: [(0, agentprofile_1.mockPreviewAgentprofileOne)().id,], __queues: [], __teams: [], __organizations: [{ organization_id: (0, organization_1.mockChildOrganization)().id, adminprofile_id: null, superadmin_profile_id: null, role: enums_1.RoleEnum.Agent, organization_ids: [(0, organization_1.mockChildOrganization)().id, ...(0, organization_1.mockChildOrganization)().children,], },], __agent_organization_ids: [(0, organization_1.mockChildOrganization)().id,], __admin_organization_ids: [], notification_settings: {}, skills: { level_one: [], level_two: [], level_three: [], }, anonymized_at: null }, date_1.mockDateObject);
exports.mockSharedUserOne = (0, utils_1.mockFunction)(Object.assign({}, base));
exports.mockPreviewUserOne = (0, utils_1.mockFunction)(Object.assign({}, base));
exports.mockPreviewUserTwo = (0, utils_1.mockFunction)(Object.assign(Object.assign({}, base), { id: 10001, last_name: 'Two' }));
