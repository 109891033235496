"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminprofilePermissionIdEnum = void 0;
var AdminprofilePermissionIdEnum;
(function (AdminprofilePermissionIdEnum) {
    AdminprofilePermissionIdEnum["User"] = "User";
    AdminprofilePermissionIdEnum["Team"] = "Team";
    AdminprofilePermissionIdEnum["Organization"] = "Organization";
    AdminprofilePermissionIdEnum["MailAccount"] = "MailAccount";
    AdminprofilePermissionIdEnum["TextMessage"] = "TextMessage";
    AdminprofilePermissionIdEnum["Queue"] = "Queue";
    AdminprofilePermissionIdEnum["List"] = "List";
    AdminprofilePermissionIdEnum["Adminprofile"] = "Adminprofile";
    AdminprofilePermissionIdEnum["Agentprofile"] = "Agentprofile";
    AdminprofilePermissionIdEnum["SlaPolicy"] = "SlaPolicy";
    AdminprofilePermissionIdEnum["Disposition"] = "Disposition";
    AdminprofilePermissionIdEnum["Form"] = "Form";
    AdminprofilePermissionIdEnum["Field"] = "Field";
    AdminprofilePermissionIdEnum["FormWebsite"] = "FormWebsite";
    AdminprofilePermissionIdEnum["Journey"] = "Journey";
    AdminprofilePermissionIdEnum["OperationalHours"] = "OperationalHours";
    AdminprofilePermissionIdEnum["Pause"] = "Pause";
    AdminprofilePermissionIdEnum["CannedAnswer"] = "CannedAnswer";
    AdminprofilePermissionIdEnum["Signature"] = "Signature";
    AdminprofilePermissionIdEnum["Tag"] = "Tag";
    AdminprofilePermissionIdEnum["TimeInterval"] = "TimeInterval";
    AdminprofilePermissionIdEnum["VoiceRoute"] = "VoiceRoute";
    AdminprofilePermissionIdEnum["VoiceRecording"] = "VoiceRecording";
    AdminprofilePermissionIdEnum["VoiceVoicemailMessage"] = "VoiceVoicemailMessage";
    AdminprofilePermissionIdEnum["VoiceShortcut"] = "VoiceShortcut";
    AdminprofilePermissionIdEnum["VoiceVoicemail"] = "VoiceVoicemail";
    AdminprofilePermissionIdEnum["Sound"] = "Sound";
    AdminprofilePermissionIdEnum["JourneyVariable"] = "JourneyVariable";
    AdminprofilePermissionIdEnum["StatsReport"] = "StatsReport";
    AdminprofilePermissionIdEnum["StatsWidget"] = "StatsWidget";
    AdminprofilePermissionIdEnum["DestinationRestrictor"] = "DestinationRestrictor";
    AdminprofilePermissionIdEnum["Invoice"] = "Invoice";
    AdminprofilePermissionIdEnum["PhonenumberPurchase"] = "PhonenumberPurchase";
    AdminprofilePermissionIdEnum["StatsCustomMetric"] = "StatsCustomMetric";
    AdminprofilePermissionIdEnum["ApiKey"] = "ApiKey";
    AdminprofilePermissionIdEnum["ContactCustomEvent"] = "ContactCustomEvent";
    AdminprofilePermissionIdEnum["Client"] = "Client";
    AdminprofilePermissionIdEnum["Contact"] = "Contact";
    AdminprofilePermissionIdEnum["Skill"] = "Skill";
    AdminprofilePermissionIdEnum["Wallboard"] = "Wallboard";
    AdminprofilePermissionIdEnum["VoiceRecordingTranscript"] = "VoiceRecordingTranscript";
    AdminprofilePermissionIdEnum["PointsSystem"] = "PointsSystem";
    AdminprofilePermissionIdEnum["Gdpr"] = "Gdpr";
    AdminprofilePermissionIdEnum["GdprAnonymize"] = "GdprAnonymize";
    AdminprofilePermissionIdEnum["VoiceRecordingTranscriptSetting"] = "VoiceRecordingTranscriptSetting";
    AdminprofilePermissionIdEnum["StatsAlarm"] = "StatsAlarm";
    AdminprofilePermissionIdEnum["VoiceCall"] = "VoiceCall";
})(AdminprofilePermissionIdEnum = exports.AdminprofilePermissionIdEnum || (exports.AdminprofilePermissionIdEnum = {}));
