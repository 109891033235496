import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  VoiceAsteriskAddForm,
  VoiceAsteriskAddInitialValues,
  VoiceAsteriskComponents,
  VoiceAsteriskAdditionalParams,
  VoiceAsteriskFormOptions,
  onVoiceAsteriskFormSaved,
  IVoiceAsteriskFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateVoiceAsterisk, PermissionEnum, } from 'atlas-shared';
import { createVoiceAsterisk, fetchVoiceAsterisk, getVoiceAsteriskCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useVoiceAsteriskDowngrades, useOrganizations, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateVoiceAsterisk> {}

const VoiceAsteriskFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const voice_asterisk_downgrades = useVoiceAsteriskDowngrades();
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateVoiceAsterisk>>();

  const param_props: IVoiceAsteriskFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    voice_asterisk_downgrades: voice_asterisk_downgrades.voice_asterisk_downgrades,
    organizations: organizations.organizations,
  };
  const formLayout = useMemo(() => VoiceAsteriskAddForm(values, param_props, t), [values, voice_asterisk_downgrades, organizations, t, abilities, ]);
  const options: IFormOptions = useMemo(() => VoiceAsteriskFormOptions(values || VoiceAsteriskAddInitialValues, param_props, t), [values, voice_asterisk_downgrades, organizations, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateVoiceAsterisk, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateVoiceAsterisk) => save(() => createVoiceAsterisk(values).then(
    res => onSaved ? onSaved(res, navigate) : onVoiceAsteriskFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchVoiceAsterisk(+id)
        .then(({
          title,
          host,
          internal_host,
          port,
          tls,
          from_domain,
          iso2,
          rest,
          voice_asterisk_downgrade_id,
          ari_based,
          description,
        }) => {
          setCloned({
            title,
            host,
            internal_host,
            port,
            tls,
            from_domain,
            iso2,
            rest,
            voice_asterisk_downgrade_id,
            ari_based,
            description,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!voice_asterisk_downgrades.loaded || !organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(null, CrudEnum.Insert, PermissionEnum.VoiceAsterisk, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={VoiceAsteriskComponents}
        additionalParams={VoiceAsteriskAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...VoiceAsteriskAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('VOICE_ASTERISK')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const VoiceAsteriskFormAddView = React.memo(withAdd(VoiceAsteriskFormAdd, getVoiceAsteriskCreateSchema, VoiceAsteriskAddInitialValues));
