import { getTextMessages } from "@Api/textMessageApi";
import { useAsync } from "@Hooks/abstract/useAsync";
import { useFeatureFlag } from "@Hooks/useFeatureFlag";
import { useEffect, useMemo, useState } from "react";

const defaultResult = {
  textMessages: [],
  error: null,
  loading: false,
  fetchTextMessages: () => {},
};

export const useConversationTextMessages = (conversation_id: number) => {
  const textMessageEnabled = useFeatureFlag("text.message", false);
  const [hasFetched, setHasFetched] = useState(false);

  const queryByConversationId = useMemo(() => {
    return encodeURIComponent(
      JSON.stringify({
        conversation: {
          id: { $eq: conversation_id },
        },
      })
    );
  }, [conversation_id]);

  const {
    data: textMessages,
    error,
    loading,
    refetch: fetchTextMessages,
    reset: resetTextMessages,
  } = useAsync(() => getTextMessages(queryByConversationId), {
    skip: !textMessageEnabled || !hasFetched,
  });

  useEffect(() => {
    if (!textMessageEnabled) return;

    resetTextMessages?.();
    setHasFetched(true);
  }, [conversation_id, textMessageEnabled]);

  if (!textMessageEnabled) return defaultResult;

  return {
    textMessages: error ? [] : textMessages || [],
    error,
    loading,
    fetchTextMessages: fetchTextMessages || (() => {}),
  };
};
