"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversationHandlerMicroservicePatterns = void 0;
var ConversationHandlerMicroservicePatterns;
(function (ConversationHandlerMicroservicePatterns) {
    ConversationHandlerMicroservicePatterns["AriInboundCallReceived"] = "AriInboundCallReceived";
    ConversationHandlerMicroservicePatterns["InboundCallReceived"] = "InboundCallReceived";
    ConversationHandlerMicroservicePatterns["OutboundCallCreated"] = "OutboundCallCreated";
    ConversationHandlerMicroservicePatterns["OutboundJourneyCallCreated"] = "OutboundJourneyCallCreated";
    ConversationHandlerMicroservicePatterns["InternalCallCreated"] = "InternalCallCreated";
    ConversationHandlerMicroservicePatterns["ChanSpyInitiated"] = "ChanSpyInitiated";
    ConversationHandlerMicroservicePatterns["ChanSpyCallStarted"] = "ChanSpyCallStarted";
    ConversationHandlerMicroservicePatterns["ChanSpyCallStopped"] = "ChanSpyCallStopped";
    ConversationHandlerMicroservicePatterns["VoiceCallBridgeInitiated"] = "VoiceCallBridgeInitiated";
    ConversationHandlerMicroservicePatterns["VoicemailMessageStopped"] = "VoicemailMessageStopped";
    ConversationHandlerMicroservicePatterns["VoiceCallTransferred"] = "VoiceCallTransferred";
    ConversationHandlerMicroservicePatterns["MailMessageReceived"] = "MailMessageReceived";
    ConversationHandlerMicroservicePatterns["RecordingFinished"] = "RecordingFinished";
    ConversationHandlerMicroservicePatterns["AriSpyBridgeStarted"] = "AriSpyBridgeStarted";
    ConversationHandlerMicroservicePatterns["AriSpyBridgeStopped"] = "AriSpyBridgeStopped";
})(ConversationHandlerMicroservicePatterns = exports.ConversationHandlerMicroservicePatterns || (exports.ConversationHandlerMicroservicePatterns = {}));
