import React, { useEffect, useState } from "react";
import "./voice-call-modal.scss";
import {
  actionSetGlobalCall,
  actionSetGlobalVoiceQueueRouteOptions,
  actionSetGlobalVoiceQueueRouteSelected,
  IVoiceQueueRouteOption,
  useAppDispatch,
} from "@Store";
import { Modal, Select } from "antd";
import {
  ISharedContact,
  Undefinable,
  ISharedVoiceAsterisk,
  ISharedUserStatusCurrentVoiceAsteriskProperty,
  getQueueRouteOutboundExtension,
  isEmergencyNumber,
  DirectionEnum,
} from "atlas-shared";
import { getSipUsersWrapper } from "SIP";
import {
  useAuth,
  useCall,
  useQueues,
  useOrganizations,
  useSipUsers,
  useVoiceQueueRouteOptions,
  useVoiceQueueRouteSelected,
  useVoiceRoutes,
  useVoiceAsterisks,
} from "@Hooks";
import { useTranslation } from "react-i18next";
import { URI } from "sip.js";
import { QueueRouteSelection } from "@Utils/queue-route";
import { IconText } from "@Components/misc";
import { PhoneIcon } from "@Assets";
import {
  AlertError,
  DisplayPhoneNumber,
  getOutboundAsterisk,
  translatePhoneNumber,
} from "@Utils";
import { useStableNavigate } from "../../../context/StableNavigateContext";
import {
  blindTransferVoiceCallExternal,
  blindTransferVoiceCallInternal,
  createVoiceChanspy,
  createVoiceInternalCall,
  voiceConversationCallInternal,
  voiceConversationCallOutbound,
} from "@Api";

export const VoiceCallModal = React.memo(() => {
  const navigate = useStableNavigate();
  const call = useCall();
  const voice_asterisks = useVoiceAsterisks();
  const voice_queue_route_options = useVoiceQueueRouteOptions();
  const voice_queue_route_selected = useVoiceQueueRouteSelected();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const auth = useAuth();
  const sip_users = useSipUsers();
  const organizations = useOrganizations();
  const sip_users_wrapper = getSipUsersWrapper();
  const contact_id: Undefinable<ISharedContact["id"]> = undefined;
  const queues = useQueues();
  const voice_routes = useVoiceRoutes();
  const [outboundAsterisk, setOutboundAsterisk] =
    useState<ISharedUserStatusCurrentVoiceAsteriskProperty>();

  const [number, setNumber] = useState<Undefinable<string>>();
  const [numbers, setNumbers] = useState<Array<string>>([]);

  useEffect(() => {
    setOutboundAsterisk(getOutboundAsterisk(auth.user_status));
  }, [auth.user_status.__current_voice_asterisks]);

  useEffect(() => {
    setNumbers(call?.numbers || []);
    if (call?.numbers.length === 1) setNumber(call?.numbers[0]);
    else if (number && !call?.numbers.includes(number)) setNumber(undefined);
  }, [call?.numbers, number]);

  useEffect(() => {
    const queue_routes: Array<IVoiceQueueRouteOption> = queues.queues
      .filter(
        (queue) =>
          !!queue.voice_settings &&
          auth.user_status.__current_queues.includes(queue.id)
      )
      .map((queue) =>
        voice_routes.voice_routes
          .filter((voice_route) =>
            queue.__outbound_voice_routes.includes(voice_route.id)
          )
          .map((route) => ({
            queue,
            route,
          }))
      )
      .flat();

    dispatch(actionSetGlobalVoiceQueueRouteOptions(queue_routes));

    if (queue_routes.length === 1) {
      dispatch(
        actionSetGlobalVoiceQueueRouteSelected({
          queue_id: queue_routes[0].queue.id,
          route_id: queue_routes[0].route.id,
        })
      );
    }
  }, [auth.user_status.__current_queues, queues, voice_routes, dispatch]);

  useEffect(() => {
    if (
      (voice_queue_route_selected?.route_id &&
        !voice_queue_route_options.some(
          (queue_route) =>
            queue_route.route.id === voice_queue_route_selected.route_id
        )) ||
      (voice_queue_route_selected?.queue_id &&
        !voice_queue_route_options.some(
          (queue_route) =>
            queue_route.queue.id === voice_queue_route_selected.queue_id
        ))
    )
      dispatch(actionSetGlobalVoiceQueueRouteSelected(null));
  }, [voice_queue_route_options, dispatch, voice_queue_route_selected]);

  if (!call) return <></>;

  const clear = () => dispatch(actionSetGlobalCall(null));
  const makeCall = () => {
    if (!call || !number) return;

    let voice_asterisk_id: Undefinable<ISharedVoiceAsterisk["id"]> =
      call.voice_asterisk_id;

    if (voice_asterisk_id === -1) voice_asterisk_id = outboundAsterisk?.vaid;

    if (!voice_asterisk_id) return clear();

    const voice_asterisk = voice_asterisks.dict[voice_asterisk_id];

    if (voice_asterisk.ari_based) {
      if (call.spy) {
        createVoiceChanspy({
          organization_id: call.organization_id as number,
          voice_asterisk_id,
          user_id: call.spy.user_id,
          spy_agentprofile_id: call.spy.spy_agentprofile_id,
          spyee_user_id: call.spy.spyee_user_id,
          spyee_agentprofile_id: call.spy.spyee_agentprofile_id,
          flags: call.spy.flags,
        });

        clear();
      }

      if (
        !auth.user_status.agentprofile_id ||
        (call.requires_route &&
          (!voice_queue_route_selected?.queue_id ||
            !voice_queue_route_selected?.route_id))
      )
        return;

      if (call.transfer) {
        if (call.direction === DirectionEnum.Internal)
          blindTransferVoiceCallInternal(
            call.transfer.organization_id,
            call.transfer.id,
            call.transfer.voice_call_bridges.find((b) => !b.leave_at)!.id,
            {
              queue_id: call.queue_id as number,
              user_id: call.callee_user_id as number,
              agentprofile_id: call.callee_agentprofile_id as number,
            }
          );
        else
          blindTransferVoiceCallExternal(
            call.transfer.organization_id,
            call.transfer.id,
            call.transfer.voice_call_bridges.find((b) => !b.leave_at)!.id,
            {
              destination: number,
            }
          );
      } else {
        if (call.direction === DirectionEnum.Internal) {
          if (call.conversation_id)
            voiceConversationCallInternal({
              queue_id: call.queue_id as number,
              callee_user_id: call.callee_user_id as number,
              callee_agentprofile_id: call.callee_agentprofile_id as number,
              agentprofile_id: call.agentprofile_id as number,
              voice_asterisk_id,
              conversation_id: call.conversation_id as number,
            }).catch((error) => {
              AlertError(t, { title: error?.message || error.toString() });
            });
          else
            createVoiceInternalCall({
              callee_user_id: call.callee_user_id as number,
              callee_agentprofile_id: call.callee_agentprofile_id as number,
              agentprofile_id: call.agentprofile_id as number,
              voice_asterisk_id,
            });
        } else if (voice_queue_route_selected)
          voiceConversationCallOutbound({
            destination: number,
            voice_route_id: voice_queue_route_selected.route_id,
            queue_id: voice_queue_route_selected.queue_id,
            agentprofile_id: auth.user_status.agentprofile_id,
            conversation_id: call.conversation_id as number,
          });
      }

      clear();

      return;
    }

    const sip_user_wrapper = sip_users_wrapper?.getSipUser(voice_asterisk_id);
    const sip_user = sip_user_wrapper?.getSipUser();

    if (sip_user_wrapper && sip_user) {
      const extraHeaders: Array<string> = [
        ...(call.headers || []),
        `X-User-ID: ${auth.user.id}`,
      ];

      if (call.conversation_id)
        extraHeaders.push(`X-Conversation-ID: ${call.conversation_id}`);

      // if (!conversation_id)
      //   extraHeaders.push('X-Conversation-ID: 1000366');

      if (contact_id) extraHeaders.push(`X-Contact-ID: ${contact_id}`);

      // blind transfer
      if (call.transfer) {
        if (
          call.requires_route &&
          (!voice_queue_route_selected?.queue_id ||
            !voice_queue_route_selected?.route_id)
        )
          return;

        const uri = new URI(
          "sip",
          `${
            call.requires_route
              ? getQueueRouteOutboundExtension(
                  voice_queue_route_selected!.queue_id,
                  voice_queue_route_selected!.route_id
                )
              : ""
          }${number.replace("+", "")}`,
          sip_user.options.userAgentOptions?.uri?.host as string,
          5060,
          {}
        );

        if (uri)
          sip_user
            .getSessionBySharedVoiceCall(call.transfer)
            ?.session.refer(uri, {
              requestOptions: {
                extraHeaders,
              },
            });
      }
      // new call
      else {
        let sip_endpoint: string;

        if (isEmergencyNumber(number, sip_user_wrapper?.voice_asterisk.iso2)) {
          sip_endpoint = `sip:${getQueueRouteOutboundExtension(
            voice_queue_route_selected!.queue_id,
            voice_queue_route_selected!.route_id
          )}${voice_asterisk.iso2}-emergency-${number.replace("+", "")}@${
            sip_user.options.userAgentOptions?.uri?.host
          }:5060`;
        } else {
          if (
            call.requires_route &&
            (!voice_queue_route_selected?.queue_id ||
              !voice_queue_route_selected?.route_id)
          )
            return;

          sip_endpoint = `sip:${
            call.requires_route
              ? getQueueRouteOutboundExtension(
                  voice_queue_route_selected!.queue_id,
                  voice_queue_route_selected!.route_id
                )
              : ""
          }${number.replace("+", "")}@${
            sip_user.options.userAgentOptions?.uri?.host
          }:5060`;
        }

        sip_user.call(sip_endpoint, {
          extraHeaders,
        });

        if (
          window.location.pathname.startsWith("/admin") ||
          window.location.pathname.startsWith("/stats")
        )
          navigate("/dashboard");
      }

      clear();
    }
  };

  if (
    (!call.confirm &&
      call.requires_route &&
      voice_queue_route_selected?.queue_id &&
      voice_queue_route_selected?.route_id &&
      number) ||
    (!call.requires_route && number)
  ) {
    makeCall();
    return <></>;
  }

  return (
    <Modal
      visible={!!call}
      onOk={() => {
        makeCall();
      }}
      onCancel={() => {
        clear();
      }}
      width={320}
      className={"voice-call-modal"}
      okText={
        <IconText
          icon={PhoneIcon}
          text={t("CALL")}
          iconProps={{ size: 18, style: { strokeWidth: 2, opacity: 0.7 } }}
        />
      }
      okButtonProps={{
        disabled: !(
          number &&
          voice_queue_route_selected?.queue_id &&
          voice_queue_route_selected?.route_id
        ),
      }}
    >
      <Select
        value={number}
        onChange={(n) => setNumber(n)}
        placeholder={t("SELECT_NUMBER")}
      >
        {numbers.map((number) => (
          <Select.Option value={number}>
            <DisplayPhoneNumber
              t={t}
              phonenumber={translatePhoneNumber(number)}
            />
          </Select.Option>
        ))}
      </Select>

      <QueueRouteSelection t={t} />
    </Modal>
  );
});
