"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockSharedMailMessage = void 0;
const enums_1 = require("../../utilities/enums");
const organization_1 = require("./organization");
const mail_account_1 = require("./mail_account");
const user_1 = require("./user");
const utils_1 = require("../utils");
const base = Object.assign({ id: 10000, channel: enums_1.ChannelEnum.Mail, body: {
        text: 'body',
        html: '<p>body</p>',
        textAsHtml: '<p>body</p>',
    }, uuid: 'uuid', organization_id: (0, organization_1.mockChildOrganization)().id, direction: enums_1.DirectionEnum.InBound, conversation_id: 1, mail_account_id: (0, mail_account_1.mockPreviewMailAccount)().id, contact_email: 'contact@mail.com', to: [{ address: 'contact@mail.com', },], cc: [{ address: 'cc1@mail.com}', }, { address: 'cc2@mail.com', },], bcc: ['bcc@mail.com', 'bcc2@mail.com',], subject: 'My long mail subject. My long mail subject. My long mail subject. My long mail subject. My long mail subject. ', attachments: [], auto_reply: false, handled_at: null, smtp_error: null, user_id: (0, user_1.mockPreviewUserOne)().id, reply_to: null }, utils_1.mockDateObject);
exports.mockSharedMailMessage = (0, utils_1.mockFunction)(Object.assign({}, base));
