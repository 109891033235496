import React from "react";
import { TFunction } from "i18next";
import { ISharedFullConversation, TConversationTextMessage } from "atlas-shared";
import { Icon } from "@Components/misc";
import { TextMessageOutboundIconCircled } from "@Assets/icons/channels";
import { THighlightedMessage } from "@Hooks";
import { ConversationMessageReplies } from "@Components/conversation";
import { highlightClassNames } from "@Utils";

interface IProps {
  t: TFunction;
  message: TConversationTextMessage;
  conversation: ISharedFullConversation;
  highlightedMessage: THighlightedMessage;
}

export const ConversationMessageTextMini = React.memo(
  ({ t, message, conversation, highlightedMessage }: IProps) => {
    return (
      <div
        className={`conversation-message conversation-message-text${highlightClassNames(
          message,
          highlightedMessage
        )}`}
      >
        <div className="conversation-message-content">
          <Icon
            icon={TextMessageOutboundIconCircled}
            iconProps={{ size: 10, padding: 3 }}
            className="channel-icon"
            tooltip={{ title: t("SMS_MESSAGE") }}
          />
          <div className="subject">{message.text}</div>
        </div>
        <ConversationMessageReplies
          message={message}
          conversation={conversation}
          highlightedMessage={highlightedMessage}
        />
      </div>
    );
  }
);
