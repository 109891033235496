"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateFinishTime = exports.getWorkingHoursByDate = void 0;
const iftime_1 = require("iftime");
const enums_1 = require("../enums");
const extractTimeSpan = (interval) => {
    const [times,] = interval.split(',');
    if (times === '*')
        return [[0, 0,], [23, 59,],];
    return times.split('-').map(time => time.split(':').map(n => +n));
};
const timeToComparisonValue = (timespan) => {
    return parseInt(`${timespan[0]}${timespan[1] < 10 ? '0' : ''}${timespan[1]}`);
};
const getWorkingHoursByDate = (operational_hours, date, [fromHours, fromMinutes,], to) => {
    let withinOpen = [];
    const withinClose = [];
    for (const interval of operational_hours.__reduced_intervals) {
        const dateMatch = (0, iftime_1.default)(`*${interval.interval.substr(interval.interval.indexOf(','), 50)}`, date);
        if (dateMatch) {
            let [[intervalStartHours, intervalStartMinutes,], [intervalEndHours, intervalEndMinutes,],] = extractTimeSpan(interval.interval);
            if (intervalStartHours === fromHours && intervalStartMinutes < fromMinutes)
                intervalStartMinutes = fromMinutes;
            if (intervalStartHours < fromHours) {
                intervalStartHours = fromHours;
                intervalStartMinutes = fromMinutes;
            }
            const obj = calculateDiffMin({
                date: date.toLocaleDateString(),
                open: interval.open,
                from: [intervalStartHours, intervalStartMinutes,],
                to: [intervalEndHours, intervalEndMinutes,],
            });
            if (interval.open)
                withinOpen.push(obj);
            else
                withinClose.push(obj);
        }
    }
    withinClose.forEach((close, closeIndex) => {
        const newOpens = [];
        withinOpen.forEach((open, openIndex) => {
            if (!open)
                return;
            if (timeToComparisonValue(open.from) < timeToComparisonValue(close.from))
                newOpens.push(calculateDiffMin({
                    date: open.date,
                    from: open.from,
                    to: timeToComparisonValue(open.to) < timeToComparisonValue(close.from) ? open.to : close.from,
                }));
            if (timeToComparisonValue(close.to) < timeToComparisonValue(open.to))
                newOpens.push(calculateDiffMin({
                    date: open.date,
                    from: timeToComparisonValue(close.to) > timeToComparisonValue(open.from) ? close.to : open.from,
                    to: open.to,
                }));
        });
        withinOpen = newOpens;
    });
    return withinOpen;
};
exports.getWorkingHoursByDate = getWorkingHoursByDate;
const calculateDiffMin = (obj) => {
    const from = new Date(obj.date);
    from.setHours(obj.from[0]);
    from.setMinutes(obj.from[1]);
    const to = new Date(obj.date);
    to.setHours(obj.to[0]);
    to.setMinutes(obj.to[1]);
    obj.diff = Math.round((to.getTime() - from.getTime()) / 60000);
    return obj;
};
const calculateFinishTime = (operational_hours, from, time, unit) => {
    let finish = new Date(from);
    finish.setFullYear(finish.getFullYear() + 1);
    if (unit === enums_1.SlaTimeUnitEnum.Hours)
        time = time * 60;
    const dates = [];
    let i = 0;
    let allIntervals = [];
    while (time > 0 && i < 365) {
        if (i) {
            from.setMinutes(0);
            from.setHours(0);
        }
        i++;
        const new_from = new Date(from);
        new_from.setDate(from.getDate() + 1);
        dates.push([from, new_from,]);
        const dateIntervals = (0, exports.getWorkingHoursByDate)(operational_hours, from, [from.getHours(), from.getMinutes(),], [new_from.getHours(), new_from.getMinutes(),]);
        allIntervals = [...allIntervals, ...dateIntervals,];
        if (unit === enums_1.SlaTimeUnitEnum.Hours)
            dateIntervals.forEach(obj => {
                if (time <= 0)
                    return;
                if (time - obj.diff <= 0) {
                    finish = new Date(obj.date);
                    finish.setHours(obj.from[0]);
                    finish.setMinutes(obj.from[1] + time);
                    time = 0;
                }
                else
                    time -= obj.diff;
            });
        else if (unit === enums_1.SlaTimeUnitEnum.Days && dateIntervals.length)
            if (time <= 1) {
                const lastItem = dateIntervals[dateIntervals.length - 1];
                finish = new Date(lastItem.date);
                finish.setHours(lastItem.to[0]);
                finish.setMinutes(lastItem.to[1]);
                time = 0;
            }
            else {
                time -= 1;
            }
        from = new_from;
    }
    return { dates: allIntervals, finish, };
};
exports.calculateFinishTime = calculateFinishTime;
