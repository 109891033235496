import { ChannelEnum, DraftChannelEnum } from "atlas-shared";

export const convertToDraftChannel = (
  channel: ChannelEnum | undefined
): DraftChannelEnum => {
  switch (channel) {
    case ChannelEnum.Mail:
      return DraftChannelEnum.Mail;
    case ChannelEnum.Sms:
      return DraftChannelEnum.Sms;
    default:
      return DraftChannelEnum.Mail;
  }
};
