import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  actionFetchVoiceChanspies,
  useAppDispatch,
  IReducerStore,
} from "@Store";

let initiated = false;

export const useVoiceChanspies = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const voice_chanspies = useSelector(
    (store: IReducerStore) => store.voice_chanspies
  );

  useEffect(() => {
    if (
      !initiated &&
      autoload &&
      !voice_chanspies.loaded &&
      !voice_chanspies.loading
    ) {
      initiated = true;
      dispatch(actionFetchVoiceChanspies());
    }
  }, [autoload, voice_chanspies.loaded, voice_chanspies.loading, dispatch]);

  return voice_chanspies;
};
