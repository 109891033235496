import { Modal } from "antd";
import "./user-settings-modal.scss";
import { useResetPassword } from "@Hooks";
import { actionSetResetPassword, useAppDispatch } from "@Store";
import { PasswordResetForm } from "./PasswordResetForm";
import { LanguageSelector } from "./LanguageSelector";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

export const UserSettingsModal = () => {
  const dispatch = useAppDispatch();
  const user_id = useResetPassword();
  const { Title } = Typography;
  const { t } = useTranslation();

  return user_id ? (
    <Modal
      visible={!!user_id}
      width={520}
      onCancel={() => dispatch(actionSetResetPassword(null))}
      className={"user-settings-modal"}
    >
      <Title level={1}>{t("USER_SETTINGS")}</Title>
      <LanguageSelector />
      <PasswordResetForm
        user_id={user_id}
        onCancel={() => dispatch(actionSetResetPassword(null))}
        onOk={() => dispatch(actionSetResetPassword(null))}
      />
    </Modal>
  ) : (
    <></>
  );
};
