"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionTimeout = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const misc_1 = require("../../misc");
exports.JourneyActionTimeout = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.Timeout,
    direction: enums_1.JourneyNodeDirectionEnum.InputOutput,
    channels: (0, misc_1.EnumValues)(enums_1.ChannelEnum),
    settings_schema: (0, base_1.settingsValidationSchema)({
        timeout: {
            type: 'number',
            required: true,
            min: 3,
        },
    }),
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_TIMEOUT_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_TIMEOUT_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                index: 0,
                required: true,
                title: 'TIMED_OUT',
                color: null,
            }) });
    },
});
