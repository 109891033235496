import { RestRequest } from "@Utils/rest";
import { FlagRecord } from "src/context/FeatureFlagContext";

export interface IFeatureFlag {
  id: number;
  name: string;
  identifier: string;
  defaultValue: boolean;
}

export interface FeatureFlagOrganization {
  id: number;
  type: "ORGANIZATION";
  organization: { id: number };
  flag: IFeatureFlag;
  value: boolean;
}

export const getFeatureFlags = async () => {
  return RestRequest.get<IFeatureFlag[]>(`api/v1/feature_flags`);
};

export const GetFeatureFlagsOrganization = async () => {
  return RestRequest.get<FeatureFlagOrganization[]>(`api/v1/feature_flag_organizations`);
};

export const createFeatureFlag = async (
  name: string,
  identifier: string,
  defaultValue: boolean = false
) => {
  return RestRequest.post<any, any>("feature_flag", {
    name,
    identifier,
    defaultValue,
  });
};

export const setFeatureFlagForOrganization = async (
  organizationId: number,
  featureFlagId: number,
  value: boolean
) => {
  return RestRequest.post<any, any>("feature_flag_organization", {
    organizationId,
    featureFlagId,
    value,
  });
};

let flagCache: FlagRecord | null = null;

export const getMergedFlags = async () => {
  if (flagCache) return flagCache;

  const featureFlagOrganization = await GetFeatureFlagsOrganization();
  const featureFlags = await getFeatureFlags();

  const mergeFlags = (
    featureFlags: IFeatureFlag[] | null,
    featureFlagsOrganization: FeatureFlagOrganization[]
  ): FlagRecord => {
    if (!featureFlags || !featureFlagOrganization) return {};

    const flags: FlagRecord = {};
    for (const flag of featureFlags) {
      flags[flag.identifier] = flag.defaultValue;
    }
    for (const flag of featureFlagsOrganization) {
      flags[flag.flag.identifier] = flag.value;
    }
    return flags;
  };

  return (flagCache = mergeFlags(featureFlags, featureFlagOrganization) ?? {});
};

export const checkFlag = async (identifier: string, defaultValue: boolean): Promise<boolean> => {
  try {
    return (await getMergedFlags())[identifier] ?? defaultValue;
  } catch {
    return Promise.resolve(defaultValue);
  }
};
