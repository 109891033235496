import { CpuIcon } from "@Assets/icons/Cpu";
import { ZapIcon } from "@Assets/icons/Zap";
import { Icon } from "@Components/misc/icon";
import { Button } from "antd";
import { ISharedFullConversation } from "atlas-shared/dist/interfaces/entities/conversation.interface";
import { useConversationSummary } from "./useConversationSummary";
import { useTranslation } from "react-i18next";
import "./conversation.summary.scss";
import { Typography } from "antd";
import { timeSince } from "@Utils";
import { AppSpinner } from "@Components/misc/app-spinner/app.spinner";
import { ChevronUpIcon } from "@Assets/icons/Chevron";
import { ChevronDownIcon } from "@Assets/icons/Chevron";

interface ConversationSummaryProps {
  conversation: ISharedFullConversation;
}

export const ConversationSummary = ({ conversation }: ConversationSummaryProps) => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const {
    getConversationSummary,
    newSummary,
    newSummaryLoading,
    newSummaryError,
    summaries,
    summariesLoading,
    summariesError,
    isSummaryOutdated,
    isOpen,
    setIsOpen,
  } = useConversationSummary(conversation);

  const currentSummary = newSummary ?? summaries[0];

  return (
    <>
      {(isSummaryOutdated || !currentSummary) && !newSummary && (
        <Button
          className="conversation-summary-button"
          onClick={getConversationSummary}
          disabled={summariesLoading || newSummaryLoading}
        >
          {summariesLoading || newSummaryLoading ? (
            <AppSpinner size="small" />
          ) : (
            <span className="conversation-summary-button-content">
              <Icon icon={ZapIcon} wrapperStyle={{ marginTop: "2px" }} />
              {summaries.length > 0 ? t("GET_NEW_SUMMARY") : t("SUMMARIZE_CONVERSATION")}
            </span>
          )}
        </Button>
      )}

      {(newSummaryError || summariesError) && (
        <div className="conversation-summary-error">
          {t("ERROR")}: {newSummaryError || summariesError}
        </div>
      )}
      {currentSummary && (
        <>
          <div
            className={`conversation-summary-wrapper ${isOpen ? "summary-open" : "summary-closed"}`}
          >
            <div className="conversation-summary-content">
              <div className="conversation-summary-content-header">
                <Icon icon={CpuIcon} iconProps={{ size: 18 }} />
                <Title level={5} className="conversation-summary-content-header-title">
                  {t("CONVERSATION_SUMMARY")}
                </Title>
              </div>
              <p>{currentSummary?.summary}</p>
              {currentSummary.createdAt && (
                <p className="conversation-summary-content-footer">
                  {timeSince(t, new Date(currentSummary.createdAt), true)}
                </p>
              )}
            </div>
          </div>
          <div className="toggle-button-container">
            <button className="toggle-button" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <Icon
                  icon={ChevronUpIcon}
                  iconProps={{ size: 16 }}
                  tooltip={{ title: t("CLOSE_SUMMARY") }}
                />
              ) : (
                <Icon
                  icon={ChevronDownIcon}
                  iconProps={{ size: 16 }}
                  tooltip={{ title: t("OPEN_SUMMARY") }}
                />
              )}
            </button>
          </div>
        </>
      )}
    </>
  );
};
