import { RestRequest } from "@Utils";
import { ITextMessageSource } from "atlas-shared";

export const createTextMessageSource = (
  payload: ITextMessageSource
): Promise<ITextMessageSource> => {
  return RestRequest.post<ITextMessageSource, ITextMessageSource>(
    "api/v1/text_message_source",
    payload
  );
};

export const getTextMessageSources = (): Promise<ITextMessageSource[]> => {
  return RestRequest.get<ITextMessageSource[]>(`api/v1/text_message_source`);
};
export const getTextMessageSourceById = (id: string): Promise<ITextMessageSource> => {
  return RestRequest.get<ITextMessageSource>(`api/v1/text_message_source/${id}`);
};

export const updateTextMessageSource = (
  id: string,
  payload: ITextMessageSource
): Promise<ITextMessageSource> => {
  return RestRequest.put<ITextMessageSource, ITextMessageSource>(
    `api/v1/text_message_source/${id}`,
    payload
  );
};

export const deleteTextMessageSource = (id: string): Promise<void> => {
  return RestRequest.delete(`api/v1/text_message_source/${id}`);
};
