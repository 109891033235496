import { useContext } from "react";
import { FeatureFlagContext } from "../context/FeatureFlagContext";

export const useFeatureFlag = (key: string, defaultValue: boolean) => {
  const flags = useFeatureFlags();
  return flags?.[key] || defaultValue;
};

/**
 * Primarily used internally
 * @returns
 */
export const useFeatureFlags = () => {
  const { flags } = useContext(FeatureFlagContext) ?? {};

  return flags;
};
