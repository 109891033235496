import { useMemo } from "react";
import {
  ConversationPriorityEnum,
  IPreviewOrganization,
  ISharedCreateConversation,
} from "atlas-shared";
import { useTranslation } from "react-i18next";
import { entitiesToOptions, filterRelated, nestedArray } from "@Utils";
import { useAsync } from "@Hooks/abstract/useAsync";
import { fetchOrganizations } from "@Api/abstract/organization.api.abstract";
import { fetchQueues } from "@Api/abstract/queue.api.abstract";
import { fetchClients } from "@Api/abstract/client.api.abstract";
import { fetchMailAccounts } from "@Api/abstract/mail_account.api.abstract";
import { fetchSignatures } from "@Api/abstract/signature.api.abstract";
import { fetchTags } from "@Api/abstract/tag.api.abstract";
import { fetchCannedAnswers } from "@Api/abstract/canned_answer.api.abstract";
import { fetchLists } from "@Api/abstract/list.api.abstract";

interface UseFormOptionsProps {
  values: Partial<ISharedCreateConversation>;
}

export const useFormOptions = ({ values }: UseFormOptionsProps) => {
  const { t } = useTranslation();
  const { data: organizations } = useAsync(fetchOrganizations);
  const { data: queues } = useAsync(fetchQueues);
  const { data: clients } = useAsync(fetchClients);
  const { data: signatures } = useAsync(fetchSignatures);
  const { data: mailAccounts } = useAsync(fetchMailAccounts);
  const { data: tags } = useAsync(fetchTags);
  const { data: cannedAnswers } = useAsync(fetchCannedAnswers);
  const { data: lists } = useAsync(fetchLists);

  const organizationOptions = useMemo(
    () =>
      entitiesToOptions<IPreviewOrganization>(
        nestedArray<any>(organizations || [], "organization_id", false)
      ),
    [organizations]
  );

  const queueOptions = useMemo(() => {
    if (!values.organization_id) return [];

    return queues?.filter((q) => q.organization_id === values.organization_id) || [];
  }, [values.organization_id, queues]);

  const clientOptions = useMemo(() => {
    if (!values.queue_id || !queues?.[values.queue_id]) return [];
    const queue = queues?.[values.queue_id];

    return clients?.filter((client) => queue.clients.includes(client.id)) || [];
  }, [values.queue_id, clients, queues]);

  const cannedAnswerOptions = useMemo(() => {
    const queue = queues?.find((q) => q.id === values.queue_id);
    const channel = values.main_channel;

    if (!queue || !channel) return [];

    const answersForChannel = cannedAnswers?.filter((ca) => ca.channel.includes(channel));
    const answersForOrganization = answersForChannel?.filter(
      (ca) => ca.organization_id === queue.organization_id
    );

    return answersForOrganization;
  }, [values.queue_id, values.main_channel, cannedAnswers, queues]);

  const signatureOptions = useMemo(() => {
    if (!values.organization_id || !signatures) return [];
    const organizationSignatures = signatures?.filter(
      (signature) => signature.organization_id === values.organization_id
    );

    return organizationSignatures?.map((signature) => ({
      value: signature.id,
      label: signature.title,
    }));
  }, [values.organization_id, signatures]);

  const mailAccountOptions = useMemo(() => {
    if (!values.queue_id || !values.organization_id || !mailAccounts) return [];
    const queue = queues?.find((q) => q.id === values.queue_id);

    return mailAccounts?.filter((acc) => queue?.__outbound_mail_accounts.includes(acc.id));
  }, [values.queue_id, values.organization_id, mailAccounts, queues]);

  const defaultMailAccountId = useMemo(() => {
    if (mailAccountOptions.length > 0) {
      return Number(mailAccountOptions[0]?.id);
    }
    return undefined;
  }, [mailAccountOptions]);

  const priorityOptions = useMemo(() => {
    return Object.values(ConversationPriorityEnum).map((priority) => ({
      key: priority,
      title: t(`PRIORITY_${priority.toUpperCase()}`),
    }));
  }, [t]);

  const tagOptions = useMemo(() => {
    if (!values.queue_id || !values.organization_id) return [];

    return filterRelated(
      values.organization_id,
      "tag",
      "conversation",
      tags?.filter(
        (tag) =>
          tag.global || queues?.find((q) => q.id === values.queue_id)?.__tags.includes(tag.id)
      ) || [],
      true
    );
  }, [values.queue_id, values.organization_id, tags, queues]);

  const listOptions =
    lists?.filter((list) => list.organization_id === values.organization_id) || [];

  return {
    organizationOptions,
    queueOptions,
    clientOptions,
    signatureOptions,
    cannedAnswerOptions,
    mailAccountOptions,
    priorityOptions,
    tagOptions,
    emailOptions: [],
    defaultMailAccountId,
    listOptions,
  };
};
