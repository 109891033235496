import React, { useEffect, useMemo, useState, } from 'react';
import './absolute.active-calls.scss';
import { ISharedFullConversationReducer,
  Undefinable,
  TConversationVoiceCall,
} from 'atlas-shared';
import { IAuth, } from '@Store';
import { ConversationMessageVoiceCall, Icon, } from '@Components';
import { ChevronDownIcon, } from '@Assets';
import { VoiceCallInfo, } from '@Components/conversation/conversation.messages/conversation.message/conversation.message.voice_call/voice_call.info';
import { getSipUsersWrapper, IVoiceSessionObject, SipUser, } from 'SIP';
import { AlertError, } from '@Utils';
import { useTranslation, } from 'react-i18next';
import { THighlightedMessage, useVoiceAsterisks, } from '@Hooks';
import { holdVoiceCallBridge, unholdVoiceCallBridge, } from '@Api';

interface IProps {
  auth: IAuth;
  conversation: ISharedFullConversationReducer;
  minimized?: boolean;
  highlightedMessage: THighlightedMessage;
}

interface IAbsoluteVoiceCalls {
  active?: Undefinable<TConversationVoiceCall>;
  held: Array<TConversationVoiceCall>;
}

export const AbsoluteActiveCalls = React.memo(({ conversation, auth, minimized, highlightedMessage, }: IProps) => {
  const { active_calls: voice_calls, } = conversation;
  const voice_asterisks = useVoiceAsterisks();
  const { t, } = useTranslation();
  const [voiceCalls, setVoiceCalls, ] = useState<IAbsoluteVoiceCalls>({ held: [], });
  const sipUserWrapper = getSipUsersWrapper();

  useEffect(() => {

    let active: IAbsoluteVoiceCalls['active'] = undefined;
    let held: IAbsoluteVoiceCalls['held'] = [];

    voice_calls.forEach(voice_call => {
      if (!voice_call.end_time) {
        if (!voice_call.voice_call_bridges.some(bridge => bridge.hold_at))
          active = voice_call;
        else
          held.push(voice_call);
      }
    });

    if (!active && held.length)
      active = held.shift();

    setVoiceCalls({ active, held, });

  }, [voice_calls, ]);

  const hasMore = useMemo(() => !!voiceCalls.held.length, [voiceCalls, ]);

  if (!voiceCalls.active && !hasMore)
    return <></>;

  return <div className={`absolute-voice-controllers ${minimized ? 'minimized' : 'mini'}`}>
    {voiceCalls.active && <ConversationMessageVoiceCall conversation={conversation} message={voiceCalls.active} auth={auth} mini={true} head={false} absolute={true} highlightedMessage={highlightedMessage} />}
    {!voiceCalls.active && <span>no active call</span>}
    <div className={`call-selector${voiceCalls.held.length ? ' has-many' : ''}`}>
      <div className='background'>
        <div className='calls'>
          {hasMore && <div className='call-dropdown'>
            {voiceCalls.held.map(voice_call => <div onClick={() => {

              const voice_asterisk = voice_asterisks.dict[voice_call.voice_asterisk_id];

              if (voice_asterisk.ari_based) {
                const held_bridge = voice_call.voice_call_bridges.find(bridge => !bridge.leave_at);
                const current_bridge = voiceCalls.active?.voice_call_bridges.find(bridge => !bridge.leave_at);

                if (current_bridge)
                  holdVoiceCallBridge(current_bridge.organization_id, current_bridge.id);
                else
                  AlertError(t, { title: `On call switch no current bridge found on voice call ${voice_call.id}`, });

                if (held_bridge)
                  unholdVoiceCallBridge(held_bridge.organization_id, held_bridge.id);
                else
                  AlertError(t, { title: `On call switch no held bridge found on voice call ${voice_call.id}`, });
              }
              else {
                const sip_user: Undefinable<SipUser> = sipUserWrapper?.getSipUser(voice_call.voice_asterisk_id)?.getSipUser();

                if (!sip_user) {
                  AlertError(t, { content: `SIP USER not found for id ${voice_call.voice_asterisk_id}`, });
                  return;
                }

                const current_voice_session: Undefinable<IVoiceSessionObject> = voiceCalls.active ? sip_user.getSessionBySharedVoiceCall(voiceCalls.active) : undefined;
                const new_voice_session: Undefinable<IVoiceSessionObject> = sip_user.getSessionBySharedVoiceCall(voice_call);

                if (current_voice_session)
                  sip_user.hold(current_voice_session);

                if (new_voice_session)
                  sip_user.unhold(new_voice_session);
              }

            }} className='call selectable'>
              <VoiceCallInfo voice_call={voice_call} />
            </div>
            )}
          </div>}
          {voiceCalls.active && <div className='call call-selected'>
            <VoiceCallInfo voice_call={voiceCalls.active} />
          </div>}
        </div>
        {hasMore && <div className='arrow'>
          <Icon icon={ChevronDownIcon} />
        </div>}
      </div>
    </div>
  </div>;
});
