"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionVoiceMenu = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
exports.JourneyActionVoiceMenu = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.VoiceMenu,
    channels: [enums_1.ChannelEnum.Voice,],
    direction: enums_1.JourneyNodeDirectionEnum.Output,
    settings_schema: (0, base_1.settingsValidationSchema)({
        asterisk_sound_id: {
            type: 'number',
            required: true,
        },
        timeout: {
            type: 'number',
            required: true,
            default: 30000,
        },
        options: {
            type: 'array',
            required: true,
            min: 1,
            max: 10,
            default: [],
            items: {
                type: 'string',
                required: true,
                valid: '0123456789#'.split(''),
            },
        },
        retries: {
            type: 'number',
            required: true,
            default: 1,
        },
        journey_variable_id: {
            type: 'number',
            required: true,
        },
    }),
    initialValues: {
        options: [],
        timeout: 30000,
        retries: 1,
    },
    getPorts: (node) => {
        let ports = Object.assign({}, base_1.journeyDefaultInputPort);
        (node.properties.options || []).forEach((option, index) => {
            const id = `${constants_1.JOURNEY_ACTION_VOICE_MENU_OUTPUT}_${index}`;
            ports[id] = {
                id,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: true,
                title: option + '',
                description: `PRESSED_${option}`,
                color: null,
                index,
            };
        });
        ports[constants_1.JOURNEY_ACTION_VOICE_MENU_FAIL_OUTPUT] = {
            id: constants_1.JOURNEY_ACTION_VOICE_MENU_FAIL_OUTPUT,
            type: enums_1.JourneyPortDirectionEnum.Output,
            removable: false,
            required: true,
            description: `PRESSED_NONE_VALID`,
            index: 0,
            title: 'FAILED',
            color: '#d91010',
        };
        return ports;
    },
});
