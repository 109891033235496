"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bitwiseRemove = exports.bitwiseAdd = exports.bitwiseIncludes = exports.getTopBitwiseMember = void 0;
const getTopBitwiseMember = (_enum, src) => {
    const bits = [];
    Object.values(_enum).forEach(s => {
        const n = +s;
        if (!isNaN(n))
            bits.push(n);
    });
    bits.sort((a, b) => a < b ? 1 : -1);
    for (const bit of bits) {
        if ((0, exports.bitwiseIncludes)(src, bit))
            return bit;
    }
    return 0;
};
exports.getTopBitwiseMember = getTopBitwiseMember;
const bitwiseIncludes = (src, target) => (src & target) === target;
exports.bitwiseIncludes = bitwiseIncludes;
const bitwiseAdd = (src, target) => {
    if (!(0, exports.bitwiseIncludes)(src, target))
        src += target;
    return src;
};
exports.bitwiseAdd = bitwiseAdd;
const bitwiseRemove = (src, target) => {
    if ((0, exports.bitwiseIncludes)(src, target))
        src -= target;
    return src;
};
exports.bitwiseRemove = bitwiseRemove;
