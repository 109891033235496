import { useAsync } from "@Hooks/abstract/useAsync";
import { getTextMessageSources } from "@Api/textMessageSourcesApi";

export const useTextMessageSources = () => {
  const {
    data: sources,
    loading,
    error,
  } = useAsync(async () => {
    const sources = await getTextMessageSources();
    return sources.map((source) => ({
      value: source.id?.toString() || "",
      label: source.alphaTag || source.id?.toString() || "",
    }));
  });

  return { options: sources || [], loading, error };
};
