import { useEffect, useState, useCallback, useMemo } from "react";
import {
  ISharedDraft,
  DraftChannelEnum,
  ISharedCreateConversation,
  ChannelEnum,
} from "atlas-shared";
import {
  createDraft,
  fetchGlobalDrafts,
  patchDraft,
  deleteDraftAttachment,
  downloadDraftAttachment,
  fetchDraft,
} from "@Api";
import debounce from "lodash/debounce";

interface UseConversationDraftProps {
  organization_id?: number;
  channel?: DraftChannelEnum;
  user_id: string;
  body?: string;
  title?: string;
  values?: ISharedCreateConversation;
  setValues: (values: Partial<ISharedCreateConversation>) => void;
}

export const useConversationDraft = ({
  organization_id,
  channel = DraftChannelEnum.Mail,
  user_id,
  values,
  setValues,
}: UseConversationDraftProps) => {
  const [draft, setDraft] = useState<ISharedDraft>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const updateDraft = useCallback(
    async (newBody: string, newTitle: string) => {
      if (!draft?.id) return;

      try {
        const payload = {
          body: newBody,
          subject: newTitle,
        };

        await patchDraft(draft.id, payload);
        setDraft((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            body: newBody,
            subject: newTitle,
          };
        });
      } catch (err) {
        setError(err instanceof Error ? err : new Error("Failed to update draft"));
      }
    },
    [draft?.id]
  );

  const debouncedUpdateDraft = useMemo(
    () =>
      debounce(async (newBody: string, newTitle: string) => {
        updateDraft(newBody, newTitle);
      }, 1000), // 1 second delay
    [updateDraft]
  );

  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedUpdateDraft.cancel();
    };
  }, [debouncedUpdateDraft]);

  const initializeDraft = useCallback(
    async (orgId: number) => {
      if (!orgId) return;

      setLoading(true);
      try {
        if (draft) return;

        const drafts = await fetchGlobalDrafts(orgId);

        if (!drafts.length) {
          const newDraft = await createDraft({
            organization_id: orgId,
            channel,
            user_id: Number(user_id),
            body: values?.body || "",
            subject: values?.title || "",
            reply_to: null,
            reply_to_address: "tmp@tmp.com",
          });
          setDraft(newDraft);
        } else {
          const lastDraft = drafts[drafts.length - 1];

          setDraft(lastDraft);
          // Only update values if they're not already set
          const newValues: Partial<ISharedCreateConversation> = {
            organization_id: values?.organization_id || lastDraft.organization_id,
            main_channel: values?.main_channel || ChannelEnum.Mail,
            body: values?.body || lastDraft.body || "",
            title: values?.title || lastDraft.subject || "",
          };
          setValues({ ...values, ...newValues });
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error("Failed to initialize draft"));
      } finally {
        setLoading(false);
      }
    },
    [channel, user_id, values, setValues]
  );

  const reloadDraft = useCallback(() => {
    if (draft?.id) fetchDraft(draft.id).then((draft) => setDraft(draft));
  }, [draft]);

  const handleAttachmentDelete = useCallback(
    async (attachmentIndex: number) => {
      if (!draft?.id) return;

      try {
        await deleteDraftAttachment(draft.id, attachmentIndex);
        setDraft((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            attachments: prev.attachments?.filter((_, index) => index !== attachmentIndex) || null,
          };
        });
      } catch (err) {
        setError(err instanceof Error ? err : new Error("Failed to delete attachment"));
      }
    },
    [draft?.id]
  );

  const handleAttachmentDownload = useCallback(
    async (attachmentIndex: number, name: string) => {
      if (!draft?.id) return;

      try {
        return await downloadDraftAttachment(draft.id, attachmentIndex, name);
      } catch (err) {
        setError(err instanceof Error ? err : new Error("Failed to download attachment"));
      }
    },
    [draft?.id]
  );

  useEffect(() => {
    if (organization_id) {
      initializeDraft(organization_id);
    }
  }, [organization_id, initializeDraft]);

  return {
    draft,
    updateDraft,
    debouncedUpdateDraft,
    reloadDraft,
    loading,
    error,
    handleAttachmentDelete,
    handleAttachmentDownload,
  };
};
