"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = void 0;
const queue_1 = require("./queue");
const start_mail_inbound_1 = require("./start_mail_inbound");
const timeout_1 = require("./timeout");
const no_op_1 = require("./no_op");
const rule_1 = require("./rule");
const rule_contact_1 = require("./rule_contact");
const operational_hours_1 = require("./operational_hours");
const enums_1 = require("../../enums");
const mail_auto_response_1 = require("./mail_auto_response");
const when_1 = require("./when");
const start_form_1 = require("./start_form");
const rest_request_1 = require("./rest_request");
const edit_conversation_1 = require("./edit_conversation");
const close_conversation_1 = require("./close_conversation");
const send_mail_1 = require("./send_mail");
const journey_1 = require("./journey");
const agent_1 = require("./agent");
const start_voice_inbound_1 = require("./start_voice_inbound");
const start_voice_outbound_1 = require("./start_voice_outbound");
const voice_answer_1 = require("./voice_answer");
const voice_get_digits_1 = require("./voice_get_digits");
const voice_hangup_1 = require("./voice_hangup");
const voice_internal_dial_1 = require("./voice_internal_dial");
const voice_menu_1 = require("./voice_menu");
const voice_playback_1 = require("./voice_playback");
const voice_say_alpha_1 = require("./voice_say_alpha");
const voice_say_digits_1 = require("./voice_say_digits");
const voice_say_number_1 = require("./voice_say_number");
const voice_say_phonetic_1 = require("./voice_say_phonetic");
const voice_start_recording_1 = require("./voice_start_recording");
const voice_voicemail_1 = require("./voice_voicemail");
const voice_external_dial_1 = require("./voice_external_dial");
const voice_queue_1 = require("./voice_queue");
const voice_variable_set_1 = require("./voice_variable_set");
const voice_stop_recording_1 = require("./voice_stop_recording");
const set_field_1 = require("./set_field");
const voice_set_language_1 = require("./voice_set_language");
const unassign_1 = require("./unassign");
const fetch_contact_data_1 = require("./fetch_contact_data");
const close_tabs_1 = require("./close_tabs");
const client_1 = require("./client");
const forward_mail_1 = require("./forward_mail");
const start_mail_outbound_1 = require("./start_mail_outbound");
const add_internal_note_1 = require("./add_internal_note");
const reply_latest_inbound_message_1 = require("./reply_latest_inbound_message");
const open_browser_tab_1 = require("./open_browser_tab");
__exportStar(require("./start_voice_inbound"), exports);
__exportStar(require("./start_voice_outbound"), exports);
__exportStar(require("./voice_answer"), exports);
__exportStar(require("./voice_variable_set"), exports);
__exportStar(require("./voice_external_dial"), exports);
__exportStar(require("./voice_get_digits"), exports);
__exportStar(require("./voice_hangup"), exports);
__exportStar(require("./voice_internal_dial"), exports);
__exportStar(require("./voice_set_language"), exports);
__exportStar(require("./voice_menu"), exports);
__exportStar(require("./voice_playback"), exports);
__exportStar(require("./voice_say_alpha"), exports);
__exportStar(require("./voice_say_digits"), exports);
__exportStar(require("./voice_say_number"), exports);
__exportStar(require("./voice_say_phonetic"), exports);
__exportStar(require("./voice_start_recording"), exports);
__exportStar(require("./voice_stop_recording"), exports);
__exportStar(require("./voice_voicemail"), exports);
__exportStar(require("./agent"), exports);
__exportStar(require("./start_mail_inbound"), exports);
__exportStar(require("./start_mail_outbound"), exports);
__exportStar(require("./base"), exports);
__exportStar(require("../../enums"), exports);
__exportStar(require("./queue"), exports);
__exportStar(require("./voice_queue"), exports);
__exportStar(require("./timeout"), exports);
__exportStar(require("./start_form"), exports);
__exportStar(require("./no_op"), exports);
__exportStar(require("./when"), exports);
__exportStar(require("./rule"), exports);
__exportStar(require("./rule_contact"), exports);
__exportStar(require("./operational_hours"), exports);
__exportStar(require("./mail_auto_response"), exports);
__exportStar(require("./rest_request"), exports);
__exportStar(require("./edit_conversation"), exports);
__exportStar(require("./close_conversation"), exports);
__exportStar(require("./send_mail"), exports);
__exportStar(require("./journey"), exports);
__exportStar(require("./options"), exports);
__exportStar(require("./set_field"), exports);
__exportStar(require("./unassign"), exports);
__exportStar(require("./fetch_contact_data"), exports);
__exportStar(require("./close_tabs"), exports);
__exportStar(require("./client"), exports);
__exportStar(require("./forward_mail"), exports);
__exportStar(require("./add_internal_note"), exports);
__exportStar(require("./reply_latest_inbound_message"), exports);
__exportStar(require("./open_browser_tab"), exports);
exports.actions = {
    [enums_1.JourneyActionEnum.StartVoiceInbound]: start_voice_inbound_1.JourneyActionStartVoiceInbound,
    [enums_1.JourneyActionEnum.StartVoiceOutbound]: start_voice_outbound_1.JourneyActionStartVoiceOutbound,
    [enums_1.JourneyActionEnum.VoiceAnswer]: voice_answer_1.JourneyActionVoiceAnswer,
    [enums_1.JourneyActionEnum.VoiceVariableSet]: voice_variable_set_1.JourneyActionVoiceVariableSet,
    [enums_1.JourneyActionEnum.VoiceSetLanguage]: voice_set_language_1.JourneyActionVoiceSetLanguage,
    [enums_1.JourneyActionEnum.VoiceExternalDial]: voice_external_dial_1.JourneyActionVoiceExternalDial,
    [enums_1.JourneyActionEnum.VoiceGetDigits]: voice_get_digits_1.JourneyActionVoiceGetDigits,
    [enums_1.JourneyActionEnum.VoiceHangup]: voice_hangup_1.JourneyActionVoiceHangup,
    [enums_1.JourneyActionEnum.VoiceInternalDial]: voice_internal_dial_1.JourneyActionVoiceInternalDial,
    [enums_1.JourneyActionEnum.VoiceMenu]: voice_menu_1.JourneyActionVoiceMenu,
    [enums_1.JourneyActionEnum.VoicePlayback]: voice_playback_1.JourneyActionVoicePlayback,
    [enums_1.JourneyActionEnum.VoiceSayAlpha]: voice_say_alpha_1.JourneyActionVoiceSayAlpha,
    [enums_1.JourneyActionEnum.VoiceSayDigits]: voice_say_digits_1.JourneyActionVoiceSayDigits,
    [enums_1.JourneyActionEnum.VoiceSayNumber]: voice_say_number_1.JourneyActionVoiceSayNumber,
    [enums_1.JourneyActionEnum.VoiceSayPhonetic]: voice_say_phonetic_1.JourneyActionVoiceSayPhonetic,
    [enums_1.JourneyActionEnum.VoiceStartRecording]: voice_start_recording_1.JourneyActionVoiceStartRecording,
    [enums_1.JourneyActionEnum.VoiceStopRecording]: voice_stop_recording_1.JourneyActionVoiceStopRecording,
    [enums_1.JourneyActionEnum.VoiceVoicemail]: voice_voicemail_1.JourneyActionVoiceVoicemail,
    [enums_1.JourneyActionEnum.StartMailInbound]: start_mail_inbound_1.JourneyActionStartMailInbound,
    [enums_1.JourneyActionEnum.StartMailOutbound]: start_mail_outbound_1.JourneyActionStartMailOutbound,
    [enums_1.JourneyActionEnum.StartForm]: start_form_1.JourneyActionStartForm,
    [enums_1.JourneyActionEnum.Queue]: queue_1.JourneyActionQueue,
    [enums_1.JourneyActionEnum.Client]: client_1.JourneyActionClient,
    [enums_1.JourneyActionEnum.VoiceQueue]: voice_queue_1.JourneyActionVoiceQueue,
    [enums_1.JourneyActionEnum.UnAssign]: unassign_1.JourneyActionUnAssign,
    [enums_1.JourneyActionEnum.Agent]: agent_1.JourneyActionAgent,
    [enums_1.JourneyActionEnum.Timeout]: timeout_1.JourneyActionTimeout,
    [enums_1.JourneyActionEnum.RestRequest]: rest_request_1.JourneyActionRestRequest,
    [enums_1.JourneyActionEnum.FetchContactData]: fetch_contact_data_1.JourneyActionFetchContactData,
    [enums_1.JourneyActionEnum.EditConversation]: edit_conversation_1.JourneyActionEditConversation,
    [enums_1.JourneyActionEnum.CloseConversation]: close_conversation_1.JourneyActionCloseConversation,
    [enums_1.JourneyActionEnum.AddInternalNote]: add_internal_note_1.JourneyActionAddInternalNote,
    [enums_1.JourneyActionEnum.CloseTabs]: close_tabs_1.JourneyActionCloseTabs,
    [enums_1.JourneyActionEnum.SendMail]: send_mail_1.JourneyActionSendMail,
    [enums_1.JourneyActionEnum.ForwardMail]: forward_mail_1.JourneyActionForwardMail,
    [enums_1.JourneyActionEnum.Journey]: journey_1.JourneyActionJourney,
    [enums_1.JourneyActionEnum.SetField]: set_field_1.JourneyActionSetField,
    [enums_1.JourneyActionEnum.NoOp]: no_op_1.JourneyActionNoOp,
    [enums_1.JourneyActionEnum.Rule]: rule_1.JourneyActionRule,
    [enums_1.JourneyActionEnum.RuleContact]: rule_contact_1.JourneyActionRuleContact,
    [enums_1.JourneyActionEnum.OperationalHours]: operational_hours_1.JourneyActionOperationalHours,
    [enums_1.JourneyActionEnum.MailAutoResponse]: mail_auto_response_1.JourneyActionMailAutoResponse,
    [enums_1.JourneyActionEnum.ReplyLatestInboundMessage]: reply_latest_inbound_message_1.JourneyActionReplyLatestInboundMessage,
    [enums_1.JourneyActionEnum.When]: when_1.JourneyActionWhen,
    [enums_1.JourneyActionEnum.OpenBrowserTab]: open_browser_tab_1.JourneyActionOpenBrowserTab,
};
