import { sendTextMessage } from "@Api/textMessageApi";
import { useTextMessageSources } from "@Components/dashboard/create-conversation/hooks/useTextMessageSources";
import { message } from "antd";
import { DirectionEnum, ISharedQueue, ITextMessage, Nullable, Undefinable } from "atlas-shared";
import { ISharedFullConversation } from "atlas-shared/dist/interfaces/entities/conversation.interface";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { phoneNumberValidateAndParse } from "@Utils";
import { CountryCode } from "libphonenumber-js";

export const useCreateTextMessage = (
  conversation: ISharedFullConversation,
  queue: Undefinable<ISharedQueue>,
  onDelete?: () => void
) => {
  const [textMessage, setTextMessage] = useState<Nullable<ITextMessage>>({
    source: { id: 0 },
    queue: { id: conversation.queue_id },
    text: "",
    number: conversation.contact?.phones?.[0] ?? "",
  });

  const MAX_TEXT_MESSAGE_LENGTH = 320;
  const MIN_TEXT_MESSAGE_LENGTH = 1;

  const {
    options: textMessageSources,
    loading: textMessageSourcesLoading,
    error: textMessageSourcesError,
  } = useTextMessageSources();

  const setSource = () => {
    try {
      if (!textMessageSources || textMessageSources.length === 0) return;

      const sourceExists = textMessageSources.some(
        (source) => String(source.value) === String(textMessage?.source?.id)
      );

      if (!textMessage?.source?.id || !sourceExists) {
        setTextMessage((prev) => ({
          source: { id: Number(textMessageSources[0]?.value) ?? 0 },
          queue: { id: conversation.queue_id ? conversation.queue_id : 0 },
          text: prev?.text || "",
          number: prev?.number || "",
        }));
      }
    } catch (error) {
      console.error("Error setting text message source:", error);
    }
  };

  const updateTextMessage = useCallback((key: keyof ITextMessage | "sourceId", value: string) => {
    setTextMessage((prev) => ({
      source: key === "sourceId" ? { id: Number(value) } : prev?.source || { id: 0 },
      queue: { id: key === "queue" ? Number(value) : prev?.queue.id || 0, name: "" },
      text: key === "text" ? value : prev?.text || "",
      number: key === "number" ? value : prev?.number || "",
    }));
  }, []);

  const sendMessage = async () => {
    if (textMessage && textMessage.text?.length < MIN_TEXT_MESSAGE_LENGTH) return;

    const defaultCountry = queue?.voice_settings?.default_language ?? "SE";
    const parsedNumber = phoneNumberValidateAndParse(
      textMessage?.number || "",
      defaultCountry?.toString() as CountryCode
    );

    const payload: ITextMessage = {
      conversation_id: conversation.id,
      text: textMessage?.text || "",
      number: parsedNumber?.number || "",
      queue: { id: textMessage?.queue.id ?? null },
      direction: DirectionEnum.Outbound,
    };

    try {
      await sendTextMessage(String(textMessage?.source?.id || ""), payload);
    } catch (error) {
      console.error("Error sending text message:", error);
      message.error(t("ERROR_SENDING_MESSAGE"));
    } finally {
      // Delete draft and close editor
      onDelete?.();
    }
  };

  useEffect(() => {
    setSource();
  }, [textMessageSources, conversation, textMessage?.source?.id]);

  return {
    textMessage,
    updateTextMessage,
    sendMessage,
    textMessageSources,
    textMessageSourcesLoading,
    textMessageSourcesError,
    MAX_TEXT_MESSAGE_LENGTH,
    MIN_TEXT_MESSAGE_LENGTH,
  };
};
