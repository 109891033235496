"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionVoiceExternalDial = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
exports.JourneyActionVoiceExternalDial = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.VoiceExternalDial,
    channels: [enums_1.ChannelEnum.Voice,],
    direction: enums_1.JourneyNodeDirectionEnum.Output,
    settings_schema: (0, base_1.settingsValidationSchema)({
        phone: {
            type: 'string',
            label: 'PHONE_NUMBER',
            required: true,
            meta: { phone: true, },
        },
        outbound_voice_queue_id: {
            type: 'number',
            required: true,
        },
        outbound_voice_queue_route_id: {
            type: 'number',
            required: true,
        },
        timeout: {
            type: 'number',
            allow: [null,],
            default: null,
        },
    }),
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_VOICE_EXTERNAL_DIAL_INITIATED_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_VOICE_EXTERNAL_DIAL_INITIATED_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                title: 'INITIATED',
            }), [constants_1.JOURNEY_ACTION_VOICE_EXTERNAL_DIAL_DONE_ANSWERED_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_VOICE_EXTERNAL_DIAL_DONE_ANSWERED_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                required: true,
                index: 1,
                title: 'DONE',
                description: 'DONE_ANSWERED',
                color: '#5bbf1c',
            }), [constants_1.JOURNEY_ACTION_VOICE_EXTERNAL_DIAL_DONE_NO_ANSWER_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_VOICE_EXTERNAL_DIAL_DONE_NO_ANSWER_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                required: true,
                index: 2,
                title: 'DONE',
                description: 'DONE_NO_ANSWER',
                color: '#d91010',
            }) });
    },
});
