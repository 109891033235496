"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.journeyNodePortAddDefault = exports.validateJourneyNode = exports.getActionProps = void 0;
const actions_1 = require("./actions");
const getActionProps = (action) => actions_1.actions[action];
exports.getActionProps = getActionProps;
const validateJourneyNode = (journey, journey_node, inputs, outputs, validation) => {
    const action = (0, exports.getActionProps)(journey_node.action);
    const ports = action.getPorts(journey_node);
    const errors = [];
    if ([
        actions_1.JourneyActionEnum.StartMailInbound,
        actions_1.JourneyActionEnum.StartMailOutbound,
        actions_1.JourneyActionEnum.StartForm,
    ].includes(journey_node.action)) {
        let count = 0;
        Object.values(journey.nodes).forEach(node => {
            if (node.action === journey_node.action)
                count++;
            if (count > 1)
                errors.push({
                    nodeId: journey_node.id,
                    reason: 'DUPLICATE',
                });
        });
    }
    Object.entries(ports).forEach(([key, port,]) => {
        if (port.type === actions_1.JourneyPortDirectionEnum.Input) {
            if (port.required) {
                const link = inputs.find(l => l.to.portId === key);
                if (!link)
                    errors.push({
                        nodeId: journey_node.id,
                        portKey: key,
                        reason: 'LINK_MISSING',
                    });
            }
        }
        else {
            if (port.required) {
                const link = outputs.find(l => l.from.portId === key);
                if (!link)
                    errors.push({
                        nodeId: journey_node.id,
                        portKey: key,
                        reason: 'LINK_MISSING',
                    });
            }
        }
    });
    if (validation === null || validation === void 0 ? void 0 : validation.error)
        errors.push({
            nodeId: journey_node.id,
            reason: 'FORM_ERROR',
        });
    return errors;
};
exports.validateJourneyNode = validateJourneyNode;
const journeyNodePortAddDefault = (props) => {
    return Object.assign({ type: actions_1.JourneyPortDirectionEnum.Output, description: null, index: 0, removable: false, required: false, color: null }, props);
};
exports.journeyNodePortAddDefault = journeyNodePortAddDefault;
