import React from "react";
import parsePhoneNumber, { CountryCode } from "libphonenumber-js";
import { IPreviewVoiceAsterisk, Undefinable } from "atlas-shared";
import { PhoneNumber } from "libphonenumber-js/types";
import { useQueues, useUsers } from "@Hooks";
import { IconText } from "@Components";
import { InboxIcon, UserIcon } from "@Assets";
import { getFullName } from "@Utils/user";
import { TFunction } from "i18next";

export const translatePhoneNumber = (
  phone_number: string,
  country?: string
): Undefinable<PhoneNumber> => {
  return parsePhoneNumber(phone_number.split("___").at(-1) as string, {
    defaultCountry: country as CountryCode,
  });
};

// export const DisplayPhoneNumber = React.memo(({ phonenumber }: {phonenumber: IPhoneResult}) => phonenumber?.isValid ? <span className='phone-number'>
//   <span>{phonenumber.countryCode}</span> <span>{formatPhoneNumber(phonenumber)}</span>
// </span> : <></>);

export const DisplayPhoneNumber = React.memo(
  ({
    t,
    phonenumber,
    iso2,
  }: {
    t: TFunction;
    phonenumber: Undefinable<PhoneNumber>;
    iso2?: IPreviewVoiceAsterisk["iso2"];
    style?: React.CSSProperties;
  }) =>
    phonenumber?.isValid() ? (
      <span className="phone-number">
        {iso2 !== phonenumber?.country && (
          <span className="flag">
            <CountryFlagImg
              t={t}
              country={(phonenumber.country as string).toLowerCase()}
              width={12}
            />
          </span>
        )}
        <span className="phone">{phonenumber.formatNational()}</span>
      </span>
    ) : (
      <>{phonenumber?.number || phonenumber || ""}</>
    )
);

export const TranslateAndDisplayPhoneNumber = React.memo(
  ({
    t,
    phone_number,
    country,
    style = {},
  }: {
    t: TFunction;
    phone_number: string;
    country?: string;
    style?: React.CSSProperties;
  }) => {
    if (!phone_number) phone_number = "";

    const users = useUsers();
    const queues = useQueues();
    let elm;
    let onClick;
    let isPhone = false;

    if (phone_number.startsWith("u-")) {
      const [_, u, q] = phone_number.split("-");
      const user = u ? users.dict[u] : null;
      const queue = q ? queues.dict[q] : null;

      elm = (
        <>
          {[
            user && <IconText icon={UserIcon} text={getFullName(user)} />,
            queue && <IconText icon={InboxIcon} text={queue.title} />,
          ]}
        </>
      );
    } else if (phone_number.startsWith("q-")) {
      const [_, q] = phone_number.split("-");
      const queue = q ? queues.dict[q] : null;

      elm = <>{queue ? <IconText icon={InboxIcon} text={queue.title} /> : `QUEUE: ${q}`}</>;
    } else {
      const translated_phonenumber = translatePhoneNumber(phone_number, country);

      if (translated_phonenumber) {
        isPhone = true;
        elm = (
          <DisplayPhoneNumber
            t={t}
            style={style}
            phonenumber={translated_phonenumber}
            iso2={country}
          />
        );
      } else elm = phone_number;
    }

    if (isPhone) {
      style.cursor = "copy";
      onClick = () => navigator.clipboard.writeText(phone_number);
    }

    return (
      <span
        className="display-phone"
        title={isPhone ? `${t?.("CLICK_TO_COPY")} (${phone_number})` : undefined}
        style={style}
        onClick={onClick}
      >
        {elm}
      </span>
    );
  }
);

export const getCountryFlag = (country: string) => {
  return `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/${country.toLowerCase()}.svg`;
};

export const CountryFlagImg = ({
  t,
  country,
  width = 12,
}: {
  t: TFunction;
  country: string;
  width?: number;
}) => {
  if (!t) t = (str) => str;

  return (
    <img
      src={getCountryFlag(country)}
      width={width}
      title={t(`COUNTRY_${country.toUpperCase()}`)}
    />
  );
};

export const CountryFlagTitle = ({
  t,
  country,
  width = 12,
}: {
  t: TFunction;
  country: string;
  width?: number;
}) => {
  return (
    <div style={{ display: "flex" }}>
      <CountryFlagImg t={t} country={country} width={width} />
      <span
        style={{
          paddingLeft: 5,
          maxWidth: 130,
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "block",
        }}
      >
        {t(`COUNTRY_${country.toUpperCase()}`)}
      </span>
    </div>
  );
};

export const phoneNumberValidateAndParse = (phoneNumber: string, countryCode?: CountryCode) => {
  const parsed = phoneNumber
    ? parsePhoneNumber(phoneNumber, { defaultCountry: countryCode })
    : undefined;

  if (["KE"].includes(countryCode as string)) return parsed?.isPossible() ? parsed : undefined;
  return parsed?.isValid() ? parsed : undefined;
};
