import { useState } from "react";
import { Popover, Switch, Button, Space, Divider, Spin } from "antd";
import { Icon } from "@Components";
import { DownloadIcon } from "@Assets";
import { ISharedConversationFilter } from "atlas-shared";
import {
  ConversationFilterSortColumn,
  ConversationFilterSortOrder,
  downloadConversationPayload,
  fetchDownloadConversations,
  Field,
  FieldColumn,
} from "@Api/conversation_filter.api";
import { useTranslation } from "react-i18next";

// Map of field keys to display names
export const FieldColumnEnum = {
  conversation_id: "CONVERSATION_ENUM_ID",
  conversation_email: "CONVERSATION_ENUM_EMAIL",
  conversation_status: "CONVERSATION_ENUM_STATUS",
  conversation_channel: "CONVERSATION_ENUM_CHANNEL",
  conversation_direction: "CONVERSATION_ENUM_DIRECTION",
  conversation_queue_name: "CONVERSATION_ENUM_QUEUE_NAME",
  conversation_agent_name: "CONVERSATION_ENUM_AGENT_NAME",
  conversation_disposition: "CONVERSATION_ENUM_DISPOSITION",
  conversation_created_date: "CONVERSATION_ENUM_CREATED_DATE",
  conversation_caller_id: "CONVERSATION_ENUM_CALLER_ID",
  conversation_organization_id: "CONVERSATION_ENUM_ORGANIZATION_ID",
  conversation_organization_title: "CONVERSATION_ENUM_ORGANIZATION_TITLE",
};

/**
 * Component handles downloading conversations based on selected fields
 * @param filterId Filter ID to download conversations from
 * @returns
 */
export const ConversationFilterDownload = ({
  filterId,
}: {
  filterId?: ISharedConversationFilter["id"];
}) => {
  // State to track selected fields
  const [selectedFields, setSelectedFields] = useState<Record<FieldColumn, boolean>>({
    conversation_id: true,
    conversation_email: true,
    conversation_status: true,
    conversation_channel: true,
    conversation_direction: true,
    conversation_queue_name: true,
    conversation_agent_name: true,
    conversation_disposition: true,
    conversation_created_date: true,
    conversation_caller_id: true,
    conversation_organization_id: true,
    conversation_organization_title: true,
  });

  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleDownload = async () => {
    // isLoading flag to prevent download requests
    // while another is still processing
    if (isLoading) return;

    try {
      // Filter out unselected fields and prepare them for expected API payload
      const fields: Field[] = Object.entries(selectedFields)
        .filter(([_, isSelected]) => isSelected)
        .map(([fieldKey]) => ({
          key: FieldColumnEnum[fieldKey as FieldColumn],
          column: fieldKey as FieldColumn,
        }));

      const payload: downloadConversationPayload = {
        format: "csv",
        sortBy: ConversationFilterSortColumn.CreatedAt,
        order: ConversationFilterSortOrder.Desc,
        limit: 1000,
        offset: 0,
        fields: fields,
      };

      // Initiate download
      setIsLoading(true);
      await fetchDownloadConversations(filterId, payload);
    } catch (error) {
      console.error("Error downloading conversations:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle individual field selection
  const toggleField = (field: FieldColumn) => {
    setSelectedFields((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Select or deselect all fields
  const toggleAll = (select: boolean) => {
    const newState = { ...selectedFields };
    (Object.keys(newState) as FieldColumn[]).forEach((key) => {
      newState[key] = select;
    });
    setSelectedFields(newState);
  };

  const popoverContent = (
    <div style={{ width: "350px" }}>
      {/* Select or deseslect fields */}
      <div style={{ display: "flex", justifyContent: "space-between", margin: "8px 12px" }}>
        <Button size="small" onClick={() => toggleAll(true)}>
          <span style={{ fontSize: "12px" }}>{t("SELECT_ALL")}</span>
        </Button>
        <Button size="small" onClick={() => toggleAll(false)}>
          <span style={{ fontSize: "12px" }}>{t("DESELECT_ALL")}</span>
        </Button>
      </div>

      <Divider style={{ margin: "10px 0" }} />

      {/* Switches for fields */}
      <div
        style={{
          overflow: "auto",
          paddingRight: "4px",
        }}
      >
        {(Object.keys(FieldColumnEnum) as FieldColumn[]).map((field) => (
          <div
            key={field}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "8px 12px",
              cursor: "pointer",
              opacity: 0.7,
              transition: "opacity 0.2s ease",
            }}
            onClick={() => toggleField(field)}
            onMouseEnter={(e) => (e.currentTarget.style.opacity = "1")}
            onMouseLeave={(e) => (e.currentTarget.style.opacity = "0.7")}
          >
            <Switch
              onClick={() => toggleField(field)}
              onChange={() => toggleField(field)}
              checked={selectedFields[field]}
              size="small"
              style={{ marginRight: "8px" }}
            />
            <span style={{ fontSize: "12px" }}>{t(FieldColumnEnum[field])}</span>
          </div>
        ))}
      </div>

      <Divider style={{ margin: "10px 0" }} />

      {/* Buttons for cancel and download */}
      <div style={{ textAlign: "right", margin: "8px 12px" }}>
        <Space>
          <Button size="small" onClick={() => setVisible(false)}>
            <span style={{ fontSize: "12px" }}>{t("CANCEL")}</span>
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={handleDownload}
            disabled={Object.values(selectedFields).every((val) => !val) || isLoading}
          >
            {isLoading ? (
              <Spin
                size="small"
                indicator={
                  <div
                    className="white-spinner"
                    style={{
                      border: "2px solid rgba(255, 255, 255, 0.3)",
                      borderTop: "2px solid white",
                      borderRadius: "50%",
                      width: "10px",
                      height: "10px",
                      animation: "spin 1s linear infinite",
                      display: "inline-block",
                    }}
                  />
                }
              />
            ) : (
              <span style={{ fontSize: "12px" }}>{t("DOWNLOAD")}</span>
            )}
          </Button>
        </Space>
      </div>
    </div>
  );

  return (
    <Popover
      open={visible}
      content={popoverContent}
      title={
        <span style={{ fontWeight: "bold", fontSize: "12px" }}>{t("CONVERSATION_DOWNLOAD")}</span>
      }
      trigger="click"
    >
      <div className="conversation-download">
        <Icon icon={DownloadIcon} onClick={() => setVisible(!visible)} />
      </div>
    </Popover>
  );
};
