"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockSharedUserStatusOne = void 0;
const date_1 = require("../utils/date");
const enums_1 = require("../../utilities/enums");
const utils_1 = require("../utils");
const user_1 = require("./user");
const agentprofile_1 = require("./agentprofile");
const sharedUser = (0, user_1.mockSharedUserOne)();
const base = {
    id: sharedUser.id,
    user_id: sharedUser.id,
    online: date_1.mockDateTime,
    pause_since: null,
    idle_since: null,
    busy_since: null,
    status: enums_1.UserStatusStatusEnum.Idle,
    voice_status: enums_1.UserStatusStatusEnum.Idle,
    mail_status: enums_1.UserStatusStatusEnum.Idle,
    pause_id: null,
    agentprofile_id: (0, agentprofile_1.mockPreviewAgentprofileOne)().id,
    handling_conversation_id: null,
    conversation_filter_id: -1,
    __current_voice_asterisks: [],
    __current_queues: [],
    __current_pauses: [],
};
exports.mockSharedUserStatusOne = (0, utils_1.mockFunction)(Object.assign({}, base));
