"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionVoiceStartRecording = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
exports.JourneyActionVoiceStartRecording = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.VoiceStartRecording,
    channels: [enums_1.ChannelEnum.Voice,],
    direction: enums_1.JourneyNodeDirectionEnum.Output,
    settings_schema: (0, base_1.settingsValidationSchema)({
        journey_variable_id: {
            type: 'number',
            required: true,
        },
        format: {
            type: 'string',
            required: true,
            default: 'wav',
            valid: ['wav', 'gsm',],
        },
    }),
    initialValues: {
        format: 'wav',
    },
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_VOICE_START_RECORDING_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_VOICE_START_RECORDING_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: false,
                index: 0,
                title: 'RECORDING_STARTED',
                color: null,
            }) });
    },
});
