import { copyMailMessageAttachments, createDraft } from "@Api";
import {
  canEmail,
  ChannelEnum,
  DraftChannelEnum,
  IAuthResponse,
  ISharedFullConversation,
  ISharedMailMessage,
  TypeConversationMessage,
  IPreviewQueue,
  Nullable,
  ISharedDraft,
} from "atlas-shared";
import { AlertError } from "@Utils/popup";
import { TFunction } from "i18next";

export const conversationMessageReply = (
  t: TFunction,
  user: IAuthResponse["user"],
  channel: DraftChannelEnum,
  conversation: ISharedFullConversation,
  queue: Nullable<IPreviewQueue>,
  message?: TypeConversationMessage,
  cc: boolean = false,
  forward: boolean = false,
  ai: boolean = false
): Promise<ISharedDraft> => {
  const reply_to_cc =
    message?.channel === ChannelEnum.Mail ? (message as ISharedMailMessage).cc : [];
  const message_channel_available =
    channel === DraftChannelEnum.Mail && conversation.contact && canEmail(user);
  const subject = message
    ? `${forward ? "FWD" : "RE"}: ${
        (message as ISharedMailMessage).subject || conversation.title || ""
      }`
    : "";
  const mail_account_id = (message as ISharedMailMessage)?.mail_account_id;
  const mail_options = queue?.__outbound_mail_accounts || [];
  const reply_account =
    mail_account_id && mail_options.includes(mail_account_id) ? mail_account_id : null;
  let reply_to_address =
    !forward && message_channel_available && conversation.contact?.emails?.length
      ? conversation.contact.emails[0]
      : "-";
  if (message?.channel === ChannelEnum.Mail && reply_to_address === "-" && !forward)
    reply_to_address = (message as ISharedMailMessage).contact_email || reply_to_address;

  if (message?.channel === ChannelEnum.Mail && reply_to_address === "-" && !forward) {
    AlertError(t, { content: t("NO_EMAIL_ON_CONTACT") });
    return Promise.reject();
  }

  const isMailMessage = (message: TypeConversationMessage): message is ISharedMailMessage => {
    return message.channel === ChannelEnum.Mail;
  };

  const request = createDraft(
    {
      body: forward ? " " : "",
      subject: subject?.substr(0, 255) || null,
      account_id: reply_account || (mail_options.length > 0 ? mail_options[0] : null),
      organization_id: conversation.organization_id,
      user_id: user.id,
      conversation_id: conversation.id,
      channel,
      reply_to: message ? `${message.channel}-${message.id}` : null,
      reply_to_address,
      reply_to_cc: cc ? reply_to_cc.map((cc) => cc.address) : [],
    },
    ai
  );

  request.then((draft) => {
    if (forward && message) {
      if (isMailMessage(message) && message.id)
        copyMailMessageAttachments(draft.id, message.id).catch((e) =>
          AlertError(t, { title: e.toString() })
        );
      else AlertError(t, { title: `No message found on forward. ${draft.id}:${channel}` });
    }
  });

  return request;
};
