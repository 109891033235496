"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockFullConversation = void 0;
const contact_1 = require("./contact");
const organization_1 = require("./organization");
const enums_1 = require("../../utilities/enums");
const queue_1 = require("./queue");
const user_1 = require("./user");
const utils_1 = require("../utils");
const voice_call_1 = require("./voice_call");
const mail_message_1 = require("./mail_message");
const internal_note_1 = require("./internal_note");
const draft_1 = require("./draft");
const organization = (0, organization_1.mockChildOrganization)();
const conversation_id = 100000;
const base = Object.assign({ journey: [], contact: (0, contact_1.mockSharedContactOne)(), messages: [
        Object.assign(Object.assign({}, (0, internal_note_1.mockSharedInternalNote)({
            id: 3,
            body: '[[[merge-from={"id":1002220,"organization_id":2,"title":"Outbound Call to 073-698 04 82"}]]]',
        })), { conversation_id }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCallOutbound)({
            id: 10013,
            contact_phone: '+46732222222',
            unique_id: '1655798931.148',
            contact_unique_id: '1655798937.150',
        })), { voice_call_bridges: [], voice_recordings: [], voice_voicemail_messages: [], voice_chanspy_calls: [] }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCallOutbound)({
            id: 10010,
            contact_phone: '+468888888',
            unique_id: '1655798936.149',
            contact_unique_id: '1655798937.150',
        })), { voice_call_bridges: [], voice_recordings: [], voice_voicemail_messages: [], voice_chanspy_calls: [] }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCallIncoming)({
            id: 10012,
            end_time: utils_1.mockDateTime,
            contact_phone: '+46732222222',
            unique_id: '1655798930.147',
            contact_unique_id: '1655798931.148',
        })), { voice_call_bridges: [], voice_recordings: [], voice_voicemail_messages: [], voice_chanspy_calls: [] }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCallOutbound)({
            id: 10010,
            contact_phone: '+468888888',
        })), { voice_call_bridges: [], voice_recordings: [], voice_voicemail_messages: [], voice_chanspy_calls: [] }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCallIncoming)({})), { voice_call_bridges: [], voice_recordings: [], voice_voicemail_messages: [], voice_chanspy_calls: [
                Object.assign({ id: 1, organization_id: organization.id, user_id: (0, user_1.mockSharedUserOne)().id, voice_chanspy_id: 1, voice_call_id: 10000, flags: 'qa', start_time: utils_1.mockDateTime }, utils_1.mockDateObject),
            ] }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCall)({
            organization_id: organization.id,
            conversation_id,
        })), { voice_recordings: [
                {
                    id: 2,
                    organization_id: organization.id,
                    conversation_id,
                    user_id: null,
                    voice_call_id: 10000,
                    voice_call_bridge_id: null,
                    created_at: utils_1.mockDateTime,
                    stopped_at: null,
                    updated_at: utils_1.mockDateTime,
                    deleted_at: null,
                    filename: 'null',
                    voice_recording_transcript_id: null,
                    anonymized_at: null,
                },
            ], voice_voicemail_messages: [
                Object.assign({ id: 1, organization_id: organization.id, conversation_id, voice_call_id: 10000, end_time: null, anonymized_at: null }, utils_1.mockDateObject),
            ], voice_call_bridges: [], voice_chanspy_calls: [] }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCallIncoming2)({
            organization_id: organization.id,
            conversation_id,
        })), { voice_call_bridges: [], voice_recordings: [], voice_voicemail_messages: [], voice_chanspy_calls: [] }),
        Object.assign({}, (0, mail_message_1.mockSharedMailMessage)({
            organization_id: organization.id,
            conversation_id,
            direction: enums_1.DirectionEnum.Outbound,
            reply_to: 100020,
        })),
        Object.assign(Object.assign({}, (0, internal_note_1.mockSharedInternalNote)({
            id: 2,
        })), { conversation_id }),
        Object.assign(Object.assign({}, (0, internal_note_1.mockSharedInternalNote)({
            id: 1,
            reply_to: 'M-100020',
            body: '<p>An internal note reply</p>',
        })), { conversation_id }),
        Object.assign({}, (0, mail_message_1.mockSharedMailMessage)({
            id: 100020,
            organization_id: organization.id,
            conversation_id,
        })),
        Object.assign({}, (0, mail_message_1.mockSharedMailMessage)({
            organization_id: organization.id,
            conversation_id,
            direction: enums_1.DirectionEnum.Outbound,
        })),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCallIncoming2)({
            organization_id: organization.id,
            conversation_id,
            end_time: utils_1.mockDateTime,
        })), { voice_call_bridges: [], voice_recordings: [], voice_voicemail_messages: [], voice_chanspy_calls: [] }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCallOutbound)({
            organization_id: organization.id,
            conversation_id,
        })), { voice_call_bridges: [], voice_recordings: [], voice_voicemail_messages: [], voice_chanspy_calls: [] }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCallOutbound)({
            organization_id: organization.id,
            conversation_id,
            end_time: utils_1.mockDateTime,
        })), { voice_call_bridges: [], voice_recordings: [], voice_voicemail_messages: [], voice_chanspy_calls: [] }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCallOutbound)({
            organization_id: organization.id,
            conversation_id,
            end_time: null,
        })), { voice_call_bridges: [], voice_recordings: [], voice_voicemail_messages: [], voice_chanspy_calls: [] }),
        Object.assign(Object.assign({}, (0, voice_call_1.mockSharedVoiceCall2)({
            organization_id: organization.id,
            conversation_id,
        })), { voice_recordings: [
                {
                    id: 1,
                    organization_id: organization.id,
                    conversation_id,
                    user_id: null,
                    voice_call_id: 10001,
                    voice_call_bridge_id: null,
                    created_at: utils_1.mockDateTime,
                    stopped_at: null,
                    updated_at: utils_1.mockDateTime,
                    deleted_at: null,
                    filename: 'null',
                    voice_recording_transcript_id: null,
                    anonymized_at: null,
                },
            ], voice_call_bridges: [], voice_voicemail_messages: [], voice_chanspy_calls: [] }),
    ], drafts: [
        Object.assign(Object.assign({}, (0, draft_1.mockSharedDraft)()), { reply_to: 'M-100020', conversation_id }),
        Object.assign(Object.assign({}, (0, draft_1.mockSharedDraft)()), { user_id: (0, user_1.mockPreviewUserTwo)().id, body: '<p>Note by another user</p>', reply_to: 'M-100020', conversation_id }),
    ], id: conversation_id, organization_id: organization.id, title: 'Conversation One', main_channel: enums_1.ChannelEnum.Voice, queue_id: (0, queue_1.mockSharedQueue)().id, user_id: (0, user_1.mockSharedUserOne)().id, journey_id: null, sub_journies: [], disposition_id: null, disposition_note: 'A regular disposition note', data: {}, status: enums_1.ConversationStatusEnum.Open, status_at: utils_1.mockDateTime, flag: null, read: 3, unread: 4, sla_policy_id: null, priority: enums_1.ConversationPriorityEnum.Medium, response_time: null, resolution_time: null, direction: enums_1.DirectionEnum.InBound, channels: [{ c: enums_1.ChannelEnum.Voice, d: enums_1.DirectionEnum.InBound, },], last_message_at: utils_1.mockDateTime, last_message_by: enums_1.ConversationLastMessageByEnum.Contact, first_assigned_at: utils_1.mockDateTime, assigned_at: utils_1.mockDateTime, __tags: [], is_new: true }, utils_1.mockDateObject);
exports.mockFullConversation = (0, utils_1.mockFunction)(Object.assign({}, base));
