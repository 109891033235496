import { useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { TFunction } from "react-i18next";
import { ISharedCreateConversation, ISharedDraft, ISharedUser } from "atlas-shared";
import { createConversation } from "@Api";
import { AlertError } from "@Utils";
import moment from "moment";

interface UseConversationSubmitProps {
  values: ISharedCreateConversation;
  draft: ISharedDraft | undefined;
  typing_started_at: Date;
  navigate: NavigateFunction;
  user: ISharedUser;
  t: TFunction;
  onClose?: () => void;
}

export const useConversationSubmit = ({
  user,
  draft,
  typing_started_at,
  navigate,
  t,
  onClose,
}: UseConversationSubmitProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<Error>();

  const handleSubmit = async (values: Partial<ISharedCreateConversation>) => {
    const initialValues: Partial<ISharedCreateConversation> = {
      client_id: null,
      data: {}, // TODO: Get real data
      organization_id: user.__organizations[0]?.organization_id,
      user_id: user.id,
      typing_started_at: moment(typing_started_at).format(),
      reply_to_cc: [],
      reply_to_bcc: [],
      __tags: [],
    };

    if (!draft?.id) {
      setError(new Error("No draft available"));
      return false;
    }

    if (!initialValues.organization_id) {
      setError(new Error("No organization selected"));
      return false;
    }

    setIsSubmitting(true);
    setError(undefined);

    try {
      const completeValues = {
        ...initialValues,
        ...values,
        client_id: values.client_id ?? null,
      } as ISharedCreateConversation;

      const conversation = await createConversation(completeValues, draft.id);

      navigate(`/dashboard/conversation/${conversation.organization_id}/${conversation.id}`);
      onClose?.();
      return true;
    } catch (err) {
      const error = err instanceof Error ? err : new Error(String(err));
      setError(error);
      AlertError(t, {
        content: error.message,
      });
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleSubmit,
    isSubmitting,
    error,
  };
};
