import { useEffect, useState, useCallback } from "react";

export function useAsync<T>(
  callback: (...args: any[]) => Promise<T>,
  { skip = false }: { skip?: boolean } = {}
) {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasBeenCalled, setHasBeenCalled] = useState<boolean>(false);

  const execute = useCallback(async () => {
    if (skip) return;

    try {
      setLoading(true);
      setHasBeenCalled(true);
      const result = await callback();
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [callback, skip]);

  const reset = useCallback(() => {
    setData(null);
    setError(null);
    setLoading(false);
    setHasBeenCalled(false);
  }, []);

  useEffect(() => {
    if (hasBeenCalled || skip) return;
    execute();
  }, [execute, hasBeenCalled, skip]);

  return skip
    ? { data: null, error: null, loading: false, execute: () => {}, reset: () => {} }
    : { data, error, loading, refetch: execute, reset };
}
