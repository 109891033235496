import { JourneyActionEnum, Metrics, GroupMetrics } from "atlas-shared";

export const no = {
  ...Object.fromEntries(
    [...Metrics, ...GroupMetrics]
      .flatMap((metric) => [
        metric.title ? [`METRIC_${metric.alias}`, metric.title] : false,
        metric.description ? [`METRIC_${metric.alias}_DESCRIPTION`, metric.description] : false,
      ])
      .filter(Boolean) as Array<[string, string]>
  ),
  COUNTRY_AF: "Afghanistan",
  COUNTRY_AX: "Åland Islands",
  COUNTRY_AL: "Albania",
  COUNTRY_DZ: "Algeria",
  COUNTRY_AS: "American Samoa",
  COUNTRY_AD: "Andorra",
  COUNTRY_AO: "Angola",
  COUNTRY_AI: "Anguilla",
  COUNTRY_AQ: "Antarctica",
  COUNTRY_AG: "Antigua and Barbuda",
  COUNTRY_AR: "Argentina",
  COUNTRY_AM: "Armenia",
  COUNTRY_AW: "Aruba",
  COUNTRY_AU: "Australia",
  COUNTRY_AT: "Austria",
  COUNTRY_AZ: "Azerbaijan",
  COUNTRY_BS: "Bahamas",
  COUNTRY_BH: "Bahrain",
  COUNTRY_BD: "Bangladesh",
  COUNTRY_BB: "Barbados",
  COUNTRY_BY: "Belarus",
  COUNTRY_BE: "Belgium",
  COUNTRY_BZ: "Belize",
  COUNTRY_BJ: "Benin",
  COUNTRY_BM: "Bermuda",
  COUNTRY_BT: "Bhutan",
  COUNTRY_BO: "Bolivia",
  COUNTRY_BA: "Bosnia and Herzegovina",
  COUNTRY_BW: "Botswana",
  COUNTRY_BV: "Bouvet Island",
  COUNTRY_BR: "Brazil",
  COUNTRY_IO: "British Indian Ocean Territory",
  COUNTRY_BN: "Brunei Darussalam",
  COUNTRY_BG: "Bulgaria",
  COUNTRY_BF: "Burkina Faso",
  COUNTRY_BI: "Burundi",
  COUNTRY_KH: "Cambodia",
  COUNTRY_CM: "Cameroon",
  COUNTRY_CA: "Canada",
  COUNTRY_CV: "Cape Verde",
  COUNTRY_KY: "Cayman Islands",
  COUNTRY_CF: "Central African Republic",
  COUNTRY_TD: "Chad",
  COUNTRY_CL: "Chile",
  COUNTRY_CN: "China",
  COUNTRY_CX: "Christmas Island",
  COUNTRY_CC: "Cocos (Keeling) Islands",
  COUNTRY_CO: "Colombia",
  COUNTRY_KM: "Comoros",
  COUNTRY_CG: "Congo",
  COUNTRY_CD: "Congo, The Democratic Republic of the",
  COUNTRY_CK: "Cook Islands",
  COUNTRY_CR: "Costa Rica",
  COUNTRY_CI: "Cote D'Ivoire",
  COUNTRY_HR: "Croatia",
  COUNTRY_CU: "Cuba",
  COUNTRY_CY: "Cyprus",
  COUNTRY_CZ: "Czech Republic",
  COUNTRY_DG: "Diego Garcia",
  COUNTRY_DK: "Denmark",
  COUNTRY_DJ: "Djibouti",
  COUNTRY_DM: "Dominica",
  COUNTRY_DO: "Dominican Republic",
  COUNTRY_EC: "Ecuador",
  COUNTRY_EG: "Egypt",
  COUNTRY_SV: "El Salvador",
  COUNTRY_GQ: "Equatorial Guinea",
  COUNTRY_ER: "Eritrea",
  COUNTRY_EE: "Estonia",
  COUNTRY_ET: "Ethiopia",
  COUNTRY_FK: "Falkland Islands (Malvinas)",
  COUNTRY_FO: "Faroe Islands",
  COUNTRY_FJ: "Fiji",
  COUNTRY_FI: "Finland",
  COUNTRY_FR: "France",
  COUNTRY_GF: "French Guiana",
  COUNTRY_PF: "French Polynesia",
  COUNTRY_TF: "French Southern Territories",
  COUNTRY_GA: "Gabon",
  COUNTRY_GM: "Gambia",
  COUNTRY_GE: "Georgia",
  COUNTRY_DE: "Germany",
  COUNTRY_GH: "Ghana",
  COUNTRY_GI: "Gibraltar",
  COUNTRY_GR: "Greece",
  COUNTRY_GL: "Greenland",
  COUNTRY_GD: "Grenada",
  COUNTRY_GP: "Guadeloupe",
  COUNTRY_GU: "Guam",
  COUNTRY_GT: "Guatemala",
  COUNTRY_GG: "Guernsey",
  COUNTRY_GN: "Guinea",
  COUNTRY_GW: "Guinea-Bissau",
  COUNTRY_GY: "Guyana",
  COUNTRY_HT: "Haiti",
  COUNTRY_HM: "Heard Island and Mcdonald Islands",
  COUNTRY_VA: "Holy See (Vatican City State)",
  COUNTRY_HN: "Honduras",
  COUNTRY_HK: "Hong Kong",
  COUNTRY_HU: "Hungary",
  COUNTRY_IS: "Iceland",
  COUNTRY_IN: "India",
  COUNTRY_ID: "Indonesia",
  COUNTRY_IR: "Iran, Islamic Republic Of",
  COUNTRY_IQ: "Iraq",
  COUNTRY_IE: "Ireland",
  COUNTRY_IM: "Isle of Man",
  COUNTRY_IL: "Israel",
  COUNTRY_IT: "Italy",
  COUNTRY_JM: "Jamaica",
  COUNTRY_JP: "Japan",
  COUNTRY_JE: "Jersey",
  COUNTRY_JO: "Jordan",
  COUNTRY_KZ: "Kazakhstan",
  COUNTRY_KE: "Kenya",
  COUNTRY_KI: "Kiribati",
  COUNTRY_KP: "Korea, Democratic People'S Republic of",
  COUNTRY_KR: "Korea, Republic of",
  COUNTRY_XK: "Kosovo",
  COUNTRY_KW: "Kuwait",
  COUNTRY_KG: "Kyrgyzstan",
  COUNTRY_LA: "Lao People'S Democratic Republic",
  COUNTRY_LV: "Latvia",
  COUNTRY_LB: "Lebanon",
  COUNTRY_LS: "Lesotho",
  COUNTRY_LR: "Liberia",
  COUNTRY_LY: "Libyan Arab Jamahiriya",
  COUNTRY_LI: "Liechtenstein",
  COUNTRY_LT: "Lithuania",
  COUNTRY_LU: "Luxembourg",
  COUNTRY_LX: "Luxembourg (Special)",
  COUNTRY_MO: "Macao",
  COUNTRY_MK: "Macedonia, The Former Yugoslav Republic of",
  COUNTRY_MG: "Madagascar",
  COUNTRY_MW: "Malawi",
  COUNTRY_MY: "Malaysia",
  COUNTRY_MV: "Maldives",
  COUNTRY_ML: "Mali",
  COUNTRY_MT: "Malta",
  COUNTRY_MH: "Marshall Islands",
  COUNTRY_MQ: "Martinique",
  COUNTRY_MR: "Mauritania",
  COUNTRY_MU: "Mauritius",
  COUNTRY_YT: "Mayotte",
  COUNTRY_MX: "Mexico",
  COUNTRY_FM: "Micronesia, Federated States of",
  COUNTRY_MD: "Moldova, Republic of",
  COUNTRY_ME: "Montenegro",
  COUNTRY_MF: "Saint Martin",
  COUNTRY_MC: "Monaco",
  COUNTRY_MN: "Mongolia",
  COUNTRY_MS: "Montserrat",
  COUNTRY_MA: "Morocco",
  COUNTRY_MZ: "Mozambique",
  COUNTRY_MM: "Myanmar",
  COUNTRY_NA: "Namibia",
  COUNTRY_NR: "Nauru",
  COUNTRY_NP: "Nepal",
  COUNTRY_NL: "Netherlands",
  COUNTRY_AN: "Netherlands Antilles",
  COUNTRY_NC: "New Caledonia",
  COUNTRY_NZ: "New Zealand",
  COUNTRY_NI: "Nicaragua",
  COUNTRY_NE: "Niger",
  COUNTRY_NG: "Nigeria",
  COUNTRY_NU: "Niue",
  COUNTRY_NF: "Norfolk Island",
  COUNTRY_MP: "Northern Mariana Islands",
  COUNTRY_NO: "Norway",
  COUNTRY_OM: "Oman",
  COUNTRY_PK: "Pakistan",
  COUNTRY_PW: "Palau",
  COUNTRY_PS: "Palestinian Territory, Occupied",
  COUNTRY_PA: "Panama",
  COUNTRY_PG: "Papua New Guinea",
  COUNTRY_PY: "Paraguay",
  COUNTRY_PE: "Peru",
  COUNTRY_PH: "Philippines",
  COUNTRY_PN: "Pitcairn",
  COUNTRY_PL: "Poland",
  COUNTRY_PT: "Portugal",
  COUNTRY_PR: "Puerto Rico",
  COUNTRY_QA: "Qatar",
  COUNTRY_RE: "Reunion",
  COUNTRY_RO: "Romania",
  COUNTRY_RS: "Serbia",
  COUNTRY_RU: "Russian Federation",
  COUNTRY_RW: "Rwanda",
  COUNTRY_SH: "Saint Helena",
  COUNTRY_KN: "Saint Kitts and Nevis",
  COUNTRY_LC: "Saint Lucia",
  COUNTRY_PM: "Saint Pierre and Miquelon",
  COUNTRY_VC: "Saint Vincent and the Grenadines",
  COUNTRY_WS: "Samoa",
  COUNTRY_SM: "San Marino",
  COUNTRY_ST: "Sao Tome and Principe",
  COUNTRY_SA: "Saudi Arabia",
  COUNTRY_SN: "Senegal",
  COUNTRY_CS: "Serbia and Montenegro",
  COUNTRY_SC: "Seychelles",
  COUNTRY_SL: "Sierra Leone",
  COUNTRY_SG: "Singapore",
  COUNTRY_SK: "Slovakia",
  COUNTRY_SI: "Slovenia",
  COUNTRY_SB: "Solomon Islands",
  COUNTRY_SO: "Somalia",
  COUNTRY_SS: "South Sudan",
  COUNTRY_SX: "Sint Maarten",
  COUNTRY_ZA: "South Africa",
  COUNTRY_GS: "South Georgia and the South Sandwich Islands",
  COUNTRY_ES: "Spain",
  COUNTRY_LK: "Sri Lanka",
  COUNTRY_SD: "Sudan",
  COUNTRY_SR: "Suriname",
  COUNTRY_SJ: "Svalbard and Jan Mayen",
  COUNTRY_SZ: "Swaziland",
  COUNTRY_SE: "Sweden",
  COUNTRY_CH: "Switzerland",
  COUNTRY_SY: "Syrian Arab Republic",
  COUNTRY_TA: "Tristan da Cunha",
  COUNTRY_TW: "Taiwan, Province of China",
  COUNTRY_TJ: "Tajikistan",
  COUNTRY_TZ: "Tanzania, United Republic of",
  COUNTRY_TH: "Thailand",
  COUNTRY_TL: "Timor-Leste",
  COUNTRY_TG: "Togo",
  COUNTRY_TK: "Tokelau",
  COUNTRY_TO: "Tonga",
  COUNTRY_TP: "East Timor",
  COUNTRY_TT: "Trinidad and Tobago",
  COUNTRY_TN: "Tunisia",
  COUNTRY_TR: "Turkey",
  COUNTRY_TM: "Turkmenistan",
  COUNTRY_TC: "Turks and Caicos Islands",
  COUNTRY_TV: "Tuvalu",
  COUNTRY_UG: "Uganda",
  COUNTRY_UA: "Ukraine",
  COUNTRY_AE: "United Arab Emirates",
  COUNTRY_GB: "United Kingdom",
  COUNTRY_US: "United States",
  COUNTRY_UM: "United States Minor Outlying Islands",
  COUNTRY_UY: "Uruguay",
  COUNTRY_UZ: "Uzbekistan",
  COUNTRY_VU: "Vanuatu",
  COUNTRY_VE: "Venezuela",
  COUNTRY_VN: "Viet Nam",
  COUNTRY_VG: "Virgin Islands, British",
  COUNTRY_VI: "Virgin Islands, U.S.",
  COUNTRY_WF: "Wallis and Futuna",
  COUNTRY_EH: "Western Sahara",
  COUNTRY_YE: "Yemen",
  COUNTRY_ZM: "Zambia",
  COUNTRY_ZW: "Zimbabwe",
  COUNTRY_AC: "Ascension Island",
  COUNTRY_BL: "Saint Martin country",
  COUNTRY_BQ: "Netherlands Antilles",
  COUNTRY_CW: "Netherlands Antilles",

  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceInbound}`]: "Innkommende taleanrop ble opprettet",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceOutbound}`]:
    "Utgående taleanrop ble avsluttet av agent",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceAnswer}`]: "Svar på taleanrop",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceExternalDial}`]: "Ring eksternt nummer",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceGetDigits}`]: "Hent tall fra innringer",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceHangup}`]: "Legg på taleanrop",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceInternalDial}`]: "Internt anrop til agenter eller køer",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceMenu}`]: "Talemeny med alternativer",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoicePlayback}`]: "Spill av lyd for kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayAlpha}`]: "Les tekst for kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayDigits}`]: "Les tall for kontakt (ett om gangen)",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVariableSet}`]: "Still inn en talevariabel",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayNumber}`]: "Les nummer for kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayPhonetic}`]: "Les bokstaver for kontakt",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStartRecording}`]: "Start opptak av samtale",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStopRecording}`]: "Stopp spesifikt samtaleopptak",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailInbound}`]:
    "Innkommende e-postsamtale ble opprettet",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailOutbound}`]: "Utgående e-postsamtale ble opprettet",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartForm}`]: "Samtale opprettet gjennom skjemainnsending",
  [`NODE_SUBTITLE_${JourneyActionEnum.OperationalHours}`]: "Innenfor eller utenfor åpningstider",
  [`NODE_SUBTITLE_${JourneyActionEnum.MailAutoResponse}`]: "Autosvar e-post",
  [`NODE_SUBTITLE_${JourneyActionEnum.Queue}`]: "Tildel til kø",
  [`NODE_SUBTITLE_${JourneyActionEnum.Client}`]: "Legg til klient i samtale",
  [`NODE_SUBTITLE_${JourneyActionEnum.UnAssign}`]: "Fjern tildeling av kø og/eller bruker",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceQueue}`]: "Tildel til kø med talehåndtering",
  [`NODE_SUBTITLE_${JourneyActionEnum.Agent}`]: "Tildel direkte til agent",
  [`NODE_SUBTITLE_${JourneyActionEnum.Timeout}`]: JourneyActionEnum.Timeout + " standardundertekst",
  [`NODE_SUBTITLE_${JourneyActionEnum.RestRequest}`]: "Gjør en REST-forespørsel",
  [`NODE_SUBTITLE_${JourneyActionEnum.FetchContactData}`]: "Hent kontaktdata via REST",
  [`NODE_SUBTITLE_${JourneyActionEnum.EditConversation}`]: "Rediger samtaleegenskaper",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseConversation}`]: "Avslutt og lukk samtale",
  [`NODE_SUBTITLE_${JourneyActionEnum.AddInternalNote}`]: "Legg til internt notat i samtale",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseTabs}`]: "Lukk faner automatisk fra reise",
  [`NODE_SUBTITLE_${JourneyActionEnum.SendMail}`]: "Send e-post",
  [`NODE_SUBTITLE_${JourneyActionEnum.ForwardMail}`]: "Videresend e-post",
  [`NODE_SUBTITLE_${JourneyActionEnum.ReplyLatestInboundMessage}`]:
    "Svar på siste innkommende e-post eller skjemainnsending",
  [`NODE_SUBTITLE_${JourneyActionEnum.Journey}`]: "Underreise",
  [`NODE_SUBTITLE_${JourneyActionEnum.Rule}`]: "Definer betingelsesregler",
  [`NODE_SUBTITLE_${JourneyActionEnum.RuleContact}`]: "Definer kontaktbetingelsesregler",
  [`NODE_SUBTITLE_${JourneyActionEnum.NoOp}`]: "Ingen handling",
  [`NODE_SUBTITLE_${JourneyActionEnum.When}`]: "Utløses ved",
  [`NODE_SUBTITLE_${JourneyActionEnum.SetField}`]: "Still inn samtalefeltverdi",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSetLanguage}`]: "Still inn taleanropets språk",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVoicemail}`]: "Spill inn talemelding",
  [`NODE_SUBTITLE_${JourneyActionEnum.OpenBrowserTab}`]: "Åpne nettleserfane",

  NODE_TITLE_DEFAULT: "Standardtittel for node",
  NODE_DESCRIPTION_DEFAULT: "Standardbeskrivelse for node",
  LOADING: "Laster...",
  CUSTOMER_JOURNEY: "Kundereise",
  CONVERSATION: "Samtale",
  CONVERSATIONS: "Samtaler",
  CUSTOMER: "Kunde",
  CUSTOMERS: "Kunder",
  STATISTICS: "Statistikk",
  REPLY_IN: "Svar i",
  REPLY: "Svar",
  AGO: "siden",
  SHOWING: "Viser",
  STATS: "Statistikk",
  CLOSE: "Lukk",
  CLOSED: "Lukket",
  OPEN: "Åpne",
  DRAFT_REPLY_MESSAGE_CONFIRM: "Vil du lagre endringene dine?",
  TODAY: "I dag",
  YES: "Ja",
  NO: "Nei",
  CONFIRM: "Bekreft",
  NEW_MESSAGE: "Ny melding",
  TO: "Til",
  DRAFT: "Utkast",
  SEND_REPLY: "Send svar",
  SAVE: "Lagre",
  TYPE: "Type",
  ALL: "Alle",
  TICKETS: "Saker",
  CONTACTS: "Kontakter",
  SOLUTIONS: "Løsninger",
  FORUMS: "Forum",
  TEAM_NAME: "Teamnavn",
  ANOTHER_TEAM: "Annet team",
  THIRD_TEAM_NAME: "Tredje teamnavn",
  OTHER_TEAM_NAME: "Annet teamnavn",
  FAVORITES: "Favoritter",
  CHAT: "Chat",
  CALL: "Anrop",
  RETURN: "Tilbake",
  SEND: "Send",
  RECENTLY_SEARCHED: "Nylig søkt",
  RECENTLY_VIEWED: "Nylig vist",
  CLEAR: "Tøm",
  ASSIGN: "Tildel",
  CHOOSE: "velg",
  AGENT: "Agent",
  ADMIN: "Admin",
  SUPERADMIN: "Superadmin",
  QUEUES: "Køer",
  QUEUE: "Kø",
  DONE: "Ferdig",
  IN: "i",
  SEARCH: "Søk",
  AND: "Og",
  PREVIOUS_ACTIVITY: "Tidligere aktivitet",
  VIEW: "Vis",
  BY: "av",
  SUPPORT: "Support",
  GENERAL: "Generelt",
  WRITE: "Skriv",
  HERE: "her",
  ADD: "Legg til",
  NEW: "Ny",
  AGENTS: "Agenter",
  EDIT: "Rediger",
  TOOLS: "Verktøy",
  EMAIL: "E-post",
  CANCEL: "Avbryt",
  UNAUTHORIZED: "Brukernavn eller passord stemmer ikke",
  OR: "Eller",
  DISPOSE: "Avslutt",
  CHOOSE_REASON_FOR_DISPOSAL: "Velg årsak for avslutning",
  WRITE_A_COMMENT: "Skriv en kommentar",
  USERNAME: "Brukernavn",
  DROP_HERE: "Slipp her",
  PASSWORD: "Passord",
  FORGOT_YOUR_PASSWORD: "Glemt passordet ditt",
  RESET_PASSWORD: "Tilbakestill passord",
  REMEMBER_ME: "Husk meg",
  SIGN_IN: "Logg inn",
  SIGN_OUT: "Logg ut",
  DISPLAY_DELETED: "Vis slettede",
  GLOBAL: "Global",
  WORK_TIME: "Arbeidstid",
  INVOICEABLE: "Fakturerbar",
  TITLE: "Tittel",
  ORGANIZATION_NOT_FOUND: 'Organisasjon med id "{{organization_id}}" ble ikke funnet!',
  BETWEEN_FROM_AND_TO: "Mellom {{from}} og {{to}}",
  FROM_FROM_TO_TO: "Fra {{from}} til {{to}}",
  FROM_THROUGH_TO: "{{from}} til {{to}}",
  FROM_THROUGH_TO_OF_MONTH: "{{from}} til {{to}} i måneden",
  ON_FROM: "Den {{from}}",
  ON_FROM_OF_MONTH: "Den {{from}} i måneden",
  EVERY_SPECIFIC_DAY: "Hver {{from}}",
  ALL_DAY: "Hele dagen",
  EVERY_DAY: "Hver dag",

  ROLE: "Rolle",
  FIRST_NAME: "Fornavn",
  LAST_NAME: "Etternavn",
  AVATAR: "Profilbilde",

  ORGANIZATIONS: "Organisasjoner",
  ORGANIZATION: "Organisasjon",
  USER: "Bruker",
  USERS: "Brukere",
  ADMINPROFILES: "Adminprofiler",
  ADMINPROFILE: "Adminprofil",
  SUPERADMIN_PROFILES: "Superadminprofiler",
  SUPERADMINPROFILES: "Superadminprofiler",
  SUPERADMINPROFILE: "Superadminprofil",
  SUPERADMIN_PROFILE: "Superadminprofil",
  AGENTPROFILES: "Agentprofiler",
  AGENTPROFILE: "Agentprofil",
  TEAMS: "Team",
  MAIL_ACCOUNTS: "E-postkontoer",
  MAIL_ACCOUNT: "E-postkonto",
  LISTS: "Lister",
  LIST: "Liste",
  JOURNEYS: "Reiser",
  JOURNEY: "Reise",
  PAUSES: "Pauser",
  PAUSE: "Pause",
  DISPOSITIONS: "Disposisjoner",
  DISPOSITION: "Disposisjon",
  FIELDS: "Felt",
  FIELD: "Felt",
  FORM_ERROR_STRING__EMPTY: "Kan ikke være tom",
  FORM_ERROR_ARRAY__MIN: "Minst {{min}} alternativer kreves",
  FORM_ERROR_ARRAY__MAX: "Maksimalt {{max}} alternativer tillatt",
  FORM_ERROR_ARRAY__LENGTH: "{{lngth}} alternativer kreves",
  FORM_ERROR_ANY__REQUIRED: "Obligatorisk",
  FORM_ERROR_NUMBER__BASE: "Gyldig nummer kreves",
  FORM_ERROR_STRING__BASE: "Gyldig tekst kreves",
  FORM_ERROR_VALID_OPTION: "Velg et alternativ",
  FORM_ERROR_ANY__ONLY: "Velg et gyldig alternativ",
  FORM_ERROR_STRING__URI: "Ugyldig uri",
  FORM_ERROR_REF_NOT_FOUND: "Et gyldig alternativ må velges",
  FORM_ERROR_INVALID_PHONE: "Ugyldig telefonnummer",
  FORM_ERROR_NUMBER__MIN: "Nummeret må være større enn eller lik {{min}}",
  FORM_ERROR_NUMBER__MAX: "Nummeret må være mindre enn eller lik {{max}}",
  FORM_ERROR_STRING__MIN: "Må være minst {{min}} tegn langt",
  FORM_ERROR_STRING__MAX: "Kan være maksimalt {{max}} tegn langt",
  FORM_ERROR_STRING__LENGTH: "Må være nøyaktig {{lngth}} tegn langt",
  FORM_ERROR_STRING__EMAIL: "Ugyldig e-postadresse",
  FORM_ERROR_STRING__ALPHANUM: "Kun alfanumeriske verdier!",
  FORM_ERROR_USERNAME_INVALID_CHARS:
    "Kan kun inneholde små bokstaver, tall og spesialtegnene . (punktum) og - (bindestrek)",
  FORM_ERROR_STRING__PATTERN__BASE: "Strengen matcher ikke mønsteret {{pattern}}",
  FORM_ERROR_DATE__BASE: "Velg en gyldig dato",
  FORM_ERROR_STRING__IPVERSION: "Ugyldig IP-adresse",

  GLOBAL_SEARCH_PLACEHOLDER: "Søk samtaler & kontakter",
  ADD_ITEM: "Legg til element",
  MAIL_CAPACITY: "E-postkapasitet",
  __ORGANIZATIONS: "Organisasjoner",
  __ORGANIZATIONS__ORGANIZATION_ID: "Organisasjon",
  __ORGANIZATIONS__ROLE: "Rolle",
  __ORGANIZATIONS__ADMINPROFILE_ID: "Adminprofil",
  __ORGANIZATIONS__SUPERADMIN_PROFILE_ID: "Superadminprofil",
  SUBMIT: "Send",
  SIGN_IN_WITH_GOOGLE: "Logg inn med Google",
  SIGN_IN_WITH_MICROSOFT: "Logg inn med Microsoft",
  OPEN_AUTHENTICATOR_AND_SCAN: "Åpne autentiseringsappen din og skann QR-koden",
  OPEN_AUTHENTICATOR: "Åpne autentiseringsappen din og angi koden",
  ENTER_6_DIGIT_CODE: "Angi 6-sifret kode",
  FILL_USERNAME_TO_RESET_PASSWORD: "Fyll inn brukernavn for å tilbakestille passord",
  SOMETHING_WENT_WRING: "Noe gikk galt, prøv igjen senere!",

  ENTRY: "Inngang",
  ASSIGNMENT: "Tildeling",
  MAIL: "E-post",
  StartMailInbound: "Start e-post (Innkommende)",
  StartMailOutbound: "Start e-post (Utgående)",
  StartVoiceInbound: "Start taleanrop (Innkommende)",
  StartVoiceOutbound: "Start taleanrop (Utgående)",
  VoiceAnswer: "Svar",
  VoiceExternalDial: "Eksternt anrop",
  VoiceGetDigits: "Hent tall",
  VoiceQueue: "Talekø",
  VoiceVariableSet: "Still inn variabel",
  VoiceHangup: "Legg på",
  VoiceInternalDial: "Internt anrop",
  VoiceMenu: "Talemeny",
  VoicePlayback: "Avspilling",
  VoiceSayAlpha: "Si alfabetisk",
  VoiceSayNumber: "Si nummer",
  VoiceSayPhonetic: "Si fonetisk",
  VoiceSayDigits: "Si tall",
  VoiceStartRecording: "Start opptak",
  VoiceStopRecording: "Stopp opptak",
  VoiceVoicemail: "Talemelding",
  StartForm: "Start skjema",
  Queue: "Kø",
  EditConversation: "Rediger samtale",
  CloseConversation: "Avslutt samtale",
  AddInternalNote: "Legg til internt notat",
  CloseTabs: "Lukk faner",
  SendMail: "Send e-post",
  ForwardMail: "Videresend e-post",
  ReplyLatestInboundMessage: "Svar på siste innkommende melding",
  MailAutoResponse: "Automatisk e-postsvar",
  RestRequest: "REST-forespørsel",
  FetchContactData: "Hent kontaktdata",
  SetField: "Still inn feltdata",
  VoiceSetLanguage: "Still inn språk",
  Rule: "Regel",
  RuleContact: "Regel (kontakt)",
  NoOp: "Ingen handling",
  OperationalHours: "Åpningstider",
  Journey: "Journey",
  MY_FILTERS: "Mine filtre",
  CREATE_NEW_FILTER: "Opprett nytt filter",
  NEW_FILTER: "Nytt filter",
  DEFAULT_FILTER: "Standardfilter",
  STATUSES: "Status",
  CHANNELS: "Kanaler",
  PRIORITIES: "Prioriteringer",
  TAGS: "Etiketter",
  LAST_MESSAGE_AT: "Siste melding",
  LAST_INBOUND_MESSAGE_AT: "Siste innkommende melding",
  LAST_OUTBOUND_MESSAGE_AT: "Siste utgående melding",
  CREATED_AT: "Opprettet",
  CONTACT: "Kontakt",
  __CONTACT_FIRST_NAME: "Fornavn",
  __CONTACT_LAST_NAME: "Etternavn",
  __CONTACT_PHONES: "Telefonnummer",
  __CONTACT_EMAILS: "E-postadresser",
  SETTINGS: "Innstillinger",
  PROFILES: "Profiler",
  NOTIFICATIONS: "Varsler",
  NewAssignment: "Ny tildeling",
  NewResponseOnMyConversation: "Nytt svar på mine samtaler",
  ConversationMessagesImTaggedIn: "Samtalemeldinger jeg er tagget i",
  StatusUpdateOnMyConversation: "Statusoppdatering på min samtale",
  ConversationAssignedToMe: "Samtale tildelt til meg",
  ConversationAssignedToMyQueues: "Samtale tildelt til mine køer",
  ReminderOnFirstResponse: "Påminnelse om første svar",
  ReminderOnResolutionTime: "Påminnelse om løsningstid",
  ReminderOnNextResponse: "Påminnelse om neste svar",
  DELETE_DRAFT: "Slett utkast",
  PERMANENTLY_DELETE_DRAFT: "Slett utkast permanent?",
  ADD_PORT: "Legg til port",
  ADD_RULE: "Legg til regel",
  ADD_PROPERTY: "Legg til egenskap",
  NOT_EQUAL_PHONE_NUMBER: "Ikke lik telefonnummer",
  EQUAL_PHONE_NUMBER: "Lik telefonnummer",
  INCLUDES_PHONE_NUMBER: "Innehåller telefonnummer",
  NOT_INCLUDES_PHONE_NUMBER: "Innehåller inte telefonnummer",
  ARRAY_LENGTH: "Arraylengde",
  STRICT_EQ: "Lik med",
  NOT_STRICT_EQ: "Ikke lik med",
  IS_EMPTY: "Er tom",
  IS_NOT_EMPTY: "Er ikke tom",
  CONTAINS: "Inneholder",
  NOT_CONTAINS: "Inneholder ikke",
  CHANGED: "Endret",
  NOT_EQUAL_NUM: "Ikke lik med (nummer)",
  NOT_EQUAL_STR: "Ikke lik med (tekst)",
  EQUAL_NUM: "Lik med (nummer)",
  EQUAL_STR: "Lik med (tekst)",
  STARTS_WITH: "Begynner med",
  NOT_STARTS_WITH: "Begynner ikke med",
  ANY_OF_NUM: "Noen av",
  NONE_OF_NUM: "Ingen av",
  ANY_OF_STR: "Noen av",
  NONE_OF_STR: "Ingen av",
  ENDS_WITH: "Slutter med",
  NOT_ENDS_WITH: "Slutter ikke med",
  GT: "Større enn",
  GTE: "Større enn eller lik med",
  LT: "Mindre enn",
  LTE: "Mindre enn eller lik med",
  IS_NOT_NULL: "Er ikke null",
  IS_NULL: "Er null",
  IS_FALSY: "Er usann",
  IS_FALSE: "Er false",
  IS_TRULY: "Er sann",
  IS_TRUE: "Er true",
  IS_UNDEFINED: "Er udefinert",
  IS_DEFINED: "Er definert",
  INCLUDES: "Inkluderer",
  INCLUDES_NUM: "Inkluderer nummer",
  INCLUDES_STR: "Inkluderer tekst",
  NOT_INCLUDES: "Inkluderer ikke",
  NOT_INCLUDES_NUM: "Inkluderer ikke nummer",
  NOT_INCLUDES_STR: "Inkluderer ikke tekst",
  GT_DATETIME: "Større enn",
  LT_DATETIME: "Mindre enn",
  MAIL_MESSAGE: "E-postmelding",
  "DRAFT.ID": "Utkast-id",
  "INTERNAL_NOTE.ID": "Internt notat-id",
  "CONVERSATION.ID": "Samtale-id",
  "CONVERSATION.ORGANIZATION_ID": "Organisasjon",
  "CONVERSATION.TITLE": "Samtaletittel",
  "CONVERSATION.QUEUE_ID": "Samtalekø",
  "CONVERSATION_QUEUE.TITLE": "Samtalekøens tittel",
  "CONVERSATION.LAST_INBOUND_MESSAGE_AT": "Siste innkommende melding",
  "CONVERSATION.LAST_OUTBOUND_MESSAGE_AT": "Siste utgående melding",
  "CONVERSATION.LAST_MESSAGE_AT": "Siste melding",
  "CONVERSATION.LAST_MESSAGE_BY": "Siste melding av",
  "CONVERSATION.CLIENT_ID": "Samtaleklient",
  "CONVERSATION_CLIENT.TITLE": "Samtaleklientens tittel",
  "CONVERSATION.CONTACT_ID": "Samtalekontakt-id",
  "CONVERSATION.JOURNEY_ID": "Reise",
  "CONVERSATION.DISPOSITION_ID": "Samtaleklassifisering",
  "CONVERSATION_DISPOSITION.TITLE": "Samtaleklassifiseringens tittel",
  "CONVERSATION.SUB_DISPOSITION_ID": "Samtaleunderklassifisering",
  "CONVERSATION_SUB_DISPOSITION.TITLE": "Samtaleunderklassifiseringens tittel",
  "CONVERSATION.THRD_DISPOSITION_ID": "Samtaletredjeklassifisering",
  "CONVERSATION_THRD_DISPOSITION.TITLE": "Samtaletredjeklassifiseringens tittel",
  "CONVERSATION.STATUS": "Samtalestatus",
  "CONVERSATION.CREATED_AT": "Samtale opprettet",
  "CONVERSATION.PRIORITY": "Samtaleprioritet",
  "CONVERSATION.USER_ID": "Samtaleagent-id",
  "USER.ID": "Agent",
  "CONVERSATION.STATUS_AT": "Samtalestatus endret",
  "CONVERSATION.RESPONSE_TIME": "Samtalesvarstid",
  "CONVERSATION.RESOLUTION_TIME": "Samtaleløsningstid",
  "CONVERSATION.MAIN_CHANNEL": "Samtalens hovedkanal",
  "CONVERSATION.DIRECTION": "Samtalens startretning",
  "CONVERSATION.TAGS": "Samtaleetiketter",
  "CONVERSATION.CHANNELS": "Samtalekanaler",
  "CONVERSATION.DATA": "Samtalens tilpassede felt",
  "MAIL_MESSAGE.ID": "E-postmelding-id",
  "MAIL_MESSAGE.TITLE": "E-postmeldingstittel",
  "MAIL_MESSAGE.SUBJECT": "E-postemne",
  "MAIL_MESSAGE.MAIL_ACCOUNT_ID": "E-postkonto",
  "MAIL_MESSAGE.CONTACT_EMAIL": "Kontaktens e-postadresse",
  "MAIL_MESSAGE.CREATED_AT": "E-postmelding opprettet",
  "CONTACT.ID": "Kontakt-id",
  "CONTACT.EMAILS": "Kontaktens e-postadresser",
  "CONTACT.PHONES": "Kontaktens telefonnumre",
  "CONTACT.FIRST_NAME": "Kontaktens fornavn",
  "CONTACT.LAST_NAME": "Kontaktens etternavn",
  "CONTACT.DATA": "Kontaktens tilpassede felt",
  FORM_SUBMISSION: "Skjemainnsending",
  "FORM_SUBMISSION.ID": "Skjemainnsending-id",
  "FORM_SUBMISSION.FORM_WEBSITE_ID": "Skjemawebside",
  "FORM_SUBMISSION.CONTACT_EMAIL": "Skjemakontaktens e-post",
  "FORM_SUBMISSION.CONTACT_PHONE": "Skjemakontaktens telefon",
  FORM_WEBSITE: "Skjemawebside",
  "FORM_WEBSITE.ID": "Skjemawebside-id",
  "MAIL_ACCOUNT.ID": "E-postkonto-id",
  "DRAFT.CREATED_AT": "Utkast opprettet",
  "INTERNAL_NOTE.CREATED_AT": "Internt notat opprettet",
  "VOICE_CALL.ID": "Talesamtale-id",
  "VOICE_CALL.VOICE_ASTERISK_ID": "Tale-asterisk",
  "VOICE_CALL.VOICE_ROUTE_ID": "Talerute",
  "VOICE_CALL.QUEUE_ID": "Talesamtalekø",
  VOICE_CALL_BRIDGES_COUNT: "Antall talesamtalebroer",
  "VOICE_CALL.CONTACT_PHONE": "Talesamtalekontaktens telefon",
  "VOICE_CALL.CREATED_AT": "Talesamtale opprettet",
  "VOICE_CALL.DIRECTION": "Talesamtaleretning",
  "VOICE_CALL.END_TIME": "Talesamtale avsluttet",
  VOICE_CALL_FIRST_ANSWERED_AT: "Talesamtale først besvart",
  "VOICE_CALL.VARIABLES": "Talevariabler",

  "VOICE_CALL_DURATION.TOTAL": "Total samtaletid (sekunder)",
  "VOICE_CALL_DURATION.PRE_QUEUE_TIME": "Tid før kø (sekunder)",
  "VOICE_CALL_DURATION.WAIT_TIME": "Ventetid (sekunder)",
  "VOICE_CALL_DURATION.TALK_TIME": "Samtaletid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Nåværende samtalebros totale tid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Nåværende samtalebros tid før kø (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Nåværende samtalebros ventetid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Nåværende samtalebros samtaletid (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Første samtalebros totale tid (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME": "Første samtalebros tid før kø (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Første samtalebros ventetid (sekunder)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Første samtalebros samtaletid (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Siste samtalebros totale tid (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME": "Siste samtalebros tid før kø (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Siste samtalebros ventetid (sekunder)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Siste samtalebros samtaletid (sekunder)",
  "CURRENT_VOICE_CALL_BRIDGE.ID": "Nåværende samtalebros ID",
  "CURRENT_VOICE_CALL_BRIDGE.DIRECTION": "Nåværende samtalebros retning",
  "CURRENT_VOICE_CALL_BRIDGE.CREATED_AT": "Nåværende samtalebro opprettet",
  "CURRENT_VOICE_CALL_BRIDGE.ANSWER_TIME": "Nåværende samtalebro besvart",
  "CURRENT_VOICE_CALL_BRIDGE.LEAVE_AT": "Nåværende samtalebro forlatt",
  "CURRENT_VOICE_CALL_BRIDGE.USER_ID": "Nåværende samtalebros agent",
  "CURRENT_VOICE_CALL_BRIDGE.QUEUE_ID": "Nåværende samtalebros kø",
  "CURRENT_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Nåværende samtalebro er ekstern oppringning",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TWINNED": "Nåværende samtalebro er twinnet",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFER": "Nåværende samtalebro er overføring",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Nåværende samtalebro er overført",
  "CURRENT_VOICE_CALL_BRIDGE.EXIT_REASON": "Nåværende samtalebros avslutningsårsak",
  "FIRST_VOICE_CALL_BRIDGE.ID": "Første samtalebros ID",
  "FIRST_VOICE_CALL_BRIDGE.DIRECTION": "Første samtalebros retning",
  "FIRST_VOICE_CALL_BRIDGE.CREATED_AT": "Første samtalebro opprettet",
  "FIRST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Første samtalebro besvart",
  "FIRST_VOICE_CALL_BRIDGE.LEAVE_AT": "Første samtalebro forlatt",
  "FIRST_VOICE_CALL_BRIDGE.USER_ID": "Første samtalebros agent",
  "FIRST_VOICE_CALL_BRIDGE.QUEUE_ID": "Første samtalebros kø",
  "FIRST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Første samtalebro er ekstern oppringning",
  "FIRST_VOICE_CALL_BRIDGE.IS_TWINNED": "Første samtalebro er twinnet",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Første samtalebro er overføring",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Første samtalebro er overført",
  "FIRST_VOICE_CALL_BRIDGE.EXIT_REASON": "Første samtalebros avslutningsårsak",
  "LAST_VOICE_CALL_BRIDGE.ID": "Siste samtalebros ID",
  "LAST_VOICE_CALL_BRIDGE.DIRECTION": "Siste samtalebros retning",
  "LAST_VOICE_CALL_BRIDGE.CREATED_AT": "Siste samtalebro opprettet",
  "LAST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Siste samtalebro besvart",
  "LAST_VOICE_CALL_BRIDGE.LEAVE_AT": "Siste samtalebro forlatt",
  "LAST_VOICE_CALL_BRIDGE.USER_ID": "Siste samtalebros agent",
  "LAST_VOICE_CALL_BRIDGE.QUEUE_ID": "Siste samtalebros kø",
  "LAST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Siste samtalebro er ekstern oppringning",
  "LAST_VOICE_CALL_BRIDGE.IS_TWINNED": "Siste samtalebro er twinnet",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Siste samtalebro er overføring",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Siste samtalebro er overført",
  "LAST_VOICE_CALL_BRIDGE.EXIT_REASON": "Siste samtalebros avslutningsårsak",
  "USER.USERNAME": "Agentens brukernavn",
  "USER.EMAIL": "Agentens e-post",
  "USER.FIRST_NAME": "Agentens fornavn",
  "USER.LAST_NAME": "Agentens etternavn",
  "USER.FULL_NAME": "Agentens fulle navn",
  "_QUEUE.AGENTS_READY": "Klare agenter i kø",
  "_QUEUE.AGENTS_PAUSED": "Pauset agenter i kø",
  "_QUEUE.AGENTS_BUSY": "Opptatte agenter i kø",
  "_QUEUE.AGENTS_TOTAL": "Totalt antall agenter i kø",
  "_QUEUE.LONGEST_WAITING": "Lengste ventetid i kø",
  "_QUEUE.WAITING": "Ventende i kø",
  PRIORITY: "Prioritet",
  DISPOSITION_ID: "Klassifisering",
  SUB_DISPOSITION_ID: "Underklassifisering",
  THRD_DISPOSITION_ID: "Tredjeklassifisering",
  DISPOSITION_NOTE: "Klassifiseringsnotat",
  STATUS: "Status",
  ADD_TAG: "Legg til etikett",
  REMOVE_TAG: "Fjern etikett",
  STATUS_O: "Åpen",
  STATUS_RO: "Gjenåpnet",
  STATUS_C: "Lukket",
  STATUS_R: "Løst",
  STATUS_P: "Ventende",
  STATUS_W: "Venter på tredjepart",

  PRIORITY_URGENT: "Haster",
  PRIORITY_HIGH: "Høy",
  PRIORITY_MEDIUM: "Medium",
  PRIORITY_LOW: "Lav",
  PRIORITY_U: "Haster",
  PRIORITY_H: "Høy",
  PRIORITY_M: "Medium",
  PRIORITY_L: "Lav",

  DIRECTION_INBOUND: "Innkommende",
  DIRECTION_OUTBOUND: "Utgående",
  DIRECTION_INTERNAL: "Intern",
  DIRECTION_I: "Innkommende",
  DIRECTION_O: "Utgående",
  "DIRECTION_-": "Intern",

  EXIT_REASON_REJECT: "Avvist",
  EXIT_REASON_CONTACTHANGEDUP: "Kontakt la på",
  EXIT_REASON_TIMEOUT: "Tidsavbrudd",
  EXIT_REASON_FULL: "Kø full",
  EXIT_REASON_JOINEMPTY: "Kø tilkobling tom",
  EXIT_REASON_LEAVEEMPTY: "Kø forlat tom",
  EXIT_REASON_JOINUNAVAIL: "Kø tilkobling utilgjengelig",
  EXIT_REASON_LEAVEUNAVAIL: "Kø forlat utilgjengelig",

  DEFINED: "Definert",
  CUSTOM: "Tilpasset",

  NEWDRAFT: "Nytt utkast opprettet",
  NEWINTERNALNOTE: "Nytt internt notat opprettet",
  CONVERSATIONUPDATED: "Samtale oppdatert",
  CONVERSATIONCREATED: "Samtale opprettet",
  CONTACTUPDATED: "Kontakt oppdatert",
  CONTACTCREATED: "Kontakt opprettet",
  NEWINBOUNDMAILMESSAGE: "Ny innkommende e-postmelding",
  NEWOUTBOUNDMAILMESSAGE: "Ny utgående e-postmelding",
  SINCECONVERSATION: "Siden samtaleoppdatering",
  NEWINBOUNDVOICECALL: "Ny innkommende talesamtale",
  NEWOUTBOUNDVOICECALL: "Ny utgående talesamtale",
  NEWINTERNALVOICECALL: "Ny intern talesamtale",
  INBOUNDVOICECALLHANGEDUP: "Innkommende talesamtale avsluttet",
  OUTBOUNDVOICECALLHANGEDUP: "Utgående talesamtale avsluttet",
  INTERNALVOICECALLHANGEDUP: "Intern talesamtale avsluttet",
  INBOUNDVOICECALLANSWERED: "Innkommende talesamtale besvart",
  OUTBOUNDVOICECALLANSWERED: "Utgående talesamtale besvart",
  INTERNALVOICECALLANSWERED: "Intern talesamtale besvart",
  NEWINBOUNDVOICECALLBRIDGE: "Ny innkommende samtalebro",
  NEWOUTBOUNDVOICECALLBRIDGE: "Ny utgående samtalebro",
  NEWINTERNALVOICECALLBRIDGE: "Ny intern samtalebro",
  INBOUNDVOICECALLBRIDGELEAVE: "Innkommende samtalebro forlatt",
  OUTBOUNDVOICECALLBRIDGELEAVE: "Utgående samtalebro forlatt",
  INTERNALVOICECALLBRIDGELEAVE: "Intern samtalebro forlatt",
  INBOUNDVOICECALLBRIDGEANSWERED: "Innkommende samtalebro besvart",
  OUTBOUNDVOICECALLBRIDGEANSWERED: "Utgående samtalebro besvart",
  INTERNALVOICECALLBRIDGEANSWERED: "Intern samtalebro besvart",

  CANNED_ANSWER_ID: "Standardsvar",
  CANNED_ANSWER_ID2: "Standardsvar 2",
  CANNED_ANSWER_ID3: "Standardsvar 3",
  CANNED_ANSWER_ID4: "Standardsvar 4",
  MAIL_ACCOUNT_ID: "E-postkonto",
  DESCRIPTION: "Beskrivelse",
  URL: "Nettadresse",
  STATUS_PORTS: "Statusporter",
  QUERY_PARAMS: "Spørreparametere",
  HEADERS: "Overskrifter",
  BODY: "Innhold",
  QUEUE_ID: "Kø",
  MILLISECONDS: "Millisekunder",
  TIMEOUT: "Tidsavbrudd",
  OPERATIONAL_HOURS_ID: "Åpningstider",
  JOURNEY_ID: "Journey ID",
  PUBLISH: "Publiser",
  ENTER_FULLSCREEN: "Vis i fullskjerm",
  EXIT_FULLSCREEN: "Avslutt fullskjerm",
  RESET: "Tilbakestill",
  APPLY: "Bruk",
  RELOAD_FROM_JOURNEY: "Last inn fra reise",
  WHEN: "Når",
  WITHIN: "Innenfor",
  NOT_WITHIN: "Ikke innenfor",
  ERROR: "Feil",
  SUCCESS: "Vellykket",
  OPTION_1: "Alternativ 1",
  OPTION_2: "Alternativ 2",
  OPTION_3: "Alternativ 3",
  OPTION_4: "Alternativ 4",
  OPTION_5: "Alternativ 5",
  OPTION_6: "Alternativ 6",
  OPTION_7: "Alternativ 7",
  OPTION_8: "Alternativ 8",
  OPTION_9: "Alternativ 9",
  RULE_1: "Regel 1",
  RULE_2: "Regel 2",
  RULE_3: "Regel 3",
  RULE_4: "Regel 4",
  RULE_5: "Regel 5",
  RULE_6: "Regel 6",
  RULE_7: "Regel 7",
  RULE_8: "Regel 8",
  RULE_9: "Regel 9",
  OTHERWISE: "Ellers",
  TRIGGERED: "Utløst",
  TIMED_OUT: "Tiden gikk ut",
  NEXT: "Neste",
  EDITED: "Redigert",
  ASSIGNED: "Tildelt",
  TIME_RANGE: "Tidsintervall",
  MONTHS: "Måneder",
  DAYS: "Dager",
  DAY_S: "Dag(er)",
  WEEK_DAYS: "Ukedager",
  FROM: "Fra",
  ALWAYS: "Alltid",
  INVALID_INTERVAL: "Ugyldig intervall",
  CREATED: "Opprettet",
  RESOLVED: "Løst",
  REOPENED: "Gjenåpnet",
  FIRSTASSIGNED: "Først tildelt",
  REQUESTERRESPONDED: "Forespørreren svarte",
  AGENTRESPONDED: "Agenten svarte",
  UNTILFIRSTRESPONSEDUE: "Til første svar forfaller",
  FIRSTRESPONSEOVERDUE: "Første svar forsinket",
  UNTILANYRESPONSEDUE: "Til noe svar forfaller",
  ANYRESPONSEOVERDUE: "Noe svar forsinket",
  RESOLUTIONTIMEOVERDUE: "Løsningstid forsinket",
  UNTILRESOLUTIONTIME: "Til løsningstid forfaller",
  WAITINGFORTHIRDPARTY: "Venter på tredjepart",
  UNIT: "Enhet",
  TIME: "Tid",
  RESPONSE_TIME: "Svartid",
  RESOLUTION_TIME: "Løsningstid",
  CONTACT_ID: "Kontakt",
  USER_ID: "Bruker-ID",
  AGENT_ID: "Agent-ID",
  HOURS_SHORT: "t",
  MINUTES_SHORT: "m",
  SECONDS_SHORT: "s",
  DAYS_SHORT: "d",
  YESTERDAY: "I går",
  JUST_NOW: "Akkurat nå",
  NOUPDATEDIN7DAYS: "Ingen oppdateringer på 7 dager",
  CUSTOMERRESPONDED: "Kunden svarte",
  STATUS_AT: "Status endret ved",
  ASSIGNED_AT: "Tildelt ved",
  FIRST_ASSIGNED_AT: "Først tildelt ved",
  CONVERSATION_UPDATE_DISPOSITION_ID: "Disposisjon oppdatert",
  CONVERSATION_UPDATE_USER_ID: "Agent oppdatert",
  CONVERSATION_UPDATE_STATUS: "Status endret",
  CONVERSATION_UPDATE_FLAG: "Flagg endret",
  CONVERSATION_UPDATE_RESOLUTION_TIME: "Løsningstid endret",
  CONVERSATION_UPDATE_RESPONSE_TIME: "Svartid endret",
  CONVERSATION_UPDATE_QUEUE: "Kø endret",
  CONVERSATION_UPDATE_PRIORITY: "Prioritet endret",
  CONVERSATION_UPDATE_CONTACT: "Kontakt endret",
  CONVERSATION_NEW_MESSAGE: "Ny melding",
  NEW_CONVERSATION: "Ny samtale",
  MINIMIZE: "Minimer",
  MAXIMIZE: "Maksimer",
  MINUTES: "Minutter",
  MINUTE_S: "Minutt(er)",
  SECOND_S: "Sekund(er)",
  HOURS: "Timer",
  HOUR_S: "Time(r)",
  LAST_UPDATED: "Sist oppdatert",
  SUBJECT: "Emne",
  CC: "Kopi",
  BCC: "Blindkopi",
  DISPOSE_AND_CLOSE: "Disponer & Lukk",
  DISPOSE_AND_RESOLVE: "Disponer & Løs",
  ADD_NEW: "Legg til ny",
  CONFIRM_DELETION: "Er du sikker på at du vil slette dette objektet?",
  OAUTH_AUTHENTICATION_NOT_FOUND: "Autentisering mislyktes!",
  OAUTH_AUTHENTICATION_NOT_FOUND_CONTENT:
    "En administrator i organisasjonen din må opprette en konto med e-postadressen din før du kan logge inn!",
  MAIL_ACCOUNT_AUTHENTICATED: "E-postkonto autentisert!",
  AN_ERROR_OCCURRED: "En feil oppstod!",
  ATTACHMENTS: "Vedlegg",
  ATTACHMENT: "Vedlegg",
  FILE_NOT_FOUND: 'Filen "{{filename}}" ble ikke funnet!',
  NOT_FOUND: "Ikke funnet!",
  PAGE_NOT_FOUND: "Siden ble ikke funnet!",
  PAGE_NOT_FOUND_DESCRIPTION:
    "Siden du leter etter finnes ikke! Hvis du klikket på en lenke som tok deg hit, vennligst rapporter problemet til administratoren din!",
  NOTIFICATION_A: "Ny tildeling",
  NOTIFICATION_B: "Nytt svar på min samtale",
  NOTIFICATION_C: "Samtalemeldinger jeg er tagget i",
  NOTIFICATION_D: "Statusoppdatering på min samtale",
  NOTIFICATION_E: "Samtale tildelt til meg",
  NOTIFICATION_F: "Samtale tildelt til mine køer",
  NOTIFICATION_G: "Påminnelse om første svar",
  NOTIFICATION_H: "Påminnelse om løsningstid",
  NOTIFICATION_I: "Påminnelse om neste svar",
  NOTIFICATION_J: "Systeminfo",
  NOTIFICATION_K: "Systemfeil",
  NOTIFICATION_L: "Passord utløper snart",
  NOTIFICATION_M: "Alarm",
  "NOTIFICATION_-": "Internt anrop",
  MARK_ALL_AS_SEEN: "Marker alle som sett",
  CANNED_ANSWER: "Standardsvar",
  CONTACT_FIRST_NAME: "Kontaktens fornavn",
  CONTACT_LAST_NAME: "Kontaktens etternavn",
  CONTACT_FULL_NAME: "Kontaktens fulle navn",
  CONTACT_EMAIL: "Kontaktens e-post",
  CONTACT_PHONE: "Kontaktens telefon",
  AGENT_FIRST_NAME: "Agentens fornavn",
  AGENT_LAST_NAME: "Agentens etternavn",
  AGENT_FULL_NAME: "Agentens fulle navn",
  CONVERSATION__ID: "Samtale-ID",
  SELECT_CONTACT: "Velg kontakt",
  MULTIPLE_CONTACTS_WHERE_FOUND: "Flere kontakter ble funnet!",
  MULTIPLE_CONTACTS_WHERE_FOUND_SELECT_CONTACT: "Flere kontakter ble funnet! Hvilken vil du bruke?",
  WHICH_ONE_DO_YOU_WANT_TO_USE: "Hvilken vil du bruke?",
  EMAILS: "E-postadresser",
  PHONES: "Telefonnumre",
  STRATEGY: "Strategi",
  ROUND_ROUND_ROBIN: "Round Robin",
  VOICE_QUEUE_STRATEGY_RINGALL: "Ring alle",
  VOICE_QUEUE_STRATEGY_ROUNDROBIN: "Round Robin",
  VOICE_QUEUE_STRATEGY_LEASTRECENT: "Minst nylig",
  VOICE_QUEUE_STRATEGY_FEWESTCALLS: "Færrest samtaler",
  VOICE_QUEUE_STRATEGY_RRMEMORY: "Sirkulær med minne",
  VOICE_QUEUE_STRATEGY_LINEAR: "Lineær",
  VOICE_QUEUE_STRATEGY_WRANDOM: "Vektet tilfeldig",
  RINGALL: "Ring alle",
  LEASTRECENT: "Minst nylig",
  FEWESTCALLS: "Færrest samtaler",
  RANDOM: "Tilfeldig",
  WRANDOM: "Vektet tilfeldig",
  LINEAR: "Lineær",
  RRMEMORY: "Sirkulær med minne",
  ROUNDROBIN: "Sirkulær",
  QUEUE_STRATEGY_ROUND_ROUND_ROBIN: "Sirkulær",
  QUEUE_STRATEGY_PING_ALL: "Ping alle",
  QUEUE_STRATEGY_LEAST_RECENT: "Minst nylig",
  QUEUE_STRATEGY_FEWEST_CONVERSATIONS: "Færrest samtaler",
  QUEUE_STRATEGY_RANDOM: "Tilfeldig",
  QUEUE_STRATEGY_SKILL_BASED_AGENTS_READY: "Kompetansebasert agenter klar",
  QUEUE_STRATEGY_SKILL_BASED_TIMEOUT: "Kompetansebasert tidsavbrudd",
  CREATE_LIST_ID: "Opprett liste",
  CREATE_LIST_ID_HELP: "Velg liste å legge til nye kontakter i",
  READ_LISTS: "Les lister",
  READ_LISTS_HELP:
    "Lister å lese kontakter fra. Både system og agenter vil velge kontakter som finnes i disse listene.",
  CONVERSATION_FORM_ID: "Samtaleskjema",
  SLA_POLICY_ID: "SLA-policy",
  INBOUND_SLA_POLICY_ID: "SLA-policy (innkommende)",
  OUTBOUND_SLA_POLICY_ID: "SLA-policy (utgående)",
  REQUIRE_DISPOSITION: "Krev klassifisering",
  SETTINGS_REQUIRE_DISPOSITION_HELP:
    "Aktiver for å tvinge valg av klassifisering før samtalen lukkes",
  REQUIRE_CLIENT: "Krev klient",
  SETTINGS_REQUIRE_CLIENT_HELP: "Aktiver for å tvinge valg av klient før samtalen lukkes",
  CONVERSATION_ASSIGNMENT: "Samtaletildeling",
  SETTINGS_CONVERSATION_ASSIGNMENT_HELP:
    "Hvordan samtaler skal tildeles til agenter. Manuell tildeling vil alltid være tilgjengelig.",
  ONOPEN: "Ved åpning",
  ONREPLY: "Ved svar",
  ONCLOSE: "Ved lukking",
  MANUALLY: "Manuelt",
  CONVERSATION_ASSIGNMENT_ONOPEN: "Ved åpning",
  CONVERSATION_ASSIGNMENT_ONREPLY: "Ved svar",
  CONVERSATION_ASSIGNMENT_ONCLOSE: "Ved lukking",
  CONVERSATION_ASSIGNMENT_MANUALLY: "Manuelt",
  __DISPOSITIONS: "Klassifiseringer",
  __DISPOSITIONS_HELP: "Tilgjengelige klassifiseringer å velge for samtaler i denne køen.",
  __CANNED_ANSWERS: "Standardsvar",
  __AGENT_SIGNATURES: "Agentsignaturer",
  __OUTBOUND_MAIL_ACCOUNTS: "Utgående e-postkontoer",
  __OUTBOUND_MAIL_ACCOUNTS_HELP:
    "Tilgjengelige utgående e-postkontoer ved opprettelse av nye utgående e-postsamtaler",
  FORM_NULLABLE_ENABLE: "Definer verdi",
  FORM_NULLABLE_DISABLE: "Deaktiver verdi",
  EDIT_QUEUE: "Rediger kø",
  ADD_QUEUE: "Opprett ny kø",
  DELETED: "Slettet",
  TWO_FACTOR_AUTH: "2FA",
  ORGANIZATION_ID: "Organisasjon",
  ADMINPROFILE_ID: "Adminprofil",
  SUPERADMIN_PROFILE_ID: "Superadminprofil",
  __PAUSES: "Pauser",
  __QUEUES: "Køer",
  __TEAMS: "Team",
  TEAM: "Team",
  ADDRESS: "Adresse",
  INBOUND_JOURNEY_ID: "Innkommende reise",
  OUTBOUND_JOURNEY_ID: "Utgående reise",
  IMAP: "IMAP",
  SMTP: "SMTP",
  HOST: "Vert",
  PORT: "Port",
  NAME: "Navn",
  TLS: "tls/ssl",
  REMOVE_ON_RECEIVE: "Fjern ved mottak",
  IMAP_REMOVE_ON_RECEIVE_HELP: "Fjern e-post fra innboksen når den mottas",
  BASIC: "Grunnleggende",
  XOAUTH2: "Oauth 2",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASIC: "Grunnleggende",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICGOOGLE: "Grunnleggende (Google)",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICMICROSOFT: "Grunnleggende (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2MICROSOFT: "Moderne autentisering (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2GOOGLE: "Moderne autentisering (Google)",
  JOURNEIES: "Journeys",
  JOURNEY_DRAFTS: "Journey drafts",
  FORM_CHANNEL: "Skjemakanal",
  FORM_WEBSITES: "Skjemanettsteder",
  TIME_INTERVALS: "Tidsintervaller",
  OPERATIONAL_HOURSES: "Åpningstider",
  OPERATIONAL_HOURS: "Åpningstider",
  SLA_POLICIES: "SLA-policyer",
  CANNED_ANSWERS: "Standardsvar",
  SIGNATURES: "Signaturer",
  FORMS: "Skjemaer",
  STAFF: "Personale",
  SEARCH_AGENTS: "Søk agenter",
  STATUS_0: "Offline",
  STATUS_1: "Online",
  STATUS_4: "Klar (E-post)",
  STATUS_8: "Klar (Tale)",
  STATUS_512: "Klar",
  STATUS_1024: "Delvis opptatt",
  STATUS_2048: "Håndterer samtale",
  STATUS_4096: "Pinger",
  STATUS_32768: "Pause",
  STATUS_262144: "Opptatt (E-post)",
  STATUS_524288: "Opptatt (Tale)",
  STATUS_1048576: "Opptatt (Skjema)",
  STATUS_33554432: "Opptatt",
  FILTER: "Filter",
  ITEMS: "Elementer",
  ALLOW: "Tillat",
  CANNEDANSWER: "Standardsvar",
  FORM: "Skjema",
  SIGNATURE: "Signatur",
  SLAPOLICY: "SLA-policy",
  OPERATIONALHOURS: "Åpningstider",
  TAG: "Etikett",
  MAILACCOUNT: "E-postkonto",
  READ: "Les",
  CREATE: "Opprett",
  DELETE: "Slett",
  PERMISSIONS: "Tillatelser",
  TIMEINTERVAL: "Tidsintervall",
  LOGIN_STRATEGY: "Påloggingstype",
  LOGIN_STRATEGY_LOCAL: "Brukernavn / Passord",
  LOGIN_STRATEGY_GOOGLE: "Google",
  LOGIN_STRATEGY_MICROSOFT: "Microsoft",
  ADMIN_AGENT: "Admin & Agent",
  FORMWEBSITE: "Skjemanettsted",
  SCHEMA_NOT_FOUND: "Skjemaet kunne ikke lastes!",
  ITEM_NOT_FOUND: "Elementet du prøver å laste kunne ikke finnes!",
  ADMIN_ORGANIZATIONS_CHANGED_RELOAD:
    "Tildelte organisasjoner for brukeren din har blitt oppdatert, vennligst last inn på nytt manuelt for at applikasjonen skal fungere korrekt!",
  LOADING_DEPENDENCIES: "Laster avhengigheter",
  PARENT: "Overordnet",
  ACTIVE: "Aktiv",
  ORGANIZATION_2FA_HELP: "Velg roller for å tvinge tofaktorautentisering",
  SECONDS: "Sekunder",
  PROPERTY: "Egenskap",
  VALUE: "Verdi",
  SIGNATURE_ID: "Signatur",
  METHOD: "Metode",
  CONDITIONS: "Betingelser",
  ADD_CONDITION: "Legg til betingelse",
  FLAG: "Flagg",
  SOURCE: "Kilde",
  SINCE: "Siden",
  JOURNEY_DRAFT: "Reiseutkast",
  PENDING: "Venter",
  START_CONVERSATION: "Start samtale",
  NEW_V_CONVERSATION: "Ny SIP-samtale",
  NEW_M_CONVERSATION: "Ny e-postsamtale",
  CREATE_NEW_CONTACT: "Opprett ny kontakt",
  OR_CREATE_NEW_CONTACT: "eller opprett ny kontakt",
  LOADING_FORM: "Laster skjema",
  __TAGS: "Etiketter",
  MAIN_CHANNEL: "Hovedkanal",
  CREATE_CONVERSATION: "Opprett samtale",
  CHOOSE_AGENT: "Velg agent",
  CHOOSE_QUEUE: "Velg kø",
  SELECTED: "Valgt",
  CHANGE_CONTACT: "Endre kontakt",
  CREATE_CONTACT: "Opprett kontakt",
  REQUIRED: "Påkrevd",
  CONVERSATION_STATUS: "Samtalestatus",
  NO_NAME: "Ingen navn",
  DISPLAY_TITLE: "Visningstittel",
  UI_TYPE: "UI-type",
  VALIDATION: "Validering",
  MIN: "Min",
  CHILD_TYPE: "Undertype",
  VALID: "Gyldig",
  REQUIRED_ON_SAVE: "Påkrevd ved lagring",
  REQUIRED_ON_CONVERSATION_CLOSE: "Påkrevd ved samtalelukking",
  REQUIRED_ON_FORM_SUBMIT: "Påkrevd ved skjemainnsending",
  ALIAS: "Alias",
  ALIAS__KEY: "Alias / nøkkel",
  FIELD_TYPE_DESCRIPTION: "Felttypen gjør det tilgjengelig for forskjellige skjematyper",
  MINIMUM: "Minimum",
  VALIDATION_MINIMUM_NUMBER_HELP: "Minste nummer som kan fylles i dette feltet",
  VALIDATION_MINIMUM_LIST_HELP: "Minste antall alternativer",
  VALIDATION_MINIMUM_TEXT_HELP: "Minste antall tegn",
  SETTINGS_VALIDATION_CHILD_TYPE_HELP: "Type listealternativ",
  FIELD_ALIAS_HELP:
    "Verdier vil bli lagret med dette unike aliaset. Kun alfanumeriske tegn og _ er gyldige tegn.",
  OPTIONS: "Alternativer",
  __FIELDS: "Felt",
  WEBSITE: "Nettsted",
  POLICY: "Policy",
  FIRST_RESPONSE: "Første svar",
  EVERY_RESPONSE: "Hvert svar",
  URGENT: "Haster",
  SLA_POLICY: "SLA-policy",
  SEARCH_RESULTS: "Søkeresultater",
  UNASSIGNED: "Ikke tildelt",
  NO_QUEUE: "Ingen kø",
  NO_PAUSE: "Ingen pause",
  NOT_DISPOSED: "Ikke klassifisert",
  NO_FLAG: "Ingen flagg",
  FLAGS: "Flagg",
  INVALID_2FA_SECRET: "Ugyldig kode",
  __TIME_INTERVALS: "Tidsintervaller",
  TIME_INTERVAL: "Tidsintervall",
  TIMEZONE: "Tidssone",
  RESET_PASSWORD_DONE: "En e-post med instruksjoner har blitt sendt til deg!",
  PASSWORD_RESET_EMAIL_WILL_BE_SENT:
    "Vi vil sende en e-post med instruksjoner til kontoen med angitt brukernavn, hvis den kontoen eksisterer!",
  DISPOSITION_REQUIRED_ON_THIS_QUEUE:
    "Klassifisering må angis ved lukking/løsning av samtale, i henhold til køinnstillinger.",
  CLIENT_REQUIRED_ON_THIS_QUEUE:
    "Klient må angis ved lukking/løsning av samtale, i henhold til køinnstillinger.",
  CONVERSATION_FORM_NOT_VALID_ON_SAVE:
    "Samtalesskjemaet har obligatoriske felt som må fylles ut før du kan redigere samtalen.",
  CONVERSATION_FORM_NOT_VALID_ON_CLOSE:
    "Samtalesskjemaet har obligatoriske felt som må fylles ut før du kan løse/lukke samtalen.",
  CONTACT_FORM_NOT_VALID_ON_SAVE:
    "Kontaktskjemaet har obligatoriske felt som må fylles ut før du kan redigere samtalen.",
  CONTACT_FORM_NOT_VALID_ON_CLOSE:
    "Kontaktskjemaet har obligatoriske felt som må fylles ut før du kan løse/lukke samtalen.",
  CONVERSATION_CANNOT_BE_CLOSED_WITHOUT_USER:
    "Samtalen må ha en tildelt agent før lukking/løsning.",
  DISPOSITION_NOTE_NOT_ALLOWED_WHEN_NO_DISPOSITION_SELECTED:
    "Klassifiseringsnotat er ikke tillatt uten valgt klassifisering.",
  QUEUE_MAY_NOT_BE_NULL: "Kø kan ikke være udefinert for samtalen.",
  AGENT_NOT_IN_SELECTED_QUEUE: "Tildelt agent er ikke tilknyttet valgt kø.",
  CONTACT_ALREADY_SELECTED: "Kontakt allerede valgt for samtalen",
  QUEUE_NOT_FOUND_ON_CONVERSATION: "En kø må tildeles samtalen før valg av kontakt.",
  NO_MESSAGES_FOUND_ON_CONVERSATION: "Ingen meldinger ble funnet i samtalen.",
  QUEUE_NO_READ_LISTS: "Køen har ingen tildelte leselister.",
  CONVERSATION_FORM_DATA_CLOSE_REQUIRED_TOOLTIP:
    "Obligatorisk felt når samtalestatus settes til lukket",
  CONVERSATION_FORM_DATA_CLOSE_TOOLTIP: "Obligatorisk felt ved oppdatering av samtaleegenskaper",
  VIEW_CONVERSATION: "Vis samtale",
  START_NEW_MAIL_CONVERSATION: "Start ny e-postsamtale",
  START_NEW_VOICE_CONVERSATION: "Start ny stemmesamtale",
  NULLABLE: "Nullbar",
  SETTINGS_NULLABLE_HELP: 'Verdi kan settes til "null"',
  UI_DATA_TYPE: "UI / Datatype",
  LAST_SAVE: "Sist lagret",
  TITLE_MISSING: "Tittel mangler",
  SELECT_QUEUE: "Velg kø",
  CREATE_INTERNAL_NOTE: "Opprett internt notat",
  SAVE_DRAFT: "Lagre utkast",
  NO_SELECTABLE_DISPOSITIONS: "Ingen valgbare klassifiseringer",
  SMTP_PORT_HELP: "Standard: 25, tls: 465",
  IMAP_PORT_HELP: "Standard: 143, tls: 993",
  FORM_ID: "Skjema",
  CHANNEL: "Kanal",
  INTERVAL: "Intervall",
  CANNED_ANSWER_TYPE_A: "Agent",
  CANNED_ANSWER_TYPE_Q: "Kø",
  CANNED_ANSWER_CHANNEL_M: "E-post",
  CANNED_ANSWER_CHANNEL_S: "SMS",
  SIGNATURE_TYPE_A: "Agent",
  SIGNATURE_TYPE_Q: "Kø",
  SLA_TIME_UNIT_H: "Timer",
  SLA_TIME_UNIT_D: "Dager",
  FORM_TYPE_CONVERSATION: "Samtale",
  FORM_TYPE_CONTACT: "Kontakt",
  FORM_TYPE_WEBSITE: "Nettsted",
  FIELD_TYPE_CONVERSATION: "Samtale",
  FIELD_TYPE_CONTACT: "Kontakt",
  FIELD_TYPE_FORMSUBMISSION: "Skjemainnsending",
  FORM_ELEMENT_TYPE_EXPOSED_INPUT: "Tekst",
  FORM_ELEMENT_TYPE_EXPOSED_TEXT: "Lang tekst",
  FORM_ELEMENT_TYPE_EXPOSED_NUMBER: "Nummer",
  FORM_ELEMENT_TYPE_EXPOSED_SELECT: "Velg",
  FORM_ELEMENT_TYPE_EXPOSED_LIST: "Liste",
  FORM_ELEMENT_TYPE_EXPOSED_SWITCH: "Bryter",
  FORM_ELEMENT_TYPE_EXPOSED_RADIO: "Radio",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOXES: "Avkrysningsbokser",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOX: "Avkrysningsboks",
  FORM_ELEMENT_TYPE_EXPOSED_EMAIL: "E-post",
  FORM_ELEMENT_TYPE_EXPOSED_PHONE: "Telefonnummer",
  FORM_ELEMENT_TYPE_EXPOSED_HTML: "Html",
  FORM_ELEMENT_TYPE_EXPOSED_FILE: "Fil",

  FORM_ELEMENT_CHILD_TYPE_EXPOSED_INPUT: "Tekst",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_NUMBER: "Nummer",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_PHONE: "Telefonnummer",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_EMAIL: "E-post",

  CHANNEL_VOICE: "Stemme",
  CHANNEL_VOICE_INBOUND: "Innkommende stemmesamtale",
  CHANNEL_VOICE_INTERNAL: "Intern stemmesamtale",
  CHANNEL_VOICE_INTERNAL_LIVE: "Pågående intern stemmesamtale",
  CHANNEL_VOICE_TRANSFER: "Stemmeoverføring",
  CHANNEL_VOICE_TRANSFER_LIVE: "Pågående stemmeoverføring",
  CHANNEL_VOICE_INBOUND_LIVE: "Pågående innkommende stemmesamtale",
  CHANNEL_VOICE_OUTBOUND: "Utgående stemmesamtale",
  CHANNEL_VOICE_OUTBOUND_LIVE: "Pågående utgående stemmesamtale",
  CHANNEL_EDIT: "Rediger",
  CHANNEL_CHAT: "Chat",
  CHANNEL_CHAT_INBOUND: "Innkommende chatmelding",
  CHANNEL_CHAT_OUTBOUND: "Utgående chatmelding",
  CHANNEL_MAIL: "E-post",
  CHANNEL_MAIL_INBOUND: "Innkommende e-post",
  CHANNEL_MAIL_OUTBOUND: "Utgående e-post",
  CHANNEL_SMS: "SMS",
  CHANNEL_SMS_INBOUND: "Innkommende SMS",
  CHANNEL_SMS_OUTBOUND: "Utgående SMS",
  CHANNEL_FORM: "Skjema",
  CHANNEL_CHANSPY: "Kanalavlytting",
  CHANNEL_DISPOSITION_NOTE: "Klassifiseringsnotat",
  INTERNAL_CALL: "Internt anrop",

  CHANNEL_V: "Stemme",
  "CHANNEL_-": "Ingen kanal",
  CHANNEL_C: "Chat",
  CHANNEL_M: "E-post",
  CHANNEL_S: "SMS",
  CHANNEL_F: "Skjema",
  CHANNEL_IN: "Internt notat",
  CHANNEL_In: "Internt notat",

  ADMINPROFILE_PERMISSION_ID_USER: "Bruker",
  ADMINPROFILE_PERMISSION_ID_TEAM: "Team",
  ADMINPROFILE_PERMISSION_ID_ORGANIZATION: "Organisasjon",
  ADMINPROFILE_PERMISSION_ID_MAILACCOUNT: "E-postkonto",
  ADMINPROFILE_PERMISSION_ID_QUEUE: "Kø",
  ADMINPROFILE_PERMISSION_ID_LIST: "Liste",
  ADMINPROFILE_PERMISSION_ID_ADMINPROFILE: "Administratorprofil",
  ADMINPROFILE_PERMISSION_ID_AGENTPROFILE: "Agentprofil",
  ADMINPROFILE_PERMISSION_ID_SLAPOLICY: "SLA-policy",
  ADMINPROFILE_PERMISSION_ID_DISPOSITION: "Klassifisering",
  ADMINPROFILE_PERMISSION_ID_FORM: "Skjema",
  ADMINPROFILE_PERMISSION_ID_FIELD: "Felt",
  ADMINPROFILE_PERMISSION_ID_FORMWEBSITE: "Skjemanettsted",
  ADMINPROFILE_PERMISSION_ID_JOURNEY: "Reise",
  ADMINPROFILE_PERMISSION_ID_OPERATIONALHOURS: "Åpningstider",
  ADMINPROFILE_PERMISSION_ID_PAUSE: "Pause",
  ADMINPROFILE_PERMISSION_ID_CANNEDANSWER: "Standardsvar",
  ADMINPROFILE_PERMISSION_ID_SIGNATURE: "Signatur",
  ADMINPROFILE_PERMISSION_ID_TAG: "Etikett",
  ADMINPROFILE_PERMISSION_ID_TIMEINTERVAL: "Tidsintervall",
  ADMINPROFILE_PERMISSION_ID_VOICEROUTE: "Stemmerute",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDING: "Stemmeopptak",
  ADMINPROFILE_PERMISSION_ID_SOUND: "Lyd",
  ADMINPROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Variabel",
  ADMINPROFILE_PERMISSION_ID_STATSREPORT: "Statistikkrapport",
  ADMINPROFILE_PERMISSION_ID_STATSWIDGET: "Statistikkwidget",
  ADMINPROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Destinasjonsbegrenser",
  ADMINPROFILE_PERMISSION_ID_INVOICE: "Faktura",
  ADMINPROFILE_PERMISSION_ID_VOICESHORTCUT: "Stemmesnarvei",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Stemmepostkasse",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Stemmepostmelding",
  ADMINPROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Telefonnummerkjøp",
  ADMINPROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Tilpassede måleverdier",
  ADMINPROFILE_PERMISSION_ID_AUDIT: "Revisjon",
  ADMINPROFILE_PERMISSION_ID_APIKEY: "API-nøkkel",
  ADMINPROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Kontakttilpasset hendelse",
  ADMINPROFILE_PERMISSION_ID_CLIENT: "Klient",
  ADMINPROFILE_PERMISSION_ID_CONTACT: "Kontakt",
  ADMINPROFILE_PERMISSION_ID_WALLBOARD: "Oppslagstavle",
  ADMINPROFILE_PERMISSION_ID_SKILL: "Kompetanse",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Stemmeopptak-transkripsjon",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Stemmeopptak-transkripsjoninnstillinger",
  ADMINPROFILE_PERMISSION_ID_POINTSSYSTEM: "Poengsystem",
  ADMINPROFILE_PERMISSION_ID_GDPR: "Sikkerhet & etterlevelse",
  ADMINPROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymiser",
  ADMINPROFILE_PERMISSION_ID_STATSALARM: "Statistikkalarm",
  ADMINPROFILE_PERMISSION_ID_VOICECALL: "Stemmesamtale",
  ADMINPROFILE_PERMISSION_ID_TEXTMESSAGE: "Tekstmelding",

  SUPERADMIN_PROFILE_PERMISSION_ID_USER: "Bruker",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEAM: "Team",
  SUPERADMIN_PROFILE_PERMISSION_ID_ORGANIZATION: "Organisasjon",
  SUPERADMIN_PROFILE_PERMISSION_ID_MAILACCOUNT: "E-postkonto",
  SUPERADMIN_PROFILE_PERMISSION_ID_QUEUE: "Kø",
  SUPERADMIN_PROFILE_PERMISSION_ID_LIST: "Liste",
  SUPERADMIN_PROFILE_PERMISSION_ID_ADMINPROFILE: "Administratorprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_AGENTPROFILE: "Agentprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_SLAPOLICY: "SLA-policy",
  SUPERADMIN_PROFILE_PERMISSION_ID_DISPOSITION: "Klassifisering",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORM: "Skjema",
  SUPERADMIN_PROFILE_PERMISSION_ID_FIELD: "Felt",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORMWEBSITE: "Skjemanettsted",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEY: "Reise",
  SUPERADMIN_PROFILE_PERMISSION_ID_OPERATIONALHOURS: "Åpningstider",
  SUPERADMIN_PROFILE_PERMISSION_ID_PAUSE: "Pause",
  SUPERADMIN_PROFILE_PERMISSION_ID_CANNEDANSWER: "Standardsvar",
  SUPERADMIN_PROFILE_PERMISSION_ID_SIGNATURE: "Signatur",
  SUPERADMIN_PROFILE_PERMISSION_ID_TAG: "Etikett",
  SUPERADMIN_PROFILE_PERMISSION_ID_TIMEINTERVAL: "Tidsintervall",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEROUTE: "Stemmerute",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDING: "Stemmeopptak",
  SUPERADMIN_PROFILE_PERMISSION_ID_SOUND: "Lyd",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Variabel",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSREPORT: "Statistikkrapport",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSWIDGET: "Statistikkwidget",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEASTERISK: "Stemmeasterisk",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFF: "Tariff",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFFPARENT: "Tariff parent",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOST: "Månedskostnad for telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOSTPARENT:
    "Overordnet månedskostnad for telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOST: "Oppstartsavgift for telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOSTPARENT:
    "Overordnet oppstartsavgift for telefonnummer",
  SUPERADMIN_PROFILE_PERMISSION_ID_SUPERADMINPROFILE: "Superadministratorprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICE: "Faktura",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICEPOST: "Fakturapost",
  SUPERADMIN_PROFILE_PERMISSION_ID_PREPAIDCREDIT: "Forhåndskreditt",
  SUPERADMIN_PROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Destinasjonsbegrenser",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Stemmepostkasse",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Stemmepostmelding",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICESHORTCUT: "Stemmesnarvei",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICETRUNK: "Stemmetrunk",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSTOCK: "Telefonnummerlager",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Telefonnummerkjøp",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Tilpassede måleverdier",
  SUPERADMIN_PROFILE_PERMISSION_ID_RELEASE: "Utgivelser",
  SUPERADMIN_PROFILE_PERMISSION_ID_AUDIT: "Revisjon",
  SUPERADMIN_PROFILE_PERMISSION_ID_APIKEY: "API-nøkkel",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Kontakttilpasset hendelse",
  SUPERADMIN_PROFILE_PERMISSION_ID_CLIENT: "Klient",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACT: "Kontakt",
  SUPERADMIN_PROFILE_PERMISSION_ID_SKILL: "Kompetanse",
  SUPERADMIN_PROFILE_PERMISSION_ID_WALLBOARD: "Oppslagstavle",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Stemmeopptak-transkripsjon",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Stemmeopptak-transkripsjoninnstillinger",
  SUPERADMIN_PROFILE_PERMISSION_ID_POINTSSYSTEM: "Poengsystem",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPR: "Sikkerhet & etterlevelse",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymiser",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSALARM: "Statistikkalarm",
  SUPERADMIN_PROFILE_PERMISSION_ID_AIASSISTANT: "AI-assistent",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICECALL: "Stemmesamtale",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEXTMESSAGE: "Tekstmelding",

  REPLY_OR_NOTE: "Svar eller noter",

  __USERS: "Agenter",
  CONTACT_MANAGEMENT: "Kontakthåndtering",
  NO_QUEUES_SELECTED:
    "Du har ingen køer koblet til organisasjoner som er koblet til deg. Kontakt din organisasjonsadministrator for å løse dette problemet.",
  SOCKET_DISCONNECTED:
    "Din tilkobling til serveren er brutt. Vennligst oppdater vinduet.\n\nAvbrudd skyldes ustabil internettforbindelse eller på grunn av inaktivitet.",
  SOCKET_DISCONNECTED_INACTIVITY: "Socket frakoblet på grunn av inaktivitet",
  WARNING_INACTIVITY:
    'Du vil bli logget ut på grunn av inaktivitet. Gå tilbake til atlas instrumentpanel eller trykk på "Jeg er tilbake" for å forhindre at du kobles fra.',
  IAM_BACK: "Jeg er tilbake!",
  NO_EMAIL_ACCESS:
    "Du har ikke tillatelse til å sende e-post. Kontakt din administrator for mer informasjon.",
  NO_CREATE_CHANNELS: "Du har ikke tilgang til noen kanaler for å opprette ny samtale.",
  NO_OUTBOUND_QUEUES_ON_CREATE_CONVERSATION:
    "Du har ikke tilgang til noen køer med utgående rute. Kontakt din organisasjonsadministrator for å løse dette problemet.",
  SELECT_AGENTPROFILE_ERROR: "En agentprofil må velges for å opprette samtale.",
  SEARCH_AGENT_AND_ASSIGN: "Søk agent og tildel",
  SEARCH_QUEUE_AND_ASSIGN: "Søk kø og tildel",
  EDIT_TAGS: "Rediger etiketter",
  SEARCH_TAGS: "Søk etiketter",
  SELECT_DISPOSITION: "Velg klassifisering",
  MERGE: "Slå sammen",
  MERGE_CONVERSATION: "Slå sammen samtale",
  CONVERSATION_MERGE_EXPLANATION:
    "Interaksjoner fra sekundære saker vil bli lagt til i den primære saken",
  CONTACT_MERGE_EXPLANATION:
    "Samtaler fra sekundær kontakt vil bli lagt til i den primære kontakten",
  SEARCH_CONVERSATION: "Søk samtale",
  SEARCH_CONTACT: "Søk kontakt",
  MERGE_CONTACT: "Slå sammen kontakt",
  PRIMARY: "Primær",
  NO_AGENT: "Ingen agent",
  MAIL_ACCOUNT_IMAP_STATE_A: "Autentisert",
  MAIL_ACCOUNT_IMAP_STATE_N: "Ingen",
  MAIL_ACCOUNT_IMAP_STATE_E: "Feil",
  MAIL_ACCOUNT_IMAP_STATE_U: "Uautorisert",
  MAIL_ACCOUNT_IMAP_STATE_C: "Tilkoblet",
  MAIL_ACCOUNT_IMAP_STATE_CN: "Kobler til",
  MAIL_ACCOUNT_IMAP_STATE_D: "Frakoblet",
  MAIL_ACCOUNT_SMTP_STATE_A: "Autentisert",
  MAIL_ACCOUNT_SMTP_STATE_N: "Ingen",
  MAIL_ACCOUNT_SMTP_STATE_E: "Feil",
  MAIL_ACCOUNT_SMTP_STATE_U: "Uautorisert",
  MAIL_ACCOUNT_SMTP_STATE_C: "Tilkoblet",
  MAIL_ACCOUNT_SMTP_STATE_CN: "Kobler til",
  MAIL_ACCOUNT_SMTP_STATE_D: "Frakoblet",
  IMAP_STATE: "IMAP-status",
  SMTP_STATE: "SMTP-status",
  RECONNECT: "Koble til igjen",
  PERSONIFY_USER: "Personifiser bruker",
  RULES: "Regler",
  JOURNEY_ERROR_LINK_MISSING: "En lenke må være koblet til denne porten",
  JOURNEY_ERROR_FORM_ERROR: "Det er feil i nodeskjemaet. Dobbeltklikk for å redigere skjemaet.",
  JOURNEY_ERROR_DUPLICATE:
    "Dobbel handling! Inngangshandlinger kan ikke forekomme mer enn én gang.",
  PUBLISH_JOURNEY: "Publiser Journey",
  NO_ITEMS_WERE_FOUND: "Ingen elementer ble funnet",
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_PHRASE:
    'Kunne ikke finne noen samtaler eller kontakter som matcher søkefrasen "{{keyword}}"',
  NO_CONVERSATIONS_WERE_FOUND_BY_PHRASE:
    'Ingen samtaler ble funnet som matcher søkefrasen "{{keyword}}"',
  NO_CONTACTS_WERE_FOUND_BY_PHRASE:
    'Ingen kontakter ble funnet som matcher søkefrasen "{{keyword}}"',
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT:
    "Kunne ikke finne noen samtaler eller kontakter fra valgt kontakt",
  NO_CONVERSATIONS_WERE_FOUND_BY_SELECTED_CONTACT: "Ingen samtaler ble funnet fra valgt kontakt",
  NO_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT: "Ingen kontakter ble funnet fra valgt kontakt",
  NO_CONVERSATION_FOUND_ON_FILTER: "Ingen samtaler ble funnet som matcher dine filtre",
  WITH_SELECTED_DO: "Med valgte endre",
  CHANGE_STATUS: "Endre status",
  CHANGE_PRIORITY: "Endre prioritet",
  APPLY_CHANGES_TO_ITEMS: "Bruk endringer på {{count}} elementer",
  APPLY_AND_RESET: "Bruk & Tilbakestill",
  ITEMS_FAILED_TO_UPDATE: "Mislyktes i å oppdatere {{count}} elementer",
  CHANGES_APPLIED_TO_ITEMS: "Endringer brukt på {{count}} elementer",
  MAIL_HAS_NO_BODY: "Ingen e-postinnhold",
  TIME_PAST: "Tid passert",
  START_TYPING_TO_SEARCH: "Begynn å skrive for å søke",
  NOT_REMOVABLE_ENTITY: "Kan ikke slettes!",
  SHOW_MORE: "Vis mer",
  SHOW_LESS: "Vis mindre",
  UPLOAD: "Last opp",
  N_FILES: "{{files}} fil(er)",
  LIST_FILES: "Liste filer",
  LIST_FILE: "Liste fil",
  LIST_ID: "Liste",
  SHEET: "Ark",
  ROW: "Rad",
  SHEET_N: "Ark {{n}}",
  ROW_N: "Rad {{n}}",
  LIST_FILE_FIELDS_HELP: "Radene ovenfor er en forhåndsvisning for å sortere kolonner.",
  WRITE_LISTS: "Skriv lister",
  WRITE_LISTS_HELP: "Lister å skrive importerte kontakter til",
  CLEAN_LISTS: "Rens lister",
  CLEAN_LISTS_HELP: "Lister å rense kontakter mot",
  DIALPAD: "Tastatur",
  NO_NOTIFICATIONS: "Du har ingen varsler!",
  NO_PAUSES_FOUND: "Ingen pauser kunne finnes",
  CONNECTION_AT: "Tilkobling ved",
  RETRIES: "Forsøk",
  NO_CONTACT: "Kontakt ble ikke funnet!",
  VOICE_MANAGEMENT: "Stemmehåndtering",
  JOINED: "Tilkoblet",
  JOINED_QUEUE_DESCRIPTION:
    '"Melding" koblet til køen. Denne porten utløses før forsøk på å distribuere "meldingen".',
  ON_ASSIGN: "Tildelt",
  DONE_ANSWERED: "Ferdig (besvart)",
  DONE_NO_ANSWER: "Ferdig (ingen svar)",
  ON_ASSIGN_QUEUE_DESCRIPTION: "Samtale ble tildelt valgt kø",
  DONE_ANSWERED_QUEUE_DESCRIPTION:
    "Stemmesamtale forlot køen på grunn av avslutning, etter å ha blitt tildelt en agent",
  DONE_NO_ANSWER_QUEUE_DESCRIPTION:
    "Stemmesamtale forlot køen på grunn av tidsavbrudd eller tom kø (avhengig av køinnstillingene)",
  HANGUP: "Legg på",
  RECORD: "Ta opp",
  HOLD: "Parker",
  UNHOLD: "Gjenoppta",
  TRANSFER: "Overfør",
  MUTE: "Demp",
  UNMUTE: "Aktiver lyd",
  DISTRIBUTING_CALL: "Distribuerer samtale",
  VOICE_ASTERISK: "Asterisk",
  VOICE_ASTERISKS: "Asterisker",
  VOICE: "Stemme",
  VOICE_TRUNK: "Trunk",
  VOICE_TRUNKS: "Trunker",
  VOICE_ROUTE: "Rute",
  VOICE_ROUTES: "Ruter",
  SOUND: "Lyd",
  SOUNDS: "Lyder",
  JOURNEY_VARIABLE: "Variabel",
  JOURNEY_VARIABLES: "Variabler",
  REST: "Rest",
  FROM_DOMAIN: "Fra domene",
  ISO2: "ISO 2",
  VOICE_ASTERISK_ID: "Asterisk",
  INBOUND_VOICE_TRUNK_ID: "Innkommende trunk",
  OUTBOUND_VOICE_TRUNK_ID: "Utgående trunk",
  NUMBER: "Nummer",
  INTERNAL: "Intern",
  VOICE_SETTINGS: "Stemmeinnstillinger",
  __OUTBOUND_VOICE_ROUTES: "Utgående stemmeruter",
  VOICE_RECORDING_FORMAT_WAV: "Wav",
  VOICE_RECORDING_FORMAT_WAV49: "Wav49",
  VOICE_RECORDING_FORMAT_GSM: "Gsm",
  NEW_CALL: "Ny samtale",
  START_RECORDING: "Manuell opptak",
  SETTINGS_START_RECORDING_HELP: "Tillat agenter å ta opp samtaler manuelt",
  PLAY_RECORDING: "Spill av opptak",
  DOWNLOAD_RECORDING: "Last ned opptak",
  DOWNLOAD_VOICEMAIL: "Last ned talemelding",
  PERMISSION_SELF_N: "Ingen",
  PERMISSION_SELF_A: "Alle",
  PERMISSION_SELF_S: "Eid av bruker",
  DOWNLOAD_RECORDING_ERROR: "Noe gikk galt ved nedlasting av opptaket.",
  PLAY_RECORDING_ERROR: "Noe gikk galt ved avspilling av opptaket.",
  DOWNLOAD_VOICEMAIL_ERROR: "Noe gikk galt ved nedlasting av talemeldingen.",
  PLAY_VOICEMAIL_ERROR: "Noe gikk galt ved avspilling av talemeldingen.",
  NO_RESULTS: "Ingen resultater",
  VARIABLE_ALIAS: "Variabelalias",
  VOICE_CALL: "Stemmesamtale",
  CURRENT_VOICE_CALL_BRIDGE: "Nåværende samtalebrygge",
  FIRST_VOICE_CALL_BRIDGE: "Første samtalebrygge",
  LAST_VOICE_CALL_BRIDGE: "Siste samtalebrygge",
  HANGED_UP: "La på",
  EDGE_ERROR_FROM_NONE_CHANNEL_SPECIFIC_TO_CHANNEL_SPECIFIC:
    "Koblinger mellom målnode med spesifikke kanaler til kildenode uten spesifikk kanal er ikke tillatt",
  EDGE_ERROR_CHANNEL_DONT_EXIST_IN_FROM_NODE:
    "Alle kanaler i målnoden må være tilgjengelige i kildenoden",
  EDGE_ERROR_CHANNELS_MISMATCH: "Kildenodens kanal matcher ikke målnodens kanaler!",
  JOURNEY_VARIABLE_ID: "Variabel",
  REST_REQUEST_VARIABLE_HELP:
    "Hvis vellykket, sett rest-forespørselens svar til valgt variabel. Data vil bli konvertert til streng.",
  REST_REQUEST_FIELD_HELP:
    "Hvis vellykket, sett rest-forespørselens svar til valgt felt. Data vil bli konvertert til felttype.",
  FIELD_ID: "Felt",
  DATA_TYPE: "Datatype",
  ACW: "ACW",
  VOICE_SETTINGS_ACW_HELP: "After call work",
  MUSICCLASS: "Ventemusikk",
  JOINEMPTY: "Koble til tom",
  FAIL_REASONS: "Feilårsaker",
  FULL: "Full",
  LEAVEEMPTY: "Forlat tom",
  JOINUNAVAIL: "Koble til utilgjengelig",
  LEAVEUNAVAIL: "Forlat utilgjengelig",
  EVENTS: "Hendelser",
  EVENT_ASSIGNED_DESCRIPTION: "Stemmesamtale ble tildelt kø",
  EVENT_JOINED_DESCRIPTION: "Stemmesamtale koblet til kø",
  FAIL_REASON_FULL_DESCRIPTION: "Avsluttet da køen var full",
  FAIL_REASON_TIMEOUT_DESCRIPTION: "Avsluttet da køens tidsgrense ble nådd",
  FAIL_REASON_JOINEMPTY_DESCRIPTION: 'Avsluttet på grunn av køstatus "JOINEMPTY"',
  FAIL_REASON_LEAVEEMPTY_DESCRIPTION: 'Avsluttet på grunn av køstatus "LEAVEEMPTY"',
  FAIL_REASON_JOINUNAVAIL_DESCRIPTION: 'Avsluttet på grunn av køstatus "JOINUNAVAIL"',
  FAIL_REASON_LEAVEUNAVAIL_DESCRIPTION: 'Avsluttet på grunn av køstatus "LEAVEUNAVAIL"',
  VOICE_QUEUE_QUEUE_STATUS: "Køstatus",
  VOICE_SETTINGS_JOINEMPTY_HELP: "Koble til kø når tom",
  LEAVEWHENEMPTY: "Forlat når tom",
  VOICE_SETTINGS_LEAVEWHENEMPTY_HELP: "Forlat kø når tom",
  RINGINUSE: "Ring ved opptatt",
  VOICE_SETTINGS_RINGINUSE_HELP:
    "Styrer om agenten skal motta ytterligere køsamtaler selv når agenten er opptatt.",
  MAXLEN: "Maksimalt antall køsamtaler",
  VOICE_SETTINGS_MAXLEN_HELP:
    "Det maksimale antallet samtaler som tillates å vente i kø. Samtaler som når køen over denne grensen vil fortsette Journey-utføringsstrategien.",
  VOICE_SETTINGS_TIMEOUT_HELP:
    "Hvor lenge det skal ringe hos en agent før agenten anses utilgjengelig.",
  WEIGHT: "Vekt",
  VOICE_SETTINGS_WEIGHT_HELP:
    "Angir prioritet for denne køen når dens agenter er medlemmer i flere køer. En høyere vektverdi betyr at denne køen får prioritet over tilgjengelige agenter.",
  AUTOPAUSE: "Autopause",
  VOICE_SETTINGS_AUTOPAUSE_HELP:
    "Autopause pauser et kømedlem hvis de mislykkes i å svare på en samtale.",
  ANNOUNCE: "Annonser",
  VOICE_SETTINGS_ANNOUNCE_HELP:
    "Melding som spilles av for den svarende agenten før samtalen kobles, vanligvis for å informere agenten hvilken kø som ringer.",
  ANNOUNCE_FREQUENCY: "Annonseringsfrekvens",
  VOICE_SETTINGS_ANNOUNCE_FREQUENCY:
    "Hvor ofte i sekunder oppringerens posisjon i køen eller den estimerte ventetiden skal annonseres. Sett til 0 for å deaktivere.",
  MIN_ANNOUNCE_FREQUENCY: "Minste annonseringsfrekvens",
  VOICE_SETTINGS_MIN_ANNOUNCE_FREQUENCY_HELP:
    "Minste tid mellom start av hver annonsering. Dette er nyttig for køer hvor oppringerens posisjon og/eller ventetid endres raskt for å unngå konstante annonseringer.",
  PERIODIC_ANNOUNCE_FREQUENCY: "Periodisk annonseringsfrekvens",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_FREQUENCY_HELP:
    "Hvor ofte periodiske annonseringer skal gjøres.",
  RANDOM_PERIODIC_ANNOUNCE: "Tilfeldige periodiske annonseringer",
  VOICE_SETTINGS_RANDOM_PERIODIC_ANNOUNCE_HELP:
    "Spill av periodiske annonseringer i tilfeldig rekkefølge.",
  RELATIVE_PERIODIC_ANNOUNCE: "Relative periodiske annonseringer",
  VOICE_SETTINGS_RELATIVE_PERIODIC_ANNOUNCE_HELP:
    "Hvis satt til ja vil den periodiske annonseringsfrekvensen telles fra slutten av hver annonsering i stedet for fra starten av hver annonsering.",
  ANNOUNCE_HOLDTIME: "Annonser ventetid",
  VOICE_SETTINGS_ANNOUNCE_HOLDTIME_HELP:
    "Spill av den estimerte ventetiden for oppringere som venter i køen.",
  ANNOUNCE_POSITION: "Annonser posisjon",
  VOICE_SETTINGS_ANNOUNCE_POSITION_HELP:
    'Spill av køposisjonen for oppringere som venter i køen. Når du bruker alternativene "grense" eller "mer" må du også stille inn Annonser posisjonsgrense.',
  ANNOUNCE_POSITION_LIMIT: "Annonser posisjonsgrense",
  VOICE_SETTINGS_ANNOUNCE_POSITION_LIMIT_HELP:
    'Angir grensen for når oppringerens posisjon i køen skal spilles av. Hvis "Annonser posisjon" er satt til "grense" vil posisjonen kun spilles av for de under grensen. Hvis satt til "mer" vil den spilles av for oppringere over grensen.',
  ANNOUNCE_POSITION_ONLY_UP: "Annonser posisjonsendring",
  VOICE_SETTINGS_ANNOUNCE_POSITION_ONLY_UP_HELP:
    "Annonser kun oppringerens posisjon hvis den har forbedret seg siden siste annonsering.",
  REPORTHOLDTIME: "Rapporter ventetid",
  VOICE_SETTINGS_REPORTHOLDTIME_HELP:
    "Rapporter ventetiden til den svarende agenten før samtalen kobles til agenten.",
  PERIODIC_ANNOUNCE: "Periodiske annonseringer",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_HELP:
    "Velg de periodiske annonseringene og deres rekkefølge. Når den siste annonseringen har blitt spilt av starter avspillingen fra begynnelsen.",
  ASTERISK_MUSICCLASS_DEFAULT: "Standard",
  ASTERISK_MUSICCLASS_CELTIC: "Keltisk",
  ASTERISK_MUSICCLASS_JAZZ: "Jazz",
  ASTERISK_MUSICCLASS_CHILLOUT: "Avslapping",
  ASTERISK_MUSICCLASS_GOODBYE: "Ha det bra",
  ASTERISK_MUSICCLASS_YESTERDAY: "I går",
  ASTERISK_MUSICCLASS_BLUES: "Blues",
  ASTERISK_MUSICCLASS_UPBEAT: "Oppløftende",
  ASTERISK_MUSICCLASS_WALTZ: "Vals",
  ASTERISK_MUSICCLASS_CLAPS: "Applaus",
  ASTERISK_MUSICCLASS_CHEERFUL: "Glad",
  ASTERISK_MUSICCLASS_LULLABY: "Vuggevise",
  ASTERISK_MUSICCLASS_HAPPY: "Lykkelig",
  ASTERISK_MUSICCLASS_HIDDEN: "Skjult",
  ASTERISK_AUTOPAUSE_YES: "Ja",
  ASTERISK_AUTOPAUSE_NO: "Nei",
  ASTERISK_AUTOPAUSE_ALL: "Alle",
  ASTERISK_ANNOUNCE_HOLDTIME_YES: "Ja",
  ASTERISK_ANNOUNCE_HOLDTIME_NO: "Nei",
  ASTERISK_ANNOUNCE_HOLDTIME_ONCE: "En gang",
  ASTERISK_ANNOUNCE_POSITION_YES: "Ja",
  ASTERISK_ANNOUNCE_POSITION_NO: "Nei",
  ASTERISK_ANNOUNCE_POSITION_LIMIT: "Grense",
  ASTERISK_ANNOUNCE_POSITION_MORE: "Mer",
  RECORDING: "Opptak",
  ERROR_CHILD_HAS_ASTERISK:
    "En eller flere underorganisasjoner har kobling til asterisk. Overordnet organisasjon til slike underorganisasjoner får ikke være koblet til asterisk.",
  ERROR_PARENT_HAS_ASTERISK:
    "Forbudt å velge asterisk når overordnet organisasjon har kobling til asterisk.",
  ERROR_ORGANIZATION_INACTIVATE_WITH_ACTIVE_CHILD:
    "Organisasjon med aktive underorganisasjoner kan ikke deaktiveres. Sørg for å deaktivere alle underorganisasjoner før denne handlingen utføres.",
  VOICE_VOICEMAIL: "Talepostkasse",
  VOICE_VOICEMAILS: "Talepostkasser",
  VOICE_VOICEMAIL_ID: "Talepostkasse",
  TIMES_TO_RETRY: "Antall forsøk",
  MAX_DIGITS: "Maks antall siffer",
  ASTERISK_SOUND_ID: "Lyd",
  GOT_DIGITS: "Fikk siffer",
  FAILED: "Mislyktes",
  CHANSPY: "Avlytt",
  CHANSPIED_ON: "Avlyttet",
  VOICE_LICENSE: "Stemmelisens",
  LOGIN_PAUSE: "Innloggingspause",
  AUTO_ANSWER: "Automatisk svar",
  VOICE_SETTINGS_CHANSPY_HELP: 'Tillat avlytting av andre brukere der "Avlyttet" er aktivert',
  VOICE_SETTINGS_CHANSPIED_ON_HELP:
    "Tillat brukere med avlyttingstillatelse å avlytte denne brukeren",
  TWO_FACTOR_AUTH_HELP:
    "La være deaktivert for å lese organisasjonens 2fa-innstillinger, aktiver for å overstyre innstillingene",
  TWO_FACTOR_AUTH_ENABLE_HELP:
    "Aktiver/deaktiver 2fa for denne brukeren og overstyr organisasjonsinnstillinger",
  DELAY: "Forsinkelse",
  LEAVE_EMPTY_FOR_ALL_QUEUES: "La stå tomt for alle køer",
  AUTO_ACCEPT: "Automatisk aksepter",
  ENABLE_TO_SELECT_SPECIFIC_QUEUES: "Aktiver for å akseptere spesifikke køer",
  AUTO_ACCEPT_HELP: "Aksepter automatisk mottatte varsler",
  ADD_CALL_TO_CONVERSATION: "Legg til samtale i konversasjon",
  CALL_SEARCH: "Skriv nummer eller navn",
  SELECT_VOICE_QUEUE_ROUTE: "Velg kø & rute",
  SOMETHING_WENT_WRONG: "Noe gikk galt",
  SPY: "Avlytt",
  NO_CONTACT_SUGGESTIONS_WERE_FOUND: "Ingen kontakter ble funnet å foreslå!",
  MAIL_SETTINGS: "E-postinnstillinger",
  REPLY_TO: "Svar til",
  REPLIED_TO: "Svarte til",
  NOTE: "Notat",
  ANSWER: "Svar",
  CALL_RECORDING: "Samtaleopptak",
  OUTBOUND_CALL: "Utgående samtale",
  LEAVE_DISABLED_FOR_DEFAULT: "La være deaktivert for standard",
  SELECT_NUMBER: "Velg nummer",
  RINGING: "Ringer",
  STOP: "Stopp",
  STOP_RECORDING: "Stopp opptak",
  DOWNLOAD: "Last ned",
  RECORDING_CONVERSATION: "Tar opp samtale",
  RECORDING_VOICEMAIL: "Tar opp talemelding",
  VOICEMAIL: "Talemelding",
  CALL_BACK: "Ring tilbake",
  WAIT_TIME: "Ventetid",
  TALK_TIME: "Samtaletid",
  CALL_LENGTH: "Samtalelengde",
  MISSED_CALL: "Tapt anrop",
  LOCAL_PHONE_NUMBER: "Lokalt telefonnummer",
  PHONE: "Telefon",
  PHONE_NUMBER: "Telefonnummer",
  NOT_ASSIGNED: "Ikke tildelt",
  CONVERSATION_ALREADY_ASSIGNED: "Samtalen er allerede tildelt!",
  OUTBOUND_VOICE_QUEUE_ID: "Utgående kø",
  OUTBOUND_VOICE_QUEUE_ROUTE_ID: "Utgående rute",
  CONVERSATION_MERGE_FROM: "Denne samtalen har blitt slått sammen med samtale {{conversation}}",
  CONVERSATION_MERGE_TO: " ble slått sammen med denne samtalen {{conversation}}",
  ON_HOLD: "Parkert",
  LISTENED_IN: "Avlyttet",
  LISTEN_ONLY: "Kun lytt",
  LISTEN_AND_TALK_TO_AGENT: "Lytt & snakk med agent",
  SELECT_AN_OPTION: "Velg et alternativ",
  ONGOING_CALL: "Pågående samtale",
  INTERNAL_NOTE: "Internt notat",
  VIEW_DETAILS: "Vis detaljer",
  CONTAINS_TRANSFER_FROM: "Inneholder overføring fra",
  TRANSFERRED_TO: "Overført til",
  HAS_NOTE: "Har notat",
  NOTE_TO: "Notat til",
  REPLIED_BY: "Besvart av",
  SELECT_QUEUE_TO_SELECT_CONTACT: "Kø må velges før kontakt kan velges!",
  CONTACT_NOT_AUTOMATICALLY_ASSIGNED: "En kontakt kunne ikke tildeles automatisk!",
  NEW_MAIL_MESSAGE: "Ny e-postmelding",
  UNABLE_TO_PERSONIFY:
    "Kan ikke personifisere denne kontoen. Dette skyldes trolig dine tillatelser.",
  USER_CREATE_USERNAME_TAKEN:
    "Brukernavnet du prøver å bruke er allerede tatt! Merk at dette brukernavnet kan tilhøre en annen organisasjon!",
  USER_CREATE_EMAIL_TAKEN:
    "E-postadressen du prøver å bruke er allerede tatt! Merk at denne e-postadressen kan tilhøre en annen organisasjon!",
  USER_UPDATE_EMAIL_TAKEN:
    "E-postadressen du prøver å bruke er allerede tatt! Merk at denne e-postadressen kan tilhøre en annen organisasjon!",
  SOCKET_DISCONNECTED_USER_ACTIVE_NOT:
    "En administrator har deaktivert kontoen din. Du vil bli logget ut!",
  MAY_NOT_DELETE_YOURSELF: "Du kan ikke slette deg selv!",
  NO_DATA_CREATED_YET: "Ingen data er opprettet ennå!",
  NO_DATA_MATCHING_YOUR_FILTERS: "Ingen data funnet som matcher filtrene dine!",
  RESET_FILTERS: "Tilbakestill filtre",
  FORM_PREVIEW_NOT_EDITABLE: "Kun forhåndsvisning! Denne egenskapen kan ikke endres.",
  AGENTPROFILE_AGENTS: "Agentprofilens agenter",
  ADMINPROFILE_USERS: "Adminprofilens brukere",
  QUEUE_AGENTS: "Køagenter",
  NO_USERS_WERE_FOUND: "Ingen brukere ble funnet",
  NO_AGENTS_WERE_FOUND: "Ingen agenter ble funnet",
  DRAFT_FROM_MAIL_ACCOUNT_REQUIRED: "Fra e-postkonto kreves",
  DRAFT_TO_MAIL_ACCOUNT_REQUIRED: "Til e-postadresse kreves",
  MAIL_MESSAGE_BODY_REQUIRED_ON_OUTBOUND: "E-postmelding kreves!",
  SMTP_ERROR: "Feil ved sending av e-post",
  RESEND_EMAIL: "Send e-post på nytt",
  RESEND_INITIATED: "Ny sending initiert",
  MAIL_MESSAGE_NOT_FAILED: "Ny sending kun tilgjengelig for mislykkede e-postmeldinger",
  NO_SUBJECT: "Ingen emne",
  NO_CUSTOM_FILTERS_CREATED: "Ingen tilpassede filtre opprettet",
  DRAFT_SEND_CONVERSATION_QUEUE_REQUIRED: "Kø må velges for samtale før utkast kan sendes",
  MAIL_ACCOUNT_NOT_IN_queueS:
    "Valgt e-postkonto er ikke tilgjengelig i køens utgående e-postkontoer",
  MAIL_ACCOUNT_REQUIRED: "E-postkonto kreves",
  UNANSWERED_CALL: "Ubesvart anrop",
  SELECT_OR_CREATE_CONTACT: "Velg eller opprett kontakt",
  ROUTING: "Ruting",
  TRUNK_TYPE_CONNECTEL: "Connectel standard",
  TRUNK_TYPE_CUSTOM: "Tilpasset trunk",
  MAIL_ACCOUNT_AUTHENTICATION_FAILED: "E-postkontots autentisering mislyktes",
  AUDIO_NOT_PLAYABLE: "Lydfil kan ikke spilles av",
  SIP_STATE: "SIP-status",
  SIP_STATE_UNDEFINED: "Ikke initiert",
  SIP_STATE_INITIAL: "Kobler til",
  SIP_STATE_UNREGISTERED: "Uregistrert",
  SIP_STATE_TERMINATED: "Avsluttet",
  SIP_STATE_REGISTERED: "Registrert",
  ACTIVE_IN_QUEUE: "For tiden aktiv i kø",
  FILENAME: "Fil",
  PRESSED_NONE_VALID: "Trykket på ugyldig alternativ",
  PRESSED_1: "Trykket på 1",
  PRESSED_2: "Trykket på 2",
  PRESSED_3: "Trykket på 3",
  PRESSED_4: "Trykket på 4",
  PRESSED_5: "Trykket på 5",
  PRESSED_6: "Trykket på 6",
  PRESSED_7: "Trykket på 7",
  PRESSED_8: "Trykket på 8",
  PRESSED_9: "Trykket på 9",
  "PRESSED_*": "Trykket på *",
  "PRESSED_#": "Trykket på #",
  INITIATED: "Initiert",
  RESET_2FA: "Tilbakestill tofaktorautentisering",
  PASSWORD_RESET_SUCCESSFUL: "Tilbakestilling av passord vellykket!",
  PASSWORD_RESET_REQUEST_SUCCESSFUL: "Forespørsel om tilbakestilling av passord vellykket!",
  PASSWORD_INVALID: "Passordet må være minst 6 tegn langt",
  FILL_PASSWORD_TO_RESET: "Fyll inn passord for å tilbakestille",
  RESET_TOKEN_HAS_EXPIRED:
    "Ugyldig eller utløpt token. Vennligst be om en ny tilbakestilling av passord.",
  VOICE_TIMELINE_ERROR: "Feil",
  INBOUND_CALL_INITIATED: "Innkommende samtale initiert",
  OUTBOUND_CALL_INITIATED: "Utgående samtale initiert",
  INTERNAL_CALL_INITIATED: "Intern samtale initiert",
  SYSTEM_ANSWER: "System mottok samtale",
  TIMELINE_JOURNEY_JOB: "Journey-handling",
  RULE_WITH_INDEX_X_MATCHED: 'Regel med indeks "{{i}}" matchet',
  TRY_X_BUTTON_X_PRESSED: 'Forsøk {{try}}: Knapp "{{num}}" trykket',
  TRY_X_DIGITS_X_PRESSED: 'Forsøk {{try}}: Siffer "{{num}}" trykket',
  USER_X_PINGED: 'Agent "{{user}}" pinget',
  USER_X_NOTIFICATION_END: 'Agent "{{user}}" ping avsluttet',
  NOTIFICATION_X_ACTION: "Handling: {{action}}",
  TIMEOUT_X_SECONDS: "Tidsavbrudd: {{sec}} sekunder",
  NOTIFICATION_TIMEOUT_X_SECONDS: "Varsel tidsavbrudd: {{sec}} sekunder",
  VOICE_QUEUE_ASSIGNED: "Tildelt til stemmesamtalekø",
  VOICE_QUEUE_JOINED: "Koblet til stemmesamtalekø",
  VOICE_QUEUE_DONE: "Forlot stemmesamtalekø",
  NOTIFICATION_ACTION_ACCEPT: "Akseptert",
  NOTIFICATION_ACTION_ACCEPT_FAIL: "Akseptering mislyktes",
  NOTIFICATION_ACTION_REJECT: "Avvist",
  NOTIFICATION_ACTION_REJECT_FAIL: "Avvisning mislyktes",
  NOTIFICATION_ACTION_TIMEOUT: "Tidsavbrudd",
  NOTIFICATION_ACTION_ANSWERED_ELSEWHERE: "Besvart annet sted",
  NOTIFICATION_ACTION_SYSTEM_HANGED_UP: "System la på (tidsavbrudd)",
  NOTIFICATION_ACTION_CONTACT_HANGED_UP: "Kontakt la på før tidsavbrudd/svar",
  RECORDING_STARTED_BY_X: 'Opptak startet av "{{user}}"',
  RECORDING_STOPPED_BY_X: 'Opptak stoppet av "{{user}}"',
  SYSTEM: "System",
  X_LEFT_CALL: "{{leg}} forlot samtalen",
  LEFT_CALL_WITH_REASON: 'Forlot broen med årsak "{{reason}}"',
  LEFT_QUEUE_WITH_REASON: 'Forlot køen "{{queue}}" med årsak "{{reason}}"',
  UNKNOWN: "Ukjent",
  USER_ANSWERED: '"{{user}}" svarte',
  QUEUE_X_WITH_TIMEOUT: 'Kø "{{queue}}", med {{timeout}} sekunders tidsavbrudd',
  AVAILABLE_VOICE_MENU_OPTIONS: 'Tilgjengelige alternativer "{{options}}", og "{{retries}}" forsøk',
  CALL_TRANSFERRED_OUT: "Samtale overført ut",
  CALL_TRANSFERRED_IN: "Samtale overført inn",
  ATTENDED: "Overvåket",
  BLIND: "Blind",
  TRANSFER_TYPE_X: 'Overføringstype "{{type}}"',
  ROUTE_X: 'Rute "{{route}}"',
  CONTACT_ANSWERED: "Kontakt svarte",
  BG_COLOR: "Bakgrunnsfarge",
  SUB_GROUP: "Undergruppe",
  VARIABLE: "Variabel",
  STATIC: "Statisk",
  KEY: "Nøkkel",
  ESCAPE_DIGITS: "Escape-siffer",
  LANGUAGE: "Språk",
  LANGUAGE_SE: "se (Svensk)",
  LANGUAGE_FI: "fi (Finsk)",
  LANGUAGE_EN: "en (Engelsk)",
  LANGUAGE_DE: "de (Tysk)",
  LANGUAGE_FR: "fr (Fransk)",
  LANGUAGE_HI: "hi (Hindi)",
  LANGUAGE_IT: "it (Italiensk)",
  LANGUAGE_NO: "no (Norsk)",
  LANGUAGE_NL: "nl (Nederlandsk)",
  LANGUAGE_DK: "dk (Dansk)",
  LANGUAGE_PL: "pl (Polsk)",
  VOICE_LANGUAGE_SE: "se (Svensk)",
  VOICE_LANGUAGE_FI: "fi (Finsk)",
  VOICE_LANGUAGE_EN: "en (Engelsk)",
  VOICE_LANGUAGE_DE: "de (Tysk)",
  VOICE_LANGUAGE_FR: "fr (Fransk)",
  VOICE_LANGUAGE_HI: "hi (Hindi)",
  VOICE_LANGUAGE_IT: "it (Italiensk)",
  VOICE_LANGUAGE_NO: "no (Norsk)",
  VOICE_LANGUAGE_NL: "nl (Nederlandsk)",
  VOICE_LANGUAGE_DK: "dk (Dansk)",
  VOICE_LANGUAGE_PL: "pl (Polsk)",
  VOICE_SHORTCUTS: "Stemmekommandoer",
  VOICE_SHORTCUT: "Stemmekommando",
  __VOICE_SHORTCUTS: "Hurtigtaster",
  HEADER_FILTERS_COUNT: "Tilbakestill {{count}} filtre",
  REAUTHENTICATE_ACCOUNT: "Reautentiser",
  IS_LIVE: "Live",
  IS_SHARED: "Delt",
  SHARED: "Delt",
  WITH: "Med",
  ADMINPROFILE_NO_FILTER_ON_CREATION:
    "Adminprofil-tillatelse kan ikke ha noe filter ved opprettelse. Opprett adminprofilen uten filter og legg til filter ved redigering!",
  ADMINPROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Adminprofilens filter må inneholde seg selv for tillatte tillatelser!",
  SUPERADMIN_PROFILE_NO_FILTER_ON_CREATION:
    "Superadmin-profil tillatelse kan ikke ha noe filter ved opprettelse. Opprett superadmin-profilen uten filter og legg til filter ved redigering!",
  SUPERADMIN_PROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Superadmin-profilens filter må inneholde seg selv for tillatte tillatelser!",
  AUDIO_SETTINGS: "Lydinnstillinger",
  SELECT_RINGTONE_SPEAKER: "Velg ringetone høyttaler",
  RINGTONE_SPEAKER: "Ringetone høyttaler",
  SELECT_VOICE_SPEAKER: "Velg stemme høyttaler",
  VOICE_SPEAKER: "Stemme høyttaler",
  SELECT_MICROPHONE: "Velg mikrofon",
  ERROR_SETTING_SPEAKER:
    "Det oppstod en feil ved innstilling av høyttaleren, velg en annen høyttaler før du fortsetter!",
  ERROR_SETTING_RINGTONE_SPEAKER:
    "Det oppstod en feil ved innstilling av ringetone høyttaleren, velg en annen høyttaler før du fortsetter!",
  NO_SPEAKERS_FOUND: "Ingen høyttalere funnet!",
  NO_MICROPHONES_FOUND: "Ingen mikrofoner funnet!",
  SELECTED_RINGTONE_SPEAKER_NOT_FOUND:
    'Valgt ringetone høyttaler ble ikke funnet! Vi endret din ringetone høyttaler til "systemstandard" til du gjør en endring!',
  SELECTED_SPEAKER_NOT_FOUND:
    'Valgt høyttaler ble ikke funnet! Vi endret din høyttaler til "systemstandard" til du gjør en endring!',
  SELECTED_MICROPHONE_NOT_FOUND:
    'Valgt mikrofon ble ikke funnet! Vi endret din mikrofon til "systemstandard" til du gjør en endring!',
  DEFAULT_MICROPHONE: "Standard mikrofon",
  DEFAULT_SPEAKER: "Standard høyttaler",
  BROWSER_DOES_NOT_SUPPORT_SPEAKER_SET: "Nettleseren din støtter ikke valg av høyttaler",
  UPLOAD_RECORDING: "Last opp opptak",
  RESTART: "Start på nytt",
  RECORD_VOICE: "Ta opp stemme",
  RECORDING_UPLOAD_SUCCESSFULL: "Opplasting vellykket",
  RECORDING_UPLOAD_ERROR: "Det oppstod en feil under opplasting",
  WE_COULD_NOT_DETECT_SOUND_FROM_MICROPHONE: "Vi kunne ikke oppdage lyd fra mikrofonen din",
  ECHO_TEST: "Enhetstest",
  START: "Start",
  START_TEST: "Start test",
  STOP_TEST: "Stopp test",
  ECHO_TEST_SOUND_QUESTION: "Hører du lyden?",
  ECHO_TEST_MICROPHONE_QUESTION: "Hører du ekkoet?",
  ECHO_TEST_PASSED: "Bra. Høyttaleren og mikrofonen din ser ut til å fungere som forventet!",
  PLEASE_CONFIRM_FOLLOWING: "Vennligst bekreft følgende",
  PERIOD: "Periode",
  TREND: "Trend",
  ADJUST: "Juster",
  TEXT_INDICATOR: "Indikator",
  METRICS: "Målinger",
  GROUP_BY: "Grupper etter",
  SUB_GROUP_BY: "Undergrupper etter",
  SORT_BY: "Sorter etter",
  DESC: "Synkende",
  SPLIT_BY: "Del etter",
  SUMMARY: "Sammendrag",
  MAIN_CHANNELS: "Main channels",
  THREAD_CHANNELS: "Thread channels",
  MESSAGE_CHANNELS: "Message channels",
  ORGANIZATION_IDS: "Organizations",
  CONVERSATION_USER_IDS: "Conversation users",
  THREAD_USER_IDS: "Thread users",
  MESSAGE_USER_IDS: "Message users",
  CONVERSATION_QUEUE_IDS: "Conversation queues",
  THREAD_QUEUE_IDS: "Thread queues",
  MESSAGE_QUEUE_IDS: "Message queues",
  DISPOSITION_IDS: "Dispositions",
  SUB_DISPOSITION_IDS: "Sub dispositions",
  THRD_DISPOSITION_IDS: "Third dispositions",
  PAUSE_IDS: "Pauser",
  TEAM_IDS: "Team",
  THREAD_TEAM_IDS: "Thread teams",
  MESSAGE_TEAM_IDS: "Meldingsteam",
  MAIL_ACCOUNT_IDS: "E-postkontoer",
  VOICE_ROUTE_IDS: "Stemmeruter",
  UI: "Grensesnitt",
  TABLE: "Tabell",
  GROUP: "Gruppe",
  ODD_ROW: "Oddetallsrad",
  EVEN_ROW: "Partallsrad",
  WIDTH: "Bredde",
  COLOR: "Farge",
  BORDER: "Kant",
  DISPLAY_HEADER: "Vis overskrift",
  LEGEND: "Tegnforklaring",
  PLACEMENT: "Plassering",
  CUTOUT: "Utskjæring",
  NESTED_METRICS: "Målinger",
  SUBTRACT: "Trekk fra",
  METRIC: "Måling",
  VALUE_ARR: "Alternativer",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DATA: "Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFDATA: "Forskjell",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFPERCENTAGE: "Prosent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROW: "Pil",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDATA: "Pil + Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFDATA: "Pil + Forskjell",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFPERCENTAGE: "Pil + Prosent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICON: "Ikon",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDATA: "Ikon + Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFDATA: "Ikon + Forskjell",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFPERCENTAGE: "Ikon + Prosent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_BACKGROUND: "Bakgrunn",
  STATS_WIDGET_TYPE_TABLE: "Tabell",
  STATS_WIDGET_TYPE_GRID: "Rutenett",
  STATS_WIDGET_TYPE_BAR: "Søyle",
  STATS_WIDGET_TYPE_LINE: "Linje",
  STATS_WIDGET_TYPE_TEXT: "Tekst",
  STATS_WIDGET_TYPE_PIE: "Sirkel",
  STATS_WIDGET_TYPE_RADAR: "Radar",
  STATS_WIDGET_TYPE_POLAR: "Polar",
  STATS_WIDGET_TYPE_TIMELINE: "Tidslinje",
  STATS_WIDGET_PERIOD_THIS_MONTH: "Denne måneden",
  STATS_WIDGET_PERIOD_LIVE_TODAY: "I dag (Live)",
  STATS_WIDGET_PERIOD_TODAY: "I dag",
  STATS_WIDGET_PERIOD_LAST_24_HOURS: "Siste 24 timer",
  STATS_WIDGET_PERIOD_YESTERDAY: "I går",
  STATS_WIDGET_PERIOD_THIS_WEEK: "Denne uken",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN: "Denne uken (start søndag)",
  STATS_WIDGET_PERIOD_THIS_WEEK_EXCEPT_TODAY: "Denne uken (unntatt i dag)",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN_EXCEPT_TODAY: "Denne uken (start søndag, unntatt i dag)",
  STATS_WIDGET_PERIOD_LAST_WEEK: "Forrige uke",
  STATS_WIDGET_PERIOD_LAST_7_DAYS: "Siste 7 dager",
  STATS_WIDGET_PERIOD_THIS_MONTH_EXCEPT_TODAY: "Denne måneden (unntatt i dag)",
  STATS_WIDGET_PERIOD_LAST_30_DAYS: "Siste 30 dager",
  STATS_WIDGET_PERIOD_LAST_MONTH: "Forrige måned",
  STATS_WIDGET_PERIOD_CUSTOM: "Tilpasset",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_DAY: "Dag(er)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_WEEK: "Uke(r)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_MONTH: "Måned(er)",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GT: "Større enn",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GTE: "Større enn eller lik",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LT: "Mindre enn",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LTE: "Mindre enn eller lik",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NOT_EQUAL_NUM: "Ikke lik",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_EQUAL_NUM: "Lik",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_ANY_OF_NUM: "Noen av valgte alternativer",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NONE_OF_NUM: "Ingen av valgte alternativer",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT: "Større enn",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT_TREND: "Større enn trendverdi",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE: "Større enn eller lik",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE_TREND: "Større enn eller lik trendverdi",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT: "Mindre enn",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT_TREND: "Mindre enn trendverdi",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE: "Mindre enn eller lik",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE_TREND: "Mindre enn eller lik trendverdi",
  STATS_WIDGET_THRESHOLD_COMPARISON_NOT_EQUAL_NUM: "Ikke lik",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM: "Lik",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM_TREND: "Lik trendverdi",
  STATS_WIDGET_THRESHOLD_COMPARISON_ANY_OF_NUM: "Noen av valgte alternativer",
  STATS_WIDGET_THRESHOLD_COMPARISON_NONE_OF_NUM: "Ingen av valgte alternativer",
  METRIC_FORMAT_STATIC: "Statisk verdi",
  METRIC_FORMAT_ValuePCS: "Stk (Stykker)",
  METRIC_FORMAT_ValuePercent: "% (Prosent)",
  METRIC_FORMAT_ValueDecimal: "Desimal",
  METRIC_FORMAT_SECONDS_TO_HH_MM: "TT:mm",
  METRIC_FORMAT_HH_MM: "TT:mm",
  METRIC_FORMAT_SECONDS_TO_HH_MM_SS: "TT:mm:ss",
  METRIC_FORMAT_SECONDS_TO_MM_SS: "mm:ss",
  METRIC_FORMAT_HH_MM_SS: "TT:mm:ss",
  METRIC_FORMAT_COUNTDOWN: "Nedtelling",
  METRIC_FORMAT_MM_SS: "mm:ss",
  METRIC_FORMAT_YEAR_MONTH_SHORT: "År Måned (kort)",
  METRIC_FORMAT_YEAR_MONTH_LONG: "År Måned",
  METRIC_FORMAT_MONTH_SHORT: "Måned (kort)",
  METRIC_FORMAT_MONTH_LONG: "Måned",
  METRIC_FORMAT_YEAR_WEEK_NUMBER: "År Ukenummer",
  METRIC_FORMAT_WEEK_NUMBER: "Ukenummer",
  METRIC_FORMAT_D_SLASH_M: "Dag/Måned",
  METRIC_FORMAT_D_SLASH_M_HH_mm: "Dag/Måned TT:mm",
  METRIC_FORMAT_YYYYMMDD: "ÅÅÅÅMMDD",
  METRIC_FORMAT_YYYY_MM_DD: "ÅÅÅÅ/MM/DD",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm: "ÅÅÅÅ/MM/DD TT:mm",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm_ss: "ÅÅÅÅ/MM/DD TT:mm:ss",
  METRIC_FORMAT_HOUR_OF_DAY_24H: "Time på dagen (24T)",
  METRIC_FORMAT_HOUR_OF_DAY_12H: "Time på dagen (12T)",
  METRIC_FORMAT_WEEKDAY_SHORT: "Ukedag (kort)",
  METRIC_FORMAT_WEEKDAY_LONG: "Ukedag",
  METRIC_FORMAT_HH_mm: "TT:mm",
  METRIC_FORMAT_mm_ss: "mm:ss",
  METRIC_FORMAT_HH_mm_ss: "TT:mm:ss",
  W_WEEK_SHORT: "U",
  PM: "e.m.",
  AM: "f.m.",
  MONTH_SHORT_1: "Jan",
  MONTH_SHORT_2: "Feb",
  MONTH_SHORT_3: "Mar",
  MONTH_SHORT_4: "Apr",
  MONTH_SHORT_5: "Mai",
  MONTH_SHORT_6: "Jun",
  MONTH_SHORT_7: "Jul",
  MONTH_SHORT_8: "Aug",
  MONTH_SHORT_9: "Sep",
  MONTH_SHORT_10: "Okt",
  MONTH_SHORT_11: "Nov",
  MONTH_SHORT_12: "Des",
  MONTH_LONG_1: "Januar",
  MONTH_LONG_2: "Februar",
  MONTH_LONG_3: "Mars",
  MONTH_LONG_4: "April",
  MONTH_LONG_5: "Mai",
  MONTH_LONG_6: "Juni",
  MONTH_LONG_7: "Juli",
  MONTH_LONG_8: "August",
  MONTH_LONG_9: "September",
  MONTH_LONG_10: "Oktober",
  MONTH_LONG_11: "November",
  MONTH_LONG_12: "Desember",
  WEEKDAY_SHORT_6: "Søn",
  WEEKDAY_SHORT_0: "Man",
  WEEKDAY_SHORT_1: "Tir",
  WEEKDAY_SHORT_2: "Ons",
  WEEKDAY_SHORT_3: "Tor",
  WEEKDAY_SHORT_4: "Fre",
  WEEKDAY_SHORT_5: "Lør",
  WEEKDAY_LONG_6: "Søndag",
  WEEKDAY_LONG_0: "Mandag",
  WEEKDAY_LONG_1: "Tirsdag",
  WEEKDAY_LONG_2: "Onsdag",
  WEEKDAY_LONG_3: "Torsdag",
  WEEKDAY_LONG_4: "Fredag",
  WEEKDAY_LONG_5: "Lørdag",
  ONGOING_CALLS: "Pågående samtaler",
  RECORDING_NEEDS_TO_BE_UPLOADED: "Sørg for å laste opp opptaket!",
  RECORDING_STARTED: "Opptak startet",
  RECORDING_STOPPED: "Opptak stoppet",
  DUE_DATE: "Forfallsdato",
  MAIL_ACCOUNT_NOT_IN_QUEUES:
    "Valgt e-postkonto er ikke tilgjengelig som utgående e-postkonto for gjeldende samtalekø",
  NO_EMAIL_ON_CONTACT: "Det finnes ingen e-postadresser på kontakten å svare til!",
  FORWARD: "Videresend",
  LEFT_BRIDGE: "Bro avsluttet",
  DESTINATION_PHONE: "Destinasjon {{phone}}",
  PARAMS_PHONE: "Telefon: {{phone}}",
  PARAMS_JOURNEY: "Reise: {{journey}}",
  CALLEE_ANSWERED: "Mottakeren svarte",
  SOCKET_CONNECTION_ERROR: 'Feil ved tilkobling til websocket: "{{error}}"',
  CONTACT_FORM_HAS_NO_FIELDS:
    "Kontaktskjemaet har ingen felt! En administrator må legge til felt i kontaktskjemaet som er koblet til den valgte køen (Opprett liste)",
  NOTIFICATION_REQUEST_NOT_SUPPORTED:
    "Nettleseren din støtter ikke varsler, vennligst bytt nettleser!",
  NOTIFICATION_REQUEST_DENIED:
    "Du har avslått nettleservarsler. Sørg for at nettleservarsler er tillatt!",
  NOTIFICATION_REQUEST_CATCH:
    "Det oppstod en feil ved forespørsel om nettleservarslingstillatelser! {{error}}",
  INACTIVITY_READY: "Logg ut ved klar-status",
  INACTIVITY_PAUSE: "Logg ut ved pause-status",
  PDF: "pdf",
  IMAGE: "bilde",
  STATS_WIDGET: "Widget",
  ADVANCED_OPTIONS: "Avanserte alternativer",
  HIDE: "Skjul",
  NO_SPECIFIC_USER: "Ingen bruker",
  NO_SPECIFIC_QUEUE: "Ingen kø",
  NO_SPECIFIC_DISPOSITION: "Ingen disposisjon",
  NO_SPECIFIC_PAUSE: "Ingen pause",
  NO_SPECIFIC_DIRECTION: "Ingen retning",
  NO_SPECIFIC_CHANNEL: "Ingen kanal",
  NO_SPECIFIC_ORGANIZATION: "Ingen organisasjon",
  NO_SPECIFIC_CLIENT: "Ingen klient",
  NO_SPECIFIC_ACCOUNT: "Ingen konto",
  USER_ID_X: "Bruker-id {{x}}",
  QUEUE_ID_X: "Kø-id {{x}}",
  DISPOSITION_ID_X: "Disposisjons-id {{x}}",
  ORGANIZATION_ID_X: "Organisasjons-id {{x}}",
  CLIENT_ID_X: "Klient-id {{x}}",
  ACCOUNT_ID_X: "Konto-id {{x}}",
  PAUSE_ID_X: "Pause-id {{x}}",
  ALIGNMENT: "Justering",
  THRESHOLDS: "Terskelverdier",
  BOX_COLOR: "Ruterfarge",
  FONT_STYLE: "Tekststil",
  FORMAT: "Format",
  USER_SELF: "Personifisert data",
  FONT_STYLE_ITALIC: "Kursiv",
  FONT_STYLE_BOLD: "Fet",
  "FONT_STYLE_BOLD-ITALIC": "Fet-kursiv",
  ALIGNMENT_CENTER: "Sentrert",
  ALIGNMENT_LEFT: "Venstre",
  ALIGNMENT_RIGHT: "Høyre",
  BLINK: "Blink",
  DECIMALS: "Desimaler",
  DIRECTION: "Retning",
  THREAD_DIRECTION: "Trådretning",
  MESSAGE_DIRECTION: "Meldingsretning",
  CONVERSATION_MAIN_CHANNELS: "Samtalens hovedkanaler",
  CONVERSATION_DIRECTION: "Samtaleretninger",
  EDIT_ITEM: "Rediger element",
  OF_TOTAL: "Av totalt",
  GROUP_POPULAR: "Populære",
  GROUP_OTHERS: "Andre",
  GROUP_CUSTOM: "Tilpassede",
  GROUP_LIVE: "Live",
  STATS_CUSTOM_METRICS: "Tilpassede målinger",
  STATS_CUSTOM_METRIC: "Tilpasset måling",
  METRIC_QUERY_TYPE_SUM: "Addisjon",
  METRIC_QUERY_TYPE_DIFF: "Subtraksjon",
  METRIC_QUERY_TYPE_PERCENT: "Prosent",
  METRIC_QUERY_TYPE_AVG: "Gjennomsnitt",
  METRIC_QUERY_TYPE_MAX: "Maksimum",
  METRIC_QUERY_TYPE_MIN: "Minimum",
  METRIC_QUERY_TYPE_LEAST: "Minst",
  METRIC_QUERY_TYPE_GREATEST: "Størst",
  METRIC_QUERY_TYPE_STATIC: "Statisk",
  METRIC_QUERY_TYPE_ADD: "Addisjon",
  QUERY_TYPE: "Spørringstype",
  DASHBOARDS: "Dashbord",
  DASHBOARD: "Dashbord",
  REPORTS: "Rapporter",
  REPORT: "Rapport",
  CREATE_DASHBOARD: "Opprett dashbord",
  CREATE_REPORT: "Opprett rapport",
  EDIT_DASHBOARD: "Rediger dashbord",
  EDIT_REPORT: "Rediger rapport",
  ALL_DASHBOARDS: "Alle dashbord",
  SHARED_WITH_ME: "Delt med meg",
  PREBUILD_DASHBOARDS: "Forhåndsbyggede dashbord",
  ALL_REPORTS: "Alle rapporter",
  PREBUILD_REPORTS: "Forhåndsbyggede rapporter",
  ADD_YOUR_FIRST_DASHBOARD: "Legg til ditt første dashbord",
  ADD_YOUR_FIRST_DASHBOARD_DESCRIPTION:
    "Start med en ny tom flate eller velg et av våre forhåndsbyggede dashbord",
  NO_SHARED_DASHBOARDS: "Ingen delte dashbord",
  ADD_YOUR_PREBUILT_DASHBOARD: "Legg til forhåndsbygget dashbord",
  ADD_YOUR_PREBUILT_DASHBOARD_DESCRIPTION:
    "Opprett ditt eget dashbord fra et forhåndsbygget dashbord",
  ADD_YOUR_FIRST_REPORT: "Legg til din første rapport",
  ADD_YOUR_FIRST_REPORT_DESCRIPTION:
    "Start med en ny tom flate eller velg en av våre forhåndsbyggede rapporter",
  NO_SHARED_REPORTS: "Ingen delte rapporter",
  ADD_YOUR_PREBUILT_REPORT: "Legg til forhåndsbygget rapport",
  ADD_YOUR_PREBUILT_REPORT_DESCRIPTION: "Opprett din egen rapport fra en forhåndsbygget rapport",
  NO_DASHBOARDS: "Ingen dashbord",
  NO_REPORTS: "Ingen rapporter",
  CREATE_FROM_THIS_TEMPLATE: "Opprett fra denne malen",
  TIME_THRESHOLD: "Tidsterskel",
  AFTER: "Etter",
  CUSTOM_METRIC: "Tilpasset måling",
  STATS_CUSTOM_METRIC_ID: "Tilpasset måling",
  HEIGHT: "Høyde",
  STATS_USER_DATA_HELP: "Personifisert data viser personifiserte verdier for denne målingen.",
  STATS_OF_TOTAL_HELP: "Prosent av denne målingen, sammenlignet med den totale verdien.",
  STATS_REPORTS: "Rapporter & dashbord",
  STATS_WIDGETS: "Widgets",
  OWNER: "Eier",
  IS_DASHBOARD: "Er dashbord",
  STATS_REPORT: "Rapport",
  ADD_WIDGET: "Legg til widget",
  FULLSCREEN: "Fullskjerm",
  CLONED: "Klont",
  STATS_WIDGET_LEGEND_PLACEMENT_LEFT: "Venstre",
  STATS_WIDGET_LEGEND_PLACEMENT_RIGHT: "Høyre",
  STATS_WIDGET_LEGEND_PLACEMENT_TOP: "Topp",
  STATS_WIDGET_LEGEND_PLACEMENT_BOTTOM: "Bunn",
  CIRCLE_TOTAL: "Sirkel totalt",
  TOTAL: "Totalt",
  BORDER_COLOR: "Kantfarge",
  LAST_DATA_AT: "Siste data ved",
  HIDE_TITLE: "Skjul tittel",
  OPEN_DASHBOARD: "Åpne Dashboard",
  ADD_PREVIEW_WIDGET: "Legg til forhåndsvisningswidget",
  SELECT_REPORT: "Velg rapport",
  DEFAULT: "Standard",
  CLONE: "Klone",
  DRAG_n_MOVE: "Dra & Flytt",
  DOWN: "Ned",
  UP: "Opp",
  EXISTING: "Eksisterende",
  SELECT_WIDGET: "Velg widget",
  ZOOM_IN: "Zoom inn",
  ZOOM_OUT: "Zoom ut",
  RELOAD: "Last på nytt",
  RELOAD_DATA: "Last data på nytt",
  DISPLAY_VALUES: "Vis verdier",
  WIDGET_NOT_FOUND: "Widget ikke funnet!",
  WIDGET_NOT_FOUND_DESCRIPTION: "Widget har blitt fjernet eller deles ikke lenger med deg!",
  NO_WIDGETS: "Ingen widgets!",
  ADD_WIDGET_TO_REPORT: "Legg til widget i rapport",
  ADD_WIDGET_TO_DASHBOARD: "Legg til widget i Dashboard",
  REMOVE: "Fjern",
  REMOVE_ITEM: "Fjern objekt",
  ADDITION: "Addisjon",
  SUBTRACTION: "Subtraksjon",
  DIVISION: "Divisjon",
  CHANNELS_SETTINGS: "Kanaler fra innstillinger",
  DIRECTION_SETTINGS: "Retning fra innstillinger",
  THREAD_CHANNELS_SETTINGS: "Trådkanaler fra innstillinger",
  MESSAGE_CHANNELS_SETTINGS: "Meldingskanaler fra innstillinger",
  THREAD_DIRECTION_SETTINGS: "Trådretning fra innstillinger",
  MESSAGE_DIRECTION_SETTINGS: "Meldingsretning fra innstillinger",
  SELECT_EXISTING: "Velg eksisterende",
  EXISTING_WIDGETS: "Eksisterende widgets",
  NEW_WIDGET: "Ny widget",
  TEMPLATES: "Maler",
  SELECT_TEMPLATE: "Velg mal",
  USE_TEMPLATE: "Bruk mal",
  SEARCH_TEMPLATES: "Søk maler",
  SEARCH_WIDGETS: "Søk widgets",
  WIDGET_TEMPLATES: "Widget-maler",
  WIDGET: "Widget",
  EDIT_WIDGET: "Rediger widget",
  SELECT_DASHBOARD: "Velg dashboard",
  REPORT_NOT_SELECTED: "Rapport ikke valgt!",
  DASHBOARD_NOT_SELECTED: "Dashboard ikke valgt!",
  TREND_VALUE: "Trendverdi",
  TREND_PERIOD: "Trendperiode",
  MY_CONVERSATIONS: "Mine samtaler",
  MY_AND_UNASSIGNED_CONVERSATIONS: "Mine & ikke-tildelte samtaler",
  CUSTOMER_RESPONDED: "Kunde svarte",
  NO_UPDATE_IN_7_DAYS: "Ingen oppdatering på 7 dager",
  CREATED_BY_ME: "Opprettet av meg",
  ALL_UNRESOLVED: "Alle uløste",
  ALL_RESOLVED: "Alle løste",
  ALL_CONVERSATIONS: "Alle samtaler",
  DIRECTIONS: "Retninger",
  INTERNAL_HOST: "Intern vert",
  VOICE_ASTERISK_DOWNGRADE_ID: "Nedgrader asterisk",
  VOICE_ASTERISK_DOWNGRADES: "Asterisk-nedgraderinger",
  VOICE_ASTERISK_DOWNGRADE: "Asterisk-nedgradering",
  DOWNGRADED: "Nedgradert",
  __IS_DOWNGRADED: "Er nedgradert",
  VOICE_ASTERISK_OPERATIONAL_HOURS: "Asterisk driftstimer",
  VOICE_ASTERISK_OPERATIONAL_HOURSES: "Asterisk driftstimer",
  UTC: "UTC",
  VOICE_ASTERISK_TOGGLE_HISTORY: "Asterisk vekslingshistorikk",
  VOICE_ASTERISK_TOGGLE_HISTORIES: "Asterisk vekslingshistorikk",
  INITIAL: "Initial",
  DID_TOGGLE: "Vekslet",
  DATE: "Dato",
  X_ONGOING_CALLS: "{{calls}} pågående samtaler",
  X_USER_STATUSES: "{{users}} tilkoblede brukere",
  PREVENTED_ASTERISK_TOGGLE: "forhindret asterisk fra å veksle",
  BOOTING_UP: "Starter opp",
  DOWNGRADE_ASTERISK_OFFLINE: "Nedgrader asterisk offline",
  ASTERISK_STATUS_OFFLINE: "Offline",
  ASTERISK_STATUS_UPANDRUNNING: "I gang",
  ASTERISK_STATUS_TERMINATED: "Avsluttet",
  ASTERISK_STATUS_BOOTING: "Starter",
  ASTERISK_STATUS_SYNCING: "Synkroniserer",
  RESYNC: "Resynkroniser",
  TOGGLE_FAILED: "Veksling mislyktes (trolig OPM-forespørsel)",
  CURRENCY: "Valuta",
  INVOICE_CURRENCY_EUR: "EUR",
  INVOICE_CURRENCY_USD: "USD",
  INVOICE_CURRENCY_SEK: "SEK",
  INVOICE_POST_ADDON: "Faktura",
  INVOICE_POST_LICENSE: "Lisens",
  INVOICE_POST_USAGE: "Stemmebruk",
  INVOICE_POST_ALL_LICENSE: "Alle kanaler lisens",
  TARIFF_ID: "Takst",
  VOICE_TARIFF_LIST: "Stemmetakstliste",
  PHONENUMBER_MONTHLY_COST_ID: "Telefonnummer månedskostnad",
  PHONENUMBER_MONTHLY_COST_LIST: "Telefonnummer månedskostnadsliste",
  PHONENUMBER_SETUP_COST_ID: "Telefonnummer oppstartskostnad",
  PHONENUMBER_SETUP_COST_LIST: "Telefonnummer oppstartskostnadsliste",
  INVOICE_POST_VOICE_TARIFF: "Stemmetakst",
  INVOICE_POST_VOICE_PHONENUMBER_MONTHLY_COST: "Telefonnummer månedskostnad",
  INVOICE_POST_VOICE_PHONENUMBER_SETUP_COST: "Telefonnummer oppstartskostnad",
  INVOICE_POST_VOICE_LICENSE: "Stemmelisens",
  INVOICE_POST_VOICE_USAGE: "Stemmebruk",
  INVOICE_POST_EMAIL_USAGE: "E-postbruk",
  INVOICE_POST_EMAIL_LICENSE: "E-postlisens",
  INVOICE_POST_FORM_USAGE: "Skjemabruk",
  INVOICE_POST_VOICE_NUMBERS_MONTHLY: "Stemmetelefonnummer månedskostnader",
  INVOICE_POST_VOICE_NUMBERS_SETUP: "Stemmetelefonnummer oppstartskostnader",
  INVOICE_POST_VOICE_RECORDING: "Stemmeopptak",
  INVOICE_POST_VOICE_RECORDING_STORAGE: "Stemmeopptakslagring",
  CUSTOMER_IDENTIFICATION_NUMBER: "Kundeidentifikasjonsnummer",
  INVOICE_PERIOD_MONTHLY: "Månedlig",
  INVOICE_PERIOD_EVERY2NDMONTH: "Annenhver måned",
  INVOICE_PERIOD_QUARTERLY: "Kvartalsvis",
  INVOICE_PERIOD_HALFYEARLY: "Halvårlig",
  INVOICE_PERIOD_ANNUALLY: "Årlig",
  AMOUNT_TYPE: "Type",
  AMOUNT_PERCENT: "Prosent",
  AMOUNT_CURRENCY: "Beløp",
  INVOICE_POST_AMOUNT_TYPE_PERCENT: "Prosent",
  INVOICE_POST_AMOUNT_TYPE_CURRENCY: "Beløp",
  INVOICE: "Faktura",
  INVOICES: "Fakturaer",
  AMOUNT: "Beløp",
  LOCKED_AT: "Låst",
  PAID_AT: "Betalt",
  PERIOD_START: "Periodestart",
  PERIOD_END: "Periodeslutt",
  INVOICE_POST: "Fakturapost",
  INVOICE_POSTS: "Fakturaposter",
  RE_PROCESS: "Behandle på nytt",
  EXCLUDE: "Ekskluder",
  INVOICE_ID: "Faktura",
  TARIFF: "Takst",
  TARIFFS: "Takster",
  TARIFF_PARENT: "Takstforelder",
  TARIFF_PARENTS: "Takstforeldre",
  OUTBOUND_TARIFFS: "Utgående takster",
  INBOUND_TARIFF: "Innkommende takst",
  INTERNAL_TARIFF: "Intern takst",
  OUTBOUND_ROUNDUP_STRATEGY: "Utgående avrundingsstrategi",
  INBOUND_ROUNDUP_STRATEGY: "Innkommende avrundingsstrategi",
  INTERNAL_ROUNDUP_STRATEGY: "Intern avrundingsstrategi",
  TARIFF_PARENT_ID: "Stemmetakstforelder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_NOROUNDUP: "Ingen avrunding",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST60SECONDS: "Nærmeste 60 sekunder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST30SECONDS: "Nærmeste 30 sekunder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST10SECONDS: "Nærmeste 10 sekunder",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEIL15TO60: "Avrund 15-60 til 60 sekunder",
  PHONENUMBER_MONTHLY_COST_PARENT_ID: "Telefonnummer månedskostnadsforelder",
  PHONENUMBER_MONTHLY_COST: "Telefonnummer månedskostnad",
  PHONENUMBER_MONTHLY_COSTS: "Telefonnummer månedskostnader",
  PHONENUMBER_MONTHLY_COST_PARENT: "Telefonnummer månedskostnadsforelder",
  PHONENUMBER_MONTHLY_COST_PARENTS: "Telefonnummer månedskostnadsforeldre",
  PHONENUMBER_SETUP_COST_PARENT_ID: "Telefonnummer oppstartskostnadsforelder",
  PHONENUMBER_SETUP_COST: "Telefonnummer oppstartskostnad",
  PHONENUMBER_SETUP_COSTS: "Telefonnummer oppstartskostnader",
  PHONENUMBER_SETUP_COST_PARENT: "Telefonnummer oppstartskostnadsforelder",
  PHONENUMBER_SETUP_COST_PARENTS: "Telefonnummer oppstartskostnadsforeldre",
  INVOICE_START: "Faktureringsstart",
  INVOICE_END: "Faktureringsslutt",
  ADDONS: "Tillegg",
  PROCESSED_AT: "Behandlet",
  INVOICE_PRINT: "Faktura",
  INVOICE_PDF: "Pdf",
  MOBILE: "Mobil",
  FIXED_LINE: "Fastlinje",
  SHARED_COST: "Delt kostnad",
  TOLL_FREE: "Gratis",
  DUE_PERIOD: "Forfallsperiode",
  COUNT: "Antall",
  INVOICE_DUE_PERIOD_MONTH: "Måned",
  INVOICE_DUE_PERIOD_DAY: "Dag",
  COMPANY_NAME: "Firmanavn",
  COMPANY_ADDRESS: "Firmaadresse",
  COUNTRY: "Land",
  CITY: "By",
  POSTAL_CODE: "Postnummer",
  ADDRESS_ROW1: "Adresselinje 1",
  ADDRESS_ROW2: "Adresselinje 2",
  MARK_AS_PAID: "Merk som betalt",
  MARK_AS_UNPAID: "Merk som ubetalt",
  INVOICE_DATE: "Fakturadato",
  AMOUNT_TO_PAY: "Beløp å betale",
  INVOICE_PERIOD: "Fakturaperiode",
  INBOUND: "Innkommende",
  SUB_TOTAL: "Delsum",
  VAT: "MVA",
  PCS: "stk",
  COMPANY_EMAILS: "Firmaets e-postadresser",
  PAYMENT_OPTION: "Betalingsalternativ",
  INVOICE_PAYMENT_OPTION_BANKTRANSFER: "Bankoverføring",
  INVOICE_PAYMENT_OPTION_CREDITCARD: "Kredittkort",
  LICENSE_COST: "Lisenskostnad",
  FORM_MODULE_COST: "Skjemamodulkostnad",
  REPORTING: "Rapportering",
  SURCHARGE: "Tilleggsavgift",
  PREPAY: "Forskuddsbetaling",
  COST: "Kostnad",
  INVOICE_POST_AMOUNT_TYPE_MINDEB: "Minimumsdebitering",
  FINANCE: "Økonomi",
  PREPAID_CREDIT: "Forskuddskreditt",
  PREPAID_CREDITS: "Forskuddskreditter",
  VALID_FROM: "Gyldig fra",
  VALID_UNTIL: "Gyldig til",
  CREDIT_LEFT: "Gjenværende kreditt",
  OUTBOUND_CALL_RESTRICTED_DESCRIPTION:
    "Utgående samtale til destinasjonstelefonen ble begrenset av systemet! Kontakt din systemadministrator for tilgang!",
  OUTBOUND_CALL_NEGATIVE_BALANCE_DESCRIPTION:
    "Utgående samtale har blitt avsluttet på grunn av negativ saldo i din organisasjon!",
  OUTBOUND_CALL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Utgående samtale har blitt avsluttet på grunn av inaktivitet i organisasjonen!",
  OUTBOUND_CALL_TOLL_FREE_NOT_SAME_ORIGIN:
    "Utgående samtale til det gratis destinasjonsnummeret har blitt avsluttet fordi oppringerens og mottakerens opprinnelsesland må være det samme!",
  OUTBOUND_EMAIL_NEGATIVE_BALANCE_DESCRIPTION:
    "Utgående e-post har blitt avsluttet på grunn av negativ saldo i din organisasjon!",
  OUTBOUND_EMAIL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Utgående e-post har blitt avsluttet på grunn av inaktivitet i organisasjonen!",
  DESTINATION_RESTRICTOR: "Destinasjonsbegrenser",
  DESTINATION_RESTRICTORS: "Destinasjonsbegrensere",
  DESTINATIONS: "Destinasjoner",
  STATS_LICENSE_TYPE_ENTRY: "Entry",
  STATS_LICENSE_TYPE_STANDARD: "Standard",
  STATS_LICENSE_TYPE_ENTERPRISE: "Enterprise",
  ALL_CHANNELS: "Alle kanaler",
  VOICE_TARIFF_ROUNDUP_STRATEGY: "Stemmetakst avrundingsstrategi",
  VOICE_OUTBOUND_USAGE: "Stemme utgående bruk",
  VOICE_USAGE_ROUNDUP_STRATEGY: "Stemmebruk avrundingsstrategi",
  VOICE_INBOUND_USAGE: "Stemme innkommende bruk",
  VOICE_INTERNAL_USAGE: "Stemme intern bruk",
  EMAIL_INBOUND_USAGE: "E-post innkommende bruk",
  EMAIL_OUTBOUND_USAGE: "E-post utgående bruk",
  FORM_INBOUND_USAGE: "Skjema innkommende bruk",
  NEGATIVE_BALANCE_LOCK: "Negativ saldo lås",
  PREPAID: "Forskuddsbetaling",
  NONE_HANDLED_INVOICE_CANNOT_BE_LOCKED: "Ikke håndtert faktura kan ikke låses!",
  LOCKED_INVOICES_MAY_NOT_BE_PATCHED: "Låst faktura kan ikke endres!",
  CREDIT_USED: "Brukt kreditt",
  INVOICE__DATA__COMPANY_EMAILS: "Fakturamottakers e-postadresser",
  DASHBOARD_LICENSE: "Dashboard-lisens",
  REPORTING_LICENSE: "Rapporteringslisens",
  DASHBOARD_LICENSE_TYPE: "Dashboard-lisenstype",
  REPORTING_LICENSE_TYPE: "Rapporteringslisenstype",
  STANDARD: "Standard",
  ENTERPRISE: "Enterprise",
  TWINNING: "Twinning",
  ON: "På",
  QUEUE_ON: "Køsamtale",
  DIRECT_ON: "Direktesamtale",
  TWINNED: "Twinnet",
  TWINNED_TO: "Twinnet til",
  RE_PROCESS_TARIFFS: "Behandle takster på nytt",
  RE_PROCESS_TARIFFS_NO_RESET_TARIFF_ID:
    "Behandle takstkostnader på nytt uten å tilbakestille takstelisten",
  RE_PROCESS_TARIFFS_WITH_RESET_TARIFF_ID:
    "Behandle takstkostnader på nytt og tilbakestill takstelisten",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTRY: "Entry",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_STANDARD: "Standard",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTERPRISE: "Enterprise",
  INVOICE_NUMBER: "Fakturanr.",
  CUSTOMER_NUMBER: "Kundenr.",
  SALESPERSON: "Selger",
  YOUR_REFERENCE: "Din referanse",
  PAYMENT_TERMS: "Betalingsbetingelser",
  VAT_REGISTRATION_NO: "MVA-registreringsnr.",
  FIN_CHARGE_TERMS: "Forsinkelsesrente",
  INVOICE_ROUNDING: "Fakturaavrunding",
  COMPANY_REFERENCE: "Firmareferanse",
  INTERNET: "Internett",
  E_MAIL: "E-post",
  CORPORATE_IDENTITY_NO: "Organisasjonsnummer",
  BANKGIRO: "Bankgiro",
  BIC_SWIFT: "BIC/SWIFT",
  PAYMENT_INFO_TEMPLATE: "Betalingsinformasjonsmal",
  HANDELSBANKEN_BG_SEK: "Handelsbanken bankgiro SEK",
  HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  WIZE_EUR: "Wize EUR",
  NET_AMOUNT: "Nettobeløp",
  SUBTOTAL: "Delsum",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_BG_SEK: "Handelsbanken Bankgiro SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  INVOICE_PAYMENT_INFO_TEMPLATE_WIZE_EUR: "Wize EUR",
  QUEUE_NOT_FOUND: "Kø ikke funnet",
  CONTACT_NOT_FOUND: "Kontakt ikke funnet",
  SEND_INVOICE: "Send faktura",
  GO_TO_CONVERSATION: "Gå til samtale",
  MISSING_ORGANIZATION_INVOICE_DATA: "Mangler organisasjonens fakturadata",
  NO_ORGANIZATION_INVOICE_EMAILS:
    "Ingen e-postadresser funnet i organisasjonen for å opprette samtale!",
  INVOICE_ALREADY_LOCKED: "Fakturaen er allerede låst",
  LOCK_INVOICE: "Lås faktura",
  REGULATION_LOCAL_ADDRESS_PROOF_REQUIRED:
    "Ved kjøp av telefonnummer, vær oppmerksom på at enkelte regioner kan kreve at du fremlegger bevis på en gyldig gateadresse innenfor det angitte området eller bydomenet. Dette kan innebære å sende inn dokumenter som en nylig faktura eller bedriftssertifikat som viser tilsvarende adresse for å sikre overholdelse av lokale forskrifter.",
  REGULATION_WORLDWIDE_ADDRESS_PROOF_REQUIRED:
    "Ved kjøp av telefonnummer, vær oppmerksom på at denne regionen vil kreve at du fremlegger bevis på en gyldig gateadresse. Dette kan innebære å sende inn dokumenter som en nylig faktura eller bedriftssertifikat som viser tilsvarende adresse for å sikre overholdelse av lokale forskrifter.",
  REGULATION_NATIONAL_ADDRESS_PROOF_REQUIRED:
    "Ved kjøp av telefonnummer, vær oppmerksom på at denne regionen vil kreve at du fremlegger bevis på en gyldig gateadresse innenfor det angitte landet. Dette kan innebære å sende inn dokumenter som en nylig faktura eller bedriftssertifikat som viser tilsvarende adresse for å sikre overholdelse av lokale forskrifter.",
  PURCHASE: "Kjøp",
  SETUP_COST: "Oppstartskostnad",
  MONTHLY_COST: "Månedskostnad",
  REGULATIONS: "Forskrifter",
  PHONENUMBER_PURCHASE_STATUS_ERROR: "Feil",
  PHONENUMBER_PURCHASE_STATUS_WARNING: "Advarsel",
  PHONENUMBER_PURCHASE_STATUS_PARTIAL: "Delvis",
  PHONENUMBER_PURCHASE_STATUS_SUCCESS: "Vellykket",
  PHONENUMBER_PURCHASE: "Telefonnummerkjøp",
  PHONENUMBER_PURCHASES: "Telefonnummerkjøp",
  PHONENUMBER_PREFIX: "Telefonnummer DID-gruppe",
  PHONENUMBER_PREFIXES: "Telefonnummer DID-grupper",
  PHONENUMBER_DID_TYPE_FIXED_LINE: "Fastlinje",
  PHONENUMBER_DID_TYPE_MOBILE: "Mobil",
  PHONENUMBER_DID_TYPE_SHARED_COST: "Delt kostnad",
  PHONENUMBER_DID_TYPE_TOLL_FREE: "Grønt nummer",
  ALPHA2: "Alpha-2",
  AREA_NAME: "Områdenavn",
  AREA_CODE: "Retningsnummer",
  STOCK: "Lager",
  PHONENUMBER_STOCK: "Telefonnummerlager",
  PHONENUMBER_STOCKS: "Telefonnummerlagre",
  BULK_ADD_PHONENUMBERS: "Masseimport av telefonnumre",
  BULK_ADD_PHONENUMBERSTOCK: "Masseimport av telefonnumre til connectel-lager",
  NUMBER_RANGE_START: "Nummerintervall start",
  NUMBER_RANGE_END: "Nummerintervall slutt",
  LIST_PHONENUMBERS: "Liste telefonnumre",
  ADD_PHONENUMBERS: "Legg til telefonnumre",
  VOICE_ROUTE_NUMBER_MAY_NOT_BE_CHANGED: "Nummer kan ikke endres!",
  EMERGENCY: "Nødsituasjon",
  ENABLED: "Aktivert",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_VOXBONE: "Voxbone",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_CONNECTEL: "Connectel",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_THIRD_PARTY: "Tredjepart",
  PROVIDER: "Leverandør",
  TERMINATE: "Avslutt",
  TERMINATION: "Avslutning",
  TERMINATED_AT: "Avsluttet",
  TERMINATED_BY: "Avsluttet av",
  TERMINATES_AT: "Avsluttes",
  USER_NOT_FOUND: "Bruker-id ({{user_id}}) ble ikke funnet!",
  TERMINATE_WENT_WRONG: "Noe gikk galt ved avslutning av telefonnummer!",
  VOXBONE_NUMBER_NOT_CANCELLED: "Telefonnummeret kunne ikke kanselleres!",
  VOXBONE_DID_ID_NOT_FOUND:
    "Leverandøren kan ikke endres til Voxbone siden nummeret ikke finnes i Voxbones database.",
  VOICE_RECORDING: "Taleopptak",
  VOICE_RECORDING_STORAGE: "Taleopptakslagring",
  VOICEMAIL_MESSAGE: "Talemelding",
  VOICE_CALL_RECORDING: "Samtaleopptak",
  ADD_CUSTOM_INVOICE_POST: "Legg til tilpasset fakturapost",
  BY_PREFIX: "Etter prefiks",
  PREFIX: "Prefiks",
  DESTINATION_RESTRICTOR_CONFIRM:
    "Aktivering av ytterligere samtaldestinasjoner kan føre til utilsiktede avgifter og økte kostnader, siden samtaler til tidligere blokkerte land vil bli tillatt. Sørg for at du er klar over de potensielle økonomiske konsekvensene før du aktiverer nye destinasjoner.",
  DISPOSITION_MAY_NOT_HAVE_MORE_THAN_3_LEVELS: "Klassifisering kan ikke ha mer enn 3 nivåer!",
  TO_ME: "Til meg",
  ASSIGN_CONVERSATION_TO_ME: "Tildel samtale til meg",
  VOICE_DEVICE_AND_TWINNING_SETTINGS: "Taleenhet- & twinninginnstillinger",
  VOICE_NOTIFICATION_SOUND: "Talevarslingslyd",
  VOICE_NOTIFICATION_MICROPHONE: "Talemikrofon på/av",
  NO_USER: "Ingen bruker",
  NO_DISPOSITION: "Ingen klassifisering",
  NO_SUB_DISPOSITION: "Ingen underklassifisering",
  NO_THRD_DISPOSITION: "Ingen tredjeklassifisering",
  NOT_REQUIRED: "Ikke påkrevd",
  RESOLVE_IN: "Løs innen",
  REPLY_AT_LATEST: "Svar senest",
  RESOLVE_AT_LATEST: "Løs senest",
  CHANGELOG: "Endringslogg",
  SWITCH_TO_DARK_THEME: "Bytt til mørkt tema",
  SWITCH_TO_LIGHT_THEME: "Bytt til lyst tema",
  DISPLAY_CHANGELOG_HISTORY: "Vis endringsloggshistorikk",
  IMPROVEMENT: "Forbedring",
  BUG_FIX: "Feilretting",
  SELECT_ALL: "Velg alle",
  NAV_CUSTOMER_ID: "Kunde navision-id",
  CREATE_NEW_JOURNEY_DRAFT: "Opprett nytt reiseflyt-utkast",
  SHOW_JOURNEY_DRAFTS: "Vis reiseflyt-utkast",
  DROP_TO_ATTACH_FILE: "Slipp for å legge ved fil",
  NO_PREVIEW_AVAILABLE: "Ingen forhåndsvisning tilgjengelig for denne filen!",
  REPLY_TO_CC: "E-post kopi",
  REPLY_TO_BCC: "E-post blindkopi",
  LEAVE_EMPTY_FOR_ALL_DAY_EVERYDAY: "La stå tomt for hele dagen, hver dag",
  RELEASE: "Release",
  RELEASES: "Releases",
  VERSION: "versjon",
  EXECUTION_AT: "Kjøring ved",
  EXECUTED_AT: "Kjørt ved",
  FULLFILLED: "Oppfylt",
  OUTPUT: "Utdata",
  CLICK_TO_PRINT_TO_CONSOLE: "Klikk for å skrive ut til konsollen",
  PRE_QUEUE_TIME_LONG: "Reiseflyt-tid (tid i reiseflyten før kø eller avslutning)",
  AUDIT: "Revisjon",
  AUDITS: "Revisjoner",
  SYS_USER: "System",
  AUDIT_ACTION_READ: "Les",
  AUDIT_ACTION_UPDATE: "Oppdater",
  AUDIT_ACTION_CREATE: "Opprett",
  AUDIT_ACTION_DELETE: "Slett",
  AUDIT_ACTION_SOFTDELETE: "Myk sletting",
  AUDIT_SEVERITY_L: "Lav",
  AUDIT_SEVERITY_M: "Middels",
  AUDIT_SEVERITY_H: "Høy",
  ACTION: "Handling",
  SEVERITY: "Alvorlighetsgrad",
  CHANGED_PROPS: "Endrede egenskaper",
  EMPTY: "Tom",
  MAIL_STATUS: "E-poststatus",
  VOICE_STATUS: "Talestatus",
  UnAssign: "Fjern tildeling",
  READONLY_UI: "Skrivebeskyttet (UI)",
  VOICE_RINGTONE: "Taleringetone",
  NOTIFICATION_RINGTONE: "Varslingsringetone",
  MAX_FILESIZE: "Maksimal filstørrelse",
  MIMETYPES: "MIME-typer",
  API_KEY: "API-nøkkel",
  API_KEYS: "API-nøkler",
  COPY_API_KEY_TO_CLIPBOARD: "Kopier API-nøkkel til utklippstavlen",
  CREATED_BY: "Opprettet av",
  CONTACT_CONDITION: "Kontaktbetingelse",
  DATA_KEY: "Feltalias",
  COMPARISON: "Sammenligning",
  DISPLAY_QUEUE_CALLER_ID: "Vis kønummer",
  MERGE_CONTACT_IF_EXISTS: "Slå sammen kontaktdata hvis den finnes",
  CUSTOM_DATA: "Tilpasset data",
  OVERRIDES_CURRENT: "Overstyrer nåværende verdi",
  MERGES_AND_REMOVES_DUPLICATED: "Slår sammen og fjerner duplikater",
  MERGEANDKEEPEXISTING: "Slå sammen og behold eksisterende",
  MERGEANDOVERRIDE: "Slå sammen og overstyr eksisterende",
  CONVERSATION_OWNER_ONLY: "Kun samtaleagenters faner",
  INACTIVE_TABS_HIDDEN: "Inaktive faner er skjult",
  INACTIVE_TABS_DESCRIPTION:
    "Sørg for at du ikke har mer enn 7 faner åpne ved å lukke dine synlige faner en etter en!",
  VIEW_EVENT: "Vis hendelse",
  EVENT_ORDER: "Rekkefølge",
  CLIENT: "Klient",
  CLIENTS: "Klienter",
  SELECT_CLIENT: "Velg klient",
  CLIENT_ID: "Klient",
  DATA: "Data",
  NO_CLIENT: "Ingen klient",
  TRANSLATIONS: "Oversettelser",
  TRANSLATION: "Oversettelse",
  __SUBMISSION_DISPLAY_FIELDS: "Visningsfelt for innsending",
  ADDITIONAL_QUEUES: "Ytterligere køer",
  HELP_ADDITIONAL_QUEUES:
    "Velg ytterligere køer som ikke er tilgjengelige i nåværende agentprofil.",
  FROM_ADDITIONAL: "Fra ytterligere køer",
  FORM_SETTINGS: "Skjemainnstillinger",
  FORM_CAPACITY: "Skjemakapasitet",
  FIXED_LINE_OR_MOBILE: "Fastlinje/mobil",
  ATTACHMENTS_STORAGE: "Vedleggslagring",
  PARENTS: "Foreldre",
  ALL_QUEUES: "Alle køer",
  PROFILE_QUEUES: "Profilkøer",
  DATA_CHANGED: "Feltdataendringer",
  FORCE_LOGOUT: "Tving utlogging",
  JSON_RESPONSE: "JSON-svar",
  JSON_PATH: "JSON-sti",
  JSON_PATH_HELP: "Eksempel: data.property, data.array[0].property",
  DELETED_ITEM_REMOVE: "Slettet element må fjernes fra valgte elementer!",
  UNASSIGN: "Fjern tildeling",
  ALLOW_AGENT_UNASSIGN: "Tillat agent å fjerne tildeling",
  ASTERISK_HOURS: "Asterisk-timer",
  TOGGLE_ASTERISK_HOURS: "Veksle asterisk-timer",
  EXTERNAL_VOICE_RINGTONE: "Ekstern samtaleringetone",
  INTERNAL_VOICE_RINGTONE: "Intern samtaleringetone",
  LOAD_MORE: "Last mer",
  JOURNEY_TIMELINE: "Reiseflyt-tidslinje",
  TIMELINE: "Tidslinje",
  EMPTY_TIMELINE: "Tom tidslinje",
  GLOBAL_SEARCH_QUEUES: "Globale søkekøer",
  PERMANENTLY_DELETE_REPORT: "Slett rapport permanent?",
  PERMANENTLY_DELETE_REPORT_DESCRIPTION:
    "Alle unike widgets i denne rapporten vil også bli slettet med denne handlingen.",
  PERMANENTLY_DELETE_DASHBOARD: "Slett Dashboard permanent?",
  PERMANENTLY_DELETE_DASHBOARD_DESCRIPTION:
    "Alle unike widgets i dette Dashboardet vil også bli slettet med denne handlingen.",
  DELETE_WIDGET: "Slett widget",
  DELETE_WIDGET_DESCRIPTION:
    "Widget vil bli slettet permanent hvis den ikke forekommer i noen andre Dashboards/rapporter.",
  CREATED_AFTER: "Opprettet etter",
  LAST_MESSAGE_BY_CONTACT: "Kontakt",
  LAST_MESSAGE_BY_AGENT: "Agent",
  SUBJECT_PREFIX: "Emneprefiks",
  HIDE_STATS_PREVIEW: "Skjul statistikkforhåndsvisning",
  DISPLAY_STATS_PREVIEW: "Vis statistikkforhåndsvisning",
  SOMETHING_WENT_WRONG_CLONING_WIDGET: "Noe gikk galt ved kloning",
  STATS_WIDGET_CUSTOM_PERIOD_TO_LONG:
    "Widgets tilpassede periodstart og slutt kan ikke overstige 31 dager.",
  TRANSFER_DASHBOARD: "Overfør Dashboard",
  TRANSFER_REPORT: "Overfør rapport",
  TRANSFER_TO_REPORT_OWNER: "Overfør widget til rapporteier",
  OF: "av",
  WIDGET_HAS_NO_DATA: "Widget har ingen data",
  RULE_OTHERWISE_MATCHED: 'Regel "Ellers" matchet',
  RULE_X: "Regel {{n}}",
  RULE_X_WAS_MATCHED: '"Regel {{n}}" matchet',
  EDIT_CONTACT: "Rediger kontakt",
  PRECISION: "Presisjon",
  SKILL: "Ferdighet",
  SKILLS: "Ferdigheter",
  SKILL_ID: "Ferdighet",
  LEVEL_ONE: "Nivå en",
  LEVEL_TWO: "Nivå to",
  LEVEL_THREE: "Nivå tre",
  SKILL_TIMEOUT_SETTINGS: "Ferdighet tidsavbruddsinnstillinger",
  TIMEOUT_ONE: "Tidsavbrudd en",
  TIMEOUT_TWO: "Tidsavbrudd to",
  TIMEOUT_THREE: "Tidsavbrudd tre",
  SKILL_TIMEOUT_ONE_DESCRIPTION:
    "Brukerferdigheter nivå to vil bli inkludert etter dette tidsavbruddet",
  SKILL_TIMEOUT_TWO_DESCRIPTION:
    "Brukerferdigheter nivå tre vil bli inkludert etter dette tidsavbruddet + forrige tidsavbrudd",
  SKILL_TIMEOUT_THREE_DESCRIPTION:
    "Alle ferdigheter vil bli inkludert etter dette tidsavbruddet + forrige tidsavbrudd",
  QUEUE_TIMEOUT_UNIT_SECOND: "Sekunder",
  QUEUE_TIMEOUT_UNIT_MINUTE: "Minutter",
  QUEUE_TIMEOUT_UNIT_HOUR: "Timer",
  QUEUE_TIMEOUT_UNIT_DAY: "Dager",
  DEFAULT_VOICE_COUNTRY: "Standardland for tale",
  NO_SKILL: "Ingen ferdighet",
  IS_WAITING: "Venter",
  IS_ONGOING: "Pågående",
  PREVIEW_PERIOD: "Forhåndsvisningsperiode",
  WALLBOARD: "Wallboard",
  WALLBOARD_KEY: "Wallboard-nøkkel",
  STATS_REPORT_ID: "Rapport / Dashboard",
  SHARE_DATA: "Del data",
  SHARE_DATA_DESCRIPTION:
    "Del data som bruker, kø, disposisjon, e-postkonto... data med veggpanelvisere. Merk at delte datapunkter som kø inkluderer alle organisasjonens køer og ikke bare de som finnes i widgets. Datadeling bør derfor slås av hvis du deler veggpanel med eksterne parter som ikke skal ha tilgang til datapunktsinformasjon.",
  WALLBOARD_NO_MORE_TRIES: "Du har blitt koblet fra, vennligst oppdater og prøv igjen!",
  DISPOSED: "Disponert",
  STATUS_CHANGED: "Status endret",
  ASSIGNED_TO: "Tildelt til",
  USER_ASSIGNED: "Bruker tildelt",
  USER_UNASSIGNED: "Bruker fjernet",
  UNASSIGNED_FROM: "Fjernet fra",
  QUEUE_ASSIGNED: "Kø tildelt",
  TIMELINE_EVENT_TYPE_JOURNEYJOB: "Reiseflytjobb",
  TIMELINE_EVENT_TYPE_VOICE: "Tale",
  TIMELINE_EVENT_TYPE_NOTIFICATION: "Varsling",
  TIMELINE_EVENT_TYPE_AUDIT: "Revisjon",
  JOURNEY_TRIGGER_ID: "Utløser",
  TRANSCRIPT: "Transkripsjon",
  DEFAULT_LANGUAGE: "Standardspråk",
  VIEW_TRANSCRIPT: "Vis transkripsjon",
  DISPLAY_TRANSCRIPT: "Vis transkripsjon",
  CONDITION_AND_OR_AND: "Og",
  CONDITION_AND_OR_OR: "Eller",
  VOICE_RECORDING_TRANSCRIPT: "Taleopptak transkripsjon",
  WALLBOARDS: "Veggpaneler",
  SLA_RESPONSE: "Svartid",
  SLA_RESOLUTION: "Løsningstid",
  HAS_SLA: "Har SLA",
  HAS_NOT_SLA: "Har ikke SLA",
  EXCEEDED: "Overskredet",
  NOT_EXCEEDED: "Ikke overskredet",
  TRANSCRIPT_TRIGGER: "Transkripsjon utløser på",
  CLICK_TO_TRANSCRIPT: "Klikk",
  RECORDED_BY_JOURNEY: "Opptak av reiseflyt",
  MANUALLY_RECORDED: "Manuelle opptak",
  GENERATE_TRANSCRIPT: "Generer transkripsjon",
  VOICE_RECORDING_TRANSCRIPT_SETTING: "Transkripsjoninnstillinger",
  VOICE_RECORDING_TRANSCRIPT_SETTINGS: "Transkripsjoninnstillinger",
  FIND_REPLACE: "Søk & erstatt",
  FIND: "Søk",
  REPLACE: "Erstatt",
  MODEL: "Modell",
  VOICE_RECORDING_TRANSCRIPT_MODEL_WHISPER: "Whisper",
  VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA: "Nova",
  "VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA-2": "Nova 2",
  VOICE_RECORDING_TRANSCRIPT_MODEL_BASE: "Base",
  VOICE_RECORDING_TRANSCRIPT_MODEL_ENHANCED: "Forbedret",
  TRANSCRIPTION_ERROR: "Transkripsjonsfeil",
  CONFIDENCE: "Pålitelighet",
  TRANSCRIPTION_TIME: "Transkripsjonsstid",
  POINTS_SYSTEMS: "Poengsystem",
  POINTS_SYSTEM: "Poengsystem",
  MAIL_MESSAGE_SENT: "E-postmelding sendt",
  INBOUND_VOICE_CALL: "Innkommende taleanrop",
  OUTBOUND_VOICE_CALL: "Utgående taleanrop",
  INTERNAL_VOICE_CALL: "Internt taleanrop",
  OUTBOUND_CONVERSATION_CREATED: "Utgående samtale opprettet",
  OUTBOUND_CONVERSATION_RESOLVED: "Utgående samtale løst",
  INBOUND_CONVERSATION_RESOLVED: "Innkommende samtale løst",
  OUTBOUND_CONVERSATION_CLOSED: "Utgående samtale lukket",
  INBOUND_CONVERSATION_CLOSED: "Innkommende samtale lukket",
  POINTS: "Poeng",
  CONVERSATION_SKILL_ID: "Ferdighet",
  JOURNEY_TRIGGER: "Reiseflytutløser",
  JOURNEY_TRIGGERS: "Reiseflytutløsere",
  HIGHLIGHT_IN_JOURNEY: "Marker i reiseflyt",
  HELP_CONVERSATION_FILTER_IS_WAITING:
    '"Venter" refererer til samtaler som ikke har blitt besvart og fortsatt er i køen.',
  HELP_CONVERSATION_FILTER_IS_ONGOING:
    '"Pågående" refererer til samtaler som ikke er lukket eller løst og har fått et svar fra en agent (systemets autosvar er ekskludert).',
  POINTS_CREATED: "Opprettet",
  POINTS_RESOLVED: "Løst",
  POINTS_CLOSED: "Lukket",
  POINTS_TALK: "Samtaletid",
  POINTS_MAIL_SENT: "Poeng for å sende e-post",
  TREND_COMPARISON_VALUE: "Trendsammenligningsverdi",
  CUSTOM_PERCENT: "Tilpasset prosent",
  STATS_CUSTOM_PERCENT_HELP:
    "Definer et tall for å dele måleverdi for å generere og vise en prosentverdi",
  GDPR: "GDPR",
  GDPRS: "GDPRs",
  PASSWORD_MAY_NOT_BE_IN_HISTORY:
    "Valgt passord har nylig blitt brukt av denne kontoen og kan for øyeblikket ikke brukes igjen!",
  PASSWORD_LIFETIME_REACHED:
    "Passordet ditt har utløpt! Tilbakestill passordet ditt for å få tilgang til applikasjonen.",
  IP_ADDRESS_NOT_WHITELISTED: "Din IP-adresse er ikke hvitelistet av noen av organisasjonene dine!",
  PASSWORD_EXPIRY_DESCRIPTION:
    "Passordets levetid utløper om mindre enn {{days_left}} dager. Sørg for å endre passordet ditt.",
  PASSWORD_NOT_STRONG:
    "Valgt passord er ikke sterkt nok! Sørg for at passordet inneholder minst én liten og stor bokstav, et spesialtegn og et tall!",
  FORM_ERROR_PASSWORD_NOT_STRONG:
    "Må inneholde minst én liten og én stor bokstav, et spesialtegn og et tall",
  IP_WHITELIST: "IP-hviteliste",
  IP_WHITELIST_DESCRIPTION: "Begrens tilgang til organisasjonen din via IP-adresse.",
  IP_ADDRESSES: "IP-adresser",
  CONNECTEL_INFO_SHARE: "Bruk av informasjon",
  LIFETIME: "Levetid",
  HISTORY: "Historikk",
  STRONG: "Sterk",
  CONNECTEL_INFO_SHARE_DESCRIPTION:
    "Samtykke til at Connectel samler inn anonymisert statistikk og data fra organisasjonen din for å forbedre tjenestene vi leverer til deg.",
  PASSWORD_STRONG: "Passordpolicy",
  PASSWORD_STRONG_DESCRIPTION: "Tving bruk av sterke passord.",
  PASSWORD_LIFETIME: "Passordets levetid",
  PASSWORD_LIFETIME_DESCRIPTION:
    "Angi maksimalt antall dager et passord kan leve før det må byttes.",
  PASSWORD_HISTORY: "Passordhistorikk",
  PASSWORD_HISTORY_DESCRIPTION: "Begrenser bruken av tidligere brukte passord.",
  ANONYMIZATION: "Anonymisering",
  AGENT_ANONYMIZATION_DESCRIPTION:
    "Anonymiser agentnavn i samtaler og andre datapunkter ved sletting.",
  EMAIL_ATTACHMENTS: "E-postvedlegg",
  FORM_SUBMISSION_ATTACHMENTS: "Skjemavedlegg",
  DELETION: "Sletting",
  VOICE_RECORDINGS: "Taleopptak",
  CONTACT_ANONYMIZATION_DESCRIPTION:
    "Antall dager som skal beholdes og ignoreres av anonymisering. Denne innstillingen gjelder kun inaktive kontakter og samtaler.",
  CONTACT_DELETION_RECORDINGS_DESCRIPTION:
    "Antall dager som skal beholdes og ignoreres av sletting. Denne innstillingen gjelder kun inaktive kontakter og samtaler.",
  CONTACT_VOICE_RECORDINGS_DESCRIPTION: "Antall dager som skal beholdes og ignoreres av sletting.",
  CONTACT_EMAIL_ATTACHMENTS_DESCRIPTION: "Antall dager som skal beholdes og ignoreres av sletting.",
  CONTACT_FORM_SUBMISSION_ATTACHMENTS_DESCRIPTION:
    "Antall dager som skal beholdes og ignoreres av sletting.",
  ANONYMIZED: "Anonymisert",
  LOGIN_ERROR: "Pålogging mislyktes",
  ANONYMIZE_CONTACT_AND_CONVERSATIONS: "Anonymiser kontakt",
  CONFIRM_ANONYMIZE: "Bekreft anonymisering",
  CONFIRM_ANONYMIZE_DESCRIPTION:
    "Ved å anonymisere kontakten anonymiserer du alle relaterte data til kontakten eller samtalene. Denne handlingen kan ikke angres!",
  ANONYMIZATION_ERROR: "Anonymiseringsfeil",
  ANONYMIZE_CONTACTS: "Anonymiser kontakter",
  TRANSCRIPT_ANONYMIZED: "Transkripsjon anonymisert",
  OpenBrowserTab: "Åpne nettleserfane",
  MAX_INACTIVITY_READY: "Maksimal inaktivitet for agentprofiler",
  ORGANIZATION_HAS_NOT_VOICE_ASTERISK: "Valgt organisasjon har ingen tale-asterisk tildelt!",
  SIGNATURE_PREVIEW: "Forhåndsvisning av signatur",
  KEYWORDS: "Nøkkelord",
  TAG_KEYWORDS_HELP: "Nøkkelord er ikke følsomme for store/små bokstaver.",
  AUTO_TAGS: "Automatiske tagger",
  VOICE_TRANSCRIPT: "Taletranskripsjon",
  SUB_DISPOSITIONS: "Underklassifiseringer",
  THRD_DISPOSITIONS: "Tredjeklassifiseringer",
  STATS_ALARMS: "Alarmer",
  STATS_ALARM: "Alarm",
  TEXT: "Tekst",
  AUTO_TEXT: "Autogenerer tekst",
  STATS_ALARM_PERIOD_LIVE_TODAY: "I dag (Live)",
  STATS_ALARM_PERIOD_TODAY: "I dag",
  STATS_ALARM_PERIOD_LAST_24_HOURS: "Siste 24 timer",
  STATS_ALARM_PERIOD_THIS_WEEK: "Denne uken",
  STATS_ALARM_PERIOD_THIS_WEEK_SUN: "Denne uken (start søndag)",
  STATS_ALARM_PERIOD_LAST_7_DAYS: "Siste 7 dager",
  STATS_ALARM_PERIOD_THIS_MONTH: "Denne måneden",
  STATS_ALARM_PERIOD_LAST_30_DAYS: "Siste 30 dager",
  LAST_MATCH_AT: "Siste treff",
  MIN_INTERVAL: "Minste intervall",
  STATS_ALARM_MIN_INTERVAL_HELP:
    "Minste intervall å vente etter at et varsel er sendt! For å unngå spam siden live-målinger kan endres ofte.",
  NOTIFICATION: "Varsling",
  ATLAS_NOTIFICATION: "Atlas-varsling",
  USER_IDS: "Brukere",
  HIGHLIGHTED: "Markert",
  MATCHED_CONDITION: "matchet betingelsen",
  SOUND_TYPE_ELEVENLABS: "Tekst til tale",
  SOUND_TYPE_FILEUPLOAD: "Filopplasting",
  ELEVENLABS_VOICE_ALICE: "Alice",
  ELEVENLABS_VOICE_ARIA: "Aria",
  ELEVENLABS_VOICE_BILL: "Bill",
  ELEVENLABS_VOICE_BRIAN: "Brian",
  ELEVENLABS_VOICE_CALLUM: "Callum",
  ELEVENLABS_VOICE_CHARLIE: "Charlie",
  ELEVENLABS_VOICE_CHARLOTTE: "Charlotte",
  ELEVENLABS_VOICE_CHRIS: "Chris",
  ELEVENLABS_VOICE_DANIEL: "Daniel",
  ELEVENLABS_VOICE_ERIC: "Eric",
  ELEVENLABS_VOICE_GEORGE: "George",
  ELEVENLABS_VOICE_JESSICA: "Jessica",
  ELEVENLABS_VOICE_LAURA: "Laura",
  ELEVENLABS_VOICE_LIAM: "Liam",
  ELEVENLABS_VOICE_LILY: "Lily",
  ELEVENLABS_VOICE_MATILDA: "Matilda",
  ELEVENLABS_VOICE_RIVER: "River",
  ELEVENLABS_VOICE_ROGER: "Roger",
  ELEVENLABS_VOICE_SARAH: "Sarah",
  ELEVENLABS_VOICE_WILL: "Will",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_CONVERSATIONAL_SWEDISH: "Sanna Hartfield - Svensk samtale",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_SASSY_SWEDISH: "Sanna Hartfield - Frekk svensk",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_NARRATION_SWEDISH: "Sanna Hartfield - Svensk fortellerstemme",
  ELEVENLABS_VOICE_JONAS: "Jonas, rolig & informativ norsk stemme",
  ELEVENLABS_SETTINGS: "Generatorinnstillinger",
  QUEUE_SORT_LASTINBOUNDMESSAGEAT: "Siste innkommende melding ved",
  QUEUE_SORT_LASTOUTBOUNDMESSAGEAT: "Siste utgående melding ved",
  QUEUE_SORT_UNHANDLED: "Uhåndterte",
  QUEUE_SORT_QUEUEJOINTIME: "Kø-tid",
  SORT: "Sorter etter",
  EFFECTIVE_WORK_TIME: "Effektiv arbeidstid",
  ARTIFICIELL_INTELLIGENCE: "Kunstig intelligens",
  AI_ASSISTANTS: "KI-assistenter",
  AI_ASSISTANT: "KI-assistent",
  KB_UNIQUE_ID: "KB unikt id",
  AI_ASSISTANT_ID: "KI-assistent",
  NO_AI_ASSISTANT_ON_QUEUE: "Ingen KI-assistent på denne køen",
  NEW_MAIL_MESSAGE_WITH_AI: "Ny e-postmelding med KI",
  REPLY_WITH_AI: "Svar med KI",
  AI_RESPONDING: "KI svarer...",
  AI_RESPONSE_CONFIDENCE: "KI-svarets pålitelighet",
  AI_REQUEST_KB_ASSISTANT: "KI-forespørsel (Svarassistanse)",
  AI_REQUEST_KB_ASSISTANCE: "KI-forespørsel (Svarassistanse)",
  KbAssistant: "Svarassistanse",
  REQ: "foresp",
  INVOICE_POST_VOICE_RECORDING_TRANSCRIPT: "Taletranskripsjon",
  TEXT_TO_VOICE_USAGE: "Tekst til tale-bruk",
  QUEUE_SORT_HELP:
    '"Uhåndterte" sorterer etter kø-tid og filtrerer i tillegg til kun samtaler som ikke har blitt besvart.',
  FIELD_CONDITIONS: "Feltbetingelser",
  FIELD_TO_AFFECT: "Felt å påvirke",
  CONDITIONAL_FIELD: "Betinget felt",
  THEN: "Da",
  FORBID: "Forby",
  MAKE_OPTIONAL: "Gjør valgfri",
  IF: "Hvis",
  MAX: "MAKS",
  MAXIMUM: "Maksimum",
  HELP: "Hjelp",
  HIDE_HELP: "Skjul hjelp",
  VALIDATION_MAXIMUM_NUMBER_HELP: "Maksimalt tall som kan fylles i dette feltet",
  VALIDATION_MAXIMUM_LIST_HELP: "Maksimalt antall alternativer",
  VALIDATION_MAXIMUM_TEXT_HELP: "Maksimalt antall tegn",
  DEFAULT_VALUE: "Standardverdi",
  IMPORT_FORM_SUBMISSION: "Importer skjemainnsending",
  SAVE_REPLY: "Lagre svar",
  EDIT_REPLY: "Rediger svar",
  CREATE_REPLY: "Opprett svar",
  HIDE_REPLY: "Skjul svar",
  IMPORT_CONVERSATION_FORM: "Importer samtaleskjema",
  CUSTOM_ANSWER: "Tilpasset",
  FIELD_CUSTOM_ANSWER_HELP:
    "Sluttbrukeren kan fylle inn tilpasset svar i et tekstfelt. Angitt verdi vil bli satt til alias {field_alias}_custom.",
  CLICK_TO_COPY: "Klikk for å kopiere",
  RECONNECTS_AT: "Kobler til igjen ved",
  DOWNLOAD_EML: "Last ned e-postmelding .eml",
  USER_SETTINGS: "Brukerinnstillinger",
  ENGLISH: "Engelsk",
  SWEDISH: "Svensk",
  DANISH: "Dansk",
  NORWEGIAN: "Norsk",
  FINNISH: "Finsk",
  GERMAN: "Tysk",
  SHOW_MORE_FIELDS: "Vis flere felt",
  INVALID: "Ugyldig",
  TOO_SHORT: "For kort",
  TOO_LONG: "For langt",
  ERROR_QUEUE_NO_CREATE_LIST: "Køen har ingen oppretteliste",
  TEXT_MESSAGE: "Tekstmelding",
  TEXT_MESSAGE_SOURCE_CREATED: "Tekstmeldingskilde opprettet",
  TEXT_MESSAGE_SOURCE_CREATED_ERROR: "Feil ved opprettelse av tekstmeldingskilde",
  TEXT_MESSAGE_SOURCE: "Tekstmeldingskilde",
  TEXT_MESSAGE_SOURCES: "Tekstmeldingskilder",
  ALPHA_TAG: "Alfa-tag",
  SELECT_ORGANIZATION: "Velg organisasjon",
  MESSAGE: "Melding",
  SELECT_SOURCE: "Velg kilde",
  SELECT_QUEUE_WITH_CREATE_LIST: "Velg en kø med en oppretteliste for å opprette en kontakt",
  BEGIN_TYPING_TO_SEE_CONTACT: "Begynn å skrive for å se kontakter...",
  MISSING_CONTACT: "Kontakt mangler",
  MISSING_EMAIL: "E-post mangler",
  MISSING_SUBJECT: "Emne mangler",
  MISSING_CONTACT_FORM: "Kontaktskjemaet mangler",
  VERIFIED: "Verifisert",
  TEXT_MESSAGE_SOURCE_UPDATED: "Tekstmeldingskilde oppdatert",
  TEXT_MESSAGE_SOURCE_UPDATE_FAILED: "Tekstmeldingskilde oppdatering feilet",
  SENDER_PHONE_NUMBER: "Avsender telefonnummer",
  CONVERSATION_ENUM_ID: "Samtale ID",
  CONVERSATION_ENUM_EMAIL: "Kontakt email",
  CONVERSATION_ENUM_STATUS: "Status",
  CONVERSATION_ENUM_CHANNEL: "Kanal",
  CONVERSATION_ENUM_DIRECTION: "Retning",
  CONVERSATION_ENUM_QUEUE_NAME: "Kønavn",
  CONVERSATION_ENUM_AGENT_NAME: "Agentnavn",
  CONVERSATION_ENUM_DISPOSITION: "Klassifisering",
  CONVERSATION_ENUM_CREATED_DATE: "Opprettet dato",
  CONVERSATION_ENUM_CALLER_ID: "Anropers ID",
  CONVERSATION_ENUM_ORGANIZATION_ID: "Organisasjons ID",
  CONVERSATION_ENUM_ORGANIZATION_TITLE: "Organisasjonstittel",
  DESELECT_ALL: "Fjern alle valg",
  CONVERSATION_DOWNLOAD: "Velg felt som skal inkluderes i nedlastingen",
  NEW_TEXT_MESSAGE: "Ny tekstmelding",
  SUMMARIZE_CONVERSATION: "Sammenfatte samtale",
  GET_NEW_SUMMARY: "Hent ny sammendrag",
  CONVERSATION_SUMMARY: "Sammendrag",
  OPEN_SUMMARY: "Åpne sammendrag",
  CLOSE_SUMMARY: "Lukk sammendrag",
  MESSAGE_SENT_SUCCESSFULLY: "Melding sendt",
  MESSAGE_TOO_SHORT: "Melding må være minst 10 tegn lang",
  MISSING_SOURCE: "Kilde mangler",
  MISSING_PHONE_NUMBER: "Telefonnummer mangler",
  ERROR_SENDING_MESSAGE: "Feil ved sending av melding",
  MISSING_QUEUE: "Kø mangler",
};
