import { CountryFlagImg } from "@Utils";
import { Select, Typography } from "antd";
import { useTranslation } from "react-i18next";

export const LanguageSelector = () => {
  const { Title } = Typography;
  const { t, i18n } = useTranslation();

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("atlas-language", language);

    // Memoization prevents some components from updating
    // Reloading the page is a quick fix
    // This can be improved in the future
    window.location.reload();
  };

  const languageOptions = [
    {
      value: "en",
      label: (
        <span className="language-selector-label">
          <CountryFlagImg t={t} country="us" /> {t("ENGLISH")}
        </span>
      ),
    },
    {
      value: "sv",
      label: (
        <span className="language-selector-label">
          <CountryFlagImg t={t} country="se" /> {t("SWEDISH")}
        </span>
      ),
    },
    {
      value: "da",
      label: (
        <span className="language-selector-label">
          <CountryFlagImg t={t} country="dk" /> {t("DANISH")}
        </span>
      ),
    },
    {
      value: "no",
      label: (
        <span className="language-selector-label">
          <CountryFlagImg t={t} country="no" /> {t("NORWEGIAN")}
        </span>
      ),
    },
    {
      value: "fi",
      label: (
        <span className="language-selector-label">
          <CountryFlagImg t={t} country="fi" /> {t("FINNISH")}
        </span>
      ),
    },
    {
      value: "de",
      label: (
        <span className="language-selector-label">
          <CountryFlagImg t={t} country="de" /> {t("GERMAN")}
        </span>
      ),
    },
  ];

  return (
    <div className="language-selector">
      <Title level={2}>{t("LANGUAGE")}</Title>
      <Select
        value={i18n.language}
        onChange={onLanguageChange}
        options={languageOptions}
        className="language-selector-select"
      />
    </div>
  );
};
