"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionCloseTabs = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const misc_1 = require("../../misc");
exports.JourneyActionCloseTabs = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.CloseTabs,
    settings_schema: (0, base_1.settingsValidationSchema)({
        conversation_owner_only: {
            type: 'boolean',
            required: true,
            default: false,
        },
    }),
    channels: (0, misc_1.EnumValues)(enums_1.ChannelEnum),
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_CLOSE_TABS_CLOSED_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_CLOSE_TABS_CLOSED_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                title: 'CLOSED',
            }) });
    },
});
