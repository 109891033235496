"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedAbility = void 0;
const enums_1 = require("../enums");
const misc_1 = require("../misc");
class SharedAbility {
    constructor(adminprofile, user, isSuperadminProfile = false) {
        this.adminprofile = adminprofile;
        this.user = user;
        this.isSuperadminProfile = isSuperadminProfile;
    }
    preview_permissions() {
        return [
            enums_1.PermissionEnum.User,
            enums_1.PermissionEnum.Team,
            enums_1.PermissionEnum.Queue,
            enums_1.PermissionEnum.Client,
            enums_1.PermissionEnum.MailAccount,
            enums_1.PermissionEnum.ContactCustomEvent,
            enums_1.PermissionEnum.Disposition,
            enums_1.PermissionEnum.Tag,
            enums_1.PermissionEnum.MailMessage,
            enums_1.PermissionEnum.FormSubmission,
            enums_1.PermissionEnum.InternalNote,
            enums_1.PermissionEnum.Pause,
            enums_1.PermissionEnum.Agentprofile,
            enums_1.PermissionEnum.Organization,
            enums_1.PermissionEnum.Form,
            enums_1.PermissionEnum.Field,
            enums_1.PermissionEnum.CannedAnswer,
            enums_1.PermissionEnum.Signature,
            enums_1.PermissionEnum.List,
            enums_1.PermissionEnum.FormWebsite,
            enums_1.PermissionEnum.VoiceAsterisk,
            enums_1.PermissionEnum.VoiceTrunk,
            enums_1.PermissionEnum.VoiceRoute,
            enums_1.PermissionEnum.VoiceVoicemail,
            enums_1.PermissionEnum.VoiceShortcut,
            enums_1.PermissionEnum.StatsCustomMetric,
            enums_1.PermissionEnum.Tariff,
            enums_1.PermissionEnum.PhonenumberMonthlyCost,
            enums_1.PermissionEnum.PhonenumberSetupCost,
            enums_1.PermissionEnum.SuperadminProfile,
            enums_1.PermissionEnum.Adminprofile,
            enums_1.PermissionEnum.Skill,
            enums_1.PermissionEnum.Sound,
            enums_1.PermissionEnum.AiAssistant,
        ].map(id => ({
            id,
            conditions: null,
            allow: true,
            crud: enums_1.CrudEnum.Preview,
        }));
    }
    global_permissions() {
        const user = this.user;
        const full_permission = enums_1.CrudEnum.Read + enums_1.CrudEnum.Update + enums_1.CrudEnum.Insert + enums_1.CrudEnum.Delete;
        let global = [
            {
                id: enums_1.PermissionEnum.User,
                conditions: { id: [user.id,], },
                allow: true,
                crud: enums_1.CrudEnum.Update,
            },
            {
                id: enums_1.PermissionEnum.NotificationUser,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: enums_1.CrudEnum.Read + enums_1.CrudEnum.Update,
            },
            {
                id: enums_1.PermissionEnum.ConversationFavourite,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.Conversation,
                conditions: null,
                allow: true,
                crud: enums_1.CrudEnum.Read + enums_1.CrudEnum.Update + enums_1.CrudEnum.Insert,
            },
            {
                id: enums_1.PermissionEnum.ContactCustomEvent,
                conditions: null,
                allow: true,
                crud: enums_1.CrudEnum.Read,
            },
            {
                id: enums_1.PermissionEnum.MailMessage,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: enums_1.CrudEnum.Update,
            },
            {
                id: enums_1.PermissionEnum.Draft,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.Contact,
                conditions: null,
                allow: true,
                crud: enums_1.CrudEnum.Read + enums_1.CrudEnum.Update + enums_1.CrudEnum.Insert,
            },
            {
                id: enums_1.PermissionEnum.InternalNote,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: enums_1.CrudEnum.Insert + enums_1.CrudEnum.Update + enums_1.CrudEnum.Delete,
            },
            {
                id: enums_1.PermissionEnum.ConversationWatcher,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.ConversationFilter,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.RecentlySearched,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.RecentlyViewedConversation,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.Tab,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.Session,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.VoiceInternalCall,
                conditions: {
                    or: [
                        { user_id: [user.id,], },
                        { callee_user_id: [user.id,], },
                    ],
                },
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.VoiceChanspy,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: enums_1.CrudEnum.Read + enums_1.CrudEnum.Delete,
            },
            {
                id: enums_1.PermissionEnum.VoiceCall,
                conditions: {
                    or: [
                        { user_id: [user.id,], },
                        { callee_user_id: [user.id,], },
                    ],
                },
                allow: true,
                crud: enums_1.CrudEnum.Read + enums_1.CrudEnum.Update + enums_1.CrudEnum.Delete,
            },
            {
                id: enums_1.PermissionEnum.VoiceChanspy,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.StatsReport,
                conditions: null,
                allow: true,
                crud: enums_1.CrudEnum.Read,
            },
            {
                id: enums_1.PermissionEnum.StatsReport,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: enums_1.CrudEnum.Update,
            },
            {
                id: enums_1.PermissionEnum.StatsReportWidget,
                conditions: null,
                allow: true,
                crud: full_permission,
            },
            {
                id: enums_1.PermissionEnum.StatsReportPreviewWidget,
                conditions: null,
                allow: true,
                crud: enums_1.CrudEnum.Read + enums_1.CrudEnum.Insert,
            },
            {
                id: enums_1.PermissionEnum.StatsReportPreviewWidget,
                conditions: {
                    user_id: [user.id,],
                },
                allow: true,
                crud: enums_1.CrudEnum.Delete + enums_1.CrudEnum.Update,
            },
            {
                id: enums_1.PermissionEnum.StatsWidget,
                conditions: null,
                allow: true,
                crud: enums_1.CrudEnum.Read,
            },
            {
                id: enums_1.PermissionEnum.StatsCustomMetric,
                conditions: null,
                allow: true,
                crud: enums_1.CrudEnum.Read,
            },
        ];
        return global;
    }
    permissions(subject, action, user, role) {
        var _a, _b;
        const filterBySubject = (it) => it.id === subject;
        const permissions = [
            ...(!this.isSuperadminProfile
                ? (((_a = this.adminprofile) === null || _a === void 0 ? void 0 : _a.permissions) || [])
                    .filter(filterBySubject)
                : []),
            ...(this.isSuperadminProfile
                ? (((_b = this.adminprofile) === null || _b === void 0 ? void 0 : _b.permissions) || [])
                    .filter(filterBySubject)
                    .map(permission => (Object.assign(Object.assign({}, permission), { allow: true, conditions: null })))
                : []),
            ...this.global_permissions().filter(filterBySubject),
        ];
        const preview_permissions = this.preview_permissions();
        if (action === enums_1.CrudEnum.Preview) {
            return [
                ...preview_permissions.filter(filterBySubject),
                ...permissions.map(permission => (Object.assign(Object.assign({}, permission), { crud: (permission.crud & enums_1.CrudEnum.Read) === enums_1.CrudEnum.Read && !preview_permissions.some(preview_permission => preview_permission.id === permission.id) ? permission.crud + enums_1.CrudEnum.Preview : permission.crud }))),
            ];
        }
        return permissions;
    }
    checkForcedPermissions(user, user_organization, action, subject) {
        return (subject === enums_1.PermissionEnum.PhonenumberPrefix
            && (action === enums_1.CrudEnum.Read || action === enums_1.CrudEnum.Preview)
            && (this.canImplicit(user, user_organization, enums_1.CrudEnum.Insert, enums_1.PermissionEnum.PhonenumberStock)
                || this.canImplicit(user, user_organization, enums_1.CrudEnum.Insert, enums_1.PermissionEnum.PhonenumberPurchase)))
            ||
                ([enums_1.PermissionEnum.PhonenumberStock, enums_1.PermissionEnum.PhonenumberMonthlyCost, enums_1.PermissionEnum.PhonenumberSetupCost,].includes(subject)
                    && (action === enums_1.CrudEnum.Read || action === enums_1.CrudEnum.Preview)
                    && this.canImplicit(user, user_organization, enums_1.CrudEnum.Insert, enums_1.PermissionEnum.PhonenumberPurchase));
    }
    canImplicit(user, user_organization, action, subject) {
        if (this.checkForcedPermissions(user, user_organization, action, subject))
            return true;
        let can = false;
        for (const { id, conditions, allow, crud, } of this.permissions(subject, action, user, user_organization.role)) {
            if (id === subject) {
                if ((crud & action) === action) {
                    if (conditions)
                        return true;
                    else {
                        if (!allow)
                            return false;
                        can = true;
                    }
                }
            }
        }
        return can;
    }
    permissionUserRead(action, user, item) {
        const user_organization_ids = [
            ...user.__agent_organization_ids,
            ...user.__admin_organization_ids,
        ];
        const item_organization_ids = [
            ...item.__agent_organization_ids,
            ...item.__admin_organization_ids,
        ];
        if (action === enums_1.CrudEnum.Read || action === enums_1.CrudEnum.Preview) {
            return item_organization_ids.some(organization_id => user_organization_ids.includes(organization_id));
        }
        else {
            return item_organization_ids.every(organization_id => user_organization_ids.includes(organization_id));
        }
        return false;
    }
    can(user, user_organization, action, subject, item) {
        if (subject === enums_1.PermissionEnum.User)
            return this.permissionUserRead(action, user, item);
        if (subject === enums_1.PermissionEnum.SuperadminProfile && item.id === 1)
            return false;
        if (this.checkForcedPermissions(user, user_organization, action, subject))
            return true;
        let can = false;
        for (const { id, conditions, allow, crud, } of this.permissions(subject, action, user, user_organization.role)) {
            if (id === subject) {
                if ((crud & action) === action) {
                    let _conditions = conditions ? Object.assign({}, conditions) : null;
                    if (_conditions) {
                        if (this.condition(item, _conditions)) {
                            if (!allow && action !== enums_1.CrudEnum.Insert)
                                return false;
                            if (allow)
                                can = true;
                        }
                    }
                    else {
                        if (!allow && action !== enums_1.CrudEnum.Insert)
                            return false;
                        if (allow)
                            can = true;
                    }
                }
            }
        }
        return can;
    }
    condition(item, conditions, alias = null) {
        for (const key in conditions) {
            const value = item[alias || key];
            const str_value = item[alias || key] + '';
            const condition = conditions[key];
            const str_condition = condition + '';
            if (key === 'contains_every') {
                if (!condition.length || !value.every(val => condition.includes(val)))
                    return false;
            }
            else if (key === 'or') {
                return condition.some(cond => Object.entries(cond).some(([or_key, or_condition,]) => this.condition(item, or_condition, or_key)));
            }
            else {
                if (Array.isArray(condition)) {
                    if (!condition.includes(value))
                        return false;
                }
                else if ((0, misc_1.isObject)(condition)) {
                    if (!this.condition(item, condition, key))
                        return false;
                }
                else {
                    if (str_condition.toString() !== str_value)
                        return false;
                }
            }
        }
        return true;
    }
}
exports.SharedAbility = SharedAbility;
