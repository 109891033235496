import React, { useCallback } from "react";
import "./header.logo.scss";
import logo from "@Assets/graphic/logo.png";
import { actionDisplayChangelog, actionSetTheme, IAuth, useAppDispatch } from "@Store";
import { useTheme } from "@Hooks";
import { Icon } from "@Components/misc";
import { MoonIcon, SunIcon } from "@Assets";
import { CURRENT_VERSION } from "@Components/dashboard/changelog";
import { TFunction } from "i18next";
import { Tooltip } from "antd";

interface IProps {
  t: TFunction;
  auth: IAuth;
  hideVersion?: boolean;
}

export const HeaderLogo = React.memo(({ t, auth, hideVersion }: IProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const toggleTheme = useCallback(() => {
    dispatch(actionSetTheme(theme === "light" ? "dark" : "light"));
  }, [theme, dispatch]);

  return (
    <div id="logo">
      {!hideVersion && (
        <Tooltip title={t("DISPLAY_CHANGELOG_HISTORY")}>
          <div className="version" onClick={() => dispatch(actionDisplayChangelog(true))}>
            {CURRENT_VERSION}
          </div>
        </Tooltip>
      )}
      <img src={logo} alt="logo" style={{ opacity: auth.user.main ? 1 : 0.2 }} />
      <Icon
        className="theme"
        onClick={toggleTheme}
        icon={theme === "light" ? MoonIcon : SunIcon}
        tooltip={{ title: t(`SWITCH_TO_${theme === "light" ? "DARK" : "LIGHT"}_THEME`) }}
      />
    </div>
  );
});
