import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Typography, Select, Input, Button, Row, Col, Card } from "antd";
import "../styles.scss";
import { useAuth } from "@Hooks";
import { useAsync } from "@Hooks/abstract/useAsync";
import { useConversationDraft, useConversationSubmit } from "../hooks";
import {
  fetchCannedAnswers,
  fetchClients,
  fetchMailAccounts,
  fetchQueues,
  fetchSignatures,
  fetchTags,
} from "@Api";
import {
  ChannelEnum,
  ConversationPriorityEnum,
  ISharedContact,
  ISharedCreateConversation,
  EnumToArray,
} from "atlas-shared";
import { convertToDraftChannel } from "../utils/channelConversion";
import { FormHtmlEditor } from "atlas-form";
import { RestRequest } from "@Utils";
import { ContactSearchSelect } from "./ContactSearchSelect";
import { SendIcon, ArrowRightIcon } from "@Assets";
import style from "./index.module.scss";
import AppSpinner from "@Components/misc/app-spinner/app.spinner";

interface CreateEmailConversationProps {
  channel?: ChannelEnum | undefined;
  contact_id?: ISharedContact["id"] | undefined;
  contact_organization_id?: ISharedContact["organization_id"] | undefined;
  queue_id?: string | undefined;
  organization_id?: string | undefined;
  close?: () => void;
}

export const CreateEmailConversation = React.memo((props: CreateEmailConversationProps) => {
  const { organization_id, close } = props;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Title } = Typography;
  const auth = useAuth();

  const { data: _queues, loading: queuesLoading } = useAsync(fetchQueues);
  const { data: _clients, loading: clientsLoading } = useAsync(fetchClients);
  const { data: _tags, loading: tagsLoading } = useAsync(fetchTags);
  const { data: _signatures, loading: signaturesLoading } = useAsync(fetchSignatures);
  const { data: _mailAccounts, loading: mailAccountsLoading } = useAsync(fetchMailAccounts);
  const { data: cannedAnswers, loading: cannedAnswersLoading } = useAsync(fetchCannedAnswers);
  const [showBCC, setShowBCC] = useState(false);
  const [showCC, setShowCC] = useState(false);

  const paramsContact = useMemo(
    () =>
      searchParams.get("contact_id")
        ? {
            contact_id: searchParams.get("contact_id") || "",
            contact_email: searchParams.get("contact_email") || "",
            contact_number: searchParams.get("contact_number") || "",
          }
        : undefined,
    [searchParams]
  );

  const _values = form.getFieldsValue();
  const [values, setValues] = useState<any>({
    organization_id: organization_id,
  });
  const queues = _queues?.filter((queue) => queue.__outbound_mail_accounts.length);
  const queue = queues?.find((queue) => queue.id == values.queue_id);
  const tags = _tags?.filter((tag) => queue?.__tags.includes(tag.id) || tag.global).reverse();
  const signatures = _signatures?.filter(
    (signature) => queue?.__agent_signatures.includes(signature.id) || signature.global
  );
  const clients = _clients?.filter((client) => queue?.clients.includes(client.id));
  const mailAccounts = _mailAccounts?.filter((mailAccount) => {
    return queue?.__outbound_mail_accounts.includes(mailAccount.id);
  });

  const draftValues = useMemo(
    () => ({
      organization_id: queue?.organization_id,
      channel: convertToDraftChannel(values.main_channel),
      user_id: auth.user.id.toString(),
      values: values as ISharedCreateConversation,
    }),
    [queue?.organization_id, values.main_channel, auth.user.id, values]
  );

  const {
    draft,
    reloadDraft,
    loading: draftLoading,
    handleAttachmentDelete,
    handleAttachmentDownload,
    debouncedUpdateDraft,
  } = useConversationDraft({
    ...draftValues,
    setValues,
  });

  const loading =
    queuesLoading ||
    clientsLoading ||
    signaturesLoading ||
    mailAccountsLoading ||
    draftLoading ||
    cannedAnswersLoading;

  const onChange = (changes: any) => {
    // form.setFieldsValue(values);
    if (changes.title) {
      setValues((prev) => ({ ...prev, title: changes.title }));
    }

    if (changes.reply_to_cc) {
      setValues((prev) => ({ ...prev, reply_to_cc: changes.reply_to_cc }));
    }

    if (changes.reply_to_bcc) {
      setValues((prev) => ({ ...prev, reply_to_bcc: changes.reply_to_bcc }));
    }

    if (changes.priority) {
      setValues((prev) => ({ ...prev, priority: changes.priority }));
    }

    if (changes.__tags) {
      setValues((prev) => ({ ...prev, __tags: changes.__tags }));
    }
  };

  const typing_started_at = useMemo(() => new Date(), []);
  const initialQueueSetRef = React.useRef(false);

  const {
    handleSubmit,
    isSubmitting,
    error: conversationError,
  } = useConversationSubmit({
    values: values as ISharedCreateConversation,
    user: auth.user,
    typing_started_at,
    draft,
    navigate,
    t,
    onClose: close,
  });

  const onQueueChange = (queueId) => {
    const queue = queues?.find((queue) => queue.id == queueId);

    const defaultMailAccount = _mailAccounts?.find((mailAccount) =>
      queue?.__outbound_mail_accounts.includes(mailAccount.id)
    );

    const deafultSignatures =
      _signatures?.find((signature) => queue?.__agent_signatures.includes(signature.id)) ??
      signatures?.[0];

    form.setFieldValue("queue_id", queue?.id);
    form.setFieldValue("mail_account_id", defaultMailAccount?.id);
    form.setFieldValue("signature_id", deafultSignatures?.id);

    setValues((prev) => ({
      ...prev,
      queue_id: queue?.id,
      mail_account_id: defaultMailAccount?.id,
      signature_id: deafultSignatures?.id,
    }));
  };

  useEffect(() => {
    debouncedUpdateDraft(values.body, values.title);
  }, [debouncedUpdateDraft, values.body, values.title]);

  useEffect(() => {
    if (values.body === undefined && draft?.body) {
      setValues((prevValues) => ({ ...prevValues, body: draft.body }));
    }
  }, [draft?.body]);

  useEffect(() => {
    const deafultQueue = queues?.find((queue) => queue.__outbound_mail_accounts.length > 0);

    if (!loading && deafultQueue && !initialQueueSetRef.current) {
      initialQueueSetRef.current = true;
      onQueueChange(deafultQueue.id);
    }
  }, [loading, queues]);

  if (loading) return <AppSpinner />;

  return (
    <div className={"create-conversation-form " + style.contacts}>
      <Form form={form} initialValues={values} onValuesChange={onChange} layout="horizontal">
        <Card
          style={{ padding: "1.2em", backgroundColor: "transparent" }}
          headStyle={{ backgroundColor: "transparent" }}
          extra={
            <Row align={"middle"} gutter={6}>
              <Col>
                <Form.Item name="queue_id" required>
                  <Select
                    filterOption={(input, option) =>
                      (option?.key as string).toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    onChange={(value) => {
                      onQueueChange(value);
                    }}
                    style={{ minWidth: "20em" }}
                    size="small"
                    placeholder={`${t("SELECT_QUEUE")}`}
                  >
                    {queues?.map(({ id, title }) => (
                      <Select.Option key={id + title} value={id}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <ArrowRightIcon></ArrowRightIcon>
              </Col>
              <Col flex={1}>
                <Form.Item name="mail_account_id">
                  <Select
                    onChange={(value) => {
                      setValues((prev) => ({ ...prev, mail_account_id: value }));
                    }}
                    style={{ minWidth: "20em" }}
                    filterOption={(input, option) =>
                      (option?.key as string).toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    size="small"
                    placeholder={`${t("MAIL_ACCOUNT")}`}
                  >
                    {mailAccounts?.map(({ id, title }) => (
                      <Select.Option key={id + title} value={id}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          }
          title={<Title level={1}>{t("CREATE_CONVERSATION")}</Title>}
          bordered={false}
        >
          <div>
            <Row gutter={24} style={{ marginBottom: "0px" }}>
              <Col flex={1}>
                <div style={{ position: "relative" }}>
                  <ContactSearchSelect
                    name="contact_email"
                    valueKey="contact_email"
                    paramsContact={paramsContact}
                    suffix={<></>}
                    size="middle"
                    agentprofileId={auth.user.__agentprofiles[0]}
                    selectedQueue={queue}
                    organizationId={values.organization_id || queue?.organization_id}
                    onContactSelect={(id, email) => {
                      form.setFieldValue("contact_id", id);
                      form.setFieldValue("contact_email", email);
                      setValues((prev) => ({ ...prev, contact_id: id, contact_email: email }));
                    }}
                    required
                  />
                  <Row style={{ position: "absolute", right: 0, top: 0 }}>
                    <Col>
                      <Button
                        className="antd-default-styling"
                        style={{ textDecoration: showCC ? "line-through" : "" }}
                        onClick={() => {
                          setValues((prev) => ({ ...prev, reply_to_cc: undefined }));
                          form.setFieldValue("reply_to_cc", undefined);

                          setShowCC(!showCC);
                        }}
                        type="link"
                        size="middle"
                      >
                        CC
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="antd-default-styling"
                        style={{ textDecoration: showBCC ? "line-through" : "" }}
                        onClick={() => {
                          setValues((prev) => ({ ...prev, reply_to_bcc: undefined }));
                          form.setFieldValue("reply_to_bcc", undefined);

                          setShowBCC(!showBCC);
                        }}
                        type="link"
                        size="middle"
                      >
                        BCC
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {showCC && (
              <Row style={{ marginTop: "5px" }}>
                <Col span={2}>
                  <Button className="antd-default-styling" type="link" size="middle">
                    CC
                  </Button>
                </Col>
                <Col flex={1}>
                  <ContactSearchSelect
                    size="middle"
                    name="reply_to_cc"
                    mode="multiple"
                    valueKey="contact_email"
                    organizationId={values.organization_id}
                    agentprofileId={auth.user.__agentprofiles[0]}
                    selectedQueue={queue}
                    required
                  />
                </Col>
              </Row>
            )}
            {showBCC && (
              <Row style={{ marginTop: "5px" }}>
                <Col span={2}>
                  <Button className="antd-default-styling" type="link" size="middle">
                    BCC
                  </Button>
                </Col>
                <Col flex={1}>
                  <ContactSearchSelect
                    size="middle"
                    mode="multiple"
                    valueKey="contact_email"
                    name="reply_to_bcc"
                    organizationId={values.organization_id}
                    agentprofileId={auth.user.__agentprofiles[0]}
                    selectedQueue={queue}
                    required
                  />
                </Col>
              </Row>
            )}

            <Row style={{ marginTop: "20px" }}>
              <Col flex={1}>
                <Form.Item rules={[{ required: true, message: t("MISSING_SUBJECT") }]} name="title">
                  <Input required size="middle" placeholder="Subject"></Input>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Row style={{ marginTop: "30px" }}>
            <Col flex={1}>
              <Form.Item name="body" trigger="onChangeTrigger">
                <FormHtmlEditor
                  value={values.body || ""}
                  onChange={(ev) => {
                    form.setFieldValue("body", ev);
                    setValues((prev) => ({ ...prev, body: ev }));
                  }}
                  atOptions={["user"]}
                  hashOptions={["contact", "agent", "conversation", "field"]}
                  canned_answers={cannedAnswers ?? []}
                  file_upload={{
                    attachments: draft?.attachments || [],
                    action: RestRequest.getFullSrc(`/draft/${draft?.id}/attachment`),
                    headers: RestRequest.getHeaders(),
                    onDelete: handleAttachmentDelete,
                    onDownload: handleAttachmentDownload,
                    onDownloadUri: (index: number) => `draft/${draft?.id}/attachment/${index}`,
                    onChange: (file) => file.status !== "uploading" && reloadDraft(),
                    draft_id: draft?.id,
                    dragAndDrop: true,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row align={"bottom"} justify={"space-between"}>
            <Col>
              <Row gutter={12} align={"bottom"}>
                <Col>
                  <Form.Item
                    name="signature_id"
                    label={<span className="visually-hidden">{t("SIGNATURE_ID")}</span>}
                  >
                    <Select
                      placeholder={t("SIGNATURE_ID")}
                      allowClear
                      options={signatures?.map((sig) => ({
                        value: sig.id,
                        key: sig.id,
                        label: sig.title,
                      }))}
                      onChange={(value) => {
                        setValues((prev) => ({ ...prev, signature_id: value }));
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="client_id"
                    label={<span className="visually-hidden">{t("CLIENT_ID")}</span>}
                  >
                    <Select
                      showSearch
                      options={clients?.map((sig) => ({
                        value: sig.id,
                        key: sig.id,
                        label: sig.title,
                      }))}
                      placeholder={t("CLIENT_ID")}
                      style={{ minWidth: "6em" }}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="priority" label={t("PRIORITY")} className="priority-select">
                    <Select allowClear placeholder={t("PRIORITY")}>
                      {EnumToArray(ConversationPriorityEnum)
                        .map(([v, k]) => ({
                          key: v,
                          title: t(`PRIORITY_${k.toUpperCase()}`),
                        }))
                        .map(({ key, title }) => (
                          <Select.Option key={key} value={key}>
                            {title}
                          </Select.Option>
                        ))
                        .reverse()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="__tags" label={t("TAGS")} className="tags-select">
                    <Select
                      style={{ minWidth: "6em" }}
                      allowClear
                      mode="multiple"
                      placeholder={t("TAGS")}
                      options={tags?.map((sig) => ({
                        value: sig.id,
                        key: sig.id,
                        label: sig.title,
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={12} align={"bottom"}>
                <Col>
                  <Button
                    onClick={async () => {
                      await form.validateFields();

                      const cleaned = { ...values };

                      for (const key in cleaned) {
                        if (cleaned[key] == null) {
                          delete cleaned[key];
                        }
                      }

                      await handleSubmit({
                        main_channel: "M",
                        ...cleaned,
                      } as ISharedCreateConversation);
                    }}
                    className="antd-default-styling"
                    type="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    icon={<SendIcon />}
                    style={{ gap: 8 }}
                  >
                    {t("SEND")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
});
