import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./conversation.reply.scss";
import { Select, Skeleton } from "antd";
import { TrashIcon } from "@Assets/icons";
import {
  CannedAnswerTypeEnum,
  ChannelEnum,
  DraftChannelEnum,
  IPreviewCannedAnswer,
  IPreviewForm,
  IPreviewMailAccount,
  IPreviewSignature,
  ISharedAiRequest,
  ISharedDraft,
  ISharedFormSubmission,
  ISharedFullConversation,
  ISharedMailMessage,
  ISharedQueue,
  Nullable,
  SignatureTypeEnum,
  Undefinable,
} from "atlas-shared";
import {
  deleteDraftAttachment,
  downloadDraftAttachment,
  fetchAiRequest,
  patchDraft,
  sendDraft,
} from "@Api";
import { AlertError, Confirm, filterRelated, isEmailValid, RestRequest, TypeModal } from "@Utils";
import { useCannedAnswers, useMailAccounts, useQueues, useSignatures } from "@Hooks";
import { FormHtmlEditor, FormSelect, IFormElementHtml } from "atlas-form";
import {
  Button,
  ConversationFormTablePreview,
  ConversationMessageFormSubmission,
  Icon,
} from "@Components";
import { ConversationMessageReplyTimer } from "./conversation.message.reply.timer";
import FroalaEditor from "froala-editor";
import { IAuth, useAppDispatch } from "@Store";
import { useCreateTextMessage } from "./useCreateTextMessage";
import { EditorFroala } from "@Components/editor-froala";

interface IProps {
  draft: ISharedDraft;
  conversation: ISharedFullConversation;
  conversationForm: Undefinable<IPreviewForm>;
  auth: IAuth;
  onDelete?: () => Promise<any>;
  fetchTextMessages?: () => void;
}

export const ConversationReply = React.memo(
  ({
    conversation,
    onDelete,
    draft: _draft,
    auth,
    conversationForm,
    fetchTextMessages,
  }: IProps) => {
    const { t } = useTranslation();
    const { Option } = Select;
    const [draft, _setDraft] = useState<ISharedDraft>(_draft);
    const signatures = useSignatures();
    const mail_accounts = useMailAccounts();
    const canned_answers = useCannedAnswers();
    const queues = useQueues();
    const [queue, setQueue] = useState<Undefinable<ISharedQueue>>();
    const [save, setSave] = useState<Nullable<number>>(null);
    const [sent, setSent] = useState<boolean>(false);
    const [displayEditReply, setDisplayEditReply] = useState<boolean>(false);
    const [editReply, setEditReply] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [formSubmissionPreviewImport, setFormSubmissionPreviewImport] =
      useState<Undefinable<React.ReactElement>>();
    const [conversationFormPreviewImport, setConversationFormPreviewImport] =
      useState<Undefinable<React.ReactElement>>();
    const [ccOptions, setCCOptions] = useState<Array<string>>([]);
    const [bccOptions, setBCCOptions] = useState<Array<string>>([]);
    const [emailOptions, setEmailOptions] = useState<Array<ISharedMailMessage["contact_email"]>>(
      []
    );
    const [addedEmailOption, setAddedEmailOption] =
      useState<Undefinable<ISharedMailMessage["contact_email"]>>();
    const [signature, setSignature] = useState<Undefinable<IPreviewSignature>>();
    const [ai_request, set_ai_request] = useState<Nullable<ISharedAiRequest>>(null);
    const dispatch = useAppDispatch();

    const {
      textMessage,
      updateTextMessage,
      sendMessage: sendTextMessage,
      textMessageSources,
      MAX_TEXT_MESSAGE_LENGTH,
      MIN_TEXT_MESSAGE_LENGTH,
    } = useCreateTextMessage(conversation, queue, onDelete);

    const mailAccount = useMemo(() => {
      return (
        draft.account_id &&
        draft.channel === DraftChannelEnum.Mail &&
        mail_accounts.dict[draft.account_id]
      );
    }, [mail_accounts, draft]);

    useEffect(() => {
      if (draft.signature_id != signature?.id)
        setSignature(draft.signature_id ? signatures.dict[draft.signature_id] : undefined);
    }, [draft.signature_id, signatures]);

    useEffect(() => {
      if (queues.loaded && conversation.queue_id)
        setQueue(queues.dict[conversation.queue_id] as ISharedQueue);
    }, [queues, conversation.queue_id]);

    useEffect(() => {
      setEmailOptions(
        [
          ...(conversation.contact?.emails || []),
          addedEmailOption as ISharedMailMessage["contact_email"],
        ].filter(Boolean)
      );
    }, [conversation.contact, addedEmailOption]);

    useEffect(() => {
      if (mailAccount)
        setCCOptions(
          [
            ...draft.reply_to_cc,
            ...conversation.messages
              .map((m) => (m as ISharedMailMessage).cc || [])
              .flat()
              .flatMap((cc) => cc.address),
            ...conversation.messages.map((m) => (m as ISharedMailMessage).bcc || []).flat(),
          ].filter((address) => address !== mailAccount.address)
        );
    }, [conversation.messages, mailAccount]);

    useEffect(() => {
      if (mailAccount)
        setBCCOptions(
          [
            ...draft.reply_to_bcc,
            ...conversation.messages
              .map((m) => (m as ISharedMailMessage).cc || [])
              .flat()
              .flatMap((cc) => cc.address),
            ...conversation.messages.map((m) => (m as ISharedMailMessage).bcc || []).flat(),
          ].filter((address) => address !== mailAccount.address)
        );
    }, [conversation.messages, mailAccount]);

    useEffect(() => {
      if (draft.ai_request_id && !ai_request)
        fetchAiRequest(draft.organization_id, draft.ai_request_id).then((ai_request) =>
          set_ai_request(ai_request)
        );
    }, [draft.ai_request_id]);

    useEffect(() => {
      if (!draft) return;

      _setDraft({ ...draft, attachments: _draft.attachments });
    }, [_draft.attachments]);

    const _patchDraft = useCallback((draft: ISharedDraft) => {
      const { id, organization_id, created_at, updated_at, ...patch } = draft;

      return patchDraft(id, patch);
    }, []);

    // Remove html from text message
    const handleTextMessageChange = (value: string) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = value;
      const plainText = tempDiv.textContent || tempDiv.innerText || "";
      const trimmedText = plainText.trim();
      updateTextMessage("text", trimmedText);
    };

    const importFormSubmission = useCallback(
      (editor: FroalaEditor) => {
        if (conversation.messages?.at(-1)?.channel !== ChannelEnum.Form) return;

        if (!formSubmissionPreviewImport) {
          setFormSubmissionPreviewImport(
            <span className="table-import" id="table-import-form-submission">
              <div className="form-submission-content">
                <ConversationMessageFormSubmission
                  t={t}
                  auth={auth}
                  message={conversation.messages.at(-1) as ISharedFormSubmission}
                  conversation={conversation}
                  dispatch={dispatch}
                  isStatic={true}
                />
              </div>
            </span>
          );
          setTimeout(() => {
            editor.html.insert(
              document.getElementById("table-import-form-submission")?.innerHTML ||
                "content not loaded"
            );
            setFormSubmissionPreviewImport(undefined);
          }, 500);
        }
      },
      [conversation, formSubmissionPreviewImport]
    );

    const importConversationForm = useCallback(
      (editor: FroalaEditor) => {
        if (!conversationFormPreviewImport) {
          setConversationFormPreviewImport(
            <span className="table-import" id="table-import-conversation-form">
              <div className="form-submission-content">
                <ConversationFormTablePreview conversation={conversation} form={conversationForm} />
              </div>
            </span>
          );
          setTimeout(() => {
            editor.html.insert(
              document.getElementById("table-import-conversation-form")?.innerHTML ||
                "content not loaded"
            );
            setConversationFormPreviewImport(undefined);
          }, 500);
        }
      },
      [conversation, conversationForm]
    );

    const saveDraft = useCallback(() => {
      return _patchDraft(draft)
        .then((saved) => {
          _setDraft({ ...draft, updated_at: saved.updated_at });
        })
        .catch((error) => {
          AlertError(t, { title: t("ERROR"), content: error.toString() });
        });
    }, [draft, t, _patchDraft]);

    const sendMessage = useCallback(() => {
      return new Promise((resolve) => {
        setSending(true);
        saveDraft()
          .then((_) => {
            sendDraft(draft.id)
              .then(() => {
                setSent(true);
                setSending(false);
              })
              .catch((e) => {
                AlertError(t, { content: e });
                setSending(false);
              });
          })
          .finally(() => resolve(true));
      });
    }, [draft, t, _patchDraft, saveDraft, textMessage]);

    useEffect(() => {
      if (!save || sent) return;

      const timer = setTimeout(() => {
        saveDraft();
        setSave(null);
      }, 2000);

      return () => clearTimeout(timer);
    }, [save, sent, setSave, saveDraft]);

    const setDraft = useCallback(
      (partial_draft: Partial<ISharedDraft>) => {
        const _draft = { ...draft, ...partial_draft };

        _setDraft(_draft);
        setSave(Math.random());
      },
      [draft]
    );

    if (sending || !conversation?.messages)
      return (
        <div style={{ marginBottom: 20, opacity: 0.5 }}>
          <Skeleton avatar active paragraph={{ rows: 3 }} />
        </div>
      );

    return (
      <>
        {formSubmissionPreviewImport}
        {conversationFormPreviewImport}
        <div className={"reply-wrapper"}>
          {ai_request && (
            <div className="ai-request">
              <span className="confidence">{t("AI_RESPONSE_CONFIDENCE")}</span>
              <span className="score">{Math.round((ai_request.confidence / 10) * 100)}%</span>
            </div>
          )}
          {draft.channel === DraftChannelEnum.Mail && (
            <div className={"subject-container"}>
              <span className={"text"}>{t("SUBJECT")}</span>
              <input
                className="subject-input"
                onChange={(e) => setDraft({ subject: e.target.value })}
                defaultValue={draft.subject || ""}
              />
            </div>
          )}
          <div className="reply-contents">
            <div className="reply-header">
              <div className="h-left">
                {draft.channel === DraftChannelEnum.Mail && (
                  <>
                    {
                      <div className="section">
                        <label>{t("FROM")}</label>
                        <FormSelect
                          onChange={(v) => setDraft({ account_id: v || null })}
                          value={draft.account_id || undefined}
                          className="bold"
                          options={filterRelated(
                            draft.organization_id,
                            "mail_account",
                            "conversation",
                            mail_accounts.mail_accounts,
                            true,
                            (mail_account: IPreviewMailAccount) =>
                              (queue?.__outbound_mail_accounts || []).includes(mail_account.id)
                          )}
                        />
                      </div>
                    }
                    <div className="section">
                      <label>{t("TO")}</label>
                      <Select
                        showSearch
                        onChange={(v) => setDraft({ reply_to_address: v })}
                        value={draft.reply_to_address}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            const email = (e.target as HTMLInputElement)?.value;

                            if (isEmailValid(email)) {
                              setAddedEmailOption(email);
                              setDraft({ reply_to_address: email });
                            }
                          }
                        }}
                      >
                        {emailOptions.map((email) => (
                          <Option key={email} value={email}>
                            {email}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="section">
                      <label>{t("CC")}</label>
                      <Select
                        dropdownMatchSelectWidth={false}
                        onChange={(v) =>
                          setDraft({ reply_to_cc: (v || []).filter((m) => isEmailValid(m)) })
                        }
                        value={draft.reply_to_cc}
                        mode="tags"
                      >
                        {ccOptions.map((email) => (
                          <Option key={email} value={email}>
                            {email}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="section">
                      <label>{t("BCC")}</label>
                      <Select
                        dropdownMatchSelectWidth={false}
                        onChange={(v) =>
                          setDraft({ reply_to_bcc: (v || []).filter((m) => isEmailValid(m)) })
                        }
                        value={draft.reply_to_bcc}
                        mode="tags"
                      >
                        {bccOptions.map((email) => (
                          <Option key={email} value={email}>
                            {email}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </>
                )}
                {draft.channel === DraftChannelEnum.Sms && (
                  <>
                    <div className="section">
                      <label>{t("SOURCE")}</label>
                      <Select
                        onChange={(v: any) => {
                          updateTextMessage("sourceId", v.value);
                        }}
                        value={
                          textMessage?.source?.id && textMessageSources.length > 0
                            ? {
                                value: textMessage.source.id,
                                label:
                                  textMessageSources.find(
                                    (source) =>
                                      String(source.value) === String(textMessage.source?.id)
                                  )?.label || `Source ${textMessage.source?.id}`,
                              }
                            : undefined
                        }
                        className="bold"
                        options={textMessageSources}
                        labelInValue
                        placeholder={t("SELECT_SOURCE")}
                      />
                    </div>
                    <div className="section">
                      <label>{t("TO")}</label>
                      <Select
                        value={
                          textMessage?.number ?? conversation.contact?.phones?.[0] ?? undefined
                        }
                        onChange={(v) => updateTextMessage("number", v)}
                      >
                        {conversation.contact?.phones?.map((phone) => (
                          <Option key={phone} value={phone}>
                            {phone}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </>
                )}
              </div>
              <div className="h-right">
                <Icon
                  onClick={() =>
                    Confirm(t, {
                      onOk: (modal: TypeModal) => onDelete?.().then((deleted) => modal.destroy()),
                      title: t("PERMANENTLY_DELETE_DRAFT"),
                    })
                  }
                  icon={TrashIcon}
                  iconProps={{ size: 17 }}
                  tooltip={{ title: t("DELETE_DRAFT") }}
                />
              </div>
            </div>
            {canned_answers.loaded && draft.channel !== DraftChannelEnum.Sms && (
              <FormHtmlEditor
                onChange={(value) => {
                  setDraft({ body: value });
                }}
                setDisplayEditReply={setDisplayEditReply}
                value={draft.body}
                hashOptions={["contact", "conversation", "agent"]}
                atOptions={["user"]}
                canned_answers={
                  draft.channel === DraftChannelEnum.InternalNote
                    ? []
                    : canned_answers.canned_answers.filter(
                        (canned_answer: IPreviewCannedAnswer) =>
                          !canned_answer.deleted_at &&
                          canned_answer.type == CannedAnswerTypeEnum.Agent &&
                          canned_answer.channel === (draft.channel as any) &&
                          (canned_answer.global ||
                            (queue?.__canned_answers || []).includes(canned_answer.id))
                      )
                }
                file_upload={{
                  attachments: draft.attachments || [],
                  action: RestRequest.getFullSrc(`/draft/${draft.id}/attachment`),
                  headers: RestRequest.getHeaders(),
                  onDelete: (uid: number) => deleteDraftAttachment(draft.id, uid),
                  onDownload: (uid: number, name: string) =>
                    downloadDraftAttachment(draft.id, uid, name),
                  onDownloadUri: (index: number) => `draft/${draft.id}/attachment/${index}`,
                  draft_id: draft.id,
                }}
                tableImport={
                  [
                    conversation.messages?.at(-1)?.channel === ChannelEnum.Form && {
                      key: "form_submission",
                      title: t("IMPORT_FORM_SUBMISSION"),
                      callback: importFormSubmission,
                    },
                    conversationForm?.id && {
                      key: "conversation_form",
                      title: t("IMPORT_CONVERSATION_FORM"),
                      callback: importConversationForm,
                    },
                  ].filter(Boolean) as IFormElementHtml["tableImport"]
                }
              />
            )}
            {draft.channel === DraftChannelEnum.Sms && (
              <>
                <div className="section">
                  <EditorFroala
                    disableRichText={true}
                    maxLength={MAX_TEXT_MESSAGE_LENGTH}
                    placeholder={t("MESSAGE")}
                    onChangeTrigger={handleTextMessageChange}
                    canned_answers={canned_answers.canned_answers}
                    editorToggle={null}
                  />
                </div>
                <div className="reply-footer">
                  {textMessage?.text && textMessage?.text?.length > MAX_TEXT_MESSAGE_LENGTH && (
                    <div style={{ color: "red", marginRight: "auto" }}>
                      Max {MAX_TEXT_MESSAGE_LENGTH}
                    </div>
                  )}

                  <Button
                    type="primary"
                    onClick={() => {
                      sendTextMessage();

                      // Sorry.. await did not work here
                      setTimeout(() => {
                        fetchTextMessages?.();
                      }, 1000);
                    }}
                    disabled={
                      // Disabled if text is too long or too short
                      Boolean(textMessage?.text?.length) &&
                      ((textMessage?.text?.length ?? 0) < MIN_TEXT_MESSAGE_LENGTH ||
                        (textMessage?.text?.length ?? 0) > MAX_TEXT_MESSAGE_LENGTH)
                    }
                  >
                    {t("SEND")}
                  </Button>
                </div>
              </>
            )}
            {draft.channel !== DraftChannelEnum.Sms && (
              <div className="reply-footer">
                {displayEditReply && draft.reply_body && (
                  <Button onClick={() => setEditReply(!editReply)} className="draft">
                    {t(editReply ? "HIDE_REPLY" : "EDIT_REPLY")}
                  </Button>
                )}
                {displayEditReply &&
                  !draft.reply_body &&
                  (draft.reply_to || "").startsWith(`${ChannelEnum.Form}-`) && (
                    <Button
                      onClick={() => {
                        setEditReply(!editReply);
                        setDraft({
                          reply_body:
                            '<div id="xm-mail-content"><blockquote type="cite"><p></p></blockquote></div>',
                        });
                      }}
                      className="draft"
                    >
                      {t("CREATE_REPLY")}
                    </Button>
                  )}
                {draft.channel !== DraftChannelEnum.InternalNote && (
                  <div className="signature">
                    <label>{t("SIGNATURE")}</label>
                    <FormSelect
                      onChange={(v) => setDraft({ signature_id: v || null })}
                      value={draft.signature_id || undefined}
                      className="bold"
                      options={filterRelated(
                        draft.organization_id,
                        "signature",
                        "conversation",
                        signatures.signatures,
                        true,
                        (signature: IPreviewSignature) =>
                          signature.type == SignatureTypeEnum.Agent &&
                          signature.channel === (draft.channel as any) &&
                          (signature.global ||
                            (queue?.__agent_signatures || []).includes(signature.id))
                      )}
                    />
                  </div>
                )}
                <div className="update-since">
                  <label>{t("LAST_SAVE")}</label>
                  <ConversationMessageReplyTimer t={t} since={draft.updated_at} />
                </div>
                <Button onClick={() => saveDraft()} className="draft">
                  {t("SAVE_DRAFT")}
                </Button>
                <Button type="primary" onClick={sendMessage} disabled={!draft.body}>
                  {t(
                    draft.channel === DraftChannelEnum.InternalNote
                      ? "CREATE_INTERNAL_NOTE"
                      : "SEND"
                  )}
                </Button>
              </div>
            )}
            {signature?.body && (
              <div className="preview-signature">
                <label title={t("SIGNATURE_PREVIEW")}>{t("SIGNATURE_PREVIEW")}</label>
                <div
                  className="preview-signature-body"
                  dangerouslySetInnerHTML={{ __html: signature.body }}
                ></div>
              </div>
            )}
            {displayEditReply && draft.reply_body && editReply && (
              <div className="reply-history">
                <FormHtmlEditor
                  onChange={(value) => {
                    setDraft({ reply_body: value });
                  }}
                  value={draft.reply_body}
                />
                <div className="reply-footer">
                  <div className="update-since">
                    <label>{t("LAST_SAVE")}</label>
                    <ConversationMessageReplyTimer t={t} since={draft.updated_at} />
                  </div>
                  <Button onClick={() => saveDraft()} className="draft">
                    {t("SAVE_REPLY")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);
