"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueHandlerMicroservicePatterns = void 0;
var QueueHandlerMicroservicePatterns;
(function (QueueHandlerMicroservicePatterns) {
    QueueHandlerMicroservicePatterns["QueueAssigned"] = "QueueAssigned";
    QueueHandlerMicroservicePatterns["QueueDistribute"] = "QueueDistribute";
    QueueHandlerMicroservicePatterns["VoiceAgentCalled"] = "VoiceAgentCalled";
    QueueHandlerMicroservicePatterns["VoiceAgentAccepted"] = "VoiceAgentAccepted";
    QueueHandlerMicroservicePatterns["AgentAssignAccepted"] = "AgentAssignAccepted";
    QueueHandlerMicroservicePatterns["AgentAssignRejected"] = "AgentAssignRejected";
    QueueHandlerMicroservicePatterns["NotificationTimedOut"] = "NotificationTimedOut";
    QueueHandlerMicroservicePatterns["VoiceCallHold"] = "VoiceCallHold";
    QueueHandlerMicroservicePatterns["VoiceCallUnhold"] = "VoiceCallUnhold";
    QueueHandlerMicroservicePatterns["AgentACW"] = "AgentACW";
    QueueHandlerMicroservicePatterns["AriVoiceAgentCalled"] = "AriVoiceAgentCalled";
    QueueHandlerMicroservicePatterns["AriVoiceInternalAgentCalled"] = "AriVoiceInternalAgentCalled";
})(QueueHandlerMicroservicePatterns = exports.QueueHandlerMicroservicePatterns || (exports.QueueHandlerMicroservicePatterns = {}));
