import { IconText } from "@Components";
import {
  CornerUpLeft,
  CornerUpLeftDouble,
  CornerUpRight,
  EditInBoxIcon,
  PhoneOutgoingIcon,
} from "@Assets";
import "./conversation.message.reply.scss";
import React, { useEffect, useMemo, useState } from "react";
import {
  ChannelEnum,
  ISharedFullConversation,
  ISharedMailMessage,
  DraftChannelEnum,
  TConversationVoiceCall,
  canEmail,
  IPreviewQueue,
  Nullable,
  hasVoiceLicense,
  DirectionEnum,
  TypeConversationMessage,
} from "atlas-shared";
import { TFunction } from "i18next";
import { conversationMessageReply } from "@Utils";
import { actionAiResponding, actionSetGlobalCall, IAuth, useAppDispatch } from "@Store";
import { useQueues } from "@Hooks";

interface IProps {
  t: TFunction;
  auth: IAuth;
  message: TypeConversationMessage;
  conversation: ISharedFullConversation;
  mini?: boolean;
}

export const ConversationMessageReply = React.memo(
  ({ message, conversation, t, auth, mini }: IProps) => {
    const dispatch = useAppDispatch();
    const queues = useQueues();
    const [queue, setQueue] = useState<Nullable<IPreviewQueue>>(null);
    const [canReplyEmail, setCanReplyEmail] = useState<boolean>(false);
    const [canCallBack, setCanCallBack] = useState<boolean>(false);

    const isMailMessage = (message: TypeConversationMessage): message is ISharedMailMessage => {
      return message.channel === ChannelEnum.Mail;
    };

    const isVoiceCall = (message: TypeConversationMessage): message is TConversationVoiceCall => {
      return message.channel === ChannelEnum.Voice;
    };

    useEffect(() => {
      setQueue(
        queues.loaded && conversation.queue_id ? queues.dict[conversation.queue_id] || null : null
      );
    }, [conversation.queue_id, queues]);

    useEffect(() => {
      setCanReplyEmail(
        canEmail(auth.user) &&
          [ChannelEnum.Mail, ChannelEnum.Form].includes(message.channel) &&
          conversation.contact?.emails?.length > 0 &&
          (queue?.__outbound_mail_accounts || []).length > 0
      );
    }, [message, auth.user, queue, conversation.contact]);

    useEffect(() => {
      setCanCallBack(
        hasVoiceLicense(auth.user) &&
          [ChannelEnum.Voice, ChannelEnum.Form].includes(message.channel) &&
          conversation.contact?.phones?.length > 0 &&
          auth.user.main &&
          (queue?.__outbound_voice_routes || []).length > 0
      );
    }, [message, auth.user, queue]);

    const actions = useMemo(() => {
      return [
        canReplyEmail && queue?.ai_assistant_id && (
          <li
            key="reply"
            onClick={(_) => {
              dispatch(actionAiResponding(true));
              conversationMessageReply(
                t,
                auth.user,
                DraftChannelEnum.Mail,
                conversation,
                queue,
                message,
                false,
                false,
                true
              ).finally(() => {
                dispatch(actionAiResponding(false));
              });
            }}
          >
            <IconText icon={CornerUpLeft} iconProps={{ size: 14 }} text={t("REPLY_WITH_AI")} />
          </li>
        ),
        canReplyEmail && (
          <li
            key="reply"
            onClick={(_) =>
              conversationMessageReply(
                t,
                auth.user,
                DraftChannelEnum.Mail,
                conversation,
                queue,
                message
              )
            }
          >
            <IconText icon={CornerUpLeft} iconProps={{ size: 14 }} text={t("REPLY")} />
          </li>
        ),
        canReplyEmail &&
          isMailMessage(message) &&
          !!(message?.channel === ChannelEnum.Mail ? message.cc : []).length && (
            <li
              key="reply-all"
              onClick={(_) =>
                conversationMessageReply(
                  t,
                  auth.user,
                  DraftChannelEnum.Mail,
                  conversation,
                  queue,
                  message,
                  true
                )
              }
            >
              <IconText icon={CornerUpLeftDouble} iconProps={{ size: 14 }} text={t("ALL")} />
            </li>
          ),
        canReplyEmail && isMailMessage(message) && (
          <li
            key="forward"
            onClick={(_) =>
              conversationMessageReply(
                t,
                auth.user,
                DraftChannelEnum.Mail,
                conversation,
                queue,
                message,
                false,
                true
              )
            }
          >
            <IconText icon={CornerUpRight} iconProps={{ size: 14 }} text={t("FORWARD")} />
          </li>
        ),
        canCallBack && isVoiceCall(message) && (message.contact_phone || "").startsWith("+") && (
          <li
            key="call-back"
            onClick={(_) =>
              dispatch(
                actionSetGlobalCall({
                  direction: DirectionEnum.Outbound,
                  numbers: [(message as unknown as TConversationVoiceCall).contact_phone],
                  requires_route: true,
                  confirm: true,
                  voice_asterisk_id: -1,
                  conversation_id: (message as unknown as TConversationVoiceCall).conversation_id,
                })
              )
            }
          >
            <IconText icon={PhoneOutgoingIcon} iconProps={{ size: 14 }} text={t("CALL_BACK")} />
          </li>
        ),
        <li
          onClick={(_) =>
            conversationMessageReply(
              t,
              auth.user,
              DraftChannelEnum.InternalNote,
              conversation,
              queue,
              message
            )
          }
          key="note"
        >
          <IconText icon={EditInBoxIcon} iconProps={{ size: 14 }} text={t("NOTE")} />
        </li>,
      ].filter(Boolean);
    }, [queue, canReplyEmail, canCallBack]);

    return actions.length ? (
      <div className={`conversation-message-reply${mini ? " mini" : ""}`}>
        <ul className={"actions"}>{actions}</ul>
      </div>
    ) : (
      <></>
    );
  }
);
