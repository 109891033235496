import { JourneyActionEnum, Metrics, GroupMetrics } from "atlas-shared";

export const en = {
  // [`NODE_DESCRIPTION_${JourneyActionEnum.When}`]: JourneyActionEnum.When + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.Timeout}`]: JourneyActionEnum.Timeout + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.StartMailInbound}`]: JourneyActionEnum.StartMailInbound + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.StartMailOutbound}`]: JourneyActionEnum.StartMailOutbound + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.StartForm}`]: JourneyActionEnum.StartForm + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.SendMail}`]: JourneyActionEnum.SendMail + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.Rule}`]: JourneyActionEnum.Rule + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.RestRequest}`]: JourneyActionEnum.RestRequest + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.OperationalHours}`]: JourneyActionEnum.OperationalHours + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.NoOp}`]: JourneyActionEnum.NoOp + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.Queue}`]: JourneyActionEnum.Queue + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.Journey}`]: JourneyActionEnum.Journey + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.MailAutoResponse}`]: JourneyActionEnum.MailAutoResponse + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.EditConversation}`]: JourneyActionEnum.EditConversation + ' default description',
  // [`NODE_DESCRIPTION_${JourneyActionEnum.CloseConversation}`]: JourneyActionEnum.CloseConversation + ' default description',
  ...Object.fromEntries(
    [...Metrics, ...GroupMetrics]
      .flatMap((metric) => [
        metric.title ? [`METRIC_${metric.alias}`, metric.title] : false,
        metric.description ? [`METRIC_${metric.alias}_DESCRIPTION`, metric.description] : false,
      ])
      .filter(Boolean) as Array<[string, string]>
  ),
  COUNTRY_AF: "Afghanistan",
  COUNTRY_AX: "Åland Islands",
  COUNTRY_AL: "Albania",
  COUNTRY_DZ: "Algeria",
  COUNTRY_AS: "American Samoa",
  COUNTRY_AD: "Andorra",
  COUNTRY_AO: "Angola",
  COUNTRY_AI: "Anguilla",
  COUNTRY_AQ: "Antarctica",
  COUNTRY_AG: "Antigua and Barbuda",
  COUNTRY_AR: "Argentina",
  COUNTRY_AM: "Armenia",
  COUNTRY_AW: "Aruba",
  COUNTRY_AU: "Australia",
  COUNTRY_AT: "Austria",
  COUNTRY_AZ: "Azerbaijan",
  COUNTRY_BS: "Bahamas",
  COUNTRY_BH: "Bahrain",
  COUNTRY_BD: "Bangladesh",
  COUNTRY_BB: "Barbados",
  COUNTRY_BY: "Belarus",
  COUNTRY_BE: "Belgium",
  COUNTRY_BZ: "Belize",
  COUNTRY_BJ: "Benin",
  COUNTRY_BM: "Bermuda",
  COUNTRY_BT: "Bhutan",
  COUNTRY_BO: "Bolivia",
  COUNTRY_BA: "Bosnia and Herzegovina",
  COUNTRY_BW: "Botswana",
  COUNTRY_BV: "Bouvet Island",
  COUNTRY_BR: "Brazil",
  COUNTRY_IO: "British Indian Ocean Territory",
  COUNTRY_BN: "Brunei Darussalam",
  COUNTRY_BG: "Bulgaria",
  COUNTRY_BF: "Burkina Faso",
  COUNTRY_BI: "Burundi",
  COUNTRY_KH: "Cambodia",
  COUNTRY_CM: "Cameroon",
  COUNTRY_CA: "Canada",
  COUNTRY_CV: "Cape Verde",
  COUNTRY_KY: "Cayman Islands",
  COUNTRY_CF: "Central African Republic",
  COUNTRY_TD: "Chad",
  COUNTRY_CL: "Chile",
  COUNTRY_CN: "China",
  COUNTRY_CX: "Christmas Island",
  COUNTRY_CC: "Cocos (Keeling) Islands",
  COUNTRY_CO: "Colombia",
  COUNTRY_KM: "Comoros",
  COUNTRY_CG: "Congo",
  COUNTRY_CD: "Congo, The Democratic Republic of the",
  COUNTRY_CK: "Cook Islands",
  COUNTRY_CR: "Costa Rica",
  COUNTRY_CI: "Cote D'Ivoire",
  COUNTRY_HR: "Croatia",
  COUNTRY_CU: "Cuba",
  COUNTRY_CY: "Cyprus",
  COUNTRY_CZ: "Czech Republic",
  COUNTRY_DG: "Diego Garcia",
  COUNTRY_DK: "Denmark",
  COUNTRY_DJ: "Djibouti",
  COUNTRY_DM: "Dominica",
  COUNTRY_DO: "Dominican Republic",
  COUNTRY_EC: "Ecuador",
  COUNTRY_EG: "Egypt",
  COUNTRY_SV: "El Salvador",
  COUNTRY_GQ: "Equatorial Guinea",
  COUNTRY_ER: "Eritrea",
  COUNTRY_EE: "Estonia",
  COUNTRY_ET: "Ethiopia",
  COUNTRY_FK: "Falkland Islands (Malvinas)",
  COUNTRY_FO: "Faroe Islands",
  COUNTRY_FJ: "Fiji",
  COUNTRY_FI: "Finland",
  COUNTRY_FR: "France",
  COUNTRY_GF: "French Guiana",
  COUNTRY_PF: "French Polynesia",
  COUNTRY_TF: "French Southern Territories",
  COUNTRY_GA: "Gabon",
  COUNTRY_GM: "Gambia",
  COUNTRY_GE: "Georgia",
  COUNTRY_DE: "Germany",
  COUNTRY_GH: "Ghana",
  COUNTRY_GI: "Gibraltar",
  COUNTRY_GR: "Greece",
  COUNTRY_GL: "Greenland",
  COUNTRY_GD: "Grenada",
  COUNTRY_GP: "Guadeloupe",
  COUNTRY_GU: "Guam",
  COUNTRY_GT: "Guatemala",
  COUNTRY_GG: "Guernsey",
  COUNTRY_GN: "Guinea",
  COUNTRY_GW: "Guinea-Bissau",
  COUNTRY_GY: "Guyana",
  COUNTRY_HT: "Haiti",
  COUNTRY_HM: "Heard Island and Mcdonald Islands",
  COUNTRY_VA: "Holy See (Vatican City State)",
  COUNTRY_HN: "Honduras",
  COUNTRY_HK: "Hong Kong",
  COUNTRY_HU: "Hungary",
  COUNTRY_IS: "Iceland",
  COUNTRY_IN: "India",
  COUNTRY_ID: "Indonesia",
  COUNTRY_IR: "Iran, Islamic Republic Of",
  COUNTRY_IQ: "Iraq",
  COUNTRY_IE: "Ireland",
  COUNTRY_IM: "Isle of Man",
  COUNTRY_IL: "Israel",
  COUNTRY_IT: "Italy",
  COUNTRY_JM: "Jamaica",
  COUNTRY_JP: "Japan",
  COUNTRY_JE: "Jersey",
  COUNTRY_JO: "Jordan",
  COUNTRY_KZ: "Kazakhstan",
  COUNTRY_KE: "Kenya",
  COUNTRY_KI: "Kiribati",
  COUNTRY_KP: "Korea, Democratic People'S Republic of",
  COUNTRY_KR: "Korea, Republic of",
  COUNTRY_XK: "Kosovo",
  COUNTRY_KW: "Kuwait",
  COUNTRY_KG: "Kyrgyzstan",
  COUNTRY_LA: "Lao People'S Democratic Republic",
  COUNTRY_LV: "Latvia",
  COUNTRY_LB: "Lebanon",
  COUNTRY_LS: "Lesotho",
  COUNTRY_LR: "Liberia",
  COUNTRY_LY: "Libyan Arab Jamahiriya",
  COUNTRY_LI: "Liechtenstein",
  COUNTRY_LT: "Lithuania",
  COUNTRY_LU: "Luxembourg",
  COUNTRY_LX: "Luxembourg (Special)",
  COUNTRY_MO: "Macao",
  COUNTRY_MK: "Macedonia, The Former Yugoslav Republic of",
  COUNTRY_MG: "Madagascar",
  COUNTRY_MW: "Malawi",
  COUNTRY_MY: "Malaysia",
  COUNTRY_MV: "Maldives",
  COUNTRY_ML: "Mali",
  COUNTRY_MT: "Malta",
  COUNTRY_MH: "Marshall Islands",
  COUNTRY_MQ: "Martinique",
  COUNTRY_MR: "Mauritania",
  COUNTRY_MU: "Mauritius",
  COUNTRY_YT: "Mayotte",
  COUNTRY_MX: "Mexico",
  COUNTRY_FM: "Micronesia, Federated States of",
  COUNTRY_MD: "Moldova, Republic of",
  COUNTRY_ME: "Montenegro",
  COUNTRY_MF: "Saint Martin",
  COUNTRY_MC: "Monaco",
  COUNTRY_MN: "Mongolia",
  COUNTRY_MS: "Montserrat",
  COUNTRY_MA: "Morocco",
  COUNTRY_MZ: "Mozambique",
  COUNTRY_MM: "Myanmar",
  COUNTRY_NA: "Namibia",
  COUNTRY_NR: "Nauru",
  COUNTRY_NP: "Nepal",
  COUNTRY_NL: "Netherlands",
  COUNTRY_AN: "Netherlands Antilles",
  COUNTRY_NC: "New Caledonia",
  COUNTRY_NZ: "New Zealand",
  COUNTRY_NI: "Nicaragua",
  COUNTRY_NE: "Niger",
  COUNTRY_NG: "Nigeria",
  COUNTRY_NU: "Niue",
  COUNTRY_NF: "Norfolk Island",
  COUNTRY_MP: "Northern Mariana Islands",
  COUNTRY_NO: "Norway",
  COUNTRY_OM: "Oman",
  COUNTRY_PK: "Pakistan",
  COUNTRY_PW: "Palau",
  COUNTRY_PS: "Palestinian Territory, Occupied",
  COUNTRY_PA: "Panama",
  COUNTRY_PG: "Papua New Guinea",
  COUNTRY_PY: "Paraguay",
  COUNTRY_PE: "Peru",
  COUNTRY_PH: "Philippines",
  COUNTRY_PN: "Pitcairn",
  COUNTRY_PL: "Poland",
  COUNTRY_PT: "Portugal",
  COUNTRY_PR: "Puerto Rico",
  COUNTRY_QA: "Qatar",
  COUNTRY_RE: "Reunion",
  COUNTRY_RO: "Romania",
  COUNTRY_RS: "Serbia",
  COUNTRY_RU: "Russian Federation",
  COUNTRY_RW: "Rwanda",
  COUNTRY_SH: "Saint Helena",
  COUNTRY_KN: "Saint Kitts and Nevis",
  COUNTRY_LC: "Saint Lucia",
  COUNTRY_PM: "Saint Pierre and Miquelon",
  COUNTRY_VC: "Saint Vincent and the Grenadines",
  COUNTRY_WS: "Samoa",
  COUNTRY_SM: "San Marino",
  COUNTRY_ST: "Sao Tome and Principe",
  COUNTRY_SA: "Saudi Arabia",
  COUNTRY_SN: "Senegal",
  COUNTRY_CS: "Serbia and Montenegro",
  COUNTRY_SC: "Seychelles",
  COUNTRY_SL: "Sierra Leone",
  COUNTRY_SG: "Singapore",
  COUNTRY_SK: "Slovakia",
  COUNTRY_SI: "Slovenia",
  COUNTRY_SB: "Solomon Islands",
  COUNTRY_SO: "Somalia",
  COUNTRY_SS: "South Sudan",
  COUNTRY_SX: "Sint Maarten",
  COUNTRY_ZA: "South Africa",
  COUNTRY_GS: "South Georgia and the South Sandwich Islands",
  COUNTRY_ES: "Spain",
  COUNTRY_LK: "Sri Lanka",
  COUNTRY_SD: "Sudan",
  COUNTRY_SR: "Suriname",
  COUNTRY_SJ: "Svalbard and Jan Mayen",
  COUNTRY_SZ: "Swaziland",
  COUNTRY_SE: "Sweden",
  COUNTRY_CH: "Switzerland",
  COUNTRY_SY: "Syrian Arab Republic",
  COUNTRY_TA: "Tristan da Cunha",
  COUNTRY_TW: "Taiwan, Province of China",
  COUNTRY_TJ: "Tajikistan",
  COUNTRY_TZ: "Tanzania, United Republic of",
  COUNTRY_TH: "Thailand",
  COUNTRY_TL: "Timor-Leste",
  COUNTRY_TG: "Togo",
  COUNTRY_TK: "Tokelau",
  COUNTRY_TO: "Tonga",
  COUNTRY_TP: "East Timor",
  COUNTRY_TT: "Trinidad and Tobago",
  COUNTRY_TN: "Tunisia",
  COUNTRY_TR: "Turkey",
  COUNTRY_TM: "Turkmenistan",
  COUNTRY_TC: "Turks and Caicos Islands",
  COUNTRY_TV: "Tuvalu",
  COUNTRY_UG: "Uganda",
  COUNTRY_UA: "Ukraine",
  COUNTRY_AE: "United Arab Emirates",
  COUNTRY_GB: "United Kingdom",
  COUNTRY_US: "United States",
  COUNTRY_UM: "United States Minor Outlying Islands",
  COUNTRY_UY: "Uruguay",
  COUNTRY_UZ: "Uzbekistan",
  COUNTRY_VU: "Vanuatu",
  COUNTRY_VE: "Venezuela",
  COUNTRY_VN: "Viet Nam",
  COUNTRY_VG: "Virgin Islands, British",
  COUNTRY_VI: "Virgin Islands, U.S.",
  COUNTRY_WF: "Wallis and Futuna",
  COUNTRY_EH: "Western Sahara",
  COUNTRY_YE: "Yemen",
  COUNTRY_ZM: "Zambia",
  COUNTRY_ZW: "Zimbabwe",
  COUNTRY_AC: "Ascension Island",
  COUNTRY_BL: "Saint Martin country",
  COUNTRY_BQ: "Netherlands Antilles",
  COUNTRY_CW: "Netherlands Antilles",

  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceInbound}`]: "Inbound voice conversation created",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceOutbound}`]:
    "Outbound voice call hanged up by agent",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceAnswer}`]: "Answer voice call",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceExternalDial}`]: "Call external number",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceGetDigits}`]: "Get digits from caller",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceHangup}`]: "Hang up voice call",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceInternalDial}`]: "Internal call to agents or queues",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceMenu}`]: "Voice options menu",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoicePlayback}`]: "Play audio for contact",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayAlpha}`]: "Read text for contact",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayDigits}`]: "Read digits for contact (one by one)",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVariableSet}`]: "Set a voice variable",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayNumber}`]: "Read number for contact",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayPhonetic}`]: "Read letters for contact",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStartRecording}`]: "Start call recording",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStopRecording}`]: "Stop specific call recording",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailInbound}`]: "Inbound e-mail conversation created",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailOutbound}`]: "Outbound e-mail conversation created",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartForm}`]: "Conversation created by form submission",
  [`NODE_SUBTITLE_${JourneyActionEnum.OperationalHours}`]: "Within or not within operational hours",
  [`NODE_SUBTITLE_${JourneyActionEnum.MailAutoResponse}`]: "Auto reply e-mail",
  [`NODE_SUBTITLE_${JourneyActionEnum.Queue}`]: "Assign to queue",
  [`NODE_SUBTITLE_${JourneyActionEnum.Client}`]: "Add client to conversation",
  [`NODE_SUBTITLE_${JourneyActionEnum.UnAssign}`]: "Un-assign queue and/or user",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceQueue}`]: "Assign to queue with voice handlers",
  [`NODE_SUBTITLE_${JourneyActionEnum.Agent}`]: "Assign directly to agent",
  [`NODE_SUBTITLE_${JourneyActionEnum.Timeout}`]: JourneyActionEnum.Timeout + " default subtitle",
  [`NODE_SUBTITLE_${JourneyActionEnum.RestRequest}`]: "Make a rest request",
  [`NODE_SUBTITLE_${JourneyActionEnum.FetchContactData}`]: "Fetch contact data using rest",
  [`NODE_SUBTITLE_${JourneyActionEnum.EditConversation}`]: "Edit conversation properties",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseConversation}`]: "Dispose & close conversation",
  [`NODE_SUBTITLE_${JourneyActionEnum.AddInternalNote}`]: "Add internal note to conversation",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseTabs}`]: "Automatically close tabs from journey",
  [`NODE_SUBTITLE_${JourneyActionEnum.SendMail}`]: "Send e-mail",
  [`NODE_SUBTITLE_${JourneyActionEnum.ForwardMail}`]: "Forward e-mail",
  [`NODE_SUBTITLE_${JourneyActionEnum.ReplyLatestInboundMessage}`]:
    "Reply to latest inbound mail message or form submission",
  [`NODE_SUBTITLE_${JourneyActionEnum.Journey}`]: "Sub journey",
  [`NODE_SUBTITLE_${JourneyActionEnum.Rule}`]: "Define conditional rules",
  [`NODE_SUBTITLE_${JourneyActionEnum.RuleContact}`]: "Define contact conditional rules",
  [`NODE_SUBTITLE_${JourneyActionEnum.NoOp}`]: "No operation",
  [`NODE_SUBTITLE_${JourneyActionEnum.When}`]: "Triggers on",
  [`NODE_SUBTITLE_${JourneyActionEnum.SetField}`]: "Set conversation field value",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSetLanguage}`]: "Set voice call variable language",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVoicemail}`]: "Record voicemail",
  [`NODE_SUBTITLE_${JourneyActionEnum.OpenBrowserTab}`]: "Open browser tab",
  NODE_TITLE_DEFAULT: "Node default title",
  NODE_DESCRIPTION_DEFAULT: "Node default description",
  LOADING: "Loading...",
  CUSTOMER_JOURNEY: "Customer journey",
  CONVERSATION: "Conversation",
  CONVERSATIONS: "Conversations",
  CUSTOMER: "Customer",
  CUSTOMERS: "Customers",
  STATISTICS: "Statistics",
  REPLY_IN: "Reply in",
  REPLY: "Reply",
  AGO: "ago",
  SHOWING: "Showing",
  STATS: "Stats",
  CLOSE: "Close",
  CLOSED: "Closed",
  OPEN: "Open",
  DRAFT_REPLY_MESSAGE_CONFIRM: "Do you want save your change?",
  TODAY: "Today",
  YES: "Yes",
  NO: "No",
  CONFIRM: "Confirm",
  NEW_MESSAGE: "New message",
  TO: "To",
  DRAFT: "Draft",
  SEND_REPLY: "Send Reply",
  SAVE: "Save",
  TYPE: "Type",
  ALL: "All",
  TICKETS: "Tickets",
  CONTACTS: "Contacts",
  SOLUTIONS: "Solutions",
  FORUMS: "Forums",
  TEAM_NAME: "Team Name",
  ANOTHER_TEAM: "Another Team",
  THIRD_TEAM_NAME: "Third Team Name",
  OTHER_TEAM_NAME: "Other Team Name",
  FAVORITES: "Favorites",
  CHAT: "Chat",
  CALL: "Call",
  RETURN: "Return",
  SEND: "Send",
  RECENTLY_SEARCHED: "Recently Searched",
  RECENTLY_VIEWED: "Recently Viewed",
  CLEAR: "Clear",
  ASSIGN: "Assign",
  CHOOSE: "choose",
  AGENT: "Agent",
  ADMIN: "Admin",
  SUPERADMIN: "Superadmin",
  QUEUES: "Queues",
  QUEUE: "Queue",
  DONE: "Done",
  IN: "in",
  SEARCH: "Search",
  AND: "And",
  PREVIOUS_ACTIVITY: "Previous activity",
  VIEW: "View",
  BY: "by",
  SUPPORT: "Support",
  GENERAL: "General",
  WRITE: "Write",
  HERE: "here",
  ADD: "Add",
  NEW: "New",
  AGENTS: "Agents",
  EDIT: "Edit",
  TOOLS: "Tools",
  EMAIL: "Email",
  CANCEL: "Cancel",
  UNAUTHORIZED: "Username or password does not match",
  OR: "Or",
  DISPOSE: "Dispose",
  CHOOSE_REASON_FOR_DISPOSAL: "Choose reason for disposal",
  WRITE_A_COMMENT: "Write a comment",
  USERNAME: "Username",
  DROP_HERE: "Drop here",
  PASSWORD: "Password",
  FORGOT_YOUR_PASSWORD: "Forgot your password",
  RESET_PASSWORD: "Reset password",
  REMEMBER_ME: "Remember me",
  SIGN_IN: "Sign in",
  SIGN_OUT: "Sign out",
  DISPLAY_DELETED: "Display deleted",
  GLOBAL: "Global",
  WORK_TIME: "Work time",
  INVOICEABLE: "Invoiceable",
  TITLE: "Title",
  ORGANIZATION_NOT_FOUND: 'Organization with id "{{organization_id}}" was not found!',
  BETWEEN_FROM_AND_TO: "Between {{from}} and {{to}}",
  FROM_FROM_TO_TO: "From {{from}} to {{to}}",
  FROM_THROUGH_TO: "{{from}} through {{to}}",
  FROM_THROUGH_TO_OF_MONTH: "{{from}} through {{to}} of the month",
  ON_FROM: "On {{from}}",
  ON_FROM_OF_MONTH: "On {{from}} of the month",
  EVERY_SPECIFIC_DAY: "Every {{from}}",
  ALL_DAY: "All day",
  EVERY_DAY: "Every day",

  ROLE: "Role",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  AVATAR: "Avatar",

  ORGANIZATIONS: "Organizations",
  ORGANIZATION: "Organization",
  USER: "User",
  USERS: "Users",
  ADMINPROFILES: "Admin profiles",
  ADMINPROFILE: "Admin profile",
  SUPERADMIN_PROFILES: "Superadmin profiles",
  SUPERADMINPROFILES: "Superadmin profiles",
  SUPERADMINPROFILE: "Superadmin profile",
  SUPERADMIN_PROFILE: "Superadmin profile",
  AGENTPROFILES: "Agent profiles",
  AGENTPROFILE: "Agent profile",
  TEAMS: "Teams",
  MAIL_ACCOUNTS: "Mail Accounts",
  MAIL_ACCOUNT: "Mail Account",
  LISTS: "Lists",
  LIST: "List",
  JOURNEYS: "Journeys",
  JOURNEY: "Journey",
  PAUSES: "Pauses",
  PAUSE: "Pause",
  DISPOSITIONS: "Dispositions",
  DISPOSITION: "Disposition",
  FIELDS: "Fields",
  FIELD: "Field",

  FORM_ERROR_STRING__EMPTY: "May not be empty",
  FORM_ERROR_ARRAY__MIN: "Minimum {{min}} option(s) required",
  FORM_ERROR_ARRAY__MAX: "Maximum {{max}} option(s) allowed",
  FORM_ERROR_ARRAY__LENGTH: "{{lngth}} option(s) required",
  FORM_ERROR_ANY__REQUIRED: "Required",
  FORM_ERROR_NUMBER__BASE: "Valid number is required",
  FORM_ERROR_STRING__BASE: "Valid text is required",
  FORM_ERROR_VALID_OPTION: "Select an option",
  FORM_ERROR_ANY__ONLY: "Select a valid option",
  FORM_ERROR_STRING__URI: "Invalid uri",
  FORM_ERROR_REF_NOT_FOUND: "A valid option has to be selected",
  FORM_ERROR_INVALID_PHONE: "Invalid phone number",
  FORM_ERROR_NUMBER__MIN: "Number has to be greater than or equal to {{min}}",
  FORM_ERROR_NUMBER__MAX: "Number has to be less than or equal to {{max}}",
  FORM_ERROR_STRING__MIN: "Has to be at least {{min}} characters long",
  FORM_ERROR_STRING__MAX: "Has to be at most {{max}} characters long",
  FORM_ERROR_STRING__LENGTH: "Has to ne exactly {{lngth}} characters long",
  FORM_ERROR_STRING__EMAIL: "Invalid e-mail",
  FORM_ERROR_STRING__ALPHANUM: "Alpha numeric values only!",
  FORM_ERROR_USERNAME_INVALID_CHARS:
    "May only contain lowercase, numbers an special characters . (dot) and - (dash)",
  FORM_ERROR_STRING__PATTERN__BASE: "String does not match pattern {{pattern}}",
  FORM_ERROR_DATE__BASE: "Select valid date",
  FORM_ERROR_STRING__IPVERSION: "Invalid IP address",

  GLOBAL_SEARCH_PLACEHOLDER: "Search conversations & contacts",
  ADD_ITEM: "Add item",
  MAIL_CAPACITY: "Mail capacity",
  __ORGANIZATIONS: "Organizations",
  __ORGANIZATIONS__ORGANIZATION_ID: "Organization",
  __ORGANIZATIONS__ROLE: "Role",
  __ORGANIZATIONS__ADMINPROFILE_ID: "Admin profile",
  __ORGANIZATIONS__SUPERADMIN_PROFILE_ID: "Superadmin profile",
  SUBMIT: "Submit",
  SIGN_IN_WITH_GOOGLE: "Sign in with Google",
  SIGN_IN_WITH_MICROSOFT: "Sign in with Microsoft",
  OPEN_AUTHENTICATOR_AND_SCAN: "Open your authenticator app and scan the qr-code",
  OPEN_AUTHENTICATOR: "Open your authenticator app and enter the code",
  ENTER_6_DIGIT_CODE: "Enter 6 digit code",
  FILL_USERNAME_TO_RESET_PASSWORD: "Fill in username to reset password",
  SOMETHING_WENT_WRING: "Something went wrong, please try again later!",

  ENTRY: "Entry",
  ASSIGNMENT: "Assignment",
  MAIL: "Mail",
  StartMailInbound: "Start mail (Inbound)",
  StartMailOutbound: "Start mail (Outbound)",
  StartVoiceInbound: "Start voice (Inbound)",
  StartVoiceOutbound: "Start voice (Outbound)",
  VoiceAnswer: "Answer",
  VoiceExternalDial: "External dial",
  VoiceGetDigits: "Get digits",
  VoiceQueue: "Voice queue",
  VoiceVariableSet: "Set variable",
  VoiceHangup: "Hang up",
  VoiceInternalDial: "Internal dial",
  VoiceMenu: "Voice menu",
  VoicePlayback: "Playback",
  VoiceSayAlpha: "Say alpha",
  VoiceSayNumber: "Say number",
  VoiceSayPhonetic: "Say phonetic",
  VoiceSayDigits: "Say digits",
  VoiceStartRecording: "Start recording",
  VoiceStopRecording: "Stop recording",
  VoiceVoicemail: "Voicemail",
  StartForm: "Start form",
  Queue: "Queue",
  EditConversation: "Edit conversation",
  CloseConversation: "Close conversation",
  AddInternalNote: "Add internal note",
  CloseTabs: "Close tabs",
  SendMail: "Send e-mail",
  ForwardMail: "Forward e-mail",
  ReplyLatestInboundMessage: "Reply latest inbound message",
  MailAutoResponse: "E-mail auto response",
  RestRequest: "Rest request",
  FetchContactData: "Fetch contact data",
  SetField: "Set field data",
  VoiceSetLanguage: "Set language",
  Rule: "Rule",
  RuleContact: "Rule (contact)",
  NoOp: "No operation",
  OperationalHours: "Operational hours",
  Journey: "Journey",
  MY_FILTERS: "My filters",
  CREATE_NEW_FILTER: "Create new filter",
  NEW_FILTER: "New filter",
  DEFAULT_FILTER: "Default filter",
  STATUSES: "Statuses",
  CHANNELS: "Channels",
  PRIORITIES: "Priorities",
  TAGS: "Tags",
  LAST_MESSAGE_AT: "Last message at",
  LAST_INBOUND_MESSAGE_AT: "Last inbound message at",
  LAST_OUTBOUND_MESSAGE_AT: "Last outbound message at",
  CREATED_AT: "Created",
  CONTACT: "Contact",
  __CONTACT_FIRST_NAME: "First name",
  __CONTACT_LAST_NAME: "Last name",
  __CONTACT_PHONES: "Phones",
  __CONTACT_EMAILS: "E-mails",
  SETTINGS: "Settings",
  PROFILES: "Profiles",
  NOTIFICATIONS: "Notifications",
  NewAssignment: "New assignment",
  NewResponseOnMyConversation: "New response on my conversations",
  ConversationMessagesImTaggedIn: "Conversation messages I'm tagged in",
  StatusUpdateOnMyConversation: "Status update on my conversation",
  ConversationAssignedToMe: "Conversation assigned to me",
  ConversationAssignedToMyQueues: "Conversation assigned to my queues",
  ReminderOnFirstResponse: "Reminder on first response",
  ReminderOnResolutionTime: "Reminder on resolution time",
  ReminderOnNextResponse: "Reminder on next response",
  DELETE_DRAFT: "Delete draft",
  PERMANENTLY_DELETE_DRAFT: "Permanently delete draft?",
  ADD_PORT: "Add port",
  ADD_RULE: "Add rule",
  ADD_PROPERTY: "Add property",

  NOT_EQUAL_PHONE_NUMBER: "Not equals phone number",
  EQUAL_PHONE_NUMBER: "Equals phone number",
  INCLUDES_PHONE_NUMBER: "Includes phone number",
  NOT_INCLUDES_PHONE_NUMBER: "Not includes phone number",
  ARRAY_LENGTH: "Array length",
  STRICT_EQ: "Equals",
  NOT_STRICT_EQ: "Not equals",
  IS_EMPTY: "Is empty",
  IS_NOT_EMPTY: "Is not empty",
  CONTAINS: "Contains",
  NOT_CONTAINS: "Not contains",
  CHANGED: "Changed",
  NOT_EQUAL_NUM: "Not equals (number)",
  NOT_EQUAL_STR: "Not equals (string)",
  EQUAL_NUM: "Equals (number)",
  EQUAL_STR: "Equals (string)",
  STARTS_WITH: "Starts with",
  NOT_STARTS_WITH: "Not starts with",
  ANY_OF_NUM: "Any of",
  NONE_OF_NUM: "None of",
  ANY_OF_STR: "Any of",
  NONE_OF_STR: "None of",
  ENDS_WITH: "Ends with",
  NOT_ENDS_WITH: "Not ends with",
  GT: "Greater than",
  GTE: "Greater than or equal",
  LT: "Less than",
  LTE: "Less than or equal",
  IS_NOT_NULL: "Is not null",
  IS_NULL: "Is null",
  IS_FALSY: "Is falsy",
  IS_FALSE: "Is false",
  IS_TRULY: "Is truly",
  IS_TRUE: "Is true",
  IS_UNDEFINED: "Is undefined",
  IS_DEFINED: "Is defined",
  INCLUDES: "Includes",
  INCLUDES_NUM: "Includes number",
  INCLUDES_STR: "Includes string",
  NOT_INCLUDES: "Not includes",
  NOT_INCLUDES_NUM: "Not includes number",
  NOT_INCLUDES_STR: "Not includes string",
  GT_DATETIME: "Greater than",
  LT_DATETIME: "Less than",
  MAIL_MESSAGE: "Mail message",
  "DRAFT.ID": "Draft id",
  "INTERNAL_NOTE.ID": "Internal note id",
  "CONVERSATION.ID": "Conversation id",
  "CONVERSATION.ORGANIZATION_ID": "Organization",
  "CONVERSATION.TITLE": "Conversation title",
  "CONVERSATION.QUEUE_ID": "Conversation queue",
  "CONVERSATION_QUEUE.TITLE": "Conversation queue title",
  "CONVERSATION.LAST_INBOUND_MESSAGE_AT": "Last inbound message at",
  "CONVERSATION.LAST_OUTBOUND_MESSAGE_AT": "Last outbound message at",
  "CONVERSATION.LAST_MESSAGE_AT": "Last message at",
  "CONVERSATION.LAST_MESSAGE_BY": "Last message by",
  "CONVERSATION.CLIENT_ID": "Conversation client",
  "CONVERSATION_CLIENT.TITLE": "Conversation client title",
  "CONVERSATION.CONTACT_ID": "Conversation contact id",
  "CONVERSATION.JOURNEY_ID": "Journey",
  "CONVERSATION.DISPOSITION_ID": "Conversation disposition",
  "CONVERSATION_DISPOSITION.TITLE": "Conversation disposition title",
  "CONVERSATION.SUB_DISPOSITION_ID": "Conversation sub disposition",
  "CONVERSATION_SUB_DISPOSITION.TITLE": "Conversation sub disposition title",
  "CONVERSATION.THRD_DISPOSITION_ID": "Conversation third disposition",
  "CONVERSATION_THRD_DISPOSITION.TITLE": "Conversation third disposition title",
  "CONVERSATION.STATUS": "Conversation status",
  "CONVERSATION.CREATED_AT": "Conversation created at",
  "CONVERSATION.PRIORITY": "Conversation priority",
  "CONVERSATION.USER_ID": "Conversation agent id",
  "USER.ID": "Agent",
  "CONVERSATION.STATUS_AT": "Conversation status changed at",
  "CONVERSATION.RESPONSE_TIME": "Conversation response time",
  "CONVERSATION.RESOLUTION_TIME": "Conversation resolution time",
  "CONVERSATION.MAIN_CHANNEL": "Conversation main channel",
  "CONVERSATION.DIRECTION": "Conversation start direction",
  "CONVERSATION.TAGS": "Conversation tags",
  "CONVERSATION.CHANNELS": "Conversation channels",
  "CONVERSATION.DATA": "Conversation custom field",
  "MAIL_MESSAGE.ID": "Mail message id",
  "MAIL_MESSAGE.TITLE": "Mail message title",
  "MAIL_MESSAGE.SUBJECT": "Mail message subject",
  "MAIL_MESSAGE.MAIL_ACCOUNT_ID": "Mail account",
  "MAIL_MESSAGE.CONTACT_EMAIL": "Mail message contact e-mail",
  "MAIL_MESSAGE.CREATED_AT": "Mail message created at",
  "CONTACT.ID": "Contact id",
  "CONTACT.EMAILS": "Contact e-mails",
  "CONTACT.PHONES": "Contact phones",
  "CONTACT.FIRST_NAME": "Contact first name",
  "CONTACT.LAST_NAME": "Contact last name",
  "CONTACT.DATA": "Contact custom field",
  FORM_SUBMISSION: "Form submission",
  "FORM_SUBMISSION.ID": "Form submission id",
  "FORM_SUBMISSION.FORM_WEBSITE_ID": "Form website",
  "FORM_SUBMISSION.CONTACT_EMAIL": "Form submission contact e-mail",
  "FORM_SUBMISSION.CONTACT_PHONE": "Form submission contact phone",
  FORM_WEBSITE: "Form website",
  "FORM_WEBSITE.ID": "Form website id",
  "MAIL_ACCOUNT.ID": "Mail account id",
  "DRAFT.CREATED_AT": "Draft created at",
  "INTERNAL_NOTE.CREATED_AT": "Internal note created at",
  "VOICE_CALL.ID": "Voice call id",
  "VOICE_CALL.VOICE_ASTERISK_ID": "Voice asterisk",
  "VOICE_CALL.VOICE_ROUTE_ID": "Voice route",
  "VOICE_CALL.QUEUE_ID": "Voice call queue",
  VOICE_CALL_BRIDGES_COUNT: "Number of voice call bridges",
  "VOICE_CALL.CONTACT_PHONE": "Voice call contact phone",
  "VOICE_CALL.CREATED_AT": "Voice call created at",
  "VOICE_CALL.DIRECTION": "Voice call direction",
  "VOICE_CALL.END_TIME": "Voice call end at",
  VOICE_CALL_FIRST_ANSWERED_AT: "Voice call first answered at",
  "VOICE_CALL.VARIABLES": "Voice variables",

  "VOICE_CALL_DURATION.TOTAL": "Voice call total duration (seconds)",
  "VOICE_CALL_DURATION.PRE_QUEUE_TIME": "Voice call pre queue time (seconds)",
  "VOICE_CALL_DURATION.WAIT_TIME": "Voice call wait time (seconds)",
  "VOICE_CALL_DURATION.TALK_TIME": "Voice call talk time (seconds)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Current voice call bridge total duration (seconds)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Current voice call bridge pre queue time (seconds)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Current voice call bridge wait time (seconds)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Current voice call bridge talk time (seconds)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "First voice call bridge total duration (seconds)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "First voice call bridge pre queue time (seconds)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "First voice call bridge wait time (seconds)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "First voice call bridge talk time (seconds)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Last voice call bridge total duration (seconds)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Last voice call bridge pre queue time (seconds)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Last voice call bridge wait time (seconds)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Last voice call bridge talk time (seconds)",
  "CURRENT_VOICE_CALL_BRIDGE.ID": "Current voice call bridge id",
  "CURRENT_VOICE_CALL_BRIDGE.DIRECTION": "Current voice call bridge direction",
  "CURRENT_VOICE_CALL_BRIDGE.CREATED_AT": "Current voice call bridge created at",
  "CURRENT_VOICE_CALL_BRIDGE.ANSWER_TIME": "Current voice call bridge answer at",
  "CURRENT_VOICE_CALL_BRIDGE.LEAVE_AT": "Current voice call bridge leave",
  "CURRENT_VOICE_CALL_BRIDGE.USER_ID": "Current voice call bridge agent",
  "CURRENT_VOICE_CALL_BRIDGE.QUEUE_ID": "Current voice call bridge queue",
  "CURRENT_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Current voice call bridge is external dial",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TWINNED": "Current voice call bridge is twinned",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFER": "Current voice call bridge is transfer",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Current voice call bridge is transferred",
  "CURRENT_VOICE_CALL_BRIDGE.EXIT_REASON": "Current voice call bridge exit reason",
  "FIRST_VOICE_CALL_BRIDGE.ID": "First voice call bridge id",
  "FIRST_VOICE_CALL_BRIDGE.DIRECTION": "First voice call bridge direction",
  "FIRST_VOICE_CALL_BRIDGE.CREATED_AT": "First voice call bridge created at",
  "FIRST_VOICE_CALL_BRIDGE.ANSWER_TIME": "First voice call bridge answer at",
  "FIRST_VOICE_CALL_BRIDGE.LEAVE_AT": "First voice call bridge leave",
  "FIRST_VOICE_CALL_BRIDGE.USER_ID": "First voice call bridge agent",
  "FIRST_VOICE_CALL_BRIDGE.QUEUE_ID": "First voice call bridge queue",
  "FIRST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "First voice call bridge is external dial",
  "FIRST_VOICE_CALL_BRIDGE.IS_TWINNED": "First voice call bridge is twinned",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFER": "First voice call bridge is transfer",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "First voice call bridge is transferred",
  "FIRST_VOICE_CALL_BRIDGE.EXIT_REASON": "First voice call bridge exit reason",
  "LAST_VOICE_CALL_BRIDGE.ID": "Last voice call bridge id",
  "LAST_VOICE_CALL_BRIDGE.DIRECTION": "Last voice call bridge direction",
  "LAST_VOICE_CALL_BRIDGE.CREATED_AT": "Last voice call bridge created at",
  "LAST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Last voice call bridge answer at",
  "LAST_VOICE_CALL_BRIDGE.LEAVE_AT": "Last voice call bridge leave",
  "LAST_VOICE_CALL_BRIDGE.USER_ID": "Last voice call bridge agent",
  "LAST_VOICE_CALL_BRIDGE.QUEUE_ID": "Last voice call bridge queue",
  "LAST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Last voice call bridge is external dial",
  "LAST_VOICE_CALL_BRIDGE.IS_TWINNED": "Last voice call bridge is twinned",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Last voice call bridge is transfer",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Last voice call bridge is transferred",
  "LAST_VOICE_CALL_BRIDGE.EXIT_REASON": "Last voice call bridge exit reason",
  "USER.USERNAME": "Agent username",
  "USER.EMAIL": "Agent e-mail",
  "USER.FIRST_NAME": "Agent first name",
  "USER.LAST_NAME": "Agent last name",
  "USER.FULL_NAME": "Agent full name",
  "_QUEUE.AGENTS_READY": "Queue agents ready",
  "_QUEUE.AGENTS_PAUSED": "Queue agents paused",
  "_QUEUE.AGENTS_BUSY": "Queue agents busy",
  "_QUEUE.AGENTS_TOTAL": "Queue agents total",
  "_QUEUE.LONGEST_WAITING": "Queue longest waiting",
  "_QUEUE.WAITING": "Queue Waiting",
  PRIORITY: "Priority",
  DISPOSITION_ID: "Disposition",
  SUB_DISPOSITION_ID: "Sub-disposition",
  THRD_DISPOSITION_ID: "Third-disposition",
  DISPOSITION_NOTE: "Disposition note",
  STATUS: "Status",
  ADD_TAG: "Add tag",
  REMOVE_TAG: "Remove tag",

  STATUS_O: "Open",
  STATUS_RO: "Re-opened",
  STATUS_C: "Closed",
  STATUS_R: "Resolved",
  STATUS_P: "Pending",
  STATUS_W: "Waiting for third party",

  PRIORITY_URGENT: "Urgent",
  PRIORITY_HIGH: "High",
  PRIORITY_MEDIUM: "Medium",
  PRIORITY_LOW: "Low",
  PRIORITY_U: "Urgent",
  PRIORITY_H: "High",
  PRIORITY_M: "Medium",
  PRIORITY_L: "Low",

  DIRECTION_INBOUND: "Inbound",
  DIRECTION_OUTBOUND: "Outbound",
  DIRECTION_INTERNAL: "Internal",
  DIRECTION_I: "Inbound",
  DIRECTION_O: "Outbound",
  "DIRECTION_-": "Internal",

  EXIT_REASON_REJECT: "Reject",
  EXIT_REASON_CONTACTHANGEDUP: "Contact hanged up",
  EXIT_REASON_TIMEOUT: "Timeout",
  EXIT_REASON_FULL: "Queue full",
  EXIT_REASON_JOINEMPTY: "Queue join empty",
  EXIT_REASON_LEAVEEMPTY: "Queue leave empty",
  EXIT_REASON_JOINUNAVAIL: "Queue join unavailable",
  EXIT_REASON_LEAVEUNAVAIL: "Queue leave unavailable",

  DEFINED: "Defined",
  CUSTOM: "Custom",

  NEWDRAFT: "New draft created",
  NEWINTERNALNOTE: "New internal note created",
  CONVERSATIONUPDATED: "Conversation updated",
  CONVERSATIONCREATED: "Conversation created",
  CONTACTUPDATED: "Contact updated",
  CONTACTCREATED: "Contact created",
  NEWINBOUNDMAILMESSAGE: "New inbound mail message",
  NEWOUTBOUNDMAILMESSAGE: "New outbound mail message",
  SINCECONVERSATION: "Since conversation update",
  NEWINBOUNDVOICECALL: "New inbound voice call",
  NEWOUTBOUNDVOICECALL: "New outbound voice call",
  NEWINTERNALVOICECALL: "New internal voice call",
  INBOUNDVOICECALLHANGEDUP: "Inbound voice call hanged up",
  OUTBOUNDVOICECALLHANGEDUP: "Outbound voice call hanged up",
  INTERNALVOICECALLHANGEDUP: "Internal voice call hanged up",
  INBOUNDVOICECALLANSWERED: "Inbound voice call answered",
  OUTBOUNDVOICECALLANSWERED: "Outbound voice call answered",
  INTERNALVOICECALLANSWERED: "Internal voice call answered",
  NEWINBOUNDVOICECALLBRIDGE: "New inbound voice call bridge",
  NEWOUTBOUNDVOICECALLBRIDGE: "New outbound voice call bridge",
  NEWINTERNALVOICECALLBRIDGE: "New internal voice call bridge",
  INBOUNDVOICECALLBRIDGELEAVE: "Inbound voice call bridge leave",
  OUTBOUNDVOICECALLBRIDGELEAVE: "Outbound voice call bridge leave",
  INTERNALVOICECALLBRIDGELEAVE: "Internal voice call bridge leave",
  INBOUNDVOICECALLBRIDGEANSWERED: "Inbound voice call bridge answered",
  OUTBOUNDVOICECALLBRIDGEANSWERED: "Outbound voice call bridge answered",
  INTERNALVOICECALLBRIDGEANSWERED: "Internal voice call bridge answered",

  CANNED_ANSWER_ID: "Canned answer",
  CANNED_ANSWER_ID2: "Canned answer 2",
  CANNED_ANSWER_ID3: "Canned answer 3",
  CANNED_ANSWER_ID4: "Canned answer 4",
  MAIL_ACCOUNT_ID: "Mail account",
  DESCRIPTION: "Description",
  URL: "Url",
  STATUS_PORTS: "Status ports",
  QUERY_PARAMS: "Query params",
  HEADERS: "Headers",
  BODY: "Body",
  QUEUE_ID: "Queue",
  MILLISECONDS: "Milliseconds",
  TIMEOUT: "Timeout",
  OPERATIONAL_HOURS_ID: "Operational hours",
  JOURNEY_ID: "Journey",
  PUBLISH: "Publish",
  ENTER_FULLSCREEN: "Enter fullscreen",
  EXIT_FULLSCREEN: "Exit fullscreen",
  RESET: "Reset",
  APPLY: "Apply",
  RELOAD_FROM_JOURNEY: "Reload from journey",
  WHEN: "When",
  WITHIN: "Within",
  NOT_WITHIN: "Not within",
  ERROR: "Error",
  SUCCESS: "Success",
  OPTION_1: "Option 1",
  OPTION_2: "Option 2",
  OPTION_3: "Option 3",
  OPTION_4: "Option 4",
  OPTION_5: "Option 5",
  OPTION_6: "Option 6",
  OPTION_7: "Option 7",
  OPTION_8: "Option 8",
  OPTION_9: "Option 9",
  RULE_1: "Rule 1",
  RULE_2: "Rule 2",
  RULE_3: "Rule 3",
  RULE_4: "Rule 4",
  RULE_5: "Rule 5",
  RULE_6: "Rule 6",
  RULE_7: "Rule 7",
  RULE_8: "Rule 8",
  RULE_9: "Rule 9",
  OTHERWISE: "Otherwise",
  TRIGGERED: "Triggered",
  TIMED_OUT: "Timed out",
  NEXT: "Next",
  EDITED: "Edited",
  ASSIGNED: "Assigned",
  TIME_RANGE: "Time range",
  MONTHS: "Months",
  DAYS: "Days",
  DAY_S: "Day(s)",
  WEEK_DAYS: "Week days",
  FROM: "From",
  ALWAYS: "Always",
  INVALID_INTERVAL: "Invalid interval",
  CREATED: "Created",
  RESOLVED: "Resolved",
  REOPENED: "Re-opened",
  FIRSTASSIGNED: "First assigned",
  REQUESTERRESPONDED: "Requester responded",
  AGENTRESPONDED: "Agent responded",
  UNTILFIRSTRESPONSEDUE: "Until first response due",
  FIRSTRESPONSEOVERDUE: "First response overdue",
  UNTILANYRESPONSEDUE: "Until any response due",
  ANYRESPONSEOVERDUE: "Any response overdue",
  RESOLUTIONTIMEOVERDUE: "Resolution time overdue",
  UNTILRESOLUTIONTIME: "Until resolution due",
  WAITINGFORTHIRDPARTY: "Waiting for third party",
  UNIT: "Unit",
  TIME: "Time",
  RESPONSE_TIME: "Response time",
  RESOLUTION_TIME: "Resolution time",
  CONTACT_ID: "Contact",
  USER_ID: "User id",
  AGENT_ID: "Agent id",
  HOURS_SHORT: "h",
  MINUTES_SHORT: "m",
  SECONDS_SHORT: "s",
  DAYS_SHORT: "d",
  YESTERDAY: "Yesterday",
  JUST_NOW: "Just now",
  NOUPDATEDIN7DAYS: "No updates in 7 days",
  CUSTOMERRESPONDED: "Customer responded",
  STATUS_AT: "Status changed at",
  ASSIGNED_AT: "Assigned at",
  FIRST_ASSIGNED_AT: "First assigned at",
  CONVERSATION_UPDATE_DISPOSITION_ID: "Disposition was updated",
  CONVERSATION_UPDATE_USER_ID: "Agent was updated",
  CONVERSATION_UPDATE_STATUS: "Status was changed",
  CONVERSATION_UPDATE_FLAG: "Flag was changed",
  CONVERSATION_UPDATE_RESOLUTION_TIME: "Resolution time was changed",
  CONVERSATION_UPDATE_RESPONSE_TIME: "Response time was changed",
  CONVERSATION_UPDATE_QUEUE: "Queue was changed",
  CONVERSATION_UPDATE_PRIORITY: "Priority was changed",
  CONVERSATION_UPDATE_CONTACT: "Contact was changed",
  CONVERSATION_NEW_MESSAGE: "New message",
  NEW_CONVERSATION: "New conversation",
  MINIMIZE: "Minimize",
  MAXIMIZE: "Maximize",
  MINUTES: "Minutes",
  MINUTE_S: "Minute(s)",
  SECOND_S: "Second(s)",
  HOURS: "Hours",
  HOUR_S: "Hour(s)",
  LAST_UPDATED: "Last updated",
  SUBJECT: "Subject",
  CC: "CC",
  BCC: "BCC",
  DISPOSE_AND_CLOSE: "Dispose & Close",
  DISPOSE_AND_RESOLVE: "Dispose & Resolve",
  ADD_NEW: "Add new",
  CONFIRM_DELETION: "Are you sure you want to delete this item?",
  OAUTH_AUTHENTICATION_NOT_FOUND: "Authentication failed!",
  OAUTH_AUTHENTICATION_NOT_FOUND_CONTENT:
    "An administrator of your organization has to create an account with your email account before you can sign in!",
  MAIL_ACCOUNT_AUTHENTICATED: "Mail account authenticated!",
  AN_ERROR_OCCURRED: "An error occurred!",
  ATTACHMENTS: "Attachments",
  ATTACHMENT: "Attachment",
  FILE_NOT_FOUND: 'File "{{filename}}" could not be found!',
  NOT_FOUND: "Not found!",
  PAGE_NOT_FOUND: "Page not found!",
  PAGE_NOT_FOUND_DESCRIPTION:
    "The page you are looking for does not exist! If you clicked on link that brought you here, please report the issue to your admin!",
  NOTIFICATION_A: "New Assignment",
  NOTIFICATION_B: "New Response On My Conversation",
  NOTIFICATION_C: "Conversation Messages I'm Tagged In",
  NOTIFICATION_D: "Status Update On My Conversation",
  NOTIFICATION_E: "Conversation Assigned To Me",
  NOTIFICATION_F: "Conversation Assigned To My Queues",
  NOTIFICATION_G: "Reminder On First Response",
  NOTIFICATION_H: "Reminder On Resolution Time",
  NOTIFICATION_I: "Reminder On Next Response",
  NOTIFICATION_J: "System info",
  NOTIFICATION_K: "System error",
  NOTIFICATION_L: "Password expiring soon",
  NOTIFICATION_M: "Alarm",
  "NOTIFICATION_-": "Internal call",
  MARK_ALL_AS_SEEN: "Mark all as seen",
  CANNED_ANSWER: "Canned answer",
  CONTACT_FIRST_NAME: "Contact first name",
  CONVERSATION_DATA_FORM: "Form conversation data",
  CONTACT_LAST_NAME: "Contact last name",
  CONTACT_FULL_NAME: "Contact full name",
  CONTACT_EMAIL: "Contact e-mail",
  CONTACT_PHONE: "Contact phone",
  AGENT_FIRST_NAME: "Agent first name",
  AGENT_LAST_NAME: "Agent last name",
  AGENT_FULL_NAME: "Agent full name",
  CONVERSATION__ID: "Conversation id",
  SELECT_CONTACT: "Select contact",
  MULTIPLE_CONTACTS_WHERE_FOUND: "Multiple contacts were found!",
  MULTIPLE_CONTACTS_WHERE_FOUND_SELECT_CONTACT:
    "Multiple contacts were found! Which one do you want to use?",
  WHICH_ONE_DO_YOU_WANT_TO_USE: "Which one do you want to use?",
  EMAILS: "E-mails",
  PHONES: "Phones",
  STRATEGY: "Strategy",
  ROUND_ROUND_ROBIN: "Round robin",
  VOICE_QUEUE_STRATEGY_RINGALL: "Ring all",
  VOICE_QUEUE_STRATEGY_ROUNDROBIN: "Round robin",
  VOICE_QUEUE_STRATEGY_LEASTRECENT: "Least recent",
  VOICE_QUEUE_STRATEGY_FEWESTCALLS: "Fewest calls",
  VOICE_QUEUE_STRATEGY_RRMEMORY: "Round robin with memory",
  VOICE_QUEUE_STRATEGY_LINEAR: "Linear",
  VOICE_QUEUE_STRATEGY_WRANDOM: "Wrandom",
  RINGALL: "Ring all",
  LEASTRECENT: "Least recent",
  FEWESTCALLS: "Fewest calls",
  RANDOM: "Random",
  WRANDOM: "Wrandom",
  LINEAR: "Linear",
  RRMEMORY: "Round robin with memory",
  ROUNDROBIN: "Round robin",
  QUEUE_STRATEGY_ROUND_ROUND_ROBIN: "Round robin",
  QUEUE_STRATEGY_PING_ALL: "Ping all",
  QUEUE_STRATEGY_LEAST_RECENT: "Least recent",
  QUEUE_STRATEGY_FEWEST_CONVERSATIONS: "Fewest conversations",
  QUEUE_STRATEGY_RANDOM: "Random",
  QUEUE_STRATEGY_SKILL_BASED_AGENTS_READY: "Skill base agents ready",
  QUEUE_STRATEGY_SKILL_BASED_TIMEOUT: "Skill base timeout",
  CREATE_LIST_ID: "Create list",
  CREATE_LIST_ID_HELP: "Select list to insert new contacts to",
  READ_LISTS: "Read lists",
  READ_LISTS_HELP:
    "Lists to read contacts from. Both system and agents will select contacts that exists in these lists.",
  CONVERSATION_FORM_ID: "Conversation form",
  SLA_POLICY_ID: "SLA policy",
  INBOUND_SLA_POLICY_ID: "SLA policy (inbound)",
  OUTBOUND_SLA_POLICY_ID: "SLA policy (outbound)",
  REQUIRE_DISPOSITION: "Require disposition",
  SETTINGS_REQUIRE_DISPOSITION_HELP:
    "Enable to force selection of disposition before closing conversation",
  REQUIRE_CLIENT: "Require client",
  SETTINGS_REQUIRE_CLIENT_HELP: "Enable to force selection of client before closing conversation",
  CONVERSATION_ASSIGNMENT: "Conversation assignment",
  SETTINGS_CONVERSATION_ASSIGNMENT_HELP:
    "How to assign conversations to agents. Manual assigment will always be available.",
  ONOPEN: "On open",
  ONREPLY: "On reply",
  ONCLOSE: "On close",
  MANUALLY: "Manually",
  CONVERSATION_ASSIGNMENT_ONOPEN: "On open",
  CONVERSATION_ASSIGNMENT_ONREPLY: "On reply",
  CONVERSATION_ASSIGNMENT_ONCLOSE: "On close",
  CONVERSATION_ASSIGNMENT_MANUALLY: "Manually",
  __DISPOSITIONS: "Dispositions",
  __DISPOSITIONS_HELP: "Available dispositions to select for conversations of this queue.",
  __CANNED_ANSWERS: "Canned answers",
  __AGENT_SIGNATURES: "Agent signatures",
  __OUTBOUND_MAIL_ACCOUNTS: "Outbound mail accounts",
  __OUTBOUND_MAIL_ACCOUNTS_HELP:
    "Available outbound mail accounts when creating new outbound e-mail conversations",
  FORM_NULLABLE_ENABLE: "Define value",
  FORM_NULLABLE_DISABLE: "Disable value",
  EDIT_QUEUE: "Edit queue",
  ADD_QUEUE: "Create new queue",
  DELETED: "Deleted",
  TWO_FACTOR_AUTH: "2FA",
  ORGANIZATION_ID: "Organization",
  ADMINPROFILE_ID: "Admin profile",
  SUPERADMIN_PROFILE_ID: "Superadmin profile",
  __PAUSES: "Pauses",
  __QUEUES: "Queues",
  __TEAMS: "Teams",
  TEAM: "Team",
  ADDRESS: "Address",
  INBOUND_JOURNEY_ID: "Inbound journey",
  OUTBOUND_JOURNEY_ID: "Outbound journey",
  IMAP: "IMAP",
  SMTP: "SMTP",
  HOST: "Host",
  PORT: "Port",
  NAME: "Name",
  TLS: "tls/ssl",
  REMOVE_ON_RECEIVE: "Remove on receive",
  IMAP_REMOVE_ON_RECEIVE_HELP: "Remove e-mail from inbox when received",
  BASIC: "Basic",
  XOAUTH2: "Oauth 2",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASIC: "Basic",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICGOOGLE: "Basic (Google)",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICMICROSOFT: "Basic (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2MICROSOFT: "Modern auth (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2GOOGLE: "Modern auth (Google)",
  JOURNEIES: "Journeys",
  JOURNEY_DRAFTS: "Journey drafts",
  FORM_CHANNEL: "Form channel",
  FORM_WEBSITES: "Form websites",
  TIME_INTERVALS: "Time intervals",
  OPERATIONAL_HOURSES: "Operational hours",
  OPERATIONAL_HOURS: "Operational hours",
  SLA_POLICIES: "SLA policies",
  CANNED_ANSWERS: "Canned answers",
  SIGNATURES: "Signatures",
  FORMS: "Forms",
  STAFF: "Staff",
  SEARCH_AGENTS: "Search agents",
  STATUS_0: "Offline",
  STATUS_1: "Online",
  STATUS_4: "Ready (Mail)",
  STATUS_8: "Ready (Voice)",
  STATUS_512: "Ready",
  STATUS_1024: "Partial busy",
  STATUS_2048: "Handling conversation",
  STATUS_4096: "Pinging",
  STATUS_32768: "Pause",
  STATUS_262144: "Busy (Mail)",
  STATUS_524288: "Busy (Voice)",
  STATUS_1048576: "Busy (Form)",
  STATUS_33554432: "Busy",
  FILTER: "Filter",
  ITEMS: "Items",
  ALLOW: "Allow",
  CANNEDANSWER: "Canned answer",
  FORM: "Form",
  SIGNATURE: "Signature",
  SLAPOLICY: "SLA policy",
  OPERATIONALHOURS: "Operational hours",
  TAG: "Tag",
  MAILACCOUNT: "Mail account",
  READ: "Read",
  CREATE: "Create",
  DELETE: "Delete",
  PERMISSIONS: "Permissions",
  TIMEINTERVAL: "Time interval",
  LOGIN_STRATEGY: "Login type",
  LOGIN_STRATEGY_LOCAL: "Username / Password",
  LOGIN_STRATEGY_GOOGLE: "Google",
  LOGIN_STRATEGY_MICROSOFT: "Microsoft",
  ADMIN_AGENT: "Admin & Agent",
  FORMWEBSITE: "Form website",
  SCHEMA_NOT_FOUND: "Form could not be loaded!",
  ITEM_NOT_FOUND: "The item you are trying to load, could not be found!",
  ADMIN_ORGANIZATIONS_CHANGED_RELOAD:
    "Assigned organizations to your user has been updated, please manually reload in order for the application to function correctly!",
  LOADING_DEPENDENCIES: "Loading dependencies",
  PARENT: "Parent",
  ACTIVE: "Active",
  ORGANIZATION_2FA_HELP: "Select roles to enforce two way authentication",
  SECONDS: "Seconds",
  PROPERTY: "Property",
  VALUE: "Value",
  SIGNATURE_ID: "Signature",
  METHOD: "Method",
  CONDITIONS: "Conditions",
  ADD_CONDITION: "Add condition",
  FLAG: "Flag",
  SOURCE: "Source",
  SINCE: "Since",
  JOURNEY_DRAFT: "Journey draft",
  PENDING: "Pending",
  START_CONVERSATION: "Start conversation",
  NEW_V_CONVERSATION: "New SIP conversation",
  NEW_M_CONVERSATION: "New e-mail conversation",
  CREATE_NEW_CONTACT: "Create new contact",
  OR_CREATE_NEW_CONTACT: "or create new contact",
  LOADING_FORM: "Loading form",
  __TAGS: "Tags",
  MAIN_CHANNEL: "Main channel",
  CREATE_CONVERSATION: "Create conversation",
  CHOOSE_AGENT: "Choose agent",
  CHOOSE_QUEUE: "Choose queue",
  SELECTED: "Selected",
  CHANGE_CONTACT: "Change contact",
  CREATE_CONTACT: "Create contact",
  REQUIRED: "Required",
  CONVERSATION_STATUS: "Conversation status",
  NO_NAME: "No name",
  DISPLAY_TITLE: "Display title",
  UI_TYPE: "UI type",
  VALIDATION: "Validation",
  MIN: "Min",
  CHILD_TYPE: "Child type",
  VALID: "Valid",
  REQUIRED_ON_SAVE: "Required on save",
  REQUIRED_ON_CONVERSATION_CLOSE: "Required on conversation close",
  REQUIRED_ON_FORM_SUBMIT: "Required on website form submission",
  ALIAS: "Alias",
  ALIAS__KEY: "Alias / key",
  FIELD_TYPE_DESCRIPTION: "Type of the field will make it available for different form types",
  MINIMUM: "Minimum",
  VALIDATION_MINIMUM_NUMBER_HELP: "Minimum number that can be filled in this field",
  VALIDATION_MINIMUM_LIST_HELP: "Minimum options",
  VALIDATION_MINIMUM_TEXT_HELP: "Minimum characters",
  SETTINGS_VALIDATION_CHILD_TYPE_HELP: "Type of list options",
  FIELD_ALIAS_HELP:
    "Values will be stored using this unique alias. Only alpha-num and _ are valid characters.",
  OPTIONS: "Options",
  __FIELDS: "Fields",
  WEBSITE: "Website",
  POLICY: "Policy",
  FIRST_RESPONSE: "First response",
  EVERY_RESPONSE: "Every response",
  URGENT: "Urgent",
  SLA_POLICY: "SLA policy",
  SEARCH_RESULTS: "Search results",
  UNASSIGNED: "Unassigned",
  NO_QUEUE: "No queue",
  NO_PAUSE: "No pause",
  NOT_DISPOSED: "Not disposed",
  NO_FLAG: "No flag",
  FLAGS: "Flags",
  INVALID_2FA_SECRET: "Invalid code",
  __TIME_INTERVALS: "Time intervals",
  TIME_INTERVAL: "Time interval",
  TIMEZONE: "Timezone",
  RESET_PASSWORD_DONE: "An e-mail with instructions has been sent to you!",
  PASSWORD_RESET_EMAIL_WILL_BE_SENT:
    "We will send an e-amil with instructions to the account with given username, if that account exists!",
  DISPOSITION_REQUIRED_ON_THIS_QUEUE:
    "Disposition must be set when closing/resolving conversation, due to queue settings.",
  CLIENT_REQUIRED_ON_THIS_QUEUE:
    "Client must be set when closing/resolving conversation, due to queue settings.",
  CONVERSATION_FORM_NOT_VALID_ON_SAVE:
    "Conversation form has required fields that has to be filled before you can edit conversation.",
  CONVERSATION_FORM_NOT_VALID_ON_CLOSE:
    "Conversation form has required fields that has to be filled before you can resolve/close conversation.",
  CONTACT_FORM_NOT_VALID_ON_SAVE:
    "Contact form has required fields that has to be filled before you can edit conversation.",
  CONTACT_FORM_NOT_VALID_ON_CLOSE:
    "Contact form has required fields that has to be filled before you can resolve/close conversation.",
  CONVERSATION_CANNOT_BE_CLOSED_WITHOUT_USER:
    "Conversation has to have an agent assigned to it before closing/resolving.",
  DISPOSITION_NOTE_NOT_ALLOWED_WHEN_NO_DISPOSITION_SELECTED:
    "Disposition note not allowed whithout a disposition selected.",
  QUEUE_MAY_NOT_BE_NULL: "Queue may not be unset on conversation.",
  AGENT_NOT_IN_SELECTED_QUEUE: "Assigned agent is not connected to selected queue.",
  CONTACT_ALREADY_SELECTED: "Contact already selected on conversation",
  QUEUE_NOT_FOUND_ON_CONVERSATION:
    "A queue has to be assigned to conversation before selecting a contact.",
  NO_MESSAGES_FOUND_ON_CONVERSATION: "No message found on conversation.",
  QUEUE_NO_READ_LISTS: "Queue has no read lists assigned to it.",
  CONVERSATION_FORM_DATA_CLOSE_REQUIRED_TOOLTIP:
    "Required field when setting conversation status to closed",
  CONVERSATION_FORM_DATA_CLOSE_TOOLTIP: "Required field when updating conversation properties",
  VIEW_CONVERSATION: "View conversation",
  START_NEW_MAIL_CONVERSATION: "Start new mail conversation",
  START_NEW_VOICE_CONVERSATION: "Start new voice conversation",
  NULLABLE: "Nullable",
  SETTINGS_NULLABLE_HELP: 'Value can be set to "null"',
  UI_DATA_TYPE: "UI / Data type",
  LAST_SAVE: "Last save",
  TITLE_MISSING: "Title missing",
  SELECT_QUEUE: "Select queue",
  CREATE_INTERNAL_NOTE: "Create internal note",
  SAVE_DRAFT: "Save draft",
  NO_SELECTABLE_DISPOSITIONS: "No selectable dispositions",
  SMTP_PORT_HELP: "Default: 25, tls: 465",
  IMAP_PORT_HELP: "Default: 143, tls: 993",
  FORM_ID: "Form",
  CHANNEL: "Channel",
  INTERVAL: "Interval",
  CANNED_ANSWER_TYPE_A: "Agent",
  CANNED_ANSWER_TYPE_Q: "Queue",
  CANNED_ANSWER_CHANNEL_M: "E-mail",
  CANNED_ANSWER_CHANNEL_S: "Sms",
  SIGNATURE_TYPE_A: "Agent",
  SIGNATURE_TYPE_Q: "Queue",
  SLA_TIME_UNIT_H: "Hours",
  SLA_TIME_UNIT_D: "Days",
  FORM_TYPE_CONVERSATION: "Conversation",
  FORM_TYPE_CONTACT: "Contact",
  FORM_TYPE_WEBSITE: "Website",
  FIELD_TYPE_CONVERSATION: "Conversation",
  FIELD_TYPE_CONTACT: "Contact",
  FIELD_TYPE_FORMSUBMISSION: "Form submission",

  FORM_ELEMENT_TYPE_EXPOSED_INPUT: "Text",
  FORM_ELEMENT_TYPE_EXPOSED_TEXT: "Long text",
  FORM_ELEMENT_TYPE_EXPOSED_NUMBER: "Number",
  FORM_ELEMENT_TYPE_EXPOSED_SELECT: "Select",
  FORM_ELEMENT_TYPE_EXPOSED_LIST: "List",
  FORM_ELEMENT_TYPE_EXPOSED_SWITCH: "Switch",
  FORM_ELEMENT_TYPE_EXPOSED_RADIO: "Radio",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOXES: "Checkboxes",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOX: "Checkbox",
  FORM_ELEMENT_TYPE_EXPOSED_EMAIL: "E-mail",
  FORM_ELEMENT_TYPE_EXPOSED_PHONE: "Phone number",
  FORM_ELEMENT_TYPE_EXPOSED_HTML: "Html",
  FORM_ELEMENT_TYPE_EXPOSED_FILE: "File",

  FORM_ELEMENT_CHILD_TYPE_EXPOSED_INPUT: "Text",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_NUMBER: "Number",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_PHONE: "Phone number",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_EMAIL: "E-mail",

  CHANNEL_VOICE: "Voice",
  CHANNEL_VOICE_INBOUND: "Incoming Voice Call",
  CHANNEL_VOICE_INTERNAL: "Internal Voice Call",
  CHANNEL_VOICE_INTERNAL_LIVE: "Ongoing Internal Voice Call",
  CHANNEL_VOICE_TRANSFER: "Voice Transfer Call",
  CHANNEL_VOICE_TRANSFER_LIVE: "Ongoing Voice Transfer Call",
  CHANNEL_VOICE_INBOUND_LIVE: "Ongoing Inbound Voice Call",
  CHANNEL_VOICE_OUTBOUND: "Outbound Voice Call",
  CHANNEL_VOICE_OUTBOUND_LIVE: "Ongoing Outbound Voice Call",
  CHANNEL_EDIT: "Edit",
  CHANNEL_CHAT: "Chat",
  CHANNEL_CHAT_INBOUND: "Inbound Chat message",
  CHANNEL_CHAT_OUTBOUND: "Outbound Chat message",
  CHANNEL_MAIL: "E-mail",
  CHANNEL_MAIL_INBOUND: "Incoming E-mail",
  CHANNEL_MAIL_OUTBOUND: "Outgoing E-mail",
  CHANNEL_SMS: "Text Message",
  CHANNEL_SMS_INBOUND: "Incoming Text Message",
  CHANNEL_SMS_OUTBOUND: "Outgoing Text Message",
  CHANNEL_FORM: "Form",
  CHANNEL_CHANSPY: "Channel spy",
  CHANNEL_DISPOSITION_NOTE: "Disposition note",
  INTERNAL_CALL: "Internal call",

  CHANNEL_V: "Voice",
  "CHANNEL_-": "No Channel",
  CHANNEL_C: "Chat",
  CHANNEL_M: "E-mail",
  CHANNEL_S: "SMS",
  CHANNEL_F: "Form",
  CHANNEL_IN: "Internal Note",
  CHANNEL_In: "Internal Note",

  ADMINPROFILE_PERMISSION_ID_USER: "User",
  ADMINPROFILE_PERMISSION_ID_TEAM: "Team",
  ADMINPROFILE_PERMISSION_ID_ORGANIZATION: "Organization",
  ADMINPROFILE_PERMISSION_ID_MAILACCOUNT: "Mail account",
  ADMINPROFILE_PERMISSION_ID_QUEUE: "Queue",
  ADMINPROFILE_PERMISSION_ID_LIST: "List",
  ADMINPROFILE_PERMISSION_ID_ADMINPROFILE: "Admin profile",
  ADMINPROFILE_PERMISSION_ID_AGENTPROFILE: "Agent profile",
  ADMINPROFILE_PERMISSION_ID_SLAPOLICY: "SLA Policy",
  ADMINPROFILE_PERMISSION_ID_DISPOSITION: "Disposition",
  ADMINPROFILE_PERMISSION_ID_FORM: "Form",
  ADMINPROFILE_PERMISSION_ID_FIELD: "Field",
  ADMINPROFILE_PERMISSION_ID_FORMWEBSITE: "Form website",
  ADMINPROFILE_PERMISSION_ID_JOURNEY: "Journey",
  ADMINPROFILE_PERMISSION_ID_OPERATIONALHOURS: "Operational hours",
  ADMINPROFILE_PERMISSION_ID_PAUSE: "Pause",
  ADMINPROFILE_PERMISSION_ID_CANNEDANSWER: "Canned answers",
  ADMINPROFILE_PERMISSION_ID_SIGNATURE: "Signature",
  ADMINPROFILE_PERMISSION_ID_TAG: "Tag",
  ADMINPROFILE_PERMISSION_ID_TIMEINTERVAL: "Time interval",
  ADMINPROFILE_PERMISSION_ID_VOICEROUTE: "Voice route",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDING: "Voice recording",
  ADMINPROFILE_PERMISSION_ID_SOUND: "Sound",
  ADMINPROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Variable",
  ADMINPROFILE_PERMISSION_ID_STATSREPORT: "Stats report",
  ADMINPROFILE_PERMISSION_ID_STATSWIDGET: "Stats widget",
  ADMINPROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Destination restrictor",
  ADMINPROFILE_PERMISSION_ID_INVOICE: "Invoice",
  ADMINPROFILE_PERMISSION_ID_VOICESHORTCUT: "Voice shortcut",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Voice voicemail",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Voice voicemail message",
  ADMINPROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Phonenumber purchase",
  ADMINPROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Custom metrics",
  ADMINPROFILE_PERMISSION_ID_AUDIT: "Audit",
  ADMINPROFILE_PERMISSION_ID_APIKEY: "Api key",
  ADMINPROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Contact custom event",
  ADMINPROFILE_PERMISSION_ID_CLIENT: "Client",
  ADMINPROFILE_PERMISSION_ID_CONTACT: "Contact",
  ADMINPROFILE_PERMISSION_ID_WALLBOARD: "Wallboard",
  ADMINPROFILE_PERMISSION_ID_SKILL: "Skill",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Voice recording transcript",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING: "Voice recording transcript settings",
  ADMINPROFILE_PERMISSION_ID_POINTSSYSTEM: "Points system",
  ADMINPROFILE_PERMISSION_ID_GDPR: "Security & compliance",
  ADMINPROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymize",
  ADMINPROFILE_PERMISSION_ID_STATSALARM: "Stats alarm",
  ADMINPROFILE_PERMISSION_ID_VOICECALL: "Voice call",
  ADMINPROFILE_PERMISSION_ID_TEXTMESSAGE: "Text message",

  SUPERADMIN_PROFILE_PERMISSION_ID_USER: "User",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEAM: "Team",
  SUPERADMIN_PROFILE_PERMISSION_ID_ORGANIZATION: "Organization",
  SUPERADMIN_PROFILE_PERMISSION_ID_MAILACCOUNT: "Mail account",
  SUPERADMIN_PROFILE_PERMISSION_ID_QUEUE: "Queue",
  SUPERADMIN_PROFILE_PERMISSION_ID_LIST: "List",
  SUPERADMIN_PROFILE_PERMISSION_ID_ADMINPROFILE: "Admin profile",
  SUPERADMIN_PROFILE_PERMISSION_ID_AGENTPROFILE: "Agent profile",
  SUPERADMIN_PROFILE_PERMISSION_ID_SLAPOLICY: "SLA Policy",
  SUPERADMIN_PROFILE_PERMISSION_ID_DISPOSITION: "Disposition",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORM: "Form",
  SUPERADMIN_PROFILE_PERMISSION_ID_FIELD: "Field",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORMWEBSITE: "Form website",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEY: "Journey",
  SUPERADMIN_PROFILE_PERMISSION_ID_OPERATIONALHOURS: "Operational hours",
  SUPERADMIN_PROFILE_PERMISSION_ID_PAUSE: "Pause",
  SUPERADMIN_PROFILE_PERMISSION_ID_CANNEDANSWER: "Canned answers",
  SUPERADMIN_PROFILE_PERMISSION_ID_SIGNATURE: "Signature",
  SUPERADMIN_PROFILE_PERMISSION_ID_TAG: "Tag",
  SUPERADMIN_PROFILE_PERMISSION_ID_TIMEINTERVAL: "Time interval",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEROUTE: "Voice route",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDING: "Voice recording",
  SUPERADMIN_PROFILE_PERMISSION_ID_SOUND: "Sound",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Variable",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSREPORT: "Stats report",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSWIDGET: "Stats widget",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEASTERISK: "Voice asterisk",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFF: "Tariff",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFFPARENT: "Tariff parent",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOST: "Phonenumber monthly cost",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOSTPARENT: "Phonenumber monthly cost parent",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOST: "Phonenumber setup cost",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOSTPARENT: "Phonenumber setup cost parent",
  SUPERADMIN_PROFILE_PERMISSION_ID_SUPERADMINPROFILE: "Superadmin profile",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICE: "Invoice",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICEPOST: "Invoice Post",
  SUPERADMIN_PROFILE_PERMISSION_ID_PREPAIDCREDIT: "Prepaid credit",
  SUPERADMIN_PROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Destination resrictor",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Voicemail",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Voicemail message",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICESHORTCUT: "Voice shortcut",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICETRUNK: "Voice trunk",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSTOCK: "Phonenumber stock",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Phonenumber purchase",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Custom metrics",
  SUPERADMIN_PROFILE_PERMISSION_ID_RELEASE: "Releases",
  SUPERADMIN_PROFILE_PERMISSION_ID_AUDIT: "Audit",
  SUPERADMIN_PROFILE_PERMISSION_ID_APIKEY: "Api key",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Contact custom event",
  SUPERADMIN_PROFILE_PERMISSION_ID_CLIENT: "Client",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACT: "Contact",
  SUPERADMIN_PROFILE_PERMISSION_ID_SKILL: "Skill",
  SUPERADMIN_PROFILE_PERMISSION_ID_WALLBOARD: "Wallboard",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Voice recording transcript",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Voice recording transcript settings",
  SUPERADMIN_PROFILE_PERMISSION_ID_POINTSSYSTEM: "Points system",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPR: "Security & compliance",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymize",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSALARM: "Stats alarm",
  SUPERADMIN_PROFILE_PERMISSION_ID_AIASSISTANT: "Ai assistant",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICECALL: "Voice call",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEXTMESSAGE: "Text message",

  REPLY_OR_NOTE: "Reply or note",

  __USERS: "Agents",
  CONTACT_MANAGEMENT: "Contact management",
  NO_QUEUES_SELECTED:
    "You do not have any queues connected to organizations connected to you. Please contact your organization administrator to resolve this issue.",
  SOCKET_DISCONNECTED:
    "Your connection to server has been terminated. Please refresh window.\n\nDisconnections are caused by none stable internet connections or due to inactivity.",
  SOCKET_DISCONNECTED_INACTIVITY: "Socket disconnected due to inactivity",
  WARNING_INACTIVITY:
    'You will be signed out due to inactivity. Return to atlas dashboard or press "I\'m back" to prevent getting disconnected.',
  IAM_BACK: "I'm back!",
  NO_EMAIL_ACCESS:
    "You are not authorized to send e-mails. Please contact your administrator for further information.",
  NO_CREATE_CHANNELS: "You do not have access to any channels in order to create new conversation.",
  NO_OUTBOUND_QUEUES_ON_CREATE_CONVERSATION:
    "You do not have access to any queues with outbound route. Please contact your organization administrator to resolve this issue.",
  SELECT_AGENTPROFILE_ERROR: "An agent profile has to be selected in order to create conversation.",
  SEARCH_AGENT_AND_ASSIGN: "Search agent and assign",
  SEARCH_QUEUE_AND_ASSIGN: "Search queue and assign",
  EDIT_TAGS: "Edit tags",
  SEARCH_TAGS: "Search tags",
  SELECT_DISPOSITION: "Select disposition",
  MERGE: "Merge",
  MERGE_CONVERSATION: "Merge conversation",
  CONVERSATION_MERGE_EXPLANATION:
    "Interactions from secondary tickets will be added to the primary ticket",
  CONTACT_MERGE_EXPLANATION:
    "Conversations from secondary contact will be added to the primary contact",
  SEARCH_CONVERSATION: "Search conversation",
  SEARCH_CONTACT: "Search contact",
  MERGE_CONTACT: "Merge contact",
  PRIMARY: "Primary",
  NO_AGENT: "No agent",
  MAIL_ACCOUNT_IMAP_STATE_A: "Authenticated",
  MAIL_ACCOUNT_IMAP_STATE_N: "None",
  MAIL_ACCOUNT_IMAP_STATE_E: "Error",
  MAIL_ACCOUNT_IMAP_STATE_U: "Unauthorized",
  MAIL_ACCOUNT_IMAP_STATE_C: "Connected",
  MAIL_ACCOUNT_IMAP_STATE_CN: "Connecting",
  MAIL_ACCOUNT_IMAP_STATE_D: "Disconnected",
  MAIL_ACCOUNT_SMTP_STATE_A: "Authenticated",
  MAIL_ACCOUNT_SMTP_STATE_N: "None",
  MAIL_ACCOUNT_SMTP_STATE_E: "Error",
  MAIL_ACCOUNT_SMTP_STATE_U: "Unauthorized",
  MAIL_ACCOUNT_SMTP_STATE_C: "Connected",
  MAIL_ACCOUNT_SMTP_STATE_CN: "Connecting",
  MAIL_ACCOUNT_SMTP_STATE_D: "Disconnected",
  IMAP_STATE: "IMAP state",
  SMTP_STATE: "SMTP state",
  RECONNECT: "Reconnect",
  PERSONIFY_USER: "Personify user",
  RULES: "Rules",
  JOURNEY_ERROR_LINK_MISSING: "A link has to be connected to this port",
  JOURNEY_ERROR_FORM_ERROR: "There are errors in node form. Double click to edit form.",
  JOURNEY_ERROR_DUPLICATE: "Duplicate action! Entry actions may not appear more than once.",
  PUBLISH_JOURNEY: "Publish journey",
  NO_ITEMS_WERE_FOUND: "No items were found",
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_PHRASE:
    'Could not find any conversations or contacts matching search phrase "{{keyword}}"',
  NO_CONVERSATIONS_WERE_FOUND_BY_PHRASE:
    'No conversations were found matching search phrase "{{keyword}}"',
  NO_CONTACTS_WERE_FOUND_BY_PHRASE: 'No contacts were found matching search phrase "{{keyword}}"',
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT:
    "Could not find any conversations or contacts matching from selected contact",
  NO_CONVERSATIONS_WERE_FOUND_BY_SELECTED_CONTACT:
    "No conversations were found from selected contact",
  NO_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT: "No contacts were found from selected contact",
  NO_CONVERSATION_FOUND_ON_FILTER: "No conversations were found matching your filters",
  WITH_SELECTED_DO: "With selected change",
  CHANGE_STATUS: "Change status",
  CHANGE_PRIORITY: "Change priority",
  APPLY_CHANGES_TO_ITEMS: "Apply changes to {{count}} items",
  APPLY_AND_RESET: "Apply & Reset",
  ITEMS_FAILED_TO_UPDATE: "Failed to update {{count}} items",
  CHANGES_APPLIED_TO_ITEMS: "Changes applied to {{count}} items",
  MAIL_HAS_NO_BODY: "No mail body",
  TIME_PAST: "Time past",
  START_TYPING_TO_SEARCH: "Start typing to search",
  NOT_REMOVABLE_ENTITY: "May not be deleted!",
  SHOW_MORE: "Show more",
  SHOW_LESS: "Show less",
  UPLOAD: "Upload",
  N_FILES: "{{files}} file(s)",
  LIST_FILES: "List files",
  LIST_FILE: "List file",
  LIST_ID: "List",
  SHEET: "Sheet",
  ROW: "Row",
  SHEET_N: "Sheet {{n}}",
  ROW_N: "Row {{n}}",
  LIST_FILE_FIELDS_HELP: "Above rows is a preview in order to sort columns.",
  WRITE_LISTS: "Write lists",
  WRITE_LISTS_HELP: "Lists to write imported contacts to",
  CLEAN_LISTS: "Clean lists",
  CLEAN_LISTS_HELP: "Lists to clean contacts against",
  DIALPAD: "Dialpad",
  NO_NOTIFICATIONS: "You do not have any notifications!",
  NO_PAUSES_FOUND: "No pauses could be found",
  CONNECTION_AT: "Connection at",
  RETRIES: "Retries",
  NO_CONTACT: "Contact not found!",
  VOICE_MANAGEMENT: "Voice management",
  JOINED: "Joined",
  JOINED_QUEUE_DESCRIPTION:
    '"Message" joined the queue. This port triggers before trying to distribute the "message".',
  ON_ASSIGN: "Assigned",
  DONE_ANSWERED: "Done (answered)",
  DONE_NO_ANSWER: "Done (no answer)",
  ON_ASSIGN_QUEUE_DESCRIPTION: "Conversation was assigned to selected queue",
  DONE_ANSWERED_QUEUE_DESCRIPTION:
    "Voice call exited queue due to hang up, after being assigned to an agent",
  DONE_NO_ANSWER_QUEUE_DESCRIPTION:
    "Voice call exited queue due to timeout or empty queue (depending on the queue settings)",
  HANGUP: "Hang up",
  RECORD: "Record",
  HOLD: "Hold",
  UNHOLD: "Unhold",
  TRANSFER: "Transfer",
  MUTE: "Mute",
  UNMUTE: "Unmute",
  DISTRIBUTING_CALL: "Distributing call",
  VOICE_ASTERISK: "Asterisk",
  VOICE_ASTERISKS: "Asterisks",
  VOICE: "Voice",
  VOICE_TRUNK: "Trunk",
  VOICE_TRUNKS: "Trunks",
  VOICE_ROUTE: "Route",
  VOICE_ROUTES: "Routes",
  SOUND: "Sound",
  SOUNDS: "Sounds",
  JOURNEY_VARIABLE: "Variable",
  JOURNEY_VARIABLES: "Variables",
  REST: "Rest",
  FROM_DOMAIN: "From domain",
  ISO2: "ISO 2",
  VOICE_ASTERISK_ID: "Asterisk",
  INBOUND_VOICE_TRUNK_ID: "Inbound trunk",
  OUTBOUND_VOICE_TRUNK_ID: "Outbound trunk",
  NUMBER: "Number",
  INTERNAL: "Internal",
  VOICE_SETTINGS: "Voice settings",
  __OUTBOUND_VOICE_ROUTES: "Outbound voice routes",
  VOICE_RECORDING_FORMAT_WAV: "Wav",
  VOICE_RECORDING_FORMAT_WAV49: "Wav49",
  VOICE_RECORDING_FORMAT_GSM: "Gsm",
  NEW_CALL: "New call",
  START_RECORDING: "Manual recording",
  SETTINGS_START_RECORDING_HELP: "Allow agents to manually record calls",
  PLAY_RECORDING: "Play recording",
  DOWNLOAD_RECORDING: "Download recording",
  DOWNLOAD_VOICEMAIL: "Download voicemail",
  PERMISSION_SELF_N: "None",
  PERMISSION_SELF_A: "All",
  PERMISSION_SELF_S: "Owned by user",
  DOWNLOAD_RECORDING_ERROR: "Something went wrong when trying to download recording.",
  PLAY_RECORDING_ERROR: "Something went wrong when trying to play recording.",
  DOWNLOAD_VOICEMAIL_ERROR: "Something went wrong when trying to download voicemail.",
  PLAY_VOICEMAIL_ERROR: "Something went wrong when trying to play voicemail.",
  NO_RESULTS: "No results",
  VARIABLE_ALIAS: "Variable alias",
  VOICE_CALL: "Voice call",
  CURRENT_VOICE_CALL_BRIDGE: "Current voice call bridge",
  FIRST_VOICE_CALL_BRIDGE: "First voice call bridge",
  LAST_VOICE_CALL_BRIDGE: "Last voice call bridge",
  HANGED_UP: "Hanged up",
  EDGE_ERROR_FROM_NONE_CHANNEL_SPECIFIC_TO_CHANNEL_SPECIFIC:
    "Links between destination node with specific channels to source node with no specific channel is not allowed",
  EDGE_ERROR_CHANNEL_DONT_EXIST_IN_FROM_NODE:
    "All channels in destination node has to be available in the source node",
  EDGE_ERROR_CHANNELS_MISMATCH: "Source node channel does not match target node channels!",
  JOURNEY_VARIABLE_ID: "Variable",
  REST_REQUEST_VARIABLE_HELP:
    "If successful, set rest request response to selected variable. Data will be casted to string.",
  REST_REQUEST_FIELD_HELP:
    "If successful, set rest request response to selected field. Data will be casted to field type.",
  FIELD_ID: "Field",
  DATA_TYPE: "Data type",
  ACW: "ACW",
  VOICE_SETTINGS_ACW_HELP: "After call work",
  MUSICCLASS: "Music on hold",
  JOINEMPTY: "Join empty",
  FAIL_REASONS: "Fail reasons",
  FULL: "Full",
  LEAVEEMPTY: "Leave empty",
  JOINUNAVAIL: "Join unavailable",
  LEAVEUNAVAIL: "Leave unavailable",
  EVENTS: "Events",
  EVENT_ASSIGNED_DESCRIPTION: "Voice call was assigned to queue",
  EVENT_JOINED_DESCRIPTION: "Voice call was joined into queue",
  FAIL_REASON_FULL_DESCRIPTION: "Exited as queue where full",
  FAIL_REASON_TIMEOUT_DESCRIPTION: "Exited as queue timeout was reached",
  FAIL_REASON_JOINEMPTY_DESCRIPTION: 'Exited due to queue status "JOINEMPTY"',
  FAIL_REASON_LEAVEEMPTY_DESCRIPTION: 'Exited due to queue status "LEAVEEMPTY"',
  FAIL_REASON_JOINUNAVAIL_DESCRIPTION: 'Exited due to queue status "JOINUNAVAIL"',
  FAIL_REASON_LEAVEUNAVAIL_DESCRIPTION: 'Exited due to queue status "LEAVEUNAVAIL"',
  VOICE_QUEUE_QUEUE_STATUS: "Queue status",
  VOICE_SETTINGS_JOINEMPTY_HELP: "Join queue when empty",
  LEAVEWHENEMPTY: "Leave empty",
  VOICE_SETTINGS_LEAVEWHENEMPTY_HELP: "Leave queue when empty",
  RINGINUSE: "Ring in use",
  VOICE_SETTINGS_RINGINUSE_HELP:
    "Controls if the agent should receive additional queue calls even though the agent is busy or in-use.",
  MAXLEN: "Maximum queue calls",
  VOICE_SETTINGS_MAXLEN_HELP:
    "The maximum allowed calls waiting in queue. Calls reaching the queue above this threshold will continue the Journey execution strategy.",
  VOICE_SETTINGS_TIMEOUT_HELP:
    "How long to ring an agent until the agent is considered unavailable.",
  WEIGHT: "Weight",
  VOICE_SETTINGS_WEIGHT_HELP:
    "Sets priority of this queue when its agents are members of multiple queues. A higher weight value means this queue will get priority over available agents.",
  AUTOPAUSE: "Autopause",
  VOICE_SETTINGS_AUTOPAUSE_HELP:
    "Autopause will pause a queue member if they fail to answer a call.",
  ANNOUNCE: "Announce",
  VOICE_SETTINGS_ANNOUNCE_HELP:
    "Announcement to be played to the answering agent before the call is connected, typically to announce to the agent which queue is calling.",
  ANNOUNCE_FREQUENCY: "Announce frequency",
  VOICE_SETTINGS_ANNOUNCE_FREQUENCY:
    "How often in seconds to announce the caller's position in queue, or the holdtime estimate. Set 0 to disable.",
  MIN_ANNOUNCE_FREQUENCY: "Minimum announce frequency",
  VOICE_SETTINGS_MIN_ANNOUNCE_FREQUENCY_HELP:
    "The minimum time between the start of each announcement. This is useful for queues where the caller's position and/or holdtime changes rapidly to avoid constant announcements.",
  PERIODIC_ANNOUNCE_FREQUENCY: "Periodic announce frequency",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_FREQUENCY_HELP: "How often to make any periodic announcement.",
  RANDOM_PERIODIC_ANNOUNCE: "Random periodic announcements",
  VOICE_SETTINGS_RANDOM_PERIODIC_ANNOUNCE_HELP: "Play periodic announcements in random order.",
  RELATIVE_PERIODIC_ANNOUNCE: "Relative periodic announcements",
  VOICE_SETTINGS_RELATIVE_PERIODIC_ANNOUNCE_HELP:
    "If set to yes, the periodic announcement frequency will be timed from the end of each announcement rather than from the start of each announcement.",
  ANNOUNCE_HOLDTIME: "Announce holdtime",
  VOICE_SETTINGS_ANNOUNCE_HOLDTIME_HELP:
    "Playback the estimated holdtime to the caller's waiting in the queue.",
  ANNOUNCE_POSITION: "Announce position",
  VOICE_SETTINGS_ANNOUNCE_POSITION_HELP:
    'Playback the queue position to the caller\'s waiting in the queue. When using the options "limit" or "more" you also need to set the Announce position limit.',
  ANNOUNCE_POSITION_LIMIT: "Announce position limit",
  VOICE_SETTINGS_ANNOUNCE_POSITION_LIMIT_HELP:
    'Sets the limit for when to playback the caller\'s position in the queue. If "Announce position" is set to "limit" the position will only be played for those below that limit. If set to "more" it will be played for caller\'s above.',
  ANNOUNCE_POSITION_ONLY_UP: "Announce position change",
  VOICE_SETTINGS_ANNOUNCE_POSITION_ONLY_UP_HELP:
    "Only announce the caller's position if it has improved since the last announcement.",
  REPORTHOLDTIME: "Report hold time",
  VOICE_SETTINGS_REPORTHOLDTIME_HELP:
    "Report the hold time to the answering agent before the call is connected to the agent.",
  PERIODIC_ANNOUNCE: "Periodic announcements",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_HELP:
    "Select the periodic announcements and their order. Once the last announcement has been played the playback will start from the beginning.",
  ASTERISK_MUSICCLASS_DEFAULT: "Default",
  ASTERISK_MUSICCLASS_CELTIC: "Celtic",
  ASTERISK_MUSICCLASS_JAZZ: "Jazz",
  ASTERISK_MUSICCLASS_CHILLOUT: "Chillout",
  ASTERISK_MUSICCLASS_GOODBYE: "Goodbye",
  ASTERISK_MUSICCLASS_YESTERDAY: "Yesterday",
  ASTERISK_MUSICCLASS_BLUES: "Blues",
  ASTERISK_MUSICCLASS_UPBEAT: "Upbeat",
  ASTERISK_MUSICCLASS_WALTZ: "Waltz",
  ASTERISK_MUSICCLASS_CLAPS: "Claps",
  ASTERISK_MUSICCLASS_CHEERFUL: "Cheerful",
  ASTERISK_MUSICCLASS_LULLABY: "Lullaby",
  ASTERISK_MUSICCLASS_HAPPY: "Happy",
  ASTERISK_MUSICCLASS_HIDDEN: "hidden",
  ASTERISK_AUTOPAUSE_YES: "Yes",
  ASTERISK_AUTOPAUSE_NO: "No",
  ASTERISK_AUTOPAUSE_ALL: "All",
  ASTERISK_ANNOUNCE_HOLDTIME_YES: "Yes",
  ASTERISK_ANNOUNCE_HOLDTIME_NO: "No",
  ASTERISK_ANNOUNCE_HOLDTIME_ONCE: "Once",
  ASTERISK_ANNOUNCE_POSITION_YES: "Yes",
  ASTERISK_ANNOUNCE_POSITION_NO: "No",
  ASTERISK_ANNOUNCE_POSITION_LIMIT: "Limit",
  ASTERISK_ANNOUNCE_POSITION_MORE: "More",
  RECORDING: "Recording",
  ERROR_CHILD_HAS_ASTERISK:
    "One or more sub organizations has connection to asterisk. Parent organization to such sub organizations may not be connected to a asterisk.",
  ERROR_PARENT_HAS_ASTERISK:
    "Forbidden to select asterisk when parent organization has connection to asterisk.",
  ERROR_ORGANIZATION_INACTIVATE_WITH_ACTIVE_CHILD:
    "Organization with active sub-organizations may not be inactivated. Make sure to inactivate all sub-organizations before proceeding with this action.",
  VOICE_VOICEMAIL: "Voicemail",
  VOICE_VOICEMAILS: "Voicemails",
  VOICE_VOICEMAIL_ID: "Voicemail",
  TIMES_TO_RETRY: "Times to retry",
  MAX_DIGITS: "Max digits",
  ASTERISK_SOUND_ID: "Sound",
  GOT_DIGITS: "Got digits",
  FAILED: "Failed",
  CHANSPY: "Spy",
  CHANSPIED_ON: "Spied on",
  VOICE_LICENSE: "Voice license",
  LOGIN_PAUSE: "Login pause",
  AUTO_ANSWER: "Auto answer",
  VOICE_SETTINGS_CHANSPY_HELP: 'Allow spy on other user where "Spied on" is activated',
  VOICE_SETTINGS_CHANSPIED_ON_HELP: "Allow users with spy permission to spy on this user",
  TWO_FACTOR_AUTH_HELP:
    "Leave disabled to read organization 2fa settings, enable to override the settings",
  TWO_FACTOR_AUTH_ENABLE_HELP:
    "Enabled/disable 2fa for this user and override organization settings",
  DELAY: "Delay",
  LEAVE_EMPTY_FOR_ALL_QUEUES: "Leave empty for all queues",
  AUTO_ACCEPT: "Auto accept",
  ENABLE_TO_SELECT_SPECIFIC_QUEUES: "Enable to accept specific queues",
  AUTO_ACCEPT_HELP: "Automatically accept received notifications",
  ADD_CALL_TO_CONVERSATION: "Add call to conversation",
  CALL_SEARCH: "Type number or name",
  SELECT_VOICE_QUEUE_ROUTE: "Select queue & route",
  SOMETHING_WENT_WRONG: "Something went wrong",
  SPY: "Spy",
  NO_CONTACT_SUGGESTIONS_WERE_FOUND: "No contacts were found to suggest!",
  MAIL_SETTINGS: "Mail settings",
  REPLY_TO: "Reply to",
  REPLIED_TO: "Replied to",
  NOTE: "Note",
  ANSWER: "Answer",
  CALL_RECORDING: "Call recording",
  OUTBOUND_CALL: "Outbound call",
  LEAVE_DISABLED_FOR_DEFAULT: "Leave disabled for default",
  SELECT_NUMBER: "Select number",
  RINGING: "Ringing",
  STOP: "Stop",
  STOP_RECORDING: "Stop recording",
  DOWNLOAD: "Download",
  RECORDING_CONVERSATION: "Recording conversation",
  RECORDING_VOICEMAIL: "Recording voicemail",
  VOICEMAIL: "Voicemail",
  CALL_BACK: "Call back",
  WAIT_TIME: "Wait time",
  TALK_TIME: "Talk time",
  CALL_LENGTH: "Call length",
  MISSED_CALL: "Missed call",
  LOCAL_PHONE_NUMBER: "Local phone number",
  PHONE: "Phone",
  PHONE_NUMBER: "Phone number",
  NOT_ASSIGNED: "Not assigned",
  CONVERSATION_ALREADY_ASSIGNED: "Conversation already assigned!",
  OUTBOUND_VOICE_QUEUE_ID: "Outbound queue",
  OUTBOUND_VOICE_QUEUE_ROUTE_ID: "Outbound route",
  CONVERSATION_MERGE_FROM: "This conversation has been merged into conversation {{conversation}}",
  CONVERSATION_MERGE_TO: " was merged into this conversation {{conversation}}",
  ON_HOLD: "On hold",
  LISTENED_IN: "Listened in",
  LISTEN_ONLY: "Listen only",
  LISTEN_AND_TALK_TO_AGENT: "Listen & talk to agent",
  SELECT_AN_OPTION: "Select an option",
  ONGOING_CALL: "Ongoing call",
  INTERNAL_NOTE: "Internal note",
  VIEW_DETAILS: "View details",
  CONTAINS_TRANSFER_FROM: "Contains transfer from",
  TRANSFERRED_TO: "Transferred to",
  HAS_NOTE: "Has note",
  NOTE_TO: "Note to",
  REPLIED_BY: "Replied by",
  SELECT_QUEUE_TO_SELECT_CONTACT: "Queue has to be selected before choosing contact!",
  CONTACT_NOT_AUTOMATICALLY_ASSIGNED: "A contact could not be automatically assigned!",
  NEW_MAIL_MESSAGE: "New mail message",
  UNABLE_TO_PERSONIFY:
    "Unable to personify this account. This is probably due to your permissions.",
  USER_CREATE_USERNAME_TAKEN:
    "The username you are trying to use is already taken! Be aware that this username could belong to another organization!",
  USER_CREATE_EMAIL_TAKEN:
    "The email you are trying to use is already taken! Be aware that this email could belong to another organization!",
  USER_UPDATE_EMAIL_TAKEN:
    "The email you are trying to use is already taken! Be aware that this email could belong to another organization!",
  SOCKET_DISCONNECTED_USER_ACTIVE_NOT:
    "An admin has inactivated your account. You will be signed out!",
  MAY_NOT_DELETE_YOURSELF: "You may not delete yourself!",
  NO_DATA_CREATED_YET: "No data created yet!",
  NO_DATA_MATCHING_YOUR_FILTERS: "No data found matching your filters!",
  RESET_FILTERS: "Reset filters",
  FORM_PREVIEW_NOT_EDITABLE: "Preview only! This property may not be changed.",
  AGENTPROFILE_AGENTS: "Agent profile agents",
  ADMINPROFILE_USERS: "Admin profile users",
  QUEUE_AGENTS: "Queue agents",
  NO_USERS_WERE_FOUND: "No users were found",
  NO_AGENTS_WERE_FOUND: "No agents were found",
  DRAFT_FROM_MAIL_ACCOUNT_REQUIRED: "From mail account is required",
  DRAFT_TO_MAIL_ACCOUNT_REQUIRED: "To mail address is required",
  MAIL_MESSAGE_BODY_REQUIRED_ON_OUTBOUND: "Mail body is required!",
  SMTP_ERROR: "Error while sending email",
  RESEND_EMAIL: "Resend email",
  RESEND_INITIATED: "Resend initiated",
  MAIL_MESSAGE_NOT_FAILED: "Resend only available for failed mail messages",
  NO_SUBJECT: "No subject",
  NO_CUSTOM_FILTERS_CREATED: "No custom filters created",
  DRAFT_SEND_CONVERSATION_QUEUE_REQUIRED:
    "Queue must be selected on conversation before sending drafts",
  MAIL_ACCOUNT_NOT_IN_queueS:
    "Selected mail account is not available in queue outbound mail accounts",
  MAIL_ACCOUNT_REQUIRED: "Mail account is required",
  UNANSWERED_CALL: "Unanswered call",
  SELECT_OR_CREATE_CONTACT: "Select or create contact",
  ROUTING: "Routing",
  TRUNK_TYPE_CONNECTEL: "Connectel default",
  TRUNK_TYPE_CUSTOM: "Custom trunk",
  MAIL_ACCOUNT_AUTHENTICATION_FAILED: "Mail account authentication failed",
  AUDIO_NOT_PLAYABLE: "Audio file not playable",
  SIP_STATE: "SIP state",
  SIP_STATE_UNDEFINED: "Not initiated",
  SIP_STATE_INITIAL: "Connecting",
  SIP_STATE_UNREGISTERED: "Unregistered",
  SIP_STATE_TERMINATED: "Terminated",
  SIP_STATE_REGISTERED: "Registered",
  ACTIVE_IN_QUEUE: "Currently active in queue",
  FILENAME: "File",
  PRESSED_NONE_VALID: "Pressed invalid option",
  PRESSED_1: "Pressed 1",
  PRESSED_2: "Pressed 2",
  PRESSED_3: "Pressed 3",
  PRESSED_4: "Pressed 4",
  PRESSED_5: "Pressed 5",
  PRESSED_6: "Pressed 6",
  PRESSED_7: "Pressed 7",
  PRESSED_8: "Pressed 8",
  PRESSED_9: "Pressed 9",
  "PRESSED_*": "Pressed *",
  "PRESSED_#": "Pressed #",
  INITIATED: "Initiated",
  RESET_2FA: "Reset two way authentication",
  PASSWORD_RESET_SUCCESSFUL: "Password reset successful!",
  PASSWORD_RESET_REQUEST_SUCCESSFUL: "Password reset request successful!",
  PASSWORD_INVALID: "Password must be at least 6 characters long",
  FILL_PASSWORD_TO_RESET: "Fill password to reset",
  RESET_TOKEN_HAS_EXPIRED: "Invalid or expired token. Please re-request password reset.",
  VOICE_TIMELINE_ERROR: "Error",
  INBOUND_CALL_INITIATED: "Inbound call initiated",
  OUTBOUND_CALL_INITIATED: "Outbound call initiated",
  INTERNAL_CALL_INITIATED: "Internal call initiated",
  SYSTEM_ANSWER: "System received call",
  TIMELINE_JOURNEY_JOB: "Journey action",
  RULE_WITH_INDEX_X_MATCHED: 'Rule with index "{{i}}" was matched',
  TRY_X_BUTTON_X_PRESSED: 'Try {{try}}: Button "{{num}}" pressed',
  TRY_X_DIGITS_X_PRESSED: 'Try {{try}}: Digits "{{num}}" pressed',
  USER_X_PINGED: 'Agent "{{user}}" pinged',
  USER_X_NOTIFICATION_END: 'Agent "{{user}}" ping ended',
  NOTIFICATION_X_ACTION: "Action: {{action}}",
  TIMEOUT_X_SECONDS: "Timeout: {{sec}} seconds",
  NOTIFICATION_TIMEOUT_X_SECONDS: "Notification timeout: {{sec}} seconds",
  VOICE_QUEUE_ASSIGNED: "Assigned to voice queue",
  VOICE_QUEUE_JOINED: "Joined into voice queue",
  VOICE_QUEUE_DONE: "Exited voice queue",
  NOTIFICATION_ACTION_ACCEPT: "Accepted",
  NOTIFICATION_ACTION_ACCEPT_FAIL: "Accept failed",
  NOTIFICATION_ACTION_REJECT: "Rejected",
  NOTIFICATION_ACTION_REJECT_FAIL: "Rejected",
  NOTIFICATION_ACTION_TIMEOUT: "Timeout",
  NOTIFICATION_ACTION_ANSWERED_ELSEWHERE: "Answered elsewhere",
  NOTIFICATION_ACTION_SYSTEM_HANGED_UP: "System hanged up (timeout)",
  NOTIFICATION_ACTION_CONTACT_HANGED_UP: "Contact hanged up before timeout/answer",
  RECORDING_STARTED_BY_X: 'Recording started by "{{user}}"',
  RECORDING_STOPPED_BY_X: 'Recording stopped by "{{user}}"',
  SYSTEM: "System",
  X_LEFT_CALL: "{{leg}} left the call",
  LEFT_CALL_WITH_REASON: 'Left bridge with reason "{{reason}}"',
  LEFT_QUEUE_WITH_REASON: 'Left queue "{{queue}}" with reason "{{reason}}"',
  UNKNOWN: "Unknown",
  USER_ANSWERED: '"{{user}}" answered',
  QUEUE_X_WITH_TIMEOUT: 'Queue "{{queue}}", with {{timeout}} seconds timeout',
  AVAILABLE_VOICE_MENU_OPTIONS: 'Available options "{{options}}", and "{{retries}}" retries',
  CALL_TRANSFERRED_OUT: "Call transferred out",
  CALL_TRANSFERRED_IN: "Call transferred in",
  ATTENDED: "Attended",
  BLIND: "Blind",
  TRANSFER_TYPE_X: 'Transfer type "{{type}}"',
  ROUTE_X: 'Route "{{route}}"',
  CONTACT_ANSWERED: "Contact answered",
  BG_COLOR: "Background color",
  SUB_GROUP: "Sub group",
  VARIABLE: "Variable",
  STATIC: "Static",
  KEY: "Key",
  ESCAPE_DIGITS: "Escape digit",
  LANGUAGE: "Language",
  LANGUAGE_SE: "se (Swedish)",
  LANGUAGE_FI: "fi (Finnish)",
  LANGUAGE_EN: "en (English)",
  LANGUAGE_DE: "de (German)",
  LANGUAGE_FR: "fr (French)",
  LANGUAGE_HI: "hi (Hindi)",
  LANGUAGE_IT: "it (Italian)",
  LANGUAGE_NO: "no (Norwegian)",
  LANGUAGE_NL: "nl (Dutch)",
  LANGUAGE_DK: "dk (Danish)",
  LANGUAGE_PL: "pl (Polish)",
  VOICE_LANGUAGE_SE: "se (Swedish)",
  VOICE_LANGUAGE_FI: "fi (Finnish)",
  VOICE_LANGUAGE_EN: "en (English)",
  VOICE_LANGUAGE_DE: "de (German)",
  VOICE_LANGUAGE_FR: "fr (French)",
  VOICE_LANGUAGE_HI: "hi (Hindi)",
  VOICE_LANGUAGE_IT: "it (Italian)",
  VOICE_LANGUAGE_NO: "no (Norwegian)",
  VOICE_LANGUAGE_NL: "nl (Dutch)",
  VOICE_LANGUAGE_DK: "dk (Danish)",
  VOICE_LANGUAGE_PL: "pl (Polish)",
  VOICE_SHORTCUTS: "Voice shortcuts",
  VOICE_SHORTCUT: "Voice shortcut",
  __VOICE_SHORTCUTS: "Shortcuts (V)",
  HEADER_FILTERS_COUNT: "Reset {{count}} filters",
  REAUTHENTICATE_ACCOUNT: "Re-authenticate",
  IS_LIVE: "Live",
  IS_SHARED: "Shared",
  SHARED: "Shared",
  WITH: "With",
  ADMINPROFILE_NO_FILTER_ON_CREATION:
    "Adminprofile permission may not have any filter on creation. Create the adminprofile without any filters, and add filters while editing!",
  ADMINPROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Adminprofile filter must contain itself on allow permissions!",
  SUPERADMIN_PROFILE_NO_FILTER_ON_CREATION:
    "Superadmin profile permission may not have any filter on creation. Create the superadmin profile without any filters, and add filters while editing!",
  SUPERADMIN_PROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Superadmin profile filter must contain itself on allow permissions!",
  AUDIO_SETTINGS: "Audio settings",
  SELECT_RINGTONE_SPEAKER: "Select ringtone speaker",
  RINGTONE_SPEAKER: "Ringtone speaker",
  SELECT_VOICE_SPEAKER: "Select voice speaker",
  VOICE_SPEAKER: "Voice speaker",
  SELECT_MICROPHONE: "Select microphone",
  ERROR_SETTING_SPEAKER:
    "An error occurred when setting your speaker device, please select another speaker device before continuing!",
  ERROR_SETTING_RINGTONE_SPEAKER:
    "An error occurred when setting your ringtone speaker device, please select another speaker device before continuing!",
  NO_SPEAKERS_FOUND: "No speakers found!",
  NO_MICROPHONES_FOUND: "No microphones found!",
  SELECTED_RINGTONE_SPEAKER_NOT_FOUND:
    'Selected ringtone speaker not found! We changed your ringtone speaker to "system default" until you make a change!',
  SELECTED_SPEAKER_NOT_FOUND:
    'Selected speaker not found! We changed your speaker to "system default" until you make a change!',
  SELECTED_MICROPHONE_NOT_FOUND:
    'Selected microphone not found! We changed your microphone to "system default" until you make a change!',
  DEFAULT_MICROPHONE: "Default microphone",
  DEFAULT_SPEAKER: "Default speaker",
  BROWSER_DOES_NOT_SUPPORT_SPEAKER_SET: "Your browser does not support choosing speaker device",
  UPLOAD_RECORDING: "Upload recording",
  RESTART: "Restart",
  RECORD_VOICE: "Record voice",
  RECORDING_UPLOAD_SUCCESSFULL: "Upload successfull",
  RECORDING_UPLOAD_ERROR: "An error occurred while uploading",
  WE_COULD_NOT_DETECT_SOUND_FROM_MICROPHONE:
    "We could not detect sound from your microphone device",
  ECHO_TEST: "Device test",
  START: "Start",
  START_TEST: "Start test",
  STOP_TEST: "Stop test",
  ECHO_TEST_SOUND_QUESTION: "Do you hear the sound?",
  ECHO_TEST_MICROPHONE_QUESTION: "Do you hear the echo?",
  ECHO_TEST_PASSED: "Great. Your speaker and microphone seem to work as expected!",
  PLEASE_CONFIRM_FOLLOWING: "Please confirm following",
  PERIOD: "Period",
  TREND: "Trend",
  ADJUST: "Adjust",
  TEXT_INDICATOR: "Indicator",
  METRICS: "Metrics",
  GROUP_BY: "Group by",
  SUB_GROUP_BY: "Sub group by",
  SORT_BY: "Sort by",
  DESC: "Descending",
  SPLIT_BY: "Split by",
  SUMMARY: "Summary",
  MAIN_CHANNELS: "Main channels",
  THREAD_CHANNELS: "Thread channels",
  MESSAGE_CHANNELS: "Message channels",
  ORGANIZATION_IDS: "Organizations",
  CONVERSATION_USER_IDS: "Conversation users",
  THREAD_USER_IDS: "Thread users",
  MESSAGE_USER_IDS: "Message users",
  CONVERSATION_QUEUE_IDS: "Conversation queues",
  THREAD_QUEUE_IDS: "Thread queues",
  MESSAGE_QUEUE_IDS: "Message queues",
  DISPOSITION_IDS: "Dispositions",
  SUB_DISPOSITION_IDS: "Sub dispositions",
  THRD_DISPOSITION_IDS: "Third dispositions",
  PAUSE_IDS: "Pauses",
  TEAM_IDS: "Teams",
  THREAD_TEAM_IDS: "Thread teams",
  MESSAGE_TEAM_IDS: "Message teams",
  MAIL_ACCOUNT_IDS: "Mail accounts",
  VOICE_ROUTE_IDS: "Voice routes",
  UI: "Interface",
  TABLE: "Table",
  GROUP: "Group",
  ODD_ROW: "Odd row",
  EVEN_ROW: "Even row",
  WIDTH: "Width",
  COLOR: "Color",
  BORDER: "Border",
  DISPLAY_HEADER: "Display header",
  LEGEND: "Legend",
  PLACEMENT: "Placement",
  CUTOUT: "Cutout",
  NESTED_METRICS: "Metrics",
  SUBTRACT: "Subtract",
  METRIC: "Metric",
  VALUE_ARR: "Options",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DATA: "Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFDATA: "Difference",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFPERCENTAGE: "Percentage",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROW: "Arrow",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDATA: "Arrow + Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFDATA: "Arrow + Difference",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFPERCENTAGE: "Arrow + Percentage",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICON: "Icon",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDATA: "Icon + Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFDATA: "Icon + Difference",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFPERCENTAGE: "Icon + Percentage",
  STATS_WIDGET_TABLE_TREND_INDICATOR_BACKGROUND: "Background",
  STATS_WIDGET_TYPE_TABLE: "Table",
  STATS_WIDGET_TYPE_GRID: "Grid",
  STATS_WIDGET_TYPE_BAR: "Bar",
  STATS_WIDGET_TYPE_LINE: "Line",
  STATS_WIDGET_TYPE_TEXT: "Text",
  STATS_WIDGET_TYPE_PIE: "Pie",
  STATS_WIDGET_TYPE_RADAR: "Radar",
  STATS_WIDGET_TYPE_POLAR: "Polar",
  STATS_WIDGET_TYPE_TIMELINE: "Timeline",
  STATS_WIDGET_PERIOD_THIS_MONTH: "This month",
  STATS_WIDGET_PERIOD_LIVE_TODAY: "Today (Live)",
  STATS_WIDGET_PERIOD_TODAY: "Today",
  STATS_WIDGET_PERIOD_LAST_24_HOURS: "Last 24 hours",
  STATS_WIDGET_PERIOD_YESTERDAY: "Yesterday",
  STATS_WIDGET_PERIOD_THIS_WEEK: "This week",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN: "This week (start sunday)",
  STATS_WIDGET_PERIOD_THIS_WEEK_EXCEPT_TODAY: "This week (except today)",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN_EXCEPT_TODAY: "This week (start sunday, except today)",
  STATS_WIDGET_PERIOD_LAST_WEEK: "Last week",
  STATS_WIDGET_PERIOD_LAST_7_DAYS: "Last 7 days",
  STATS_WIDGET_PERIOD_THIS_MONTH_EXCEPT_TODAY: "This month (except today)",
  STATS_WIDGET_PERIOD_LAST_30_DAYS: "Last 30 days",
  STATS_WIDGET_PERIOD_LAST_MONTH: "Last month",
  STATS_WIDGET_PERIOD_CUSTOM: "Custom",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_DAY: "Day(s)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_WEEK: "Week(s)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_MONTH: "Month(s)",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GT: "Greater than",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GTE: "Greater than, or equal to",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LT: "Less than",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LTE: "Less than, or equal to",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NOT_EQUAL_NUM: "Not equal to",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_EQUAL_NUM: "Equal to",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_ANY_OF_NUM: "Any of selected options",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NONE_OF_NUM: "None of selected options",

  STATS_WIDGET_THRESHOLD_COMPARISON_GT: "Greater than",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT_TREND: "Greater than trend value",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE: "Greater than, or equal to",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE_TREND: "Greater than, or equal to trend value",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT: "Less than",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT_TREND: "Less than trend value",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE: "Less than, or equal to",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE_TREND: "Less than, or equal to trend value",
  STATS_WIDGET_THRESHOLD_COMPARISON_NOT_EQUAL_NUM: "Not equal to",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM: "Equal to",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM_TREND: "Equal to trend value",
  STATS_WIDGET_THRESHOLD_COMPARISON_ANY_OF_NUM: "Any of selected options",
  STATS_WIDGET_THRESHOLD_COMPARISON_NONE_OF_NUM: "None of selected options",
  METRIC_FORMAT_STATIC: "Static value",
  METRIC_FORMAT_ValuePCS: "Pcs (Pieces)",
  METRIC_FORMAT_ValuePercent: "% (Percent)",
  METRIC_FORMAT_ValueDecimal: "Decimal",
  METRIC_FORMAT_SECONDS_TO_HH_MM: "HH:mm",
  METRIC_FORMAT_HH_MM: "HH:mm",
  METRIC_FORMAT_SECONDS_TO_HH_MM_SS: "HH:mm:ss",
  METRIC_FORMAT_SECONDS_TO_MM_SS: "mm:ss",
  METRIC_FORMAT_HH_MM_SS: "HH:mm:ss",
  METRIC_FORMAT_COUNTDOWN: "Counter",
  METRIC_FORMAT_MM_SS: "mm:ss",
  METRIC_FORMAT_YEAR_MONTH_SHORT: "Year Month (short)",
  METRIC_FORMAT_YEAR_MONTH_LONG: "Year Month",
  METRIC_FORMAT_MONTH_SHORT: "Month (short)",
  METRIC_FORMAT_MONTH_LONG: "Month",
  METRIC_FORMAT_YEAR_WEEK_NUMBER: "Year Week number",
  METRIC_FORMAT_WEEK_NUMBER: "Week number",
  METRIC_FORMAT_D_SLASH_M: "Day/Month",
  METRIC_FORMAT_D_SLASH_M_HH_mm: "Day/Month HH:mm",
  METRIC_FORMAT_YYYYMMDD: "YYYYMMDD",
  METRIC_FORMAT_YYYY_MM_DD: "YYYY/MM/DD",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm: "YYYY/MM/DD HH:mm",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm_ss: "YYYY/MM/DD HH:mm:ss",
  METRIC_FORMAT_HOUR_OF_DAY_24H: "Hour of day (24H)",
  METRIC_FORMAT_HOUR_OF_DAY_12H: "Hour of day (12H)",
  METRIC_FORMAT_WEEKDAY_SHORT: "Weekday (short)",
  METRIC_FORMAT_WEEKDAY_LONG: "Weekday",
  METRIC_FORMAT_HH_mm: "HH:mm",
  METRIC_FORMAT_mm_ss: "mm:ss",
  METRIC_FORMAT_HH_mm_ss: "HH:mm:ss",
  W_WEEK_SHORT: "W",
  PM: "p.m.",
  AM: "a.m.",
  MONTH_SHORT_1: "Jan",
  MONTH_SHORT_2: "Feb",
  MONTH_SHORT_3: "Mar",
  MONTH_SHORT_4: "Apr",
  MONTH_SHORT_5: "May",
  MONTH_SHORT_6: "Jun",
  MONTH_SHORT_7: "Jul",
  MONTH_SHORT_8: "Aug",
  MONTH_SHORT_9: "Sep",
  MONTH_SHORT_10: "Oct",
  MONTH_SHORT_11: "Nov",
  MONTH_SHORT_12: "Dec",
  MONTH_LONG_1: "January",
  MONTH_LONG_2: "February",
  MONTH_LONG_3: "March",
  MONTH_LONG_4: "April",
  MONTH_LONG_5: "May",
  MONTH_LONG_6: "June",
  MONTH_LONG_7: "July",
  MONTH_LONG_8: "August",
  MONTH_LONG_9: "September",
  MONTH_LONG_10: "October",
  MONTH_LONG_11: "November",
  MONTH_LONG_12: "December",
  WEEKDAY_SHORT_6: "Sun",
  WEEKDAY_SHORT_0: "Mon",
  WEEKDAY_SHORT_1: "Tue",
  WEEKDAY_SHORT_2: "Wed",
  WEEKDAY_SHORT_3: "Thu",
  WEEKDAY_SHORT_4: "Fri",
  WEEKDAY_SHORT_5: "Sat",
  WEEKDAY_LONG_6: "Sunday",
  WEEKDAY_LONG_0: "Monday",
  WEEKDAY_LONG_1: "Tuesday",
  WEEKDAY_LONG_2: "Wednesday",
  WEEKDAY_LONG_3: "Thursday",
  WEEKDAY_LONG_4: "Friday",
  WEEKDAY_LONG_5: "Saturday",
  ONGOING_CALLS: "Ongoing calls",
  RECORDING_NEEDS_TO_BE_UPLOADED: "Make sure to upload the recording!",
  RECORDING_STARTED: "Recording started",
  RECORDING_STOPPED: "Recording stopped",
  DUE_DATE: "Due date",
  MAIL_ACCOUNT_NOT_IN_QUEUES:
    "Selected mail account is not available as outbound mail account on current conversation queue",
  NO_EMAIL_ON_CONTACT: "There are no emails found on contact to reply to!",
  FORWARD: "Forward",
  LEFT_BRIDGE: "Bridge terminated",
  DESTINATION_PHONE: "Destination {{phone}}",
  PARAMS_PHONE: "Phone: {{phone}}",
  PARAMS_JOURNEY: "Journey: {{journey}}",
  CALLEE_ANSWERED: "Callee answered",
  SOCKET_CONNECTION_ERROR: 'Error connecting to websocket: "{{error}}"',
  CONTACT_FORM_HAS_NO_FIELDS:
    "Contact form has no fields! An admin needs to add fields to the contact form connected to the selected queue (Create list)",
  NOTIFICATION_REQUEST_NOT_SUPPORTED:
    "Your browser does not support notifications, please change your browser!",
  NOTIFICATION_REQUEST_DENIED:
    "You have denied browser notifications. Please make sure browser notification is allowed!",
  NOTIFICATION_REQUEST_CATCH:
    "Error occurred while requesting browser notification permissions! {{error}}",
  INACTIVITY_READY: "Sign out on ready status",
  INACTIVITY_PAUSE: "Sign out on pause status",
  PDF: "pdf",
  IMAGE: "image",
  STATS_WIDGET: "Widget",
  ADVANCED_OPTIONS: "Advanced options",
  HIDE: "Hide",
  NO_SPECIFIC_USER: "No user",
  NO_SPECIFIC_QUEUE: "No queue",
  NO_SPECIFIC_DISPOSITION: "No disposition",
  NO_SPECIFIC_PAUSE: "No pause",
  NO_SPECIFIC_DIRECTION: "No direction",
  NO_SPECIFIC_CHANNEL: "No channel",
  NO_SPECIFIC_ORGANIZATION: "No organization",
  NO_SPECIFIC_CLIENT: "No Client",
  NO_SPECIFIC_ACCOUNT: "No account",
  USER_ID_X: "User id {{x}}",
  QUEUE_ID_X: "Queue id {{x}}",
  DISPOSITION_ID_X: "Disposition id {{x}}",
  ORGANIZATION_ID_X: "Organization id {{x}}",
  CLIENT_ID_X: "Client id {{x}}",
  ACCOUNT_ID_X: "Account id {{x}}",
  PAUSE_ID_X: "Pause id {{x}}",
  ALIGNMENT: "Alignment",
  THRESHOLDS: "Thresholds",
  BOX_COLOR: "Box color",
  FONT_STYLE: "Font style",
  FORMAT: "Format",
  USER_SELF: "Personified data",
  FONT_STYLE_ITALIC: "Italic",
  FONT_STYLE_BOLD: "Bold",
  "FONT_STYLE_BOLD-ITALIC": "Bold-Italic",
  ALIGNMENT_CENTER: "Center",
  ALIGNMENT_LEFT: "Left",
  ALIGNMENT_RIGHT: "Right",
  BLINK: "Blink",
  DECIMALS: "Decimals",
  DIRECTION: "Direction",
  THREAD_DIRECTION: "Thread direction",
  MESSAGE_DIRECTION: "Message direction",
  CONVERSATION_MAIN_CHANNELS: "Conversation main channels",
  CONVERSATION_DIRECTION: "Conversation directions",
  EDIT_ITEM: "Edit item",
  OF_TOTAL: "Of total",
  GROUP_POPULAR: "Popular",
  GROUP_OTHERS: "Others",
  GROUP_CUSTOM: "Custom",
  GROUP_LIVE: "Live",
  STATS_CUSTOM_METRICS: "Custom metrics",
  STATS_CUSTOM_METRIC: "Custom metric",
  METRIC_QUERY_TYPE_SUM: "Addition",
  METRIC_QUERY_TYPE_DIFF: "Subtraction",
  METRIC_QUERY_TYPE_PERCENT: "Percent",
  METRIC_QUERY_TYPE_AVG: "Average",
  METRIC_QUERY_TYPE_MAX: "Maximum",
  METRIC_QUERY_TYPE_MIN: "Mininum",
  METRIC_QUERY_TYPE_LEAST: "Least",
  METRIC_QUERY_TYPE_GREATEST: "Greatest",
  METRIC_QUERY_TYPE_STATIC: "Static",
  METRIC_QUERY_TYPE_ADD: "Addition",
  QUERY_TYPE: "Query type",
  DASHBOARDS: "Dashboards",
  DASHBOARD: "Dashboard",
  REPORTS: "Reports",
  REPORT: "Report",
  CREATE_DASHBOARD: "Create dashboard",
  CREATE_REPORT: "Create report",
  EDIT_DASHBOARD: "Edit dashboard",
  EDIT_REPORT: "Edit report",
  ALL_DASHBOARDS: "All dashboards",
  SHARED_WITH_ME: "Shared with me",
  PREBUILD_DASHBOARDS: "Pre-built dashboards",
  ALL_REPORTS: "All reports",
  PREBUILD_REPORTS: "Pre-built reports",
  ADD_YOUR_FIRST_DASHBOARD: "Add your first dashboard",
  ADD_YOUR_FIRST_DASHBOARD_DESCRIPTION:
    "Start off with a fresh new canvas or select one of our pre-build dashboards",
  NO_SHARED_DASHBOARDS: "No shared dashboards",
  ADD_YOUR_PREBUILT_DASHBOARD: "Add pre-built dashboard",
  ADD_YOUR_PREBUILT_DASHBOARD_DESCRIPTION: "Create your own dashboard from a pre-built dashboard",
  ADD_YOUR_FIRST_REPORT: "Add your first report",
  ADD_YOUR_FIRST_REPORT_DESCRIPTION:
    "Start off with a fresh new canvas or select one of our pre-build reports",
  NO_SHARED_REPORTS: "No shared reports",
  ADD_YOUR_PREBUILT_REPORT: "Add pre-built report",
  ADD_YOUR_PREBUILT_REPORT_DESCRIPTION: "Create your own report from a pre-built report",
  NO_DASHBOARDS: "No dashboards",
  NO_REPORTS: "No reports",
  CREATE_FROM_THIS_TEMPLATE: "Create from this template",
  TIME_THRESHOLD: "Time threshold",
  AFTER: "After",
  CUSTOM_METRIC: "Custom metric",
  STATS_CUSTOM_METRIC_ID: "Custom metric",
  HEIGHT: "Height",
  STATS_USER_DATA_HELP: "Personified data will display personified values for this metric.",
  STATS_OF_TOTAL_HELP: "Percent of this metric value, compare to the total value.",
  STATS_REPORTS: "Reports & dashboards",
  STATS_WIDGETS: "Widgets",
  OWNER: "Owner",
  IS_DASHBOARD: "Is dashboard",
  STATS_REPORT: "Report / Dashbord",
  ADD_WIDGET: "Add widget",
  FULLSCREEN: "Fullscreen",
  CLONED: "Cloned",
  STATS_WIDGET_LEGEND_PLACEMENT_LEFT: "Left",
  STATS_WIDGET_LEGEND_PLACEMENT_RIGHT: "Right",
  STATS_WIDGET_LEGEND_PLACEMENT_TOP: "Top",
  STATS_WIDGET_LEGEND_PLACEMENT_BOTTOM: "Bottom",
  CIRCLE_TOTAL: "Circle total",
  TOTAL: "Total",
  BORDER_COLOR: "Border color",
  LAST_DATA_AT: "Last data at",
  HIDE_TITLE: "Hide title",
  OPEN_DASHBOARD: "Open dashboard",
  ADD_PREVIEW_WIDGET: "Add preview widget",
  SELECT_REPORT: "Select report",
  DEFAULT: "Default",
  CLONE: "Clone",
  DRAG_n_MOVE: "Drag & Move",
  DOWN: "Down",
  UP: "Up",
  EXISTING: "Existing",
  SELECT_WIDGET: "Select widget",
  ZOOM_IN: "Zoom in",
  ZOOM_OUT: "Zoom out",
  RELOAD: "Reload",
  RELOAD_DATA: "Reload data",
  DISPLAY_VALUES: "Display values",
  WIDGET_NOT_FOUND: "Widget not found!",
  WIDGET_NOT_FOUND_DESCRIPTION: "Widget has been removed or no longer shared with you!",
  NO_WIDGETS: "No widgets!",
  ADD_WIDGET_TO_REPORT: "Add widget to report",
  ADD_WIDGET_TO_DASHBOARD: "Add widget to dashboard",
  REMOVE: "Remove",
  REMOVE_ITEM: "Remove item",
  ADDITION: "Addition",
  SUBTRACTION: "Subtraction",
  DIVISION: "Division",
  CHANNELS_SETTINGS: "Channels from settings",
  DIRECTION_SETTINGS: "Direction from settings",
  THREAD_CHANNELS_SETTINGS: "Thread channels from settings",
  MESSAGE_CHANNELS_SETTINGS: "Message channels from settings",
  THREAD_DIRECTION_SETTINGS: "Thread direction from settings",
  MESSAGE_DIRECTION_SETTINGS: "Message direction from settings",
  SELECT_EXISTING: "Select existing",
  EXISTING_WIDGETS: "Existing widgets",
  NEW_WIDGET: "New widget",
  TEMPLATES: "Templates",
  SELECT_TEMPLATE: "Select template",
  USE_TEMPLATE: "Use template",
  SEARCH_TEMPLATES: "Search templates",
  SEARCH_WIDGETS: "Search widgets",
  WIDGET_TEMPLATES: "Widget templates",
  WIDGET: "Widget",
  EDIT_WIDGET: "Edit widget",
  SELECT_DASHBOARD: "Select dashboard",
  REPORT_NOT_SELECTED: "Report not selected!",
  DASHBOARD_NOT_SELECTED: "Dashboard not selected!",
  TREND_VALUE: "Trend value",
  TREND_PERIOD: "Trend period",
  MY_CONVERSATIONS: "My conversations",
  MY_AND_UNASSIGNED_CONVERSATIONS: "My & unassigned conversations",
  CUSTOMER_RESPONDED: "Customer responded",
  NO_UPDATE_IN_7_DAYS: "No update in 7 days",
  CREATED_BY_ME: "Created by me",
  ALL_UNRESOLVED: "All unresolved",
  ALL_RESOLVED: "All resolved",
  ALL_CONVERSATIONS: "All conversations",
  DIRECTIONS: "Directions",
  INTERNAL_HOST: "Internal host",
  VOICE_ASTERISK_DOWNGRADE_ID: "Downgrade asterisk",
  VOICE_ASTERISK_DOWNGRADES: "Asterisk downgrades",
  VOICE_ASTERISK_DOWNGRADE: "Asterisk downgrade",
  DOWNGRADED: "Downgraded",
  __IS_DOWNGRADED: "Is downgraded",
  VOICE_ASTERISK_OPERATIONAL_HOURS: "Asterisk operational hours",
  VOICE_ASTERISK_OPERATIONAL_HOURSES: "Asterisk operational hours",
  UTC: "UTC",
  VOICE_ASTERISK_TOGGLE_HISTORY: "Asterisk toggle history",
  VOICE_ASTERISK_TOGGLE_HISTORIES: "Asterisk toggle history",
  INITIAL: "Initial",
  DID_TOGGLE: "Did toggle",
  DATE: "Date",
  X_ONGOING_CALLS: "{{calls}} ongoing call(s)",
  X_USER_STATUSES: "{{users}} connected user(s)",
  PREVENTED_ASTERISK_TOGGLE: "prevented asterisk from toggling",
  BOOTING_UP: "Booting up",
  DOWNGRADE_ASTERISK_OFFLINE: "Downgrade asterisk offline",
  ASTERISK_STATUS_OFFLINE: "Offline",
  ASTERISK_STATUS_UPANDRUNNING: "Up & running",
  ASTERISK_STATUS_TERMINATED: "Terminated",
  ASTERISK_STATUS_BOOTING: "Booting",
  ASTERISK_STATUS_SYNCING: "Syncing",
  RESYNC: "Re-sync",
  TOGGLE_FAILED: "Toggle failed (probably OPM request)",
  CURRENCY: "Currency",
  INVOICE_CURRENCY_EUR: "EUR",
  INVOICE_CURRENCY_USD: "USD",
  INVOICE_CURRENCY_SEK: "SEK",
  INVOICE_POST_ADDON: "Invoice",
  INVOICE_POST_LICENSE: "Licence",
  INVOICE_POST_USAGE: "Voice usage",
  INVOICE_POST_ALL_LICENSE: "All channels license",
  TARIFF_ID: "Tariff",
  VOICE_TARIFF_LIST: "Voice tariff list",
  PHONENUMBER_MONTHLY_COST_ID: "Phonenumber monthly cost",
  PHONENUMBER_MONTHLY_COST_LIST: "Phonenumber monthly cost list",
  PHONENUMBER_SETUP_COST_ID: "Phonenumber setup cost",
  PHONENUMBER_SETUP_COST_LIST: "Phonenumber setup cost list",
  INVOICE_POST_VOICE_TARIFF: "Voice tariff",
  INVOICE_POST_VOICE_PHONENUMBER_MONTHLY_COST: "Phonenumber monthly cost",
  INVOICE_POST_VOICE_PHONENUMBER_SETUP_COST: "Phonenumber setup cost",
  INVOICE_POST_VOICE_LICENSE: "Voice licence",
  INVOICE_POST_VOICE_USAGE: "Voice usage",
  INVOICE_POST_EMAIL_USAGE: "E-mail usage",
  INVOICE_POST_EMAIL_LICENSE: "E-mail licence",
  INVOICE_POST_FORM_USAGE: "Form usage",
  INVOICE_POST_VOICE_NUMBERS_MONTHLY: "Voice number monthly costs",
  INVOICE_POST_VOICE_NUMBERS_SETUP: "Voice number setup costs",
  INVOICE_POST_VOICE_RECORDING: "Voice recording",
  INVOICE_POST_VOICE_RECORDING_STORAGE: "Voice recording storage",
  CUSTOMER_IDENTIFICATION_NUMBER: "Customer identification number",
  INVOICE_PERIOD_MONTHLY: "Monthly",
  INVOICE_PERIOD_EVERY2NDMONTH: "Every 2nd month",
  INVOICE_PERIOD_QUARTERLY: "Quarterly",
  INVOICE_PERIOD_HALFYEARLY: "Half yearly",
  INVOICE_PERIOD_ANNUALLY: "Annually",
  AMOUNT_TYPE: "Type",
  AMOUNT_PERCENT: "Percent",
  AMOUNT_CURRENCY: "Amount",
  INVOICE_POST_AMOUNT_TYPE_PERCENT: "Percent",
  INVOICE_POST_AMOUNT_TYPE_CURRENCY: "Amount",
  INVOICE: "Invoice",
  INVOICES: "Invoices",
  AMOUNT: "Amount",
  LOCKED_AT: "Locked",
  PAID_AT: "Paid",
  PERIOD_START: "Period start",
  PERIOD_END: "Period end",
  INVOICE_POST: "Invoice post",
  INVOICE_POSTS: "Invoice posts",
  RE_PROCESS: "Re-process",
  EXCLUDE: "Exclude",
  INVOICE_ID: "Invoice",
  TARIFF: "Tariff",
  TARIFFS: "Tariffs",
  TARIFF_PARENT: "Tariff parent",
  TARIFF_PARENTS: "Tariff parents",
  OUTBOUND_TARIFFS: "Outbound tariffs",
  INBOUND_TARIFF: "Inbound tariff",
  INTERNAL_TARIFF: "Internal tariff",
  OUTBOUND_ROUNDUP_STRATEGY: "Outbound roundup strategy",
  INBOUND_ROUNDUP_STRATEGY: "Inbound roundup strategy",
  INTERNAL_ROUNDUP_STRATEGY: "Internal roundup strategy",
  TARIFF_PARENT_ID: "Voice tariff parent",
  VOICE_TARIFF_ROUNDUP_STRATEGY_NOROUNDUP: "No roundup",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST60SECONDS: "Closest 60 seconds",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST30SECONDS: "Closest 30 seconds",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST10SECONDS: "Closest 10 seconds",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEIL15TO60: "Ceil 15-60 to 60 seconds",
  PHONENUMBER_MONTHLY_COST_PARENT_ID: "Phonenumber monthly cost parent",
  PHONENUMBER_MONTHLY_COST: "Phonenumber monthly cost",
  PHONENUMBER_MONTHLY_COSTS: "Phonenumber monthly costs",
  PHONENUMBER_MONTHLY_COST_PARENT: "Phonenumber monthly cost parent",
  PHONENUMBER_MONTHLY_COST_PARENTS: "Phonenumber monthly cost parents",
  PHONENUMBER_SETUP_COST_PARENT_ID: "Phonenumber setup cost parent",
  PHONENUMBER_SETUP_COST: "Phonenumber setup cost",
  PHONENUMBER_SETUP_COSTS: "Phonenumber setup costs",
  PHONENUMBER_SETUP_COST_PARENT: "Phonenumber setup cost parent",
  PHONENUMBER_SETUP_COST_PARENTS: "Phonenumber setup cost parents",
  INVOICE_START: "Invoicing start",
  INVOICE_END: "Invoicing end",
  ADDONS: "Addons",
  PROCESSED_AT: "Processed at",
  INVOICE_PRINT: "Invoice",
  INVOICE_PDF: "Pdf",
  MOBILE: "Mobile",
  FIXED_LINE: "Fixed line",
  SHARED_COST: "Shared cost",
  TOLL_FREE: "Toll free",
  DUE_PERIOD: "Due period",
  COUNT: "Count",
  INVOICE_DUE_PERIOD_MONTH: "Month",
  INVOICE_DUE_PERIOD_DAY: "Day",
  COMPANY_NAME: "Company name",
  COMPANY_ADDRESS: "Company address",
  COUNTRY: "Country",
  CITY: "City",
  POSTAL_CODE: "Postal code",
  ADDRESS_ROW1: "Address row 1",
  ADDRESS_ROW2: "Address row 2",
  MARK_AS_PAID: "Mark as paid",
  MARK_AS_UNPAID: "Mark as not paid",
  INVOICE_DATE: "Invoice date",
  AMOUNT_TO_PAY: "Amount to pay",
  INVOICE_PERIOD: "Invoice period",
  INBOUND: "Inbound",
  SUB_TOTAL: "Sub-total",
  VAT: "VAT",
  PCS: "pcs",
  COMPANY_EMAILS: "Company emails",
  PAYMENT_OPTION: "Payment option",
  INVOICE_PAYMENT_OPTION_BANKTRANSFER: "Bank transfer",
  INVOICE_PAYMENT_OPTION_CREDITCARD: "Credit card",
  LICENSE_COST: "License cost",
  FORM_MODULE_COST: "Form module cost",
  REPORTING: "Reporting",
  SURCHARGE: "Surcharge",
  PREPAY: "Prepay",
  COST: "Cost",
  INVOICE_POST_AMOUNT_TYPE_MINDEB: "Minimum debiting",
  FINANCE: "Finance",
  PREPAID_CREDIT: "Pre-paid credit",
  PREPAID_CREDITS: "Pre-paid credits",
  VALID_FROM: "Valid from",
  VALID_UNTIL: "Valid until",
  CREDIT_LEFT: "Credit left",
  OUTBOUND_CALL_RESTRICTED_DESCRIPTION:
    "Outbound call to destination phone was restricted by system! Contact your system administrator for access!",
  OUTBOUND_CALL_NEGATIVE_BALANCE_DESCRIPTION:
    "Outbound call has been terminated due to negative balance on your organization!",
  OUTBOUND_CALL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Outbound call has been terminated due to inactivity of the organization!",
  OUTBOUND_CALL_TOLL_FREE_NOT_SAME_ORIGIN:
    "Outbound call to the toll free destination number has been terminated as caller and callee country of origin has to be the same!",
  OUTBOUND_EMAIL_NEGATIVE_BALANCE_DESCRIPTION:
    "Outbound e-mail has been terminated due to negative balance on your organization!",
  OUTBOUND_EMAIL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Outbound e-mail has been terminated due to inactivity of the organization!",
  DESTINATION_RESTRICTOR: "Destination restrictor",
  DESTINATION_RESTRICTORS: "Destination restrictors",
  DESTINATIONS: "Destinations",
  STATS_LICENSE_TYPE_ENTRY: "Entry",
  STATS_LICENSE_TYPE_STANDARD: "Standard",
  STATS_LICENSE_TYPE_ENTERPRISE: "Enterprise",
  ALL_CHANNELS: "All channels",
  VOICE_TARIFF_ROUNDUP_STRATEGY: "Voice tariff roundup strategy",
  VOICE_OUTBOUND_USAGE: "Voice outbound usage",
  VOICE_USAGE_ROUNDUP_STRATEGY: "Voice usage roundup strategy",
  VOICE_INBOUND_USAGE: "Voice inbound usage",
  VOICE_INTERNAL_USAGE: "Voice internal usage",
  EMAIL_INBOUND_USAGE: "E-mail inbound usage",
  EMAIL_OUTBOUND_USAGE: "E-mail outbound usage",
  FORM_INBOUND_USAGE: "Form inbound usage",
  NEGATIVE_BALANCE_LOCK: "Negative balance lock",
  PREPAID: "Pre-paid",
  NONE_HANDLED_INVOICE_CANNOT_BE_LOCKED: "None handled invoice may not be locked!",
  LOCKED_INVOICES_MAY_NOT_BE_PATCHED: "Locked invoice may not be patched!",
  CREDIT_USED: "Credit used",
  INVOICE__DATA__COMPANY_EMAILS: "Invoice receiver e-mails",
  DASHBOARD_LICENSE: "Dashboard license",
  REPORTING_LICENSE: "Reporting license",
  DASHBOARD_LICENSE_TYPE: "Dashboard license type",
  REPORTING_LICENSE_TYPE: "Reporting license type",
  STANDARD: "Standard",
  ENTERPRISE: "Enterprise",
  TWINNING: "Twinning",
  ON: "On",
  QUEUE_ON: "Queue calls",
  DIRECT_ON: "Direct calls",
  TWINNED: "Twinned",
  TWINNED_TO: "Twinned to",
  RE_PROCESS_TARIFFS: "Re-process tariffs",
  RE_PROCESS_TARIFFS_NO_RESET_TARIFF_ID: "Re-process tariff costs without resetting tariff list",
  RE_PROCESS_TARIFFS_WITH_RESET_TARIFF_ID: "Re-process tariff costs and reset tariff list",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTRY: "Entry",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_STANDARD: "Standard",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTERPRISE: "Enterprise",
  INVOICE_NUMBER: "Invoice No.",
  CUSTOMER_NUMBER: "Customer No.",
  SALESPERSON: "Salesperson",
  YOUR_REFERENCE: "Your Reference",
  PAYMENT_TERMS: "Payment Terms",
  VAT_REGISTRATION_NO: "Vat Registration No.",
  FIN_CHARGE_TERMS: "Fin. Charge Terms",
  INVOICE_ROUNDING: "Invoice rounding",
  COMPANY_REFERENCE: "Company reference",
  INTERNET: "Internet",
  E_MAIL: "E-mail",
  CORPORATE_IDENTITY_NO: "Company identity no.",
  BANKGIRO: "Bankgiro",
  BIC_SWIFT: "BIC/SWIFT",
  PAYMENT_INFO_TEMPLATE: "Payment info template",
  HANDELSBANKEN_BG_SEK: "Handelsbanken bankgiro SEK",
  HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  WIZE_EUR: "Wize EUR",
  NET_AMOUNT: "Net amount",
  SUBTOTAL: "Subtotal",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_BG_SEK: "Handelsbanken Bankgiro SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  INVOICE_PAYMENT_INFO_TEMPLATE_WIZE_EUR: "Wize EUR",
  QUEUE_NOT_FOUND: "Queue not found",
  CONTACT_NOT_FOUND: "Contact not found",
  SEND_INVOICE: "Send invoice",
  GO_TO_CONVERSATION: "Go to conversation",
  MISSING_ORGANIZATION_INVOICE_DATA: "Missing organization invoice data",
  NO_ORGANIZATION_INVOICE_EMAILS: "No emails found on organization to create conversation!",
  INVOICE_ALREADY_LOCKED: "Invoice already locked",
  LOCK_INVOICE: "Lock invoice",
  REGULATION_LOCAL_ADDRESS_PROOF_REQUIRED:
    "When purchasing a telephone number, please be aware that certain regions may require you to provide proof of a valid street address within the designated area or city domain. This could involve submitting documents such as a recent invoice or business certificate displaying the corresponding address to ensure compliance with local regulations.",
  REGULATION_WORLDWIDE_ADDRESS_PROOF_REQUIRED:
    "When purchasing a telephone number, be aware that this region will require you to provide proof of a valid street address. This could involve submitting documents such as a recent invoice or business certificate displaying the corresponding address to ensure compliance with local regulations.",
  REGULATION_NATIONAL_ADDRESS_PROOF_REQUIRED:
    "When purchasing a telephone number, be aware that this region will require you to provide proof of a valid street address within the designated country. This could involve submitting documents such as a recent invoice or business certificate displaying the corresponding address to ensure compliance with local regulations.",
  PURCHASE: "Purchase",
  SETUP_COST: "Setup cost",
  MONTHLY_COST: "Monthly cost",
  REGULATIONS: "Regulations",
  PHONENUMBER_PURCHASE_STATUS_ERROR: "Error",
  PHONENUMBER_PURCHASE_STATUS_WARNING: "Warning",
  PHONENUMBER_PURCHASE_STATUS_PARTIAL: "Partial",
  PHONENUMBER_PURCHASE_STATUS_SUCCESS: "Success",
  PHONENUMBER_PURCHASE: "Phonenumber purchase",
  PHONENUMBER_PURCHASES: "Phonenumber purchases",
  PHONENUMBER_PREFIX: "Phonenumber DID group",
  PHONENUMBER_PREFIXES: "Phonenumber DID groups",
  PHONENUMBER_DID_TYPE_FIXED_LINE: "Fixed line",
  PHONENUMBER_DID_TYPE_MOBILE: "Mobile",
  PHONENUMBER_DID_TYPE_SHARED_COST: "Shared cost",
  PHONENUMBER_DID_TYPE_TOLL_FREE: "Toll-free",
  ALPHA2: "Alpha-2",
  AREA_NAME: "Area name",
  AREA_CODE: "Area code",
  STOCK: "Stock",
  PHONENUMBER_STOCK: "Phonenumber stock",
  PHONENUMBER_STOCKS: "Phonenumber stock",
  BULK_ADD_PHONENUMBERS: "Bulk add phonenumbers",
  BULK_ADD_PHONENUMBERSTOCK: "Bulk add phonenumbers to connectel stock",
  NUMBER_RANGE_START: "Number range start",
  NUMBER_RANGE_END: "Number range end",
  LIST_PHONENUMBERS: "List phonenumbers",
  ADD_PHONENUMBERS: "Add phonenumbers",
  VOICE_ROUTE_NUMBER_MAY_NOT_BE_CHANGED: "Number may not be changed!",
  EMERGENCY: "Emergency",
  ENABLED: "Enabled",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_VOXBONE: "Voxbone",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_CONNECTEL: "Connectel",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_THIRD_PARTY: "Third party",
  PROVIDER: "Provider",
  TERMINATE: "Terminate",
  TERMINATION: "Termination",
  TERMINATED_AT: "Terminated at",
  TERMINATED_BY: "Terminated by",
  TERMINATES_AT: "Terminates at",
  USER_NOT_FOUND: "User id ({{user_id}}) not found!",
  TERMINATE_WENT_WRONG: "Something went wrong while terminating phone number!",
  VOXBONE_NUMBER_NOT_CANCELLED: "Phonenumber could not be cancelled!",
  VOXBONE_DID_ID_NOT_FOUND:
    "Provider may not be changed to Voxbone as the number does not exist in Voxbone database.",
  VOICE_RECORDING: "Voice recording",
  VOICE_RECORDING_STORAGE: "Voice recording storage",
  VOICEMAIL_MESSAGE: "Voicemail message",
  VOICE_CALL_RECORDING: "Call recording",
  ADD_CUSTOM_INVOICE_POST: "Add custom invoice post",
  BY_PREFIX: "By prefix",
  PREFIX: "Prefix",
  DESTINATION_RESTRICTOR_CONFIRM:
    "Enabling additional calling destinations may lead to unintended charges and increased costs, as calls to previously blocked countries will be permitted. Ensure that you are aware of the potential financial implications before activating new destinations.",
  DISPOSITION_MAY_NOT_HAVE_MORE_THAN_3_LEVELS: "Disposition may not have more than 3 levels!",
  TO_ME: "To me",
  ASSIGN_CONVERSATION_TO_ME: "Assign conversation to me",
  VOICE_DEVICE_AND_TWINNING_SETTINGS: "Voice device & twinning settings",
  VOICE_NOTIFICATION_SOUND: "Voice notification sound",
  VOICE_NOTIFICATION_MICROPHONE: "Voice microphone on/off",
  NO_USER: "No user",
  NO_DISPOSITION: "No disposition",
  NO_SUB_DISPOSITION: "No sub disposition",
  NO_THRD_DISPOSITION: "No third disposition",
  NOT_REQUIRED: "Not required",
  RESOLVE_IN: "Resolve in",
  REPLY_AT_LATEST: "Reply at latest",
  RESOLVE_AT_LATEST: "Resolve at latest",
  CHANGELOG: "Changelog",
  SWITCH_TO_DARK_THEME: "Switch to dark theme",
  SWITCH_TO_LIGHT_THEME: "Switch to light theme",
  DISPLAY_CHANGELOG_HISTORY: "Display changelog history",
  IMPROVEMENT: "Improvement",
  BUG_FIX: "Bugfix",
  SELECT_ALL: "Select all",
  NAV_CUSTOMER_ID: "Customer navision id",
  CREATE_NEW_JOURNEY_DRAFT: "Create new journey draft",
  SHOW_JOURNEY_DRAFTS: "Show journey drafts",
  DROP_TO_ATTACH_FILE: "Drop to attach file",
  NO_PREVIEW_AVAILABLE: "No preview available for this file!",
  REPLY_TO_CC: "E-mail CC",
  REPLY_TO_BCC: "E-mail BCC",
  LEAVE_EMPTY_FOR_ALL_DAY_EVERYDAY: "Leave empty for all day, every day",
  RELEASE: "Release",
  RELEASES: "Releases",
  VERSION: "version",
  EXECUTION_AT: "Execution at",
  EXECUTED_AT: "Executed at",
  FULLFILLED: "Fullfilled",
  OUTPUT: "Output",
  CLICK_TO_PRINT_TO_CONSOLE: "Click to print to console",
  PRE_QUEUE_TIME_LONG: "Journey time (time spend in journey before entering queue or hanging up)",
  AUDIT: "Audit",
  AUDITS: "Audit",
  SYS_USER: "System",
  AUDIT_ACTION_READ: "Read",
  AUDIT_ACTION_UPDATE: "Update",
  AUDIT_ACTION_CREATE: "Create",
  AUDIT_ACTION_DELETE: "Delete",
  AUDIT_ACTION_SOFTDELETE: "Soft-Delete",
  AUDIT_SEVERITY_L: "Low",
  AUDIT_SEVERITY_M: "Medium",
  AUDIT_SEVERITY_H: "High",
  ACTION: "Action",
  SEVERITY: "Severity",
  CHANGED_PROPS: "Changed props",
  EMPTY: "Empty",
  MAIL_STATUS: "Mail status",
  VOICE_STATUS: "Voice status",
  UnAssign: "Un-assign",
  READONLY_UI: "Readonly (UI)",
  VOICE_RINGTONE: "Voice ringtone",
  NOTIFICATION_RINGTONE: "Notification ringtone",
  MAX_FILESIZE: "Max file size",
  MIMETYPES: "Mime types",
  API_KEY: "Api key",
  API_KEYS: "Api keys",
  COPY_API_KEY_TO_CLIPBOARD: "Copy api key to clipboard",
  CREATED_BY: "Created by",
  CONTACT_CONDITION: "Contact condition",
  DATA_KEY: "Field alias",
  COMPARISON: "Comparison",
  DISPLAY_QUEUE_CALLER_ID: "Display queue number",
  MERGE_CONTACT_IF_EXISTS: "Merge contact data if exists",
  CUSTOM_DATA: "Custom data",
  OVERRIDES_CURRENT: "Overrides current value",
  MERGES_AND_REMOVES_DUPLICATED: "Merges and removes duplicates",
  MERGEANDKEEPEXISTING: "Merge and keep existing",
  MERGEANDOVERRIDE: "Merge and override existing",
  CONVERSATION_OWNER_ONLY: "Conversation agents tabs only",
  INACTIVE_TABS_HIDDEN: "Inactive tabs has been hidden",
  INACTIVE_TABS_DESCRIPTION:
    "Make sure you do not have more than 7 tabs open, by closing your visible tabs one by one!",
  VIEW_EVENT: "View event",
  EVENT_ORDER: "Order",
  CLIENT: "Client",
  CLIENTS: "Clients",
  SELECT_CLIENT: "Select client",
  CLIENT_ID: "Client",
  DATA: "Data",
  NO_CLIENT: "No client",
  TRANSLATIONS: "Translations",
  TRANSLATION: "Translation",
  __SUBMISSION_DISPLAY_FIELDS: "Submission display fields",
  ADDITIONAL_QUEUES: "Additional queues",
  HELP_ADDITIONAL_QUEUES:
    "Select additional queues that are not available in current agent profile.",
  FROM_ADDITIONAL: "From additional queues",
  FORM_SETTINGS: "Form settings",
  FORM_CAPACITY: "Form capacity",
  FIXED_LINE_OR_MOBILE: "Fixed line/mobile",
  ATTACHMENTS_STORAGE: "Attachments storage",
  PARENTS: "Parents",
  ALL_QUEUES: "All queues",
  PROFILE_QUEUES: "Profile queues",
  DATA_CHANGED: "Field data changes",
  FORCE_LOGOUT: "Force logout",
  JSON_RESPONSE: "JSON response",
  JSON_PATH: "JSON path",
  JSON_PATH_HELP: "Example: data.property, data.array[0].property",
  DELETED_ITEM_REMOVE: "Deleted item needs to be removed from selected items!",
  UNASSIGN: "Un-assign",
  ALLOW_AGENT_UNASSIGN: "Allow agent un-assign",
  ASTERISK_HOURS: "Asterisk hours",
  TOGGLE_ASTERISK_HOURS: "Toggle asterisk hours",
  EXTERNAL_VOICE_RINGTONE: "External voice call ringtone",
  INTERNAL_VOICE_RINGTONE: "Internal voice call ringtone",
  LOAD_MORE: "Load more",
  JOURNEY_TIMELINE: "Journey timeline",
  TIMELINE: "Timeline",
  EMPTY_TIMELINE: "Empty timeline",
  GLOBAL_SEARCH_QUEUES: "Global search queues",
  PERMANENTLY_DELETE_REPORT: "Permanently remove report?",
  PERMANENTLY_DELETE_REPORT_DESCRIPTION:
    "All unique widgets in this report will also be removed with this action.",
  PERMANENTLY_DELETE_DASHBOARD: "Permanently remove dashboard?",
  PERMANENTLY_DELETE_DASHBOARD_DESCRIPTION:
    "All unique widgets in this dashboard will also be removed with this action.",
  DELETE_WIDGET: "Delete widget",
  DELETE_WIDGET_DESCRIPTION:
    "Widget will be permanently deleted if not occured in any other dashboards / reports.",
  CREATED_AFTER: "Created after",
  LAST_MESSAGE_BY_CONTACT: "Contact",
  LAST_MESSAGE_BY_AGENT: "Agent",
  SUBJECT_PREFIX: "Subject prefix",
  HIDE_STATS_PREVIEW: "Hide stats preview",
  DISPLAY_STATS_PREVIEW: "Display preview stats",
  SOMETHING_WENT_WRONG_CLONING_WIDGET: "Something went wrong while cloning",
  STATS_WIDGET_CUSTOM_PERIOD_TO_LONG: "Widget custom period start and end may not exceed 31 days.",
  TRANSFER_DASHBOARD: "Transfer dashboard",
  TRANSFER_REPORT: "Transfer report",
  TRANSFER_TO_REPORT_OWNER: "Transfer widget to report owner",
  OF: "of",
  WIDGET_HAS_NO_DATA: "Widget has no data",
  RULE_OTHERWISE_MATCHED: 'Rule "Otherwise" was matched',
  RULE_X: "Rule {{n}}",
  RULE_X_WAS_MATCHED: '"Rule {{n}}" was matched',
  EDIT_CONTACT: "Edit contact",
  PRECISION: "Precision",
  SKILL: "Skill",
  SKILLS: "Skills",
  SKILL_ID: "Skill",
  LEVEL_ONE: "Level one",
  LEVEL_TWO: "Level two",
  LEVEL_THREE: "Level three",
  SKILL_TIMEOUT_SETTINGS: "Skill timeout settings",
  TIMEOUT_ONE: "Timeout one",
  TIMEOUT_TWO: "Timeout two",
  TIMEOUT_THREE: "Timeout three",
  SKILL_TIMEOUT_ONE_DESCRIPTION: "User skills level two will be included after this timeout",
  SKILL_TIMEOUT_TWO_DESCRIPTION:
    "User skills level three will be included after this timeout + previous timeout",
  SKILL_TIMEOUT_THREE_DESCRIPTION:
    "All skills will be included after this timeout + previous timeouts",
  QUEUE_TIMEOUT_UNIT_SECOND: "Seconds",
  QUEUE_TIMEOUT_UNIT_MINUTE: "Minutes",
  QUEUE_TIMEOUT_UNIT_HOUR: "Hours",
  QUEUE_TIMEOUT_UNIT_DAY: "Days",
  DEFAULT_VOICE_COUNTRY: "Default voice country",
  NO_SKILL: "No skill",
  IS_WAITING: "Is waiting",
  IS_ONGOING: "Is ongoing",
  PREVIEW_PERIOD: "Preview period",
  WALLBOARD: "Wallboard",
  WALLBOARD_KEY: "Wallboard key",
  STATS_REPORT_ID: "Report / Dashboard",
  SHARE_DATA: "Share data",
  SHARE_DATA_DESCRIPTION:
    "Share data like user, queue, disposition, mail account... data with wallboard viewer. Please note that shared data points like queue, includes all organization queues and not only the ones in the widgets. Share data should therefore be turned off if you are sharing wallbord with external parts that should not have access to data point information.",
  WALLBOARD_NO_MORE_TRIES: "You have been disconnected, please refresh and try again!",
  DISPOSED: "Disposed",
  STATUS_CHANGED: "Status changed",
  ASSIGNED_TO: "Assigned to",
  USER_ASSIGNED: "User assigned",
  USER_UNASSIGNED: "User unassigned",
  UNASSIGNED_FROM: "Un-assigned from",
  QUEUE_ASSIGNED: "Queue assigned",
  TIMELINE_EVENT_TYPE_JOURNEYJOB: "Journey job",
  TIMELINE_EVENT_TYPE_VOICE: "Voice",
  TIMELINE_EVENT_TYPE_NOTIFICATION: "Notification",
  TIMELINE_EVENT_TYPE_AUDIT: "Audit",
  JOURNEY_TRIGGER_ID: "Trigger",
  TRANSCRIPT: "Transcript",
  DEFAULT_LANGUAGE: "Default language",
  VIEW_TRANSCRIPT: "View transcript",
  DISPLAY_TRANSCRIPT: "Display transcript",
  CONDITION_AND_OR_AND: "And",
  CONDITION_AND_OR_OR: "Or",
  VOICE_RECORDING_TRANSCRIPT: "Voice transcript",
  WALLBOARDS: "Wallboards",
  SLA_RESPONSE: "Response time",
  SLA_RESOLUTION: "Resolution time",
  HAS_SLA: "Has SLA",
  HAS_NOT_SLA: "Has not SLA",
  EXCEEDED: "Exceeded",
  NOT_EXCEEDED: "Not exceeded",
  TRANSCRIPT_TRIGGER: "Transcript trigger on",
  CLICK_TO_TRANSCRIPT: "Click",
  RECORDED_BY_JOURNEY: "Recordings by journey",
  MANUALLY_RECORDED: "Manual recordings",
  GENERATE_TRANSCRIPT: "Generate transcript",
  VOICE_RECORDING_TRANSCRIPT_SETTING: "Transcript settings",
  VOICE_RECORDING_TRANSCRIPT_SETTINGS: "Transcript settings",
  FIND_REPLACE: "Find & replace",
  FIND: "Find",
  REPLACE: "Replace",
  MODEL: "Model",
  VOICE_RECORDING_TRANSCRIPT_MODEL_WHISPER: "Whisper",
  VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA: "Nova",
  "VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA-2": "Nova 2",
  VOICE_RECORDING_TRANSCRIPT_MODEL_BASE: "Base",
  VOICE_RECORDING_TRANSCRIPT_MODEL_ENHANCED: "Enhanced",
  TRANSCRIPTION_ERROR: "Transcription error",
  CONFIDENCE: "Confidence",
  TRANSCRIPTION_TIME: "Transcription time",
  POINTS_SYSTEMS: "Points systems",
  POINTS_SYSTEM: "Points system",
  MAIL_MESSAGE_SENT: "E-mail message sent",
  INBOUND_VOICE_CALL: "Inbound voice call",
  OUTBOUND_VOICE_CALL: "Outbound voice call",
  INTERNAL_VOICE_CALL: "Internal voice call",
  OUTBOUND_CONVERSATION_CREATED: "Outbound conversation created",
  OUTBOUND_CONVERSATION_RESOLVED: "Outbound conversation resolved",
  INBOUND_CONVERSATION_RESOLVED: "Inbound conversation resolved",
  OUTBOUND_CONVERSATION_CLOSED: "Outbound conversation closed",
  INBOUND_CONVERSATION_CLOSED: "Inbound conversation closed",
  POINTS: "Points",
  CONVERSATION_SKILL_ID: "Skill",
  JOURNEY_TRIGGER: "Journey trigger",
  JOURNEY_TRIGGERS: "Journey triggers",
  HIGHLIGHT_IN_JOURNEY: "Highlight in journey",
  HELP_CONVERSATION_FILTER_IS_WAITING:
    '"Waiting" refers to conversations that haven\'t been answered and are still in the queue.',
  HELP_CONVERSATION_FILTER_IS_ONGOING:
    '"Ongoing" refers to conversations that are not closed or resolved and have received a reply from an agent (system auto-replies are excluded).',
  POINTS_CREATED: "Creation",
  POINTS_RESOLVED: "Resolved",
  POINTS_CLOSED: "Closed",
  POINTS_TALK: "Talk time",
  POINTS_MAIL_SENT: "Points sending e-mail",
  TREND_COMPARISON_VALUE: "Trend comparison value",
  CUSTOM_PERCENT: "Custom percentage",
  STATS_CUSTOM_PERCENT_HELP:
    "Defined a number to divide metric value to generate and display a percentage value",
  GDPR: "Security & compliance",
  GDPRS: "Security & compliances",
  PASSWORD_MAY_NOT_BE_IN_HISTORY:
    "Selected password has been used recently by this account and cannot currently be used again!",
  PASSWORD_LIFETIME_REACHED:
    "Your password has expired! Reset your password to reach the application.",
  IP_ADDRESS_NOT_WHITELISTED: "Your ip address is not whitelisted by any of your organizations!",
  PASSWORD_EXPIRY_DESCRIPTION:
    "Password lifetime expires in less than {{days_left}} days. Make sure to change your password.",
  PASSWORD_NOT_STRONG:
    "Selected password is not strong enough! Make sure you password contains at least one lowercase and uppercase letter, one special characters and one digit!",
  FORM_ERROR_PASSWORD_NOT_STRONG:
    "Must contain at least one lowercase and uppercase letter, one special characters and one digit",
  IP_WHITELIST: "IP whitelist",
  IP_WHITELIST_DESCRIPTION: "Restrict access to your oganisation by IP-address.",
  IP_ADDRESSES: "IP addresses",
  CONNECTEL_INFO_SHARE: "Use of information",
  LIFETIME: "Lifetime",
  HISTORY: "History",
  STRONG: "Strong",
  CONNECTEL_INFO_SHARE_DESCRIPTION:
    "Consent to Connectel gathering anonymized statistics and data from your organisation in order to improve our services we provide to you.",
  PASSWORD_STRONG: "Password policy",
  PASSWORD_STRONG_DESCRIPTION: "Enforce the usage of strong passwords.",
  PASSWORD_LIFETIME: "Password lifespan",
  PASSWORD_LIFETIME_DESCRIPTION:
    "Set the max number of days a password is allowed to live for until it has to be replaced.",
  PASSWORD_HISTORY: "Password history",
  PASSWORD_HISTORY_DESCRIPTION: "Restricts the usage of previously set passwords.",
  ANONYMIZATION: "Anonymization",
  AGENT_ANONYMIZATION_DESCRIPTION:
    "Anonymize the agent name in conversations and other data points upon removal.",
  EMAIL_ATTACHMENTS: "E-mail attachments",
  FORM_SUBMISSION_ATTACHMENTS: "Form attachments",
  DELETION: "Deletion",
  VOICE_RECORDINGS: "Voice recordings",
  CONTACT_ANONYMIZATION_DESCRIPTION:
    "The number of days to be retained and ignored by anonymization. This setting will only apply to inactive contacts and conversations.",
  CONTACT_DELETION_RECORDINGS_DESCRIPTION:
    "The number of days to be retained and ignored by deletion. This setting will only apply to inactive contacts and conversations.",
  CONTACT_VOICE_RECORDINGS_DESCRIPTION:
    "The number of days to be retained and ignored by deletion.",
  CONTACT_EMAIL_ATTACHMENTS_DESCRIPTION:
    "The number of days to be retained and ignored by deletion.",
  CONTACT_FORM_SUBMISSION_ATTACHMENTS_DESCRIPTION:
    "The number of days to be retained and ignored by deletion.",
  ANONYMIZED: "Anonymized",
  LOGIN_ERROR: "Login failed",
  ANONYMIZE_CONTACT_AND_CONVERSATIONS: "Anonymize contact",
  CONFIRM_ANONYMIZE: "Confirm anonymization",
  CONFIRM_ANONYMIZE_DESCRIPTION:
    "By anonymizing contact you do anonymize any related data to the contact or its conversations. This action cannot be undone!",
  ANONYMIZATION_ERROR: "Anonymization error",
  ANONYMIZE_CONTACTS: "Anonymize contacts",
  TRANSCRIPT_ANONYMIZED: "Transcript anonymized",
  OpenBrowserTab: "Open browser tab",
  MAX_INACTIVITY_READY: "Maximum inactivity for agent profiles",
  ORGANIZATION_HAS_NOT_VOICE_ASTERISK:
    "Selected organization has no voice asterisk assigned to it!",
  SIGNATURE_PREVIEW: "Signature preview",
  KEYWORDS: "Keywords",
  TAG_KEYWORDS_HELP: "Keywords are not case-sensitive.",
  AUTO_TAGS: "Automatic tags",
  VOICE_TRANSCRIPT: "Voice transcript",
  SUB_DISPOSITIONS: "Sub dispositions",
  THRD_DISPOSITIONS: "Third dispositions",
  STATS_ALARMS: "Alarms",
  STATS_ALARM: "Alarm",
  TEXT: "Text",
  AUTO_TEXT: "Auto generate text",
  STATS_ALARM_PERIOD_LIVE_TODAY: "Today (Live)",
  STATS_ALARM_PERIOD_TODAY: "Today",
  STATS_ALARM_PERIOD_LAST_24_HOURS: "Last 24 hours",
  STATS_ALARM_PERIOD_THIS_WEEK: "This week",
  STATS_ALARM_PERIOD_THIS_WEEK_SUN: "This week (start sunday)",
  STATS_ALARM_PERIOD_LAST_7_DAYS: "Last 7 days",
  STATS_ALARM_PERIOD_THIS_MONTH: "This month",
  STATS_ALARM_PERIOD_LAST_30_DAYS: "Last 30 days",
  LAST_MATCH_AT: "Last match",
  MIN_INTERVAL: "Minimum interval",
  STATS_ALARM_MIN_INTERVAL_HELP:
    "Minimal interval to wait after a notification is sent! To Avoid spamming as live metrics may often change.",
  NOTIFICATION: "Notification",
  ATLAS_NOTIFICATION: "Atlas notification",
  USER_IDS: "Users",
  HIGHLIGHTED: "Highlighted",
  MATCHED_CONDITION: "matched the condition",
  SOUND_TYPE_ELEVENLABS: "Text to voice",
  SOUND_TYPE_FILEUPLOAD: "File upload",
  ELEVENLABS_VOICE_ALICE: "Alice",
  ELEVENLABS_VOICE_ARIA: "Aria",
  ELEVENLABS_VOICE_BILL: "Bill",
  ELEVENLABS_VOICE_BRIAN: "Brian",
  ELEVENLABS_VOICE_CALLUM: "Callum",
  ELEVENLABS_VOICE_CHARLIE: "Charlie",
  ELEVENLABS_VOICE_CHARLOTTE: "Charlotte",
  ELEVENLABS_VOICE_CHRIS: "Chris",
  ELEVENLABS_VOICE_DANIEL: "Daniel",
  ELEVENLABS_VOICE_ERIC: "Eric",
  ELEVENLABS_VOICE_GEORGE: "George",
  ELEVENLABS_VOICE_JESSICA: "Jessica",
  ELEVENLABS_VOICE_LAURA: "Laura",
  ELEVENLABS_VOICE_LIAM: "Liam",
  ELEVENLABS_VOICE_LILY: "Lily",
  ELEVENLABS_VOICE_MATILDA: "Matilda",
  ELEVENLABS_VOICE_RIVER: "River",
  ELEVENLABS_VOICE_ROGER: "Roger",
  ELEVENLABS_VOICE_SARAH: "Sarah",
  ELEVENLABS_VOICE_WILL: "Will",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_CONVERSATIONAL_SWEDISH:
    "Sanna Hartfield - Swedish Conversational",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_SASSY_SWEDISH: "Sanna Hartfield - Sassy Swedish",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_NARRATION_SWEDISH: "Sanna Hartfield - Swedish Narration",
  ELEVENLABS_VOICE_JONAS: "Jonas, calm & informative Swedish voice",
  ELEVENLABS_SETTINGS: "Generator settings",
  QUEUE_SORT_LASTINBOUNDMESSAGEAT: "Last inbound message at",
  QUEUE_SORT_LASTOUTBOUNDMESSAGEAT: "Last outbound message at",
  QUEUE_SORT_UNHANDLED: "Unhandled",
  QUEUE_SORT_QUEUEJOINTIME: "Queue join time",
  SORT: "Sort by",
  EFFECTIVE_WORK_TIME: "Effective work time",
  ARTIFICIELL_INTELLIGENCE: "Artificiell intelligence",
  AI_ASSISTANTS: "Ai assistants",
  AI_ASSISTANT: "Ai assistant",
  KB_UNIQUE_ID: "KB unique id",
  AI_ASSISTANT_ID: "Ai assistant",
  NO_AI_ASSISTANT_ON_QUEUE: "No Ai assistant on this queue",
  NEW_MAIL_MESSAGE_WITH_AI: "New mail message with Ai",
  REPLY_WITH_AI: "Reply with Ai",
  AI_RESPONDING: "Ai responding...",
  AI_RESPONSE_CONFIDENCE: "Ai response confidence",
  AI_REQUEST_KB_ASSISTANT: "Ai Request (Reply Assistance)",
  AI_REQUEST_KB_ASSISTANCE: "Ai Request (Reply Assistance)",
  KbAssistant: "Reply Assistance",
  REQ: "req",
  INVOICE_POST_VOICE_RECORDING_TRANSCRIPT: "Voice transcript",
  TEXT_TO_VOICE_USAGE: "Text to voice usage",
  QUEUE_SORT_HELP:
    '"Unhandled" will sort by queue join time while in addition filter to only conversations that have not been answered.',
  FIELD_CONDITIONS: "Field conditions",
  FIELD_TO_AFFECT: "Field to take affect",
  CONDITIONAL_FIELD: "Conditional field",
  THEN: "Then",
  FORBID: "Forbid",
  MAKE_OPTIONAL: "Make optional",
  IF: "If",
  MAX: "MAX",
  MAXIMUM: "Maximum",
  HELP: "Help",
  HIDE_HELP: "Hide help",
  VALIDATION_MAXIMUM_NUMBER_HELP: "Maximum number that can be filled in this field",
  VALIDATION_MAXIMUM_LIST_HELP: "Maximum options",
  VALIDATION_MAXIMUM_TEXT_HELP: "Maximum characters",
  DEFAULT_VALUE: "Default value",
  IMPORT_FORM_SUBMISSION: "Import form submission",
  SAVE_REPLY: "Save reply",
  EDIT_REPLY: "Edit reply",
  CREATE_REPLY: "Create reply",
  HIDE_REPLY: "Hide reply",
  IMPORT_CONVERSATION_FORM: "Import conversation form",
  CUSTOM_ANSWER: "Custom",
  FIELD_CUSTOM_ANSWER_HELP:
    "End user can fill custom answer in a text field. Entered value will be set to alias {field_alias}_custom.",
  CLICK_TO_COPY: "Click to copy",
  RECONNECTS_AT: "Reconnects at",
  DOWNLOAD_EML: "Download mail message .eml",
  QUEUEASSISTANT: "Queue assistant",
  ORGANIZATIONASSISTANT: "Organization assistant",
  SWITCH_TO_AI_ASSISTANT: "Switch to AI assistant",
  SWITCH_TO_STATS: "Switch to stats",
  ARI_BASED: "Ari-based",
  RESOLVED_AFTER: "Resolved after",
  CLOSED_AFTER: "Closed after",
  AI_ASSISTANT_TYPE_ORGANIZATIONASSISTANT: "Organization assistant",
  AI_ASSISTANT_TYPE_QUEUEASSISTANT: "Queue assistant",
  USER_SETTINGS: "User settings",

  ENGLISH: "English",
  SWEDISH: "Swedish",
  DANISH: "Danish",
  NORWEGIAN: "Norwegian",
  FINNISH: "Finnish",
  GERMAN: "German",
  SHOW_MORE_FIELDS: "Show more fields",
  INVALID: "Invalid",
  TOO_SHORT: "Too short",
  TOO_LONG: "Too long",
  ERROR_QUEUE_NO_CREATE_LIST: "Queue has no create list",
  TEXT_MESSAGE: "Text message",
  TEXT_MESSAGE_SOURCE_CREATED: "Text message source created",
  TEXT_MESSAGE_SOURCE_CREATED_ERROR: "Text message source creation error",
  TEXT_MESSAGE_SOURCE: "Text message source",
  TEXT_MESSAGE_SOURCES: "Text message sources",
  ALPHA_TAG: "Alpha tag",
  SELECT_ORGANIZATION: "Select organization",
  MESSAGE: "Message",
  SELECT_SOURCE: "Select source",
  SELECT_QUEUE_WITH_CREATE_LIST: "Please select a queue with a create list to create a contact",
  BEGIN_TYPING_TO_SEE_CONTACT: "Start typing to see contacts...",
  MISSING_CONTACT: "Contact missing",
  MISSING_EMAIL: "Email missing",
  MISSING_SUBJECT: "Subject missing",
  MISSING_CONTACT_FORM: "Contact form is missing",
  VERIFIED: "Verified",
  TEXT_MESSAGE_SOURCE_UPDATED: "Text message source updated",
  TEXT_MESSAGE_SOURCE_UPDATE_FAILED: "Text message source update failed",
  SENDER_PHONE_NUMBER: "Sender phone number",
  CONVERSATION_ENUM_ID: "Conversation ID",
  CONVERSATION_ENUM_EMAIL: "Contact email",
  CONVERSATION_ENUM_STATUS: "Status",
  CONVERSATION_ENUM_CHANNEL: "Channel",
  CONVERSATION_ENUM_DIRECTION: "Direction",
  CONVERSATION_ENUM_QUEUE_NAME: "Queue name",
  CONVERSATION_ENUM_AGENT_NAME: "Agent name",
  CONVERSATION_ENUM_DISPOSITION: "Disposition",
  CONVERSATION_ENUM_CREATED_DATE: "Created date",
  CONVERSATION_ENUM_CALLER_ID: "Contact caller ID",
  CONVERSATION_ENUM_ORGANIZATION_ID: "Organization ID",
  CONVERSATION_ENUM_ORGANIZATION_TITLE: "Organization title",
  DESELECT_ALL: "Deselect all",
  CONVERSATION_DOWNLOAD: "Select fields to include in download",
  NEW_TEXT_MESSAGE: "New text message",
  SUMMARIZE_CONVERSATION: "Summarize conversation",
  GET_NEW_SUMMARY: "Get new summary",
  CONVERSATION_SUMMARY: "Conversation summary",
  OPEN_SUMMARY: "Open summary",
  CLOSE_SUMMARY: "Close summary",
  MESSAGE_SENT_SUCCESSFULLY: "Message sent successfully",
  MESSAGE_TOO_SHORT: "Message must be at least 10 characters long",
  MISSING_SOURCE: "Missing source",
  MISSING_PHONE_NUMBER: "Missing phone number",
  ERROR_SENDING_MESSAGE: "Error sending message",
  MISSING_QUEUE: "Missing queue",
};
