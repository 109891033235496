"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionClient = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const misc_1 = require("../../misc");
exports.JourneyActionClient = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.Client,
    channels: (0, misc_1.EnumValues)(enums_1.ChannelEnum).filter(c => c !== enums_1.ChannelEnum.Voice),
    settings_schema: (0, base_1.settingsValidationSchema)({
        client_id: {
            type: 'number',
        },
    }),
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_CLIENT_DONE_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_CLIENT_DONE_OUTPUT,
                index: 1,
                title: 'DONE',
            }) });
    },
});
