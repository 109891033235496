import React, { RefObject } from "react";
import {
  ISharedConversationWatcher,
  TypeConversationMessage,
  ISharedFullConversationReducer,
  IPreviewTab,
  DirectionEnum,
  ChannelEnum,
} from "atlas-shared";
import "./tab.mini.conversation.list.scss";
import { useTranslation } from "react-i18next";
import { IAuth } from "@Store";
import { AppSpinner, Avatar, ConversationDispositionNote } from "@Components";
import { THighlightedMessage, useUsers } from "@Hooks";
import { TabMiniConversationMessage } from "./tab.mini.conversation.message";
import { useConversationTextMessages } from "@Components/conversation/conversation.messages/useConversationTextMessages";

interface IProps {
  conversationId: number;
  source: ISharedConversationWatcher["source"];
  className?: string;
  conversation: ISharedFullConversationReducer;
  auth: IAuth;
  highlightedMessage: THighlightedMessage;
  scrollRef: RefObject<HTMLDivElement>;
  tab?: IPreviewTab;
}
export const TabMiniConversationList = React.memo(
  ({ className, conversation, auth, highlightedMessage, scrollRef, tab }: IProps) => {
    const { t } = useTranslation();
    const users = useUsers();
    const { textMessages } = useConversationTextMessages(conversation.id);

    const conversationMessages = conversation?.messages || [];
    const combinedConversationMessages = React.useMemo(() => {
      const allMessages = [
        ...conversationMessages,
        ...(textMessages?.map(
          (textMsg) =>
            ({
              ...textMsg,
              channel: ChannelEnum.Sms,
              createdAt: textMsg.created_at,
              id: textMsg.id,
              direction: textMsg.direction === "I" ? DirectionEnum.InBound : DirectionEnum.Outbound,
              organization_id: conversation.organization_id,
              conversation_id: conversation.id,
            } as TypeConversationMessage)
        ) || []),
      ];

      return allMessages.sort(
        (a, b) => new Date(b.created_at || "").getTime() - new Date(a.created_at || "").getTime()
      );
    }, [conversationMessages, textMessages, conversation]);

    if (!conversation) {
      return <AppSpinner />;
    }

    return (
      <div className={"tab-mini-conversation-list-view-container " + className}>
        <div className={"body"} ref={scrollRef}>
          {conversation.disposition_id && (
            <div className="row-right conversation-message-row">
              <Avatar
                size={20}
                user={conversation.user_id ? users.dict[conversation.user_id] : undefined}
              />
              <ConversationDispositionNote conversation={conversation} mini={true} />
            </div>
          )}
          {combinedConversationMessages.map((message: TypeConversationMessage) => (
            <TabMiniConversationMessage
              key={`${message.channel}${message.id}`}
              t={t}
              auth={auth}
              message={message}
              conversation={conversation}
              users={users}
              highlightedMessage={highlightedMessage}
              tab={tab}
            />
          ))}
        </div>
      </div>
    );
  }
);
