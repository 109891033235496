import React from "react";
import "./app.spinner.scss";

interface AppSpinnerProps {
  size?: "small" | "large";
}

export const AppSpinner = ({ size = "large" }: AppSpinnerProps) => {
  const style = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    padding: "10px",
    scale: size === "small" ? "0.5" : "1",
  };

  return (
    <div style={style}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default React.memo(AppSpinner);
