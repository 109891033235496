"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default_conversation_filters = exports.default_conversation_filter_sort = void 0;
const enums_1 = require("../enums");
const enum_1 = require("./enum");
exports.default_conversation_filter_sort = {
    column: enums_1.ConversationFilterSortColumnEnum.LastMessageAt,
    order: enums_1.ConversationFilterSortOrderEnum.Desc,
};
const ResolvedStatuses = [enums_1.ConversationStatusEnum.Closed, enums_1.ConversationStatusEnum.Resolved,];
const UnresolvedStatuses = (0, enum_1.EnumValues)(enums_1.ConversationStatusEnum).filter(s => !ResolvedStatuses.includes(s));
const default_conversation_filters = (user_id, agentprofile_id, is_agent = true) => {
    return [
        {
            title: 'MY_CONVERSATIONS',
            filters: [
                {
                    users: is_agent ? [user_id,] : [],
                    statuses: UnresolvedStatuses,
                },
            ],
        },
        {
            title: 'MY_AND_UNASSIGNED_CONVERSATIONS',
            filters: [
                {
                    users: is_agent ? [0, user_id,] : [0,],
                    statuses: UnresolvedStatuses,
                },
            ],
        },
        {
            title: 'CUSTOMER_RESPONDED',
            filters: [
                {
                    users: is_agent ? [user_id,] : [],
                    flags: [enums_1.ConversationFlagEnum.CustomerResponded,],
                },
            ],
        },
        {
            title: 'NO_UPDATE_IN_7_DAYS',
            filters: [
                {
                    users: is_agent ? [user_id,] : [],
                    flags: [enums_1.ConversationFlagEnum.NoUpdatedIn7Days,],
                },
            ],
        },
        {
            title: 'CREATED_BY_ME',
            filters: [
                {
                    users: is_agent ? [user_id,] : [],
                    directions: [enums_1.DirectionEnum.Outbound,],
                },
            ],
        },
        {
            title: 'ALL_UNRESOLVED',
            filters: [
                {
                    statuses: UnresolvedStatuses,
                },
            ],
        },
        {
            title: 'ALL_RESOLVED',
            filters: [
                {
                    statuses: ResolvedStatuses,
                },
            ],
        },
        {
            title: 'ALL_CONVERSATIONS',
        },
    ].map((f, i) => {
        var _a;
        return Object.assign(Object.assign({ id: (i + 1) * -1, user_id,
            agentprofile_id, sort_by: exports.default_conversation_filter_sort }, f), { filters: [
                Object.assign({ organizations: [], users: [], queues: [], statuses: [], channels: [], priorities: [], dispositions: [], tags: [], flags: [], contacts: [], sla_response: null, sla_resolution: null }, (_a = f.filters) === null || _a === void 0 ? void 0 : _a[0]),
            ] });
    });
};
exports.default_conversation_filters = default_conversation_filters;
