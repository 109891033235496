"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockPreviewAgentprofileOne = void 0;
const organization_1 = require("./organization");
const enums_1 = require("../../utilities/enums");
const utils_1 = require("../utils");
const base = Object.assign({ id: 1, organization_id: (0, organization_1.mockChildOrganization)().id, title: 'Agentprofile One', __pauses: [], __queues: [], __teams: [], __voice_shortcuts: [], settings: {
        start_recording: true,
        play_recording: enums_1.PermissionSelfEnum.All,
        download_recording: enums_1.PermissionSelfEnum.Self,
        view_transcript: enums_1.PermissionSelfEnum.Self,
        inactivity_ready: 10,
        inactivity_pause: 30,
        iso2: null,
        anonymize_contacts: false,
    } }, utils_1.mockDateObject);
exports.mockPreviewAgentprofileOne = (0, utils_1.mockFunction)(Object.assign({}, base));
