import { downloadPost } from "@Utils";

export * from "./abstract/conversation_filter.api.abstract";

export type SortBy = {
  column: ConversationFilterSortColumn;
  order: ConversationFilterSortOrder;
};

export enum ConversationFilterSortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export enum ConversationFilterSortColumn {
  CreatedAt = "created_at",
  LastMessageAt = "last_message_at",
  LastOutboundMessageAt = "last_inbound_message_at",
  LastInboundMessageAt = "last_outbound_message_at",
  ResolutionTime = "resolution_time",
  ResponseTime = "response_time",
  StatusAt = "status_at",
  AssignedAt = "assigned_at",
  FirstAssignedAt = "first_assigned_at",
}

export type Field = {
  key: string;
  column: FieldColumn;
};

export type FieldColumn =
  | "conversation_id"
  | "conversation_email"
  | "conversation_status"
  | "conversation_channel"
  | "conversation_direction"
  | "conversation_queue_name"
  | "conversation_agent_name"
  | "conversation_disposition"
  | "conversation_created_date"
  | "conversation_caller_id"
  | "conversation_organization_id"
  | "conversation_organization_title";

export type downloadConversationPayload = {
  // format not in use as of now
  format: string;
  sortBy?: ConversationFilterSortColumn;
  order?: ConversationFilterSortOrder;
  limit?: 1000;
  offset?: 0;
  fields: Array<Field>;
};

export const fetchDownloadConversations = (
  filterId: number | undefined,
  payload: downloadConversationPayload
) => {
  if (!filterId) {
    console.error("No filter ID provided for download");
    return;
  }
  return downloadPost(
    `conversation_filter/${filterId}/conversations/download`,
    payload,
    `conversation_${new Date().toISOString()}.csv`,
    () => {}
  );
};
