import React, { useMemo, useState, } from 'react';
import './dialpad.scss';
import { TFunction, } from 'i18next';

interface IProps {
  t: TFunction;
  onPress: (digit: string) => void;
}

export const Dialpad = React.memo(({ onPress, t, }: IProps) => {
  const [sound, setSound, ] = useState<string>();
  const [locked, setLocked, ] = useState<boolean>(false);
  const player = useMemo(() => {
    return sound ? <audio src={`/dtmf/${sound}`} autoPlay /> : <></>;
  }, [sound, ]);

  return (
    <div className={`dialpad${locked ? ' locked' : ''}`} >
      {player}
      <div className={'dialpad-contents'} >
        <div className={'keys-wrp'} >
          {
            new Array(4).fill('').map((row, row_index) => <div key={row_index} className={'key-row'}>
              {
                new Array(3).fill('').map((col, col_index) => {
                  let num: string | number = (row_index * 3) + col_index + 1;

                  if (num === 10)
                    num = '*';
                  else if (num === 11)
                    num = 0;
                  else if (num === 12)
                    num = '#';

                  const str = {
                    1: '',
                    2: 'ABC',
                    3: 'DEF',
                    4: 'GHI',
                    5: 'JKL',
                    6: 'MNO',
                    7: 'PQRS',
                    8: 'TUV',
                    9: 'WXWZ',
                    0: '+',
                    '*': ' ',
                    '#': ' ',
                  };

                  const soundMap = {
                    1: 'Dtmf-1.wav',
                    2: 'Dtmf-2.wav',
                    3: 'Dtmf-3.wav',
                    4: 'Dtmf-4.wav',
                    5: 'Dtmf-5.wav',
                    6: 'Dtmf-6.wav',
                    7: 'Dtmf-7.wav',
                    8: 'Dtmf-8.wav',
                    9: 'Dtmf-9.wav',
                    0: 'Dtmf-0.wav',
                    '*': 'Dtmf-star.wav',
                    '#': 'Dtmf-pound.wav',
                  };

                  return <div
                    key={col_index}
                    onClick={() => {
                      if (locked)
                        return;

                      setLocked(true);
                      setSound(soundMap[num]);
                      onPress(num + '');
                      setTimeout(() => setLocked(false), 150);
                    }}
                    className={'key'}>
                    <div className={`num num-${num}`} >{num}</div>
                    <div className={'words'} >{str[num]}</div>
                  </div>;
                })
              }

            </div>)
          }
        </div>
      </div>
    </div>
  );
});
