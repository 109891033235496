import { TFunction } from "i18next";
import { FormLayoutTypes, IForm, IFormOptions } from "atlas-form";
import { filterRelated } from "@Utils/relation";
import {
  IQueueFormOptionsProps,
  QueueFormOptions as QueueFormOptionsBase,
  QueueAdditionalParams as QueueAdditionalParamsBase,
} from "./abstract/queue.form.abstract";
import {
  ISharedCreateQueue,
  FormTypeEnum,
  IPreviewForm,
  ISharedQueue,
  IPreviewDisposition,
  IPreviewAiAssistant,
  AiAssistantTypeEnum,
} from "atlas-shared";
import { IAuth } from "@Store";

export const QueueFormOptions = (
  queue: ISharedCreateQueue | ISharedQueue,
  props: IQueueFormOptionsProps,
  t: TFunction,
  isARIBased = false
): IFormOptions => ({
  ...QueueFormOptionsBase(queue, props, t, isARIBased),
  ai_assistant_id: (it: ISharedQueue) =>
    filterRelated<IPreviewAiAssistant>(
      it.organization_id || queue.organization_id,
      "ai_assistant",
      "queue",
      props.ai_assistants,
      true,
      (it) => it.type === AiAssistantTypeEnum.QueueAssistant
    ),
  __dispositions: (it: ISharedQueue) =>
    filterRelated<IPreviewDisposition>(
      it.organization_id || queue.organization_id,
      "disposition",
      "queue",
      props.dispositions,
      true,
      (it) => !it.global && !it.parents
    ),
  conversation_form_id: (it: ISharedCreateQueue) =>
    filterRelated<IPreviewForm>(
      it.organization_id || queue.organization_id,
      "list",
      "queue",
      props.forms,
      true,
      (it) => it.type === FormTypeEnum.Conversation
    ),
});

export const QueueAdditionalParams: (
  auth: IAuth,
  props: IQueueFormOptionsProps,
  isARIBased?: boolean
) => IForm["additionalParams"] = (
  auth: IAuth,
  props: IQueueFormOptionsProps,
  isARIBased = false
) => ({
  ...QueueAdditionalParamsBase(auth, props),
  __outbound_voice_routes: (__outbound_voice_routes, queue) => ({
    hidden: !queue.voice_settings,
  }),
  mail_settings_sort: { help: "QUEUE_SORT_HELP" },
  mail_settings_skill_timeout_settings_timeout_one: {
    help: "SKILL_TIMEOUT_ONE_DESCRIPTION",
    ui_layout: FormLayoutTypes.INLINE,
  },
  mail_settings_skill_timeout_settings_timeout_one_count: { hideLabel: true },
  mail_settings_skill_timeout_settings_timeout_one_unit: {
    hideLabel: true,
    keep_default_sorting: true,
  },
  mail_settings_skill_timeout_settings_timeout_two: {
    help: "SKILL_TIMEOUT_TWO_DESCRIPTION",
    ui_layout: FormLayoutTypes.INLINE,
  },
  mail_settings_skill_timeout_settings_timeout_two_count: { hideLabel: true },
  mail_settings_skill_timeout_settings_timeout_two_unit: {
    hideLabel: true,
    keep_default_sorting: true,
  },
  mail_settings_skill_timeout_settings_timeout_three: {
    help: "SKILL_TIMEOUT_THREE_DESCRIPTION",
    ui_layout: FormLayoutTypes.INLINE,
  },
  mail_settings_skill_timeout_settings_timeout_three_count: { hideLabel: true },
  mail_settings_skill_timeout_settings_timeout_three_unit: {
    hideLabel: true,
    keep_default_sorting: true,
  },
  form_settings_sort: { help: "QUEUE_SORT_HELP" },
  form_settings_skill_timeout_settings_timeout_one: {
    help: "SKILL_TIMEOUT_ONE_DESCRIPTION",
    ui_layout: FormLayoutTypes.INLINE,
  },
  form_settings_skill_timeout_settings_timeout_one_count: { hideLabel: true },
  form_settings_skill_timeout_settings_timeout_one_unit: {
    hideLabel: true,
    keep_default_sorting: true,
  },
  form_settings_skill_timeout_settings_timeout_two: {
    help: "SKILL_TIMEOUT_TWO_DESCRIPTION",
    ui_layout: FormLayoutTypes.INLINE,
  },
  form_settings_skill_timeout_settings_timeout_two_count: { hideLabel: true },
  form_settings_skill_timeout_settings_timeout_two_unit: {
    hideLabel: true,
    keep_default_sorting: true,
  },
  form_settings_skill_timeout_settings_timeout_three: {
    help: "SKILL_TIMEOUT_THREE_DESCRIPTION",
    ui_layout: FormLayoutTypes.INLINE,
  },
  form_settings_skill_timeout_settings_timeout_three_count: { hideLabel: true },
  form_settings_skill_timeout_settings_timeout_three_unit: {
    hideLabel: true,
    keep_default_sorting: true,
  },
  // If the queue is not ari based
  ...(!isARIBased ? { voice_settings_sort: { hidden: true } } : {}),

  // If the queue is ari based
  ...(isARIBased
    ? {
        voice_settings_joinempty: { hidden: true },
        voice_settings_leavewhenempty: { hidden: true },
        voice_settings_ringinuse: { hidden: true },
        voice_settings_weight: { hidden: true },
        voice_settings_announce: { hidden: true },
        voice_settings_reportholdtime: { hidden: true },
        voice_settings_min_announce_frequency: { hidden: true },
        voice_settings_periodic_announce_frequency: { hidden: true },
        voice_settings_random_announce_frequency: { hidden: true },
        voice_settings_relative_periodic_announce: { hidden: true },
      }
    : {}),
});

export * from "./abstract/queue.form.abstract";
