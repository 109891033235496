"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionOperationalHours = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const misc_1 = require("../../misc");
exports.JourneyActionOperationalHours = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.OperationalHours,
    settings_schema: (0, base_1.settingsValidationSchema)({
        operational_hours_id: {
            type: 'number',
            required: true,
        },
    }),
    channels: (0, misc_1.EnumValues)(enums_1.ChannelEnum),
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_OPERATIONAL_HOURS_WITHIN_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_OPERATIONAL_HOURS_WITHIN_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: false,
                index: 0,
                title: 'WITHIN',
                color: '#5bbf1c',
            }), [constants_1.JOURNEY_ACTION_OPERATIONAL_HOURS_NOT_WITHIN_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_OPERATIONAL_HOURS_NOT_WITHIN_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: false,
                index: 1,
                title: 'NOT_WITHIN',
                color: '#d91010',
            }) });
    },
});
