import { useFeatureFlag } from "@Hooks/useFeatureFlag";

interface IProps {
  identifier: string;
  children: JSX.Element;
  fallback?: JSX.Element | null;
  defaultValue: boolean;
}

export const FeatureFlag = ({ identifier, children, fallback = null, defaultValue }: IProps) => {
  const isEnabled = useFeatureFlag(identifier, defaultValue);

  return isEnabled ? children : fallback;
};
