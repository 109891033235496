"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionEditConversation = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const misc_1 = require("../../misc");
exports.JourneyActionEditConversation = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.EditConversation,
    channels: (0, misc_1.EnumValues)(enums_1.ChannelEnum),
    settings_schema: (0, base_1.settingsValidationSchema)({
        set: {
            type: 'array',
            min: 1,
            items: {
                type: 'object',
                properties: {
                    property: {
                        type: 'any',
                        valid: ['priority', 'client_id', 'skill_id', 'disposition', 'status', 'add_tag', 'remove_tag', 'disposition_note',],
                        required: true,
                    },
                    value: {
                        type: 'any',
                        when: {
                            reference: { '$ref': 'property', },
                            is: {
                                type: 'string',
                                required: true,
                            },
                            then: { type: 'any', required: true, },
                            otherwise: { type: 'string', forbidden: true, },
                        },
                    },
                },
            },
        },
    }),
    getPorts: (node) => {
        return Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_EDIT_CONVERSATION_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_EDIT_CONVERSATION_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: false,
                index: 0,
                title: 'EDITED',
                color: null,
            }) });
    },
});
