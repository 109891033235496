import { JourneyActionEnum, Metrics, GroupMetrics } from "atlas-shared";

export const de = {
  ...Object.fromEntries(
    [...Metrics, ...GroupMetrics]
      .flatMap((metric) => [
        metric.title ? [`METRIC_${metric.alias}`, metric.title] : false,
        metric.description ? [`METRIC_${metric.alias}_DESCRIPTION`, metric.description] : false,
      ])
      .filter(Boolean) as Array<[string, string]>
  ),
  COUNTRY_AF: "Afghanistan",
  COUNTRY_AX: "Åland Islands",
  COUNTRY_AL: "Albania",
  COUNTRY_DZ: "Algeria",
  COUNTRY_AS: "American Samoa",
  COUNTRY_AD: "Andorra",
  COUNTRY_AO: "Angola",
  COUNTRY_AI: "Anguilla",
  COUNTRY_AQ: "Antarctica",
  COUNTRY_AG: "Antigua and Barbuda",
  COUNTRY_AR: "Argentina",
  COUNTRY_AM: "Armenia",
  COUNTRY_AW: "Aruba",
  COUNTRY_AU: "Australia",
  COUNTRY_AT: "Austria",
  COUNTRY_AZ: "Azerbaijan",
  COUNTRY_BS: "Bahamas",
  COUNTRY_BH: "Bahrain",
  COUNTRY_BD: "Bangladesh",
  COUNTRY_BB: "Barbados",
  COUNTRY_BY: "Belarus",
  COUNTRY_BE: "Belgium",
  COUNTRY_BZ: "Belize",
  COUNTRY_BJ: "Benin",
  COUNTRY_BM: "Bermuda",
  COUNTRY_BT: "Bhutan",
  COUNTRY_BO: "Bolivia",
  COUNTRY_BA: "Bosnia and Herzegovina",
  COUNTRY_BW: "Botswana",
  COUNTRY_BV: "Bouvet Island",
  COUNTRY_BR: "Brazil",
  COUNTRY_IO: "British Indian Ocean Territory",
  COUNTRY_BN: "Brunei Darussalam",
  COUNTRY_BG: "Bulgaria",
  COUNTRY_BF: "Burkina Faso",
  COUNTRY_BI: "Burundi",
  COUNTRY_KH: "Cambodia",
  COUNTRY_CM: "Cameroon",
  COUNTRY_CA: "Canada",
  COUNTRY_CV: "Cape Verde",
  COUNTRY_KY: "Cayman Islands",
  COUNTRY_CF: "Central African Republic",
  COUNTRY_TD: "Chad",
  COUNTRY_CL: "Chile",
  COUNTRY_CN: "China",
  COUNTRY_CX: "Christmas Island",
  COUNTRY_CC: "Cocos (Keeling) Islands",
  COUNTRY_CO: "Colombia",
  COUNTRY_KM: "Comoros",
  COUNTRY_CG: "Congo",
  COUNTRY_CD: "Congo, The Democratic Republic of the",
  COUNTRY_CK: "Cook Islands",
  COUNTRY_CR: "Costa Rica",
  COUNTRY_CI: "Cote D'Ivoire",
  COUNTRY_HR: "Croatia",
  COUNTRY_CU: "Cuba",
  COUNTRY_CY: "Cyprus",
  COUNTRY_CZ: "Czech Republic",
  COUNTRY_DG: "Diego Garcia",
  COUNTRY_DK: "Denmark",
  COUNTRY_DJ: "Djibouti",
  COUNTRY_DM: "Dominica",
  COUNTRY_DO: "Dominican Republic",
  COUNTRY_EC: "Ecuador",
  COUNTRY_EG: "Egypt",
  COUNTRY_SV: "El Salvador",
  COUNTRY_GQ: "Equatorial Guinea",
  COUNTRY_ER: "Eritrea",
  COUNTRY_EE: "Estonia",
  COUNTRY_ET: "Ethiopia",
  COUNTRY_FK: "Falkland Islands (Malvinas)",
  COUNTRY_FO: "Faroe Islands",
  COUNTRY_FJ: "Fiji",
  COUNTRY_FI: "Finland",
  COUNTRY_FR: "France",
  COUNTRY_GF: "French Guiana",
  COUNTRY_PF: "French Polynesia",
  COUNTRY_TF: "French Southern Territories",
  COUNTRY_GA: "Gabon",
  COUNTRY_GM: "Gambia",
  COUNTRY_GE: "Georgia",
  COUNTRY_DE: "Germany",
  COUNTRY_GH: "Ghana",
  COUNTRY_GI: "Gibraltar",
  COUNTRY_GR: "Greece",
  COUNTRY_GL: "Greenland",
  COUNTRY_GD: "Grenada",
  COUNTRY_GP: "Guadeloupe",
  COUNTRY_GU: "Guam",
  COUNTRY_GT: "Guatemala",
  COUNTRY_GG: "Guernsey",
  COUNTRY_GN: "Guinea",
  COUNTRY_GW: "Guinea-Bissau",
  COUNTRY_GY: "Guyana",
  COUNTRY_HT: "Haiti",
  COUNTRY_HM: "Heard Island and Mcdonald Islands",
  COUNTRY_VA: "Holy See (Vatican City State)",
  COUNTRY_HN: "Honduras",
  COUNTRY_HK: "Hong Kong",
  COUNTRY_HU: "Hungary",
  COUNTRY_IS: "Iceland",
  COUNTRY_IN: "India",
  COUNTRY_ID: "Indonesia",
  COUNTRY_IR: "Iran, Islamic Republic Of",
  COUNTRY_IQ: "Iraq",
  COUNTRY_IE: "Ireland",
  COUNTRY_IM: "Isle of Man",
  COUNTRY_IL: "Israel",
  COUNTRY_IT: "Italy",
  COUNTRY_JM: "Jamaica",
  COUNTRY_JP: "Japan",
  COUNTRY_JE: "Jersey",
  COUNTRY_JO: "Jordan",
  COUNTRY_KZ: "Kazakhstan",
  COUNTRY_KE: "Kenya",
  COUNTRY_KI: "Kiribati",
  COUNTRY_KP: "Korea, Democratic People'S Republic of",
  COUNTRY_KR: "Korea, Republic of",
  COUNTRY_XK: "Kosovo",
  COUNTRY_KW: "Kuwait",
  COUNTRY_KG: "Kyrgyzstan",
  COUNTRY_LA: "Lao People'S Democratic Republic",
  COUNTRY_LV: "Latvia",
  COUNTRY_LB: "Lebanon",
  COUNTRY_LS: "Lesotho",
  COUNTRY_LR: "Liberia",
  COUNTRY_LY: "Libyan Arab Jamahiriya",
  COUNTRY_LI: "Liechtenstein",
  COUNTRY_LT: "Lithuania",
  COUNTRY_LU: "Luxembourg",
  COUNTRY_LX: "Luxembourg (Special)",
  COUNTRY_MO: "Macao",
  COUNTRY_MK: "Macedonia, The Former Yugoslav Republic of",
  COUNTRY_MG: "Madagascar",
  COUNTRY_MW: "Malawi",
  COUNTRY_MY: "Malaysia",
  COUNTRY_MV: "Maldives",
  COUNTRY_ML: "Mali",
  COUNTRY_MT: "Malta",
  COUNTRY_MH: "Marshall Islands",
  COUNTRY_MQ: "Martinique",
  COUNTRY_MR: "Mauritania",
  COUNTRY_MU: "Mauritius",
  COUNTRY_YT: "Mayotte",
  COUNTRY_MX: "Mexico",
  COUNTRY_FM: "Micronesia, Federated States of",
  COUNTRY_MD: "Moldova, Republic of",
  COUNTRY_ME: "Montenegro",
  COUNTRY_MF: "Saint Martin",
  COUNTRY_MC: "Monaco",
  COUNTRY_MN: "Mongolia",
  COUNTRY_MS: "Montserrat",
  COUNTRY_MA: "Morocco",
  COUNTRY_MZ: "Mozambique",
  COUNTRY_MM: "Myanmar",
  COUNTRY_NA: "Namibia",
  COUNTRY_NR: "Nauru",
  COUNTRY_NP: "Nepal",
  COUNTRY_NL: "Netherlands",
  COUNTRY_AN: "Netherlands Antilles",
  COUNTRY_NC: "New Caledonia",
  COUNTRY_NZ: "New Zealand",
  COUNTRY_NI: "Nicaragua",
  COUNTRY_NE: "Niger",
  COUNTRY_NG: "Nigeria",
  COUNTRY_NU: "Niue",
  COUNTRY_NF: "Norfolk Island",
  COUNTRY_MP: "Northern Mariana Islands",
  COUNTRY_NO: "Norway",
  COUNTRY_OM: "Oman",
  COUNTRY_PK: "Pakistan",
  COUNTRY_PW: "Palau",
  COUNTRY_PS: "Palestinian Territory, Occupied",
  COUNTRY_PA: "Panama",
  COUNTRY_PG: "Papua New Guinea",
  COUNTRY_PY: "Paraguay",
  COUNTRY_PE: "Peru",
  COUNTRY_PH: "Philippines",
  COUNTRY_PN: "Pitcairn",
  COUNTRY_PL: "Poland",
  COUNTRY_PT: "Portugal",
  COUNTRY_PR: "Puerto Rico",
  COUNTRY_QA: "Qatar",
  COUNTRY_RE: "Reunion",
  COUNTRY_RO: "Romania",
  COUNTRY_RS: "Serbia",
  COUNTRY_RU: "Russian Federation",
  COUNTRY_RW: "Rwanda",
  COUNTRY_SH: "Saint Helena",
  COUNTRY_KN: "Saint Kitts and Nevis",
  COUNTRY_LC: "Saint Lucia",
  COUNTRY_PM: "Saint Pierre and Miquelon",
  COUNTRY_VC: "Saint Vincent and the Grenadines",
  COUNTRY_WS: "Samoa",
  COUNTRY_SM: "San Marino",
  COUNTRY_ST: "Sao Tome and Principe",
  COUNTRY_SA: "Saudi Arabia",
  COUNTRY_SN: "Senegal",
  COUNTRY_CS: "Serbia and Montenegro",
  COUNTRY_SC: "Seychelles",
  COUNTRY_SL: "Sierra Leone",
  COUNTRY_SG: "Singapore",
  COUNTRY_SK: "Slovakia",
  COUNTRY_SI: "Slovenia",
  COUNTRY_SB: "Solomon Islands",
  COUNTRY_SO: "Somalia",
  COUNTRY_SS: "South Sudan",
  COUNTRY_SX: "Sint Maarten",
  COUNTRY_ZA: "South Africa",
  COUNTRY_GS: "South Georgia and the South Sandwich Islands",
  COUNTRY_ES: "Spain",
  COUNTRY_LK: "Sri Lanka",
  COUNTRY_SD: "Sudan",
  COUNTRY_SR: "Suriname",
  COUNTRY_SJ: "Svalbard and Jan Mayen",
  COUNTRY_SZ: "Swaziland",
  COUNTRY_SE: "Sweden",
  COUNTRY_CH: "Switzerland",
  COUNTRY_SY: "Syrian Arab Republic",
  COUNTRY_TA: "Tristan da Cunha",
  COUNTRY_TW: "Taiwan, Province of China",
  COUNTRY_TJ: "Tajikistan",
  COUNTRY_TZ: "Tanzania, United Republic of",
  COUNTRY_TH: "Thailand",
  COUNTRY_TL: "Timor-Leste",
  COUNTRY_TG: "Togo",
  COUNTRY_TK: "Tokelau",
  COUNTRY_TO: "Tonga",
  COUNTRY_TP: "East Timor",
  COUNTRY_TT: "Trinidad and Tobago",
  COUNTRY_TN: "Tunisia",
  COUNTRY_TR: "Turkey",
  COUNTRY_TM: "Turkmenistan",
  COUNTRY_TC: "Turks and Caicos Islands",
  COUNTRY_TV: "Tuvalu",
  COUNTRY_UG: "Uganda",
  COUNTRY_UA: "Ukraine",
  COUNTRY_AE: "United Arab Emirates",
  COUNTRY_GB: "United Kingdom",
  COUNTRY_US: "United States",
  COUNTRY_UM: "United States Minor Outlying Islands",
  COUNTRY_UY: "Uruguay",
  COUNTRY_UZ: "Uzbekistan",
  COUNTRY_VU: "Vanuatu",
  COUNTRY_VE: "Venezuela",
  COUNTRY_VN: "Viet Nam",
  COUNTRY_VG: "Virgin Islands, British",
  COUNTRY_VI: "Virgin Islands, U.S.",
  COUNTRY_WF: "Wallis and Futuna",
  COUNTRY_EH: "Western Sahara",
  COUNTRY_YE: "Yemen",
  COUNTRY_ZM: "Zambia",
  COUNTRY_ZW: "Zimbabwe",
  COUNTRY_AC: "Ascension Island",
  COUNTRY_BL: "Saint Martin country",
  COUNTRY_BQ: "Netherlands Antilles",
  COUNTRY_CW: "Netherlands Antilles",

  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceInbound}`]:
    "Eingehender Sprachanruf wurde erstellt",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceOutbound}`]:
    "Ausgehender Sprachanruf wurde vom Agenten beendet",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceAnswer}`]: "Sprachanruf beantworten",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceExternalDial}`]: "Externe Nummer wählen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceGetDigits}`]: "Ziffern vom Anrufer abrufen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceHangup}`]: "Sprachanruf beenden",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceInternalDial}`]:
    "Interner Anruf an Agenten oder Warteschlangen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceMenu}`]: "Sprachmenü mit Optionen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoicePlayback}`]: "Audio für Kontakt abspielen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayAlpha}`]: "Text für Kontakt vorlesen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayDigits}`]: "Ziffern für Kontakt vorlesen (einzeln)",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVariableSet}`]: "Sprachvariable festlegen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayNumber}`]: "Nummer für Kontakt vorlesen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayPhonetic}`]: "Buchstaben für Kontakt vorlesen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStartRecording}`]: "Anrufaufzeichnung starten",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStopRecording}`]: "Bestimmte Anrufaufzeichnung stoppen",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailInbound}`]:
    "Eingehende E-Mail-Konversation wurde erstellt",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailOutbound}`]:
    "Ausgehende E-Mail-Konversation wurde erstellt",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartForm}`]:
    "Konversation wurde durch Formularübermittlung erstellt",
  [`NODE_SUBTITLE_${JourneyActionEnum.OperationalHours}`]:
    "Innerhalb oder außerhalb der Öffnungszeiten",
  [`NODE_SUBTITLE_${JourneyActionEnum.MailAutoResponse}`]: "Automatische E-Mail-Antwort",
  [`NODE_SUBTITLE_${JourneyActionEnum.Queue}`]: "Der Warteschlange zuweisen",
  [`NODE_SUBTITLE_${JourneyActionEnum.Client}`]: "Client zur Konversation hinzufügen",
  [`NODE_SUBTITLE_${JourneyActionEnum.UnAssign}`]: "Warteschlange und/oder Benutzer entfernen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceQueue}`]: "Der Warteschlange mit Sprachhandler zuweisen",
  [`NODE_SUBTITLE_${JourneyActionEnum.Agent}`]: "Direkt einem Agenten zuweisen",
  [`NODE_SUBTITLE_${JourneyActionEnum.Timeout}`]: JourneyActionEnum.Timeout + " Standarduntertitel",
  [`NODE_SUBTITLE_${JourneyActionEnum.RestRequest}`]: "REST-Anfrage ausführen",
  [`NODE_SUBTITLE_${JourneyActionEnum.FetchContactData}`]: "Kontaktdaten über REST abrufen",
  [`NODE_SUBTITLE_${JourneyActionEnum.EditConversation}`]: "Konversationseigenschaften bearbeiten",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseConversation}`]: "Konversation beenden und schließen",
  [`NODE_SUBTITLE_${JourneyActionEnum.AddInternalNote}`]:
    "Interne Notiz zur Konversation hinzufügen",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseTabs}`]: "Tabs automatisch aus Journey schließen",
  [`NODE_SUBTITLE_${JourneyActionEnum.SendMail}`]: "E-Mail senden",
  [`NODE_SUBTITLE_${JourneyActionEnum.ForwardMail}`]: "E-Mail weiterleiten",
  [`NODE_SUBTITLE_${JourneyActionEnum.ReplyLatestInboundMessage}`]:
    "Auf letzte eingehende E-Mail oder Formularübermittlung antworten",
  [`NODE_SUBTITLE_${JourneyActionEnum.Journey}`]: "Unter-Journey",
  [`NODE_SUBTITLE_${JourneyActionEnum.Rule}`]: "Bedingungsregeln definieren",
  [`NODE_SUBTITLE_${JourneyActionEnum.RuleContact}`]: "Kontakt-Bedingungsregeln definieren",
  [`NODE_SUBTITLE_${JourneyActionEnum.NoOp}`]: "Keine Aktion",
  [`NODE_SUBTITLE_${JourneyActionEnum.When}`]: "Wird ausgelöst bei",
  [`NODE_SUBTITLE_${JourneyActionEnum.SetField}`]: "Konversationsfeld-Wert setzen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSetLanguage}`]: "Sprache des Sprachanrufs festlegen",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVoicemail}`]: "Sprachnachricht aufnehmen",
  [`NODE_SUBTITLE_${JourneyActionEnum.OpenBrowserTab}`]: "Browser-Tab öffnen",

  NODE_TITLE_DEFAULT: "Standard-Knotentitel",
  NODE_DESCRIPTION_DEFAULT: "Standard-Knotenbeschreibung",
  LOADING: "Lädt...",
  CUSTOMER_JOURNEY: "Kundenreise",
  CONVERSATION: "Konversation",
  CONVERSATIONS: "Konversationen",
  CUSTOMER: "Kunde",
  CUSTOMERS: "Kunden",
  STATISTICS: "Statistik",
  REPLY_IN: "Antworten in",
  REPLY: "Antworten",
  AGO: "vor",
  SHOWING: "Zeigt",
  STATS: "Statistik",
  CLOSE: "Schließen",
  CLOSED: "Geschlossen",
  OPEN: "Öffnen",
  DRAFT_REPLY_MESSAGE_CONFIRM: "Möchten Sie Ihre Änderungen speichern?",
  TODAY: "Heute",
  YES: "Ja",
  NO: "Nein",
  CONFIRM: "Bestätigen",
  NEW_MESSAGE: "Neue Nachricht",
  TO: "An",
  DRAFT: "Entwurf",
  SEND_REPLY: "Antwort senden",
  SAVE: "Speichern",
  TYPE: "Typ",
  ALL: "Alle",
  TICKETS: "Tickets",
  CONTACTS: "Kontakte",
  SOLUTIONS: "Lösungen",
  FORUMS: "Foren",
  TEAM_NAME: "Teamname",
  ANOTHER_TEAM: "Anderes Team",
  THIRD_TEAM_NAME: "Dritter Teamname",
  OTHER_TEAM_NAME: "Anderer Teamname",
  FAVORITES: "Favoriten",
  CHAT: "Chat",
  CALL: "Anruf",
  RETURN: "Zurück",
  SEND: "Senden",
  RECENTLY_SEARCHED: "Kürzlich gesucht",
  RECENTLY_VIEWED: "Kürzlich angesehen",
  CLEAR: "Löschen",
  ASSIGN: "Zuweisen",
  CHOOSE: "Wählen",
  AGENT: "Agent",
  ADMIN: "Admin",
  SUPERADMIN: "Superadmin",
  QUEUES: "Warteschlangen",
  QUEUE: "Warteschlange",
  DONE: "Fertig",
  IN: "in",
  SEARCH: "Suchen",
  AND: "Und",
  PREVIOUS_ACTIVITY: "Vorherige Aktivität",
  VIEW: "Ansicht",
  BY: "von",
  SUPPORT: "Support",
  GENERAL: "Allgemein",
  WRITE: "Schreiben",
  HERE: "hier",
  ADD: "Hinzufügen",
  NEW: "Neu",
  AGENTS: "Agenten",
  EDIT: "Bearbeiten",
  TOOLS: "Werkzeuge",
  EMAIL: "E-Mail",
  CANCEL: "Abbrechen",
  UNAUTHORIZED: "Benutzername oder Passwort stimmen nicht",
  OR: "Oder",
  DISPOSE: "Beenden",
  CHOOSE_REASON_FOR_DISPOSAL: "Grund für Beendigung wählen",
  WRITE_A_COMMENT: "Einen Kommentar schreiben",
  USERNAME: "Benutzername",
  DROP_HERE: "Hier ablegen",
  PASSWORD: "Passwort",
  FORGOT_YOUR_PASSWORD: "Passwort vergessen",
  RESET_PASSWORD: "Passwort zurücksetzen",
  REMEMBER_ME: "Angemeldet bleiben",
  SIGN_IN: "Anmelden",
  SIGN_OUT: "Abmelden",
  DISPLAY_DELETED: "Gelöschte anzeigen",
  GLOBAL: "Global",
  WORK_TIME: "Arbeitszeit",
  INVOICEABLE: "Abrechenbar",
  TITLE: "Titel",
  ORGANIZATION_NOT_FOUND: 'Organisation mit ID "{{organization_id}}" wurde nicht gefunden!',
  BETWEEN_FROM_AND_TO: "Zwischen {{from}} und {{to}}",
  FROM_FROM_TO_TO: "Von {{from}} bis {{to}}",
  FROM_THROUGH_TO: "{{from}} bis {{to}}",
  FROM_THROUGH_TO_OF_MONTH: "{{from}} bis {{to}} des Monats",
  ON_FROM: "Am {{from}}",
  ON_FROM_OF_MONTH: "Am {{from}} des Monats",
  EVERY_SPECIFIC_DAY: "Jeden {{from}}",
  ALL_DAY: "Ganztägig",
  EVERY_DAY: "Jeden Tag",

  ROLE: "Rolle",
  FIRST_NAME: "Vorname",
  LAST_NAME: "Nachname",
  AVATAR: "Profilbild",

  ORGANIZATIONS: "Organisationen",
  ORGANIZATION: "Organisation",
  USER: "Benutzer",
  USERS: "Benutzer",
  ADMINPROFILES: "Admin-Profile",
  ADMINPROFILE: "Admin-Profil",
  SUPERADMIN_PROFILES: "Superadmin-Profile",
  SUPERADMINPROFILES: "Superadmin-Profile",
  SUPERADMINPROFILE: "Superadmin-Profil",
  SUPERADMIN_PROFILE: "Superadmin-Profil",
  AGENTPROFILES: "Agenten-Profile",
  AGENTPROFILE: "Agenten-Profil",
  TEAMS: "Teams",
  MAIL_ACCOUNTS: "E-Mail-Konten",
  MAIL_ACCOUNT: "E-Mail-Konto",
  LISTS: "Listen",
  LIST: "Liste",
  JOURNEYS: "Journeys",
  JOURNEY: "Journey",
  PAUSES: "Pausen",
  PAUSE: "Pause",
  DISPOSITIONS: "Dispositionen",
  DISPOSITION: "Disposition",
  FIELDS: "Felder",
  FIELD: "Feld",
  FORM_ERROR_STRING__EMPTY: "Darf nicht leer sein",
  FORM_ERROR_ARRAY__MIN: "Mindestens {{min}} Optionen erforderlich",
  FORM_ERROR_ARRAY__MAX: "Maximal {{max}} Optionen erlaubt",
  FORM_ERROR_ARRAY__LENGTH: "{{lngth}} Optionen erforderlich",
  FORM_ERROR_ANY__REQUIRED: "Pflichtfeld",
  FORM_ERROR_NUMBER__BASE: "Gültige Nummer erforderlich",
  FORM_ERROR_STRING__BASE: "Gültiger Text erforderlich",
  FORM_ERROR_VALID_OPTION: "Wählen Sie eine Option",
  FORM_ERROR_ANY__ONLY: "Wählen Sie eine gültige Option",
  FORM_ERROR_STRING__URI: "Ungültige URI",
  FORM_ERROR_REF_NOT_FOUND: "Eine gültige Option muss ausgewählt werden",
  FORM_ERROR_INVALID_PHONE: "Ungültige Telefonnummer",
  FORM_ERROR_NUMBER__MIN: "Die Zahl muss größer oder gleich {{min}} sein",
  FORM_ERROR_NUMBER__MAX: "Die Zahl muss kleiner oder gleich {{max}} sein",
  FORM_ERROR_STRING__MIN: "Muss mindestens {{min}} Zeichen lang sein",
  FORM_ERROR_STRING__MAX: "Darf höchstens {{max}} Zeichen lang sein",
  FORM_ERROR_STRING__LENGTH: "Muss genau {{lngth}} Zeichen lang sein",
  FORM_ERROR_STRING__EMAIL: "Ungültige E-Mail-Adresse",
  FORM_ERROR_STRING__ALPHANUM: "Nur alphanumerische Werte!",
  FORM_ERROR_USERNAME_INVALID_CHARS:
    "Darf nur Kleinbuchstaben, Zahlen und die Sonderzeichen . (Punkt) und - (Bindestrich) enthalten",
  FORM_ERROR_STRING__PATTERN__BASE: "Die Zeichenkette entspricht nicht dem Muster {{pattern}}",
  FORM_ERROR_DATE__BASE: "Wählen Sie ein gültiges Datum",
  FORM_ERROR_STRING__IPVERSION: "Ungültige IP-Adresse",

  GLOBAL_SEARCH_PLACEHOLDER: "Konversationen & Kontakte durchsuchen",
  ADD_ITEM: "Element hinzufügen",
  MAIL_CAPACITY: "E-Mail-Kapazität",
  __ORGANIZATIONS: "Organisationen",
  __ORGANIZATIONS__ORGANIZATION_ID: "Organisation",
  __ORGANIZATIONS__ROLE: "Rolle",
  __ORGANIZATIONS__ADMINPROFILE_ID: "Admin-Profil",
  __ORGANIZATIONS__SUPERADMIN_PROFILE_ID: "Superadmin-Profil",
  SUBMIT: "Absenden",
  SIGN_IN_WITH_GOOGLE: "Mit Google anmelden",
  SIGN_IN_WITH_MICROSOFT: "Mit Microsoft anmelden",
  OPEN_AUTHENTICATOR_AND_SCAN: "Öffnen Sie Ihre Authentifizierungs-App und scannen Sie den QR-Code",
  OPEN_AUTHENTICATOR: "Öffnen Sie Ihre Authentifizierungs-App und geben Sie den Code ein",
  ENTER_6_DIGIT_CODE: "6-stelligen Code eingeben",
  FILL_USERNAME_TO_RESET_PASSWORD: "Benutzernamen eingeben, um Passwort zurückzusetzen",
  SOMETHING_WENT_WRING: "Etwas ist schiefgelaufen, bitte später erneut versuchen!",

  ENTRY: "Eintrag",
  ASSIGNMENT: "Zuweisung",
  MAIL: "E-Mail",
  StartMailInbound: "E-Mail starten (Eingehend)",
  StartMailOutbound: "E-Mail starten (Ausgehend)",
  StartVoiceInbound: "Sprachanruf starten (Eingehend)",
  StartVoiceOutbound: "Sprachanruf starten (Ausgehend)",
  VoiceAnswer: "Antworten",
  VoiceExternalDial: "Externer Anruf",
  VoiceGetDigits: "Ziffern abrufen",
  VoiceQueue: "Sprach-Warteschlange",
  VoiceVariableSet: "Variable setzen",
  VoiceHangup: "Auflegen",
  VoiceInternalDial: "Interner Anruf",
  VoiceMenu: "Sprachmenü",
  VoicePlayback: "Wiedergabe",
  VoiceSayAlpha: "Alphabetisch sagen",
  VoiceSayNumber: "Nummer sagen",
  VoiceSayPhonetic: "Phonetisch sagen",
  VoiceSayDigits: "Ziffern sagen",
  VoiceStartRecording: "Aufnahme starten",
  VoiceStopRecording: "Aufnahme stoppen",
  VoiceVoicemail: "Sprachnachricht",
  StartForm: "Formular starten",
  Queue: "Warteschlange",
  EditConversation: "Konversation bearbeiten",
  CloseConversation: "Konversation beenden",
  AddInternalNote: "Interne Notiz hinzufügen",
  CloseTabs: "Tabs schließen",
  SendMail: "E-Mail senden",
  ForwardMail: "E-Mail weiterleiten",
  ReplyLatestInboundMessage: "Auf letzte eingehende Nachricht antworten",
  MailAutoResponse: "Automatische E-Mail-Antwort",
  RestRequest: "REST-Anfrage",
  FetchContactData: "Kontaktdaten abrufen",
  SetField: "Felddaten setzen",
  VoiceSetLanguage: "Sprache einstellen",
  Rule: "Regel",
  RuleContact: "Regel (Kontakt)",
  NoOp: "Keine Aktion",
  OperationalHours: "Öffnungszeiten",
  Journey: "Journey",
  MY_FILTERS: "Meine Filter",
  CREATE_NEW_FILTER: "Neuen Filter erstellen",
  NEW_FILTER: "Neuer Filter",
  DEFAULT_FILTER: "Standardfilter",
  STATUSES: "Status",
  CHANNELS: "Kanäle",
  PRIORITIES: "Prioritäten",
  TAGS: "Tags",
  LAST_MESSAGE_AT: "Letzte Nachricht",
  LAST_INBOUND_MESSAGE_AT: "Letzte eingehende Nachricht",
  LAST_OUTBOUND_MESSAGE_AT: "Letzte ausgehende Nachricht",
  CREATED_AT: "Erstellt",
  CONTACT: "Kontakt",
  __CONTACT_FIRST_NAME: "Vorname",
  __CONTACT_LAST_NAME: "Nachname",
  __CONTACT_PHONES: "Telefonnummern",
  __CONTACT_EMAILS: "E-Mail-Adressen",
  SETTINGS: "Einstellungen",
  PROFILES: "Profile",
  NOTIFICATIONS: "Benachrichtigungen",
  NewAssignment: "Neue Zuweisung",
  NewResponseOnMyConversation: "Neue Antwort auf meine Konversationen",
  ConversationMessagesImTaggedIn: "Konversationsnachrichten, in denen ich markiert bin",
  StatusUpdateOnMyConversation: "Statusaktualisierung meiner Konversation",
  ConversationAssignedToMe: "Mir zugewiesene Konversation",
  ConversationAssignedToMyQueues: "Meinen Warteschlangen zugewiesene Konversation",
  ReminderOnFirstResponse: "Erinnerung an erste Antwort",
  ReminderOnResolutionTime: "Erinnerung an Lösungszeit",
  ReminderOnNextResponse: "Erinnerung an nächste Antwort",
  DELETE_DRAFT: "Entwurf löschen",
  PERMANENTLY_DELETE_DRAFT: "Entwurf endgültig löschen?",
  ADD_PORT: "Port hinzufügen",
  ADD_RULE: "Regel hinzufügen",
  ADD_PROPERTY: "Eigenschaft hinzufügen",
  NOT_EQUAL_PHONE_NUMBER: "Ungleich Telefonnummer",
  EQUAL_PHONE_NUMBER: "Gleich Telefonnummer",
  INCLUDES_PHONE_NUMBER: "Enthält Telefonnummer",
  NOT_INCLUDES_PHONE_NUMBER: "Enthält keine Telefonnummer",
  ARRAY_LENGTH: "Array length",
  STRICT_EQ: "Ist gleich",
  NOT_STRICT_EQ: "Ist nicht gleich",
  IS_EMPTY: "Ist leer",
  IS_NOT_EMPTY: "Ist nicht leer",
  CONTAINS: "Enthält",
  NOT_CONTAINS: "Enthält nicht",
  CHANGED: "Geändert",
  NOT_EQUAL_NUM: "Ungleich (Zahl)",
  NOT_EQUAL_STR: "Ungleich (Text)",
  EQUAL_NUM: "Gleich (Zahl)",
  EQUAL_STR: "Gleich (Text)",
  STARTS_WITH: "Beginnt mit",
  NOT_STARTS_WITH: "Beginnt nicht mit",
  ANY_OF_NUM: "Einer von",
  NONE_OF_NUM: "Keiner von",
  ANY_OF_STR: "Einer von",
  NONE_OF_STR: "Keiner von",
  ENDS_WITH: "Endet mit",
  NOT_ENDS_WITH: "Endet nicht mit",
  GT: "Größer als",
  GTE: "Größer oder gleich",
  LT: "Kleiner als",
  LTE: "Kleiner oder gleich",
  IS_NOT_NULL: "Ist nicht null",
  IS_NULL: "Ist null",
  IS_FALSY: "Ist falsch",
  IS_FALSE: "Ist false",
  IS_TRULY: "Ist wahr",
  IS_TRUE: "Ist true",
  IS_UNDEFINED: "Ist undefiniert",
  IS_DEFINED: "Ist definiert",
  INCLUDES: "Enthält",
  INCLUDES_NUM: "Enthält Zahl",
  INCLUDES_STR: "Enthält Text",
  NOT_INCLUDES: "Enthält nicht",
  NOT_INCLUDES_NUM: "Enthält keine Zahl",
  NOT_INCLUDES_STR: "Enthält keinen Text",
  GT_DATETIME: "Größer als",
  LT_DATETIME: "Kleiner als",
  MAIL_MESSAGE: "E-Mail-Nachricht",
  "DRAFT.ID": "Entwurfs-ID",
  "INTERNAL_NOTE.ID": "Interne Notiz-ID",
  "CONVERSATION.ID": "Konversations-ID",
  "CONVERSATION.ORGANIZATION_ID": "Organisation",
  "CONVERSATION.TITLE": "Konversationstitel",
  "CONVERSATION.QUEUE_ID": "Konversationswarteschlange",
  "CONVERSATION_QUEUE.TITLE": "Titel der Konversationswarteschlange",
  "CONVERSATION.LAST_INBOUND_MESSAGE_AT": "Letzte eingehende Nachricht",
  "CONVERSATION.LAST_OUTBOUND_MESSAGE_AT": "Letzte ausgehende Nachricht",
  "CONVERSATION.LAST_MESSAGE_AT": "Letzte Nachricht",
  "CONVERSATION.LAST_MESSAGE_BY": "Letzte Nachricht von",
  "CONVERSATION.CLIENT_ID": "Konversationsklient",
  "CONVERSATION_CLIENT.TITLE": "Titel des Konversationsklienten",
  "CONVERSATION.CONTACT_ID": "Konversationskontakt-ID",
  "CONVERSATION.JOURNEY_ID": "Journey",
  "CONVERSATION.DISPOSITION_ID": "Konversationsklassifizierung",
  "CONVERSATION_DISPOSITION.TITLE": "Titel der Konversationsklassifizierung",
  "CONVERSATION.SUB_DISPOSITION_ID": "Konversationsunterklassifizierung",
  "CONVERSATION_SUB_DISPOSITION.TITLE": "Titel der Konversationsunterklassifizierung",
  "CONVERSATION.THRD_DISPOSITION_ID": "Konversationsdrittklassifizierung",
  "CONVERSATION_THRD_DISPOSITION.TITLE": "Titel der Konversationsdrittklassifizierung",
  "CONVERSATION.STATUS": "Konversationsstatus",
  "CONVERSATION.CREATED_AT": "Konversation erstellt",
  "CONVERSATION.PRIORITY": "Konversationspriorität",
  "CONVERSATION.USER_ID": "Konversationsagent-ID",
  "USER.ID": "Agent",
  "CONVERSATION.STATUS_AT": "Konversationsstatus geändert",
  "CONVERSATION.RESPONSE_TIME": "Konversationsantwortzeit",
  "CONVERSATION.RESOLUTION_TIME": "Konversationslösungszeit",
  "CONVERSATION.MAIN_CHANNEL": "Hauptkanal der Konversation",
  "CONVERSATION.DIRECTION": "Startrichtung der Konversation",
  "CONVERSATION.TAGS": "Konversations-Tags",
  "CONVERSATION.CHANNELS": "Konversationskanäle",
  "CONVERSATION.DATA": "Benutzerdefinierte Konversationsfelder",
  "MAIL_MESSAGE.ID": "E-Mail-Nachrichten-ID",
  "MAIL_MESSAGE.TITLE": "E-Mail-Nachrichtentitel",
  "MAIL_MESSAGE.SUBJECT": "E-Mail-Betreff",
  "MAIL_MESSAGE.MAIL_ACCOUNT_ID": "E-Mail-Konto",
  "MAIL_MESSAGE.CONTACT_EMAIL": "Kontakt-E-Mail",
  "MAIL_MESSAGE.CREATED_AT": "E-Mail-Nachricht erstellt",
  "CONTACT.ID": "Kontakt-ID",
  "CONTACT.EMAILS": "Kontakt-E-Mails",
  "CONTACT.PHONES": "Kontakt-Telefonnummern",
  "CONTACT.FIRST_NAME": "Kontakt-Vorname",
  "CONTACT.LAST_NAME": "Kontakt-Nachname",
  "CONTACT.DATA": "Benutzerdefinierte Kontaktfelder",
  FORM_SUBMISSION: "Formularübermittlung",
  "FORM_SUBMISSION.ID": "Formularübermittlungs-ID",
  "FORM_SUBMISSION.FORM_WEBSITE_ID": "Formular-Website",
  "FORM_SUBMISSION.CONTACT_EMAIL": "Formular-Kontakt-E-Mail",
  "FORM_SUBMISSION.CONTACT_PHONE": "Formular-Kontakt-Telefon",
  FORM_WEBSITE: "Formular-Website",
  "FORM_WEBSITE.ID": "Formular-Website-ID",
  "MAIL_ACCOUNT.ID": "E-Mail-Konto-ID",
  "DRAFT.CREATED_AT": "Entwurf erstellt",
  "INTERNAL_NOTE.CREATED_AT": "Interne Notiz erstellt",
  "VOICE_CALL.ID": "Sprachanruf-ID",
  "VOICE_CALL.VOICE_ASTERISK_ID": "Voice Asterisk",
  "VOICE_CALL.VOICE_ROUTE_ID": "Sprachroute",
  "VOICE_CALL.QUEUE_ID": "Sprachanruf-Warteschlange",
  VOICE_CALL_BRIDGES_COUNT: "Anzahl der Sprachanrufbrücken",
  "VOICE_CALL.CONTACT_PHONE": "Sprachanruf-Kontakt-Telefon",
  "VOICE_CALL.CREATED_AT": "Sprachanruf erstellt",
  "VOICE_CALL.DIRECTION": "Sprachanrufrichtung",
  "VOICE_CALL.END_TIME": "Sprachanruf beendet",
  VOICE_CALL_FIRST_ANSWERED_AT: "Sprachanruf zuerst beantwortet",
  "VOICE_CALL.VARIABLES": "Sprachvariablen",

  "VOICE_CALL_DURATION.TOTAL": "Gesamtgesprächszeit (Sekunden)",
  "VOICE_CALL_DURATION.PRE_QUEUE_TIME": "Vor-Warteschlangen-Zeit (Sekunden)",
  "VOICE_CALL_DURATION.WAIT_TIME": "Wartezeit (Sekunden)",
  "VOICE_CALL_DURATION.TALK_TIME": "Sprechzeit (Sekunden)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Aktuelle Anrufbrücke Gesamtzeit (Sekunden)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Aktuelle Anrufbrücke Vor-Warteschlangen-Zeit (Sekunden)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Aktuelle Anrufbrücke Wartezeit (Sekunden)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Aktuelle Anrufbrücke Sprechzeit (Sekunden)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Erste Anrufbrücke Gesamtzeit (Sekunden)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Erste Anrufbrücke Vor-Warteschlangen-Zeit (Sekunden)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Erste Anrufbrücke Wartezeit (Sekunden)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Erste Anrufbrücke Sprechzeit (Sekunden)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Letzte Anrufbrücke Gesamtzeit (Sekunden)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Letzte Anrufbrücke Vor-Warteschlangen-Zeit (Sekunden)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Letzte Anrufbrücke Wartezeit (Sekunden)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Letzte Anrufbrücke Sprechzeit (Sekunden)",
  "CURRENT_VOICE_CALL_BRIDGE.ID": "Aktuelle Anrufbrücken-ID",
  "CURRENT_VOICE_CALL_BRIDGE.DIRECTION": "Aktuelle Anrufbrückenrichtung",
  "CURRENT_VOICE_CALL_BRIDGE.CREATED_AT": "Aktuelle Anrufbrücke erstellt",
  "CURRENT_VOICE_CALL_BRIDGE.ANSWER_TIME": "Aktuelle Anrufbrücke beantwortet",
  "CURRENT_VOICE_CALL_BRIDGE.LEAVE_AT": "Aktuelle Anrufbrücke verlassen",
  "CURRENT_VOICE_CALL_BRIDGE.USER_ID": "Aktueller Anrufbrücken-Agent",
  "CURRENT_VOICE_CALL_BRIDGE.QUEUE_ID": "Aktuelle Anrufbrücken-Warteschlange",
  "CURRENT_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Aktuelle Anrufbrücke ist externer Anruf",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TWINNED": "Aktuelle Anrufbrücke ist gekoppelt",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFER": "Aktuelle Anrufbrücke ist Weiterleitung",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Aktuelle Anrufbrücke ist weitergeleitet",
  "CURRENT_VOICE_CALL_BRIDGE.EXIT_REASON": "Aktuelle Anrufbrücke Beendigungsgrund",
  "FIRST_VOICE_CALL_BRIDGE.ID": "Erste Anrufbrücken-ID",
  "FIRST_VOICE_CALL_BRIDGE.DIRECTION": "Erste Anrufbrückenrichtung",
  "FIRST_VOICE_CALL_BRIDGE.CREATED_AT": "Erste Anrufbrücke erstellt",
  "FIRST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Erste Anrufbrücke beantwortet",
  "FIRST_VOICE_CALL_BRIDGE.LEAVE_AT": "Erste Anrufbrücke verlassen",
  "FIRST_VOICE_CALL_BRIDGE.USER_ID": "Erster Anrufbrücken-Agent",
  "FIRST_VOICE_CALL_BRIDGE.QUEUE_ID": "Erste Anrufbrücken-Warteschlange",
  "FIRST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Erste Anrufbrücke ist externer Anruf",
  "FIRST_VOICE_CALL_BRIDGE.IS_TWINNED": "Erste Anrufbrücke ist gekoppelt",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Erste Anrufbrücke ist Weiterleitung",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Erste Anrufbrücke ist weitergeleitet",
  "FIRST_VOICE_CALL_BRIDGE.EXIT_REASON": "Erste Anrufbrücke Beendigungsgrund",
  "LAST_VOICE_CALL_BRIDGE.ID": "Letzte Anrufbrücken-ID",
  "LAST_VOICE_CALL_BRIDGE.DIRECTION": "Letzte Anrufbrückenrichtung",
  "LAST_VOICE_CALL_BRIDGE.CREATED_AT": "Letzte Anrufbrücke erstellt",
  "LAST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Letzte Anrufbrücke beantwortet",
  "LAST_VOICE_CALL_BRIDGE.LEAVE_AT": "Letzte Anrufbrücke verlassen",
  "LAST_VOICE_CALL_BRIDGE.USER_ID": "Letzter Anrufbrücken-Agent",
  "LAST_VOICE_CALL_BRIDGE.QUEUE_ID": "Letzte Anrufbrücken-Warteschlange",
  "LAST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Letzte Anrufbrücke ist externer Anruf",
  "LAST_VOICE_CALL_BRIDGE.IS_TWINNED": "Letzte Anrufbrücke ist gekoppelt",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Letzte Anrufbrücke ist Weiterleitung",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Letzte Anrufbrücke ist weitergeleitet",
  "LAST_VOICE_CALL_BRIDGE.EXIT_REASON": "Letzte Anrufbrücke Beendigungsgrund",
  "USER.USERNAME": "Agent-Benutzername",
  "USER.EMAIL": "Agent-E-Mail",
  "USER.FIRST_NAME": "Agent-Vorname",
  "USER.LAST_NAME": "Agent-Nachname",
  "USER.FULL_NAME": "Agent-Vollständiger Name",
  "_QUEUE.AGENTS_READY": "Bereite Agenten in Warteschlange",
  "_QUEUE.AGENTS_PAUSED": "Pausierte Agenten in Warteschlange",
  "_QUEUE.AGENTS_BUSY": "Beschäftigte Agenten in Warteschlange",
  "_QUEUE.AGENTS_TOTAL": "Gesamtzahl Agenten in Warteschlange",
  "_QUEUE.LONGEST_WAITING": "Längste Wartezeit in Warteschlange",
  "_QUEUE.WAITING": "Wartend in Warteschlange",
  PRIORITY: "Priorität",
  DISPOSITION_ID: "Klassifizierung",
  SUB_DISPOSITION_ID: "Unterklassifizierung",
  THRD_DISPOSITION_ID: "Drittklassifizierung",
  DISPOSITION_NOTE: "Klassifizierungsnotiz",
  STATUS: "Status",
  ADD_TAG: "Tag hinzufügen",
  REMOVE_TAG: "Tag entfernen",
  STATUS_O: "Offen",
  STATUS_RO: "Wiedereröffnet",
  STATUS_C: "Geschlossen",
  STATUS_R: "Gelöst",
  STATUS_P: "Ausstehend",
  STATUS_W: "Wartet auf Dritte",

  PRIORITY_URGENT: "Dringend",
  PRIORITY_HIGH: "Hoch",
  PRIORITY_MEDIUM: "Mittel",
  PRIORITY_LOW: "Niedrig",
  PRIORITY_U: "Dringend",
  PRIORITY_H: "Hoch",
  PRIORITY_M: "Mittel",
  PRIORITY_L: "Niedrig",

  DIRECTION_INBOUND: "Eingehend",
  DIRECTION_OUTBOUND: "Ausgehend",
  DIRECTION_INTERNAL: "Intern",
  DIRECTION_I: "Eingehend",
  DIRECTION_O: "Ausgehend",
  "DIRECTION_-": "Intern",

  EXIT_REASON_REJECT: "Abgelehnt",
  EXIT_REASON_CONTACTHANGEDUP: "Kontakt aufgelegt",
  EXIT_REASON_TIMEOUT: "Zeitüberschreitung",
  EXIT_REASON_FULL: "Warteschlange voll",
  EXIT_REASON_JOINEMPTY: "Warteschlange Beitritt leer",
  EXIT_REASON_LEAVEEMPTY: "Warteschlange Verlassen leer",
  EXIT_REASON_JOINUNAVAIL: "Warteschlange Beitritt nicht verfügbar",
  EXIT_REASON_LEAVEUNAVAIL: "Warteschlange Verlassen nicht verfügbar",

  DEFINED: "Definiert",
  CUSTOM: "Benutzerdefiniert",

  NEWDRAFT: "Neuer Entwurf erstellt",
  NEWINTERNALNOTE: "Neue interne Notiz erstellt",
  CONVERSATIONUPDATED: "Konversation aktualisiert",
  CONVERSATIONCREATED: "Konversation erstellt",
  CONTACTUPDATED: "Kontakt aktualisiert",
  CONTACTCREATED: "Kontakt erstellt",
  NEWINBOUNDMAILMESSAGE: "Neue eingehende E-Mail",
  NEWOUTBOUNDMAILMESSAGE: "Neue ausgehende E-Mail",
  SINCECONVERSATION: "Seit Konversationsaktualisierung",
  NEWINBOUNDVOICECALL: "Neuer eingehender Sprachanruf",
  NEWOUTBOUNDVOICECALL: "Neuer ausgehender Sprachanruf",
  NEWINTERNALVOICECALL: "Neuer interner Sprachanruf",
  INBOUNDVOICECALLHANGEDUP: "Eingehender Sprachanruf beendet",
  OUTBOUNDVOICECALLHANGEDUP: "Ausgehender Sprachanruf beendet",
  INTERNALVOICECALLHANGEDUP: "Interner Sprachanruf beendet",
  INBOUNDVOICECALLANSWERED: "Eingehender Sprachanruf beantwortet",
  OUTBOUNDVOICECALLANSWERED: "Ausgehender Sprachanruf beantwortet",
  INTERNALVOICECALLANSWERED: "Interner Sprachanruf beantwortet",
  NEWINBOUNDVOICECALLBRIDGE: "Neue eingehende Anrufbrücke",
  NEWOUTBOUNDVOICECALLBRIDGE: "Neue ausgehende Anrufbrücke",
  NEWINTERNALVOICECALLBRIDGE: "Neue interne Anrufbrücke",
  INBOUNDVOICECALLBRIDGELEAVE: "Eingehende Anrufbrücke verlassen",
  OUTBOUNDVOICECALLBRIDGELEAVE: "Ausgehende Anrufbrücke verlassen",
  INTERNALVOICECALLBRIDGELEAVE: "Interne Anrufbrücke verlassen",
  INBOUNDVOICECALLBRIDGEANSWERED: "Eingehende Anrufbrücke beantwortet",
  OUTBOUNDVOICECALLBRIDGEANSWERED: "Ausgehende Anrufbrücke beantwortet",
  INTERNALVOICECALLBRIDGEANSWERED: "Interne Anrufbrücke beantwortet",

  CANNED_ANSWER_ID: "Standardantwort",
  CANNED_ANSWER_ID2: "Standardantwort 2",
  CANNED_ANSWER_ID3: "Standardantwort 3",
  CANNED_ANSWER_ID4: "Standardantwort 4",
  MAIL_ACCOUNT_ID: "E-Mail-Konto",
  DESCRIPTION: "Beschreibung",
  URL: "URL",
  STATUS_PORTS: "Status-Ports",
  QUERY_PARAMS: "Abfrageparameter",
  HEADERS: "Header",
  BODY: "Body",
  QUEUE_ID: "Warteschlange",
  MILLISECONDS: "Millisekunden",
  TIMEOUT: "Zeitüberschreitung",
  OPERATIONAL_HOURS_ID: "Betriebszeiten",
  JOURNEY_ID: "Journey ID",
  PUBLISH: "Veröffentlichen",
  ENTER_FULLSCREEN: "Vollbildmodus",
  EXIT_FULLSCREEN: "Vollbildmodus beenden",
  RESET: "Zurücksetzen",
  APPLY: "Anwenden",
  RELOAD_FROM_JOURNEY: "Von Journey neu laden",
  WHEN: "Wenn",
  WITHIN: "Innerhalb",
  NOT_WITHIN: "Nicht innerhalb",
  ERROR: "Fehler",
  SUCCESS: "Erfolg",
  OPTION_1: "Option 1",
  OPTION_2: "Option 2",
  OPTION_3: "Option 3",
  OPTION_4: "Option 4",
  OPTION_5: "Option 5",
  OPTION_6: "Option 6",
  OPTION_7: "Option 7",
  OPTION_8: "Option 8",
  OPTION_9: "Option 9",
  RULE_1: "Regel 1",
  RULE_2: "Regel 2",
  RULE_3: "Regel 3",
  RULE_4: "Regel 4",
  RULE_5: "Regel 5",
  RULE_6: "Regel 6",
  RULE_7: "Regel 7",
  RULE_8: "Regel 8",
  RULE_9: "Regel 9",
  OTHERWISE: "Andernfalls",
  TRIGGERED: "Ausgelöst",
  TIMED_OUT: "Zeit abgelaufen",
  NEXT: "Weiter",
  EDITED: "Bearbeitet",
  ASSIGNED: "Zugewiesen",
  TIME_RANGE: "Zeitspanne",
  MONTHS: "Monate",
  DAYS: "Tage",
  DAY_S: "Tag(e)",
  WEEK_DAYS: "Wochentage",
  FROM: "Von",
  ALWAYS: "Immer",
  INVALID_INTERVAL: "Ungültiges Intervall",
  CREATED: "Erstellt",
  RESOLVED: "Gelöst",
  REOPENED: "Wiedereröffnet",
  FIRSTASSIGNED: "Erste Zuweisung",
  REQUESTERRESPONDED: "Anfragender hat geantwortet",
  AGENTRESPONDED: "Agent hat geantwortet",
  UNTILFIRSTRESPONSEDUE: "Bis erste Antwort fällig",
  FIRSTRESPONSEOVERDUE: "Erste Antwort überfällig",
  UNTILANYRESPONSEDUE: "Bis Antwort fällig",
  ANYRESPONSEOVERDUE: "Antwort überfällig",
  RESOLUTIONTIMEOVERDUE: "Lösungszeit überfällig",
  UNTILRESOLUTIONTIME: "Bis zur Lösungszeit",
  WAITINGFORTHIRDPARTY: "Warten auf Dritte",
  UNIT: "Einheit",
  TIME: "Zeit",
  RESPONSE_TIME: "Antwortzeit",
  RESOLUTION_TIME: "Lösungszeit",
  CONTACT_ID: "Kontakt",
  USER_ID: "Benutzer-ID",
  AGENT_ID: "Agenten-ID",
  HOURS_SHORT: "h",
  MINUTES_SHORT: "m",
  SECONDS_SHORT: "s",
  DAYS_SHORT: "d",
  YESTERDAY: "Gestern",
  JUST_NOW: "Gerade eben",
  NOUPDATEDIN7DAYS: "Keine Updates seit 7 Tagen",
  CUSTOMERRESPONDED: "Kunde hat geantwortet",
  STATUS_AT: "Status geändert am",
  ASSIGNED_AT: "Zugewiesen am",
  FIRST_ASSIGNED_AT: "Erste Zuweisung am",
  CONVERSATION_UPDATE_DISPOSITION_ID: "Disposition aktualisiert",
  CONVERSATION_UPDATE_USER_ID: "Agent aktualisiert",
  CONVERSATION_UPDATE_STATUS: "Status geändert",
  CONVERSATION_UPDATE_FLAG: "Markierung geändert",
  CONVERSATION_UPDATE_RESOLUTION_TIME: "Lösungszeit geändert",
  CONVERSATION_UPDATE_RESPONSE_TIME: "Antwortzeit geändert",
  CONVERSATION_UPDATE_QUEUE: "Warteschlange geändert",
  CONVERSATION_UPDATE_PRIORITY: "Priorität geändert",
  CONVERSATION_UPDATE_CONTACT: "Kontakt geändert",
  CONVERSATION_NEW_MESSAGE: "Neue Nachricht",
  NEW_CONVERSATION: "Neue Konversation",
  MINIMIZE: "Minimieren",
  MAXIMIZE: "Maximieren",
  MINUTES: "Minuten",
  MINUTE_S: "Minute(n)",
  SECOND_S: "Sekunde(n)",
  HOURS: "Stunden",
  HOUR_S: "Stunde(n)",
  LAST_UPDATED: "Zuletzt aktualisiert",
  SUBJECT: "Betreff",
  CC: "CC",
  BCC: "BCC",
  DISPOSE_AND_CLOSE: "Disponieren & Schließen",
  DISPOSE_AND_RESOLVE: "Disponieren & Lösen",
  ADD_NEW: "Neu hinzufügen",
  CONFIRM_DELETION: "Sind Sie sicher, dass Sie dieses Element löschen möchten?",
  OAUTH_AUTHENTICATION_NOT_FOUND: "Authentifizierung fehlgeschlagen!",
  OAUTH_AUTHENTICATION_NOT_FOUND_CONTENT:
    "Ein Administrator in Ihrer Organisation muss ein Konto mit Ihrer E-Mail-Adresse erstellen, bevor Sie sich anmelden können!",
  MAIL_ACCOUNT_AUTHENTICATED: "E-Mail-Konto authentifiziert!",
  AN_ERROR_OCCURRED: "Ein Fehler ist aufgetreten!",
  ATTACHMENTS: "Anhänge",
  ATTACHMENT: "Anhang",
  FILE_NOT_FOUND: 'Datei "{{filename}}" wurde nicht gefunden!',
  NOT_FOUND: "Nicht gefunden!",
  PAGE_NOT_FOUND: "Seite nicht gefunden!",
  PAGE_NOT_FOUND_DESCRIPTION:
    "Die gesuchte Seite existiert nicht! Wenn Sie einem Link gefolgt sind, der Sie hierher gebracht hat, melden Sie dies bitte Ihrem Administrator!",
  NOTIFICATION_A: "Neue Zuweisung",
  NOTIFICATION_B: "Neue Antwort auf meine Konversation",
  NOTIFICATION_C: "Konversationsnachrichten in denen ich markiert bin",
  NOTIFICATION_D: "Statusaktualisierung meiner Konversation",
  NOTIFICATION_E: "Konversation mir zugewiesen",
  NOTIFICATION_F: "Konversation meinen Warteschlangen zugewiesen",
  NOTIFICATION_G: "Erinnerung erste Antwort",
  NOTIFICATION_H: "Erinnerung Lösungszeit",
  NOTIFICATION_I: "Erinnerung nächste Antwort",
  NOTIFICATION_J: "Systeminfo",
  NOTIFICATION_K: "Systemfehler",
  NOTIFICATION_L: "Passwort läuft bald ab",
  NOTIFICATION_M: "Alarm",
  "NOTIFICATION_-": "Interner Anruf",
  MARK_ALL_AS_SEEN: "Alle als gesehen markieren",
  CANNED_ANSWER: "Standardantwort",
  CONTACT_FIRST_NAME: "Vorname des Kontakts",
  CONTACT_LAST_NAME: "Nachname des Kontakts",
  CONTACT_FULL_NAME: "Vollständiger Name des Kontakts",
  CONTACT_EMAIL: "E-Mail des Kontakts",
  CONTACT_PHONE: "Telefon des Kontakts",
  AGENT_FIRST_NAME: "Vorname des Agenten",
  AGENT_LAST_NAME: "Nachname des Agenten",
  AGENT_FULL_NAME: "Vollständiger Name des Agenten",
  CONVERSATION__ID: "Konversations-ID",
  SELECT_CONTACT: "Kontakt auswählen",
  MULTIPLE_CONTACTS_WHERE_FOUND: "Mehrere Kontakte gefunden!",
  MULTIPLE_CONTACTS_WHERE_FOUND_SELECT_CONTACT:
    "Mehrere Kontakte gefunden! Welchen möchten Sie verwenden?",
  WHICH_ONE_DO_YOU_WANT_TO_USE: "Welchen möchten Sie verwenden?",
  EMAILS: "E-Mail-Adressen",
  PHONES: "Telefonnummern",
  STRATEGY: "Strategie",
  ROUND_ROUND_ROBIN: "Round Robin",
  VOICE_QUEUE_STRATEGY_RINGALL: "Alle klingeln",
  VOICE_QUEUE_STRATEGY_ROUNDROBIN: "Round Robin",
  VOICE_QUEUE_STRATEGY_LEASTRECENT: "Am längsten nicht aktiv",
  VOICE_QUEUE_STRATEGY_FEWESTCALLS: "Wenigste Anrufe",
  VOICE_QUEUE_STRATEGY_RRMEMORY: "Round Robin mit Gedächtnis",
  VOICE_QUEUE_STRATEGY_LINEAR: "Linear",
  VOICE_QUEUE_STRATEGY_WRANDOM: "Gewichtet zufällig",
  RINGALL: "Alle klingeln",
  LEASTRECENT: "Am längsten nicht aktiv",
  FEWESTCALLS: "Wenigste Anrufe",
  RANDOM: "Zufällig",
  WRANDOM: "Gewichtet zufällig",
  LINEAR: "Linear",
  RRMEMORY: "Round Robin mit Gedächtnis",
  ROUNDROBIN: "Round Robin",
  QUEUE_STRATEGY_ROUND_ROUND_ROBIN: "Round Robin",
  QUEUE_STRATEGY_PING_ALL: "Alle pingen",
  QUEUE_STRATEGY_LEAST_RECENT: "Am längsten nicht aktiv",
  QUEUE_STRATEGY_FEWEST_CONVERSATIONS: "Wenigste Konversationen",
  QUEUE_STRATEGY_RANDOM: "Zufällig",
  QUEUE_STRATEGY_SKILL_BASED_AGENTS_READY: "Skill-basiert Agenten bereit",
  QUEUE_STRATEGY_SKILL_BASED_TIMEOUT: "Skill-basiert Timeout",
  CREATE_LIST_ID: "Liste erstellen",
  CREATE_LIST_ID_HELP: "Wählen Sie eine Liste zum Hinzufügen neuer Kontakte",
  READ_LISTS: "Listen lesen",
  READ_LISTS_HELP:
    "Listen, aus denen Kontakte gelesen werden. Sowohl System als auch Agenten werden Kontakte aus diesen Listen auswählen.",
  CONVERSATION_FORM_ID: "Konversationsformular",
  SLA_POLICY_ID: "SLA-Richtlinie",
  INBOUND_SLA_POLICY_ID: "SLA-Richtlinie (eingehend)",
  OUTBOUND_SLA_POLICY_ID: "SLA-Richtlinie (ausgehend)",
  REQUIRE_DISPOSITION: "Disposition erforderlich",
  SETTINGS_REQUIRE_DISPOSITION_HELP:
    "Aktivieren Sie dies, um die Auswahl einer Disposition vor dem Schließen der Konversation zu erzwingen",
  REQUIRE_CLIENT: "Client erforderlich",
  SETTINGS_REQUIRE_CLIENT_HELP:
    "Aktivieren Sie dies, um die Auswahl eines Clients vor dem Schließen der Konversation zu erzwingen",
  CONVERSATION_ASSIGNMENT: "Konversationszuweisung",
  SETTINGS_CONVERSATION_ASSIGNMENT_HELP:
    "Wie Konversationen Agenten zugewiesen werden sollen. Manuelle Zuweisung ist immer verfügbar.",
  ONOPEN: "Beim Öffnen",
  ONREPLY: "Bei Antwort",
  ONCLOSE: "Beim Schließen",
  MANUALLY: "Manuell",
  CONVERSATION_ASSIGNMENT_ONOPEN: "Beim Öffnen",
  CONVERSATION_ASSIGNMENT_ONREPLY: "Bei Antwort",
  CONVERSATION_ASSIGNMENT_ONCLOSE: "Beim Schließen",
  CONVERSATION_ASSIGNMENT_MANUALLY: "Manuell",
  __DISPOSITIONS: "Dispositionen",
  __DISPOSITIONS_HELP:
    "Verfügbare Dispositionen zur Auswahl für Konversationen in dieser Warteschlange.",
  __CANNED_ANSWERS: "Standardantworten",
  __AGENT_SIGNATURES: "Agentensignaturen",
  __OUTBOUND_MAIL_ACCOUNTS: "Ausgehende E-Mail-Konten",
  __OUTBOUND_MAIL_ACCOUNTS_HELP:
    "Verfügbare ausgehende E-Mail-Konten beim Erstellen neuer ausgehender E-Mail-Konversationen",
  FORM_NULLABLE_ENABLE: "Wert definieren",
  FORM_NULLABLE_DISABLE: "Wert deaktivieren",
  EDIT_QUEUE: "Warteschlange bearbeiten",
  ADD_QUEUE: "Neue Warteschlange erstellen",
  DELETED: "Gelöscht",
  TWO_FACTOR_AUTH: "2FA",
  ORGANIZATION_ID: "Organisation",
  ADMINPROFILE_ID: "Adminprofil",
  SUPERADMIN_PROFILE_ID: "Superadminprofil",
  __PAUSES: "Pausen",
  __QUEUES: "Warteschlangen",
  __TEAMS: "Teams",
  TEAM: "Team",
  ADDRESS: "Adresse",
  INBOUND_JOURNEY_ID: "Eingehende Journey",
  OUTBOUND_JOURNEY_ID: "Ausgehende Journey",
  IMAP: "IMAP",
  SMTP: "SMTP",
  HOST: "Host",
  PORT: "Port",
  NAME: "Name",
  TLS: "TLS/SSL",
  REMOVE_ON_RECEIVE: "Beim Empfang löschen",
  IMAP_REMOVE_ON_RECEIVE_HELP: "E-Mails beim Empfang aus dem Posteingang löschen",
  BASIC: "Basic",
  XOAUTH2: "OAuth 2",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASIC: "Basic",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICGOOGLE: "Basic (Google)",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICMICROSOFT: "Basic (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2MICROSOFT: "Moderne Authentifizierung (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2GOOGLE: "Moderne Authentifizierung (Google)",
  JOURNEIES: "Journeys",
  JOURNEY_DRAFTS: "Journey-Entwürfe",
  FORM_CHANNEL: "Formularkanal",
  FORM_WEBSITES: "Formular-Websites",
  TIME_INTERVALS: "Zeitintervalle",
  OPERATIONAL_HOURSES: "Betriebszeiten",
  OPERATIONAL_HOURS: "Betriebszeiten",
  SLA_POLICIES: "SLA-Richtlinien",
  CANNED_ANSWERS: "Standardantworten",
  SIGNATURES: "Signaturen",
  FORMS: "Formulare",
  STAFF: "Personal",
  SEARCH_AGENTS: "Agenten suchen",
  STATUS_0: "Offline",
  STATUS_1: "Online",
  STATUS_4: "Bereit (E-Mail)",
  STATUS_8: "Bereit (Sprache)",
  STATUS_512: "Bereit",
  STATUS_1024: "Teilweise beschäftigt",
  STATUS_2048: "Bearbeitet Konversation",
  STATUS_4096: "Pingt",
  STATUS_32768: "Pause",
  STATUS_262144: "Beschäftigt (E-Mail)",
  STATUS_524288: "Beschäftigt (Sprache)",
  STATUS_1048576: "Beschäftigt (Formular)",
  STATUS_33554432: "Beschäftigt",
  FILTER: "Filter",
  ITEMS: "Elemente",
  ALLOW: "Erlauben",
  CANNEDANSWER: "Standardantwort",
  FORM: "Formular",
  SIGNATURE: "Signatur",
  SLAPOLICY: "SLA-Richtlinie",
  OPERATIONALHOURS: "Betriebszeiten",
  TAG: "Tag",
  MAILACCOUNT: "E-Mail-Konto",
  READ: "Lesen",
  CREATE: "Erstellen",
  DELETE: "Löschen",
  PERMISSIONS: "Berechtigungen",
  TIMEINTERVAL: "Zeitintervall",
  LOGIN_STRATEGY: "Anmeldemethode",
  LOGIN_STRATEGY_LOCAL: "Benutzername / Passwort",
  LOGIN_STRATEGY_GOOGLE: "Google",
  LOGIN_STRATEGY_MICROSOFT: "Microsoft",
  ADMIN_AGENT: "Admin & Agent",
  FORMWEBSITE: "Formular-Website",
  SCHEMA_NOT_FOUND: "Formular konnte nicht geladen werden!",
  ITEM_NOT_FOUND: "Das Element, das Sie laden möchten, wurde nicht gefunden!",
  ADMIN_ORGANIZATIONS_CHANGED_RELOAD:
    "Die zugewiesenen Organisationen für Ihren Benutzer wurden aktualisiert, bitte laden Sie manuell neu, damit die Anwendung korrekt funktioniert!",
  LOADING_DEPENDENCIES: "Lade Abhängigkeiten",
  PARENT: "Übergeordnet",
  ACTIVE: "Aktiv",
  ORGANIZATION_2FA_HELP: "Wählen Sie Rollen für erzwungene Zwei-Faktor-Authentifizierung",
  SECONDS: "Sekunden",
  PROPERTY: "Eigenschaft",
  VALUE: "Wert",
  SIGNATURE_ID: "Signatur",
  METHOD: "Methode",
  CONDITIONS: "Bedingungen",
  ADD_CONDITION: "Bedingung hinzufügen",
  FLAG: "Markierung",
  SOURCE: "Quelle",
  SINCE: "Seit",
  JOURNEY_DRAFT: "Journey-Entwurf",
  PENDING: "Ausstehend",
  START_CONVERSATION: "Konversation starten",
  NEW_V_CONVERSATION: "Neue SIP-Konversation",
  NEW_M_CONVERSATION: "Neue E-Mail-Konversation",
  CREATE_NEW_CONTACT: "Neuen Kontakt erstellen",
  OR_CREATE_NEW_CONTACT: "oder neuen Kontakt erstellen",
  LOADING_FORM: "Lade Formular",
  __TAGS: "Tags",
  MAIN_CHANNEL: "Hauptkanal",
  CREATE_CONVERSATION: "Konversation erstellen",
  CHOOSE_AGENT: "Agent auswählen",
  CHOOSE_QUEUE: "Warteschlange auswählen",
  SELECTED: "Ausgewählt",
  CHANGE_CONTACT: "Kontakt ändern",
  CREATE_CONTACT: "Kontakt erstellen",
  REQUIRED: "Erforderlich",
  CONVERSATION_STATUS: "Konversationsstatus",
  NO_NAME: "Kein Name",
  DISPLAY_TITLE: "Anzeigename",
  UI_TYPE: "UI-Typ",
  VALIDATION: "Validierung",
  MIN: "Min",
  CHILD_TYPE: "Untertyp",
  VALID: "Gültig",
  REQUIRED_ON_SAVE: "Erforderlich beim Speichern",
  REQUIRED_ON_CONVERSATION_CLOSE: "Erforderlich beim Schließen der Konversation",
  REQUIRED_ON_FORM_SUBMIT: "Erforderlich bei Formularübermittlung",
  ALIAS: "Alias",
  ALIAS__KEY: "Alias / Schlüssel",
  FIELD_TYPE_DESCRIPTION: "Der Feldtyp macht es für verschiedene Formulartypen verfügbar",
  MINIMUM: "Minimum",
  VALIDATION_MINIMUM_NUMBER_HELP: "Kleinste Zahl, die in dieses Feld eingegeben werden kann",
  VALIDATION_MINIMUM_LIST_HELP: "Mindestanzahl an Optionen",
  VALIDATION_MINIMUM_TEXT_HELP: "Mindestanzahl an Zeichen",
  SETTINGS_VALIDATION_CHILD_TYPE_HELP: "Art der Listenoptionen",
  FIELD_ALIAS_HELP:
    "Werte werden unter diesem eindeutigen Alias gespeichert. Nur alphanumerische Zeichen und _ sind gültige Zeichen.",
  OPTIONS: "Optionen",
  __FIELDS: "Felder",
  WEBSITE: "Webseite",
  POLICY: "Richtlinie",
  FIRST_RESPONSE: "Erste Antwort",
  EVERY_RESPONSE: "Jede Antwort",
  URGENT: "Dringend",
  SLA_POLICY: "SLA-Richtlinie",
  SEARCH_RESULTS: "Suchergebnisse",
  UNASSIGNED: "Nicht zugewiesen",
  NO_QUEUE: "Keine Warteschlange",
  NO_PAUSE: "Keine Pause",
  NOT_DISPOSED: "Nicht klassifiziert",
  NO_FLAG: "Keine Markierung",
  FLAGS: "Markierungen",
  INVALID_2FA_SECRET: "Ungültiger Code",
  __TIME_INTERVALS: "Zeitintervalle",
  TIME_INTERVAL: "Zeitintervall",
  TIMEZONE: "Zeitzone",
  RESET_PASSWORD_DONE: "Eine E-Mail mit Anweisungen wurde an Sie gesendet!",
  PASSWORD_RESET_EMAIL_WILL_BE_SENT:
    "Wir senden eine E-Mail mit Anweisungen an das Konto mit dem angegebenen Benutzernamen, falls dieses Konto existiert!",
  DISPOSITION_REQUIRED_ON_THIS_QUEUE:
    "Klassifizierung muss beim Schließen/Lösen der Konversation gemäß Warteschlangeneinstellungen angegeben werden.",
  CLIENT_REQUIRED_ON_THIS_QUEUE:
    "Kunde muss beim Schließen/Lösen der Konversation gemäß Warteschlangeneinstellungen angegeben werden.",
  CONVERSATION_FORM_NOT_VALID_ON_SAVE:
    "Das Konversationsformular hat Pflichtfelder, die ausgefüllt werden müssen, bevor Sie die Konversation bearbeiten können.",
  CONVERSATION_FORM_NOT_VALID_ON_CLOSE:
    "Das Konversationsformular hat Pflichtfelder, die ausgefüllt werden müssen, bevor Sie die Konversation lösen/schließen können.",
  CONTACT_FORM_NOT_VALID_ON_SAVE:
    "Das Kontaktformular hat Pflichtfelder, die ausgefüllt werden müssen, bevor Sie die Konversation bearbeiten können.",
  CONTACT_FORM_NOT_VALID_ON_CLOSE:
    "Das Kontaktformular hat Pflichtfelder, die ausgefüllt werden müssen, bevor Sie die Konversation lösen/schließen können.",
  CONVERSATION_CANNOT_BE_CLOSED_WITHOUT_USER:
    "Der Konversation muss vor dem Schließen/Lösen ein Agent zugewiesen sein.",
  DISPOSITION_NOTE_NOT_ALLOWED_WHEN_NO_DISPOSITION_SELECTED:
    "Klassifizierungsnotiz ist ohne ausgewählte Klassifizierung nicht erlaubt.",
  QUEUE_MAY_NOT_BE_NULL: "Warteschlange darf für die Konversation nicht undefiniert sein.",
  AGENT_NOT_IN_SELECTED_QUEUE:
    "Zugewiesener Agent ist nicht mit der ausgewählten Warteschlange verbunden.",
  CONTACT_ALREADY_SELECTED: "Kontakt bereits für die Konversation ausgewählt",
  QUEUE_NOT_FOUND_ON_CONVERSATION:
    "Der Konversation muss vor der Kontaktauswahl eine Warteschlange zugewiesen werden.",
  NO_MESSAGES_FOUND_ON_CONVERSATION: "Keine Nachrichten in der Konversation gefunden.",
  QUEUE_NO_READ_LISTS: "Die Warteschlange hat keine zugewiesenen Leselisten.",
  CONVERSATION_FORM_DATA_CLOSE_REQUIRED_TOOLTIP:
    "Pflichtfeld wenn Konversationsstatus auf geschlossen gesetzt wird",
  CONVERSATION_FORM_DATA_CLOSE_TOOLTIP:
    "Pflichtfeld bei Aktualisierung der Konversationseigenschaften",
  VIEW_CONVERSATION: "Konversation anzeigen",
  START_NEW_MAIL_CONVERSATION: "Neue E-Mail-Konversation starten",
  START_NEW_VOICE_CONVERSATION: "Neue Sprachkonversation starten",
  NULLABLE: "Nullable",
  SETTINGS_NULLABLE_HELP: 'Wert kann auf "null" gesetzt werden',
  UI_DATA_TYPE: "UI / Datentyp",
  LAST_SAVE: "Zuletzt gespeichert",
  TITLE_MISSING: "Titel fehlt",
  SELECT_QUEUE: "Warteschlange auswählen",
  CREATE_INTERNAL_NOTE: "Interne Notiz erstellen",
  SAVE_DRAFT: "Entwurf speichern",
  NO_SELECTABLE_DISPOSITIONS: "Keine auswählbaren Klassifizierungen",
  SMTP_PORT_HELP: "Standard: 25, tls: 465",
  IMAP_PORT_HELP: "Standard: 143, tls: 993",
  FORM_ID: "Formular",
  CHANNEL: "Kanal",
  INTERVAL: "Intervall",
  CANNED_ANSWER_TYPE_A: "Agent",
  CANNED_ANSWER_TYPE_Q: "Warteschlange",
  CANNED_ANSWER_CHANNEL_M: "E-Mail",
  CANNED_ANSWER_CHANNEL_S: "SMS",
  SIGNATURE_TYPE_A: "Agent",
  SIGNATURE_TYPE_Q: "Warteschlange",
  SLA_TIME_UNIT_H: "Stunden",
  SLA_TIME_UNIT_D: "Tage",
  FORM_TYPE_CONVERSATION: "Konversation",
  FORM_TYPE_CONTACT: "Kontakt",
  FORM_TYPE_WEBSITE: "Webseite",
  FIELD_TYPE_CONVERSATION: "Konversation",
  FIELD_TYPE_CONTACT: "Kontakt",
  FIELD_TYPE_FORMSUBMISSION: "Formularübermittlung",
  FORM_ELEMENT_TYPE_EXPOSED_INPUT: "Text",
  FORM_ELEMENT_TYPE_EXPOSED_TEXT: "Langer Text",
  FORM_ELEMENT_TYPE_EXPOSED_NUMBER: "Nummer",
  FORM_ELEMENT_TYPE_EXPOSED_SELECT: "Auswahl",
  FORM_ELEMENT_TYPE_EXPOSED_LIST: "Liste",
  FORM_ELEMENT_TYPE_EXPOSED_SWITCH: "Schalter",
  FORM_ELEMENT_TYPE_EXPOSED_RADIO: "Radio",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOXES: "Kontrollkästchen",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOX: "Kontrollkästchen",
  FORM_ELEMENT_TYPE_EXPOSED_EMAIL: "E-Mail",
  FORM_ELEMENT_TYPE_EXPOSED_PHONE: "Telefonnummer",
  FORM_ELEMENT_TYPE_EXPOSED_HTML: "Html",
  FORM_ELEMENT_TYPE_EXPOSED_FILE: "Datei",

  FORM_ELEMENT_CHILD_TYPE_EXPOSED_INPUT: "Text",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_NUMBER: "Nummer",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_PHONE: "Telefonnummer",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_EMAIL: "E-Mail",

  CHANNEL_VOICE: "Sprache",
  CHANNEL_VOICE_INBOUND: "Eingehender Sprachanruf",
  CHANNEL_VOICE_INTERNAL: "Interner Sprachanruf",
  CHANNEL_VOICE_INTERNAL_LIVE: "Laufender interner Sprachanruf",
  CHANNEL_VOICE_TRANSFER: "Sprachübertragung",
  CHANNEL_VOICE_TRANSFER_LIVE: "Laufende Sprachübertragung",
  CHANNEL_VOICE_INBOUND_LIVE: "Laufender eingehender Sprachanruf",
  CHANNEL_VOICE_OUTBOUND: "Ausgehender Sprachanruf",
  CHANNEL_VOICE_OUTBOUND_LIVE: "Laufender ausgehender Sprachanruf",
  CHANNEL_EDIT: "Bearbeiten",
  CHANNEL_CHAT: "Chat",
  CHANNEL_CHAT_INBOUND: "Eingehende Chat-Nachricht",
  CHANNEL_CHAT_OUTBOUND: "Ausgehende Chat-Nachricht",
  CHANNEL_MAIL: "E-Mail",
  CHANNEL_MAIL_INBOUND: "Eingehende E-Mail",
  CHANNEL_MAIL_OUTBOUND: "Ausgehende E-Mail",
  CHANNEL_SMS: "SMS",
  CHANNEL_SMS_INBOUND: "Eingehende SMS",
  CHANNEL_SMS_OUTBOUND: "Ausgehende SMS",
  CHANNEL_FORM: "Formular",
  CHANNEL_CHANSPY: "Kanalüberwachung",
  CHANNEL_DISPOSITION_NOTE: "Klassifizierungsnotiz",
  INTERNAL_CALL: "Interner Anruf",

  CHANNEL_V: "Sprache",
  "CHANNEL_-": "Kein Kanal",
  CHANNEL_C: "Chat",
  CHANNEL_M: "E-Mail",
  CHANNEL_S: "SMS",
  CHANNEL_F: "Formular",
  CHANNEL_IN: "Interne Notiz",
  CHANNEL_In: "Interne Notiz",

  ADMINPROFILE_PERMISSION_ID_USER: "Benutzer",
  ADMINPROFILE_PERMISSION_ID_TEAM: "Team",
  ADMINPROFILE_PERMISSION_ID_ORGANIZATION: "Organisation",
  ADMINPROFILE_PERMISSION_ID_MAILACCOUNT: "E-Mail-Konto",
  ADMINPROFILE_PERMISSION_ID_QUEUE: "Warteschlange",
  ADMINPROFILE_PERMISSION_ID_LIST: "Liste",
  ADMINPROFILE_PERMISSION_ID_ADMINPROFILE: "Administratorprofil",
  ADMINPROFILE_PERMISSION_ID_AGENTPROFILE: "Agentenprofil",
  ADMINPROFILE_PERMISSION_ID_SLAPOLICY: "SLA-Richtlinie",
  ADMINPROFILE_PERMISSION_ID_DISPOSITION: "Klassifizierung",
  ADMINPROFILE_PERMISSION_ID_FORM: "Formular",
  ADMINPROFILE_PERMISSION_ID_FIELD: "Feld",
  ADMINPROFILE_PERMISSION_ID_FORMWEBSITE: "Formular-Website",
  ADMINPROFILE_PERMISSION_ID_JOURNEY: "Journey",
  ADMINPROFILE_PERMISSION_ID_OPERATIONALHOURS: "Betriebszeiten",
  ADMINPROFILE_PERMISSION_ID_PAUSE: "Pause",
  ADMINPROFILE_PERMISSION_ID_CANNEDANSWER: "Standardantwort",
  ADMINPROFILE_PERMISSION_ID_SIGNATURE: "Signatur",
  ADMINPROFILE_PERMISSION_ID_TAG: "Tag",
  ADMINPROFILE_PERMISSION_ID_TIMEINTERVAL: "Zeitintervall",
  ADMINPROFILE_PERMISSION_ID_VOICEROUTE: "Sprachroute",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDING: "Sprachaufnahme",
  ADMINPROFILE_PERMISSION_ID_SOUND: "Ton",
  ADMINPROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Variable",
  ADMINPROFILE_PERMISSION_ID_STATSREPORT: "Statistikbericht",
  ADMINPROFILE_PERMISSION_ID_STATSWIDGET: "Statistik-Widget",
  ADMINPROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Zielbeschränker",
  ADMINPROFILE_PERMISSION_ID_INVOICE: "Rechnung",
  ADMINPROFILE_PERMISSION_ID_VOICESHORTCUT: "Sprachkürzel",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Sprachmailbox",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Sprachmailbox-Nachricht",
  ADMINPROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Telefonnummernkauf",
  ADMINPROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Benutzerdefinierte Metriken",
  ADMINPROFILE_PERMISSION_ID_AUDIT: "Prüfung",
  ADMINPROFILE_PERMISSION_ID_APIKEY: "API-Schlüssel",
  ADMINPROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Kontakt-Benutzerereignis",
  ADMINPROFILE_PERMISSION_ID_CLIENT: "Kunde",
  ADMINPROFILE_PERMISSION_ID_CONTACT: "Kontakt",
  ADMINPROFILE_PERMISSION_ID_WALLBOARD: "Dashboard",
  ADMINPROFILE_PERMISSION_ID_SKILL: "Fähigkeit",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Sprachaufnahme-Transkript",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Sprachaufnahme-Transkripteinstellungen",
  ADMINPROFILE_PERMISSION_ID_POINTSSYSTEM: "Punktesystem",
  ADMINPROFILE_PERMISSION_ID_GDPR: "Sicherheit & Compliance",
  ADMINPROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymisieren",
  ADMINPROFILE_PERMISSION_ID_STATSALARM: "Statistik-Alarm",
  ADMINPROFILE_PERMISSION_ID_VOICECALL: "Sprachanruf",
  ADMINPROFILE_PERMISSION_ID_TEXTMESSAGE: "Textnachricht",

  SUPERADMIN_PROFILE_PERMISSION_ID_USER: "Benutzer",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEAM: "Team",
  SUPERADMIN_PROFILE_PERMISSION_ID_ORGANIZATION: "Organisation",
  SUPERADMIN_PROFILE_PERMISSION_ID_MAILACCOUNT: "E-Mail-Konto",
  SUPERADMIN_PROFILE_PERMISSION_ID_QUEUE: "Warteschlange",
  SUPERADMIN_PROFILE_PERMISSION_ID_LIST: "Liste",
  SUPERADMIN_PROFILE_PERMISSION_ID_ADMINPROFILE: "Administratorprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_AGENTPROFILE: "Agentenprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_SLAPOLICY: "SLA-Richtlinie",
  SUPERADMIN_PROFILE_PERMISSION_ID_DISPOSITION: "Klassifizierung",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORM: "Formular",
  SUPERADMIN_PROFILE_PERMISSION_ID_FIELD: "Feld",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORMWEBSITE: "Formular-Website",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEY: "Journey",
  SUPERADMIN_PROFILE_PERMISSION_ID_OPERATIONALHOURS: "Betriebszeiten",
  SUPERADMIN_PROFILE_PERMISSION_ID_PAUSE: "Pause",
  SUPERADMIN_PROFILE_PERMISSION_ID_CANNEDANSWER: "Standardantwort",
  SUPERADMIN_PROFILE_PERMISSION_ID_SIGNATURE: "Signatur",
  SUPERADMIN_PROFILE_PERMISSION_ID_TAG: "Tag",
  SUPERADMIN_PROFILE_PERMISSION_ID_TIMEINTERVAL: "Zeitintervall",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEROUTE: "Sprachroute",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDING: "Sprachaufnahme",
  SUPERADMIN_PROFILE_PERMISSION_ID_SOUND: "Ton",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Variable",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSREPORT: "Statistikbericht",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSWIDGET: "Statistik-Widget",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEASTERISK: "Sprach-Asterisk",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFF: "Tarif",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFFPARENT: "Tarif-Übergeordnet",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOST: "Monatliche Telefonnummernkosten",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOSTPARENT:
    "Übergeordnete monatliche Telefonnummernkosten",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOST: "Telefonnummer-Einrichtungskosten",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOSTPARENT:
    "Übergeordnete Telefonnummer-Einrichtungskosten",
  SUPERADMIN_PROFILE_PERMISSION_ID_SUPERADMINPROFILE: "Superadministratorprofil",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICE: "Rechnung",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICEPOST: "Rechnungsposten",
  SUPERADMIN_PROFILE_PERMISSION_ID_PREPAIDCREDIT: "Prepaid-Guthaben",
  SUPERADMIN_PROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Zielbeschränker",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Sprachmailbox",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Sprachmailbox-Nachricht",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICESHORTCUT: "Sprachkürzel",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICETRUNK: "Sprach-Trunk",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSTOCK: "Telefonnummernbestand",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Telefonnummernkauf",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Benutzerdefinierte Metriken",
  SUPERADMIN_PROFILE_PERMISSION_ID_RELEASE: "Release",
  SUPERADMIN_PROFILE_PERMISSION_ID_AUDIT: "Prüfung",
  SUPERADMIN_PROFILE_PERMISSION_ID_APIKEY: "API-Schlüssel",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Kontakt-Benutzerereignis",
  SUPERADMIN_PROFILE_PERMISSION_ID_CLIENT: "Kunde",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACT: "Kontakt",
  SUPERADMIN_PROFILE_PERMISSION_ID_SKILL: "Fähigkeit",
  SUPERADMIN_PROFILE_PERMISSION_ID_WALLBOARD: "Dashboard",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Sprachaufnahme-Transkript",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Sprachaufnahme-Transkripteinstellungen",
  SUPERADMIN_PROFILE_PERMISSION_ID_POINTSSYSTEM: "Punktesystem",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPR: "Sicherheit & Compliance",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymisieren",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSALARM: "Statistik-Alarm",
  SUPERADMIN_PROFILE_PERMISSION_ID_AIASSISTANT: "KI-Assistent",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICECALL: "Sprachanruf",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEXTMESSAGE: "Textnachricht",

  REPLY_OR_NOTE: "Antworten oder notieren",

  __USERS: "Agenten",
  CONTACT_MANAGEMENT: "Kontaktverwaltung",
  NO_QUEUES_SELECTED:
    "Sie haben keine Warteschlangen, die mit Ihren zugewiesenen Organisationen verbunden sind. Bitte kontaktieren Sie Ihren Organisationsadministrator, um dieses Problem zu lösen.",
  SOCKET_DISCONNECTED:
    "Ihre Verbindung zum Server wurde unterbrochen. Bitte aktualisieren Sie das Fenster.\n\nUnterbrechungen werden durch instabile Internetverbindung oder Inaktivität verursacht.",
  SOCKET_DISCONNECTED_INACTIVITY: "Socket getrennt wegen Inaktivität",
  WARNING_INACTIVITY:
    'Sie werden wegen Inaktivität abgemeldet. Kehren Sie zum Atlas-Dashboard zurück oder klicken Sie auf "Ich bin zurück", um die Trennung zu verhindern.',
  IAM_BACK: "Ich bin zurück!",
  NO_EMAIL_ACCESS:
    "Sie haben keine Berechtigung zum Senden von E-Mails. Kontaktieren Sie Ihren Administrator für weitere Informationen.",
  NO_CREATE_CHANNELS: "Sie haben keinen Zugriff auf Kanäle zum Erstellen neuer Konversationen.",
  NO_OUTBOUND_QUEUES_ON_CREATE_CONVERSATION:
    "Sie haben keinen Zugriff auf Warteschlangen mit ausgehender Route. Bitte kontaktieren Sie Ihren Organisationsadministrator, um dieses Problem zu lösen.",
  SELECT_AGENTPROFILE_ERROR:
    "Ein Agentenprofil muss ausgewählt werden, um eine Konversation zu erstellen.",
  SEARCH_AGENT_AND_ASSIGN: "Agent suchen und zuweisen",
  SEARCH_QUEUE_AND_ASSIGN: "Warteschlange suchen und zuweisen",
  EDIT_TAGS: "Tags bearbeiten",
  SEARCH_TAGS: "Tags suchen",
  SELECT_DISPOSITION: "Klassifizierung wählen",
  MERGE: "Zusammenführen",
  MERGE_CONVERSATION: "Konversation zusammenführen",
  CONVERSATION_MERGE_EXPLANATION:
    "Interaktionen aus sekundären Vorgängen werden dem primären Vorgang hinzugefügt",
  CONTACT_MERGE_EXPLANATION:
    "Konversationen des sekundären Kontakts werden dem primären Kontakt hinzugefügt",
  SEARCH_CONVERSATION: "Konversation suchen",
  SEARCH_CONTACT: "Kontakt suchen",
  MERGE_CONTACT: "Kontakt zusammenführen",
  PRIMARY: "Primär",
  NO_AGENT: "Kein Agent",
  MAIL_ACCOUNT_IMAP_STATE_A: "Authentifiziert",
  MAIL_ACCOUNT_IMAP_STATE_N: "Keine",
  MAIL_ACCOUNT_IMAP_STATE_E: "Fehler",
  MAIL_ACCOUNT_IMAP_STATE_U: "Nicht autorisiert",
  MAIL_ACCOUNT_IMAP_STATE_C: "Verbunden",
  MAIL_ACCOUNT_IMAP_STATE_CN: "Verbindet",
  MAIL_ACCOUNT_IMAP_STATE_D: "Getrennt",
  MAIL_ACCOUNT_SMTP_STATE_A: "Authentifiziert",
  MAIL_ACCOUNT_SMTP_STATE_N: "Keine",
  MAIL_ACCOUNT_SMTP_STATE_E: "Fehler",
  MAIL_ACCOUNT_SMTP_STATE_U: "Nicht autorisiert",
  MAIL_ACCOUNT_SMTP_STATE_C: "Verbunden",
  MAIL_ACCOUNT_SMTP_STATE_CN: "Verbindet",
  MAIL_ACCOUNT_SMTP_STATE_D: "Getrennt",
  IMAP_STATE: "IMAP-Status",
  SMTP_STATE: "SMTP-Status",
  RECONNECT: "Neu verbinden",
  PERSONIFY_USER: "Benutzer personifizieren",
  RULES: "Regeln",
  JOURNEY_ERROR_LINK_MISSING: "Ein Link muss mit diesem Port verbunden sein",
  JOURNEY_ERROR_FORM_ERROR:
    "Es gibt Fehler im Knotenformular. Doppelklicken Sie, um das Formular zu bearbeiten.",
  JOURNEY_ERROR_DUPLICATE:
    "Doppelte Aktion! Eingangsaktionen dürfen nicht mehr als einmal vorkommen.",
  PUBLISH_JOURNEY: "Journey veröffentlichen",
  NO_ITEMS_WERE_FOUND: "Keine Elemente gefunden",
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_PHRASE:
    'Es konnten keine Konversationen oder Kontakte gefunden werden, die dem Suchbegriff "{{keyword}}" entsprechen',
  NO_CONVERSATIONS_WERE_FOUND_BY_PHRASE:
    'Keine Konversationen gefunden, die dem Suchbegriff "{{keyword}}" entsprechen',
  NO_CONTACTS_WERE_FOUND_BY_PHRASE:
    'Keine Kontakte gefunden, die dem Suchbegriff "{{keyword}}" entsprechen',
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT:
    "Es konnten keine Konversationen oder Kontakte vom ausgewählten Kontakt gefunden werden",
  NO_CONVERSATIONS_WERE_FOUND_BY_SELECTED_CONTACT:
    "Keine Konversationen vom ausgewählten Kontakt gefunden",
  NO_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT: "Keine Kontakte vom ausgewählten Kontakt gefunden",
  NO_CONVERSATION_FOUND_ON_FILTER: "Keine Konversationen gefunden, die Ihren Filtern entsprechen",
  WITH_SELECTED_DO: "Mit ausgewählten ändern",
  CHANGE_STATUS: "Status ändern",
  CHANGE_PRIORITY: "Priorität ändern",
  APPLY_CHANGES_TO_ITEMS: "Änderungen auf {{count}} Elemente anwenden",
  APPLY_AND_RESET: "Anwenden & Zurücksetzen",
  ITEMS_FAILED_TO_UPDATE: "{{count}} Elemente konnten nicht aktualisiert werden",
  CHANGES_APPLIED_TO_ITEMS: "Änderungen auf {{count}} Elemente angewendet",
  MAIL_HAS_NO_BODY: "Keine E-Mail-Inhalte",
  TIME_PAST: "Zeit vergangen",
  START_TYPING_TO_SEARCH: "Tippen Sie, um zu suchen",
  NOT_REMOVABLE_ENTITY: "Darf nicht gelöscht werden!",
  SHOW_MORE: "Mehr anzeigen",
  SHOW_LESS: "Weniger anzeigen",
  UPLOAD: "Hochladen",
  N_FILES: "{{files}} Datei(en)",
  LIST_FILES: "Dateien auflisten",
  LIST_FILE: "Datei auflisten",
  LIST_ID: "Liste",
  SHEET: "Blatt",
  ROW: "Zeile",
  SHEET_N: "Blatt {{n}}",
  ROW_N: "Zeile {{n}}",
  LIST_FILE_FIELDS_HELP: "Die obigen Zeilen sind eine Vorschau zum Sortieren der Spalten.",
  WRITE_LISTS: "Listen schreiben",
  WRITE_LISTS_HELP: "Listen zum Schreiben importierter Kontakte",
  CLEAN_LISTS: "Listen bereinigen",
  CLEAN_LISTS_HELP: "Listen zum Bereinigen von Kontakten",
  DIALPAD: "Wähltastatur",
  NO_NOTIFICATIONS: "Sie haben keine Benachrichtigungen!",
  NO_PAUSES_FOUND: "Keine Pausen gefunden",
  CONNECTION_AT: "Verbindung um",
  RETRIES: "Versuche",
  NO_CONTACT: "Kontakt nicht gefunden!",
  VOICE_MANAGEMENT: "Sprachverwaltung",
  JOINED: "Beigetreten",
  JOINED_QUEUE_DESCRIPTION:
    '"Nachricht" ist der Warteschlange beigetreten. Dieser Port wird ausgelöst, bevor versucht wird, die "Nachricht" zu verteilen.',
  ON_ASSIGN: "Zugewiesen",
  DONE_ANSWERED: "Erledigt (beantwortet)",
  DONE_NO_ANSWER: "Erledigt (keine Antwort)",
  ON_ASSIGN_QUEUE_DESCRIPTION: "Konversation wurde der ausgewählten Warteschlange zugewiesen",
  DONE_ANSWERED_QUEUE_DESCRIPTION:
    "Sprachanruf hat die Warteschlange verlassen aufgrund von Abschluss, nachdem er einem Agenten zugewiesen wurde",
  DONE_NO_ANSWER_QUEUE_DESCRIPTION:
    "Sprachanruf hat die Warteschlange verlassen aufgrund von Zeitüberschreitung oder leerer Warteschlange (abhängig von den Warteschlangeneinstellungen)",
  HANGUP: "Auflegen",
  RECORD: "Aufnehmen",
  HOLD: "Halten",
  UNHOLD: "Fortsetzen",
  TRANSFER: "Übertragen",
  MUTE: "Stummschalten",
  UNMUTE: "Stummschaltung aufheben",
  DISTRIBUTING_CALL: "Anruf wird verteilt",
  VOICE_ASTERISK: "Asterisk",
  VOICE_ASTERISKS: "Asterisks",
  VOICE: "Sprache",
  VOICE_TRUNK: "Trunk",
  VOICE_TRUNKS: "Trunks",
  VOICE_ROUTE: "Route",
  VOICE_ROUTES: "Routen",
  SOUND: "Ton",
  SOUNDS: "Töne",
  JOURNEY_VARIABLE: "Variable",
  JOURNEY_VARIABLES: "Variablen",
  REST: "Rest",
  FROM_DOMAIN: "Von Domain",
  ISO2: "ISO 2",
  VOICE_ASTERISK_ID: "Asterisk",
  INBOUND_VOICE_TRUNK_ID: "Eingehender Trunk",
  OUTBOUND_VOICE_TRUNK_ID: "Ausgehender Trunk",
  NUMBER: "Nummer",
  INTERNAL: "Intern",
  VOICE_SETTINGS: "Spracheinstellungen",
  __OUTBOUND_VOICE_ROUTES: "Ausgehende Sprachrouten",
  VOICE_RECORDING_FORMAT_WAV: "Wav",
  VOICE_RECORDING_FORMAT_WAV49: "Wav49",
  VOICE_RECORDING_FORMAT_GSM: "Gsm",
  NEW_CALL: "Neuer Anruf",
  START_RECORDING: "Manuelle Aufnahme",
  SETTINGS_START_RECORDING_HELP: "Agenten erlauben, Anrufe manuell aufzunehmen",
  PLAY_RECORDING: "Aufnahme abspielen",
  DOWNLOAD_RECORDING: "Aufnahme herunterladen",
  DOWNLOAD_VOICEMAIL: "Sprachnachricht herunterladen",
  PERMISSION_SELF_N: "Keine",
  PERMISSION_SELF_A: "Alle",
  PERMISSION_SELF_S: "Vom Benutzer besessen",
  DOWNLOAD_RECORDING_ERROR: "Beim Herunterladen der Aufnahme ist ein Fehler aufgetreten.",
  PLAY_RECORDING_ERROR: "Beim Abspielen der Aufnahme ist ein Fehler aufgetreten.",
  DOWNLOAD_VOICEMAIL_ERROR: "Beim Herunterladen der Sprachnachricht ist ein Fehler aufgetreten.",
  PLAY_VOICEMAIL_ERROR: "Beim Abspielen der Sprachnachricht ist ein Fehler aufgetreten.",
  NO_RESULTS: "Keine Ergebnisse",
  VARIABLE_ALIAS: "Variablenalias",
  VOICE_CALL: "Sprachanruf",
  CURRENT_VOICE_CALL_BRIDGE: "Aktuelle Anrufbrücke",
  FIRST_VOICE_CALL_BRIDGE: "Erste Anrufbrücke",
  LAST_VOICE_CALL_BRIDGE: "Letzte Anrufbrücke",
  HANGED_UP: "Aufgelegt",
  EDGE_ERROR_FROM_NONE_CHANNEL_SPECIFIC_TO_CHANNEL_SPECIFIC:
    "Verbindungen zwischen Zielknoten mit spezifischen Kanälen zu Quellknoten ohne spezifischen Kanal sind nicht erlaubt",
  EDGE_ERROR_CHANNEL_DONT_EXIST_IN_FROM_NODE:
    "Alle Kanäle im Zielknoten müssen im Quellknoten verfügbar sein",
  EDGE_ERROR_CHANNELS_MISMATCH:
    "Der Kanal des Quellknotens stimmt nicht mit den Kanälen des Zielknotens überein!",
  JOURNEY_VARIABLE_ID: "Variable",
  REST_REQUEST_VARIABLE_HELP:
    "Bei Erfolg, setze die REST-Antwort auf die ausgewählte Variable. Daten werden in String konvertiert.",
  REST_REQUEST_FIELD_HELP:
    "Bei Erfolg, setze die REST-Antwort auf das ausgewählte Feld. Daten werden in den Feldtyp konvertiert.",
  FIELD_ID: "Feld",
  DATA_TYPE: "Datentyp",
  ACW: "ACW",
  VOICE_SETTINGS_ACW_HELP: "After call work",
  MUSICCLASS: "Wartemusik",
  JOINEMPTY: "Leer beitreten",
  FAIL_REASONS: "Fehlergründe",
  FULL: "Voll",
  LEAVEEMPTY: "Leer verlassen",
  JOINUNAVAIL: "Nicht verfügbar beitreten",
  LEAVEUNAVAIL: "Nicht verfügbar verlassen",
  EVENTS: "Ereignisse",
  EVENT_ASSIGNED_DESCRIPTION: "Sprachanruf wurde der Warteschlange zugewiesen",
  EVENT_JOINED_DESCRIPTION: "Sprachanruf ist der Warteschlange beigetreten",
  FAIL_REASON_FULL_DESCRIPTION: "Beendet, da die Warteschlange voll war",
  FAIL_REASON_TIMEOUT_DESCRIPTION: "Beendet, da das Warteschlangen-Timeout erreicht wurde",
  FAIL_REASON_JOINEMPTY_DESCRIPTION: 'Beendet aufgrund des Warteschlangenstatus "JOINEMPTY"',
  FAIL_REASON_LEAVEEMPTY_DESCRIPTION: 'Beendet aufgrund des Warteschlangenstatus "LEAVEEMPTY"',
  FAIL_REASON_JOINUNAVAIL_DESCRIPTION: 'Beendet aufgrund des Warteschlangenstatus "JOINUNAVAIL"',
  FAIL_REASON_LEAVEUNAVAIL_DESCRIPTION: 'Beendet aufgrund des Warteschlangenstatus "LEAVEUNAVAIL"',
  VOICE_QUEUE_QUEUE_STATUS: "Warteschlangenstatus",
  VOICE_SETTINGS_JOINEMPTY_HELP: "Der Warteschlange beitreten wenn leer",
  LEAVEWHENEMPTY: "Verlassen wenn leer",
  VOICE_SETTINGS_LEAVEWHENEMPTY_HELP: "Warteschlange verlassen wenn leer",
  RINGINUSE: "Bei Besetzt klingeln",
  VOICE_SETTINGS_RINGINUSE_HELP:
    "Steuert, ob der Agent zusätzliche Warteschlangenanrufe erhält, auch wenn der Agent beschäftigt ist.",
  MAXLEN: "Maximale Anzahl Warteschlangenanrufe",
  VOICE_SETTINGS_MAXLEN_HELP:
    "Die maximale Anzahl von Anrufen, die in der Warteschlange warten dürfen. Anrufe, die die Warteschlange über diesem Limit erreichen, werden die Journey-Ausführungsstrategie fortsetzen.",
  VOICE_SETTINGS_TIMEOUT_HELP:
    "Wie lange es bei einem Agenten klingeln soll, bevor der Agent als nicht verfügbar gilt.",
  WEIGHT: "Gewichtung",
  VOICE_SETTINGS_WEIGHT_HELP:
    "Legt die Priorität für diese Warteschlange fest, wenn ihre Agenten Mitglieder mehrerer Warteschlangen sind. Ein höherer Gewichtungswert bedeutet, dass diese Warteschlange Priorität über verfügbare Agenten erhält.",
  AUTOPAUSE: "Autopause",
  VOICE_SETTINGS_AUTOPAUSE_HELP:
    "Autopause pausiert ein Warteschlangenmitglied, wenn es nicht auf einen Anruf antwortet.",
  ANNOUNCE: "Ansage",
  VOICE_SETTINGS_ANNOUNCE_HELP:
    "Nachricht, die dem antwortenden Agenten vor der Anrufverbindung vorgespielt wird, normalerweise um den Agenten über die klingelnde Warteschlange zu informieren.",
  ANNOUNCE_FREQUENCY: "Ansagefrequenz",
  VOICE_SETTINGS_ANNOUNCE_FREQUENCY:
    "Wie oft in Sekunden die Position des Anrufers in der Warteschlange oder die geschätzte Wartezeit angesagt werden soll. Auf 0 setzen zum Deaktivieren.",
  MIN_ANNOUNCE_FREQUENCY: "Minimale Ansagefrequenz",
  VOICE_SETTINGS_MIN_ANNOUNCE_FREQUENCY_HELP:
    "Minimale Zeit zwischen dem Start jeder Ansage. Dies ist nützlich für Warteschlangen, in denen sich die Position des Anrufers und/oder Wartezeit schnell ändert, um ständige Ansagen zu vermeiden.",
  PERIODIC_ANNOUNCE_FREQUENCY: "Periodische Ansagefrequenz",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_FREQUENCY_HELP:
    "Wie oft periodische Ansagen gemacht werden sollen.",
  RANDOM_PERIODIC_ANNOUNCE: "Zufällige periodische Ansagen",
  VOICE_SETTINGS_RANDOM_PERIODIC_ANNOUNCE_HELP:
    "Periodische Ansagen in zufälliger Reihenfolge abspielen.",
  RELATIVE_PERIODIC_ANNOUNCE: "Relative periodische Ansagen",
  VOICE_SETTINGS_RELATIVE_PERIODIC_ANNOUNCE_HELP:
    "Wenn auf ja gesetzt, wird die periodische Ansagefrequenz vom Ende jeder Ansage statt vom Beginn jeder Ansage gezählt.",
  ANNOUNCE_HOLDTIME: "Wartezeit ansagen",
  VOICE_SETTINGS_ANNOUNCE_HOLDTIME_HELP:
    "Die geschätzte Wartezeit für wartende Anrufer in der Warteschlange ansagen.",
  ANNOUNCE_POSITION: "Position ansagen",
  VOICE_SETTINGS_ANNOUNCE_POSITION_HELP:
    'Die Warteschlangenposition für wartende Anrufer in der Warteschlange ansagen. Bei Verwendung der Optionen "Limit" oder "Mehr" müssen Sie auch das Ansage-Positionslimit festlegen.',
  ANNOUNCE_POSITION_LIMIT: "Ansage-Positionslimit",
  VOICE_SETTINGS_ANNOUNCE_POSITION_LIMIT_HELP:
    'Legt das Limit fest, ab dem die Position des Anrufers in der Warteschlange angesagt wird. Wenn "Position ansagen" auf "Limit" gesetzt ist, wird die Position nur für diejenigen unter dem Limit angesagt. Wenn auf "Mehr" gesetzt, wird sie für Anrufer über dem Limit angesagt.',
  ANNOUNCE_POSITION_ONLY_UP: "Positionsänderung ansagen",
  VOICE_SETTINGS_ANNOUNCE_POSITION_ONLY_UP_HELP:
    "Die Position des Anrufers nur ansagen, wenn sie sich seit der letzten Ansage verbessert hat.",
  REPORTHOLDTIME: "Wartezeit melden",
  VOICE_SETTINGS_REPORTHOLDTIME_HELP:
    "Die Wartezeit dem antwortenden Agenten melden, bevor der Anruf zum Agenten verbunden wird.",
  PERIODIC_ANNOUNCE: "Periodische Ansagen",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_HELP:
    "Wählen Sie die periodischen Ansagen und ihre Reihenfolge. Wenn die letzte Ansage abgespielt wurde, beginnt die Wiedergabe von vorne.",
  ASTERISK_MUSICCLASS_DEFAULT: "Standard",
  ASTERISK_MUSICCLASS_CELTIC: "Keltisch",
  ASTERISK_MUSICCLASS_JAZZ: "Jazz",
  ASTERISK_MUSICCLASS_CHILLOUT: "Entspannung",
  ASTERISK_MUSICCLASS_GOODBYE: "Auf Wiedersehen",
  ASTERISK_MUSICCLASS_YESTERDAY: "Gestern",
  ASTERISK_MUSICCLASS_BLUES: "Blues",
  ASTERISK_MUSICCLASS_UPBEAT: "Beschwingt",
  ASTERISK_MUSICCLASS_WALTZ: "Walzer",
  ASTERISK_MUSICCLASS_CLAPS: "Applaus",
  ASTERISK_MUSICCLASS_CHEERFUL: "Fröhlich",
  ASTERISK_MUSICCLASS_LULLABY: "Wiegenlied",
  ASTERISK_MUSICCLASS_HAPPY: "Glücklich",
  ASTERISK_MUSICCLASS_HIDDEN: "Versteckt",
  ASTERISK_AUTOPAUSE_YES: "Ja",
  ASTERISK_AUTOPAUSE_NO: "Nein",
  ASTERISK_AUTOPAUSE_ALL: "Alle",
  ASTERISK_ANNOUNCE_HOLDTIME_YES: "Ja",
  ASTERISK_ANNOUNCE_HOLDTIME_NO: "Nein",
  ASTERISK_ANNOUNCE_HOLDTIME_ONCE: "Einmal",
  ASTERISK_ANNOUNCE_POSITION_YES: "Ja",
  ASTERISK_ANNOUNCE_POSITION_NO: "Nein",
  ASTERISK_ANNOUNCE_POSITION_LIMIT: "Limit",
  ASTERISK_ANNOUNCE_POSITION_MORE: "Mehr",
  RECORDING: "Aufnahme",
  ERROR_CHILD_HAS_ASTERISK:
    "Eine oder mehrere Unterorganisationen haben eine Verbindung zu Asterisk. Übergeordnete Organisationen solcher Unterorganisationen dürfen nicht mit Asterisk verbunden sein.",
  ERROR_PARENT_HAS_ASTERISK:
    "Es ist verboten, Asterisk auszuwählen, wenn die übergeordnete Organisation eine Verbindung zu Asterisk hat.",
  ERROR_ORGANIZATION_INACTIVATE_WITH_ACTIVE_CHILD:
    "Organisation mit aktiven Unterorganisationen kann nicht deaktiviert werden. Stellen Sie sicher, dass alle Unterorganisationen deaktiviert sind, bevor diese Aktion ausgeführt wird.",
  VOICE_VOICEMAIL: "Sprachmailbox",
  VOICE_VOICEMAILS: "Sprachmailboxen",
  VOICE_VOICEMAIL_ID: "Sprachmailbox",
  TIMES_TO_RETRY: "Anzahl Wiederholungsversuche",
  MAX_DIGITS: "Maximale Anzahl Ziffern",
  ASTERISK_SOUND_ID: "Ton",
  GOT_DIGITS: "Ziffern erhalten",
  FAILED: "Fehlgeschlagen",
  CHANSPY: "Mithören",
  CHANSPIED_ON: "Wird mitgehört",
  VOICE_LICENSE: "Sprachlizenz",
  LOGIN_PAUSE: "Anmeldepause",
  AUTO_ANSWER: "Automatische Antwort",
  VOICE_SETTINGS_CHANSPY_HELP:
    'Erlaubt das Mithören anderer Benutzer, bei denen "Wird mitgehört" aktiviert ist',
  VOICE_SETTINGS_CHANSPIED_ON_HELP:
    "Erlaubt Benutzern mit Mithörberechtigung, diesen Benutzer mitzuhören",
  TWO_FACTOR_AUTH_HELP:
    "Deaktiviert lassen, um die 2FA-Einstellungen der Organisation zu lesen, aktivieren um die Einstellungen zu überschreiben",
  TWO_FACTOR_AUTH_ENABLE_HELP:
    "2FA für diesen Benutzer aktivieren/deaktivieren und Organisationseinstellungen überschreiben",
  DELAY: "Verzögerung",
  LEAVE_EMPTY_FOR_ALL_QUEUES: "Leer lassen für alle Warteschlangen",
  AUTO_ACCEPT: "Automatisch akzeptieren",
  ENABLE_TO_SELECT_SPECIFIC_QUEUES: "Aktivieren, um spezifische Warteschlangen zu akzeptieren",
  AUTO_ACCEPT_HELP: "Empfangene Benachrichtigungen automatisch akzeptieren",
  ADD_CALL_TO_CONVERSATION: "Anruf zur Konversation hinzufügen",
  CALL_SEARCH: "Nummer oder Name eingeben",
  SELECT_VOICE_QUEUE_ROUTE: "Warteschlange & Route wählen",
  SOMETHING_WENT_WRONG: "Etwas ist schiefgelaufen",
  SPY: "Mithören",
  NO_CONTACT_SUGGESTIONS_WERE_FOUND: "Keine Kontaktvorschläge gefunden!",
  MAIL_SETTINGS: "E-Mail-Einstellungen",
  REPLY_TO: "Antworten an",
  REPLIED_TO: "Geantwortet an",
  NOTE: "Notiz",
  ANSWER: "Antworten",
  CALL_RECORDING: "Anrufaufzeichnung",
  OUTBOUND_CALL: "Ausgehender Anruf",
  LEAVE_DISABLED_FOR_DEFAULT: "Deaktiviert lassen für Standard",
  SELECT_NUMBER: "Nummer auswählen",
  RINGING: "Klingelt",
  STOP: "Stopp",
  STOP_RECORDING: "Aufnahme stoppen",
  DOWNLOAD: "Herunterladen",
  RECORDING_CONVERSATION: "Konversation wird aufgezeichnet",
  RECORDING_VOICEMAIL: "Sprachnachricht wird aufgezeichnet",
  VOICEMAIL: "Sprachnachricht",
  CALL_BACK: "Rückruf",
  WAIT_TIME: "Wartezeit",
  TALK_TIME: "Sprechzeit",
  CALL_LENGTH: "Anrufdauer",
  MISSED_CALL: "Verpasster Anruf",
  LOCAL_PHONE_NUMBER: "Lokale Telefonnummer",
  PHONE: "Telefon",
  PHONE_NUMBER: "Telefonnummer",
  NOT_ASSIGNED: "Nicht zugewiesen",
  CONVERSATION_ALREADY_ASSIGNED: "Die Konversation ist bereits zugewiesen!",
  OUTBOUND_VOICE_QUEUE_ID: "Ausgehende Warteschlange",
  OUTBOUND_VOICE_QUEUE_ROUTE_ID: "Ausgehende Route",
  CONVERSATION_MERGE_FROM:
    "Diese Konversation wurde mit Konversation {{conversation}} zusammengeführt",
  CONVERSATION_MERGE_TO: " wurde mit dieser Konversation {{conversation}} zusammengeführt",
  ON_HOLD: "In Wartestellung",
  LISTENED_IN: "Mitgehört",
  LISTEN_ONLY: "Nur zuhören",
  LISTEN_AND_TALK_TO_AGENT: "Zuhören & mit Agent sprechen",
  SELECT_AN_OPTION: "Option auswählen",
  ONGOING_CALL: "Laufender Anruf",
  INTERNAL_NOTE: "Interne Notiz",
  VIEW_DETAILS: "Details anzeigen",
  CONTAINS_TRANSFER_FROM: "Enthält Weiterleitung von",
  TRANSFERRED_TO: "Weitergeleitet an",
  HAS_NOTE: "Hat Notiz",
  NOTE_TO: "Notiz an",
  REPLIED_BY: "Beantwortet von",
  SELECT_QUEUE_TO_SELECT_CONTACT: "Warteschlange muss vor Kontaktauswahl gewählt werden!",
  CONTACT_NOT_AUTOMATICALLY_ASSIGNED: "Ein Kontakt konnte nicht automatisch zugewiesen werden!",
  NEW_MAIL_MESSAGE: "Neue E-Mail-Nachricht",
  UNABLE_TO_PERSONIFY:
    "Dieses Konto kann nicht personifiziert werden. Dies liegt wahrscheinlich an Ihren Berechtigungen.",
  USER_CREATE_USERNAME_TAKEN:
    "Der Benutzername, den Sie verwenden möchten, ist bereits vergeben! Beachten Sie, dass dieser Benutzername zu einer anderen Organisation gehören könnte!",
  USER_CREATE_EMAIL_TAKEN:
    "Die E-Mail-Adresse, die Sie verwenden möchten, ist bereits vergeben! Beachten Sie, dass diese E-Mail-Adresse zu einer anderen Organisation gehören könnte!",
  USER_UPDATE_EMAIL_TAKEN:
    "Die E-Mail-Adresse, die Sie verwenden möchten, ist bereits vergeben! Beachten Sie, dass diese E-Mail-Adresse zu einer anderen Organisation gehören könnte!",
  SOCKET_DISCONNECTED_USER_ACTIVE_NOT:
    "Ein Administrator hat Ihr Konto deaktiviert. Sie werden abgemeldet!",
  MAY_NOT_DELETE_YOURSELF: "Sie können sich nicht selbst löschen!",
  NO_DATA_CREATED_YET: "Es wurden noch keine Daten erstellt!",
  NO_DATA_MATCHING_YOUR_FILTERS: "Keine Daten gefunden, die Ihren Filtern entsprechen!",
  RESET_FILTERS: "Filter zurücksetzen",
  FORM_PREVIEW_NOT_EDITABLE: "Nur Vorschau! Diese Eigenschaft kann nicht geändert werden.",
  AGENTPROFILE_AGENTS: "Agenten des Agentenprofils",
  ADMINPROFILE_USERS: "Benutzer des Administratorprofils",
  QUEUE_AGENTS: "Warteschlangen-Agenten",
  NO_USERS_WERE_FOUND: "Keine Benutzer gefunden",
  NO_AGENTS_WERE_FOUND: "Keine Agenten gefunden",
  DRAFT_FROM_MAIL_ACCOUNT_REQUIRED: "Von E-Mail-Konto erforderlich",
  DRAFT_TO_MAIL_ACCOUNT_REQUIRED: "An E-Mail-Adresse erforderlich",
  MAIL_MESSAGE_BODY_REQUIRED_ON_OUTBOUND: "E-Mail-Nachricht erforderlich!",
  SMTP_ERROR: "Fehler beim E-Mail-Versand",
  RESEND_EMAIL: "E-Mail erneut senden",
  RESEND_INITIATED: "Erneuter Versand initiiert",
  MAIL_MESSAGE_NOT_FAILED: "Erneuter Versand nur für fehlgeschlagene E-Mail-Nachrichten verfügbar",
  NO_SUBJECT: "Kein Betreff",
  NO_CUSTOM_FILTERS_CREATED: "Keine benutzerdefinierten Filter erstellt",
  DRAFT_SEND_CONVERSATION_QUEUE_REQUIRED:
    "Warteschlange muss für Konversation gewählt werden, bevor Entwurf gesendet werden kann",
  MAIL_ACCOUNT_NOT_IN_queueS:
    "Gewähltes E-Mail-Konto ist nicht in den ausgehenden E-Mail-Konten der Warteschlange verfügbar",
  MAIL_ACCOUNT_REQUIRED: "E-Mail-Konto erforderlich",
  UNANSWERED_CALL: "Unbeantworteter Anruf",
  SELECT_OR_CREATE_CONTACT: "Kontakt auswählen oder erstellen",
  ROUTING: "Routing",
  TRUNK_TYPE_CONNECTEL: "Connectel Standard",
  TRUNK_TYPE_CUSTOM: "Benutzerdefinierter Trunk",
  MAIL_ACCOUNT_AUTHENTICATION_FAILED: "E-Mail-Konto-Authentifizierung fehlgeschlagen",
  AUDIO_NOT_PLAYABLE: "Audiodatei kann nicht abgespielt werden",
  SIP_STATE: "SIP-Status",
  SIP_STATE_UNDEFINED: "Nicht initialisiert",
  SIP_STATE_INITIAL: "Verbindet",
  SIP_STATE_UNREGISTERED: "Nicht registriert",
  SIP_STATE_TERMINATED: "Beendet",
  SIP_STATE_REGISTERED: "Registriert",
  ACTIVE_IN_QUEUE: "Derzeit aktiv in Warteschlange",
  FILENAME: "Datei",
  PRESSED_NONE_VALID: "Ungültige Option gedrückt",
  PRESSED_1: "1 gedrückt",
  PRESSED_2: "2 gedrückt",
  PRESSED_3: "3 gedrückt",
  PRESSED_4: "4 gedrückt",
  PRESSED_5: "5 gedrückt",
  PRESSED_6: "6 gedrückt",
  PRESSED_7: "7 gedrückt",
  PRESSED_8: "8 gedrückt",
  PRESSED_9: "9 gedrückt",
  "PRESSED_*": "* gedrückt",
  "PRESSED_#": "# gedrückt",
  INITIATED: "Initiiert",
  RESET_2FA: "Zwei-Faktor-Authentifizierung zurücksetzen",
  PASSWORD_RESET_SUCCESSFUL: "Passwort-Zurücksetzung erfolgreich!",
  PASSWORD_RESET_REQUEST_SUCCESSFUL: "Anfrage zur Passwort-Zurücksetzung erfolgreich!",
  PASSWORD_INVALID: "Das Passwort muss mindestens 6 Zeichen lang sein",
  FILL_PASSWORD_TO_RESET: "Passwort zum Zurücksetzen eingeben",
  RESET_TOKEN_HAS_EXPIRED:
    "Ungültiger oder abgelaufener Token. Bitte fordern Sie eine neue Passwort-Zurücksetzung an.",
  VOICE_TIMELINE_ERROR: "Fehler",
  INBOUND_CALL_INITIATED: "Eingehender Anruf initiiert",
  OUTBOUND_CALL_INITIATED: "Ausgehender Anruf initiiert",
  INTERNAL_CALL_INITIATED: "Interner Anruf initiiert",
  SYSTEM_ANSWER: "System hat Anruf angenommen",
  TIMELINE_JOURNEY_JOB: "Journey-Aktion",
  RULE_WITH_INDEX_X_MATCHED: 'Regel mit Index "{{i}}" wurde erfüllt',
  TRY_X_BUTTON_X_PRESSED: 'Versuch {{try}}: Taste "{{num}}" gedrückt',
  TRY_X_DIGITS_X_PRESSED: 'Versuch {{try}}: Ziffern "{{num}}" gedrückt',
  USER_X_PINGED: 'Agent "{{user}}" angepingt',
  USER_X_NOTIFICATION_END: 'Agent "{{user}}" Ping beendet',
  NOTIFICATION_X_ACTION: "Aktion: {{action}}",
  TIMEOUT_X_SECONDS: "Timeout: {{sec}} Sekunden",
  NOTIFICATION_TIMEOUT_X_SECONDS: "Benachrichtigungs-Timeout: {{sec}} Sekunden",
  VOICE_QUEUE_ASSIGNED: "Sprachanruf-Warteschlange zugewiesen",
  VOICE_QUEUE_JOINED: "Sprachanruf-Warteschlange beigetreten",
  VOICE_QUEUE_DONE: "Sprachanruf-Warteschlange verlassen",
  NOTIFICATION_ACTION_ACCEPT: "Akzeptiert",
  NOTIFICATION_ACTION_ACCEPT_FAIL: "Akzeptieren fehlgeschlagen",
  NOTIFICATION_ACTION_REJECT: "Abgelehnt",
  NOTIFICATION_ACTION_REJECT_FAIL: "Ablehnen fehlgeschlagen",
  NOTIFICATION_ACTION_TIMEOUT: "Timeout",
  NOTIFICATION_ACTION_ANSWERED_ELSEWHERE: "Anderswo beantwortet",
  NOTIFICATION_ACTION_SYSTEM_HANGED_UP: "System hat aufgelegt (Timeout)",
  NOTIFICATION_ACTION_CONTACT_HANGED_UP: "Kontakt hat vor Timeout/Antwort aufgelegt",
  RECORDING_STARTED_BY_X: 'Aufnahme gestartet von "{{user}}"',
  RECORDING_STOPPED_BY_X: 'Aufnahme gestoppt von "{{user}}"',
  SYSTEM: "System",
  X_LEFT_CALL: "{{leg}} hat den Anruf verlassen",
  LEFT_CALL_WITH_REASON: 'Konferenz verlassen mit Grund "{{reason}}"',
  LEFT_QUEUE_WITH_REASON: 'Warteschlange "{{queue}}" verlassen mit Grund "{{reason}}"',
  UNKNOWN: "Unbekannt",
  USER_ANSWERED: '"{{user}}" hat geantwortet',
  QUEUE_X_WITH_TIMEOUT: 'Warteschlange "{{queue}}", mit {{timeout}} Sekunden Timeout',
  AVAILABLE_VOICE_MENU_OPTIONS: 'Verfügbare Optionen "{{options}}", und "{{retries}}" Versuche',
  CALL_TRANSFERRED_OUT: "Anruf weitergeleitet",
  CALL_TRANSFERRED_IN: "Anruf empfangen",
  ATTENDED: "Begleitet",
  BLIND: "Blind",
  TRANSFER_TYPE_X: 'Weiterleitungstyp "{{type}}"',
  ROUTE_X: 'Route "{{route}}"',
  CONTACT_ANSWERED: "Kontakt hat geantwortet",
  BG_COLOR: "Hintergrundfarbe",
  SUB_GROUP: "Untergruppe",
  VARIABLE: "Variable",
  STATIC: "Statisch",
  KEY: "Schlüssel",
  ESCAPE_DIGITS: "Escape-Ziffern",
  LANGUAGE: "Sprache",
  LANGUAGE_SE: "se (Schwedisch)",
  LANGUAGE_FI: "fi (Finnisch)",
  LANGUAGE_EN: "en (English)",
  LANGUAGE_DE: "de (Deutsch)",
  LANGUAGE_FR: "fr (Französisch)",
  LANGUAGE_HI: "hi (Hindi)",
  LANGUAGE_IT: "it (Italienisch)",
  LANGUAGE_NO: "no (Norwegisch)",
  LANGUAGE_NL: "nl (Niederländisch)",
  LANGUAGE_DK: "dk (Dänisch)",
  LANGUAGE_PL: "pl (Polnisch)",
  VOICE_LANGUAGE_SE: "se (Schwedisch)",
  VOICE_LANGUAGE_FI: "fi (Finnisch)",
  VOICE_LANGUAGE_EN: "en (English)",
  VOICE_LANGUAGE_DE: "de (Deutsch)",
  VOICE_LANGUAGE_FR: "fr (Französisch)",
  VOICE_LANGUAGE_HI: "hi (Hindi)",
  VOICE_LANGUAGE_IT: "it (Italienisch)",
  VOICE_LANGUAGE_NO: "no (Norwegisch)",
  VOICE_LANGUAGE_NL: "nl (Niederländisch)",
  VOICE_LANGUAGE_DK: "dk (Dänisch)",
  VOICE_LANGUAGE_PL: "pl (Polnisch)",
  VOICE_SHORTCUTS: "Sprachkürzel",
  VOICE_SHORTCUT: "Sprachkürzel",
  __VOICE_SHORTCUTS: "Kurzwahlen",
  HEADER_FILTERS_COUNT: "{{count}} Filter zurücksetzen",
  REAUTHENTICATE_ACCOUNT: "Neu authentifizieren",
  IS_LIVE: "Live",
  IS_SHARED: "Geteilt",
  SHARED: "Geteilt",
  WITH: "Mit",
  ADMINPROFILE_NO_FILTER_ON_CREATION:
    "Administratorprofil-Berechtigung darf bei der Erstellung keine Filter haben. Erstellen Sie das Administratorprofil ohne Filter und fügen Sie Filter bei der Bearbeitung hinzu!",
  ADMINPROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Der Filter des Administratorprofils muss sich selbst für erlaubte Berechtigungen enthalten!",
  SUPERADMIN_PROFILE_NO_FILTER_ON_CREATION:
    "Superadmin-Profil-Berechtigung darf bei der Erstellung keine Filter haben. Erstellen Sie das Superadmin-Profil ohne Filter und fügen Sie Filter bei der Bearbeitung hinzu!",
  SUPERADMIN_PROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Der Filter des Superadmin-Profils muss sich selbst für erlaubte Berechtigungen enthalten!",
  AUDIO_SETTINGS: "Audioeinstellungen",
  SELECT_RINGTONE_SPEAKER: "Klingelton-Lautsprecher auswählen",
  RINGTONE_SPEAKER: "Klingelton-Lautsprecher",
  SELECT_VOICE_SPEAKER: "Sprach-Lautsprecher auswählen",
  VOICE_SPEAKER: "Sprach-Lautsprecher",
  SELECT_MICROPHONE: "Mikrofon auswählen",
  ERROR_SETTING_SPEAKER:
    "Beim Einstellen des Lautsprechers ist ein Fehler aufgetreten, bitte wählen Sie einen anderen Lautsprecher bevor Sie fortfahren!",
  ERROR_SETTING_RINGTONE_SPEAKER:
    "Beim Einstellen des Klingelton-Lautsprechers ist ein Fehler aufgetreten, bitte wählen Sie einen anderen Lautsprecher bevor Sie fortfahren!",
  NO_SPEAKERS_FOUND: "Keine Lautsprecher gefunden!",
  NO_MICROPHONES_FOUND: "Keine Mikrofone gefunden!",
  SELECTED_RINGTONE_SPEAKER_NOT_FOUND:
    'Ausgewählter Klingelton-Lautsprecher nicht gefunden! Wir haben Ihren Klingelton-Lautsprecher auf "Systemstandard" geändert, bis Sie eine Änderung vornehmen!',
  SELECTED_SPEAKER_NOT_FOUND:
    'Ausgewählter Lautsprecher nicht gefunden! Wir haben Ihren Lautsprecher auf "Systemstandard" geändert, bis Sie eine Änderung vornehmen!',
  SELECTED_MICROPHONE_NOT_FOUND:
    'Ausgewähltes Mikrofon nicht gefunden! Wir haben Ihr Mikrofon auf "Systemstandard" geändert, bis Sie eine Änderung vornehmen!',
  DEFAULT_MICROPHONE: "Standard-Mikrofon",
  DEFAULT_SPEAKER: "Standard-Lautsprecher",
  BROWSER_DOES_NOT_SUPPORT_SPEAKER_SET: "Ihr Browser unterstützt keine Lautsprecherauswahl",
  UPLOAD_RECORDING: "Aufnahme hochladen",
  RESTART: "Neu starten",
  RECORD_VOICE: "Stimme aufnehmen",
  RECORDING_UPLOAD_SUCCESSFULL: "Upload erfolgreich",
  RECORDING_UPLOAD_ERROR: "Beim Upload ist ein Fehler aufgetreten",
  WE_COULD_NOT_DETECT_SOUND_FROM_MICROPHONE: "Wir konnten keinen Ton von Ihrem Mikrofon erkennen",
  ECHO_TEST: "Gerätetest",
  START: "Start",
  START_TEST: "Test starten",
  STOP_TEST: "Test stoppen",
  ECHO_TEST_SOUND_QUESTION: "Hören Sie den Ton?",
  ECHO_TEST_MICROPHONE_QUESTION: "Hören Sie das Echo?",
  ECHO_TEST_PASSED: "Gut. Ihr Lautsprecher und Mikrofon scheinen wie erwartet zu funktionieren!",
  PLEASE_CONFIRM_FOLLOWING: "Bitte bestätigen Sie Folgendes",
  PERIOD: "Zeitraum",
  TREND: "Trend",
  ADJUST: "Anpassen",
  TEXT_INDICATOR: "Indikator",
  METRICS: "Metriken",
  GROUP_BY: "Gruppieren nach",
  SUB_GROUP_BY: "Untergruppieren nach",
  SORT_BY: "Sortieren nach",
  DESC: "Absteigend",
  SPLIT_BY: "Aufteilen nach",
  SUMMARY: "Zusammenfassung",
  MAIN_CHANNELS: "Hauptkanäle",
  THREAD_CHANNELS: "Thread-Kanäle",
  MESSAGE_CHANNELS: "Nachrichtenkanäle",
  ORGANIZATION_IDS: "Organisationen",
  CONVERSATION_USER_IDS: "Konversationsbenutzer",
  THREAD_USER_IDS: "Thread-Benutzer",
  MESSAGE_USER_IDS: "Nachrichtenbenutzer",
  CONVERSATION_QUEUE_IDS: "Konversations-Warteschlangen",
  THREAD_QUEUE_IDS: "Thread-Warteschlangen",
  MESSAGE_QUEUE_IDS: "Nachrichten-Warteschlangen",
  DISPOSITION_IDS: "Klassifizierungen",
  SUB_DISPOSITION_IDS: "Unterklassifizierungen",
  THRD_DISPOSITION_IDS: "Dritte Klassifizierungen",
  PAUSE_IDS: "Pausen",
  TEAM_IDS: "Teams",
  THREAD_TEAM_IDS: "Thread-Teams",
  MESSAGE_TEAM_IDS: "Nachrichten-Teams",
  MAIL_ACCOUNT_IDS: "E-Mail-Konten",
  VOICE_ROUTE_IDS: "Sprachrouten",
  UI: "Benutzeroberfläche",
  TABLE: "Tabelle",
  GROUP: "Gruppe",
  ODD_ROW: "Ungerade Zeile",
  EVEN_ROW: "Gerade Zeile",
  WIDTH: "Breite",
  COLOR: "Farbe",
  BORDER: "Rahmen",
  DISPLAY_HEADER: "Kopfzeile anzeigen",
  LEGEND: "Legende",
  PLACEMENT: "Platzierung",
  CUTOUT: "Ausschnitt",
  NESTED_METRICS: "Kennzahlen",
  SUBTRACT: "Subtrahieren",
  METRIC: "Kennzahl",
  VALUE_ARR: "Optionen",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DATA: "Daten",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFDATA: "Differenz",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFPERCENTAGE: "Prozent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROW: "Pfeil",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDATA: "Pfeil + Daten",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFDATA: "Pfeil + Differenz",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFPERCENTAGE: "Pfeil + Prozent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICON: "Symbol",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDATA: "Symbol + Daten",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFDATA: "Symbol + Differenz",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFPERCENTAGE: "Symbol + Prozent",
  STATS_WIDGET_TABLE_TREND_INDICATOR_BACKGROUND: "Hintergrund",
  STATS_WIDGET_TYPE_TABLE: "Tabelle",
  STATS_WIDGET_TYPE_GRID: "Raster",
  STATS_WIDGET_TYPE_BAR: "Balken",
  STATS_WIDGET_TYPE_LINE: "Linie",
  STATS_WIDGET_TYPE_TEXT: "Text",
  STATS_WIDGET_TYPE_PIE: "Kreis",
  STATS_WIDGET_TYPE_RADAR: "Radar",
  STATS_WIDGET_TYPE_POLAR: "Polar",
  STATS_WIDGET_TYPE_TIMELINE: "Zeitstrahl",
  STATS_WIDGET_PERIOD_THIS_MONTH: "Dieser Monat",
  STATS_WIDGET_PERIOD_LIVE_TODAY: "Heute (Live)",
  STATS_WIDGET_PERIOD_TODAY: "Heute",
  STATS_WIDGET_PERIOD_LAST_24_HOURS: "Letzte 24 Stunden",
  STATS_WIDGET_PERIOD_YESTERDAY: "Gestern",
  STATS_WIDGET_PERIOD_THIS_WEEK: "Diese Woche",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN: "Diese Woche (Start Sonntag)",
  STATS_WIDGET_PERIOD_THIS_WEEK_EXCEPT_TODAY: "Diese Woche (außer heute)",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN_EXCEPT_TODAY: "Diese Woche (Start Sonntag, außer heute)",
  STATS_WIDGET_PERIOD_LAST_WEEK: "Letzte Woche",
  STATS_WIDGET_PERIOD_LAST_7_DAYS: "Letzte 7 Tage",
  STATS_WIDGET_PERIOD_THIS_MONTH_EXCEPT_TODAY: "Dieser Monat (außer heute)",
  STATS_WIDGET_PERIOD_LAST_30_DAYS: "Letzte 30 Tage",
  STATS_WIDGET_PERIOD_LAST_MONTH: "Letzter Monat",
  STATS_WIDGET_PERIOD_CUSTOM: "Benutzerdefiniert",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_DAY: "Tag(e)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_WEEK: "Woche(n)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_MONTH: "Monat(e)",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GT: "Größer als",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GTE: "Größer oder gleich",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LT: "Kleiner als",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LTE: "Kleiner oder gleich",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NOT_EQUAL_NUM: "Ungleich",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_EQUAL_NUM: "Gleich",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_ANY_OF_NUM: "Einer der ausgewählten Werte",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NONE_OF_NUM: "Keiner der ausgewählten Werte",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT: "Größer als",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT_TREND: "Größer als Trendwert",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE: "Größer oder gleich",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE_TREND: "Größer oder gleich Trendwert",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT: "Kleiner als",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT_TREND: "Kleiner als Trendwert",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE: "Kleiner oder gleich",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE_TREND: "Kleiner oder gleich Trendwert",
  STATS_WIDGET_THRESHOLD_COMPARISON_NOT_EQUAL_NUM: "Ungleich",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM: "Gleich",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM_TREND: "Gleich Trendwert",
  STATS_WIDGET_THRESHOLD_COMPARISON_ANY_OF_NUM: "Einer der ausgewählten Werte",
  STATS_WIDGET_THRESHOLD_COMPARISON_NONE_OF_NUM: "Keiner der ausgewählten Werte",
  METRIC_FORMAT_STATIC: "Statischer Wert",
  METRIC_FORMAT_ValuePCS: "St (Stück)",
  METRIC_FORMAT_ValuePercent: "% (Prozent)",
  METRIC_FORMAT_ValueDecimal: "Dezimal",
  METRIC_FORMAT_SECONDS_TO_HH_MM: "HH:mm",
  METRIC_FORMAT_HH_MM: "HH:mm",
  METRIC_FORMAT_SECONDS_TO_HH_MM_SS: "HH:mm:ss",
  METRIC_FORMAT_SECONDS_TO_MM_SS: "mm:ss",
  METRIC_FORMAT_HH_MM_SS: "HH:mm:ss",
  METRIC_FORMAT_COUNTDOWN: "Countdown",
  METRIC_FORMAT_MM_SS: "mm:ss",
  METRIC_FORMAT_YEAR_MONTH_SHORT: "Jahr Monat (kurz)",
  METRIC_FORMAT_YEAR_MONTH_LONG: "Jahr Monat",
  METRIC_FORMAT_MONTH_SHORT: "Monat (kurz)",
  METRIC_FORMAT_MONTH_LONG: "Monat",
  METRIC_FORMAT_YEAR_WEEK_NUMBER: "Jahr Kalenderwoche",
  METRIC_FORMAT_WEEK_NUMBER: "Kalenderwoche",
  METRIC_FORMAT_D_SLASH_M: "Tag/Monat",
  METRIC_FORMAT_D_SLASH_M_HH_mm: "Tag/Monat HH:mm",
  METRIC_FORMAT_YYYYMMDD: "JJJJMMTT",
  METRIC_FORMAT_YYYY_MM_DD: "JJJJ/MM/TT",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm: "JJJJ/MM/TT HH:mm",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm_ss: "JJJJ/MM/TT HH:mm:ss",
  METRIC_FORMAT_HOUR_OF_DAY_24H: "Stunde des Tages (24H)",
  METRIC_FORMAT_HOUR_OF_DAY_12H: "Stunde des Tages (12H)",
  METRIC_FORMAT_WEEKDAY_SHORT: "Wochentag (kurz)",
  METRIC_FORMAT_WEEKDAY_LONG: "Wochentag",
  METRIC_FORMAT_HH_mm: "HH:mm",
  METRIC_FORMAT_mm_ss: "mm:ss",
  METRIC_FORMAT_HH_mm_ss: "HH:mm:ss",
  W_WEEK_SHORT: "KW",
  PM: "PM",
  AM: "AM",
  MONTH_SHORT_1: "Jan",
  MONTH_SHORT_2: "Feb",
  MONTH_SHORT_3: "Mär",
  MONTH_SHORT_4: "Apr",
  MONTH_SHORT_5: "Mai",
  MONTH_SHORT_6: "Jun",
  MONTH_SHORT_7: "Jul",
  MONTH_SHORT_8: "Aug",
  MONTH_SHORT_9: "Sep",
  MONTH_SHORT_10: "Okt",
  MONTH_SHORT_11: "Nov",
  MONTH_SHORT_12: "Dez",
  MONTH_LONG_1: "Januar",
  MONTH_LONG_2: "Februar",
  MONTH_LONG_3: "März",
  MONTH_LONG_4: "April",
  MONTH_LONG_5: "Mai",
  MONTH_LONG_6: "Juni",
  MONTH_LONG_7: "Juli",
  MONTH_LONG_8: "August",
  MONTH_LONG_9: "September",
  MONTH_LONG_10: "Oktober",
  MONTH_LONG_11: "November",
  MONTH_LONG_12: "Dezember",
  WEEKDAY_SHORT_6: "So",
  WEEKDAY_SHORT_0: "Mo",
  WEEKDAY_SHORT_1: "Di",
  WEEKDAY_SHORT_2: "Mi",
  WEEKDAY_SHORT_3: "Do",
  WEEKDAY_SHORT_4: "Fr",
  WEEKDAY_SHORT_5: "Sa",
  WEEKDAY_LONG_6: "Sonntag",
  WEEKDAY_LONG_0: "Montag",
  WEEKDAY_LONG_1: "Dienstag",
  WEEKDAY_LONG_2: "Mittwoch",
  WEEKDAY_LONG_3: "Donnerstag",
  WEEKDAY_LONG_4: "Freitag",
  WEEKDAY_LONG_5: "Samstag",
  ONGOING_CALLS: "Laufende Anrufe",
  RECORDING_NEEDS_TO_BE_UPLOADED: "Stellen Sie sicher, dass die Aufnahme hochgeladen wird!",
  RECORDING_STARTED: "Aufnahme gestartet",
  RECORDING_STOPPED: "Aufnahme gestoppt",
  DUE_DATE: "Fälligkeitsdatum",
  MAIL_ACCOUNT_NOT_IN_QUEUES:
    "Ausgewähltes E-Mail-Konto ist nicht als ausgehendes E-Mail-Konto für die aktuelle Konversationswarteschlange verfügbar",
  NO_EMAIL_ON_CONTACT: "Es gibt keine E-Mail-Adressen für den Kontakt zum Antworten!",
  FORWARD: "Weiterleiten",
  LEFT_BRIDGE: "Bridge beendet",
  DESTINATION_PHONE: "Ziel {{phone}}",
  PARAMS_PHONE: "Telefon: {{phone}}",
  PARAMS_JOURNEY: "Journey: {{journey}}",
  CALLEE_ANSWERED: "Empfänger hat geantwortet",
  SOCKET_CONNECTION_ERROR: 'Fehler bei der Websocket-Verbindung: "{{error}}"',
  CONTACT_FORM_HAS_NO_FIELDS:
    "Das Kontaktformular hat keine Felder! Ein Administrator muss Felder zum Kontaktformular hinzufügen, das mit der ausgewählten Warteschlange verknüpft ist (Liste erstellen)",
  NOTIFICATION_REQUEST_NOT_SUPPORTED:
    "Ihr Browser unterstützt keine Benachrichtigungen, bitte wechseln Sie den Browser!",
  NOTIFICATION_REQUEST_DENIED:
    "Sie haben Browser-Benachrichtigungen abgelehnt. Stellen Sie sicher, dass Browser-Benachrichtigungen erlaubt sind!",
  NOTIFICATION_REQUEST_CATCH:
    "Ein Fehler ist bei der Anfrage für Browser-Benachrichtigungsberechtigungen aufgetreten! {{error}}",
  INACTIVITY_READY: "Bei Bereit-Status abmelden",
  INACTIVITY_PAUSE: "Bei Pause-Status abmelden",
  PDF: "PDF",
  IMAGE: "Bild",
  STATS_WIDGET: "Widget",
  ADVANCED_OPTIONS: "Erweiterte Optionen",
  HIDE: "Ausblenden",
  NO_SPECIFIC_USER: "Kein Benutzer",
  NO_SPECIFIC_QUEUE: "Keine Warteschlange",
  NO_SPECIFIC_DISPOSITION: "Keine Disposition",
  NO_SPECIFIC_PAUSE: "Keine Pause",
  NO_SPECIFIC_DIRECTION: "Keine Richtung",
  NO_SPECIFIC_CHANNEL: "Kein Kanal",
  NO_SPECIFIC_ORGANIZATION: "Keine Organisation",
  NO_SPECIFIC_CLIENT: "Kein Client",
  NO_SPECIFIC_ACCOUNT: "Kein Konto",
  USER_ID_X: "Benutzer-ID {{x}}",
  QUEUE_ID_X: "Warteschlangen-ID {{x}}",
  DISPOSITION_ID_X: "Dispositions-ID {{x}}",
  ORGANIZATION_ID_X: "Organisations-ID {{x}}",
  CLIENT_ID_X: "Client-ID {{x}}",
  ACCOUNT_ID_X: "Konto-ID {{x}}",
  PAUSE_ID_X: "Pause-ID {{x}}",
  ALIGNMENT: "Ausrichtung",
  THRESHOLDS: "Schwellenwerte",
  BOX_COLOR: "Kastenfarbe",
  FONT_STYLE: "Schriftstil",
  FORMAT: "Format",
  USER_SELF: "Personalisierte Daten",
  FONT_STYLE_ITALIC: "Kursiv",
  FONT_STYLE_BOLD: "Fett",
  "FONT_STYLE_BOLD-ITALIC": "Fett-Kursiv",
  ALIGNMENT_CENTER: "Zentriert",
  ALIGNMENT_LEFT: "Links",
  ALIGNMENT_RIGHT: "Rechts",
  BLINK: "Blinken",
  DECIMALS: "Dezimalstellen",
  DIRECTION: "Richtung",
  THREAD_DIRECTION: "Thread-Richtung",
  MESSAGE_DIRECTION: "Nachrichten-Richtung",
  CONVERSATION_MAIN_CHANNELS: "Konversations-Hauptkanäle",
  CONVERSATION_DIRECTION: "Konversationsrichtungen",
  EDIT_ITEM: "Element bearbeiten",
  OF_TOTAL: "Von Gesamt",
  GROUP_POPULAR: "Beliebt",
  GROUP_OTHERS: "Andere",
  GROUP_CUSTOM: "Benutzerdefiniert",
  GROUP_LIVE: "Live",
  STATS_CUSTOM_METRICS: "Benutzerdefinierte Kennzahlen",
  STATS_CUSTOM_METRIC: "Benutzerdefinierte Kennzahl",
  METRIC_QUERY_TYPE_SUM: "Addition",
  METRIC_QUERY_TYPE_DIFF: "Subtraktion",
  METRIC_QUERY_TYPE_PERCENT: "Prozent",
  METRIC_QUERY_TYPE_AVG: "Durchschnitt",
  METRIC_QUERY_TYPE_MAX: "Maximum",
  METRIC_QUERY_TYPE_MIN: "Minimum",
  METRIC_QUERY_TYPE_LEAST: "Kleinster",
  METRIC_QUERY_TYPE_GREATEST: "Größter",
  METRIC_QUERY_TYPE_STATIC: "Statisch",
  METRIC_QUERY_TYPE_ADD: "Addition",
  QUERY_TYPE: "Abfragetyp",
  DASHBOARDS: "Dashboards",
  DASHBOARD: "Dashboard",
  REPORTS: "Berichte",
  REPORT: "Bericht",
  CREATE_DASHBOARD: "Dashboard erstellen",
  CREATE_REPORT: "Bericht erstellen",
  EDIT_DASHBOARD: "Dashboard bearbeiten",
  EDIT_REPORT: "Bericht bearbeiten",
  ALL_DASHBOARDS: "Alle Dashboards",
  SHARED_WITH_ME: "Mit mir geteilt",
  PREBUILD_DASHBOARDS: "Vorgefertigte Dashboards",
  ALL_REPORTS: "Alle Berichte",
  PREBUILD_REPORTS: "Vorgefertigte Berichte",
  ADD_YOUR_FIRST_DASHBOARD: "Fügen Sie Ihr erstes Dashboard hinzu",
  ADD_YOUR_FIRST_DASHBOARD_DESCRIPTION:
    "Beginnen Sie mit einer leeren Fläche oder wählen Sie eines unserer vorgefertigten Dashboards",
  NO_SHARED_DASHBOARDS: "Keine geteilten Dashboards",
  ADD_YOUR_PREBUILT_DASHBOARD: "Vorgefertigtes Dashboard hinzufügen",
  ADD_YOUR_PREBUILT_DASHBOARD_DESCRIPTION:
    "Erstellen Sie Ihr eigenes Dashboard aus einem vorgefertigten Dashboard",
  ADD_YOUR_FIRST_REPORT: "Fügen Sie Ihren ersten Bericht hinzu",
  ADD_YOUR_FIRST_REPORT_DESCRIPTION:
    "Beginnen Sie mit einer leeren Fläche oder wählen Sie einen unserer vorgefertigten Berichte",
  NO_SHARED_REPORTS: "Keine geteilten Berichte",
  ADD_YOUR_PREBUILT_REPORT: "Vorgefertigten Bericht hinzufügen",
  ADD_YOUR_PREBUILT_REPORT_DESCRIPTION:
    "Erstellen Sie Ihren eigenen Bericht aus einem vorgefertigten Bericht",
  NO_DASHBOARDS: "Keine Dashboards",
  NO_REPORTS: "Keine Berichte",
  CREATE_FROM_THIS_TEMPLATE: "Aus dieser Vorlage erstellen",
  TIME_THRESHOLD: "Zeitschwelle",
  AFTER: "Nach",
  CUSTOM_METRIC: "Benutzerdefinierte Kennzahl",
  STATS_CUSTOM_METRIC_ID: "Benutzerdefinierte Kennzahl",
  HEIGHT: "Höhe",
  STATS_USER_DATA_HELP: "Personalisierte Daten zeigen personalisierte Werte für diese Kennzahl.",
  STATS_OF_TOTAL_HELP: "Prozentsatz dieser Kennzahl im Vergleich zum Gesamtwert.",
  STATS_REPORTS: "Berichte & Dashboards",
  STATS_WIDGETS: "Widgets",
  OWNER: "Besitzer",
  IS_DASHBOARD: "Ist Dashboard",
  STATS_REPORT: "Bericht",
  ADD_WIDGET: "Widget hinzufügen",
  FULLSCREEN: "Vollbild",
  CLONED: "Geklont",
  STATS_WIDGET_LEGEND_PLACEMENT_LEFT: "Links",
  STATS_WIDGET_LEGEND_PLACEMENT_RIGHT: "Rechts",
  STATS_WIDGET_LEGEND_PLACEMENT_TOP: "Oben",
  STATS_WIDGET_LEGEND_PLACEMENT_BOTTOM: "Unten",
  CIRCLE_TOTAL: "Kreis Gesamt",
  TOTAL: "Gesamt",
  BORDER_COLOR: "Rahmenfarbe",
  LAST_DATA_AT: "Letzte Daten um",
  HIDE_TITLE: "Titel ausblenden",
  OPEN_DASHBOARD: "Dashboard öffnen",
  ADD_PREVIEW_WIDGET: "Vorschau-Widget hinzufügen",
  SELECT_REPORT: "Bericht auswählen",
  DEFAULT: "Standard",
  CLONE: "Klonen",
  DRAG_n_MOVE: "Ziehen & Verschieben",
  DOWN: "Runter",
  UP: "Hoch",
  EXISTING: "Vorhanden",
  SELECT_WIDGET: "Widget auswählen",
  ZOOM_IN: "Hineinzoomen",
  ZOOM_OUT: "Herauszoomen",
  RELOAD: "Neu laden",
  RELOAD_DATA: "Daten neu laden",
  DISPLAY_VALUES: "Werte anzeigen",
  WIDGET_NOT_FOUND: "Widget nicht gefunden!",
  WIDGET_NOT_FOUND_DESCRIPTION: "Widget wurde gelöscht oder wird nicht mehr mit Ihnen geteilt!",
  NO_WIDGETS: "Keine Widgets!",
  ADD_WIDGET_TO_REPORT: "Widget zum Bericht hinzufügen",
  ADD_WIDGET_TO_DASHBOARD: "Widget zum Dashboard hinzufügen",
  REMOVE: "Entfernen",
  REMOVE_ITEM: "Element entfernen",
  ADDITION: "Addition",
  SUBTRACTION: "Subtraktion",
  DIVISION: "Division",
  CHANNELS_SETTINGS: "Kanäle aus Einstellungen",
  DIRECTION_SETTINGS: "Richtung aus Einstellungen",
  THREAD_CHANNELS_SETTINGS: "Thread-Kanäle aus Einstellungen",
  MESSAGE_CHANNELS_SETTINGS: "Nachrichten-Kanäle aus Einstellungen",
  THREAD_DIRECTION_SETTINGS: "Thread-Richtung aus Einstellungen",
  MESSAGE_DIRECTION_SETTINGS: "Nachrichten-Richtung aus Einstellungen",
  SELECT_EXISTING: "Vorhandene auswählen",
  EXISTING_WIDGETS: "Vorhandene Widgets",
  NEW_WIDGET: "Neues Widget",
  TEMPLATES: "Vorlagen",
  SELECT_TEMPLATE: "Vorlage auswählen",
  USE_TEMPLATE: "Vorlage verwenden",
  SEARCH_TEMPLATES: "Vorlagen suchen",
  SEARCH_WIDGETS: "Widgets suchen",
  WIDGET_TEMPLATES: "Widget-Vorlagen",
  WIDGET: "Widget",
  EDIT_WIDGET: "Widget bearbeiten",
  SELECT_DASHBOARD: "Dashboard auswählen",
  REPORT_NOT_SELECTED: "Bericht nicht ausgewählt!",
  DASHBOARD_NOT_SELECTED: "Dashboard nicht ausgewählt!",
  TREND_VALUE: "Trendwert",
  TREND_PERIOD: "Trendzeitraum",
  MY_CONVERSATIONS: "Meine Konversationen",
  MY_AND_UNASSIGNED_CONVERSATIONS: "Meine & nicht zugewiesene Konversationen",
  CUSTOMER_RESPONDED: "Kunde hat geantwortet",
  NO_UPDATE_IN_7_DAYS: "Keine Aktualisierung seit 7 Tagen",
  CREATED_BY_ME: "Von mir erstellt",
  ALL_UNRESOLVED: "Alle ungelösten",
  ALL_RESOLVED: "Alle gelösten",
  ALL_CONVERSATIONS: "Alle Konversationen",
  DIRECTIONS: "Richtungen",
  INTERNAL_HOST: "Interner Host",
  VOICE_ASTERISK_DOWNGRADE_ID: "Asterisk herabstufen",
  VOICE_ASTERISK_DOWNGRADES: "Asterisk-Herabstufungen",
  VOICE_ASTERISK_DOWNGRADE: "Asterisk-Herabstufung",
  DOWNGRADED: "Herabgestuft",
  __IS_DOWNGRADED: "Ist herabgestuft",
  VOICE_ASTERISK_OPERATIONAL_HOURS: "Asterisk Betriebszeiten",
  VOICE_ASTERISK_OPERATIONAL_HOURSES: "Asterisk Betriebszeiten",
  UTC: "UTC",
  VOICE_ASTERISK_TOGGLE_HISTORY: "Asterisk Umschalthistorie",
  VOICE_ASTERISK_TOGGLE_HISTORIES: "Asterisk Umschalthistorien",
  INITIAL: "Initial",
  DID_TOGGLE: "Wurde umgeschaltet",
  DATE: "Datum",
  X_ONGOING_CALLS: "{{calls}} laufende Anrufe",
  X_USER_STATUSES: "{{users}} verbundene Benutzer",
  PREVENTED_ASTERISK_TOGGLE: "verhinderte Asterisk-Umschaltung",
  BOOTING_UP: "Startet",
  DOWNGRADE_ASTERISK_OFFLINE: "Asterisk offline herabstufen",
  ASTERISK_STATUS_OFFLINE: "Offline",
  ASTERISK_STATUS_UPANDRUNNING: "Läuft",
  ASTERISK_STATUS_TERMINATED: "Beendet",
  ASTERISK_STATUS_BOOTING: "Startet",
  ASTERISK_STATUS_SYNCING: "Synchronisiert",
  RESYNC: "Neu synchronisieren",
  TOGGLE_FAILED: "Umschaltung fehlgeschlagen (wahrscheinlich OPM-Anfrage)",
  CURRENCY: "Währung",
  INVOICE_CURRENCY_EUR: "EUR",
  INVOICE_CURRENCY_USD: "USD",
  INVOICE_CURRENCY_SEK: "SEK",
  INVOICE_POST_ADDON: "Rechnung",
  INVOICE_POST_LICENSE: "Lizenz",
  INVOICE_POST_USAGE: "Sprachnutzung",
  INVOICE_POST_ALL_LICENSE: "Alle Kanäle Lizenz",
  TARIFF_ID: "Tarif",
  VOICE_TARIFF_LIST: "Sprachtarifliste",
  PHONENUMBER_MONTHLY_COST_ID: "Telefonnummer monatliche Kosten",
  PHONENUMBER_MONTHLY_COST_LIST: "Telefonnummer monatliche Kostenliste",
  PHONENUMBER_SETUP_COST_ID: "Telefonnummer Einrichtungskosten",
  PHONENUMBER_SETUP_COST_LIST: "Telefonnummer Einrichtungskostenliste",
  INVOICE_POST_VOICE_TARIFF: "Sprachtarif",
  INVOICE_POST_VOICE_PHONENUMBER_MONTHLY_COST: "Telefonnummer monatliche Kosten",
  INVOICE_POST_VOICE_PHONENUMBER_SETUP_COST: "Telefonnummer Einrichtungskosten",
  INVOICE_POST_VOICE_LICENSE: "Sprachlizenz",
  INVOICE_POST_VOICE_USAGE: "Sprachnutzung",
  INVOICE_POST_EMAIL_USAGE: "E-Mail-Nutzung",
  INVOICE_POST_EMAIL_LICENSE: "E-Mail-Lizenz",
  INVOICE_POST_FORM_USAGE: "Formularnutzung",
  INVOICE_POST_VOICE_NUMBERS_MONTHLY: "Sprachtelefonnummern monatliche Kosten",
  INVOICE_POST_VOICE_NUMBERS_SETUP: "Sprachtelefonnummern Einrichtungskosten",
  INVOICE_POST_VOICE_RECORDING: "Sprachaufzeichnung",
  INVOICE_POST_VOICE_RECORDING_STORAGE: "Sprachaufzeichnungsspeicher",
  CUSTOMER_IDENTIFICATION_NUMBER: "Kundenidentifikationsnummer",
  INVOICE_PERIOD_MONTHLY: "Monatlich",
  INVOICE_PERIOD_EVERY2NDMONTH: "Alle zwei Monate",
  INVOICE_PERIOD_QUARTERLY: "Vierteljährlich",
  INVOICE_PERIOD_HALFYEARLY: "Halbjährlich",
  INVOICE_PERIOD_ANNUALLY: "Jährlich",
  AMOUNT_TYPE: "Typ",
  AMOUNT_PERCENT: "Prozent",
  AMOUNT_CURRENCY: "Betrag",
  INVOICE_POST_AMOUNT_TYPE_PERCENT: "Prozent",
  INVOICE_POST_AMOUNT_TYPE_CURRENCY: "Betrag",
  INVOICE: "Rechnung",
  INVOICES: "Rechnungen",
  AMOUNT: "Betrag",
  LOCKED_AT: "Gesperrt",
  PAID_AT: "Bezahlt",
  PERIOD_START: "Periodenbeginn",
  PERIOD_END: "Periodenende",
  INVOICE_POST: "Rechnungsposten",
  INVOICE_POSTS: "Rechnungsposten",
  RE_PROCESS: "Neu verarbeiten",
  EXCLUDE: "Ausschließen",
  INVOICE_ID: "Rechnung",
  TARIFF: "Tarif",
  TARIFFS: "Tarife",
  TARIFF_PARENT: "Tarifelternteil",
  TARIFF_PARENTS: "Tarifelternteile",
  OUTBOUND_TARIFFS: "Ausgehende Tarife",
  INBOUND_TARIFF: "Eingehender Tarif",
  INTERNAL_TARIFF: "Interner Tarif",
  OUTBOUND_ROUNDUP_STRATEGY: "Ausgehende Aufrundungsstrategie",
  INBOUND_ROUNDUP_STRATEGY: "Eingehende Aufrundungsstrategie",
  INTERNAL_ROUNDUP_STRATEGY: "Interne Aufrundungsstrategie",
  TARIFF_PARENT_ID: "Sprachtarifelternteil",
  VOICE_TARIFF_ROUNDUP_STRATEGY_NOROUNDUP: "Keine Aufrundung",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST60SECONDS: "Nächste 60 Sekunden",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST30SECONDS: "Nächste 30 Sekunden",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST10SECONDS: "Nächste 10 Sekunden",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEIL15TO60: "Aufrunden 15-60 auf 60 Sekunden",
  PHONENUMBER_MONTHLY_COST_PARENT_ID: "Telefonnummer monatliche Kosten Elternteil",
  PHONENUMBER_MONTHLY_COST: "Telefonnummer monatliche Kosten",
  PHONENUMBER_MONTHLY_COSTS: "Telefonnummer monatliche Kosten",
  PHONENUMBER_MONTHLY_COST_PARENT: "Telefonnummer monatliche Kosten Elternteil",
  PHONENUMBER_MONTHLY_COST_PARENTS: "Telefonnummer monatliche Kosten Elternteile",
  PHONENUMBER_SETUP_COST_PARENT_ID: "Telefonnummer Einrichtungskosten Elternteil",
  PHONENUMBER_SETUP_COST: "Telefonnummer Einrichtungskosten",
  PHONENUMBER_SETUP_COSTS: "Telefonnummer Einrichtungskosten",
  PHONENUMBER_SETUP_COST_PARENT: "Telefonnummer Einrichtungskosten Elternteil",
  PHONENUMBER_SETUP_COST_PARENTS: "Telefonnummer Einrichtungskosten Elternteile",
  INVOICE_START: "Rechnungsbeginn",
  INVOICE_END: "Rechnungsende",
  ADDONS: "Erweiterungen",
  PROCESSED_AT: "Verarbeitet",
  INVOICE_PRINT: "Rechnung",
  INVOICE_PDF: "PDF",
  MOBILE: "Mobil",
  FIXED_LINE: "Festnetz",
  SHARED_COST: "Geteilte Kosten",
  TOLL_FREE: "Gebührenfrei",
  DUE_PERIOD: "Fälligkeitszeitraum",
  COUNT: "Anzahl",
  INVOICE_DUE_PERIOD_MONTH: "Monat",
  INVOICE_DUE_PERIOD_DAY: "Tag",
  COMPANY_NAME: "Firmenname",
  COMPANY_ADDRESS: "Firmenadresse",
  COUNTRY: "Land",
  CITY: "Stadt",
  POSTAL_CODE: "Postleitzahl",
  ADDRESS_ROW1: "Adresszeile 1",
  ADDRESS_ROW2: "Adresszeile 2",
  MARK_AS_PAID: "Als bezahlt markieren",
  MARK_AS_UNPAID: "Als unbezahlt markieren",
  INVOICE_DATE: "Rechnungsdatum",
  AMOUNT_TO_PAY: "Zu zahlender Betrag",
  INVOICE_PERIOD: "Rechnungszeitraum",
  INBOUND: "Eingehend",
  SUB_TOTAL: "Zwischensumme",
  VAT: "MwSt.",
  PCS: "Stk",
  COMPANY_EMAILS: "Firmen-E-Mail-Adressen",
  PAYMENT_OPTION: "Zahlungsoption",
  INVOICE_PAYMENT_OPTION_BANKTRANSFER: "Banküberweisung",
  INVOICE_PAYMENT_OPTION_CREDITCARD: "Kreditkarte",
  LICENSE_COST: "Lizenzkosten",
  FORM_MODULE_COST: "Formularmodulkosten",
  REPORTING: "Berichterstattung",
  SURCHARGE: "Aufschlag",
  PREPAY: "Vorauszahlung",
  COST: "Kosten",
  INVOICE_POST_AMOUNT_TYPE_MINDEB: "Mindestabrechnung",
  FINANCE: "Finanzen",
  PREPAID_CREDIT: "Prepaid-Guthaben",
  PREPAID_CREDITS: "Prepaid-Guthaben",
  VALID_FROM: "Gültig ab",
  VALID_UNTIL: "Gültig bis",
  CREDIT_LEFT: "Verbleibendes Guthaben",
  OUTBOUND_CALL_RESTRICTED_DESCRIPTION:
    "Ausgehender Anruf zum Zieltelefon wurde vom System eingeschränkt! Bitte kontaktieren Sie Ihren Systemadministrator für Zugriff!",
  OUTBOUND_CALL_NEGATIVE_BALANCE_DESCRIPTION:
    "Ausgehender Anruf wurde aufgrund negativen Guthabens Ihrer Organisation beendet!",
  OUTBOUND_CALL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Ausgehender Anruf wurde aufgrund Inaktivität der Organisation beendet!",
  OUTBOUND_CALL_TOLL_FREE_NOT_SAME_ORIGIN:
    "Ausgehender Anruf zur gebührenfreien Zielnummer wurde beendet, da Anrufer- und Empfängerland identisch sein müssen!",
  OUTBOUND_EMAIL_NEGATIVE_BALANCE_DESCRIPTION:
    "Ausgehende E-Mail wurde aufgrund negativen Guthabens Ihrer Organisation beendet!",
  OUTBOUND_EMAIL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Ausgehende E-Mail wurde aufgrund Inaktivität der Organisation beendet!",
  DESTINATION_RESTRICTOR: "Zielbeschränkung",
  DESTINATION_RESTRICTORS: "Zielbeschränkungen",
  DESTINATIONS: "Ziele",
  STATS_LICENSE_TYPE_ENTRY: "Entry",
  STATS_LICENSE_TYPE_STANDARD: "Standard",
  STATS_LICENSE_TYPE_ENTERPRISE: "Enterprise",
  ALL_CHANNELS: "Alle Kanäle",
  VOICE_TARIFF_ROUNDUP_STRATEGY: "Sprachtarif Aufrundungsstrategie",
  VOICE_OUTBOUND_USAGE: "Sprache ausgehende Nutzung",
  VOICE_USAGE_ROUNDUP_STRATEGY: "Sprachnutzung Aufrundungsstrategie",
  VOICE_INBOUND_USAGE: "Sprache eingehende Nutzung",
  VOICE_INTERNAL_USAGE: "Sprache interne Nutzung",
  EMAIL_INBOUND_USAGE: "E-Mail eingehende Nutzung",
  EMAIL_OUTBOUND_USAGE: "E-Mail ausgehende Nutzung",
  FORM_INBOUND_USAGE: "Formular eingehende Nutzung",
  NEGATIVE_BALANCE_LOCK: "Negativsaldo-Sperre",
  PREPAID: "Prepaid",
  NONE_HANDLED_INVOICE_CANNOT_BE_LOCKED: "Nicht bearbeitete Rechnung kann nicht gesperrt werden!",
  LOCKED_INVOICES_MAY_NOT_BE_PATCHED: "Gesperrte Rechnung kann nicht geändert werden!",
  CREDIT_USED: "Genutztes Guthaben",
  INVOICE__DATA__COMPANY_EMAILS: "Rechnungsempfänger E-Mail-Adressen",
  DASHBOARD_LICENSE: "Dashboard-Lizenz",
  REPORTING_LICENSE: "Berichterstattungs-Lizenz",
  DASHBOARD_LICENSE_TYPE: "Dashboard-Lizenztyp",
  REPORTING_LICENSE_TYPE: "Berichterstattungs-Lizenztyp",
  STANDARD: "Standard",
  ENTERPRISE: "Enterprise",
  TWINNING: "Twinning",
  ON: "An",
  QUEUE_ON: "Warteschlange an",
  DIRECT_ON: "Direkt an",
  TWINNED: "Getwinnt",
  TWINNED_TO: "Getwinnt mit",
  RE_PROCESS_TARIFFS: "Tarife neu verarbeiten",
  RE_PROCESS_TARIFFS_NO_RESET_TARIFF_ID:
    "Tarifkosten neu verarbeiten ohne Tarifliste zurückzusetzen",
  RE_PROCESS_TARIFFS_WITH_RESET_TARIFF_ID:
    "Tarifkosten neu verarbeiten und Tarifliste zurücksetzen",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTRY: "Entry",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_STANDARD: "Standard",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTERPRISE: "Enterprise",
  INVOICE_NUMBER: "Rechnungsnr.",
  CUSTOMER_NUMBER: "Kundennr.",
  SALESPERSON: "Verkäufer",
  YOUR_REFERENCE: "Ihre Referenz",
  PAYMENT_TERMS: "Zahlungsbedingungen",
  VAT_REGISTRATION_NO: "USt-IdNr.",
  FIN_CHARGE_TERMS: "Verzugszinsen",
  INVOICE_ROUNDING: "Rechnungsrundung",
  COMPANY_REFERENCE: "Firmenreferenz",
  INTERNET: "Internet",
  E_MAIL: "E-Mail",
  CORPORATE_IDENTITY_NO: "Handelsregisternummer",
  BANKGIRO: "Bankgiro",
  BIC_SWIFT: "BIC/SWIFT",
  PAYMENT_INFO_TEMPLATE: "Zahlungsinformationsvorlage",
  HANDELSBANKEN_BG_SEK: "Handelsbanken Bankgiro SEK",
  HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  WIZE_EUR: "Wize EUR",
  NET_AMOUNT: "Nettobetrag",
  SUBTOTAL: "Zwischensumme",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_BG_SEK: "Handelsbanken Bankgiro SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  INVOICE_PAYMENT_INFO_TEMPLATE_WIZE_EUR: "Wize EUR",
  QUEUE_NOT_FOUND: "Warteschlange nicht gefunden",
  CONTACT_NOT_FOUND: "Kontakt nicht gefunden",
  SEND_INVOICE: "Rechnung senden",
  GO_TO_CONVERSATION: "Zur Konversation gehen",
  MISSING_ORGANIZATION_INVOICE_DATA: "Fehlende Organisationsrechnungsdaten",
  NO_ORGANIZATION_INVOICE_EMAILS:
    "Keine E-Mail-Adressen in der Organisation gefunden, um eine Konversation zu erstellen!",
  INVOICE_ALREADY_LOCKED: "Rechnung ist bereits gesperrt",
  LOCK_INVOICE: "Rechnung sperren",
  REGULATION_LOCAL_ADDRESS_PROOF_REQUIRED:
    "Beim Kauf von Telefonnummern beachten Sie bitte, dass einige Regionen möglicherweise einen Nachweis einer gültigen Straßenadresse im angegebenen Gebiet oder der Stadtdomäne verlangen. Dies kann die Einreichung von Dokumenten wie einer aktuellen Rechnung oder Firmenzertifikaten erfordern, die die entsprechende Adresse zur Einhaltung lokaler Vorschriften nachweisen.",
  REGULATION_WORLDWIDE_ADDRESS_PROOF_REQUIRED:
    "Beim Kauf von Telefonnummern beachten Sie bitte, dass diese Region einen Nachweis einer gültigen Straßenadresse verlangt. Dies kann die Einreichung von Dokumenten wie einer aktuellen Rechnung oder Firmenzertifikaten erfordern, die die entsprechende Adresse zur Einhaltung lokaler Vorschriften nachweisen.",
  REGULATION_NATIONAL_ADDRESS_PROOF_REQUIRED:
    "Beim Kauf von Telefonnummern beachten Sie bitte, dass diese Region einen Nachweis einer gültigen Straßenadresse innerhalb des angegebenen Landes verlangt. Dies kann die Einreichung von Dokumenten wie einer aktuellen Rechnung oder Firmenzertifikaten erfordern, die die entsprechende Adresse zur Einhaltung lokaler Vorschriften nachweisen.",
  PURCHASE: "Kaufen",
  SETUP_COST: "Einrichtungskosten",
  MONTHLY_COST: "Monatliche Kosten",
  REGULATIONS: "Vorschriften",
  PHONENUMBER_PURCHASE_STATUS_ERROR: "Error",
  PHONENUMBER_PURCHASE_STATUS_WARNING: "Warning",
  PHONENUMBER_PURCHASE_STATUS_PARTIAL: "Partial",
  PHONENUMBER_PURCHASE_STATUS_SUCCESS: "Success",
  PHONENUMBER_PURCHASE: "Telefonnummernkauf",
  PHONENUMBER_PURCHASES: "Telefonnummernkäufe",
  PHONENUMBER_PREFIX: "Telefonnummer DID-Gruppe",
  PHONENUMBER_PREFIXES: "Telefonnummer DID-Gruppen",
  PHONENUMBER_DID_TYPE_FIXED_LINE: "Festnetz",
  PHONENUMBER_DID_TYPE_MOBILE: "Mobil",
  PHONENUMBER_DID_TYPE_SHARED_COST: "Geteilte Kosten",
  PHONENUMBER_DID_TYPE_TOLL_FREE: "Gebührenfrei",
  ALPHA2: "Alpha-2",
  AREA_NAME: "Gebietsname",
  AREA_CODE: "Vorwahl",
  STOCK: "Bestand",
  PHONENUMBER_STOCK: "Telefonnummernbestand",
  PHONENUMBER_STOCKS: "Telefonnummernbestände",
  BULK_ADD_PHONENUMBERS: "Massenzufügung von Telefonnummern",
  BULK_ADD_PHONENUMBERSTOCK: "Massenzufügung von Telefonnummern zum Connectel-Bestand",
  NUMBER_RANGE_START: "Nummernbereich Start",
  NUMBER_RANGE_END: "Nummernbereich Ende",
  LIST_PHONENUMBERS: "Telefonnummern auflisten",
  ADD_PHONENUMBERS: "Telefonnummern hinzufügen",
  VOICE_ROUTE_NUMBER_MAY_NOT_BE_CHANGED: "Nummer darf nicht geändert werden!",
  EMERGENCY: "Notfall",
  ENABLED: "Aktiviert",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_VOXBONE: "Voxbone",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_CONNECTEL: "Connectel",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_THIRD_PARTY: "Drittanbieter",
  PROVIDER: "Anbieter",
  TERMINATE: "Beenden",
  TERMINATION: "Beendigung",
  TERMINATED_AT: "Beendet am",
  TERMINATED_BY: "Beendet von",
  TERMINATES_AT: "Endet am",
  USER_NOT_FOUND: "Benutzer-ID ({{user_id}}) nicht gefunden!",
  TERMINATE_WENT_WRONG: "Etwas ging bei der Beendigung der Telefonnummer schief!",
  VOXBONE_NUMBER_NOT_CANCELLED: "Telefonnummer konnte nicht storniert werden!",
  VOXBONE_DID_ID_NOT_FOUND:
    "Der Anbieter kann nicht zu Voxbone geändert werden, da die Nummer nicht in der Voxbone-Datenbank existiert.",
  VOICE_RECORDING: "Sprachaufnahme",
  VOICE_RECORDING_STORAGE: "Sprachaufnahmenspeicher",
  VOICEMAIL_MESSAGE: "Sprachnachricht",
  VOICE_CALL_RECORDING: "Anrufaufzeichnung",
  ADD_CUSTOM_INVOICE_POST: "Benutzerdefinierte Rechnungsposition hinzufügen",
  BY_PREFIX: "Nach Präfix",
  PREFIX: "Präfix",
  DESTINATION_RESTRICTOR_CONFIRM:
    "Die Aktivierung zusätzlicher Anrufziele kann zu unbeabsichtigten Gebühren und erhöhten Kosten führen, da Anrufe zu zuvor blockierten Ländern erlaubt werden. Stellen Sie sicher, dass Sie sich der potenziellen finanziellen Auswirkungen bewusst sind, bevor Sie neue Ziele aktivieren.",
  DISPOSITION_MAY_NOT_HAVE_MORE_THAN_3_LEVELS: "Disposition darf nicht mehr als 3 Ebenen haben!",
  TO_ME: "An mich",
  ASSIGN_CONVERSATION_TO_ME: "Konversation mir zuweisen",
  VOICE_DEVICE_AND_TWINNING_SETTINGS: "Sprachgeräte- & Twinning-Einstellungen",
  VOICE_NOTIFICATION_SOUND: "Sprachbenachrichtigungston",
  VOICE_NOTIFICATION_MICROPHONE: "Sprachmikrofon ein/aus",
  NO_USER: "Kein Benutzer",
  NO_DISPOSITION: "Keine Disposition",
  NO_SUB_DISPOSITION: "Keine Unterdisposition",
  NO_THRD_DISPOSITION: "Keine Drittdisposition",
  NOT_REQUIRED: "Nicht erforderlich",
  RESOLVE_IN: "Lösen innerhalb",
  REPLY_AT_LATEST: "Antworten spätestens",
  RESOLVE_AT_LATEST: "Lösen spätestens",
  CHANGELOG: "Änderungsprotokoll",
  SWITCH_TO_DARK_THEME: "Zu dunklem Design wechseln",
  SWITCH_TO_LIGHT_THEME: "Zu hellem Design wechseln",
  DISPLAY_CHANGELOG_HISTORY: "Änderungsprotokollverlauf anzeigen",
  IMPROVEMENT: "Verbesserung",
  BUG_FIX: "Fehlerbehebung",
  SELECT_ALL: "Alle auswählen",
  NAV_CUSTOMER_ID: "Kunden-Navision-ID",
  CREATE_NEW_JOURNEY_DRAFT: "Neuen Journey-Entwurf erstellen",
  SHOW_JOURNEY_DRAFTS: "Journey-Entwürfe anzeigen",
  DROP_TO_ATTACH_FILE: "Zum Anhängen ablegen",
  NO_PREVIEW_AVAILABLE: "Keine Vorschau für diese Datei verfügbar!",
  REPLY_TO_CC: "E-Mail CC",
  REPLY_TO_BCC: "E-Mail BCC",
  LEAVE_EMPTY_FOR_ALL_DAY_EVERYDAY: "Leer lassen für ganztägig, jeden Tag",
  RELEASE: "Release",
  RELEASES: "Releases",
  VERSION: "Version",
  EXECUTION_AT: "Ausführung am",
  EXECUTED_AT: "Ausgeführt am",
  FULLFILLED: "Erfüllt",
  OUTPUT: "Ausgabe",
  CLICK_TO_PRINT_TO_CONSOLE: "Klicken zum Drucken in die Konsole",
  PRE_QUEUE_TIME_LONG: "Journey-Zeit (Zeit in Journey vor Warteschlange oder Abschluss)",
  AUDIT: "Prüfung",
  AUDITS: "Prüfungen",
  SYS_USER: "System",
  AUDIT_ACTION_READ: "Lesen",
  AUDIT_ACTION_UPDATE: "Aktualisieren",
  AUDIT_ACTION_CREATE: "Erstellen",
  AUDIT_ACTION_DELETE: "Löschen",
  AUDIT_ACTION_SOFTDELETE: "Soft Delete",
  AUDIT_SEVERITY_L: "Niedrig",
  AUDIT_SEVERITY_M: "Mittel",
  AUDIT_SEVERITY_H: "Hoch",
  ACTION: "Aktion",
  SEVERITY: "Schweregrad",
  CHANGED_PROPS: "Geänderte Eigenschaften",
  EMPTY: "Leer",
  MAIL_STATUS: "E-Mail-Status",
  VOICE_STATUS: "Sprachstatus",
  UnAssign: "Zuweisung aufheben",
  READONLY_UI: "Schreibgeschützt (UI)",
  VOICE_RINGTONE: "Sprachklingelton",
  NOTIFICATION_RINGTONE: "Benachrichtigungston",
  MAX_FILESIZE: "Maximale Dateigröße",
  MIMETYPES: "MIME-Typen",
  API_KEY: "API-Schlüssel",
  API_KEYS: "API-Schlüssel",
  COPY_API_KEY_TO_CLIPBOARD: "API-Schlüssel in Zwischenablage kopieren",
  CREATED_BY: "Erstellt von",
  CONTACT_CONDITION: "Kontaktbedingung",
  DATA_KEY: "Feldalias",
  COMPARISON: "Vergleich",
  DISPLAY_QUEUE_CALLER_ID: "Warteschlangennummer anzeigen",
  MERGE_CONTACT_IF_EXISTS: "Kontaktdaten zusammenführen falls vorhanden",
  CUSTOM_DATA: "Benutzerdefinierte Daten",
  OVERRIDES_CURRENT: "Überschreibt aktuellen Wert",
  MERGES_AND_REMOVES_DUPLICATED: "Führt zusammen und entfernt Duplikate",
  MERGEANDKEEPEXISTING: "Zusammenführen und Bestehendes behalten",
  MERGEANDOVERRIDE: "Zusammenführen und Bestehendes überschreiben",
  CONVERSATION_OWNER_ONLY: "Nur Tabs der Konversationsagenten",
  INACTIVE_TABS_HIDDEN: "Inaktive Tabs wurden ausgeblendet",
  INACTIVE_TABS_DESCRIPTION:
    "Stellen Sie sicher, dass Sie nicht mehr als 7 Tabs geöffnet haben, indem Sie Ihre sichtbaren Tabs nacheinander schließen!",
  VIEW_EVENT: "Ereignis anzeigen",
  EVENT_ORDER: "Reihenfolge",
  CLIENT: "Client",
  CLIENTS: "Clients",
  SELECT_CLIENT: "Client auswählen",
  CLIENT_ID: "Client",
  DATA: "Daten",
  NO_CLIENT: "Kein Client",
  TRANSLATIONS: "Übersetzungen",
  TRANSLATION: "Übersetzung",
  __SUBMISSION_DISPLAY_FIELDS: "Einreichungsanzeigefelder",
  ADDITIONAL_QUEUES: "Zusätzliche Warteschlangen",
  HELP_ADDITIONAL_QUEUES:
    "Wählen Sie zusätzliche Warteschlangen, die im aktuellen Agentenprofil nicht verfügbar sind.",
  FROM_ADDITIONAL: "Aus zusätzlichen Warteschlangen",
  FORM_SETTINGS: "Formulareinstellungen",
  FORM_CAPACITY: "Formularkapazität",
  FIXED_LINE_OR_MOBILE: "Festnetz/Mobil",
  ATTACHMENTS_STORAGE: "Anhangspeicher",
  PARENTS: "Übergeordnete",
  ALL_QUEUES: "Alle Warteschlangen",
  PROFILE_QUEUES: "Profilwarteschlangen",
  DATA_CHANGED: "Felddatenänderungen",
  FORCE_LOGOUT: "Abmeldung erzwingen",
  JSON_RESPONSE: "JSON-Antwort",
  JSON_PATH: "JSON-Pfad",
  JSON_PATH_HELP: "Beispiel: data.property, data.array[0].property",
  DELETED_ITEM_REMOVE: "Gelöschtes Element muss aus ausgewählten Elementen entfernt werden!",
  UNASSIGN: "Zuweisung aufheben",
  ALLOW_AGENT_UNASSIGN: "Agent darf Zuweisung aufheben",
  ASTERISK_HOURS: "Asterisk-Stunden",
  TOGGLE_ASTERISK_HOURS: "Asterisk-Stunden umschalten",
  EXTERNAL_VOICE_RINGTONE: "Externer Anrufton",
  INTERNAL_VOICE_RINGTONE: "Interner Anrufton",
  LOAD_MORE: "Mehr laden",
  JOURNEY_TIMELINE: "Journey-Zeitlinie",
  TIMELINE: "Zeitlinie",
  EMPTY_TIMELINE: "Leere Zeitlinie",
  GLOBAL_SEARCH_QUEUES: "Globale Suchwarteschlangen",
  PERMANENTLY_DELETE_REPORT: "Bericht endgültig löschen?",
  PERMANENTLY_DELETE_REPORT_DESCRIPTION:
    "Alle einzigartigen Widgets in diesem Bericht werden mit dieser Aktion ebenfalls gelöscht.",
  PERMANENTLY_DELETE_DASHBOARD: "Dashboard endgültig löschen?",
  PERMANENTLY_DELETE_DASHBOARD_DESCRIPTION:
    "Alle einzigartigen Widgets in diesem Dashboard werden mit dieser Aktion ebenfalls gelöscht.",
  DELETE_WIDGET: "Widget löschen",
  DELETE_WIDGET_DESCRIPTION:
    "Widget wird endgültig gelöscht, wenn es in keinen anderen Dashboards/Berichten vorkommt.",
  CREATED_AFTER: "Erstellt nach",
  LAST_MESSAGE_BY_CONTACT: "Kontakt",
  LAST_MESSAGE_BY_AGENT: "Agent",
  SUBJECT_PREFIX: "Betreffpräfix",
  HIDE_STATS_PREVIEW: "Statistikvorschau ausblenden",
  DISPLAY_STATS_PREVIEW: "Statistikvorschau anzeigen",
  SOMETHING_WENT_WRONG_CLONING_WIDGET: "Beim Klonen ist etwas schiefgegangen",
  STATS_WIDGET_CUSTOM_PERIOD_TO_LONG:
    "Der benutzerdefinierte Zeitraum des Widgets darf 31 Tage nicht überschreiten.",
  TRANSFER_DASHBOARD: "Dashboard übertragen",
  TRANSFER_REPORT: "Bericht übertragen",
  TRANSFER_TO_REPORT_OWNER: "Widget an Berichtseigentümer übertragen",
  OF: "von",
  WIDGET_HAS_NO_DATA: "Widget hat keine Daten",
  RULE_OTHERWISE_MATCHED: 'Regel "Ansonsten" wurde angewendet',
  RULE_X: "Regel {{n}}",
  RULE_X_WAS_MATCHED: '"Regel {{n}}" wurde angewendet',
  EDIT_CONTACT: "Kontakt bearbeiten",
  PRECISION: "Genauigkeit",
  SKILL: "Fähigkeit",
  SKILLS: "Fähigkeiten",
  SKILL_ID: "Fähigkeit",
  LEVEL_ONE: "Stufe eins",
  LEVEL_TWO: "Stufe zwei",
  LEVEL_THREE: "Stufe drei",
  SKILL_TIMEOUT_SETTINGS: "Fähigkeiten-Timeout-Einstellungen",
  TIMEOUT_ONE: "Timeout eins",
  TIMEOUT_TWO: "Timeout zwei",
  TIMEOUT_THREE: "Timeout drei",
  SKILL_TIMEOUT_ONE_DESCRIPTION:
    "Benutzerfähigkeiten Stufe zwei werden nach diesem Timeout einbezogen",
  SKILL_TIMEOUT_TWO_DESCRIPTION:
    "Benutzerfähigkeiten Stufe drei werden nach diesem Timeout + vorherigem Timeout einbezogen",
  SKILL_TIMEOUT_THREE_DESCRIPTION:
    "Alle Fähigkeiten werden nach diesem Timeout + vorherigen Timeouts einbezogen",
  QUEUE_TIMEOUT_UNIT_SECOND: "Sekunden",
  QUEUE_TIMEOUT_UNIT_MINUTE: "Minuten",
  QUEUE_TIMEOUT_UNIT_HOUR: "Stunden",
  QUEUE_TIMEOUT_UNIT_DAY: "Tage",
  DEFAULT_VOICE_COUNTRY: "Standardland für Sprache",
  NO_SKILL: "Keine Fähigkeit",
  IS_WAITING: "Wartet",
  IS_ONGOING: "Laufend",
  PREVIEW_PERIOD: "Vorschauzeitraum",
  WALLBOARD: "Wallboard",
  WALLBOARD_KEY: "Wallboard-Schlüssel",
  STATS_REPORT_ID: "Bericht / Dashboard",
  SHARE_DATA: "Daten teilen",
  SHARE_DATA_DESCRIPTION:
    "Teilen Sie Daten wie Benutzer, Warteschlange, Disposition, E-Mail-Konto... Daten mit Wallboard-Betrachtern. Beachten Sie, dass geteilte Datenpunkte wie Warteschlangen alle Warteschlangen der Organisation enthalten und nicht nur die in den Widgets. Die Datenteilung sollte daher deaktiviert werden, wenn Sie das Wallboard mit externen Parteien teilen, die keinen Zugriff auf Datenpunktinformationen haben sollen.",
  WALLBOARD_NO_MORE_TRIES:
    "Sie wurden getrennt, bitte aktualisieren Sie und versuchen Sie es erneut!",
  DISPOSED: "Disponiert",
  STATUS_CHANGED: "Status geändert",
  ASSIGNED_TO: "Zugewiesen an",
  USER_ASSIGNED: "Benutzer zugewiesen",
  USER_UNASSIGNED: "Benutzer nicht mehr zugewiesen",
  UNASSIGNED_FROM: "Zuweisung aufgehoben von",
  QUEUE_ASSIGNED: "Warteschlange zugewiesen",
  TIMELINE_EVENT_TYPE_JOURNEYJOB: "Journey-Job",
  TIMELINE_EVENT_TYPE_VOICE: "Sprache",
  TIMELINE_EVENT_TYPE_NOTIFICATION: "Benachrichtigung",
  TIMELINE_EVENT_TYPE_AUDIT: "Prüfung",
  JOURNEY_TRIGGER_ID: "Auslöser",
  TRANSCRIPT: "Transkript",
  DEFAULT_LANGUAGE: "Standardsprache",
  VIEW_TRANSCRIPT: "Transkript anzeigen",
  DISPLAY_TRANSCRIPT: "Transkript anzeigen",
  CONDITION_AND_OR_AND: "Und",
  CONDITION_AND_OR_OR: "Oder",
  VOICE_RECORDING_TRANSCRIPT: "Sprachtranskript",
  WALLBOARDS: "Wallboards",
  SLA_RESPONSE: "Antwortzeit",
  SLA_RESOLUTION: "Lösungszeit",
  HAS_SLA: "Hat SLA",
  HAS_NOT_SLA: "Hat keine SLA",
  EXCEEDED: "Überschritten",
  NOT_EXCEEDED: "Nicht überschritten",
  TRANSCRIPT_TRIGGER: "Transkript-Auslöser an",
  CLICK_TO_TRANSCRIPT: "Klicken",
  RECORDED_BY_JOURNEY: "Von Journey aufgezeichnet",
  MANUALLY_RECORDED: "Manuell aufgezeichnet",
  GENERATE_TRANSCRIPT: "Transkript generieren",
  VOICE_RECORDING_TRANSCRIPT_SETTING: "Transkripteinstellungen",
  VOICE_RECORDING_TRANSCRIPT_SETTINGS: "Transkripteinstellungen",
  FIND_REPLACE: "Suchen & Ersetzen",
  FIND: "Suchen",
  REPLACE: "Ersetzen",
  MODEL: "Modell",
  VOICE_RECORDING_TRANSCRIPT_MODEL_WHISPER: "Whisper",
  VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA: "Nova",
  "VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA-2": "Nova 2",
  VOICE_RECORDING_TRANSCRIPT_MODEL_BASE: "Basis",
  VOICE_RECORDING_TRANSCRIPT_MODEL_ENHANCED: "Erweitert",
  TRANSCRIPTION_ERROR: "Transkriptionsfehler",
  CONFIDENCE: "Konfidenz",
  TRANSCRIPTION_TIME: "Transkriptionszeit",
  POINTS_SYSTEMS: "Punktesysteme",
  POINTS_SYSTEM: "Punktesystem",
  MAIL_MESSAGE_SENT: "E-Mail-Nachricht gesendet",
  INBOUND_VOICE_CALL: "Eingehender Sprachanruf",
  OUTBOUND_VOICE_CALL: "Ausgehender Sprachanruf",
  INTERNAL_VOICE_CALL: "Interner Sprachanruf",
  OUTBOUND_CONVERSATION_CREATED: "Ausgehende Konversation erstellt",
  OUTBOUND_CONVERSATION_RESOLVED: "Ausgehende Konversation gelöst",
  INBOUND_CONVERSATION_RESOLVED: "Eingehende Konversation gelöst",
  OUTBOUND_CONVERSATION_CLOSED: "Ausgehende Konversation geschlossen",
  INBOUND_CONVERSATION_CLOSED: "Eingehende Konversation geschlossen",
  POINTS: "Punkte",
  CONVERSATION_SKILL_ID: "Fähigkeit",
  JOURNEY_TRIGGER: "Journey-Auslöser",
  JOURNEY_TRIGGERS: "Journey-Auslöser",
  HIGHLIGHT_IN_JOURNEY: "In Journey hervorheben",
  HELP_CONVERSATION_FILTER_IS_WAITING:
    '"Wartet" bezieht sich auf Konversationen, die noch nicht beantwortet wurden und sich noch in der Warteschlange befinden.',
  HELP_CONVERSATION_FILTER_IS_ONGOING:
    '"Laufend" bezieht sich auf Konversationen, die nicht geschlossen oder gelöst sind und eine Antwort von einem Agenten erhalten haben (automatische Systemantworten sind ausgeschlossen).',
  POINTS_CREATED: "Erstellung",
  POINTS_RESOLVED: "Gelöst",
  POINTS_CLOSED: "Geschlossen",
  POINTS_TALK: "Sprechzeit",
  POINTS_MAIL_SENT: "Punkte für E-Mail-Versand",
  TREND_COMPARISON_VALUE: "Trendvergleichswert",
  CUSTOM_PERCENT: "Benutzerdefinierter Prozentsatz",
  STATS_CUSTOM_PERCENT_HELP:
    "Definieren Sie eine Zahl, um den Metrikwert zu teilen, um einen Prozentwert zu generieren und anzuzeigen",
  GDPR: "DSGVO",
  GDPRS: "DSGVOs",
  PASSWORD_MAY_NOT_BE_IN_HISTORY:
    "Das gewählte Passwort wurde kürzlich für dieses Konto verwendet und kann derzeit nicht wiederverwendet werden!",
  PASSWORD_LIFETIME_REACHED:
    "Ihr Passwort ist abgelaufen! Setzen Sie Ihr Passwort zurück, um auf die Anwendung zuzugreifen.",
  IP_ADDRESS_NOT_WHITELISTED:
    "Ihre IP-Adresse ist von keiner Ihrer Organisationen auf der Whitelist!",
  PASSWORD_EXPIRY_DESCRIPTION:
    "Passwortlebensdauer läuft in weniger als {{days_left}} Tagen ab. Stellen Sie sicher, dass Sie Ihr Passwort ändern.",
  PASSWORD_NOT_STRONG:
    "Das gewählte Passwort ist nicht stark genug! Stellen Sie sicher, dass Ihr Passwort mindestens einen Klein- und Großbuchstaben, ein Sonderzeichen und eine Zahl enthält!",
  FORM_ERROR_PASSWORD_NOT_STRONG:
    "Muss mindestens einen Klein- und Großbuchstaben, ein Sonderzeichen und eine Zahl enthalten",
  IP_WHITELIST: "IP-Whitelist",
  IP_WHITELIST_DESCRIPTION: "Beschränken Sie den Zugriff auf Ihre Organisation über IP-Adresse.",
  IP_ADDRESSES: "IP-Adressen",
  CONNECTEL_INFO_SHARE: "Informationsnutzung",
  LIFETIME: "Lebensdauer",
  HISTORY: "Verlauf",
  STRONG: "Stark",
  CONNECTEL_INFO_SHARE_DESCRIPTION:
    "Zustimmung, dass Connectel anonymisierte Statistiken und Daten aus Ihrer Organisation sammelt, um unsere Dienstleistungen zu verbessern, die wir Ihnen zur Verfügung stellen.",
  PASSWORD_STRONG: "Passwortrichtlinie",
  PASSWORD_STRONG_DESCRIPTION: "Erzwinge die Verwendung starker Passwörter.",
  PASSWORD_LIFETIME: "Passwortlebensdauer",
  PASSWORD_LIFETIME_DESCRIPTION:
    "Legen Sie die maximale Anzahl von Tagen fest, die ein Passwort gültig sein darf, bevor es geändert werden muss.",
  PASSWORD_HISTORY: "Passwortverlauf",
  PASSWORD_HISTORY_DESCRIPTION: "Beschränkt die Verwendung zuvor verwendeter Passwörter.",
  ANONYMIZATION: "Anonymisierung",
  AGENT_ANONYMIZATION_DESCRIPTION:
    "Anonymisiere den Agentennamen in Konversationen und anderen Datenpunkten bei der Löschung.",
  EMAIL_ATTACHMENTS: "E-Mail-Anhänge",
  FORM_SUBMISSION_ATTACHMENTS: "Formularanhänge",
  DELETION: "Löschung",
  VOICE_RECORDINGS: "Sprachaufnahmen",
  CONTACT_ANONYMIZATION_DESCRIPTION:
    "Anzahl der Tage, die beibehalten und von der Anonymisierung ausgenommen werden sollen. Diese Einstellung gilt nur für inaktive Kontakte und Konversationen.",
  CONTACT_DELETION_RECORDINGS_DESCRIPTION:
    "Anzahl der Tage, die beibehalten und von der Löschung ausgenommen werden sollen. Diese Einstellung gilt nur für inaktive Kontakte und Konversationen.",
  CONTACT_VOICE_RECORDINGS_DESCRIPTION:
    "Anzahl der Tage, die beibehalten und von der Löschung ausgenommen werden sollen.",
  CONTACT_EMAIL_ATTACHMENTS_DESCRIPTION:
    "Anzahl der Tage, die beibehalten und von der Löschung ausgenommen werden sollen.",
  CONTACT_FORM_SUBMISSION_ATTACHMENTS_DESCRIPTION:
    "Anzahl der Tage, die beibehalten und von der Löschung ausgenommen werden sollen.",
  ANONYMIZED: "Anonymisiert",
  LOGIN_ERROR: "Anmeldung fehlgeschlagen",
  ANONYMIZE_CONTACT_AND_CONVERSATIONS: "Kontakt anonymisieren",
  CONFIRM_ANONYMIZE: "Anonymisierung bestätigen",
  CONFIRM_ANONYMIZE_DESCRIPTION:
    "Durch die Anonymisierung des Kontakts werden alle damit verbundenen Daten zum Kontakt oder seinen Konversationen anonymisiert. Diese Aktion kann nicht rückgängig gemacht werden!",
  ANONYMIZATION_ERROR: "Anonymisierungsfehler",
  ANONYMIZE_CONTACTS: "Kontakte anonymisieren",
  TRANSCRIPT_ANONYMIZED: "Transkript anonymisiert",
  OpenBrowserTab: "Browser-Tab öffnen",
  MAX_INACTIVITY_READY: "Maximale Inaktivität für Agentenprofile",
  ORGANIZATION_HAS_NOT_VOICE_ASTERISK:
    "Ausgewählte Organisation hat keinen Voice-Asterisk zugewiesen!",
  SIGNATURE_PREVIEW: "Signaturvorschau",
  KEYWORDS: "Schlüsselwörter",
  TAG_KEYWORDS_HELP: "Schlüsselwörter sind nicht case-sensitive.",
  AUTO_TAGS: "Automatische Tags",
  VOICE_TRANSCRIPT: "Sprachtranskript",
  SUB_DISPOSITIONS: "Unterklassifizierungen",
  THRD_DISPOSITIONS: "Drittklassifizierungen",
  STATS_ALARMS: "Alarme",
  STATS_ALARM: "Alarm",
  TEXT: "Text",
  AUTO_TEXT: "Text automatisch generieren",
  STATS_ALARM_PERIOD_LIVE_TODAY: "Heute (Live)",
  STATS_ALARM_PERIOD_TODAY: "Heute",
  STATS_ALARM_PERIOD_LAST_24_HOURS: "Letzte 24 Stunden",
  STATS_ALARM_PERIOD_THIS_WEEK: "Diese Woche",
  STATS_ALARM_PERIOD_THIS_WEEK_SUN: "Diese Woche (Start Sonntag)",
  STATS_ALARM_PERIOD_LAST_7_DAYS: "Letzte 7 Tage",
  STATS_ALARM_PERIOD_THIS_MONTH: "Dieser Monat",
  STATS_ALARM_PERIOD_LAST_30_DAYS: "Letzte 30 Tage",
  LAST_MATCH_AT: "Letzte Übereinstimmung",
  MIN_INTERVAL: "Mindestintervall",
  STATS_ALARM_MIN_INTERVAL_HELP:
    "Mindestintervall nach dem Senden einer Benachrichtigung! Um Spam zu vermeiden, da sich Live-Metriken häufig ändern können.",
  NOTIFICATION: "Benachrichtigung",
  ATLAS_NOTIFICATION: "Atlas-Benachrichtigung",
  USER_IDS: "Benutzer",
  HIGHLIGHTED: "Hervorgehoben",
  MATCHED_CONDITION: "erfüllte Bedingung",
  SOUND_TYPE_ELEVENLABS: "Text zu Sprache",
  SOUND_TYPE_FILEUPLOAD: "Datei-Upload",
  ELEVENLABS_VOICE_ALICE: "Alice",
  ELEVENLABS_VOICE_ARIA: "Aria",
  ELEVENLABS_VOICE_BILL: "Bill",
  ELEVENLABS_VOICE_BRIAN: "Brian",
  ELEVENLABS_VOICE_CALLUM: "Callum",
  ELEVENLABS_VOICE_CHARLIE: "Charlie",
  ELEVENLABS_VOICE_CHARLOTTE: "Charlotte",
  ELEVENLABS_VOICE_CHRIS: "Chris",
  ELEVENLABS_VOICE_DANIEL: "Daniel",
  ELEVENLABS_VOICE_ERIC: "Eric",
  ELEVENLABS_VOICE_GEORGE: "George",
  ELEVENLABS_VOICE_JESSICA: "Jessica",
  ELEVENLABS_VOICE_LAURA: "Laura",
  ELEVENLABS_VOICE_LIAM: "Liam",
  ELEVENLABS_VOICE_LILY: "Lily",
  ELEVENLABS_VOICE_MATILDA: "Matilda",
  ELEVENLABS_VOICE_RIVER: "River",
  ELEVENLABS_VOICE_ROGER: "Roger",
  ELEVENLABS_VOICE_SARAH: "Sarah",
  ELEVENLABS_VOICE_WILL: "Will",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_CONVERSATIONAL_SWEDISH:
    "Sanna Hartfield - Schwedische Konversation",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_SASSY_SWEDISH: "Sanna Hartfield - Freche schwedische Stimme",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_NARRATION_SWEDISH: "Sanna Hartfield - Schwedische Erzählstimme",
  ELEVENLABS_VOICE_JONAS: "Jonas, ruhige & informative schwedische Stimme",
  ELEVENLABS_SETTINGS: "Generator-Einstellungen",
  QUEUE_SORT_LASTINBOUNDMESSAGEAT: "Letzte eingehende Nachricht um",
  QUEUE_SORT_LASTOUTBOUNDMESSAGEAT: "Letzte ausgehende Nachricht um",
  QUEUE_SORT_UNHANDLED: "Unbearbeitet",
  QUEUE_SORT_QUEUEJOINTIME: "Wartezeit",
  SORT: "Sortieren nach",
  EFFECTIVE_WORK_TIME: "Effektive Arbeitszeit",
  ARTIFICIELL_INTELLIGENCE: "Künstliche Intelligenz",
  AI_ASSISTANTS: "KI-Assistenten",
  AI_ASSISTANT: "KI-Assistent",
  KB_UNIQUE_ID: "KB eindeutige ID",
  AI_ASSISTANT_ID: "KI-Assistent",
  NO_AI_ASSISTANT_ON_QUEUE: "Kein KI-Assistent in dieser Warteschlange",
  NEW_MAIL_MESSAGE_WITH_AI: "Neue E-Mail-Nachricht mit KI",
  REPLY_WITH_AI: "Mit KI antworten",
  AI_RESPONDING: "KI antwortet...",
  AI_RESPONSE_CONFIDENCE: "KI-Antwort-Zuverlässigkeit",
  AI_REQUEST_KB_ASSISTANT: "KI-Anfrage (Antwortassistenz)",
  AI_REQUEST_KB_ASSISTANCE: "KI-Anfrage (Antwortassistenz)",
  KbAssistant: "Antwortassistenz",
  REQ: "Anfr",
  INVOICE_POST_VOICE_RECORDING_TRANSCRIPT: "Sprachaufnahme-Transkript",
  TEXT_TO_VOICE_USAGE: "Text-zu-Sprache-Nutzung",
  QUEUE_SORT_HELP:
    '"Unbearbeitet" sortiert nach Wartezeit und filtert zusätzlich nur Konversationen, die noch nicht beantwortet wurden.',
  FIELD_CONDITIONS: "Feldbedingungen",
  FIELD_TO_AFFECT: "Zu beeinflussendes Feld",
  CONDITIONAL_FIELD: "Bedingtes Feld",
  THEN: "Dann",
  FORBID: "Verbieten",
  MAKE_OPTIONAL: "Optional machen",
  IF: "Wenn",
  MAX: "MAX",
  MAXIMUM: "Maximum",
  HELP: "Hilfe",
  HIDE_HELP: "Hilfe ausblenden",
  VALIDATION_MAXIMUM_NUMBER_HELP: "Maximale Zahl, die in dieses Feld eingegeben werden kann",
  VALIDATION_MAXIMUM_LIST_HELP: "Maximale Anzahl von Optionen",
  VALIDATION_MAXIMUM_TEXT_HELP: "Maximale Anzahl von Zeichen",
  DEFAULT_VALUE: "Standardwert",
  IMPORT_FORM_SUBMISSION: "Formulareinsendung importieren",
  SAVE_REPLY: "Antwort speichern",
  EDIT_REPLY: "Antwort bearbeiten",
  CREATE_REPLY: "Antwort erstellen",
  HIDE_REPLY: "Antwort ausblenden",
  IMPORT_CONVERSATION_FORM: "Konversationsformular importieren",
  CUSTOM_ANSWER: "Benutzerdefiniert",
  FIELD_CUSTOM_ANSWER_HELP:
    "Der Endbenutzer kann eine benutzerdefinierte Antwort in ein Textfeld eingeben. Der angegebene Wert wird als Alias {field_alias}_custom gesetzt.",
  CLICK_TO_COPY: "Zum Kopieren klicken",
  RECONNECTS_AT: "Verbindet neu um",
  DOWNLOAD_EML: "E-Mail-Nachricht herunterladen .eml",
  USER_SETTINGS: "Benutzereinstellungen",
  ENGLISH: "Englisch",
  SWEDISH: "Schwedisch",
  DANISH: "Dänisch",
  NORWEGIAN: "Norwegisch",
  FINNISH: "Finnisch",
  GERMAN: "Deutsch",
  SHOW_MORE_FIELDS: "Weitere Felder anzeigen",
  INVALID: "Ungültig",
  TOO_SHORT: "Zu kurz",
  TOO_LONG: "Zu lang",
  ERROR_QUEUE_NO_CREATE_LIST: "Warteschlange hat keine Erstellungsliste",
  TEXT_MESSAGE: "Textnachricht",
  TEXT_MESSAGE_SOURCE_CREATED: "Textnachrichtenquelle erstellt",
  TEXT_MESSAGE_SOURCE_CREATED_ERROR: "Fehler beim Erstellen der Textnachrichtenquelle",
  TEXT_MESSAGE_SOURCE: "Textnachrichtenquelle",
  TEXT_MESSAGE_SOURCES: "Textnachrichtenquellen",
  ALPHA_TAG: "Alpha-Tag",
  SELECT_ORGANIZATION: "Organisation auswählen",
  MESSAGE: "Nachricht",
  SELECT_SOURCE: "Quelle auswählen",
  SELECT_QUEUE_WITH_CREATE_LIST:
    "Bitte wählen Sie eine Warteschlange mit einer Erstellungsliste aus, um einen Kontakt zu erstellen",
  BEGIN_TYPING_TO_SEE_CONTACT: "Beginnen Sie zu tippen, um Kontakte zu sehen...",
  MISSING_CONTACT: "Kontakt fehlt",
  MISSING_EMAIL: "E-Mail fehlt",
  MISSING_SUBJECT: "Betreff fehlt",
  MISSING_CONTACT_FORM: " Das Kontaktformular fehlt",
  VERIFIED: "Verifiziert",
  TEXT_MESSAGE_SOURCE_UPDATED: "Textnachrichtenquelle aktualisiert",
  TEXT_MESSAGE_SOURCE_UPDATE_FAILED: "Fehler beim Aktualisieren der Textnachrichtenquelle",
  SENDER_PHONE_NUMBER: "Absender-Telefonnummer",
  CONVERSATION_ENUM_ID: "Konversations ID",
  CONVERSATION_ENUM_EMAIL: "Kontakt email",
  CONVERSATION_ENUM_STATUS: "Status",
  CONVERSATION_ENUM_CHANNEL: "Kanal",
  CONVERSATION_ENUM_DIRECTION: "Richtung",
  CONVERSATION_ENUM_QUEUE_NAME: "Warteschlangenname",
  CONVERSATION_ENUM_AGENT_NAME: "Agentenname",
  CONVERSATION_ENUM_DISPOSITION: "Anordnung",
  CONVERSATION_ENUM_CREATED_DATE: "Erstellungsdatum",
  CONVERSATION_ENUM_CALLER_ID: "Anrufer ID",
  CONVERSATION_ENUM_ORGANIZATION_ID: "Organisations ID",
  CONVERSATION_ENUM_ORGANIZATION_TITLE: "Organisationstitel",
  DESELECT_ALL: "Alle abwählen",
  CONVERSATION_DOWNLOAD: "Felder für Download wählen",
  NEW_TEXT_MESSAGE: "Neue Textnachricht",
  SUMMARIZE_CONVERSATION: "Konversation zusammenfassen",
  GET_NEW_SUMMARY: "Neue Zusammenfassung abrufen",
  CONVERSATION_SUMMARY: "Zusammenfassung",
  OPEN_SUMMARY: "Zusammenfassung öffnen",
  CLOSE_SUMMARY: "Zusammenfassung schließen",
  MESSAGE_SENT_SUCCESSFULLY: "Nachricht erfolgreich gesendet",
  MESSAGE_TOO_SHORT: "Nachricht muss mindestens 10 Zeichen lang sein",
  MISSING_SOURCE: "Quelle fehlt",
  MISSING_PHONE_NUMBER: "Telefonnummer fehlt",
  ERROR_SENDING_MESSAGE: "Fehler beim Senden der Nachricht",
  MISSING_QUEUE: "Warteschlange fehlt",
};
