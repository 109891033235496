import { THighlightedMessage } from "@Hooks/use.highlighted.message";
import { IAuth } from "@Store/types";
import { getContactFullName, timeSince } from "@Utils";
import {
  DirectionEnum,
  ISharedFullConversation,
  ITextMessage,
  TConversationTextMessage,
} from "atlas-shared";
import React from "react";
import { useTranslation } from "react-i18next";
import "./conversation-message-text.scss";
import { ConversationMessageReply } from "../conversation.message.reply";
import { Avatar } from "@Components/misc/avatar";

interface IProps {
  message: ITextMessage;
  conversation: ISharedFullConversation;
  auth: IAuth;
  highlightedMessage: THighlightedMessage;
  avatar?: React.ReactNode;
}

export const ConversationMessageText = React.memo((props: IProps) => {
  const { message, conversation, auth, avatar } = props;
  const { t } = useTranslation();

  return (
    <div className={`conversation-message ${message.direction === "I" ? "inbound" : "outbound"}`}>
      <div className="conversation-message-text">
        <ConversationMessageReply
          t={t}
          auth={auth}
          message={message as TConversationTextMessage}
          conversation={conversation}
        />
        <div className="conversation-message-content">
          <div className="head">
            <div className="info">
              <div className="time">{timeSince(t, new Date(message.created_at || ""), true)}</div>
              {message.direction === DirectionEnum.Outbound && avatar}
              {message.direction === DirectionEnum.Outbound && (
                <>
                  {
                    <div className="source-wrapper">
                      <span className="source">{t("SOURCE")}: </span>
                      <span className="alpha-tag">{message.source?.alphaTag ?? "..."}</span>
                    </div>
                  }
                  <div className="to-wrapper">
                    <span className="to">{t("TO")}: </span>
                    <span className="number">{message.number}</span>
                  </div>
                </>
              )}
              {message.direction === DirectionEnum.InBound && conversation.contact && (
                <div>
                  <span className="phone-number">{message.number}</span>
                  <div className="user-info">
                    <Avatar size={14} />
                    <span className="name">{getContactFullName(conversation.contact, t)}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="text">{message.text}</div>
        </div>
      </div>
    </div>
  );
});
