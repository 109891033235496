"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionNoOp = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
exports.JourneyActionNoOp = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.NoOp,
    direction: enums_1.JourneyNodeDirectionEnum.Output,
    getPorts: (node) => {
        return base_1.journeyDefaultInputPort;
    },
});
