import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import "./form.element.phone.scss";
import { CountryFlagImg, phoneNumberValidateAndParse } from "@Utils";
import { useTranslation } from "react-i18next";

import { CountryCode } from "libphonenumber-js";
import { Countries } from "atlas-shared/dist";

interface IProps {
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  onBlur?: Function;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const FormElementPhone = React.memo(
  (props: IProps) => {
    const { defaultValue, onBlur, style, className, placeholder, disabled } = props;
    const { t } = useTranslation();
    const [fullNumber, setFullNumber] = useState("");
    const [countryCode, setCountryCode] = useState<CountryCode>();
    const [phoneNumber, setPhoneNumber] = useState<string>(defaultValue || "");
    const [parsed, setParsed] = useState<boolean>(false);

    useEffect(() => {
      const parsed = defaultValue ? phoneNumberValidateAndParse(defaultValue) : undefined;

      if (defaultValue && parsed && parsed.country) {
        setCountryCode(parsed.country);
        setPhoneNumber(parsed.nationalNumber);
        setFullNumber(defaultValue);
      } else {
        setCountryCode("SE");
      }

      setParsed(true);
    }, []);

    useEffect(() => {
      if (!parsed) return;

      setFullNumber(
        (phoneNumber ? phoneNumberValidateAndParse(phoneNumber, countryCode) : {})?.number || ""
      );
    }, [countryCode, phoneNumber]);

    useEffect(() => {
      onBlur?.(fullNumber);
    }, [fullNumber]);

    if (!parsed) return <></>;

    return (
      <div
        className={`form-phone-element ${className}`}
        style={{
          ...style,
          display: "flex",
        }}
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            (option!.label as string).toLowerCase().includes(input.toLowerCase())
          }
          defaultValue={countryCode}
          onChange={(c) => setCountryCode(c as CountryCode)}
          className="country-code"
          disabled={disabled}
        >
          {Countries.map(([iso, code]) => (
            <Select.Option key={iso} value={iso} label={`${t(`COUNTRY_${iso}`)} +${code}`}>
              <CountryFlagImg t={t} country={iso} width={18} />
              <span style={{ paddingLeft: 5 }}>
                (+{code}) {t(`COUNTRY_${iso}`)}
              </span>
            </Select.Option>
          ))}
        </Select>
        <Input
          defaultValue={phoneNumber}
          className="phone-number"
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.defaultValue === nextProps.defaultValue &&
      prevProps.className === nextProps.className &&
      prevProps.placeholder === nextProps.placeholder &&
      prevProps.disabled === nextProps.disabled &&
      prevProps.onBlur === nextProps.onBlur &&
      JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    );
  }
);
