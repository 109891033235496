import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { Button, Popover, } from 'antd';
import './voice-suggestions.scss';
import {
  ChannelEnum,
  DirectionEnum,
  IPreviewAgentprofile,
  IPreviewVoiceAsterisk,
  IPreviewVoiceCall,
  IPreviewVoiceShortcut,
  isEmergencyNumber,
  ISharedFullConversation,
  Nullable, TConversationVoiceCall,
  Undefinable,
  UserStatusStatusEnum,
} from 'atlas-shared';
import {
  useAgentprofiles,
  useAuth,
  useConversations,
  useQueues,
  useSipUsers,
  useUsers,
  useUserStatuses,
  useVoiceAsterisks,
  useVoiceCalls,
  useVoiceShortcuts,
} from '@Hooks';
import {
  AlertError,
  CountryFlagImg,
  DisplayPhoneNumber,
  getActiveCalls,
  isSuperAdmin,
  pickVoiceCall,
  setPreventDefault,
  translatePhoneNumber,
} from '@Utils';
import debounce from 'lodash/debounce';
import {
  IAgentCallSuggestion,
  queueSearchString,
  TQueueCallSuggestion,
  TVoiceSuggestionOnSelect,
  userSearchString,
} from './voice-suggestion.utils';
import { VoiceSuggestionAgent, } from './voice-suggestion-agent';
import { VoiceSuggestionQueue, } from './voice-suggestion-queue';
import { PhoneNumber, } from 'libphonenumber-js/types';
import { actionFetchUserStatuses, TGlobalCallsInterface, useAppDispatch, } from '@Store';
import { VoiceSuggestionNumber, } from './voice-suggestion-number';
import { attendedTransferVoiceCallBridge, holdVoiceCallBridge, unholdVoiceCallBridge, unsubscribeEntity, } from '@Api';
import { useTranslation, } from 'react-i18next';
import { getSipUsersWrapper, IVoiceSessionObject, SipUser, } from 'SIP';
import { QueueRouteSelection, } from '@Utils/queue-route';
import { VoiceSuggestionVoiceShortcut, } from './voice-suggestion-voice_shortcut';
import { IconText, } from '@Components/misc';
import { EmergencyIcon, } from '@Assets';

interface IProps {
  popover?: boolean;
  conversation?: ISharedFullConversation;
  transfer?: {
    voice_call: TConversationVoiceCall;
    conversation: ISharedFullConversation;
    voice_session: IVoiceSessionObject;
    sip_user: SipUser;
  };
}

interface ITransferableItem {
  voice_call: TConversationVoiceCall;
  voice_session: IVoiceSessionObject;
}

export const VoiceSuggestions = React.memo((props: IProps) => {

  const { popover, transfer, } = props;
  const { t, } = useTranslation();
  const inputRef = useRef<Nullable<HTMLInputElement>>(null);
  const suggestionsRef = useRef<Nullable<HTMLUListElement>>(null);
  const sipUsersWrapper = getSipUsersWrapper();
  const conversations = useConversations(false);
  const users = useUsers();
  const user_statuses = useUserStatuses();
  const queues = useQueues();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const sip_users = useSipUsers();
  const voice_calls = useVoiceCalls();
  const voice_asterisks = useVoiceAsterisks();
  const voice_shortcuts = useVoiceShortcuts();
  const agentprofiles = useAgentprofiles();

  const [search, setSearch, ] = useState<Undefinable<string>>('');
  const [latestSuggestions, setLatestSuggestions, ] = useState<Array<IPreviewVoiceCall>>([]);
  const [agentSuggestions, setAgentSuggestions, ] = useState<Array<IAgentCallSuggestion>>([]);
  const [profileQueuesSuggestions, setProfileQueuesSuggestions, ] = useState<Array<TQueueCallSuggestion>>([]);
  const [allQueuesSuggestions, setAllQueuesSuggestions, ] = useState<Array<TQueueCallSuggestion>>([]);
  const [voiceShortcuts, setVoiceShortcuts, ] = useState<Array<IPreviewVoiceShortcut>>([]);
  const [phonenumber, setPhonenumber, ] = useState<Undefinable<PhoneNumber>>();
  const [suggestionsVisible, setSuggestionVisible, ] = useState<boolean>(false);
  const [conversation, setConversation, ] = useState<Undefinable<ISharedFullConversation>>(props.conversation);
  const [voiceAsterisk, setVoiceAsterisk, ] = useState<IPreviewVoiceAsterisk>(sip_users.sip_users?.[0]?.voice_asterisk);
  const [selected, setSelected, ] = useState<number>(-1);
  const [options, setOptions, ] = useState<Array<React.ReactNode>>([]);
  const [transferable, setTransferable, ] = useState<Array<ITransferableItem>>([]);
  const [searchFocused, setSearchFocused, ] = useState<boolean>(!popover);
  const [routeSelectFocused, setRouteSelectFocused, ] = useState<boolean>(false);
  const [agentprofile, setAgentprofile, ] = useState<IPreviewAgentprofile>();
  const callBtn = useMemo(() => <Button type='primary' className='call-btn'>{t(transfer ? 'TRANSFER' : 'CALL')}</Button>, []);

  const searchingSuggestion = useCallback(() => {
    if (!props.conversation)
      setConversation(getActiveCalls(auth, conversations));
  }, [conversations, props.conversation, ]);

  useEffect(() => {
    if (props.conversation && conversation?.id !== props.conversation.id)
      setConversation(props.conversation);
  }, [props.conversation, ]);

  useEffect(() => {
    setSuggestionVisible(searchFocused || routeSelectFocused);
  }, [searchFocused, routeSelectFocused, ]);

  useEffect(() => {
    if (transfer && conversation) {
      setTransferable((conversation.messages
        .filter(message => message.channel === ChannelEnum.Voice && message.id !== transfer.voice_call.id && !(message as IPreviewVoiceCall).end_time) as Array<IPreviewVoiceCall>)
        .map(voice_call => ({
          voice_call,
          voice_session: transfer.sip_user.getSessionBySharedVoiceCall(voice_call),
        })) as Array<ITransferableItem>);
    }
  }, [transfer, conversation, ]);

  useEffect(() => {
    setAgentprofile(auth.user_status.agentprofile_id ? agentprofiles.dict[auth.user_status.agentprofile_id] : undefined);
  }, [agentprofiles, auth.user_status.agentprofile_id, ]);

  useEffect(() => {

    if (suggestionsVisible)
      dispatch(actionFetchUserStatuses());
    else
      unsubscribeEntity('user_status', 'main');

  }, [suggestionsVisible, dispatch, ]);

  /* eslint-disable react-hooks/exhaustive-deps*/
  const performSearch = useCallback(debounce((text: Undefinable<string>) => {
    setPhonenumber(text ? translatePhoneNumber(text, agentprofile?.settings?.iso2 || voiceAsterisk.iso2) : undefined);
    setSearch(text?.toLowerCase());
    setSelected(getNextOption());
  }, 250), [agentprofile, voiceAsterisk, ]);

  useEffect(() => {
    if ((search || '').length > 9)
      setPhonenumber(search ? translatePhoneNumber(search, voiceAsterisk.iso2) : undefined);

    performSearch(search);
  }, [search, performSearch, ]);

  useEffect(() => {
    if (selected > options.length - 1)
      setSelected(options.findIndex(option => typeof option === 'string'));
  }, [options, ]);

  const getNextOption = useCallback(() => {
    const _selected = options.length === selected ? -1 : selected;

    return options.findIndex((option, index) => index > _selected && typeof option !== 'string');
  }, [options, selected, ]);

  const getPrevOption = useCallback(() => {
    let looped = false;
    let _selected = selected;

    while (true) {
      _selected--;
      if (!looped && _selected < 0) {
        _selected = options.length - 1;
        looped = true;
      }

      if (looped && _selected < 0)
        break;

      if (typeof options[_selected] !== 'string')
        return _selected;
    }

    return -1;
  }, [options, selected, ]);

  useEffect(() => {
    if (selected === -1 && options.length)
      setSelected(getNextOption());
  }, [selected, options, ]);

  useEffect(() => {
    setVoiceAsterisk(sip_users.sip_users?.[0].voice_asterisk);
  }, [sip_users.sip_users, ]);

  const onSelect: TVoiceSuggestionOnSelect = useCallback(async (calls, clicked) => {

    // if (transfer && transfer_session_key) {
    //   if (!headers.some(h => h.startsWith('X-User-ID: ')))
    //     headers.push(`X-User-ID: ${transfer.voice_call.user_id}`);
    //
    //   if (!headers.some(h => h.startsWith('X-Conversation-ID: ')))
    //     headers.push(`X-Conversation-ID: ${transfer.conversation.id}`);
    // }

    //makeCall(destination, clicked, !!requires_route, headers);

    if (conversation) {
      await Promise.all(conversation.messages.map(message => {
        if (message.channel === ChannelEnum.Voice) {
          const call = message as TConversationVoiceCall;
          const voice_asterisk = voice_asterisks.dict[call.voice_asterisk_id];

          if (!voice_asterisk?.ari_based)
            return [];

          return Promise.all(call.voice_call_bridges.map(bridge => {
            if (!bridge.leave_at && !bridge.hold_at)
              return holdVoiceCallBridge(bridge.organization_id, bridge.id);

            return;
          }));
        }
      }));
    }

    pickVoiceCall(dispatch, calls.map(call => {
      return {
        ...call,
        conversation_id: conversation?.id || transfer?.conversation.id,
        transfer: transfer?.voice_call,
      } as TGlobalCallsInterface;
    }));
  }, [dispatch, conversation, transfer, voice_asterisks, ]);

  const calculateVoiceShortcutSuggestions = useCallback(() => {
    const nsearch = search && search.startsWith('0') ? search.substr(1, search.length - 1) : search;
    const shortcuts: Array<IPreviewVoiceShortcut> = [];

    if (agentprofile && voice_shortcuts.loaded)
      for (const voice_shortcut_id of agentprofile.__voice_shortcuts) {
        const voice_shortcut = voice_shortcuts.dict[voice_shortcut_id];

        if (voice_shortcut && (!nsearch || `${voice_shortcut.title.toLowerCase()}${voice_shortcut.number}`.includes(nsearch)))
          shortcuts.push(voice_shortcut);

        if (conversation && shortcuts.length >= 10)
          break;
      }

    setVoiceShortcuts(shortcuts);
  }, [search, agentprofile, voice_shortcuts, transferable, ]);

  const calculateQueueSuggestions = useCallback(() => {
    const all_queues: Array<TQueueCallSuggestion> = [];
    const profile_queues: Array<TQueueCallSuggestion> = [];

    if (suggestionsVisible && conversation)
      queues.queues.forEach(queue => {
        if (!queue.voice_settings || queue.deleted_at)
          return undefined;

        const queue_users = user_statuses.user_statuses.filter(user_status => user_status.__current_queues.includes(queue.id));

        if (!queue.voice_settings.strategy && !queue_users.length)
          return undefined;

        const selectable = !!queue.voice_settings.strategy;
        const filtered_queue_users = queue_users.map(user_status => {
          const user = users.dict[user_status.user_id];

          if (
            (search && !userSearchString(user).includes(search))
          || user.id === auth.user.id
          || isSuperAdmin(user) ||
          user_status.voice_status < UserStatusStatusEnum.Idle
          )
            return undefined;

          return { user_status, user, };
        }).filter(Boolean) as Array<IAgentCallSuggestion>;

        if ((search && (
          (!queueSearchString(queue).includes(search) && !filtered_queue_users.length)
        )) || (!selectable && !filtered_queue_users.length))
          return undefined;

        const response = {
          selectable,
          ...queue,
          users: filtered_queue_users,
        };

        if (auth.user_status.__current_queues.includes(queue.id))
          profile_queues.push(response);
        else
          all_queues.push(response);
      });

    setAllQueuesSuggestions(all_queues);
    setProfileQueuesSuggestions(profile_queues);

  }, [search, suggestionsVisible, auth.user.id, conversation, queues, user_statuses, users, ]);

  const calculateAgentSuggestions = useCallback(() => {
    setAgentSuggestions(suggestionsVisible && !conversation ? user_statuses.user_statuses.map(user_status => {
      const user = users.dict[user_status.id];

      if (search && !userSearchString(user).includes(search))
        return undefined;

      if (
        (search && !userSearchString(user).includes(search))
        || user.id === auth.user.id
        || isSuperAdmin(user) ||
        user_status.voice_status < UserStatusStatusEnum.Idle
      )
        return undefined;

      return {
        user_status,
        user,
      };
    }).filter(Boolean) as Array<IAgentCallSuggestion> : []);
  }, [search, suggestionsVisible, conversation, user_statuses, auth, users, ]);

  const calculateLatestSuggestions = useCallback(() => {
    const nsearch = search && search.startsWith('0') ? search.substr(1, search.length - 1) : search;

    setLatestSuggestions(suggestionsVisible ? voice_calls.voice_calls.filter(voice_call =>
      ((!conversation && voice_call.direction !== DirectionEnum.Internal) || (conversation && [DirectionEnum.Internal, DirectionEnum.Outbound, ].includes(voice_call.direction)))
      //voice_call.transfer_type !== VoiceCallTransferTypeEnum.Attended
      // && !(voice_call.direction === DirectionEnum.Internal && !conversation)
      // && voice_call.direction !== DirectionEnum.Internal
      // && voice_call.queue_id && auth.user_status.__current_queues.includes(voice_call.queue_id)
      && (!search || (nsearch && voice_call.contact_phone.includes(nsearch)))
    ).slice(0, 5) : []);
  }, [search, suggestionsVisible, conversation, voice_calls, ]);

  useEffect(() => {
    if (!popover)
      searchingSuggestion();
  }, [popover, searchingSuggestion, ]);

  useEffect(() => {
    if (user_statuses.loaded && queues.loaded)
      calculateQueueSuggestions();
  }, [user_statuses.loaded, queues.loaded, search, suggestionsVisible, conversation, calculateQueueSuggestions, ]);

  useEffect(() => {
    if (voice_shortcuts.loaded && agentprofiles.loaded)
      calculateVoiceShortcutSuggestions();
  }, [voice_shortcuts.loaded, agentprofiles.loaded, search, suggestionsVisible, calculateVoiceShortcutSuggestions, ]);

  useEffect(() => {
    if (user_statuses.loaded)
      calculateAgentSuggestions();
  }, [user_statuses.loaded, search, suggestionsVisible, conversation, calculateAgentSuggestions, ]);

  useEffect(() => {
    if (voice_calls.loaded)
      calculateLatestSuggestions();
  }, [voice_calls.loaded, search, suggestionsVisible, conversation, calculateLatestSuggestions, ]);

  useEffect(() => {
    setOptions([
      ...(transfer && transferable.length ? [
        t('ONGOING_CALLS'),
        ...transferable.map(({ voice_call, voice_session, }) => <VoiceSuggestionNumber
          key={`voice_call-${voice_call.id}`}
          auth={auth}
          voice_call={voice_call}
          iso2={voiceAsterisk.iso2}
          callBtn={callBtn}
          callable={true}
          ongoing={true}
          onSelect={() => {

            const voice_asterisk = voice_asterisks.dict[transfer.voice_call.voice_asterisk_id];
            const bridge = transfer?.voice_call.voice_call_bridges.find(bridge => !bridge.leave_at);

            if (!bridge)
              return;

            if (voice_asterisk.ari_based) {
              const transfer_bridge = voice_call.voice_call_bridges.find(bridge => !bridge.leave_at);

              if (transfer_bridge)
                unholdVoiceCallBridge(transfer_bridge.organization_id, transfer_bridge.id);
              else
                return AlertError(t, { title: `Transfer bridge on voice call ${voice_call.id} not found!`, });

              attendedTransferVoiceCallBridge(bridge.organization_id, bridge.id, transfer_bridge.id);
            }
            else {
              const sip_user: Undefinable<SipUser> = sipUsersWrapper?.getSipUser(transfer.voice_call.voice_asterisk_id)?.getSipUser();
              const promises: Array<Promise<void>> = [];

              if (!sip_user)
                return;

              // attended transfer
              if (transfer.voice_session.held) {
                promises.push(sip_user.unhold(transfer.voice_session));
              }

              if (voice_session.held) {
                promises.push(sip_user.unhold(voice_session));
              }

              Promise.all(promises).then(_ => {
                transfer.voice_session.session.refer(voice_session.session);
              });
            }

          }}
        />),
      ] : []),
      ...(search && isEmergencyNumber(search, voiceAsterisk.iso2) ? [<IconText
        icon={EmergencyIcon}
        iconWrapperStyle={{ marginLeft: 9, marginBottom: 1, }}
        onClick={() => { onSelect([{
          title: search,
          voice_asterisk_id: -1,
          numbers: [search, ],
          requires_route: true,
        }, ], true); }}
        text={<div className='li-content clickable'>
          <span className='phone-number'>
            <span className='flag'>
              <CountryFlagImg t={t} country={voiceAsterisk.iso2.toLowerCase()} width={12} />
            </span>
            <span className='phone'>{search} <span style={{ opacity: .5, }}>- {t('EMERGENCY')}</span></span>
          </span>
          {callBtn}
        </div>}
      />, ] : []),
      ...(phonenumber?.isValid() ? [
        t('INPUT'),
        <div
          className='li-content clickable'
          onClick={() => { phonenumber?.isValid() && onSelect([{
            title: phonenumber?.number,
            voice_asterisk_id: -1,
            numbers: [phonenumber?.number, ],
            requires_route: true,
          }, ], true); }}
        >
          <DisplayPhoneNumber t={t} phonenumber={phonenumber} />
          {callBtn}
        </div>,
      ] : []),
      ...(conversation && voiceShortcuts.length ? [
        t('SHORTCUTS'),
        ...voiceShortcuts.map(voice_shortcut => <VoiceSuggestionVoiceShortcut
          t={t}
          key={`voice_shortcut-${voice_shortcut.id}`}
          voice_shortcut={voice_shortcut}
          onSelect={onSelect}
          callBtn={callBtn}
        />),
      ] : []),
      latestSuggestions.length ? t('RECENT') : undefined,
      ...latestSuggestions.map(voice_call => <VoiceSuggestionNumber
        key={`voice_call-${voice_call.id}`}
        auth={auth}
        voice_call={voice_call}
        iso2={voiceAsterisk.iso2}
        onSelect={onSelect}
        callBtn={callBtn}
      />),
      agentSuggestions.length ? t('AGENTS') : undefined,
      ...(agentSuggestions || []).map(({ user_status, user, }) => <VoiceSuggestionAgent
        key={user.id}
        auth={auth}
        user={user}
        user_status={user_status}
        callBtn={callBtn}
        onSelect={onSelect}
      />),
      profileQueuesSuggestions.length ? t('PROFILE_QUEUES') : undefined,
      ...profileQueuesSuggestions.map(queue => ([
        <VoiceSuggestionQueue
          key={queue.id}
          voice_asterisks={voice_asterisks}
          auth={auth}
          queue={queue}
          onSelect={onSelect}
          callBtn={callBtn}
        />,
        ...(queue.users || []).map(({ user, user_status, }) => <VoiceSuggestionAgent
          key={`${user.id}-${queue.id}`}
          auth={auth}
          user={user}
          user_status={user_status}
          onSelect={onSelect}
          callBtn={callBtn}
          queue={queue}
        />),
      ])).flat(),
      allQueuesSuggestions.length ? t('ALL_QUEUES') : undefined,
      ...allQueuesSuggestions.map(queue => ([
        <VoiceSuggestionQueue
          key={queue.id}
          voice_asterisks={voice_asterisks}
          auth={auth}
          queue={queue}
          onSelect={onSelect}
          callBtn={callBtn}
        />,
        ...(queue.users || []).map(({ user, user_status, }) => <VoiceSuggestionAgent
          key={`${user.id}-${queue.id}`}
          auth={auth}
          user={user}
          user_status={user_status}
          onSelect={onSelect}
          callBtn={callBtn}
          queue={queue}
        />),
      ])).flat(),
      ...(!conversation && voiceShortcuts.length ? [
        t('SHORTCUTS'),
        ...voiceShortcuts.map(voice_shortcut => <VoiceSuggestionVoiceShortcut
          t={t}
          key={`voice_shortcut-${voice_shortcut.id}`}
          voice_shortcut={voice_shortcut}
          onSelect={onSelect}
          callBtn={callBtn}
        />),
      ] : []),
    ].filter(Boolean) as Array<React.ReactNode>);
  }, [phonenumber, latestSuggestions, agentSuggestions, profileQueuesSuggestions, allQueuesSuggestions, transfer, onSelect, transferable, voiceAsterisk.iso2, ]);

  if (!voiceAsterisk)
    return <></>;

  const input = <input
    className='call-transfer-input'
    type='text'
    ref={inputRef}
    placeholder={t('CALL_SEARCH')}
    onKeyDown={e => {
      const _selected = selected || 0;

      if (options.length) {
        if (e.key === 'ArrowDown')
          setSelected(getNextOption());
        else if (e.key === 'ArrowUp') {
          setSelected(getPrevOption());
          setPreventDefault(e);
        }
        else if (e.key === 'Enter') {
          if (selected !== null)
            (suggestionsRef?.current?.children?.[selected]?.firstChild as HTMLElement)?.click();

          if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.blur();
          }
        }
      }
      else
        setSelected(-1);
    } }
    onKeyUp={e => {
      if (search !== inputRef.current?.value)
        setSearch(inputRef.current?.value);
    }}
    onFocus={() => {
      searchingSuggestion();
      setSearchFocused(true);
    }}
    onBlur={popover ? () => setTimeout(() => {
      setSearchFocused(false);
    }, 250) : undefined}
  />;

  const emptyResults = !options.length;
  const suggestions = <div className='suggestions'>
    <QueueRouteSelection
      t={t}
      onFocus={() => setRouteSelectFocused(true)}
      onBlur={() => setTimeout(() => setRouteSelectFocused(false), 250)}
    />
    <ul ref={suggestionsRef} className="voice-suggestions-list">
      {emptyResults && <span className='no-results'>{t('NO_RESULTS')}!</span>}
      {!emptyResults && options.map((option, index) => <li className={selected === index ? 'selected' : typeof option === 'string' ? 'group-title' : ''}>
        {option}
      </li>)}
    </ul>
  </div>;

  if (popover)
    return <Popover
      overlayClassName={'voice-endpoint-suggestions popover'}
      placement='bottom'
      visible={suggestionsVisible}
      destroyTooltipOnHide={true}
      content={suggestions}
    >
      {input}
    </Popover>;

  return <div className='voice-endpoint-suggestions standalone'>
    {input}
    {suggestions}
  </div>;
});
