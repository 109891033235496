"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionRule = exports.condition_validation = exports.RULE_CONDITION_KEY_OPTIONS = exports.WHEN_CONDITION_CHANGED_OPTIONS = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const misc_1 = require("../../misc");
const utils_1 = require("../utils");
exports.WHEN_CONDITION_CHANGED_OPTIONS = [
    'disposition_id',
    'sub_disposition_id',
    'thrd_disposition_id',
    'status',
    'queue',
    'client',
    'response_time',
    'resolution_time',
    'contact_id',
    'user_id',
    'tags',
    'flag',
    'priority',
];
const queue_options = [
    '_queue.agents_ready',
    '_queue.agents_paused',
    '_queue.agents_busy',
    '_queue.agents_total',
    '_queue.longest_waiting',
    '_queue.waiting',
];
const call_duration_options = [
    'voice_call_duration.total',
    'voice_call_duration.pre_queue_time',
    'voice_call_duration.wait_time',
    'voice_call_duration.talk_time',
    'current_voice_call_bridge_duration.total',
    'current_voice_call_bridge_duration.pre_queue_time',
    'current_voice_call_bridge_duration.wait_time',
    'current_voice_call_bridge_duration.talk_time',
    'first_voice_call_bridge_duration.total',
    'first_voice_call_bridge_duration.pre_queue_time',
    'first_voice_call_bridge_duration.wait_time',
    'first_voice_call_bridge_duration.talk_time',
    'last_voice_call_bridge_duration.total',
    'last_voice_call_bridge_duration.pre_queue_time',
    'last_voice_call_bridge_duration.wait_time',
    'last_voice_call_bridge_duration.talk_time',
];
exports.RULE_CONDITION_KEY_OPTIONS = [
    'conversation.id',
    'conversation.organization_id',
    'conversation.title',
    'conversation.queue_id',
    'conversation.client_id',
    'conversation.contact_id',
    'conversation.journey_id',
    'conversation.disposition_id',
    'conversation.sub_disposition_id',
    'conversation.thrd_disposition_id',
    'conversation.status',
    'conversation.priority',
    'conversation.user_id',
    'conversation.status_at',
    'conversation.response_time',
    'conversation.resolution_time',
    'conversation.main_channel',
    'conversation.direction',
    'conversation.tags',
    'conversation.channels',
    'conversation.created_at',
    'conversation.data',
    'conversation.last_message_at',
    'conversation.last_inbound_message_at',
    'conversation.last_outbound_message_at',
    'conversation.last_message_by',
    'voice_call.id',
    'voice_call.direction',
    'voice_call.voice_asterisk_id',
    'voice_call.queue_id',
    'voice_call.voice_route_id',
    'voice_call.contact_phone',
    'voice_call.created_at',
    'voice_call.end_time',
    'voice_call.variables',
    'voice_call_first_answered_at',
    'voice_call_bridges_count',
    'current_voice_call_bridge.id',
    'current_voice_call_bridge.direction',
    'current_voice_call_bridge.created_at',
    'current_voice_call_bridge.answer_time',
    'current_voice_call_bridge.leave_at',
    'current_voice_call_bridge.user_id',
    'current_voice_call_bridge.queue_id',
    'current_voice_call_bridge.is_external',
    'current_voice_call_bridge.is_transfer',
    'current_voice_call_bridge.is_transferred',
    'current_voice_call_bridge.exit_reason',
    'current_voice_call_bridge.is_twinned',
    'first_voice_call_bridge.id',
    'first_voice_call_bridge.direction',
    'first_voice_call_bridge.created_at',
    'first_voice_call_bridge.answer_time',
    'first_voice_call_bridge.leave_at',
    'first_voice_call_bridge.user_id',
    'first_voice_call_bridge.queue_id',
    'first_voice_call_bridge.is_external',
    'first_voice_call_bridge.is_transfer',
    'first_voice_call_bridge.is_transferred',
    'first_voice_call_bridge.exit_reason',
    'first_voice_call_bridge.is_twinned',
    'last_voice_call_bridge.id',
    'last_voice_call_bridge.direction',
    'last_voice_call_bridge.created_at',
    'last_voice_call_bridge.answer_time',
    'last_voice_call_bridge.leave_at',
    'last_voice_call_bridge.user_id',
    'last_voice_call_bridge.queue_id',
    'last_voice_call_bridge.is_external',
    'last_voice_call_bridge.is_transfer',
    'last_voice_call_bridge.is_transferred',
    'last_voice_call_bridge.exit_reason',
    'last_voice_call_bridge.is_twinned',
    'mail_message.id',
    'mail_message.mail_account_id',
    'mail_message.subject',
    'mail_message.contact_email',
    'mail_message.created_at',
    'contact.id',
    'contact.first_name',
    'contact.last_name',
    'contact.emails',
    'contact.phones',
    'contact.data',
    'form_submission.id',
    'form_submission.form_website_id',
    'form_submission.contact_email',
    'form_submission.contact_phone',
    'draft.id',
    'draft.created_at',
    'internal_note.id',
    'internal_note.created_at',
    'user.id',
    'user.username',
    'user.email',
    'user.first_name',
    'user.last_name',
    'user.full_name',
    ...queue_options,
    ...call_duration_options,
];
exports.condition_validation = {
    type: 'object',
    properties: {
        key: {
            type: 'string',
            valid: exports.RULE_CONDITION_KEY_OPTIONS,
            required: true,
        },
        data_key: {
            type: 'string',
            when: [
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'string',
                        required: true,
                        valid: [
                            'contact.data',
                            'conversation.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        required: true,
                    },
                    otherwise: {
                        type: 'any',
                        forbidden: true,
                    },
                },
            ],
        },
        variable_alias: {
            type: 'string',
            when: [
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'string',
                        required: true,
                        valid: [
                            'voice_call.variables',
                        ],
                    },
                    then: {
                        type: 'any',
                        required: true,
                    },
                    otherwise: {
                        type: 'any',
                        forbidden: true,
                    },
                },
            ],
        },
        queue_id: {
            type: 'number',
            when: [
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'string',
                        required: true,
                        valid: queue_options,
                    },
                    then: {
                        type: 'any',
                        required: true,
                    },
                    otherwise: {
                        type: 'any',
                        forbidden: true,
                    },
                },
            ],
        },
        channel: {
            type: 'any',
            required: true,
            valid: (0, misc_1.EnumValues)(enums_1.ChannelEnum),
            when: [
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: queue_options,
                    },
                    then: {
                        type: 'any',
                        required: true,
                    },
                    otherwise: {
                        type: 'any',
                        forbidden: true,
                    },
                },
            ],
        },
        comparison: {
            type: 'string',
            valid: [],
            required: true,
            when: [
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'string',
                        required: true,
                    },
                    then: {
                        type: 'any',
                        required: true,
                    },
                    otherwise: {
                        type: 'any',
                        forbidden: true,
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'conversation.title',
                            'mail_message.subject',
                            'contact.first_name',
                            'contact.last_name',
                            'user.username',
                            'user.email',
                            'user.first_name',
                            'user.last_name',
                            'user.full_name',
                            'conversation.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IsEmpty,
                            enums_1.JourneyConditionComparisonEnum.IsNotEmpty,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.emails',
                            'contact.phones',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.ArrayLength,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'voice_call.contact_phone',
                            'form_submission.contact_phone',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.EqPhoneNumber,
                            enums_1.JourneyConditionComparisonEnum.nEqPhoneNumber,
                            enums_1.JourneyConditionComparisonEnum.Contains,
                            enums_1.JourneyConditionComparisonEnum.NotContains,
                            enums_1.JourneyConditionComparisonEnum.StartsWith,
                            enums_1.JourneyConditionComparisonEnum.NotStartsWith,
                            enums_1.JourneyConditionComparisonEnum.EndsWith,
                            enums_1.JourneyConditionComparisonEnum.NotEndsWith,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'conversation.title',
                            'mail_message.subject',
                            'mail_message.contact_email',
                            'form_submission.contact_email',
                            'contact.first_name',
                            'contact.last_name',
                            'user.username',
                            'user.email',
                            'user.first_name',
                            'user.last_name',
                            'user.full_name',
                            'conversation.data',
                            'voice_call.variables',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.EqStr,
                            enums_1.JourneyConditionComparisonEnum.nEqStr,
                            enums_1.JourneyConditionComparisonEnum.Contains,
                            enums_1.JourneyConditionComparisonEnum.NotContains,
                            enums_1.JourneyConditionComparisonEnum.StartsWith,
                            enums_1.JourneyConditionComparisonEnum.NotStartsWith,
                            enums_1.JourneyConditionComparisonEnum.EndsWith,
                            enums_1.JourneyConditionComparisonEnum.NotEndsWith,
                            enums_1.JourneyConditionComparisonEnum.AnyOfStr,
                            enums_1.JourneyConditionComparisonEnum.NoneOfStr,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'conversation.queue_id',
                            'conversation.client_id',
                            'conversation.journey_id',
                            'conversation.organization_id',
                            'conversation.disposition_id',
                            'conversation.sub_disposition_id',
                            'conversation.thrd_disposition_id',
                            'conversation.priority',
                            'conversation.user_id',
                            'user.id',
                            'conversation.main_channel',
                            'conversation.direction',
                            'conversation.status',
                            'mail_message.mail_account_id',
                            'mail_account.id',
                            'voice_call.voice_route_id',
                            'voice_call.direction',
                            'voice_call.voice_asterisk_id',
                            'voice_call.queue_id',
                            'current_voice_call_bridge.id',
                            'current_voice_call_bridge.direction',
                            'current_voice_call_bridge.user_id',
                            'current_voice_call_bridge.queue_id',
                            'current_voice_call_bridge.exit_reason',
                            'first_voice_call_bridge.id',
                            'first_voice_call_bridge.direction',
                            'first_voice_call_bridge.user_id',
                            'first_voice_call_bridge.queue_id',
                            'first_voice_call_bridge.exit_reason',
                            'last_voice_call_bridge.id',
                            'last_voice_call_bridge.direction',
                            'last_voice_call_bridge.user_id',
                            'last_voice_call_bridge.queue_id',
                            'last_voice_call_bridge.exit_reason',
                            'form_website.id',
                            'form_submission.form_website_id',
                            'conversation.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.strictEq,
                            enums_1.JourneyConditionComparisonEnum.strictNotEq,
                            enums_1.JourneyConditionComparisonEnum.AnyOfNum,
                            enums_1.JourneyConditionComparisonEnum.NoneOfNum,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'conversation.last_message_by',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.strictEq,
                            enums_1.JourneyConditionComparisonEnum.strictNotEq,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'current_voice_call_bridge.is_external',
                            'current_voice_call_bridge.is_twinned',
                            'current_voice_call_bridge.is_transfer',
                            'current_voice_call_bridge.is_transferred',
                            'first_voice_call_bridge.is_external',
                            'first_voice_call_bridge.is_twinned',
                            'first_voice_call_bridge.is_transfer',
                            'first_voice_call_bridge.is_transferred',
                            'last_voice_call_bridge.is_external',
                            'last_voice_call_bridge.is_twinned',
                            'last_voice_call_bridge.is_transfer',
                            'last_voice_call_bridge.is_transferred',
                            'form_website.id',
                            'form_submission.form_website_id',
                            'conversation.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IsTruly,
                            enums_1.JourneyConditionComparisonEnum.IsFalsy,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'conversation.id',
                            'contact.id',
                            'conversation.contact_id',
                            'voice_call.id',
                            'voice_call_bridges_count',
                            ...queue_options,
                            ...call_duration_options,
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.EqNum,
                            enums_1.JourneyConditionComparisonEnum.Gt,
                            enums_1.JourneyConditionComparisonEnum.Gte,
                            enums_1.JourneyConditionComparisonEnum.Lt,
                            enums_1.JourneyConditionComparisonEnum.Lte,
                            enums_1.JourneyConditionComparisonEnum.AnyOfNum,
                            enums_1.JourneyConditionComparisonEnum.NoneOfNum,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'conversation.status_at',
                            'conversation.response_time',
                            'conversation.resolution_time',
                            'conversation.created_at',
                            'internal_note.created_at',
                            'draft.created_at',
                            'mail_message.created_at',
                            'voice_call.created_at',
                            'voice_call.end_time',
                            'voice_call_first_answered_at',
                            'current_voice_call_bridge.created_at',
                            'current_voice_call_bridge.leave_at',
                            'first_voice_call_bridge.created_at',
                            'first_voice_call_bridge.leave_at',
                            'last__voice_call_bridge.created_at',
                            'last__voice_call_bridge.leave_at',
                            'conversation.last_message_at',
                            'conversation.last_inbound_message_at',
                            'conversation.last_outbound_message_at',
                            'conversation.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.GtDateTime,
                            enums_1.JourneyConditionComparisonEnum.LtDateTime,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'mail_account.id',
                            'voice_call.id',
                            'contact.id',
                            'mail_message.id',
                            'current_voice_call_bridge.id',
                            'first_voice_call_bridge.id',
                            'last_voice_call_bridge.id',
                            'form_submission.id',
                            'draft.id',
                            'internal_note.id',
                            'conversation.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IsDefined,
                            enums_1.JourneyConditionComparisonEnum.IsUndefined,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'conversation.id',
                            'conversation.user_id',
                            'user.id',
                            'conversation.queue_id',
                            'conversation.client_id',
                            'conversation.contact_id',
                            'conversation.disposition_id',
                            'conversation.sub_disposition_id',
                            'conversation.thrd_disposition_id',
                            'contact.id',
                            'voice_call.id',
                            'voice_call.voice_route_id',
                            'voice_call_first_answered_at',
                            'voice_call.queue_id',
                            'current_voice_call_bridge.queue_id',
                            'current_voice_call_bridge.user_id',
                            'current_voice_call_bridge.answer_time',
                            'first_voice_call_bridge.queue_id',
                            'first_voice_call_bridge.user_id',
                            'first_voice_call_bridge.answer_time',
                            'last_voice_call_bridge.queue_id',
                            'last_voice_call_bridge.user_id',
                            'last_voice_call_bridge.answer_time',
                            'conversation.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IsNull,
                            enums_1.JourneyConditionComparisonEnum.IsNotNull,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'conversation.channels',
                            'conversation.tags',
                            'contact.emails',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.Includes,
                            enums_1.JourneyConditionComparisonEnum.NotIncludes,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.phones',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IncludesPhoneNumber,
                            enums_1.JourneyConditionComparisonEnum.NotIncludesPhoneNumber,
                        ],
                    },
                },
            ],
        },
        value: {
            type: 'any',
            when: [
                {
                    reference: { '$ref': 'comparison', },
                    is: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IsNull,
                            enums_1.JourneyConditionComparisonEnum.IsNotNull,
                            enums_1.JourneyConditionComparisonEnum.IsFalsy,
                            enums_1.JourneyConditionComparisonEnum.IsTruly,
                            enums_1.JourneyConditionComparisonEnum.IsEmpty,
                            enums_1.JourneyConditionComparisonEnum.IsNotEmpty,
                            enums_1.JourneyConditionComparisonEnum.IsUndefined,
                            enums_1.JourneyConditionComparisonEnum.IsDefined,
                            enums_1.JourneyConditionComparisonEnum.AnyOfNum,
                            enums_1.JourneyConditionComparisonEnum.NoneOfNum,
                            enums_1.JourneyConditionComparisonEnum.AnyOfStr,
                            enums_1.JourneyConditionComparisonEnum.NoneOfStr,
                        ],
                    },
                    then: { type: 'any', forbidden: true, },
                    otherwise: { type: 'any', required: true, },
                },
                {
                    reference: { '$ref': 'comparison', },
                    is: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.EqPhoneNumber,
                            enums_1.JourneyConditionComparisonEnum.nEqPhoneNumber,
                            enums_1.JourneyConditionComparisonEnum.IncludesPhoneNumber,
                            enums_1.JourneyConditionComparisonEnum.NotIncludesPhoneNumber,
                        ],
                    },
                    then: { type: 'string', meta: { phone: true, }, },
                },
                {
                    reference: { '$ref': 'comparison', },
                    is: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.GtDateTime,
                            enums_1.JourneyConditionComparisonEnum.LtDateTime,
                        ],
                    },
                    then: { type: 'date', },
                },
                {
                    reference: { '$ref': 'comparison', },
                    is: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.Gt,
                            enums_1.JourneyConditionComparisonEnum.Gte,
                            enums_1.JourneyConditionComparisonEnum.nEqNum,
                            enums_1.JourneyConditionComparisonEnum.EqNum,
                            enums_1.JourneyConditionComparisonEnum.Lt,
                            enums_1.JourneyConditionComparisonEnum.Lte,
                            enums_1.JourneyConditionComparisonEnum.IncludesNum,
                            enums_1.JourneyConditionComparisonEnum.ArrayLength,
                        ],
                    },
                    then: { type: 'number', },
                },
            ],
        },
        value_arr: {
            type: 'array',
            min: 1,
            items: {
                type: 'any',
                when: [
                    {
                        reference: { '$ref': '...comparison', },
                        is: {
                            type: 'any',
                            valid: [
                                enums_1.JourneyConditionComparisonEnum.AnyOfNum,
                                enums_1.JourneyConditionComparisonEnum.NoneOfNum,
                            ],
                        },
                        then: { type: 'number', },
                        otherwise: { type: 'string', },
                    },
                ],
            },
            when: [
                {
                    reference: { '$ref': 'comparison', },
                    is: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.AnyOfNum,
                            enums_1.JourneyConditionComparisonEnum.NoneOfNum,
                            enums_1.JourneyConditionComparisonEnum.AnyOfStr,
                            enums_1.JourneyConditionComparisonEnum.NoneOfStr,
                        ],
                    },
                    then: { type: 'any', required: true, },
                    otherwise: { type: 'any', forbidden: true, },
                },
            ],
        },
    },
};
exports.JourneyActionRule = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.Rule,
    initialValues: {
        conditions: [{
                type: enums_1.ConditionAndOr.AND,
                rules: [],
            },],
    },
    channels: (0, misc_1.EnumValues)(enums_1.ChannelEnum),
    settings_schema: (0, base_1.settingsValidationSchema)({
        conditions: {
            type: 'array',
            min: 1,
            items: {
                type: 'object',
                properties: {
                    type: {
                        type: 'string',
                        valid: (0, misc_1.EnumValues)(enums_1.ConditionAndOr),
                        default: enums_1.ConditionAndOr.AND,
                        required: true,
                        when: [
                            {
                                reference: { '$ref': 'rules', },
                                is: { type: 'array', },
                                then: { type: 'any', },
                                otherwise: { type: 'any', meta: { hide: true, }, },
                            },
                            {
                                reference: { '$ref': 'rules', },
                                is: { type: 'array', length: 0, },
                                then: { type: 'any', meta: { hide: true, }, },
                            },
                            {
                                reference: { '$ref': 'rules', },
                                is: { type: 'array', length: 1, },
                                then: { type: 'any', meta: { hide: true, }, },
                            },
                        ],
                    },
                    rules: {
                        type: 'array',
                        min: 1,
                        items: exports.condition_validation,
                        default: [],
                    },
                },
            },
        },
    }),
    getPorts: (node) => {
        let ports = Object.assign({}, base_1.journeyDefaultInputPort);
        const properties = node.properties;
        properties.conditions.forEach((condition, index) => {
            const id = `${constants_1.JOURNEY_ACTION_RULE_IF_OUTPUT}_${index}`;
            ports[id] = (0, utils_1.journeyNodePortAddDefault)({
                id,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: true,
                title: `RULE_${index + 1}`,
                color: null,
                index,
            });
        });
        ports[constants_1.JOURNEY_ACTION_RULE_ELSE_OUTPUT] = (0, utils_1.journeyNodePortAddDefault)({
            id: constants_1.JOURNEY_ACTION_RULE_ELSE_OUTPUT,
            type: enums_1.JourneyPortDirectionEnum.Output,
            removable: false,
            required: false,
            title: 'OTHERWISE',
            color: null,
            index: properties.conditions.length,
        });
        return ports;
    },
});
