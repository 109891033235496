import { JourneyActionEnum, Metrics, GroupMetrics } from "atlas-shared";

export const fi = {
  ...Object.fromEntries(
    [...Metrics, ...GroupMetrics]
      .flatMap((metric) => [
        metric.title ? [`METRIC_${metric.alias}`, metric.title] : false,
        metric.description ? [`METRIC_${metric.alias}_DESCRIPTION`, metric.description] : false,
      ])
      .filter(Boolean) as Array<[string, string]>
  ),
  COUNTRY_AF: "Afghanistan",
  COUNTRY_AX: "Åland Islands",
  COUNTRY_AL: "Albania",
  COUNTRY_DZ: "Algeria",
  COUNTRY_AS: "American Samoa",
  COUNTRY_AD: "Andorra",
  COUNTRY_AO: "Angola",
  COUNTRY_AI: "Anguilla",
  COUNTRY_AQ: "Antarctica",
  COUNTRY_AG: "Antigua and Barbuda",
  COUNTRY_AR: "Argentina",
  COUNTRY_AM: "Armenia",
  COUNTRY_AW: "Aruba",
  COUNTRY_AU: "Australia",
  COUNTRY_AT: "Austria",
  COUNTRY_AZ: "Azerbaijan",
  COUNTRY_BS: "Bahamas",
  COUNTRY_BH: "Bahrain",
  COUNTRY_BD: "Bangladesh",
  COUNTRY_BB: "Barbados",
  COUNTRY_BY: "Belarus",
  COUNTRY_BE: "Belgium",
  COUNTRY_BZ: "Belize",
  COUNTRY_BJ: "Benin",
  COUNTRY_BM: "Bermuda",
  COUNTRY_BT: "Bhutan",
  COUNTRY_BO: "Bolivia",
  COUNTRY_BA: "Bosnia and Herzegovina",
  COUNTRY_BW: "Botswana",
  COUNTRY_BV: "Bouvet Island",
  COUNTRY_BR: "Brazil",
  COUNTRY_IO: "British Indian Ocean Territory",
  COUNTRY_BN: "Brunei Darussalam",
  COUNTRY_BG: "Bulgaria",
  COUNTRY_BF: "Burkina Faso",
  COUNTRY_BI: "Burundi",
  COUNTRY_KH: "Cambodia",
  COUNTRY_CM: "Cameroon",
  COUNTRY_CA: "Canada",
  COUNTRY_CV: "Cape Verde",
  COUNTRY_KY: "Cayman Islands",
  COUNTRY_CF: "Central African Republic",
  COUNTRY_TD: "Chad",
  COUNTRY_CL: "Chile",
  COUNTRY_CN: "China",
  COUNTRY_CX: "Christmas Island",
  COUNTRY_CC: "Cocos (Keeling) Islands",
  COUNTRY_CO: "Colombia",
  COUNTRY_KM: "Comoros",
  COUNTRY_CG: "Congo",
  COUNTRY_CD: "Congo, The Democratic Republic of the",
  COUNTRY_CK: "Cook Islands",
  COUNTRY_CR: "Costa Rica",
  COUNTRY_CI: "Cote D'Ivoire",
  COUNTRY_HR: "Croatia",
  COUNTRY_CU: "Cuba",
  COUNTRY_CY: "Cyprus",
  COUNTRY_CZ: "Czech Republic",
  COUNTRY_DG: "Diego Garcia",
  COUNTRY_DK: "Denmark",
  COUNTRY_DJ: "Djibouti",
  COUNTRY_DM: "Dominica",
  COUNTRY_DO: "Dominican Republic",
  COUNTRY_EC: "Ecuador",
  COUNTRY_EG: "Egypt",
  COUNTRY_SV: "El Salvador",
  COUNTRY_GQ: "Equatorial Guinea",
  COUNTRY_ER: "Eritrea",
  COUNTRY_EE: "Estonia",
  COUNTRY_ET: "Ethiopia",
  COUNTRY_FK: "Falkland Islands (Malvinas)",
  COUNTRY_FO: "Faroe Islands",
  COUNTRY_FJ: "Fiji",
  COUNTRY_FI: "Finland",
  COUNTRY_FR: "France",
  COUNTRY_GF: "French Guiana",
  COUNTRY_PF: "French Polynesia",
  COUNTRY_TF: "French Southern Territories",
  COUNTRY_GA: "Gabon",
  COUNTRY_GM: "Gambia",
  COUNTRY_GE: "Georgia",
  COUNTRY_DE: "Germany",
  COUNTRY_GH: "Ghana",
  COUNTRY_GI: "Gibraltar",
  COUNTRY_GR: "Greece",
  COUNTRY_GL: "Greenland",
  COUNTRY_GD: "Grenada",
  COUNTRY_GP: "Guadeloupe",
  COUNTRY_GU: "Guam",
  COUNTRY_GT: "Guatemala",
  COUNTRY_GG: "Guernsey",
  COUNTRY_GN: "Guinea",
  COUNTRY_GW: "Guinea-Bissau",
  COUNTRY_GY: "Guyana",
  COUNTRY_HT: "Haiti",
  COUNTRY_HM: "Heard Island and Mcdonald Islands",
  COUNTRY_VA: "Holy See (Vatican City State)",
  COUNTRY_HN: "Honduras",
  COUNTRY_HK: "Hong Kong",
  COUNTRY_HU: "Hungary",
  COUNTRY_IS: "Iceland",
  COUNTRY_IN: "India",
  COUNTRY_ID: "Indonesia",
  COUNTRY_IR: "Iran, Islamic Republic Of",
  COUNTRY_IQ: "Iraq",
  COUNTRY_IE: "Ireland",
  COUNTRY_IM: "Isle of Man",
  COUNTRY_IL: "Israel",
  COUNTRY_IT: "Italy",
  COUNTRY_JM: "Jamaica",
  COUNTRY_JP: "Japan",
  COUNTRY_JE: "Jersey",
  COUNTRY_JO: "Jordan",
  COUNTRY_KZ: "Kazakhstan",
  COUNTRY_KE: "Kenya",
  COUNTRY_KI: "Kiribati",
  COUNTRY_KP: "Korea, Democratic People'S Republic of",
  COUNTRY_KR: "Korea, Republic of",
  COUNTRY_XK: "Kosovo",
  COUNTRY_KW: "Kuwait",
  COUNTRY_KG: "Kyrgyzstan",
  COUNTRY_LA: "Lao People'S Democratic Republic",
  COUNTRY_LV: "Latvia",
  COUNTRY_LB: "Lebanon",
  COUNTRY_LS: "Lesotho",
  COUNTRY_LR: "Liberia",
  COUNTRY_LY: "Libyan Arab Jamahiriya",
  COUNTRY_LI: "Liechtenstein",
  COUNTRY_LT: "Lithuania",
  COUNTRY_LU: "Luxembourg",
  COUNTRY_LX: "Luxembourg (Special)",
  COUNTRY_MO: "Macao",
  COUNTRY_MK: "Macedonia, The Former Yugoslav Republic of",
  COUNTRY_MG: "Madagascar",
  COUNTRY_MW: "Malawi",
  COUNTRY_MY: "Malaysia",
  COUNTRY_MV: "Maldives",
  COUNTRY_ML: "Mali",
  COUNTRY_MT: "Malta",
  COUNTRY_MH: "Marshall Islands",
  COUNTRY_MQ: "Martinique",
  COUNTRY_MR: "Mauritania",
  COUNTRY_MU: "Mauritius",
  COUNTRY_YT: "Mayotte",
  COUNTRY_MX: "Mexico",
  COUNTRY_FM: "Micronesia, Federated States of",
  COUNTRY_MD: "Moldova, Republic of",
  COUNTRY_ME: "Montenegro",
  COUNTRY_MF: "Saint Martin",
  COUNTRY_MC: "Monaco",
  COUNTRY_MN: "Mongolia",
  COUNTRY_MS: "Montserrat",
  COUNTRY_MA: "Morocco",
  COUNTRY_MZ: "Mozambique",
  COUNTRY_MM: "Myanmar",
  COUNTRY_NA: "Namibia",
  COUNTRY_NR: "Nauru",
  COUNTRY_NP: "Nepal",
  COUNTRY_NL: "Netherlands",
  COUNTRY_AN: "Netherlands Antilles",
  COUNTRY_NC: "New Caledonia",
  COUNTRY_NZ: "New Zealand",
  COUNTRY_NI: "Nicaragua",
  COUNTRY_NE: "Niger",
  COUNTRY_NG: "Nigeria",
  COUNTRY_NU: "Niue",
  COUNTRY_NF: "Norfolk Island",
  COUNTRY_MP: "Northern Mariana Islands",
  COUNTRY_NO: "Norway",
  COUNTRY_OM: "Oman",
  COUNTRY_PK: "Pakistan",
  COUNTRY_PW: "Palau",
  COUNTRY_PS: "Palestinian Territory, Occupied",
  COUNTRY_PA: "Panama",
  COUNTRY_PG: "Papua New Guinea",
  COUNTRY_PY: "Paraguay",
  COUNTRY_PE: "Peru",
  COUNTRY_PH: "Philippines",
  COUNTRY_PN: "Pitcairn",
  COUNTRY_PL: "Poland",
  COUNTRY_PT: "Portugal",
  COUNTRY_PR: "Puerto Rico",
  COUNTRY_QA: "Qatar",
  COUNTRY_RE: "Reunion",
  COUNTRY_RO: "Romania",
  COUNTRY_RS: "Serbia",
  COUNTRY_RU: "Russian Federation",
  COUNTRY_RW: "Rwanda",
  COUNTRY_SH: "Saint Helena",
  COUNTRY_KN: "Saint Kitts and Nevis",
  COUNTRY_LC: "Saint Lucia",
  COUNTRY_PM: "Saint Pierre and Miquelon",
  COUNTRY_VC: "Saint Vincent and the Grenadines",
  COUNTRY_WS: "Samoa",
  COUNTRY_SM: "San Marino",
  COUNTRY_ST: "Sao Tome and Principe",
  COUNTRY_SA: "Saudi Arabia",
  COUNTRY_SN: "Senegal",
  COUNTRY_CS: "Serbia and Montenegro",
  COUNTRY_SC: "Seychelles",
  COUNTRY_SL: "Sierra Leone",
  COUNTRY_SG: "Singapore",
  COUNTRY_SK: "Slovakia",
  COUNTRY_SI: "Slovenia",
  COUNTRY_SB: "Solomon Islands",
  COUNTRY_SO: "Somalia",
  COUNTRY_SS: "South Sudan",
  COUNTRY_SX: "Sint Maarten",
  COUNTRY_ZA: "South Africa",
  COUNTRY_GS: "South Georgia and the South Sandwich Islands",
  COUNTRY_ES: "Spain",
  COUNTRY_LK: "Sri Lanka",
  COUNTRY_SD: "Sudan",
  COUNTRY_SR: "Suriname",
  COUNTRY_SJ: "Svalbard and Jan Mayen",
  COUNTRY_SZ: "Swaziland",
  COUNTRY_SE: "Sweden",
  COUNTRY_CH: "Switzerland",
  COUNTRY_SY: "Syrian Arab Republic",
  COUNTRY_TA: "Tristan da Cunha",
  COUNTRY_TW: "Taiwan, Province of China",
  COUNTRY_TJ: "Tajikistan",
  COUNTRY_TZ: "Tanzania, United Republic of",
  COUNTRY_TH: "Thailand",
  COUNTRY_TL: "Timor-Leste",
  COUNTRY_TG: "Togo",
  COUNTRY_TK: "Tokelau",
  COUNTRY_TO: "Tonga",
  COUNTRY_TP: "East Timor",
  COUNTRY_TT: "Trinidad and Tobago",
  COUNTRY_TN: "Tunisia",
  COUNTRY_TR: "Turkey",
  COUNTRY_TM: "Turkmenistan",
  COUNTRY_TC: "Turks and Caicos Islands",
  COUNTRY_TV: "Tuvalu",
  COUNTRY_UG: "Uganda",
  COUNTRY_UA: "Ukraine",
  COUNTRY_AE: "United Arab Emirates",
  COUNTRY_GB: "United Kingdom",
  COUNTRY_US: "United States",
  COUNTRY_UM: "United States Minor Outlying Islands",
  COUNTRY_UY: "Uruguay",
  COUNTRY_UZ: "Uzbekistan",
  COUNTRY_VU: "Vanuatu",
  COUNTRY_VE: "Venezuela",
  COUNTRY_VN: "Viet Nam",
  COUNTRY_VG: "Virgin Islands, British",
  COUNTRY_VI: "Virgin Islands, U.S.",
  COUNTRY_WF: "Wallis and Futuna",
  COUNTRY_EH: "Western Sahara",
  COUNTRY_YE: "Yemen",
  COUNTRY_ZM: "Zambia",
  COUNTRY_ZW: "Zimbabwe",
  COUNTRY_AC: "Ascension Island",
  COUNTRY_BL: "Saint Martin country",
  COUNTRY_BQ: "Netherlands Antilles",
  COUNTRY_CW: "Netherlands Antilles",

  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceInbound}`]: "Saapuva puhelu luotiin",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartVoiceOutbound}`]:
    "Lähtevä puhelu päättyi agentin toimesta",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceAnswer}`]: "Vastaa puheluun",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceExternalDial}`]: "Soita ulkoiseen numeroon",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceGetDigits}`]: "Hae numerot soittajalta",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceHangup}`]: "Lopeta puhelu",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceInternalDial}`]:
    "Sisäinen puhelu agenteille tai jonoihin",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceMenu}`]: "Äänivalikko vaihtoehdoilla",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoicePlayback}`]: "Toista ääni yhteydelle",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayAlpha}`]: "Lue teksti yhteydelle",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayDigits}`]: "Lue numerot yhteydelle (yksi kerrallaan)",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVariableSet}`]: "Aseta äänimuuttuja",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayNumber}`]: "Lue numero yhteydelle",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSayPhonetic}`]: "Lue kirjaimet yhteydelle",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStartRecording}`]: "Aloita puhelun nauhoitus",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceStopRecording}`]: "Pysäytä tietty puhelun nauhoitus",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailInbound}`]: "Saapuva sähköpostikeskustelu luotiin",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartMailOutbound}`]: "Lähtevä sähköpostikeskustelu luotiin",
  [`NODE_SUBTITLE_${JourneyActionEnum.StartForm}`]: "Keskustelu luotiin lomakkeen lähetyksestä",
  [`NODE_SUBTITLE_${JourneyActionEnum.OperationalHours}`]:
    "Aukioloaikojen sisällä tai ulkopuolella",
  [`NODE_SUBTITLE_${JourneyActionEnum.MailAutoResponse}`]: "Automaattivastaus sähköposti",
  [`NODE_SUBTITLE_${JourneyActionEnum.Queue}`]: "Lisää jonoon",
  [`NODE_SUBTITLE_${JourneyActionEnum.Client}`]: "Lisää asiakas keskusteluun",
  [`NODE_SUBTITLE_${JourneyActionEnum.UnAssign}`]: "Poista jonon ja/tai käyttäjän määritys",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceQueue}`]: "Lisää jonoon äänenkäsittelijällä",
  [`NODE_SUBTITLE_${JourneyActionEnum.Agent}`]: "Määritä suoraan agentille",
  [`NODE_SUBTITLE_${JourneyActionEnum.Timeout}`]: JourneyActionEnum.Timeout + " oletusteksti",
  [`NODE_SUBTITLE_${JourneyActionEnum.RestRequest}`]: "Tee REST-pyyntö",
  [`NODE_SUBTITLE_${JourneyActionEnum.FetchContactData}`]: "Hae yhteystiedot REST:n kautta",
  [`NODE_SUBTITLE_${JourneyActionEnum.EditConversation}`]: "Muokkaa keskustelun ominaisuuksia",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseConversation}`]: "Päätä ja sulje keskustelu",
  [`NODE_SUBTITLE_${JourneyActionEnum.AddInternalNote}`]:
    "Lisää sisäinen muistiinpano keskusteluun",
  [`NODE_SUBTITLE_${JourneyActionEnum.CloseTabs}`]: "Sulje välilehdet automaattisesti matkasta",
  [`NODE_SUBTITLE_${JourneyActionEnum.SendMail}`]: "Lähetä sähköposti",
  [`NODE_SUBTITLE_${JourneyActionEnum.ForwardMail}`]: "Välitä sähköposti",
  [`NODE_SUBTITLE_${JourneyActionEnum.ReplyLatestInboundMessage}`]:
    "Vastaa viimeisimpään saapuneeseen sähköpostiin tai lomakkeen lähetykseen",
  [`NODE_SUBTITLE_${JourneyActionEnum.Journey}`]: "Alamatka",
  [`NODE_SUBTITLE_${JourneyActionEnum.Rule}`]: "Määritä ehtosäännöt",
  [`NODE_SUBTITLE_${JourneyActionEnum.RuleContact}`]: "Määritä yhteystietojen ehtosäännöt",
  [`NODE_SUBTITLE_${JourneyActionEnum.NoOp}`]: "Ei toimintoa",
  [`NODE_SUBTITLE_${JourneyActionEnum.When}`]: "Laukaistaan kun",
  [`NODE_SUBTITLE_${JourneyActionEnum.SetField}`]: "Aseta keskustelukentän arvo",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceSetLanguage}`]: "Aseta puhelun kieli",
  [`NODE_SUBTITLE_${JourneyActionEnum.VoiceVoicemail}`]: "Tallenna ääniviesti",
  [`NODE_SUBTITLE_${JourneyActionEnum.OpenBrowserTab}`]: "Avaa selainvälilehti",

  NODE_TITLE_DEFAULT: "Solmun oletusotsikko",
  NODE_DESCRIPTION_DEFAULT: "Solmun oletuskuvaus",
  LOADING: "Ladataan...",
  CUSTOMER_JOURNEY: "Asiakasmatka",
  CONVERSATION: "Keskustelu",
  CONVERSATIONS: "Keskustelut",
  CUSTOMER: "Asiakas",
  CUSTOMERS: "Asiakkaat",
  STATISTICS: "Tilastot",
  REPLY_IN: "Vastaa",
  REPLY: "Vastaa",
  AGO: "sitten",
  SHOWING: "Näytetään",
  STATS: "Tilastot",
  CLOSE: "Sulje",
  CLOSED: "Suljettu",
  OPEN: "Avaa",
  DRAFT_REPLY_MESSAGE_CONFIRM: "Haluatko tallentaa muutoksesi?",
  TODAY: "Tänään",
  YES: "Kyllä",
  NO: "Ei",
  CONFIRM: "Vahvista",
  NEW_MESSAGE: "Uusi viesti",
  TO: "Vastaanottaja",
  DRAFT: "Luonnos",
  SEND_REPLY: "Lähetä vastaus",
  SAVE: "Tallenna",
  TYPE: "Tyyppi",
  ALL: "Kaikki",
  TICKETS: "Tiketit",
  CONTACTS: "Yhteystiedot",
  SOLUTIONS: "Ratkaisut",
  FORUMS: "Foorumit",
  TEAM_NAME: "Tiimin nimi",
  ANOTHER_TEAM: "Toinen tiimi",
  THIRD_TEAM_NAME: "Kolmas tiimin nimi",
  OTHER_TEAM_NAME: "Muu tiimin nimi",
  FAVORITES: "Suosikit",
  CHAT: "Chat",
  CALL: "Puhelu",
  RETURN: "Palaa",
  SEND: "Lähetä",
  RECENTLY_SEARCHED: "Äskettäin haetut",
  RECENTLY_VIEWED: "Äskettäin katsotut",
  CLEAR: "Tyhjennä",
  ASSIGN: "Määritä",
  CHOOSE: "valitse",
  AGENT: "Agentti",
  ADMIN: "Ylläpitäjä",
  SUPERADMIN: "Pääylläpitäjä",
  QUEUES: "Jonot",
  QUEUE: "Jono",
  DONE: "Valmis",
  IN: "sisällä",
  SEARCH: "Haku",
  AND: "Ja",
  PREVIOUS_ACTIVITY: "Aiempi toiminta",
  VIEW: "Näytä",
  BY: "tekijä",
  SUPPORT: "Tuki",
  GENERAL: "Yleinen",
  WRITE: "Kirjoita",
  HERE: "tähän",
  ADD: "Lisää",
  NEW: "Uusi",
  AGENTS: "Agentit",
  EDIT: "Muokkaa",
  TOOLS: "Työkalut",
  EMAIL: "Sähköposti",
  CANCEL: "Peruuta",
  UNAUTHORIZED: "Käyttäjätunnus tai salasana on väärä",
  OR: "Tai",
  DISPOSE: "Päätä",
  CHOOSE_REASON_FOR_DISPOSAL: "Valitse päättämisen syy",
  WRITE_A_COMMENT: "Kirjoita kommentti",
  USERNAME: "Käyttäjätunnus",
  DROP_HERE: "Pudota tähän",
  PASSWORD: "Salasana",
  FORGOT_YOUR_PASSWORD: "Unohditko salasanasi",
  RESET_PASSWORD: "Nollaa salasana",
  REMEMBER_ME: "Muista minut",
  SIGN_IN: "Kirjaudu sisään",
  SIGN_OUT: "Kirjaudu ulos",
  DISPLAY_DELETED: "Näytä poistetut",
  GLOBAL: "Globaali",
  WORK_TIME: "Työaika",
  INVOICEABLE: "Laskutettava",
  TITLE: "Otsikko",
  ORGANIZATION_NOT_FOUND: 'Organisaatiota tunnuksella "{{organization_id}}" ei löytynyt!',
  BETWEEN_FROM_AND_TO: "{{from}} ja {{to}} välillä",
  FROM_FROM_TO_TO: "{{from}} - {{to}}",
  FROM_THROUGH_TO: "{{from}} - {{to}}",
  FROM_THROUGH_TO_OF_MONTH: "{{from}} - {{to}} kuukaudessa",
  ON_FROM: "{{from}}",
  ON_FROM_OF_MONTH: "{{from}} kuukaudessa",
  EVERY_SPECIFIC_DAY: "Joka {{from}}",
  ALL_DAY: "Koko päivä",
  EVERY_DAY: "Joka päivä",

  ROLE: "Rooli",
  FIRST_NAME: "Etunimi",
  LAST_NAME: "Sukunimi",
  AVATAR: "Profiilikuva",

  ORGANIZATIONS: "Organisaatiot",
  ORGANIZATION: "Organisaatio",
  USER: "Käyttäjä",
  USERS: "Käyttäjät",
  ADMINPROFILES: "Ylläpitäjäprofiilit",
  ADMINPROFILE: "Ylläpitäjäprofiili",
  SUPERADMIN_PROFILES: "Pääylläpitäjäprofiilit",
  SUPERADMINPROFILES: "Pääylläpitäjäprofiilit",
  SUPERADMINPROFILE: "Pääylläpitäjäprofiili",
  SUPERADMIN_PROFILE: "Pääylläpitäjäprofiili",
  AGENTPROFILES: "Agenttiprofiilit",
  AGENTPROFILE: "Agenttiprofiili",
  TEAMS: "Tiimit",
  MAIL_ACCOUNTS: "Sähköpostitilit",
  MAIL_ACCOUNT: "Sähköpostitili",
  LISTS: "Listat",
  LIST: "Lista",
  JOURNEYS: "Matkat",
  JOURNEY: "Matka",
  PAUSES: "Tauot",
  PAUSE: "Tauko",
  DISPOSITIONS: "Päätökset",
  DISPOSITION: "Päätös",
  FIELDS: "Kentät",
  FIELD: "Kenttä",
  FORM_ERROR_STRING__EMPTY: "Ei saa olla tyhjä",
  FORM_ERROR_ARRAY__MIN: "Vähintään {{min}} vaihtoehtoa vaaditaan",
  FORM_ERROR_ARRAY__MAX: "Enintään {{max}} vaihtoehtoa sallitaan",
  FORM_ERROR_ARRAY__LENGTH: "{{lngth}} vaihtoehtoa vaaditaan",
  FORM_ERROR_ANY__REQUIRED: "Pakollinen",
  FORM_ERROR_NUMBER__BASE: "Kelvollinen numero vaaditaan",
  FORM_ERROR_STRING__BASE: "Kelvollinen teksti vaaditaan",
  FORM_ERROR_VALID_OPTION: "Valitse vaihtoehto",
  FORM_ERROR_ANY__ONLY: "Valitse kelvollinen vaihtoehto",
  FORM_ERROR_STRING__URI: "Virheellinen uri",
  FORM_ERROR_REF_NOT_FOUND: "Kelvollinen vaihtoehto on valittava",
  FORM_ERROR_INVALID_PHONE: "Virheellinen puhelinnumero",
  FORM_ERROR_NUMBER__MIN: "Numeron on oltava suurempi tai yhtä suuri kuin {{min}}",
  FORM_ERROR_NUMBER__MAX: "Numeron on oltava pienempi tai yhtä suuri kuin {{max}}",
  FORM_ERROR_STRING__MIN: "Vähintään {{min}} merkkiä pitkä",
  FORM_ERROR_STRING__MAX: "Enintään {{max}} merkkiä pitkä",
  FORM_ERROR_STRING__LENGTH: "Täsmälleen {{lngth}} merkkiä pitkä",
  FORM_ERROR_STRING__EMAIL: "Virheellinen sähköpostiosoite",
  FORM_ERROR_STRING__ALPHANUM: "Vain aakkosnumeerisia arvoja!",
  FORM_ERROR_USERNAME_INVALID_CHARS:
    "Saa sisältää vain pieniä kirjaimia, numeroita ja erikoismerkkejä . (piste) ja - (viiva)",
  FORM_ERROR_STRING__PATTERN__BASE: "Merkkijono ei vastaa mallia {{pattern}}",
  FORM_ERROR_DATE__BASE: "Valitse kelvollinen päivämäärä",
  FORM_ERROR_STRING__IPVERSION: "Virheellinen IP-osoite",

  GLOBAL_SEARCH_PLACEHOLDER: "Etsi keskusteluja & yhteystietoja",
  ADD_ITEM: "Lisää kohde",
  MAIL_CAPACITY: "Sähköpostikapasiteetti",
  __ORGANIZATIONS: "Organisaatiot",
  __ORGANIZATIONS__ORGANIZATION_ID: "Organisaatio",
  __ORGANIZATIONS__ROLE: "Rooli",
  __ORGANIZATIONS__ADMINPROFILE_ID: "Ylläpitäjäprofiili",
  __ORGANIZATIONS__SUPERADMIN_PROFILE_ID: "Pääylläpitäjäprofiili",
  SUBMIT: "Lähetä",
  SIGN_IN_WITH_GOOGLE: "Kirjaudu Google-tilillä",
  SIGN_IN_WITH_MICROSOFT: "Kirjaudu Microsoft-tilillä",
  OPEN_AUTHENTICATOR_AND_SCAN: "Avaa todennussovellus ja skannaa QR-koodi",
  OPEN_AUTHENTICATOR: "Avaa todennussovellus ja syötä koodi",
  ENTER_6_DIGIT_CODE: "Syötä 6-numeroinen koodi",
  FILL_USERNAME_TO_RESET_PASSWORD: "Täytä käyttäjätunnus palauttaaksesi salasanan",
  SOMETHING_WENT_WRING: "Jokin meni pieleen, yritä myöhemmin uudelleen!",

  ENTRY: "Syöte",
  ASSIGNMENT: "Määritys",
  MAIL: "Sähköposti",
  StartMailInbound: "Aloita sähköposti (Saapuva)",
  StartMailOutbound: "Aloita sähköposti (Lähtevä)",
  StartVoiceInbound: "Aloita puhelu (Saapuva)",
  StartVoiceOutbound: "Aloita puhelu (Lähtevä)",
  VoiceAnswer: "Vastaa",
  VoiceExternalDial: "Ulkoinen puhelu",
  VoiceGetDigits: "Hae numerot",
  VoiceQueue: "Puhelujono",
  VoiceVariableSet: "Aseta muuttuja",
  VoiceHangup: "Lopeta puhelu",
  VoiceInternalDial: "Sisäinen puhelu",
  VoiceMenu: "Puheluvalikko",
  VoicePlayback: "Toisto",
  VoiceSayAlpha: "Sano aakkosellisesti",
  VoiceSayNumber: "Sano numero",
  VoiceSayPhonetic: "Sano foneettisesti",
  VoiceSayDigits: "Sano numerot",
  VoiceStartRecording: "Aloita nauhoitus",
  VoiceStopRecording: "Lopeta nauhoitus",
  VoiceVoicemail: "Ääniviesti",
  StartForm: "Aloita lomake",
  Queue: "Jono",
  EditConversation: "Muokkaa keskustelua",
  CloseConversation: "Sulje keskustelu",
  AddInternalNote: "Lisää sisäinen muistiinpano",
  CloseTabs: "Sulje välilehdet",
  SendMail: "Lähetä sähköposti",
  ForwardMail: "Välitä sähköposti",
  ReplyLatestInboundMessage: "Vastaa viimeisimpään saapuneeseen viestiin",
  MailAutoResponse: "Automaattinen sähköpostivastaus",
  RestRequest: "REST-pyyntö",
  FetchContactData: "Hae yhteystiedot",
  SetField: "Aseta kenttätieto",
  VoiceSetLanguage: "Aseta kieli",
  Rule: "Sääntö",
  RuleContact: "Sääntö (yhteystieto)",
  NoOp: "Ei toimintoa",
  OperationalHours: "Aukioloajat",
  Journey: "Journey",
  MY_FILTERS: "Omat suodattimet",
  CREATE_NEW_FILTER: "Luo uusi suodatin",
  NEW_FILTER: "Uusi suodatin",
  DEFAULT_FILTER: "Oletussuodatin",
  STATUSES: "Tilat",
  CHANNELS: "Kanavat",
  PRIORITIES: "Prioriteetit",
  TAGS: "Tunnisteet",
  LAST_MESSAGE_AT: "Viimeisin viesti",
  LAST_INBOUND_MESSAGE_AT: "Viimeisin saapunut viesti",
  LAST_OUTBOUND_MESSAGE_AT: "Viimeisin lähetetty viesti",
  CREATED_AT: "Luotu",
  CONTACT: "Yhteystieto",
  __CONTACT_FIRST_NAME: "Etunimi",
  __CONTACT_LAST_NAME: "Sukunimi",
  __CONTACT_PHONES: "Puhelinnumerot",
  __CONTACT_EMAILS: "Sähköpostiosoitteet",
  SETTINGS: "Asetukset",
  PROFILES: "Profiilit",
  NOTIFICATIONS: "Ilmoitukset",
  NewAssignment: "Uusi määritys",
  NewResponseOnMyConversation: "Uusi vastaus keskusteluissani",
  ConversationMessagesImTaggedIn: "Keskusteluviestit, joissa minut on merkitty",
  StatusUpdateOnMyConversation: "Tilapäivitys keskustelussani",
  ConversationAssignedToMe: "Minulle määrätty keskustelu",
  ConversationAssignedToMyQueues: "Jonoihini määrätty keskustelu",
  ReminderOnFirstResponse: "Muistutus ensimmäisestä vastauksesta",
  ReminderOnResolutionTime: "Muistutus ratkaisuajasta",
  ReminderOnNextResponse: "Muistutus seuraavasta vastauksesta",
  DELETE_DRAFT: "Poista luonnos",
  PERMANENTLY_DELETE_DRAFT: "Poista luonnos pysyvästi?",
  ADD_PORT: "Lisää portti",
  ADD_RULE: "Lisää sääntö",
  ADD_PROPERTY: "Lisää ominaisuus",
  NOT_EQUAL_PHONE_NUMBER: "Ei sama puhelinnumero",
  EQUAL_PHONE_NUMBER: "Sama puhelinnumero",
  INCLUDES_PHONE_NUMBER: "Sisältää puhelinnumeron",
  NOT_INCLUDES_PHONE_NUMBER: "Ei sisällä puhelinnumeroa",
  ARRAY_LENGTH: "Taulukon pituus",
  STRICT_EQ: "Yhtä kuin",
  NOT_STRICT_EQ: "Ei yhtä kuin",
  IS_EMPTY: "On tyhjä",
  IS_NOT_EMPTY: "Ei ole tyhjä",
  CONTAINS: "Sisältää",
  NOT_CONTAINS: "Ei sisällä",
  CHANGED: "Muuttunut",
  NOT_EQUAL_NUM: "Ei yhtä kuin (numero)",
  NOT_EQUAL_STR: "Ei yhtä kuin (teksti)",
  EQUAL_NUM: "Yhtä kuin (numero)",
  EQUAL_STR: "Yhtä kuin (teksti)",
  STARTS_WITH: "Alkaa",
  NOT_STARTS_WITH: "Ei ala",
  ANY_OF_NUM: "Jokin näistä",
  NONE_OF_NUM: "Ei mikään näistä",
  ANY_OF_STR: "Jokin näistä",
  NONE_OF_STR: "Ei mikään näistä",
  ENDS_WITH: "Päättyy",
  NOT_ENDS_WITH: "Ei pääty",
  GT: "Suurempi kuin",
  GTE: "Suurempi tai yhtä suuri kuin",
  LT: "Pienempi kuin",
  LTE: "Pienempi tai yhtä suuri kuin",
  IS_NOT_NULL: "Ei ole null",
  IS_NULL: "On null",
  IS_FALSY: "On epätosi",
  IS_FALSE: "On false",
  IS_TRULY: "On tosi",
  IS_TRUE: "On true",
  IS_UNDEFINED: "On määrittelemätön",
  IS_DEFINED: "On määritelty",
  INCLUDES: "Sisältää",
  INCLUDES_NUM: "Sisältää numeron",
  INCLUDES_STR: "Sisältää tekstin",
  NOT_INCLUDES: "Ei sisällä",
  NOT_INCLUDES_NUM: "Ei sisällä numeroa",
  NOT_INCLUDES_STR: "Ei sisällä tekstiä",
  GT_DATETIME: "Suurempi kuin",
  LT_DATETIME: "Pienempi kuin",
  MAIL_MESSAGE: "Sähköpostiviesti",
  "DRAFT.ID": "Luonnos-id",
  "INTERNAL_NOTE.ID": "Sisäinen muistiinpano-id",
  "CONVERSATION.ID": "Keskustelu-id",
  "CONVERSATION.ORGANIZATION_ID": "Organisaatio",
  "CONVERSATION.TITLE": "Keskustelun otsikko",
  "CONVERSATION.QUEUE_ID": "Keskustelujono",
  "CONVERSATION_QUEUE.TITLE": "Keskustelujonon otsikko",
  "CONVERSATION.LAST_INBOUND_MESSAGE_AT": "Viimeisin saapunut viesti",
  "CONVERSATION.LAST_OUTBOUND_MESSAGE_AT": "Viimeisin lähetetty viesti",
  "CONVERSATION.LAST_MESSAGE_AT": "Viimeisin viesti",
  "CONVERSATION.LAST_MESSAGE_BY": "Viimeisimmän viestin lähettäjä",
  "CONVERSATION.CLIENT_ID": "Keskusteluasiakas",
  "CONVERSATION_CLIENT.TITLE": "Keskusteluasiakkaan otsikko",
  "CONVERSATION.CONTACT_ID": "Keskustelun yhteystieto-id",
  "CONVERSATION.JOURNEY_ID": "Matka",
  "CONVERSATION.DISPOSITION_ID": "Keskustelun luokittelu",
  "CONVERSATION_DISPOSITION.TITLE": "Keskustelun luokittelun otsikko",
  "CONVERSATION.SUB_DISPOSITION_ID": "Keskustelun alaluokittelu",
  "CONVERSATION_SUB_DISPOSITION.TITLE": "Keskustelun alaluokittelun otsikko",
  "CONVERSATION.THRD_DISPOSITION_ID": "Keskustelun kolmas luokittelu",
  "CONVERSATION_THRD_DISPOSITION.TITLE": "Keskustelun kolmannen luokittelun otsikko",
  "CONVERSATION.STATUS": "Keskustelun tila",
  "CONVERSATION.CREATED_AT": "Keskustelu luotu",
  "CONVERSATION.PRIORITY": "Keskustelun prioriteetti",
  "CONVERSATION.USER_ID": "Keskustelun agentti-id",
  "USER.ID": "Agentti",
  "CONVERSATION.STATUS_AT": "Keskustelun tila muutettu",
  "CONVERSATION.RESPONSE_TIME": "Keskustelun vastausaika",
  "CONVERSATION.RESOLUTION_TIME": "Keskustelun ratkaisuaika",
  "CONVERSATION.MAIN_CHANNEL": "Keskustelun pääkanava",
  "CONVERSATION.DIRECTION": "Keskustelun aloitussuunta",
  "CONVERSATION.TAGS": "Keskustelun tunnisteet",
  "CONVERSATION.CHANNELS": "Keskustelun kanavat",
  "CONVERSATION.DATA": "Keskustelun mukautetut kentät",
  "MAIL_MESSAGE.ID": "Sähköpostiviesti-id",
  "MAIL_MESSAGE.TITLE": "Sähköpostiviestin otsikko",
  "MAIL_MESSAGE.SUBJECT": "Sähköpostin aihe",
  "MAIL_MESSAGE.MAIL_ACCOUNT_ID": "Sähköpostitili",
  "MAIL_MESSAGE.CONTACT_EMAIL": "Yhteystiedon sähköposti",
  "MAIL_MESSAGE.CREATED_AT": "Sähköpostiviesti luotu",
  "CONTACT.ID": "Yhteystieto-id",
  "CONTACT.EMAILS": "Yhteystiedon sähköpostit",
  "CONTACT.PHONES": "Yhteystiedon puhelinnumerot",
  "CONTACT.FIRST_NAME": "Yhteystiedon etunimi",
  "CONTACT.LAST_NAME": "Yhteystiedon sukunimi",
  "CONTACT.DATA": "Yhteystiedon mukautetut kentät",
  FORM_SUBMISSION: "Lomakelähetys",
  "FORM_SUBMISSION.ID": "Lomakelähetys-id",
  "FORM_SUBMISSION.FORM_WEBSITE_ID": "Lomakeverkkosivusto",
  "FORM_SUBMISSION.CONTACT_EMAIL": "Lomakkeen yhteystiedon sähköposti",
  "FORM_SUBMISSION.CONTACT_PHONE": "Lomakkeen yhteystiedon puhelin",
  FORM_WEBSITE: "Lomakeverkkosivusto",
  "FORM_WEBSITE.ID": "Lomakeverkkosivusto-id",
  "MAIL_ACCOUNT.ID": "Sähköpostitili-id",
  "DRAFT.CREATED_AT": "Luonnos luotu",
  "INTERNAL_NOTE.CREATED_AT": "Sisäinen muistiinpano luotu",
  "VOICE_CALL.ID": "Puhelu-id",
  "VOICE_CALL.VOICE_ASTERISK_ID": "Puhelu-asterisk",
  "VOICE_CALL.VOICE_ROUTE_ID": "Puhelureitti",
  "VOICE_CALL.QUEUE_ID": "Puhelujono",
  VOICE_CALL_BRIDGES_COUNT: "Puhelusiltojen määrä",
  "VOICE_CALL.CONTACT_PHONE": "Puhelun yhteystiedon puhelin",
  "VOICE_CALL.CREATED_AT": "Puhelu luotu",
  "VOICE_CALL.DIRECTION": "Puhelun suunta",
  "VOICE_CALL.END_TIME": "Puhelu päättynyt",
  VOICE_CALL_FIRST_ANSWERED_AT: "Puheluun vastattu ensimmäisen kerran",
  "VOICE_CALL.VARIABLES": "Puhelumuuttujat",

  "VOICE_CALL_DURATION.TOTAL": "Kokonaispuheluaika (sekuntia)",
  "VOICE_CALL_DURATION.PRE_QUEUE_TIME": "Aika ennen jonoa (sekuntia)",
  "VOICE_CALL_DURATION.WAIT_TIME": "Odotusaika (sekuntia)",
  "VOICE_CALL_DURATION.TALK_TIME": "Puheaika (sekuntia)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Nykyisen puhelusillan kokonaisaika (sekuntia)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Nykyisen puhelusillan aika ennen jonoa (sekuntia)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Nykyisen puhelusillan odotusaika (sekuntia)",
  "CURRENT_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Nykyisen puhelusillan puheaika (sekuntia)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Ensimmäisen puhelusillan kokonaisaika (sekuntia)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Ensimmäisen puhelusillan aika ennen jonoa (sekuntia)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Ensimmäisen puhelusillan odotusaika (sekuntia)",
  "FIRST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Ensimmäisen puhelusillan puheaika (sekuntia)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TOTAL": "Viimeisen puhelusillan kokonaisaika (sekuntia)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.PRE_QUEUE_TIME":
    "Viimeisen puhelusillan aika ennen jonoa (sekuntia)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.WAIT_TIME": "Viimeisen puhelusillan odotusaika (sekuntia)",
  "LAST_VOICE_CALL_BRIDGE_DURATION.TALK_TIME": "Viimeisen puhelusillan puheaika (sekuntia)",
  "CURRENT_VOICE_CALL_BRIDGE.ID": "Nykyisen puhelusillan ID",
  "CURRENT_VOICE_CALL_BRIDGE.DIRECTION": "Nykyisen puhelusillan suunta",
  "CURRENT_VOICE_CALL_BRIDGE.CREATED_AT": "Nykyinen puhelusilta luotu",
  "CURRENT_VOICE_CALL_BRIDGE.ANSWER_TIME": "Nykyiseen puhelusiltaan vastattu",
  "CURRENT_VOICE_CALL_BRIDGE.LEAVE_AT": "Nykyinen puhelusilta jätetty",
  "CURRENT_VOICE_CALL_BRIDGE.USER_ID": "Nykyisen puhelusillan agentti",
  "CURRENT_VOICE_CALL_BRIDGE.QUEUE_ID": "Nykyisen puhelusillan jono",
  "CURRENT_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Nykyinen puhelusilta on ulkoinen soitto",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TWINNED": "Nykyinen puhelusilta on kahdennettu",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFER": "Nykyinen puhelusilta on siirto",
  "CURRENT_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Nykyinen puhelusilta on siirretty",
  "CURRENT_VOICE_CALL_BRIDGE.EXIT_REASON": "Nykyisen puhelusillan poistumissyy",
  "FIRST_VOICE_CALL_BRIDGE.ID": "Ensimmäisen puhelusillan ID",
  "FIRST_VOICE_CALL_BRIDGE.DIRECTION": "Ensimmäisen puhelusillan suunta",
  "FIRST_VOICE_CALL_BRIDGE.CREATED_AT": "Ensimmäinen puhelusilta luotu",
  "FIRST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Ensimmäiseen puhelusiltaan vastattu",
  "FIRST_VOICE_CALL_BRIDGE.LEAVE_AT": "Ensimmäinen puhelusilta jätetty",
  "FIRST_VOICE_CALL_BRIDGE.USER_ID": "Ensimmäisen puhelusillan agentti",
  "FIRST_VOICE_CALL_BRIDGE.QUEUE_ID": "Ensimmäisen puhelusillan jono",
  "FIRST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Ensimmäinen puhelusilta on ulkoinen soitto",
  "FIRST_VOICE_CALL_BRIDGE.IS_TWINNED": "Ensimmäinen puhelusilta on kahdennettu",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Ensimmäinen puhelusilta on siirto",
  "FIRST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Ensimmäinen puhelusilta on siirretty",
  "FIRST_VOICE_CALL_BRIDGE.EXIT_REASON": "Ensimmäisen puhelusillan poistumissyy",
  "LAST_VOICE_CALL_BRIDGE.ID": "Viimeisen puhelusillan ID",
  "LAST_VOICE_CALL_BRIDGE.DIRECTION": "Viimeisen puhelusillan suunta",
  "LAST_VOICE_CALL_BRIDGE.CREATED_AT": "Viimeinen puhelusilta luotu",
  "LAST_VOICE_CALL_BRIDGE.ANSWER_TIME": "Viimeiseen puhelusiltaan vastattu",
  "LAST_VOICE_CALL_BRIDGE.LEAVE_AT": "Viimeinen puhelusilta jätetty",
  "LAST_VOICE_CALL_BRIDGE.USER_ID": "Viimeisen puhelusillan agentti",
  "LAST_VOICE_CALL_BRIDGE.QUEUE_ID": "Viimeisen puhelusillan jono",
  "LAST_VOICE_CALL_BRIDGE.IS_EXTERNAL": "Viimeinen puhelusilta on ulkoinen soitto",
  "LAST_VOICE_CALL_BRIDGE.IS_TWINNED": "Viimeinen puhelusilta on kahdennettu",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFER": "Viimeinen puhelusilta on siirto",
  "LAST_VOICE_CALL_BRIDGE.IS_TRANSFERRED": "Viimeinen puhelusilta on siirretty",
  "LAST_VOICE_CALL_BRIDGE.EXIT_REASON": "Viimeisen puhelusillan poistumissyy",
  "USER.USERNAME": "Agentin käyttäjätunnus",
  "USER.EMAIL": "Agentin sähköposti",
  "USER.FIRST_NAME": "Agentin etunimi",
  "USER.LAST_NAME": "Agentin sukunimi",
  "USER.FULL_NAME": "Agentin koko nimi",
  "_QUEUE.AGENTS_READY": "Valmiit agentit jonossa",
  "_QUEUE.AGENTS_PAUSED": "Tauolla olevat agentit jonossa",
  "_QUEUE.AGENTS_BUSY": "Varatut agentit jonossa",
  "_QUEUE.AGENTS_TOTAL": "Agenttien kokonaismäärä jonossa",
  "_QUEUE.LONGEST_WAITING": "Pisin odotusaika jonossa",
  "_QUEUE.WAITING": "Odottavat jonossa",
  PRIORITY: "Prioriteetti",
  DISPOSITION_ID: "Luokittelu",
  SUB_DISPOSITION_ID: "Alaluokittelu",
  THRD_DISPOSITION_ID: "Kolmas luokittelu",
  DISPOSITION_NOTE: "Luokittelun huomautus",
  STATUS: "Tila",
  ADD_TAG: "Lisää tunniste",
  REMOVE_TAG: "Poista tunniste",
  STATUS_O: "Avoin",
  STATUS_RO: "Uudelleen avattu",
  STATUS_C: "Suljettu",
  STATUS_R: "Ratkaistu",
  STATUS_P: "Odottaa",
  STATUS_W: "Odottaa kolmatta osapuolta",

  PRIORITY_URGENT: "Kiireellinen",
  PRIORITY_HIGH: "Korkea",
  PRIORITY_MEDIUM: "Keskitaso",
  PRIORITY_LOW: "Matala",
  PRIORITY_U: "Kiireellinen",
  PRIORITY_H: "Korkea",
  PRIORITY_M: "Keskitaso",
  PRIORITY_L: "Matala",

  DIRECTION_INBOUND: "Saapuva",
  DIRECTION_OUTBOUND: "Lähtevä",
  DIRECTION_INTERNAL: "Sisäinen",
  DIRECTION_I: "Saapuva",
  DIRECTION_O: "Lähtevä",
  "DIRECTION_-": "Sisäinen",

  EXIT_REASON_REJECT: "Hylätty",
  EXIT_REASON_CONTACTHANGEDUP: "Asiakas sulki puhelun",
  EXIT_REASON_TIMEOUT: "Aikakatkaisu",
  EXIT_REASON_FULL: "Jono täynnä",
  EXIT_REASON_JOINEMPTY: "Liittyminen tyhjään jonoon",
  EXIT_REASON_LEAVEEMPTY: "Tyhjän jonon jättäminen",
  EXIT_REASON_JOINUNAVAIL: "Liittyminen ei-saatavilla olevaan jonoon",
  EXIT_REASON_LEAVEUNAVAIL: "Ei-saatavilla olevan jonon jättäminen",

  DEFINED: "Määritelty",
  CUSTOM: "Mukautettu",

  NEWDRAFT: "Uusi luonnos luotu",
  NEWINTERNALNOTE: "Uusi sisäinen muistiinpano luotu",
  CONVERSATIONUPDATED: "Keskustelu päivitetty",
  CONVERSATIONCREATED: "Keskustelu luotu",
  CONTACTUPDATED: "Yhteystieto päivitetty",
  CONTACTCREATED: "Yhteystieto luotu",
  NEWINBOUNDMAILMESSAGE: "Uusi saapuva sähköpostiviesti",
  NEWOUTBOUNDMAILMESSAGE: "Uusi lähtevä sähköpostiviesti",
  SINCECONVERSATION: "Keskustelun päivityksestä",
  NEWINBOUNDVOICECALL: "Uusi saapuva puhelu",
  NEWOUTBOUNDVOICECALL: "Uusi lähtevä puhelu",
  NEWINTERNALVOICECALL: "Uusi sisäinen puhelu",
  INBOUNDVOICECALLHANGEDUP: "Saapuva puhelu lopetettu",
  OUTBOUNDVOICECALLHANGEDUP: "Lähtevä puhelu lopetettu",
  INTERNALVOICECALLHANGEDUP: "Sisäinen puhelu lopetettu",
  INBOUNDVOICECALLANSWERED: "Saapuvaan puheluun vastattu",
  OUTBOUNDVOICECALLANSWERED: "Lähtevään puheluun vastattu",
  INTERNALVOICECALLANSWERED: "Sisäiseen puheluun vastattu",
  NEWINBOUNDVOICECALLBRIDGE: "Uusi saapuva puhelusilta",
  NEWOUTBOUNDVOICECALLBRIDGE: "Uusi lähtevä puhelusilta",
  NEWINTERNALVOICECALLBRIDGE: "Uusi sisäinen puhelusilta",
  INBOUNDVOICECALLBRIDGELEAVE: "Saapuva puhelusilta jätetty",
  OUTBOUNDVOICECALLBRIDGELEAVE: "Lähtevä puhelusilta jätetty",
  INTERNALVOICECALLBRIDGELEAVE: "Sisäinen puhelusilta jätetty",
  INBOUNDVOICECALLBRIDGEANSWERED: "Saapuvaan puhelusiltaan vastattu",
  OUTBOUNDVOICECALLBRIDGEANSWERED: "Lähtevään puhelusiltaan vastattu",
  INTERNALVOICECALLBRIDGEANSWERED: "Sisäiseen puhelusiltaan vastattu",

  CANNED_ANSWER_ID: "Vakiovastaus",
  CANNED_ANSWER_ID2: "Vakiovastaus 2",
  CANNED_ANSWER_ID3: "Vakiovastaus 3",
  CANNED_ANSWER_ID4: "Vakiovastaus 4",
  MAIL_ACCOUNT_ID: "Sähköpostitili",
  DESCRIPTION: "Kuvaus",
  URL: "Verkko-osoite",
  STATUS_PORTS: "Tilaportit",
  QUERY_PARAMS: "Kyselyparametrit",
  HEADERS: "Otsikot",
  BODY: "Sisältö",
  QUEUE_ID: "Jono",
  MILLISECONDS: "Millisekuntia",
  TIMEOUT: "Aikakatkaisu",
  OPERATIONAL_HOURS_ID: "Aukioloajat",
  JOURNEY_ID: "Journey ID",
  PUBLISH: "Julkaise",
  ENTER_FULLSCREEN: "Näytä koko näytöllä",
  EXIT_FULLSCREEN: "Poistu koko näytön tilasta",
  RESET: "Palauta",
  APPLY: "Käytä",
  RELOAD_FROM_JOURNEY: "Lataa uudelleen matkasta",
  WHEN: "Milloin",
  WITHIN: "Sisällä",
  NOT_WITHIN: "Ei sisällä",
  ERROR: "Virhe",
  SUCCESS: "Onnistui",
  OPTION_1: "Vaihtoehto 1",
  OPTION_2: "Vaihtoehto 2",
  OPTION_3: "Vaihtoehto 3",
  OPTION_4: "Vaihtoehto 4",
  OPTION_5: "Vaihtoehto 5",
  OPTION_6: "Vaihtoehto 6",
  OPTION_7: "Vaihtoehto 7",
  OPTION_8: "Vaihtoehto 8",
  OPTION_9: "Vaihtoehto 9",
  RULE_1: "Sääntö 1",
  RULE_2: "Sääntö 2",
  RULE_3: "Sääntö 3",
  RULE_4: "Sääntö 4",
  RULE_5: "Sääntö 5",
  RULE_6: "Sääntö 6",
  RULE_7: "Sääntö 7",
  RULE_8: "Sääntö 8",
  RULE_9: "Sääntö 9",
  OTHERWISE: "Muuten",
  TRIGGERED: "Lauennut",
  TIMED_OUT: "Aikakatkaisu",
  NEXT: "Seuraava",
  EDITED: "Muokattu",
  ASSIGNED: "Määrätty",
  TIME_RANGE: "Aikaväli",
  MONTHS: "Kuukaudet",
  DAYS: "Päivät",
  DAY_S: "Päivä(t)",
  WEEK_DAYS: "Viikonpäivät",
  FROM: "Alkaen",
  ALWAYS: "Aina",
  INVALID_INTERVAL: "Virheellinen aikaväli",
  CREATED: "Luotu",
  RESOLVED: "Ratkaistu",
  REOPENED: "Avattu uudelleen",
  FIRSTASSIGNED: "Ensimmäinen määräys",
  REQUESTERRESPONDED: "Pyytäjä vastasi",
  AGENTRESPONDED: "Agentti vastasi",
  UNTILFIRSTRESPONSEDUE: "Ensimmäiseen vastaukseen asti",
  FIRSTRESPONSEOVERDUE: "Ensimmäinen vastaus myöhässä",
  UNTILANYRESPONSEDUE: "Mihin tahansa vastaukseen asti",
  ANYRESPONSEOVERDUE: "Mikä tahansa vastaus myöhässä",
  RESOLUTIONTIMEOVERDUE: "Ratkaisuaika ylitetty",
  UNTILRESOLUTIONTIME: "Ratkaisuaikaan asti",
  WAITINGFORTHIRDPARTY: "Odottaa kolmatta osapuolta",
  UNIT: "Yksikkö",
  TIME: "Aika",
  RESPONSE_TIME: "Vastausaika",
  RESOLUTION_TIME: "Ratkaisuaika",
  CONTACT_ID: "Yhteystieto",
  USER_ID: "Käyttäjätunnus",
  AGENT_ID: "Agentin tunnus",
  HOURS_SHORT: "t",
  MINUTES_SHORT: "m",
  SECONDS_SHORT: "s",
  DAYS_SHORT: "pv",
  YESTERDAY: "Eilen",
  JUST_NOW: "Juuri nyt",
  NOUPDATEDIN7DAYS: "Ei päivityksiä 7 päivään",
  CUSTOMERRESPONDED: "Asiakas vastasi",
  STATUS_AT: "Tila muutettu",
  ASSIGNED_AT: "Määrätty",
  FIRST_ASSIGNED_AT: "Ensimmäinen määräys",
  CONVERSATION_UPDATE_DISPOSITION_ID: "Luokittelu päivitetty",
  CONVERSATION_UPDATE_USER_ID: "Agentti päivitetty",
  CONVERSATION_UPDATE_STATUS: "Tila muutettu",
  CONVERSATION_UPDATE_FLAG: "Lippu muutettu",
  CONVERSATION_UPDATE_RESOLUTION_TIME: "Ratkaisuaika muutettu",
  CONVERSATION_UPDATE_RESPONSE_TIME: "Vastausaika muutettu",
  CONVERSATION_UPDATE_QUEUE: "Jono muutettu",
  CONVERSATION_UPDATE_PRIORITY: "Prioriteetti muutettu",
  CONVERSATION_UPDATE_CONTACT: "Yhteystieto muutettu",
  CONVERSATION_NEW_MESSAGE: "Uusi viesti",
  NEW_CONVERSATION: "Uusi keskustelu",
  MINIMIZE: "Pienennä",
  MAXIMIZE: "Suurenna",
  MINUTES: "Minuutit",
  MINUTE_S: "Minuutti(a)",
  SECOND_S: "Sekunti(a)",
  HOURS: "Tunnit",
  HOUR_S: "Tunti(a)",
  LAST_UPDATED: "Viimeksi päivitetty",
  SUBJECT: "Aihe",
  CC: "Kopio",
  BCC: "Piilokopio",
  DISPOSE_AND_CLOSE: "Luokittele & Sulje",
  DISPOSE_AND_RESOLVE: "Luokittele & Ratkaise",
  ADD_NEW: "Lisää uusi",
  CONFIRM_DELETION: "Haluatko varmasti poistaa tämän kohteen?",
  OAUTH_AUTHENTICATION_NOT_FOUND: "Todennus epäonnistui!",
  OAUTH_AUTHENTICATION_NOT_FOUND_CONTENT:
    "Organisaatiosi ylläpitäjän täytyy luoda tili sähköpostiosoitteellesi ennen kuin voit kirjautua sisään!",
  MAIL_ACCOUNT_AUTHENTICATED: "Sähköpostitili todennettu!",
  AN_ERROR_OCCURRED: "Tapahtui virhe!",
  ATTACHMENTS: "Liitteet",
  ATTACHMENT: "Liite",
  FILE_NOT_FOUND: 'Tiedostoa "{{filename}}" ei löytynyt!',
  NOT_FOUND: "Ei löytynyt!",
  PAGE_NOT_FOUND: "Sivua ei löytynyt!",
  PAGE_NOT_FOUND_DESCRIPTION:
    "Etsimääsi sivua ei löydy! Jos tulit tänne linkin kautta, ilmoita ongelmasta ylläpitäjällesi!",
  NOTIFICATION_A: "Uusi määräys",
  NOTIFICATION_B: "Uusi vastaus keskusteluuni",
  NOTIFICATION_C: "Keskusteluviestit joissa minut on merkitty",
  NOTIFICATION_D: "Tilapäivitys keskusteluuni",
  NOTIFICATION_E: "Keskustelu määrätty minulle",
  NOTIFICATION_F: "Keskustelu määrätty jonoihini",
  NOTIFICATION_G: "Muistutus ensimmäisestä vastauksesta",
  NOTIFICATION_H: "Muistutus ratkaisuajasta",
  NOTIFICATION_I: "Muistutus seuraavasta vastauksesta",
  NOTIFICATION_J: "Järjestelmäinfo",
  NOTIFICATION_K: "Järjestelmävirhe",
  NOTIFICATION_L: "Salasana vanhenee pian",
  NOTIFICATION_M: "Hälytykset",
  "NOTIFICATION_-": "Sisäinen puhelu",
  MARK_ALL_AS_SEEN: "Merkitse kaikki nähdyiksi",
  CANNED_ANSWER: "Vakiovastaus",
  CONTACT_FIRST_NAME: "Yhteystiedon etunimi",
  CONTACT_LAST_NAME: "Yhteystiedon sukunimi",
  CONTACT_FULL_NAME: "Yhteystiedon koko nimi",
  CONTACT_EMAIL: "Yhteystiedon sähköposti",
  CONTACT_PHONE: "Yhteystiedon puhelin",
  AGENT_FIRST_NAME: "Agentin etunimi",
  AGENT_LAST_NAME: "Agentin sukunimi",
  AGENT_FULL_NAME: "Agentin koko nimi",
  CONVERSATION__ID: "Keskustelun tunnus",
  SELECT_CONTACT: "Valitse yhteystieto",
  MULTIPLE_CONTACTS_WHERE_FOUND: "Useita yhteystietoja löytyi!",
  MULTIPLE_CONTACTS_WHERE_FOUND_SELECT_CONTACT: "Useita yhteystietoja löytyi! Mitä haluat käyttää?",
  WHICH_ONE_DO_YOU_WANT_TO_USE: "Mitä haluat käyttää?",
  EMAILS: "Sähköpostiosoitteet",
  PHONES: "Puhelinnumerot",
  STRATEGY: "Strategia",
  ROUND_ROUND_ROBIN: "Vuorottelu",
  VOICE_QUEUE_STRATEGY_RINGALL: "Soita kaikille",
  VOICE_QUEUE_STRATEGY_ROUNDROBIN: "Vuorottelu",
  VOICE_QUEUE_STRATEGY_LEASTRECENT: "Vähiten viimeisin",
  VOICE_QUEUE_STRATEGY_FEWESTCALLS: "Vähiten puheluita",
  VOICE_QUEUE_STRATEGY_RRMEMORY: "Vuorottelu muistilla",
  VOICE_QUEUE_STRATEGY_LINEAR: "Lineaarinen",
  VOICE_QUEUE_STRATEGY_WRANDOM: "Painotettu satunnainen",
  RINGALL: "Soita kaikille",
  LEASTRECENT: "Vähiten viimeisin",
  FEWESTCALLS: "Vähiten puheluita",
  RANDOM: "Satunnainen",
  WRANDOM: "Painotettu satunnainen",
  LINEAR: "Lineaarinen",
  RRMEMORY: "Vuorottelu muistilla",
  ROUNDROBIN: "Vuorottelu",
  QUEUE_STRATEGY_ROUND_ROUND_ROBIN: "Vuorottelu",
  QUEUE_STRATEGY_PING_ALL: "Pingaa kaikki",
  QUEUE_STRATEGY_LEAST_RECENT: "Vähiten viimeisin",
  QUEUE_STRATEGY_FEWEST_CONVERSATIONS: "Vähiten keskusteluja",
  QUEUE_STRATEGY_RANDOM: "Satunnainen",
  QUEUE_STRATEGY_SKILL_BASED_AGENTS_READY: "Taitopohjainen agentit valmiina",
  QUEUE_STRATEGY_SKILL_BASED_TIMEOUT: "Taitopohjainen aikakatkaisu",
  CREATE_LIST_ID: "Luo lista",
  CREATE_LIST_ID_HELP: "Valitse lista johon uudet yhteystiedot lisätään",
  READ_LISTS: "Lue listat",
  READ_LISTS_HELP:
    "Listat joista yhteystietoja luetaan. Sekä järjestelmä että agentit valitsevat yhteystietoja näistä listoista.",
  CONVERSATION_FORM_ID: "Keskustelulomake",
  SLA_POLICY_ID: "SLA-käytäntö",
  INBOUND_SLA_POLICY_ID: "SLA-käytäntö (saapuva)",
  OUTBOUND_SLA_POLICY_ID: "SLA-käytäntö (lähtevä)",
  REQUIRE_DISPOSITION: "Vaadi luokittelu",
  SETTINGS_REQUIRE_DISPOSITION_HELP:
    "Ota käyttöön pakottaaksesi luokittelun valinnan ennen keskustelun sulkemista",
  REQUIRE_CLIENT: "Vaadi asiakas",
  SETTINGS_REQUIRE_CLIENT_HELP:
    "Ota käyttöön pakottaaksesi asiakkaan valinnan ennen keskustelun sulkemista",
  CONVERSATION_ASSIGNMENT: "Keskustelun määräys",
  SETTINGS_CONVERSATION_ASSIGNMENT_HELP:
    "Miten keskustelut määrätään agenteille. Manuaalinen määräys on aina saatavilla.",
  ONOPEN: "Avattaessa",
  ONREPLY: "Vastattaessa",
  ONCLOSE: "Suljettaessa",
  MANUALLY: "Manuaalisesti",
  CONVERSATION_ASSIGNMENT_ONOPEN: "Avattaessa",
  CONVERSATION_ASSIGNMENT_ONREPLY: "Vastattaessa",
  CONVERSATION_ASSIGNMENT_ONCLOSE: "Suljettaessa",
  CONVERSATION_ASSIGNMENT_MANUALLY: "Manuaalisesti",
  __DISPOSITIONS: "Luokittelut",
  __DISPOSITIONS_HELP: "Saatavilla olevat luokittelut valittavaksi tämän jonon keskusteluille.",
  __CANNED_ANSWERS: "Vakiovastaukset",
  __AGENT_SIGNATURES: "Agenttien allekirjoitukset",
  __OUTBOUND_MAIL_ACCOUNTS: "Lähtevät sähköpostitilit",
  __OUTBOUND_MAIL_ACCOUNTS_HELP:
    "Saatavilla olevat lähtevät sähköpostitilit uusia lähteviä sähköpostikeskusteluja luotaessa",
  FORM_NULLABLE_ENABLE: "Määritä arvo",
  FORM_NULLABLE_DISABLE: "Poista arvo käytöstä",
  EDIT_QUEUE: "Muokkaa jonoa",
  ADD_QUEUE: "Luo uusi jono",
  DELETED: "Poistettu",
  TWO_FACTOR_AUTH: "2FA",
  ORGANIZATION_ID: "Organisaatio",
  ADMINPROFILE_ID: "Ylläpitäjäprofiili",
  SUPERADMIN_PROFILE_ID: "Pääylläpitäjäprofiili",
  __PAUSES: "Tauot",
  __QUEUES: "Jonot",
  __TEAMS: "Tiimit",
  TEAM: "Tiimi",
  ADDRESS: "Osoite",
  INBOUND_JOURNEY_ID: "Saapuva matka",
  OUTBOUND_JOURNEY_ID: "Lähtevä matka",
  IMAP: "IMAP",
  SMTP: "SMTP",
  HOST: "Isäntä",
  PORT: "Portti",
  NAME: "Nimi",
  TLS: "tls/ssl",
  REMOVE_ON_RECEIVE: "Poista vastaanotettaessa",
  IMAP_REMOVE_ON_RECEIVE_HELP: "Poista sähköposti saapuneet-kansiosta kun se vastaanotetaan",
  BASIC: "Perus",
  XOAUTH2: "Oauth 2",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASIC: "Perus",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICGOOGLE: "Perus (Google)",
  MAIL_ACCOUNT_AUTH_STRATEGY_BASICMICROSOFT: "Perus (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2MICROSOFT: "Moderni todennus (Microsoft)",
  MAIL_ACCOUNT_AUTH_STRATEGY_XOAUTH2GOOGLE: "Moderni todennus (Google)",
  JOURNEIES: "Journeys",
  JOURNEY_DRAFTS: "Journey drafts",
  FORM_CHANNEL: "Lomakekanava",
  FORM_WEBSITES: "Lomakesivustot",
  TIME_INTERVALS: "Aikavälit",
  OPERATIONAL_HOURSES: "Aukioloajat",
  OPERATIONAL_HOURS: "Aukioloajat",
  SLA_POLICIES: "SLA-käytännöt",
  CANNED_ANSWERS: "Vakiovastaukset",
  SIGNATURES: "Allekirjoitukset",
  FORMS: "Lomakkeet",
  STAFF: "Henkilöstö",
  SEARCH_AGENTS: "Hae agentteja",
  STATUS_0: "Offline",
  STATUS_1: "Online",
  STATUS_4: "Valmis (Sähköposti)",
  STATUS_8: "Valmis (Ääni)",
  STATUS_512: "Valmis",
  STATUS_1024: "Osittain varattu",
  STATUS_2048: "Käsittelee keskustelua",
  STATUS_4096: "Pingaa",
  STATUS_32768: "Tauko",
  STATUS_262144: "Varattu (Sähköposti)",
  STATUS_524288: "Varattu (Ääni)",
  STATUS_1048576: "Varattu (Lomake)",
  STATUS_33554432: "Varattu",
  FILTER: "Suodatin",
  ITEMS: "Kohteet",
  ALLOW: "Salli",
  CANNEDANSWER: "Vakiovastaus",
  FORM: "Lomake",
  SIGNATURE: "Allekirjoitus",
  SLAPOLICY: "SLA-käytäntö",
  OPERATIONALHOURS: "Aukioloajat",
  TAG: "Tunniste",
  MAILACCOUNT: "Sähköpostitili",
  READ: "Lue",
  CREATE: "Luo",
  DELETE: "Poista",
  PERMISSIONS: "Käyttöoikeudet",
  TIMEINTERVAL: "Aikaväli",
  LOGIN_STRATEGY: "Kirjautumistapa",
  LOGIN_STRATEGY_LOCAL: "Käyttäjätunnus / Salasana",
  LOGIN_STRATEGY_GOOGLE: "Google",
  LOGIN_STRATEGY_MICROSOFT: "Microsoft",
  ADMIN_AGENT: "Ylläpitäjä & Agentti",
  FORMWEBSITE: "Lomakesivusto",
  SCHEMA_NOT_FOUND: "Lomaketta ei voitu ladata!",
  ITEM_NOT_FOUND: "Kohdetta jota yrität ladata ei löytynyt!",
  ADMIN_ORGANIZATIONS_CHANGED_RELOAD:
    "Käyttäjäsi organisaatiot on päivitetty, ole hyvä ja lataa sivu uudelleen jotta sovellus toimii oikein!",
  LOADING_DEPENDENCIES: "Ladataan riippuvuuksia",
  PARENT: "Ylempi",
  ACTIVE: "Aktiivinen",
  ORGANIZATION_2FA_HELP: "Valitse roolit joille pakotetaan kaksivaiheinen tunnistautuminen",
  SECONDS: "Sekuntia",
  PROPERTY: "Ominaisuus",
  VALUE: "Arvo",
  SIGNATURE_ID: "Allekirjoitus",
  METHOD: "Metodi",
  CONDITIONS: "Ehdot",
  ADD_CONDITION: "Lisää ehto",
  FLAG: "Lippu",
  SOURCE: "Lähde",
  SINCE: "Lähtien",
  JOURNEY_DRAFT: "Matkaluonnos",
  PENDING: "Odottaa",
  START_CONVERSATION: "Aloita keskustelu",
  NEW_V_CONVERSATION: "Uusi SIP-keskustelu",
  NEW_M_CONVERSATION: "Uusi sähköpostikeskustelu",
  CREATE_NEW_CONTACT: "Luo uusi yhteystieto",
  OR_CREATE_NEW_CONTACT: "tai luo uusi yhteystieto",
  LOADING_FORM: "Ladataan lomaketta",
  __TAGS: "Tunnisteet",
  MAIN_CHANNEL: "Pääkanava",
  CREATE_CONVERSATION: "Luo keskustelu",
  CHOOSE_AGENT: "Valitse agentti",
  CHOOSE_QUEUE: "Valitse jono",
  SELECTED: "Valittu",
  CHANGE_CONTACT: "Vaihda yhteystieto",
  CREATE_CONTACT: "Luo yhteystieto",
  REQUIRED: "Pakollinen",
  CONVERSATION_STATUS: "Keskustelun tila",
  NO_NAME: "Ei nimeä",
  DISPLAY_TITLE: "Näyttönimi",
  UI_TYPE: "UI-tyyppi",
  VALIDATION: "Validointi",
  MIN: "Min",
  CHILD_TYPE: "Alatyyppi",
  VALID: "Kelvollinen",
  REQUIRED_ON_SAVE: "Pakollinen tallennettaessa",
  REQUIRED_ON_CONVERSATION_CLOSE: "Pakollinen keskustelua suljettaessa",
  REQUIRED_ON_FORM_SUBMIT: "Pakollinen verkkolomaketta lähetettäessä",
  ALIAS: "Alias",
  ALIAS__KEY: "Alias / avain",
  FIELD_TYPE_DESCRIPTION: "Kenttätyyppi tekee sen saatavaksi eri lomaketyypeille",
  MINIMUM: "Minimi",
  VALIDATION_MINIMUM_NUMBER_HELP: "Pienin numero joka voidaan täyttää tähän kenttään",
  VALIDATION_MINIMUM_LIST_HELP: "Pienin määrä vaihtoehtoja",
  VALIDATION_MINIMUM_TEXT_HELP: "Pienin määrä merkkejä",
  SETTINGS_VALIDATION_CHILD_TYPE_HELP: "Listavaihtoehtojen tyyppi",
  FIELD_ALIAS_HELP:
    "Arvot tallennetaan tällä yksilöllisellä aliaksella. Vain alfanumeeriset merkit ja _ ovat kelvollisia merkkejä.",
  OPTIONS: "Vaihtoehdot",
  __FIELDS: "Kentät",
  WEBSITE: "Verkkosivusto",
  POLICY: "Käytäntö",
  FIRST_RESPONSE: "Ensimmäinen vastaus",
  EVERY_RESPONSE: "Jokainen vastaus",
  URGENT: "Kiireellinen",
  SLA_POLICY: "SLA-käytäntö",
  SEARCH_RESULTS: "Hakutulokset",
  UNASSIGNED: "Ei määritetty",
  NO_QUEUE: "Ei jonoa",
  NO_PAUSE: "Ei taukoa",
  NOT_DISPOSED: "Ei luokiteltu",
  NO_FLAG: "Ei lippua",
  FLAGS: "Liput",
  INVALID_2FA_SECRET: "Virheellinen koodi",
  __TIME_INTERVALS: "Aikavälit",
  TIME_INTERVAL: "Aikaväli",
  TIMEZONE: "Aikavyöhyke",
  RESET_PASSWORD_DONE: "Ohjeet sisältävä sähköposti on lähetetty sinulle!",
  PASSWORD_RESET_EMAIL_WILL_BE_SENT:
    "Lähetämme ohjeet sisältävän sähköpostin annettuun käyttäjätiliin, jos tili on olemassa!",
  DISPOSITION_REQUIRED_ON_THIS_QUEUE:
    "Luokittelu on pakollinen keskustelun sulkemiseksi/ratkaisemiseksi jonon asetusten mukaisesti.",
  CLIENT_REQUIRED_ON_THIS_QUEUE:
    "Asiakas on pakollinen keskustelun sulkemiseksi/ratkaisemiseksi jonon asetusten mukaisesti.",
  CONVERSATION_FORM_NOT_VALID_ON_SAVE:
    "Keskustelulomakkeessa on pakollisia kenttiä jotka täytyy täyttää ennen keskustelun muokkaamista.",
  CONVERSATION_FORM_NOT_VALID_ON_CLOSE:
    "Keskustelulomakkeessa on pakollisia kenttiä jotka täytyy täyttää ennen keskustelun ratkaisemista/sulkemista.",
  CONTACT_FORM_NOT_VALID_ON_SAVE:
    "Yhteystietolomakkeessa on pakollisia kenttiä jotka täytyy täyttää ennen keskustelun muokkaamista.",
  CONTACT_FORM_NOT_VALID_ON_CLOSE:
    "Yhteystietolomakkeessa on pakollisia kenttiä jotka täytyy täyttää ennen keskustelun ratkaisemista/sulkemista.",
  CONVERSATION_CANNOT_BE_CLOSED_WITHOUT_USER:
    "Keskustelulle täytyy olla määritetty agentti ennen sulkemista/ratkaisemista.",
  DISPOSITION_NOTE_NOT_ALLOWED_WHEN_NO_DISPOSITION_SELECTED:
    "Luokittelumerkintä ei ole sallittu ilman valittua luokittelua.",
  QUEUE_MAY_NOT_BE_NULL: "Jono ei saa olla määrittämätön keskustelulle.",
  AGENT_NOT_IN_SELECTED_QUEUE: "Määritetty agentti ei ole liitetty valittuun jonoon.",
  CONTACT_ALREADY_SELECTED: "Yhteystieto on jo valittu keskustelulle",
  QUEUE_NOT_FOUND_ON_CONVERSATION:
    "Jono täytyy määrittää keskustelulle ennen yhteystiedon valintaa.",
  NO_MESSAGES_FOUND_ON_CONVERSATION: "Keskustelusta ei löytynyt viestejä.",
  QUEUE_NO_READ_LISTS: "Jonolla ei ole määritettyjä lukulistoja.",
  CONVERSATION_FORM_DATA_CLOSE_REQUIRED_TOOLTIP:
    "Pakollinen kenttä kun keskustelun tila asetetaan suljetuksi",
  CONVERSATION_FORM_DATA_CLOSE_TOOLTIP:
    "Pakollinen kenttä keskustelun ominaisuuksia päivitettäessä",
  VIEW_CONVERSATION: "Näytä keskustelu",
  START_NEW_MAIL_CONVERSATION: "Aloita uusi sähköpostikeskustelu",
  START_NEW_VOICE_CONVERSATION: "Aloita uusi äänipuhelu",
  NULLABLE: "Tyhjennettävä",
  SETTINGS_NULLABLE_HELP: 'Arvo voidaan asettaa "null"-tilaan',
  UI_DATA_TYPE: "UI / Tietotyyppi",
  LAST_SAVE: "Viimeksi tallennettu",
  TITLE_MISSING: "Otsikko puuttuu",
  SELECT_QUEUE: "Valitse jono",
  CREATE_INTERNAL_NOTE: "Luo sisäinen muistiinpano",
  SAVE_DRAFT: "Tallenna luonnos",
  NO_SELECTABLE_DISPOSITIONS: "Ei valittavia luokitteluja",
  SMTP_PORT_HELP: "Oletus: 25, tls: 465",
  IMAP_PORT_HELP: "Oletus: 143, tls: 993",
  FORM_ID: "Lomake",
  CHANNEL: "Kanava",
  INTERVAL: "Aikaväli",
  CANNED_ANSWER_TYPE_A: "Agentti",
  CANNED_ANSWER_TYPE_Q: "Jono",
  CANNED_ANSWER_CHANNEL_M: "Sähköposti",
  CANNED_ANSWER_CHANNEL_S: "Tekstiviesti",
  SIGNATURE_TYPE_A: "Agentti",
  SIGNATURE_TYPE_Q: "Jono",
  SLA_TIME_UNIT_H: "Tuntia",
  SLA_TIME_UNIT_D: "Päivää",
  FORM_TYPE_CONVERSATION: "Keskustelu",
  FORM_TYPE_CONTACT: "Yhteystieto",
  FORM_TYPE_WEBSITE: "Verkkosivusto",
  FIELD_TYPE_CONVERSATION: "Keskustelu",
  FIELD_TYPE_CONTACT: "Yhteystieto",
  FIELD_TYPE_FORMSUBMISSION: "Lomakelähetys",
  FORM_ELEMENT_TYPE_EXPOSED_INPUT: "Teksti",
  FORM_ELEMENT_TYPE_EXPOSED_TEXT: "Pitkä teksti",
  FORM_ELEMENT_TYPE_EXPOSED_NUMBER: "Numero",
  FORM_ELEMENT_TYPE_EXPOSED_SELECT: "Valitse",
  FORM_ELEMENT_TYPE_EXPOSED_LIST: "Lista",
  FORM_ELEMENT_TYPE_EXPOSED_SWITCH: "Kytkin",
  FORM_ELEMENT_TYPE_EXPOSED_RADIO: "Radio",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOXES: "Valintaruudut",
  FORM_ELEMENT_TYPE_EXPOSED_CHECKBOX: "Valintaruutu",
  FORM_ELEMENT_TYPE_EXPOSED_EMAIL: "Sähköposti",
  FORM_ELEMENT_TYPE_EXPOSED_PHONE: "Puhelinnumero",
  FORM_ELEMENT_TYPE_EXPOSED_HTML: "Html",
  FORM_ELEMENT_TYPE_EXPOSED_FILE: "Tiedosto",

  FORM_ELEMENT_CHILD_TYPE_EXPOSED_INPUT: "Teksti",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_NUMBER: "Numero",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_PHONE: "Puhelinnumero",
  FORM_ELEMENT_CHILD_TYPE_EXPOSED_EMAIL: "Sähköposti",

  CHANNEL_VOICE: "Ääni",
  CHANNEL_VOICE_INBOUND: "Saapuva äänipuhelu",
  CHANNEL_VOICE_INTERNAL: "Sisäinen äänipuhelu",
  CHANNEL_VOICE_INTERNAL_LIVE: "Käynnissä oleva sisäinen äänipuhelu",
  CHANNEL_VOICE_TRANSFER: "Äänisiirto",
  CHANNEL_VOICE_TRANSFER_LIVE: "Käynnissä oleva äänisiirto",
  CHANNEL_VOICE_INBOUND_LIVE: "Käynnissä oleva saapuva äänipuhelu",
  CHANNEL_VOICE_OUTBOUND: "Lähtevä äänipuhelu",
  CHANNEL_VOICE_OUTBOUND_LIVE: "Käynnissä oleva lähtevä äänipuhelu",
  CHANNEL_EDIT: "Muokkaa",
  CHANNEL_CHAT: "Chat",
  CHANNEL_CHAT_INBOUND: "Saapuva chat-viesti",
  CHANNEL_CHAT_OUTBOUND: "Lähtevä chat-viesti",
  CHANNEL_MAIL: "Sähköposti",
  CHANNEL_MAIL_INBOUND: "Saapuva sähköposti",
  CHANNEL_MAIL_OUTBOUND: "Lähtevä sähköposti",
  CHANNEL_SMS: "SMS",
  CHANNEL_SMS_INBOUND: "Saapuva SMS",
  CHANNEL_SMS_OUTBOUND: "Lähtevä SMS",
  CHANNEL_FORM: "Lomake",
  CHANNEL_CHANSPY: "Kanavan tarkkailu",
  CHANNEL_DISPOSITION_NOTE: "Luokittelumerkintä",
  INTERNAL_CALL: "Sisäinen puhelu",

  CHANNEL_V: "Ääni",
  "CHANNEL_-": "Ei kanavaa",
  CHANNEL_C: "Chat",
  CHANNEL_M: "Sähköposti",
  CHANNEL_S: "SMS",
  CHANNEL_F: "Lomake",
  CHANNEL_IN: "Sisäinen muistiinpano",
  CHANNEL_In: "Sisäinen muistiinpano",

  ADMINPROFILE_PERMISSION_ID_USER: "Käyttäjä",
  ADMINPROFILE_PERMISSION_ID_TEAM: "Tiimi",
  ADMINPROFILE_PERMISSION_ID_ORGANIZATION: "Organisaatio",
  ADMINPROFILE_PERMISSION_ID_MAILACCOUNT: "Sähköpostitili",
  ADMINPROFILE_PERMISSION_ID_QUEUE: "Jono",
  ADMINPROFILE_PERMISSION_ID_LIST: "Lista",
  ADMINPROFILE_PERMISSION_ID_ADMINPROFILE: "Ylläpitäjäprofiili",
  ADMINPROFILE_PERMISSION_ID_AGENTPROFILE: "Agenttiprofiili",
  ADMINPROFILE_PERMISSION_ID_SLAPOLICY: "SLA-käytäntö",
  ADMINPROFILE_PERMISSION_ID_DISPOSITION: "Luokittelu",
  ADMINPROFILE_PERMISSION_ID_FORM: "Lomake",
  ADMINPROFILE_PERMISSION_ID_FIELD: "Kenttä",
  ADMINPROFILE_PERMISSION_ID_FORMWEBSITE: "Lomakesivusto",
  ADMINPROFILE_PERMISSION_ID_JOURNEY: "Matka",
  ADMINPROFILE_PERMISSION_ID_OPERATIONALHOURS: "Aukioloajat",
  ADMINPROFILE_PERMISSION_ID_PAUSE: "Tauko",
  ADMINPROFILE_PERMISSION_ID_CANNEDANSWER: "Vakiovastaus",
  ADMINPROFILE_PERMISSION_ID_SIGNATURE: "Allekirjoitus",
  ADMINPROFILE_PERMISSION_ID_TAG: "Tunniste",
  ADMINPROFILE_PERMISSION_ID_TIMEINTERVAL: "Aikaväli",
  ADMINPROFILE_PERMISSION_ID_VOICEROUTE: "Äänireitti",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDING: "Äänitallenne",
  ADMINPROFILE_PERMISSION_ID_SOUND: "Ääni",
  ADMINPROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Muuttuja",
  ADMINPROFILE_PERMISSION_ID_STATSREPORT: "Tilastoraportti",
  ADMINPROFILE_PERMISSION_ID_STATSWIDGET: "Tilastowidget",
  ADMINPROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Kohderajoitin",
  ADMINPROFILE_PERMISSION_ID_INVOICE: "Lasku",
  ADMINPROFILE_PERMISSION_ID_VOICESHORTCUT: "Äänipikakuvake",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Ääniviesti",
  ADMINPROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Ääniviestiviesti",
  ADMINPROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Puhelinnumeron osto",
  ADMINPROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Mukautetut mittarit",
  ADMINPROFILE_PERMISSION_ID_AUDIT: "Tarkastus",
  ADMINPROFILE_PERMISSION_ID_APIKEY: "API-avain",
  ADMINPROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Yhteystiedon mukautettu tapahtuma",
  ADMINPROFILE_PERMISSION_ID_CLIENT: "Asiakas",
  ADMINPROFILE_PERMISSION_ID_CONTACT: "Yhteystieto",
  ADMINPROFILE_PERMISSION_ID_WALLBOARD: "Ilmoitustaulu",
  ADMINPROFILE_PERMISSION_ID_SKILL: "Taito",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Äänitallenteiden litterointi",
  ADMINPROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Äänitallenteiden litterointiasetukset",
  ADMINPROFILE_PERMISSION_ID_POINTSSYSTEM: "Pistejärjestelmä",
  ADMINPROFILE_PERMISSION_ID_GDPR: "Turvallisuus & vaatimustenmukaisuus",
  ADMINPROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymisoi",
  ADMINPROFILE_PERMISSION_ID_STATSALARM: "Tilastohälytys",
  ADMINPROFILE_PERMISSION_ID_VOICECALL: "Äänipuhelu",
  ADMINPROFILE_PERMISSION_ID_TEXTMESSAGE: "Tekstiviesti",

  SUPERADMIN_PROFILE_PERMISSION_ID_USER: "Käyttäjä",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEAM: "Tiimi",
  SUPERADMIN_PROFILE_PERMISSION_ID_ORGANIZATION: "Organisaatio",
  SUPERADMIN_PROFILE_PERMISSION_ID_MAILACCOUNT: "Sähköpostitili",
  SUPERADMIN_PROFILE_PERMISSION_ID_QUEUE: "Jono",
  SUPERADMIN_PROFILE_PERMISSION_ID_LIST: "Lista",
  SUPERADMIN_PROFILE_PERMISSION_ID_ADMINPROFILE: "Ylläpitäjäprofiili",
  SUPERADMIN_PROFILE_PERMISSION_ID_AGENTPROFILE: "Agenttiprofiili",
  SUPERADMIN_PROFILE_PERMISSION_ID_SLAPOLICY: "SLA-käytäntö",
  SUPERADMIN_PROFILE_PERMISSION_ID_DISPOSITION: "Luokittelu",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORM: "Lomake",
  SUPERADMIN_PROFILE_PERMISSION_ID_FIELD: "Kenttä",
  SUPERADMIN_PROFILE_PERMISSION_ID_FORMWEBSITE: "Lomakesivusto",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEY: "Matka",
  SUPERADMIN_PROFILE_PERMISSION_ID_OPERATIONALHOURS: "Aukioloajat",
  SUPERADMIN_PROFILE_PERMISSION_ID_PAUSE: "Tauko",
  SUPERADMIN_PROFILE_PERMISSION_ID_CANNEDANSWER: "Vakiovastaus",
  SUPERADMIN_PROFILE_PERMISSION_ID_SIGNATURE: "Allekirjoitus",
  SUPERADMIN_PROFILE_PERMISSION_ID_TAG: "Tunniste",
  SUPERADMIN_PROFILE_PERMISSION_ID_TIMEINTERVAL: "Aikaväli",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEROUTE: "Äänireitti",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDING: "Äänitallenne",
  SUPERADMIN_PROFILE_PERMISSION_ID_SOUND: "Ääni",
  SUPERADMIN_PROFILE_PERMISSION_ID_JOURNEYVARIABLE: "Muuttuja",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSREPORT: "Tilastoraportti",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSWIDGET: "Tilastowidget",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEASTERISK: "Ääniasterisk",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFF: "Tariffi",
  SUPERADMIN_PROFILE_PERMISSION_ID_TARIFFPARENT: "Tariff parent",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOST: "Puhelinnumeron kuukausimaksu",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERMONTHLYCOSTPARENT:
    "Puhelinnumeron kuukausimaksun yläkäsite",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOST: "Puhelinnumeron aloitusmaksu",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSETUPCOSTPARENT:
    "Puhelinnumeron aloitusmaksun yläkäsite",
  SUPERADMIN_PROFILE_PERMISSION_ID_SUPERADMINPROFILE: "Pääylläpitäjäprofiili",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICE: "Lasku",
  SUPERADMIN_PROFILE_PERMISSION_ID_INVOICEPOST: "Laskurivi",
  SUPERADMIN_PROFILE_PERMISSION_ID_PREPAIDCREDIT: "Ennakkomaksu",
  SUPERADMIN_PROFILE_PERMISSION_ID_DESTINATIONRESTRICTOR: "Kohderajoitin",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAIL: "Ääniviesti",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICEVOICEMAILMESSAGE: "Ääniviestiviesti",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICESHORTCUT: "Äänipikakuvake",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICETRUNK: "Äänilinja",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERSTOCK: "Puhelinnumerovarasto",
  SUPERADMIN_PROFILE_PERMISSION_ID_PHONENUMBERPURCHASE: "Puhelinnumeron osto",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSCUSTOMMETRIC: "Mukautetut mittarit",
  SUPERADMIN_PROFILE_PERMISSION_ID_RELEASE: "Julkaisut",
  SUPERADMIN_PROFILE_PERMISSION_ID_AUDIT: "Tarkastus",
  SUPERADMIN_PROFILE_PERMISSION_ID_APIKEY: "API-avain",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACTCUSTOMEVENT: "Yhteystiedon mukautettu tapahtuma",
  SUPERADMIN_PROFILE_PERMISSION_ID_CLIENT: "Asiakas",
  SUPERADMIN_PROFILE_PERMISSION_ID_CONTACT: "Yhteystieto",
  SUPERADMIN_PROFILE_PERMISSION_ID_SKILL: "Taito",
  SUPERADMIN_PROFILE_PERMISSION_ID_WALLBOARD: "Ilmoitustaulu",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPT: "Äänitallenteiden litterointi",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICERECORDINGTRANSCRIPTSETTING:
    "Äänitallenteiden litterointiasetukset",
  SUPERADMIN_PROFILE_PERMISSION_ID_POINTSSYSTEM: "Pistejärjestelmä",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPR: "Turvallisuus & vaatimustenmukaisuus",
  SUPERADMIN_PROFILE_PERMISSION_ID_GDPRANONYMIZE: "Anonymisoi",
  SUPERADMIN_PROFILE_PERMISSION_ID_STATSALARM: "Tilastohälytys",
  SUPERADMIN_PROFILE_PERMISSION_ID_AIASSISTANT: "Tekoälyavustaja",
  SUPERADMIN_PROFILE_PERMISSION_ID_VOICECALL: "Äänipuhelu",
  SUPERADMIN_PROFILE_PERMISSION_ID_TEXTMESSAGE: "Tekstiviesti",

  REPLY_OR_NOTE: "Vastaa tai tee muistiinpano",

  __USERS: "Agentit",
  CONTACT_MANAGEMENT: "Yhteystietojen hallinta",
  NO_QUEUES_SELECTED:
    "Sinulla ei ole jonoja liitettynä organisaatioihin, joihin sinut on liitetty. Ota yhteyttä organisaatiosi ylläpitäjään ratkaistaksesi tämän ongelman.",
  SOCKET_DISCONNECTED:
    "Yhteytesi palvelimeen on katkennut. Päivitä ikkuna.\n\nKatkokset johtuvat epävakaasta internetyhteydestä tai käyttämättömyydestä.",
  SOCKET_DISCONNECTED_INACTIVITY: "Yhteys katkaistu käyttämättömyyden vuoksi",
  WARNING_INACTIVITY:
    'Sinut kirjataan ulos käyttämättömyyden vuoksi. Palaa atlaksen kojelaudalle tai paina "Olen takaisin" estääksesi yhteyden katkeamisen.',
  IAM_BACK: "Olen takaisin!",
  NO_EMAIL_ACCESS:
    "Sinulla ei ole oikeutta lähettää sähköpostia. Ota yhteyttä ylläpitäjään saadaksesi lisätietoja.",
  NO_CREATE_CHANNELS: "Sinulla ei ole pääsyä mihinkään kanavaan luodaksesi uutta keskustelua.",
  NO_OUTBOUND_QUEUES_ON_CREATE_CONVERSATION:
    "Sinulla ei ole pääsyä jonoihin, joissa on lähtevä reitti. Ota yhteyttä organisaatiosi ylläpitäjään ratkaistaksesi tämän ongelman.",
  SELECT_AGENTPROFILE_ERROR: "Agenttiprofiili on valittava keskustelun luomiseksi.",
  SEARCH_AGENT_AND_ASSIGN: "Etsi agentti ja määritä",
  SEARCH_QUEUE_AND_ASSIGN: "Etsi jono ja määritä",
  EDIT_TAGS: "Muokkaa tunnisteita",
  SEARCH_TAGS: "Etsi tunnisteita",
  SELECT_DISPOSITION: "Valitse luokittelu",
  MERGE: "Yhdistä",
  MERGE_CONVERSATION: "Yhdistä keskustelu",
  CONVERSATION_MERGE_EXPLANATION:
    "Toissijaisten tapausten vuorovaikutukset lisätään ensisijaiseen tapaukseen",
  CONTACT_MERGE_EXPLANATION:
    "Toissijaisen yhteystiedon keskustelut lisätään ensisijaiseen yhteystietoon",
  SEARCH_CONVERSATION: "Etsi keskustelu",
  SEARCH_CONTACT: "Etsi yhteystieto",
  MERGE_CONTACT: "Yhdistä yhteystieto",
  PRIMARY: "Ensisijainen",
  NO_AGENT: "Ei agenttia",
  MAIL_ACCOUNT_IMAP_STATE_A: "Todennettu",
  MAIL_ACCOUNT_IMAP_STATE_N: "Ei mitään",
  MAIL_ACCOUNT_IMAP_STATE_E: "Virhe",
  MAIL_ACCOUNT_IMAP_STATE_U: "Luvaton",
  MAIL_ACCOUNT_IMAP_STATE_C: "Yhdistetty",
  MAIL_ACCOUNT_IMAP_STATE_CN: "Yhdistetään",
  MAIL_ACCOUNT_IMAP_STATE_D: "Yhteys katkaistu",
  MAIL_ACCOUNT_SMTP_STATE_A: "Todennettu",
  MAIL_ACCOUNT_SMTP_STATE_N: "Ei mitään",
  MAIL_ACCOUNT_SMTP_STATE_E: "Virhe",
  MAIL_ACCOUNT_SMTP_STATE_U: "Luvaton",
  MAIL_ACCOUNT_SMTP_STATE_C: "Yhdistetty",
  MAIL_ACCOUNT_SMTP_STATE_CN: "Yhdistetään",
  MAIL_ACCOUNT_SMTP_STATE_D: "Yhteys katkaistu",
  IMAP_STATE: "IMAP-tila",
  SMTP_STATE: "SMTP-tila",
  RECONNECT: "Yhdistä uudelleen",
  PERSONIFY_USER: "Henkilöi käyttäjä",
  RULES: "Säännöt",
  JOURNEY_ERROR_LINK_MISSING: "Porttiin on oltava liitetty linkki",
  JOURNEY_ERROR_FORM_ERROR: "Solmulomakkeessa on virheitä. Kaksoisnapsauta muokataksesi lomaketta.",
  JOURNEY_ERROR_DUPLICATE:
    "Päällekkäinen toiminto! Sisääntulotoimintoja ei saa olla useammin kuin kerran.",
  PUBLISH_JOURNEY: "Julkaise matka",
  NO_ITEMS_WERE_FOUND: "Kohteita ei löytynyt",
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_PHRASE:
    'Hakufraasilla "{{keyword}}" ei löytynyt keskusteluja tai yhteystietoja',
  NO_CONVERSATIONS_WERE_FOUND_BY_PHRASE: 'Hakufraasilla "{{keyword}}" ei löytynyt keskusteluja',
  NO_CONTACTS_WERE_FOUND_BY_PHRASE: 'Hakufraasilla "{{keyword}}" ei löytynyt yhteystietoja',
  NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT:
    "Valitusta yhteystiedosta ei löytynyt keskusteluja tai yhteystietoja",
  NO_CONVERSATIONS_WERE_FOUND_BY_SELECTED_CONTACT:
    "Valitusta yhteystiedosta ei löytynyt keskusteluja",
  NO_CONTACTS_WERE_FOUND_BY_SELECTED_CONTACT: "Valitusta yhteystiedosta ei löytynyt yhteystietoja",
  NO_CONVERSATION_FOUND_ON_FILTER: "Suodattimia vastaavia keskusteluja ei löytynyt",
  WITH_SELECTED_DO: "Valituille tee",
  CHANGE_STATUS: "Muuta tila",
  CHANGE_PRIORITY: "Muuta prioriteetti",
  APPLY_CHANGES_TO_ITEMS: "Toteuta muutokset {{count}} kohteeseen",
  APPLY_AND_RESET: "Toteuta & Nollaa",
  ITEMS_FAILED_TO_UPDATE: "{{count}} kohdetta ei voitu päivittää",
  CHANGES_APPLIED_TO_ITEMS: "Muutokset toteutettu {{count}} kohteeseen",
  MAIL_HAS_NO_BODY: "Ei sähköpostin sisältöä",
  TIME_PAST: "Aika kulunut",
  START_TYPING_TO_SEARCH: "Aloita kirjoittaminen hakeaksesi",
  NOT_REMOVABLE_ENTITY: "Ei voida poistaa!",
  SHOW_MORE: "Näytä lisää",
  SHOW_LESS: "Näytä vähemmän",
  UPLOAD: "Lataa",
  N_FILES: "{{files}} tiedosto(a)",
  LIST_FILES: "Listaa tiedostot",
  LIST_FILE: "Listaa tiedosto",
  LIST_ID: "Lista",
  SHEET: "Arkki",
  ROW: "Rivi",
  SHEET_N: "Arkki {{n}}",
  ROW_N: "Rivi {{n}}",
  LIST_FILE_FIELDS_HELP: "Yllä olevat rivit ovat esikatselu sarakkeiden lajittelua varten.",
  WRITE_LISTS: "Kirjoita listat",
  WRITE_LISTS_HELP: "Listat, joihin tuodut yhteystiedot kirjoitetaan",
  CLEAN_LISTS: "Puhdista listat",
  CLEAN_LISTS_HELP: "Listat, joita vastaan yhteystiedot puhdistetaan",
  DIALPAD: "Numeronäppäimistö",
  NO_NOTIFICATIONS: "Sinulla ei ole ilmoituksia!",
  NO_PAUSES_FOUND: "Taukoja ei löytynyt",
  CONNECTION_AT: "Yhteys",
  RETRIES: "Yritykset",
  NO_CONTACT: "Yhteystietoa ei löytynyt!",
  VOICE_MANAGEMENT: "Äänenhallinta",
  JOINED: "Liittynyt",
  JOINED_QUEUE_DESCRIPTION:
    '"Viesti" liittyi jonoon. Tämä portti laukeaa ennen "viestin" jakeluyritystä.',
  ON_ASSIGN: "Määrätty",
  DONE_ANSWERED: "Valmis (vastattu)",
  DONE_NO_ANSWER: "Valmis (ei vastausta)",
  ON_ASSIGN_QUEUE_DESCRIPTION: "Keskustelu määrättiin valittuun jonoon",
  DONE_ANSWERED_QUEUE_DESCRIPTION:
    "Äänipuhelu poistui jonosta päättymisen vuoksi, agentille määräämisen jälkeen",
  DONE_NO_ANSWER_QUEUE_DESCRIPTION:
    "Äänipuhelu poistui jonosta aikakatkaisun tai tyhjän jonon vuoksi (riippuen jonomäärityksistä)",
  HANGUP: "Lopeta puhelu",
  RECORD: "Tallenna",
  HOLD: "Pito",
  UNHOLD: "Poista pito",
  TRANSFER: "Siirrä",
  MUTE: "Mykistä",
  UNMUTE: "Poista mykistys",
  DISTRIBUTING_CALL: "Jaetaan puhelua",
  VOICE_ASTERISK: "Asterisk",
  VOICE_ASTERISKS: "Asteriskit",
  VOICE: "Ääni",
  VOICE_TRUNK: "Runkolinja",
  VOICE_TRUNKS: "Runkolinjat",
  VOICE_ROUTE: "Reitti",
  VOICE_ROUTES: "Reitit",
  SOUND: "Ääni",
  SOUNDS: "Äänet",
  JOURNEY_VARIABLE: "Muuttuja",
  JOURNEY_VARIABLES: "Muuttujat",
  REST: "Rest",
  FROM_DOMAIN: "Lähtödomaini",
  ISO2: "ISO 2",
  VOICE_ASTERISK_ID: "Asterisk",
  INBOUND_VOICE_TRUNK_ID: "Saapuva runkolinja",
  OUTBOUND_VOICE_TRUNK_ID: "Lähtevä runkolinja",
  NUMBER: "Numero",
  INTERNAL: "Sisäinen",
  VOICE_SETTINGS: "Ääniasetukset",
  __OUTBOUND_VOICE_ROUTES: "Lähtevät äänireitit",
  VOICE_RECORDING_FORMAT_WAV: "Wav",
  VOICE_RECORDING_FORMAT_WAV49: "Wav49",
  VOICE_RECORDING_FORMAT_GSM: "Gsm",
  NEW_CALL: "Uusi puhelu",
  START_RECORDING: "Manuaalinen tallennus",
  SETTINGS_START_RECORDING_HELP: "Salli agenttien tallentaa puhelut manuaalisesti",
  PLAY_RECORDING: "Toista tallenne",
  DOWNLOAD_RECORDING: "Lataa tallenne",
  DOWNLOAD_VOICEMAIL: "Lataa ääniviesti",
  PERMISSION_SELF_N: "Ei mitään",
  PERMISSION_SELF_A: "Kaikki",
  PERMISSION_SELF_S: "Käyttäjän omistamat",
  DOWNLOAD_RECORDING_ERROR: "Jotain meni pieleen tallenteen lataamisessa.",
  PLAY_RECORDING_ERROR: "Jotain meni pieleen tallenteen toistamisessa.",
  DOWNLOAD_VOICEMAIL_ERROR: "Jotain meni pieleen ääniviestin lataamisessa.",
  PLAY_VOICEMAIL_ERROR: "Jotain meni pieleen ääniviestin toistamisessa.",
  NO_RESULTS: "Ei tuloksia",
  VARIABLE_ALIAS: "Muuttujan alias",
  VOICE_CALL: "Äänipuhelu",
  CURRENT_VOICE_CALL_BRIDGE: "Nykyinen puhelusilta",
  FIRST_VOICE_CALL_BRIDGE: "Ensimmäinen puhelusilta",
  LAST_VOICE_CALL_BRIDGE: "Viimeinen puhelusilta",
  HANGED_UP: "Lopetti puhelun",
  EDGE_ERROR_FROM_NONE_CHANNEL_SPECIFIC_TO_CHANNEL_SPECIFIC:
    "Linkit kanavakohtaisesta kohdesolmusta ei-kanavakohtaiseen lähdesolmuun eivät ole sallittuja",
  EDGE_ERROR_CHANNEL_DONT_EXIST_IN_FROM_NODE:
    "Kaikkien kohdesolmun kanavien on oltava saatavilla lähdesolmussa",
  EDGE_ERROR_CHANNELS_MISMATCH: "Lähdesolmun kanava ei vastaa kohdesolmun kanavia!",
  JOURNEY_VARIABLE_ID: "Muuttuja",
  REST_REQUEST_VARIABLE_HELP:
    "Jos onnistuu, aseta rest-pyynnön vastaus valittuun muuttujaan. Data muunnetaan merkkijonoksi.",
  REST_REQUEST_FIELD_HELP:
    "Jos onnistuu, aseta rest-pyynnön vastaus valittuun kenttään. Data muunnetaan kenttätyypin mukaiseksi.",
  FIELD_ID: "Kenttä",
  DATA_TYPE: "Datatyyppi",
  ACW: "ACW",
  VOICE_SETTINGS_ACW_HELP: "After call work",
  MUSICCLASS: "Odotusmusiikki",
  JOINEMPTY: "Liity tyhjään",
  FAIL_REASONS: "Epäonnistumisen syyt",
  FULL: "Täysi",
  LEAVEEMPTY: "Jätä tyhjä",
  JOINUNAVAIL: "Liity ei-saatavilla",
  LEAVEUNAVAIL: "Jätä ei-saatavilla",
  EVENTS: "Tapahtumat",
  EVENT_ASSIGNED_DESCRIPTION: "Äänipuhelu määrättiin jonoon",
  EVENT_JOINED_DESCRIPTION: "Äänipuhelu liittyi jonoon",
  FAIL_REASON_FULL_DESCRIPTION: "Päättyi koska jono oli täysi",
  FAIL_REASON_TIMEOUT_DESCRIPTION: "Päättyi koska jonon aikakatkaisu saavutettiin",
  FAIL_REASON_JOINEMPTY_DESCRIPTION: 'Päättyi jonon tilan "JOINEMPTY" vuoksi',
  FAIL_REASON_LEAVEEMPTY_DESCRIPTION: 'Päättyi jonon tilan "LEAVEEMPTY" vuoksi',
  FAIL_REASON_JOINUNAVAIL_DESCRIPTION: 'Päättyi jonon tilan "JOINUNAVAIL" vuoksi',
  FAIL_REASON_LEAVEUNAVAIL_DESCRIPTION: 'Päättyi jonon tilan "LEAVEUNAVAIL" vuoksi',
  VOICE_QUEUE_QUEUE_STATUS: "Jonon tila",
  VOICE_SETTINGS_JOINEMPTY_HELP: "Liity jonoon kun tyhjä",
  LEAVEWHENEMPTY: "Poistu kun tyhjä",
  VOICE_SETTINGS_LEAVEWHENEMPTY_HELP: "Poistu jonosta kun tyhjä",
  RINGINUSE: "Soita varattuun",
  VOICE_SETTINGS_RINGINUSE_HELP: "Määrittää, saako agentti lisää jonopuheluita ollessaan varattu.",
  MAXLEN: "Jonopuheluiden enimmäismäärä",
  VOICE_SETTINGS_MAXLEN_HELP:
    "Jonossa sallittujen puheluiden enimmäismäärä. Tämän rajan ylittävät puhelut jatkavat matkan suoritusstrategiaa.",
  VOICE_SETTINGS_TIMEOUT_HELP:
    "Kuinka kauan puhelun annetaan soida agentille ennen kuin agentti katsotaan tavoittamattomaksi.",
  WEIGHT: "Paino",
  VOICE_SETTINGS_WEIGHT_HELP:
    "Määrittää tämän jonon prioriteetin, kun sen agentit ovat usean jonon jäseniä. Korkeampi painoarvo tarkoittaa, että tämä jono saa etusijan käytettävissä oleviin agentteihin.",
  AUTOPAUSE: "Autopaussi",
  VOICE_SETTINGS_AUTOPAUSE_HELP:
    "Autopaussi asettaa jonon jäsenen tauolle, jos he eivät vastaa puheluun.",
  ANNOUNCE: "Ilmoita",
  VOICE_SETTINGS_ANNOUNCE_HELP:
    "Viesti, joka toistetaan vastaavalle agentille ennen puhelun yhdistämistä, yleensä ilmoittaakseen agentille mikä jono soittaa.",
  ANNOUNCE_FREQUENCY: "Ilmoitustiheys",
  VOICE_SETTINGS_ANNOUNCE_FREQUENCY:
    "Kuinka usein sekunneissa soittajan asema jonossa tai arvioitu odotusaika ilmoitetaan. Aseta 0 poistaaksesi käytöstä.",
  MIN_ANNOUNCE_FREQUENCY: "Vähimmäisilmoitustiheys",
  VOICE_SETTINGS_MIN_ANNOUNCE_FREQUENCY_HELP:
    "Vähimmäisaika kunkin ilmoituksen alun välillä. Tämä on hyödyllinen jonoissa, joissa soittajan asema ja/tai odotusaika muuttuu nopeasti, jotta vältetään jatkuvat ilmoitukset.",
  PERIODIC_ANNOUNCE_FREQUENCY: "Säännöllinen ilmoitustiheys",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_FREQUENCY_HELP: "Kuinka usein säännölliset ilmoitukset tehdään.",
  RANDOM_PERIODIC_ANNOUNCE: "Satunnaiset säännölliset ilmoitukset",
  VOICE_SETTINGS_RANDOM_PERIODIC_ANNOUNCE_HELP:
    "Toista säännölliset ilmoitukset satunnaisessa järjestyksessä.",
  RELATIVE_PERIODIC_ANNOUNCE: "Suhteelliset säännölliset ilmoitukset",
  VOICE_SETTINGS_RELATIVE_PERIODIC_ANNOUNCE_HELP:
    "Jos asetettu kyllä, säännöllinen ilmoitustiheys lasketaan kunkin ilmoituksen lopusta eikä kunkin ilmoituksen alusta.",
  ANNOUNCE_HOLDTIME: "Ilmoita odotusaika",
  VOICE_SETTINGS_ANNOUNCE_HOLDTIME_HELP:
    "Toista arvioitu odotusaika jonossa odottaville soittajille.",
  ANNOUNCE_POSITION: "Ilmoita asema",
  VOICE_SETTINGS_ANNOUNCE_POSITION_HELP:
    'Toista jonoasema jonossa odottaville soittajille. Kun käytät "raja" tai "enemmän" vaihtoehtoja, sinun täytyy myös asettaa Ilmoita aseman raja.',
  ANNOUNCE_POSITION_LIMIT: "Ilmoita aseman raja",
  VOICE_SETTINGS_ANNOUNCE_POSITION_LIMIT_HELP:
    'Määrittää rajan milloin soittajan asema jonossa toistetaan. Jos "Ilmoita asema" on asetettu "raja", asema toistetaan vain rajan alapuolella oleville. Jos asetettu "enemmän", se toistetaan rajan yläpuolella oleville soittajille.',
  ANNOUNCE_POSITION_ONLY_UP: "Ilmoita aseman muutos",
  VOICE_SETTINGS_ANNOUNCE_POSITION_ONLY_UP_HELP:
    "Ilmoita soittajan asema vain jos se on parantunut edellisestä ilmoituksesta.",
  REPORTHOLDTIME: "Raportoi odotusaika",
  VOICE_SETTINGS_REPORTHOLDTIME_HELP:
    "Raportoi odotusaika vastaavalle agentille ennen puhelun yhdistämistä agentille.",
  PERIODIC_ANNOUNCE: "Säännölliset ilmoitukset",
  VOICE_SETTINGS_PERIODIC_ANNOUNCE_HELP:
    "Valitse säännölliset ilmoitukset ja niiden järjestys. Kun viimeinen ilmoitus on toistettu, toisto alkaa alusta.",
  ASTERISK_MUSICCLASS_DEFAULT: "Oletus",
  ASTERISK_MUSICCLASS_CELTIC: "Kelttiläinen",
  ASTERISK_MUSICCLASS_JAZZ: "Jazz",
  ASTERISK_MUSICCLASS_CHILLOUT: "Rentoutus",
  ASTERISK_MUSICCLASS_GOODBYE: "Näkemiin",
  ASTERISK_MUSICCLASS_YESTERDAY: "Eilen",
  ASTERISK_MUSICCLASS_BLUES: "Blues",
  ASTERISK_MUSICCLASS_UPBEAT: "Pirteä",
  ASTERISK_MUSICCLASS_WALTZ: "Valssi",
  ASTERISK_MUSICCLASS_CLAPS: "Taputukset",
  ASTERISK_MUSICCLASS_CHEERFUL: "Iloinen",
  ASTERISK_MUSICCLASS_LULLABY: "Kehtolaulu",
  ASTERISK_MUSICCLASS_HAPPY: "Onnellinen",
  ASTERISK_MUSICCLASS_HIDDEN: "Piilotettu",
  ASTERISK_AUTOPAUSE_YES: "Kyllä",
  ASTERISK_AUTOPAUSE_NO: "Ei",
  ASTERISK_AUTOPAUSE_ALL: "Kaikki",
  ASTERISK_ANNOUNCE_HOLDTIME_YES: "Kyllä",
  ASTERISK_ANNOUNCE_HOLDTIME_NO: "Ei",
  ASTERISK_ANNOUNCE_HOLDTIME_ONCE: "Kerran",
  ASTERISK_ANNOUNCE_POSITION_YES: "Kyllä",
  ASTERISK_ANNOUNCE_POSITION_NO: "Ei",
  ASTERISK_ANNOUNCE_POSITION_LIMIT: "Raja",
  ASTERISK_ANNOUNCE_POSITION_MORE: "Enemmän",
  RECORDING: "Nauhoitus",
  ERROR_CHILD_HAS_ASTERISK:
    "Yhdellä tai useammalla alaorganisaatiolla on yhteys asteriskiin. Tällaisten alaorganisaatioiden yläorganisaatio ei saa olla yhteydessä asteriskiin.",
  ERROR_PARENT_HAS_ASTERISK:
    "Kielletty valita asterisk kun yläorganisaatiolla on yhteys asteriskiin.",
  ERROR_ORGANIZATION_INACTIVATE_WITH_ACTIVE_CHILD:
    "Organisaatiota, jolla on aktiivisia alaorganisaatioita, ei voi deaktivoida. Varmista, että kaikki alaorganisaatiot on deaktivoitu ennen tätä toimenpidettä.",
  VOICE_VOICEMAIL: "Ääniviesti",
  VOICE_VOICEMAILS: "Ääniviestit",
  VOICE_VOICEMAIL_ID: "Ääniviesti",
  TIMES_TO_RETRY: "Uudelleenyrityskerrat",
  MAX_DIGITS: "Maksimi numeromäärä",
  ASTERISK_SOUND_ID: "Ääni",
  GOT_DIGITS: "Sai numerot",
  FAILED: "Epäonnistui",
  CHANSPY: "Kuuntele",
  CHANSPIED_ON: "Kuunneltu",
  VOICE_LICENSE: "Äänilisenssi",
  LOGIN_PAUSE: "Kirjautumispaussi",
  AUTO_ANSWER: "Automaattinen vastaus",
  VOICE_SETTINGS_CHANSPY_HELP: 'Salli muiden käyttäjien kuuntelu, joilla "Kuunneltu" on aktivoitu',
  VOICE_SETTINGS_CHANSPIED_ON_HELP:
    "Salli kuunteluoikeuden omaavien käyttäjien kuunnella tätä käyttäjää",
  TWO_FACTOR_AUTH_HELP:
    "Jätä pois käytöstä lukeaksesi organisaation 2fa-asetukset, aktivoi ohittaaksesi asetukset",
  TWO_FACTOR_AUTH_ENABLE_HELP:
    "Aktivoi/deaktivoi 2fa tälle käyttäjälle ja ohita organisaatioasetukset",
  DELAY: "Viive",
  LEAVE_EMPTY_FOR_ALL_QUEUES: "Jätä tyhjäksi kaikille jonoille",
  AUTO_ACCEPT: "Automaattinen hyväksyntä",
  ENABLE_TO_SELECT_SPECIFIC_QUEUES: "Aktivoi hyväksyäksesi tietyt jonot",
  AUTO_ACCEPT_HELP: "Hyväksy automaattisesti vastaanotetut ilmoitukset",
  ADD_CALL_TO_CONVERSATION: "Lisää puhelu keskusteluun",
  CALL_SEARCH: "Kirjoita numero tai nimi",
  SELECT_VOICE_QUEUE_ROUTE: "Valitse jono & reitti",
  SOMETHING_WENT_WRONG: "Jokin meni pieleen",
  SPY: "Kuuntele",
  NO_CONTACT_SUGGESTIONS_WERE_FOUND: "Yhteystietoehdotuksia ei löytynyt!",
  MAIL_SETTINGS: "Sähköpostiasetukset",
  REPLY_TO: "Vastaa",
  REPLIED_TO: "Vastasi",
  NOTE: "Muistiinpano",
  ANSWER: "Vastaa",
  CALL_RECORDING: "Puhelun nauhoitus",
  OUTBOUND_CALL: "Lähtevä puhelu",
  LEAVE_DISABLED_FOR_DEFAULT: "Jätä pois käytöstä oletusarvoksi",
  SELECT_NUMBER: "Valitse numero",
  RINGING: "Soi",
  STOP: "Pysäytä",
  STOP_RECORDING: "Pysäytä nauhoitus",
  DOWNLOAD: "Lataa",
  RECORDING_CONVERSATION: "Nauhoittaa keskustelua",
  RECORDING_VOICEMAIL: "Nauhoittaa ääniviestiä",
  VOICEMAIL: "Ääniviesti",
  CALL_BACK: "Soita takaisin",
  WAIT_TIME: "Odotusaika",
  TALK_TIME: "Puheaika",
  CALL_LENGTH: "Puhelun pituus",
  MISSED_CALL: "Vastaamaton puhelu",
  LOCAL_PHONE_NUMBER: "Paikallinen puhelinnumero",
  PHONE: "Puhelin",
  PHONE_NUMBER: "Puhelinnumero",
  NOT_ASSIGNED: "Ei määritetty",
  CONVERSATION_ALREADY_ASSIGNED: "Keskustelu on jo määritetty!",
  OUTBOUND_VOICE_QUEUE_ID: "Lähtevä jono",
  OUTBOUND_VOICE_QUEUE_ROUTE_ID: "Lähtevä reitti",
  CONVERSATION_MERGE_FROM: "Tämä keskustelu on yhdistetty keskusteluun {{conversation}}",
  CONVERSATION_MERGE_TO: " yhdistettiin tähän keskusteluun {{conversation}}",
  ON_HOLD: "Pidossa",
  LISTENED_IN: "Kuunneltu",
  LISTEN_ONLY: "Vain kuuntele",
  LISTEN_AND_TALK_TO_AGENT: "Kuuntele & puhu agentille",
  SELECT_AN_OPTION: "Valitse vaihtoehto",
  ONGOING_CALL: "Käynnissä oleva puhelu",
  INTERNAL_NOTE: "Sisäinen muistiinpano",
  VIEW_DETAILS: "Näytä tiedot",
  CONTAINS_TRANSFER_FROM: "Sisältää siirron",
  TRANSFERRED_TO: "Siirretty",
  HAS_NOTE: "Sisältää muistiinpanon",
  NOTE_TO: "Muistiinpano",
  REPLIED_BY: "Vastannut",
  SELECT_QUEUE_TO_SELECT_CONTACT: "Jono täytyy valita ennen yhteystiedon valintaa!",
  CONTACT_NOT_AUTOMATICALLY_ASSIGNED: "Yhteystietoa ei voitu määrittää automaattisesti!",
  NEW_MAIL_MESSAGE: "Uusi sähköpostiviesti",
  UNABLE_TO_PERSONIFY: "Tätä tiliä ei voi personoida. Tämä johtuu todennäköisesti oikeuksistasi.",
  USER_CREATE_USERNAME_TAKEN:
    "Käyttäjänimi, jota yrität käyttää, on jo varattu! Huomaa, että tämä käyttäjänimi voi kuulua toiselle organisaatiolle!",
  USER_CREATE_EMAIL_TAKEN:
    "Sähköpostiosoite, jota yrität käyttää, on jo varattu! Huomaa, että tämä sähköpostiosoite voi kuulua toiselle organisaatiolle!",
  USER_UPDATE_EMAIL_TAKEN:
    "Sähköpostiosoite, jota yrität käyttää, on jo varattu! Huomaa, että tämä sähköpostiosoite voi kuulua toiselle organisaatiolle!",
  SOCKET_DISCONNECTED_USER_ACTIVE_NOT:
    "Järjestelmänvalvoja on poistanut tilisi käytöstä. Sinut kirjataan ulos!",
  MAY_NOT_DELETE_YOURSELF: "Et voi poistaa itseäsi!",
  NO_DATA_CREATED_YET: "Tietoja ei ole vielä luotu!",
  NO_DATA_MATCHING_YOUR_FILTERS: "Suodattimia vastaavia tietoja ei löytynyt!",
  RESET_FILTERS: "Nollaa suodattimet",
  FORM_PREVIEW_NOT_EDITABLE: "Vain esikatselu! Tätä ominaisuutta ei voi muuttaa.",
  AGENTPROFILE_AGENTS: "Agenttiprofiilin agentit",
  ADMINPROFILE_USERS: "Ylläpitoprofiilin käyttäjät",
  QUEUE_AGENTS: "Jonon agentit",
  NO_USERS_WERE_FOUND: "Käyttäjiä ei löytynyt",
  NO_AGENTS_WERE_FOUND: "Agentteja ei löytynyt",
  DRAFT_FROM_MAIL_ACCOUNT_REQUIRED: "Lähettäjän sähköpostitili vaaditaan",
  DRAFT_TO_MAIL_ACCOUNT_REQUIRED: "Vastaanottajan sähköpostiosoite vaaditaan",
  MAIL_MESSAGE_BODY_REQUIRED_ON_OUTBOUND: "Sähköpostiviesti vaaditaan!",
  SMTP_ERROR: "Virhe sähköpostin lähetyksessä",
  RESEND_EMAIL: "Lähetä sähköposti uudelleen",
  RESEND_INITIATED: "Uudelleenlähetys aloitettu",
  MAIL_MESSAGE_NOT_FAILED: "Uudelleenlähetys saatavilla vain epäonnistuneille sähköpostiviesteille",
  NO_SUBJECT: "Ei aihetta",
  NO_CUSTOM_FILTERS_CREATED: "Mukautettuja suodattimia ei ole luotu",
  DRAFT_SEND_CONVERSATION_QUEUE_REQUIRED:
    "Jono täytyy valita keskustelulle ennen luonnoksen lähettämistä",
  MAIL_ACCOUNT_NOT_IN_queueS:
    "Valittu sähköpostitili ei ole käytettävissä jonon lähtevissä sähköpostitileissä",
  MAIL_ACCOUNT_REQUIRED: "Sähköpostitili vaaditaan",
  UNANSWERED_CALL: "Vastaamaton puhelu",
  SELECT_OR_CREATE_CONTACT: "Valitse tai luo yhteystieto",
  ROUTING: "Reititys",
  TRUNK_TYPE_CONNECTEL: "Connectel standardi",
  TRUNK_TYPE_CUSTOM: "Mukautettu trunk",
  MAIL_ACCOUNT_AUTHENTICATION_FAILED: "Sähköpostitilin todennus epäonnistui",
  AUDIO_NOT_PLAYABLE: "Äänitiedostoa ei voi toistaa",
  SIP_STATE: "SIP-tila",
  SIP_STATE_UNDEFINED: "Ei alustettu",
  SIP_STATE_INITIAL: "Yhdistää",
  SIP_STATE_UNREGISTERED: "Rekisteröimätön",
  SIP_STATE_TERMINATED: "Päättynyt",
  SIP_STATE_REGISTERED: "Rekisteröity",
  ACTIVE_IN_QUEUE: "Tällä hetkellä aktiivinen jonossa",
  FILENAME: "Tiedosto",
  PRESSED_NONE_VALID: "Painoi virheellistä vaihtoehtoa",
  PRESSED_1: "Painoi 1",
  PRESSED_2: "Painoi 2",
  PRESSED_3: "Painoi 3",
  PRESSED_4: "Painoi 4",
  PRESSED_5: "Painoi 5",
  PRESSED_6: "Painoi 6",
  PRESSED_7: "Painoi 7",
  PRESSED_8: "Painoi 8",
  PRESSED_9: "Painoi 9",
  "PRESSED_*": "Painoi *",
  "PRESSED_#": "Painoi #",
  INITIATED: "Aloitettu",
  RESET_2FA: "Nollaa kaksivaiheinen tunnistautuminen",
  PASSWORD_RESET_SUCCESSFUL: "Salasanan nollaus onnistui!",
  PASSWORD_RESET_REQUEST_SUCCESSFUL: "Salasanan nollauspyyntö onnistui!",
  PASSWORD_INVALID: "Salasanan täytyy olla vähintään 6 merkkiä pitkä",
  FILL_PASSWORD_TO_RESET: "Täytä salasana nollataksesi",
  RESET_TOKEN_HAS_EXPIRED: "Virheellinen tai vanhentunut token. Pyydä uusi salasanan nollaus.",
  VOICE_TIMELINE_ERROR: "Virhe",
  INBOUND_CALL_INITIATED: "Saapuva puhelu aloitettu",
  OUTBOUND_CALL_INITIATED: "Lähtevä puhelu aloitettu",
  INTERNAL_CALL_INITIATED: "Sisäinen puhelu aloitettu",
  SYSTEM_ANSWER: "Järjestelmä vastaanotti puhelun",
  TIMELINE_JOURNEY_JOB: "Journey-toiminto",
  RULE_WITH_INDEX_X_MATCHED: 'Sääntö indeksillä "{{i}}" täsmäsi',
  TRY_X_BUTTON_X_PRESSED: 'Yritys {{try}}: Painike "{{num}}" painettu',
  TRY_X_DIGITS_X_PRESSED: 'Yritys {{try}}: Numerot "{{num}}" painettu',
  USER_X_PINGED: 'Agentti "{{user}}" pingattu',
  USER_X_NOTIFICATION_END: 'Agentti "{{user}}" ping päättyi',
  NOTIFICATION_X_ACTION: "Toiminto: {{action}}",
  TIMEOUT_X_SECONDS: "Aikakatkaisu: {{sec}} sekuntia",
  NOTIFICATION_TIMEOUT_X_SECONDS: "Ilmoituksen aikakatkaisu: {{sec}} sekuntia",
  VOICE_QUEUE_ASSIGNED: "Määrätty puhelujonoon",
  VOICE_QUEUE_JOINED: "Liittyi puhelujonoon",
  VOICE_QUEUE_DONE: "Poistui puhelujonosta",
  NOTIFICATION_ACTION_ACCEPT: "Hyväksytty",
  NOTIFICATION_ACTION_ACCEPT_FAIL: "Hyväksyntä epäonnistui",
  NOTIFICATION_ACTION_REJECT: "Hylätty",
  NOTIFICATION_ACTION_REJECT_FAIL: "Hylkäys epäonnistui",
  NOTIFICATION_ACTION_TIMEOUT: "Aikakatkaisu",
  NOTIFICATION_ACTION_ANSWERED_ELSEWHERE: "Vastattu muualla",
  NOTIFICATION_ACTION_SYSTEM_HANGED_UP: "Järjestelmä katkaisi (aikakatkaisu)",
  NOTIFICATION_ACTION_CONTACT_HANGED_UP: "Yhteyshenkilö katkaisi ennen aikakatkaisua/vastausta",
  RECORDING_STARTED_BY_X: 'Nauhoitus aloitettu käyttäjän "{{user}}" toimesta',
  RECORDING_STOPPED_BY_X: 'Nauhoitus pysäytetty käyttäjän "{{user}}" toimesta',
  SYSTEM: "Järjestelmä",
  X_LEFT_CALL: "{{leg}} poistui puhelusta",
  LEFT_CALL_WITH_REASON: 'Poistui sillasta syyllä "{{reason}}"',
  LEFT_QUEUE_WITH_REASON: 'Poistui jonosta "{{queue}}" syyllä "{{reason}}"',
  UNKNOWN: "Tuntematon",
  USER_ANSWERED: '"{{user}}" vastasi',
  QUEUE_X_WITH_TIMEOUT: 'Jono "{{queue}}", {{timeout}} sekunnin aikakatkaisulla',
  AVAILABLE_VOICE_MENU_OPTIONS:
    'Käytettävissä olevat vaihtoehdot "{{options}}", ja "{{retries}}" yritystä',
  CALL_TRANSFERRED_OUT: "Puhelu siirretty ulos",
  CALL_TRANSFERRED_IN: "Puhelu siirretty sisään",
  ATTENDED: "Valvottu",
  BLIND: "Sokea",
  TRANSFER_TYPE_X: 'Siirtotyyppi "{{type}}"',
  ROUTE_X: 'Reitti "{{route}}"',
  CONTACT_ANSWERED: "Yhteyshenkilö vastasi",
  BG_COLOR: "Taustaväri",
  SUB_GROUP: "Alaryhmä",
  VARIABLE: "Muuttuja",
  STATIC: "Staattinen",
  KEY: "Avain",
  ESCAPE_DIGITS: "Escape-numero",
  LANGUAGE: "Kieli",
  LANGUAGE_SE: "se (Ruotsi)",
  LANGUAGE_FI: "fi (Suomi)",
  LANGUAGE_EN: "en (Englanti)",
  LANGUAGE_DE: "de (Saksa)",
  LANGUAGE_FR: "fr (Ranska)",
  LANGUAGE_HI: "hi (Hindi)",
  LANGUAGE_IT: "it (Italia)",
  LANGUAGE_NO: "no (Norja)",
  LANGUAGE_NL: "nl (Hollanti)",
  LANGUAGE_DK: "dk (Tanska)",
  LANGUAGE_PL: "pl (Puola)",
  VOICE_LANGUAGE_SE: "se (Ruotsi)",
  VOICE_LANGUAGE_FI: "fi (Suomi)",
  VOICE_LANGUAGE_EN: "en (Englanti)",
  VOICE_LANGUAGE_DE: "de (Saksa)",
  VOICE_LANGUAGE_FR: "fr (Ranska)",
  VOICE_LANGUAGE_HI: "hi (Hindi)",
  VOICE_LANGUAGE_IT: "it (Italia)",
  VOICE_LANGUAGE_NO: "no (Norja)",
  VOICE_LANGUAGE_NL: "nl (Hollanti)",
  VOICE_LANGUAGE_DK: "dk (Tanska)",
  VOICE_LANGUAGE_PL: "pl (Puola)",
  VOICE_SHORTCUTS: "Äänipikavalinnat",
  VOICE_SHORTCUT: "Äänipikavalikko",
  __VOICE_SHORTCUTS: "Pikavalintanumerot",
  HEADER_FILTERS_COUNT: "Nollaa {{count}} suodatinta",
  REAUTHENTICATE_ACCOUNT: "Todenna uudelleen",
  IS_LIVE: "Live",
  IS_SHARED: "Jaettu",
  SHARED: "Jaettu",
  WITH: "Kanssa",
  ADMINPROFILE_NO_FILTER_ON_CREATION:
    "Järjestelmänvalvojan profiilioikeuksilla ei saa olla suodatinta luonnin yhteydessä. Luo järjestelmänvalvojan profiili ilman suodatinta ja lisää suodatin muokkauksen yhteydessä!",
  ADMINPROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Järjestelmänvalvojan profiilin suodattimen on sisällettävä itsensä sallituille oikeuksille!",
  SUPERADMIN_PROFILE_NO_FILTER_ON_CREATION:
    "Pääkäyttäjän profiilioikeuksilla ei saa olla suodatinta luonnin yhteydessä. Luo pääkäyttäjän profiili ilman suodatinta ja lisää suodatin muokkauksen yhteydessä!",
  SUPERADMIN_PROFILE_ALLOW_FILTER_MUST_CONTAIN:
    "Pääkäyttäjän profiilin suodattimen on sisällettävä itsensä sallituille oikeuksille!",
  AUDIO_SETTINGS: "Ääniasetukset",
  SELECT_RINGTONE_SPEAKER: "Valitse soittoäänikaiutin",
  RINGTONE_SPEAKER: "Soittoäänikaiutin",
  SELECT_VOICE_SPEAKER: "Valitse äänikaiutin",
  VOICE_SPEAKER: "Äänikaiutin",
  SELECT_MICROPHONE: "Valitse mikrofoni",
  ERROR_SETTING_SPEAKER:
    "Kaiuttimen asettamisessa tapahtui virhe, valitse toinen kaiutin ennen jatkamista!",
  ERROR_SETTING_RINGTONE_SPEAKER:
    "Soittoäänikaiuttimen asettamisessa tapahtui virhe, valitse toinen kaiutin ennen jatkamista!",
  NO_SPEAKERS_FOUND: "Kaiuttimia ei löytynyt!",
  NO_MICROPHONES_FOUND: "Mikrofoneja ei löytynyt!",
  SELECTED_RINGTONE_SPEAKER_NOT_FOUND:
    'Valittua soittoäänikaiutinta ei löytynyt! Vaihdoimme soittoäänikaiuttimesi "järjestelmän oletukseksi" kunnes teet muutoksen!',
  SELECTED_SPEAKER_NOT_FOUND:
    'Valittua kaiutinta ei löytynyt! Vaihdoimme kaiuttimesi "järjestelmän oletukseksi" kunnes teet muutoksen!',
  SELECTED_MICROPHONE_NOT_FOUND:
    'Valittua mikrofonia ei löytynyt! Vaihdoimme mikrofonisi "järjestelmän oletukseksi" kunnes teet muutoksen!',
  DEFAULT_MICROPHONE: "Oletusmikrofoni",
  DEFAULT_SPEAKER: "Oletuskaiutin",
  BROWSER_DOES_NOT_SUPPORT_SPEAKER_SET: "Selaimesi ei tue kaiuttimen valintaa",
  UPLOAD_RECORDING: "Lataa äänitys",
  RESTART: "Käynnistä uudelleen",
  RECORD_VOICE: "Äänitä ääni",
  RECORDING_UPLOAD_SUCCESSFULL: "Lataus onnistui",
  RECORDING_UPLOAD_ERROR: "Latauksessa tapahtui virhe",
  WE_COULD_NOT_DETECT_SOUND_FROM_MICROPHONE: "Emme havainneet ääntä mikrofonistasi",
  ECHO_TEST: "Laitetesti",
  START: "Aloita",
  START_TEST: "Aloita testi",
  STOP_TEST: "Lopeta testi",
  ECHO_TEST_SOUND_QUESTION: "Kuuletko äänen?",
  ECHO_TEST_MICROPHONE_QUESTION: "Kuuletko kaiun?",
  ECHO_TEST_PASSED: "Hyvä. Kaiuttimesi ja mikrofonisi näyttävät toimivan odotetusti!",
  PLEASE_CONFIRM_FOLLOWING: "Vahvista seuraavat",
  PERIOD: "Jakso",
  TREND: "Trendi",
  ADJUST: "Säädä",
  TEXT_INDICATOR: "Indikaattori",
  METRICS: "Mittarit",
  GROUP_BY: "Ryhmittele",
  SUB_GROUP_BY: "Alaryhmittele",
  SORT_BY: "Lajittele",
  DESC: "Laskeva",
  SPLIT_BY: "Jaa",
  SUMMARY: "Yhteenveto",
  MAIN_CHANNELS: "Main channels",
  THREAD_CHANNELS: "Thread channels",
  MESSAGE_CHANNELS: "Message channels",
  ORGANIZATION_IDS: "Organizations",
  CONVERSATION_USER_IDS: "Conversation users",
  THREAD_USER_IDS: "Thread users",
  MESSAGE_USER_IDS: "Message users",
  CONVERSATION_QUEUE_IDS: "Conversation queues",
  THREAD_QUEUE_IDS: "Thread queues",
  MESSAGE_QUEUE_IDS: "Message queues",
  DISPOSITION_IDS: "Dispositions",
  SUB_DISPOSITION_IDS: "Sub dispositions",
  THRD_DISPOSITION_IDS: "Third dispositions",
  PAUSE_IDS: "Tauot",
  TEAM_IDS: "Tiimit",
  THREAD_TEAM_IDS: "Thread teams",
  MESSAGE_TEAM_IDS: "Viestiryhmät",
  MAIL_ACCOUNT_IDS: "Sähköpostitilit",
  VOICE_ROUTE_IDS: "Äänireitit",
  UI: "Käyttöliittymä",
  TABLE: "Taulukko",
  GROUP: "Ryhmä",
  ODD_ROW: "Pariton rivi",
  EVEN_ROW: "Parillinen rivi",
  WIDTH: "Leveys",
  COLOR: "Väri",
  BORDER: "Reuna",
  DISPLAY_HEADER: "Näytä otsikko",
  LEGEND: "Selite",
  PLACEMENT: "Sijoitus",
  CUTOUT: "Leikkaus",
  NESTED_METRICS: "Mittarit",
  SUBTRACT: "Vähennä",
  METRIC: "Mittari",
  VALUE_ARR: "Vaihtoehdot",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DATA: "Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFDATA: "Ero",
  STATS_WIDGET_TABLE_TREND_INDICATOR_DIFFPERCENTAGE: "Prosentti",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROW: "Nuoli",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDATA: "Nuoli + Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFDATA: "Nuoli + Ero",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ARROWDIFFPERCENTAGE: "Nuoli + Prosentti",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICON: "Kuvake",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDATA: "Kuvake + Data",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFDATA: "Kuvake + Ero",
  STATS_WIDGET_TABLE_TREND_INDICATOR_ICONDIFFPERCENTAGE: "Kuvake + Prosentti",
  STATS_WIDGET_TABLE_TREND_INDICATOR_BACKGROUND: "Tausta",
  STATS_WIDGET_TYPE_TABLE: "Taulukko",
  STATS_WIDGET_TYPE_GRID: "Ruudukko",
  STATS_WIDGET_TYPE_BAR: "Pylväs",
  STATS_WIDGET_TYPE_LINE: "Viiva",
  STATS_WIDGET_TYPE_TEXT: "Teksti",
  STATS_WIDGET_TYPE_PIE: "Ympyrä",
  STATS_WIDGET_TYPE_RADAR: "Tutka",
  STATS_WIDGET_TYPE_POLAR: "Polaarinen",
  STATS_WIDGET_TYPE_TIMELINE: "Aikajana",
  STATS_WIDGET_PERIOD_THIS_MONTH: "Tämä kuukausi",
  STATS_WIDGET_PERIOD_LIVE_TODAY: "Tänään (Live)",
  STATS_WIDGET_PERIOD_TODAY: "Tänään",
  STATS_WIDGET_PERIOD_LAST_24_HOURS: "Viimeiset 24 tuntia",
  STATS_WIDGET_PERIOD_YESTERDAY: "Eilen",
  STATS_WIDGET_PERIOD_THIS_WEEK: "Tämä viikko",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN: "Tämä viikko (alku sunnuntai)",
  STATS_WIDGET_PERIOD_THIS_WEEK_EXCEPT_TODAY: "Tämä viikko (paitsi tänään)",
  STATS_WIDGET_PERIOD_THIS_WEEK_SUN_EXCEPT_TODAY: "Tämä viikko (alku sunnuntai, paitsi tänään)",
  STATS_WIDGET_PERIOD_LAST_WEEK: "Viime viikko",
  STATS_WIDGET_PERIOD_LAST_7_DAYS: "Viimeiset 7 päivää",
  STATS_WIDGET_PERIOD_THIS_MONTH_EXCEPT_TODAY: "Tämä kuukausi (paitsi tänään)",
  STATS_WIDGET_PERIOD_LAST_30_DAYS: "Viimeiset 30 päivää",
  STATS_WIDGET_PERIOD_LAST_MONTH: "Viime kuukausi",
  STATS_WIDGET_PERIOD_CUSTOM: "Mukautettu",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_DAY: "Päivä(t)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_WEEK: "Viikko(a)",
  STATS_WIDGET_TRENDS_ADJUST_UNIT_MONTH: "Kuukausi(a)",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GT: "Suurempi kuin",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_GTE: "Suurempi tai yhtä suuri kuin",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LT: "Pienempi kuin",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_LTE: "Pienempi tai yhtä suuri kuin",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NOT_EQUAL_NUM: "Ei yhtä suuri kuin",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_EQUAL_NUM: "Yhtä suuri kuin",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_ANY_OF_NUM: "Mikä tahansa valituista",
  STATS_WIDGET_FILTER_METRIC_COMPARISON_NONE_OF_NUM: "Ei mikään valituista",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT: "Suurempi kuin",
  STATS_WIDGET_THRESHOLD_COMPARISON_GT_TREND: "Suurempi kuin trendiarvo",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE: "Suurempi tai yhtä suuri kuin",
  STATS_WIDGET_THRESHOLD_COMPARISON_GTE_TREND: "Suurempi tai yhtä suuri kuin trendiarvo",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT: "Pienempi kuin",
  STATS_WIDGET_THRESHOLD_COMPARISON_LT_TREND: "Pienempi kuin trendiarvo",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE: "Pienempi tai yhtä suuri kuin",
  STATS_WIDGET_THRESHOLD_COMPARISON_LTE_TREND: "Pienempi tai yhtä suuri kuin trendiarvo",
  STATS_WIDGET_THRESHOLD_COMPARISON_NOT_EQUAL_NUM: "Ei yhtä suuri kuin",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM: "Yhtä suuri kuin",
  STATS_WIDGET_THRESHOLD_COMPARISON_EQUAL_NUM_TREND: "Yhtä suuri kuin trendiarvo",
  STATS_WIDGET_THRESHOLD_COMPARISON_ANY_OF_NUM: "Mikä tahansa valituista",
  STATS_WIDGET_THRESHOLD_COMPARISON_NONE_OF_NUM: "Ei mikään valituista",
  METRIC_FORMAT_STATIC: "Staattinen arvo",
  METRIC_FORMAT_ValuePCS: "Kpl (Kappaletta)",
  METRIC_FORMAT_ValuePercent: "% (Prosentti)",
  METRIC_FORMAT_ValueDecimal: "Desimaali",
  METRIC_FORMAT_SECONDS_TO_HH_MM: "TT:mm",
  METRIC_FORMAT_HH_MM: "TT:mm",
  METRIC_FORMAT_SECONDS_TO_HH_MM_SS: "TT:mm:ss",
  METRIC_FORMAT_SECONDS_TO_MM_SS: "mm:ss",
  METRIC_FORMAT_HH_MM_SS: "TT:mm:ss",
  METRIC_FORMAT_COUNTDOWN: "Laskuri",
  METRIC_FORMAT_MM_SS: "mm:ss",
  METRIC_FORMAT_YEAR_MONTH_SHORT: "Vuosi Kuukausi (lyhyt)",
  METRIC_FORMAT_YEAR_MONTH_LONG: "Vuosi Kuukausi",
  METRIC_FORMAT_MONTH_SHORT: "Kuukausi (lyhyt)",
  METRIC_FORMAT_MONTH_LONG: "Kuukausi",
  METRIC_FORMAT_YEAR_WEEK_NUMBER: "Vuosi Viikkonumero",
  METRIC_FORMAT_WEEK_NUMBER: "Viikkonumero",
  METRIC_FORMAT_D_SLASH_M: "Päivä/Kuukausi",
  METRIC_FORMAT_D_SLASH_M_HH_mm: "Päivä/Kuukausi TT:mm",
  METRIC_FORMAT_YYYYMMDD: "VVVVKKPP",
  METRIC_FORMAT_YYYY_MM_DD: "VVVV/KK/PP",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm: "VVVV/KK/PP TT:mm",
  METRIC_FORMAT_YYYY_MM_DD_HH_mm_ss: "VVVV/KK/PP TT:mm:ss",
  METRIC_FORMAT_HOUR_OF_DAY_24H: "Päivän tunti (24H)",
  METRIC_FORMAT_HOUR_OF_DAY_12H: "Päivän tunti (12H)",
  METRIC_FORMAT_WEEKDAY_SHORT: "Viikonpäivä (lyhyt)",
  METRIC_FORMAT_WEEKDAY_LONG: "Viikonpäivä",
  METRIC_FORMAT_HH_mm: "TT:mm",
  METRIC_FORMAT_mm_ss: "mm:ss",
  METRIC_FORMAT_HH_mm_ss: "TT:mm:ss",
  W_WEEK_SHORT: "V",
  PM: "ip.",
  AM: "ap.",
  MONTH_SHORT_1: "Tam",
  MONTH_SHORT_2: "Hel",
  MONTH_SHORT_3: "Maa",
  MONTH_SHORT_4: "Huh",
  MONTH_SHORT_5: "Tou",
  MONTH_SHORT_6: "Kes",
  MONTH_SHORT_7: "Hei",
  MONTH_SHORT_8: "Elo",
  MONTH_SHORT_9: "Syy",
  MONTH_SHORT_10: "Lok",
  MONTH_SHORT_11: "Mar",
  MONTH_SHORT_12: "Jou",
  MONTH_LONG_1: "Tammikuu",
  MONTH_LONG_2: "Helmikuu",
  MONTH_LONG_3: "Maaliskuu",
  MONTH_LONG_4: "Huhtikuu",
  MONTH_LONG_5: "Toukokuu",
  MONTH_LONG_6: "Kesäkuu",
  MONTH_LONG_7: "Heinäkuu",
  MONTH_LONG_8: "Elokuu",
  MONTH_LONG_9: "Syyskuu",
  MONTH_LONG_10: "Lokakuu",
  MONTH_LONG_11: "Marraskuu",
  MONTH_LONG_12: "Joulukuu",
  WEEKDAY_SHORT_6: "Su",
  WEEKDAY_SHORT_0: "Ma",
  WEEKDAY_SHORT_1: "Ti",
  WEEKDAY_SHORT_2: "Ke",
  WEEKDAY_SHORT_3: "To",
  WEEKDAY_SHORT_4: "Pe",
  WEEKDAY_SHORT_5: "La",
  WEEKDAY_LONG_6: "Sunnuntai",
  WEEKDAY_LONG_0: "Maanantai",
  WEEKDAY_LONG_1: "Tiistai",
  WEEKDAY_LONG_2: "Keskiviikko",
  WEEKDAY_LONG_3: "Torstai",
  WEEKDAY_LONG_4: "Perjantai",
  WEEKDAY_LONG_5: "Lauantai",
  ONGOING_CALLS: "Käynnissä olevat puhelut",
  RECORDING_NEEDS_TO_BE_UPLOADED: "Varmista, että lataat äänityksen!",
  RECORDING_STARTED: "Äänitys aloitettu",
  RECORDING_STOPPED: "Äänitys pysäytetty",
  DUE_DATE: "Eräpäivä",
  MAIL_ACCOUNT_NOT_IN_QUEUES:
    "Valittu sähköpostitili ei ole käytettävissä lähtevänä sähköpostitilinä nykyiselle keskustelujonolle",
  NO_EMAIL_ON_CONTACT: "Yhteystiedoissa ei ole sähköpostiosoitteita vastaamiseen!",
  FORWARD: "Välitä eteenpäin",
  LEFT_BRIDGE: "Silta päättynyt",
  DESTINATION_PHONE: "Kohde {{phone}}",
  PARAMS_PHONE: "Puhelin: {{phone}}",
  PARAMS_JOURNEY: "Matka: {{journey}}",
  CALLEE_ANSWERED: "Vastaanottaja vastasi",
  SOCKET_CONNECTION_ERROR: 'Virhe websocket-yhteydessä: "{{error}}"',
  CONTACT_FORM_HAS_NO_FIELDS:
    "Yhteystietolomakkeessa ei ole kenttiä! Järjestelmänvalvojan on lisättävä kenttiä valittuun jonoon liitettyyn yhteystietolomakkeeseen (Luo lista)",
  NOTIFICATION_REQUEST_NOT_SUPPORTED: "Selaimesi ei tue ilmoituksia, vaihda selainta!",
  NOTIFICATION_REQUEST_DENIED:
    "Olet estänyt selainilmoitukset. Varmista, että selainilmoitukset ovat sallittuja!",
  NOTIFICATION_REQUEST_CATCH: "Virhe pyydettäessä selainilmoitusoikeuksia! {{error}}",
  INACTIVITY_READY: "Kirjaudu ulos valmis-tilassa",
  INACTIVITY_PAUSE: "Kirjaudu ulos tauko-tilassa",
  PDF: "pdf",
  IMAGE: "kuva",
  STATS_WIDGET: "Widget",
  ADVANCED_OPTIONS: "Lisäasetukset",
  HIDE: "Piilota",
  NO_SPECIFIC_USER: "Ei käyttäjää",
  NO_SPECIFIC_QUEUE: "Ei jonoa",
  NO_SPECIFIC_DISPOSITION: "Ei luokittelua",
  NO_SPECIFIC_PAUSE: "Ei taukoa",
  NO_SPECIFIC_DIRECTION: "Ei suuntaa",
  NO_SPECIFIC_CHANNEL: "Ei kanavaa",
  NO_SPECIFIC_ORGANIZATION: "Ei organisaatiota",
  NO_SPECIFIC_CLIENT: "Ei asiakasta",
  NO_SPECIFIC_ACCOUNT: "Ei tiliä",
  USER_ID_X: "Käyttäjä-id {{x}}",
  QUEUE_ID_X: "Jono-id {{x}}",
  DISPOSITION_ID_X: "Luokittelu-id {{x}}",
  ORGANIZATION_ID_X: "Organisaatio-id {{x}}",
  CLIENT_ID_X: "Asiakas-id {{x}}",
  ACCOUNT_ID_X: "Tili-id {{x}}",
  PAUSE_ID_X: "Tauko-id {{x}}",
  ALIGNMENT: "Tasaus",
  THRESHOLDS: "Raja-arvot",
  BOX_COLOR: "Laatikon väri",
  FONT_STYLE: "Tekstityyli",
  FORMAT: "Muoto",
  USER_SELF: "Personoitu data",
  FONT_STYLE_ITALIC: "Kursiivi",
  FONT_STYLE_BOLD: "Lihavoitu",
  "FONT_STYLE_BOLD-ITALIC": "Lihavoitu-kursiivi",
  ALIGNMENT_CENTER: "Keskitetty",
  ALIGNMENT_LEFT: "Vasen",
  ALIGNMENT_RIGHT: "Oikea",
  BLINK: "Vilku",
  DECIMALS: "Desimaalit",
  DIRECTION: "Suunta",
  THREAD_DIRECTION: "Viestiketjun suunta",
  MESSAGE_DIRECTION: "Viestin suunta",
  CONVERSATION_MAIN_CHANNELS: "Keskustelun pääkanavat",
  CONVERSATION_DIRECTION: "Keskustelun suunnat",
  EDIT_ITEM: "Muokkaa kohdetta",
  OF_TOTAL: "Kokonaismäärästä",
  GROUP_POPULAR: "Suositut",
  GROUP_OTHERS: "Muut",
  GROUP_CUSTOM: "Mukautetut",
  GROUP_LIVE: "Live",
  STATS_CUSTOM_METRICS: "Mukautetut mittarit",
  STATS_CUSTOM_METRIC: "Mukautettu mittari",
  METRIC_QUERY_TYPE_SUM: "Addition",
  METRIC_QUERY_TYPE_DIFF: "Subtraction",
  METRIC_QUERY_TYPE_PERCENT: "Percent",
  METRIC_QUERY_TYPE_AVG: "Keskiarvo",
  METRIC_QUERY_TYPE_MAX: "Maximum",
  METRIC_QUERY_TYPE_MIN: "Minimum",
  METRIC_QUERY_TYPE_LEAST: "Vähiten",
  METRIC_QUERY_TYPE_GREATEST: "Eniten",
  METRIC_QUERY_TYPE_STATIC: "Staattinen",
  METRIC_QUERY_TYPE_ADD: "Addition",
  QUERY_TYPE: "Kyselytyyppi",
  DASHBOARDS: "Kojelaudat",
  DASHBOARD: "Kojelauta",
  REPORTS: "Raportit",
  REPORT: "Raportti",
  CREATE_DASHBOARD: "Luo kojelauta",
  CREATE_REPORT: "Luo raportti",
  EDIT_DASHBOARD: "Muokkaa kojelautaa",
  EDIT_REPORT: "Muokkaa raporttia",
  ALL_DASHBOARDS: "Kaikki kojelaudat",
  SHARED_WITH_ME: "Jaettu minulle",
  PREBUILD_DASHBOARDS: "Valmiit kojelaudat",
  ALL_REPORTS: "Kaikki raportit",
  PREBUILD_REPORTS: "Valmiit raportit",
  ADD_YOUR_FIRST_DASHBOARD: "Lisää ensimmäinen kojelautasi",
  ADD_YOUR_FIRST_DASHBOARD_DESCRIPTION:
    "Aloita uudella tyhjällä pohjalla tai valitse jokin valmiista kojelaudoista",
  NO_SHARED_DASHBOARDS: "Ei jaettuja kojelautoja",
  ADD_YOUR_PREBUILT_DASHBOARD: "Lisää valmis kojelauta",
  ADD_YOUR_PREBUILT_DASHBOARD_DESCRIPTION: "Luo oma kojelautasi valmiista kojelaudasta",
  ADD_YOUR_FIRST_REPORT: "Lisää ensimmäinen raporttisi",
  ADD_YOUR_FIRST_REPORT_DESCRIPTION:
    "Aloita uudella tyhjällä pohjalla tai valitse jokin valmiista raporteista",
  NO_SHARED_REPORTS: "Ei jaettuja raportteja",
  ADD_YOUR_PREBUILT_REPORT: "Lisää valmis raportti",
  ADD_YOUR_PREBUILT_REPORT_DESCRIPTION: "Luo oma raporttisi valmiista raportista",
  NO_DASHBOARDS: "Ei kojelautoja",
  NO_REPORTS: "Ei raportteja",
  CREATE_FROM_THIS_TEMPLATE: "Luo tästä mallista",
  TIME_THRESHOLD: "Aikaraja",
  AFTER: "Jälkeen",
  CUSTOM_METRIC: "Mukautettu mittari",
  STATS_CUSTOM_METRIC_ID: "Mukautettu mittari",
  HEIGHT: "Korkeus",
  STATS_USER_DATA_HELP: "Personoitu data näyttää personoidut arvot tälle mittarille.",
  STATS_OF_TOTAL_HELP: "Tämän mittarin prosenttiosuus verrattuna kokonaisarvoon.",
  STATS_REPORTS: "Raportit & kojelaudat",
  STATS_WIDGETS: "Widgetit",
  OWNER: "Omistaja",
  IS_DASHBOARD: "On kojelauta",
  STATS_REPORT: "Raportti",
  ADD_WIDGET: "Lisää widget",
  FULLSCREEN: "Koko näyttö",
  CLONED: "Kloonattu",
  STATS_WIDGET_LEGEND_PLACEMENT_LEFT: "Vasen",
  STATS_WIDGET_LEGEND_PLACEMENT_RIGHT: "Oikea",
  STATS_WIDGET_LEGEND_PLACEMENT_TOP: "Ylä",
  STATS_WIDGET_LEGEND_PLACEMENT_BOTTOM: "Ala",
  CIRCLE_TOTAL: "Ympyrä yhteensä",
  TOTAL: "Yhteensä",
  BORDER_COLOR: "Reunaväri",
  LAST_DATA_AT: "Viimeisin data",
  HIDE_TITLE: "Piilota otsikko",
  OPEN_DASHBOARD: "Avaa kojelauta",
  ADD_PREVIEW_WIDGET: "Lisää esikatseluwidget",
  SELECT_REPORT: "Valitse raportti",
  DEFAULT: "Oletus",
  CLONE: "Kloonaa",
  DRAG_n_MOVE: "Vedä & Siirrä",
  DOWN: "Alas",
  UP: "Ylös",
  EXISTING: "Olemassa oleva",
  SELECT_WIDGET: "Valitse widget",
  ZOOM_IN: "Lähennä",
  ZOOM_OUT: "Loitonna",
  RELOAD: "Lataa uudelleen",
  RELOAD_DATA: "Lataa data uudelleen",
  DISPLAY_VALUES: "Näytä arvot",
  WIDGET_NOT_FOUND: "Widgetiä ei löydy!",
  WIDGET_NOT_FOUND_DESCRIPTION: "Widget on poistettu tai sitä ei enää jaeta kanssasi!",
  NO_WIDGETS: "Ei widgettejä!",
  ADD_WIDGET_TO_REPORT: "Lisää widget raporttiin",
  ADD_WIDGET_TO_DASHBOARD: "Lisää widget kojelautaan",
  REMOVE: "Poista",
  REMOVE_ITEM: "Poista kohde",
  ADDITION: "Yhteenlasku",
  SUBTRACTION: "Vähennyslasku",
  DIVISION: "Jakolasku",
  CHANNELS_SETTINGS: "Kanavat asetuksista",
  DIRECTION_SETTINGS: "Suunta asetuksista",
  THREAD_CHANNELS_SETTINGS: "Viestiketjukanavat asetuksista",
  MESSAGE_CHANNELS_SETTINGS: "Viestikanavat asetuksista",
  THREAD_DIRECTION_SETTINGS: "Viestiketjun suunta asetuksista",
  MESSAGE_DIRECTION_SETTINGS: "Viestin suunta asetuksista",
  SELECT_EXISTING: "Valitse olemassa oleva",
  EXISTING_WIDGETS: "Olemassa olevat widgetit",
  NEW_WIDGET: "Uusi widget",
  TEMPLATES: "Mallit",
  SELECT_TEMPLATE: "Valitse malli",
  USE_TEMPLATE: "Käytä mallia",
  SEARCH_TEMPLATES: "Etsi malleja",
  SEARCH_WIDGETS: "Etsi widgettejä",
  WIDGET_TEMPLATES: "Widget-mallit",
  WIDGET: "Widget",
  EDIT_WIDGET: "Muokkaa widgettiä",
  SELECT_DASHBOARD: "Valitse kojelauta",
  REPORT_NOT_SELECTED: "Raporttia ei ole valittu!",
  DASHBOARD_NOT_SELECTED: "Kojelautaa ei ole valittu!",
  TREND_VALUE: "Trendin arvo",
  TREND_PERIOD: "Trendin jakso",
  MY_CONVERSATIONS: "Omat keskustelut",
  MY_AND_UNASSIGNED_CONVERSATIONS: "Omat & kohdistamattomat keskustelut",
  CUSTOMER_RESPONDED: "Asiakas vastasi",
  NO_UPDATE_IN_7_DAYS: "Ei päivityksiä 7 päivään",
  CREATED_BY_ME: "Minun luomani",
  ALL_UNRESOLVED: "Kaikki ratkaisemattomat",
  ALL_RESOLVED: "Kaikki ratkaistut",
  ALL_CONVERSATIONS: "Kaikki keskustelut",
  DIRECTIONS: "Suunnat",
  INTERNAL_HOST: "Sisäinen isäntä",
  VOICE_ASTERISK_DOWNGRADE_ID: "Asterisk-alentaminen",
  VOICE_ASTERISK_DOWNGRADES: "Asterisk-alentamiset",
  VOICE_ASTERISK_DOWNGRADE: "Asterisk-alentaminen",
  DOWNGRADED: "Alennettu",
  __IS_DOWNGRADED: "On alennettu",
  VOICE_ASTERISK_OPERATIONAL_HOURS: "Asterisk käyttötunnit",
  VOICE_ASTERISK_OPERATIONAL_HOURSES: "Asterisk käyttötunnit",
  UTC: "UTC",
  VOICE_ASTERISK_TOGGLE_HISTORY: "Asterisk vaihtohistoria",
  VOICE_ASTERISK_TOGGLE_HISTORIES: "Asterisk vaihtohistoriat",
  INITIAL: "Alustava",
  DID_TOGGLE: "Vaihtoi",
  DATE: "Päivämäärä",
  X_ONGOING_CALLS: "{{calls}} käynnissä olevaa puhelua",
  X_USER_STATUSES: "{{users}} yhdistettyä käyttäjää",
  PREVENTED_ASTERISK_TOGGLE: "esti Asteriskin vaihtamisen",
  BOOTING_UP: "Käynnistyy",
  DOWNGRADE_ASTERISK_OFFLINE: "Alenna Asterisk offline-tilaan",
  ASTERISK_STATUS_OFFLINE: "Offline",
  ASTERISK_STATUS_UPANDRUNNING: "Käynnissä",
  ASTERISK_STATUS_TERMINATED: "Lopetettu",
  ASTERISK_STATUS_BOOTING: "Käynnistyy",
  ASTERISK_STATUS_SYNCING: "Synkronoi",
  RESYNC: "Synkronoi uudelleen",
  TOGGLE_FAILED: "Vaihto epäonnistui (todennäköisesti OPM-pyyntö)",
  CURRENCY: "Valuutta",
  INVOICE_CURRENCY_EUR: "EUR",
  INVOICE_CURRENCY_USD: "USD",
  INVOICE_CURRENCY_SEK: "SEK",
  INVOICE_POST_ADDON: "Lasku",
  INVOICE_POST_LICENSE: "Lisenssi",
  INVOICE_POST_USAGE: "Puhelinkäyttö",
  INVOICE_POST_ALL_LICENSE: "Kaikki kanavat lisenssi",
  TARIFF_ID: "Tariffi",
  VOICE_TARIFF_LIST: "Puhetariffilista",
  PHONENUMBER_MONTHLY_COST_ID: "Puhelinnumeron kuukausikustannus",
  PHONENUMBER_MONTHLY_COST_LIST: "Puhelinnumeron kuukausikustannuslista",
  PHONENUMBER_SETUP_COST_ID: "Puhelinnumeron aloituskustannus",
  PHONENUMBER_SETUP_COST_LIST: "Puhelinnumeron aloituskustannuslista",
  INVOICE_POST_VOICE_TARIFF: "Puhetariffi",
  INVOICE_POST_VOICE_PHONENUMBER_MONTHLY_COST: "Puhelinnumeron kuukausikustannus",
  INVOICE_POST_VOICE_PHONENUMBER_SETUP_COST: "Puhelinnumeron aloituskustannus",
  INVOICE_POST_VOICE_LICENSE: "Puhelisenssi",
  INVOICE_POST_VOICE_USAGE: "Puhelinkäyttö",
  INVOICE_POST_EMAIL_USAGE: "Sähköpostikäyttö",
  INVOICE_POST_EMAIL_LICENSE: "Sähköpostilisenssi",
  INVOICE_POST_FORM_USAGE: "Lomakekäyttö",
  INVOICE_POST_VOICE_NUMBERS_MONTHLY: "Puhelinnumeroiden kuukausikustannukset",
  INVOICE_POST_VOICE_NUMBERS_SETUP: "Puhelinnumeroiden aloituskustannukset",
  INVOICE_POST_VOICE_RECORDING: "Puhetallennus",
  INVOICE_POST_VOICE_RECORDING_STORAGE: "Puhetallennuksen tallennus",
  CUSTOMER_IDENTIFICATION_NUMBER: "Asiakkaan tunnistenumero",
  INVOICE_PERIOD_MONTHLY: "Kuukausittain",
  INVOICE_PERIOD_EVERY2NDMONTH: "Joka toinen kuukausi",
  INVOICE_PERIOD_QUARTERLY: "Neljännesvuosittain",
  INVOICE_PERIOD_HALFYEARLY: "Puolivuosittain",
  INVOICE_PERIOD_ANNUALLY: "Vuosittain",
  AMOUNT_TYPE: "Tyyppi",
  AMOUNT_PERCENT: "Prosentti",
  AMOUNT_CURRENCY: "Summa",
  INVOICE_POST_AMOUNT_TYPE_PERCENT: "Prosentti",
  INVOICE_POST_AMOUNT_TYPE_CURRENCY: "Summa",
  INVOICE: "Lasku",
  INVOICES: "Laskut",
  AMOUNT: "Summa",
  LOCKED_AT: "Lukittu",
  PAID_AT: "Maksettu",
  PERIOD_START: "Jakson alku",
  PERIOD_END: "Jakson loppu",
  INVOICE_POST: "Laskurivi",
  INVOICE_POSTS: "Laskurivit",
  RE_PROCESS: "Käsittele uudelleen",
  EXCLUDE: "Sulje pois",
  INVOICE_ID: "Lasku",
  TARIFF: "Tariffi",
  TARIFFS: "Tariffit",
  TARIFF_PARENT: "Tariffin yläkäsite",
  TARIFF_PARENTS: "Tariffien yläkäsitteet",
  OUTBOUND_TARIFFS: "Lähtevät tariffit",
  INBOUND_TARIFF: "Saapuva tariffi",
  INTERNAL_TARIFF: "Sisäinen tariffi",
  OUTBOUND_ROUNDUP_STRATEGY: "Lähtevien pyöristysstrategia",
  INBOUND_ROUNDUP_STRATEGY: "Saapuvien pyöristysstrategia",
  INTERNAL_ROUNDUP_STRATEGY: "Sisäinen pyöristysstrategia",
  TARIFF_PARENT_ID: "Puhetariffin yläkäsite",
  VOICE_TARIFF_ROUNDUP_STRATEGY_NOROUNDUP: "Ei pyöristystä",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST60SECONDS: "Lähin 60 sekuntia",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST30SECONDS: "Lähin 30 sekuntia",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEILNEAREST10SECONDS: "Lähin 10 sekuntia",
  VOICE_TARIFF_ROUNDUP_STRATEGY_CEIL15TO60: "Pyöristä 15-60 60 sekuntiin",
  PHONENUMBER_MONTHLY_COST_PARENT_ID: "Puhelinnumeron kuukausikustannuksen yläkäsite",
  PHONENUMBER_MONTHLY_COST: "Puhelinnumeron kuukausikustannus",
  PHONENUMBER_MONTHLY_COSTS: "Puhelinnumeron kuukausikustannukset",
  PHONENUMBER_MONTHLY_COST_PARENT: "Puhelinnumeron kuukausikustannuksen yläkäsite",
  PHONENUMBER_MONTHLY_COST_PARENTS: "Puhelinnumeron kuukausikustannusten yläkäsitteet",
  PHONENUMBER_SETUP_COST_PARENT_ID: "Puhelinnumeron aloituskustannuksen yläkäsite",
  PHONENUMBER_SETUP_COST: "Puhelinnumeron aloituskustannus",
  PHONENUMBER_SETUP_COSTS: "Puhelinnumeron aloituskustannukset",
  PHONENUMBER_SETUP_COST_PARENT: "Puhelinnumeron aloituskustannuksen yläkäsite",
  PHONENUMBER_SETUP_COST_PARENTS: "Puhelinnumeron aloituskustannusten yläkäsitteet",
  INVOICE_START: "Laskutuksen alku",
  INVOICE_END: "Laskutuksen loppu",
  ADDONS: "Lisäosat",
  PROCESSED_AT: "Käsitelty",
  INVOICE_PRINT: "Lasku",
  INVOICE_PDF: "Pdf",
  MOBILE: "Mobiili",
  FIXED_LINE: "Kiinteä linja",
  SHARED_COST: "Jaettu kustannus",
  TOLL_FREE: "Maksuton",
  DUE_PERIOD: "Eräpäiväjakso",
  COUNT: "Määrä",
  INVOICE_DUE_PERIOD_MONTH: "Kuukausi",
  INVOICE_DUE_PERIOD_DAY: "Päivä",
  COMPANY_NAME: "Yrityksen nimi",
  COMPANY_ADDRESS: "Yrityksen osoite",
  COUNTRY: "Maa",
  CITY: "Kaupunki",
  POSTAL_CODE: "Postinumero",
  ADDRESS_ROW1: "Osoiterivi 1",
  ADDRESS_ROW2: "Osoiterivi 2",
  MARK_AS_PAID: "Merkitse maksetuksi",
  MARK_AS_UNPAID: "Merkitse maksamattomaksi",
  INVOICE_DATE: "Laskun päivämäärä",
  AMOUNT_TO_PAY: "Maksettava summa",
  INVOICE_PERIOD: "Laskutusjakso",
  INBOUND: "Saapuva",
  SUB_TOTAL: "Välisumma",
  VAT: "ALV",
  PCS: "kpl",
  COMPANY_EMAILS: "Yrityksen sähköpostiosoitteet",
  PAYMENT_OPTION: "Maksuvaihtoehto",
  INVOICE_PAYMENT_OPTION_BANKTRANSFER: "Pankkisiirto",
  INVOICE_PAYMENT_OPTION_CREDITCARD: "Luottokortti",
  LICENSE_COST: "Lisenssikustannus",
  FORM_MODULE_COST: "Lomakemoduliin kustannus",
  REPORTING: "Raportointi",
  SURCHARGE: "Lisämaksu",
  PREPAY: "Ennakkomaksu",
  COST: "Kustannus",
  INVOICE_POST_AMOUNT_TYPE_MINDEB: "Vähimmäisveloitus",
  FINANCE: "Talous",
  PREPAID_CREDIT: "Ennakkomaksukrediitti",
  PREPAID_CREDITS: "Ennakkomaksukreditit",
  VALID_FROM: "Voimassa alkaen",
  VALID_UNTIL: "Voimassa asti",
  CREDIT_LEFT: "Krediittiä jäljellä",
  OUTBOUND_CALL_RESTRICTED_DESCRIPTION:
    "Järjestelmä rajoitti lähtevää puhelua kohdenumeroon! Ota yhteyttä järjestelmänvalvojaan saadaksesi pääsyn!",
  OUTBOUND_CALL_NEGATIVE_BALANCE_DESCRIPTION:
    "Lähtevä puhelu lopetettiin organisaatiosi negatiivisen saldon vuoksi!",
  OUTBOUND_CALL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Lähtevä puhelu lopetettiin organisaation toimimattomuuden vuoksi!",
  OUTBOUND_CALL_TOLL_FREE_NOT_SAME_ORIGIN:
    "Lähtevä puhelu maksuttomaan kohdenumeroon lopetettiin, koska soittajan ja vastaanottajan alkuperämaan on oltava sama!",
  OUTBOUND_EMAIL_NEGATIVE_BALANCE_DESCRIPTION:
    "Lähtevä sähköposti lopetettiin organisaatiosi negatiivisen saldon vuoksi!",
  OUTBOUND_EMAIL_ORGANIZATION_INACTIVE_DESCRIPTION:
    "Lähtevä sähköposti lopetettiin organisaation toimimattomuuden vuoksi!",
  DESTINATION_RESTRICTOR: "Kohderajoitin",
  DESTINATION_RESTRICTORS: "Kohderajoittimet",
  DESTINATIONS: "Kohteet",
  STATS_LICENSE_TYPE_ENTRY: "Entry",
  STATS_LICENSE_TYPE_STANDARD: "Standard",
  STATS_LICENSE_TYPE_ENTERPRISE: "Enterprise",
  ALL_CHANNELS: "Kaikki kanavat",
  VOICE_TARIFF_ROUNDUP_STRATEGY: "Puhetariffin pyöristysstrategia",
  VOICE_OUTBOUND_USAGE: "Lähtevä puhelinkäyttö",
  VOICE_USAGE_ROUNDUP_STRATEGY: "Puhelinkäytön pyöristysstrategia",
  VOICE_INBOUND_USAGE: "Saapuva puhelinkäyttö",
  VOICE_INTERNAL_USAGE: "Sisäinen puhelinkäyttö",
  EMAIL_INBOUND_USAGE: "Saapuva sähköpostikäyttö",
  EMAIL_OUTBOUND_USAGE: "Lähtevä sähköpostikäyttö",
  FORM_INBOUND_USAGE: "Lomakkeen saapuva käyttö",
  NEGATIVE_BALANCE_LOCK: "Negatiivisen saldon lukitus",
  PREPAID: "Ennakkomaksu",
  NONE_HANDLED_INVOICE_CANNOT_BE_LOCKED: "Käsittelemätöntä laskua ei voi lukita!",
  LOCKED_INVOICES_MAY_NOT_BE_PATCHED: "Lukittua laskua ei voi muokata!",
  CREDIT_USED: "Käytetty krediitti",
  INVOICE__DATA__COMPANY_EMAILS: "Laskun vastaanottajan sähköpostiosoitteet",
  DASHBOARD_LICENSE: "Dashboard-lisenssi",
  REPORTING_LICENSE: "Raportointilisenssi",
  DASHBOARD_LICENSE_TYPE: "Dashboard-lisenssityyppi",
  REPORTING_LICENSE_TYPE: "Raportointilisenssityyppi",
  STANDARD: "Standard",
  ENTERPRISE: "Enterprise",
  TWINNING: "Twinning",
  ON: "Päällä",
  QUEUE_ON: "Jonopuhelut",
  DIRECT_ON: "Suorat puhelut",
  TWINNED: "Twinnattu",
  TWINNED_TO: "Twinnattu kohteeseen",
  RE_PROCESS_TARIFFS: "Käsittele tariffit uudelleen",
  RE_PROCESS_TARIFFS_NO_RESET_TARIFF_ID:
    "Käsittele tariffimaksut uudelleen ilman tariffilistan nollaamista",
  RE_PROCESS_TARIFFS_WITH_RESET_TARIFF_ID:
    "Käsittele tariffimaksut uudelleen ja nollaa tariffilista",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTRY: "Entry",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_STANDARD: "Standard",
  ORGANIZATION_DASHBOARD_REPORTING_TYPE_ENTERPRISE: "Enterprise",
  INVOICE_NUMBER: "Laskun numero",
  CUSTOMER_NUMBER: "Asiakasnumero",
  SALESPERSON: "Myyjä",
  YOUR_REFERENCE: "Viitteenne",
  PAYMENT_TERMS: "Maksuehdot",
  VAT_REGISTRATION_NO: "ALV-numero",
  FIN_CHARGE_TERMS: "Viivästyskorko",
  INVOICE_ROUNDING: "Laskun pyöristys",
  COMPANY_REFERENCE: "Yritysviite",
  INTERNET: "Internet",
  E_MAIL: "Sähköposti",
  CORPORATE_IDENTITY_NO: "Y-tunnus",
  BANKGIRO: "Pankkisiirto",
  BIC_SWIFT: "BIC/SWIFT",
  PAYMENT_INFO_TEMPLATE: "Maksutietomalli",
  HANDELSBANKEN_BG_SEK: "Handelsbanken pankkisiirto SEK",
  HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  WIZE_EUR: "Wize EUR",
  NET_AMOUNT: "Nettosumma",
  SUBTOTAL: "Välisumma",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_BG_SEK: "Handelsbanken Pankkisiirto SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_SEK: "Handelsbanken IBAN SEK",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_EUR: "Handelsbanken IBAN EUR",
  INVOICE_PAYMENT_INFO_TEMPLATE_HANDELSBANKEN_IBAN_USD: "Handelsbanken IBAN USD",
  INVOICE_PAYMENT_INFO_TEMPLATE_WIZE_EUR: "Wize EUR",
  QUEUE_NOT_FOUND: "Jonoa ei löytynyt",
  CONTACT_NOT_FOUND: "Yhteystietoa ei löytynyt",
  SEND_INVOICE: "Lähetä lasku",
  GO_TO_CONVERSATION: "Siirry keskusteluun",
  MISSING_ORGANIZATION_INVOICE_DATA: "Organisaation laskutustiedot puuttuvat",
  NO_ORGANIZATION_INVOICE_EMAILS:
    "Organisaatiolta ei löytynyt sähköpostiosoitteita keskustelun luomiseksi!",
  INVOICE_ALREADY_LOCKED: "Lasku on jo lukittu",
  LOCK_INVOICE: "Lukitse lasku",
  REGULATION_LOCAL_ADDRESS_PROOF_REQUIRED:
    "Puhelinnumeroita ostaessa huomioi, että jotkin alueet saattavat vaatia todisteen voimassa olevasta katuosoitteesta kyseisellä alueella tai kaupunkialueella. Tämä voi tarkoittaa asiakirjojen, kuten viimeaikaisen laskun tai yritystodistuksen toimittamista, joka osoittaa vastaavan osoitteen paikallisten määräysten noudattamisen varmistamiseksi.",
  REGULATION_WORLDWIDE_ADDRESS_PROOF_REQUIRED:
    "Puhelinnumeroita ostaessa huomioi, että tämä alue vaatii todisteen voimassa olevasta katuosoitteesta. Tämä voi tarkoittaa asiakirjojen, kuten viimeaikaisen laskun tai yritystodistuksen toimittamista, joka osoittaa vastaavan osoitteen paikallisten määräysten noudattamisen varmistamiseksi.",
  REGULATION_NATIONAL_ADDRESS_PROOF_REQUIRED:
    "Puhelinnumeroita ostaessa huomioi, että tämä alue vaatii todisteen voimassa olevasta katuosoitteesta kyseisessä maassa. Tämä voi tarkoittaa asiakirjojen, kuten viimeaikaisen laskun tai yritystodistuksen toimittamista, joka osoittaa vastaavan osoitteen paikallisten määräysten noudattamisen varmistamiseksi.",
  PURCHASE: "Osta",
  SETUP_COST: "Aloitusmaksu",
  MONTHLY_COST: "Kuukausimaksu",
  REGULATIONS: "Määräykset",
  PHONENUMBER_PURCHASE_STATUS_ERROR: "Virhe",
  PHONENUMBER_PURCHASE_STATUS_WARNING: "Varoitus",
  PHONENUMBER_PURCHASE_STATUS_PARTIAL: "Osittainen",
  PHONENUMBER_PURCHASE_STATUS_SUCCESS: "Onnistui",
  PHONENUMBER_PURCHASE: "Puhelinnumeron osto",
  PHONENUMBER_PURCHASES: "Puhelinnumeroiden ostot",
  PHONENUMBER_PREFIX: "Puhelinnumero DID-ryhmä",
  PHONENUMBER_PREFIXES: "Puhelinnumero DID-ryhmät",
  PHONENUMBER_DID_TYPE_FIXED_LINE: "Kiinteä linja",
  PHONENUMBER_DID_TYPE_MOBILE: "Mobiili",
  PHONENUMBER_DID_TYPE_SHARED_COST: "Jaettu kustannus",
  PHONENUMBER_DID_TYPE_TOLL_FREE: "Maksuton",
  ALPHA2: "Alpha-2",
  AREA_NAME: "Alueen nimi",
  AREA_CODE: "Suuntanumero",
  STOCK: "Varasto",
  PHONENUMBER_STOCK: "Puhelinnumerovarasto",
  PHONENUMBER_STOCKS: "Puhelinnumerovarastot",
  BULK_ADD_PHONENUMBERS: "Lisää puhelinnumeroita massana",
  BULK_ADD_PHONENUMBERSTOCK: "Lisää puhelinnumeroita connectel-varastoon massana",
  NUMBER_RANGE_START: "Numeroalueen alku",
  NUMBER_RANGE_END: "Numeroalueen loppu",
  LIST_PHONENUMBERS: "Listaa puhelinnumerot",
  ADD_PHONENUMBERS: "Lisää puhelinnumeroita",
  VOICE_ROUTE_NUMBER_MAY_NOT_BE_CHANGED: "Numeroa ei voi muuttaa!",
  EMERGENCY: "Hätätila",
  ENABLED: "Käytössä",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_VOXBONE: "Voxbone",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_CONNECTEL: "Connectel",
  VOICE_ROUTE_PHONENUMBER_PROVIDER_THIRD_PARTY: "Kolmas osapuoli",
  PROVIDER: "Palveluntarjoaja",
  TERMINATE: "Lopeta",
  TERMINATION: "Lopetus",
  TERMINATED_AT: "Lopetettu",
  TERMINATED_BY: "Lopettanut",
  TERMINATES_AT: "Loppuu",
  USER_NOT_FOUND: "Käyttäjä-id:tä ({{user_id}}) ei löytynyt!",
  TERMINATE_WENT_WRONG: "Jotain meni vikaan puhelinnumeron lopettamisessa!",
  VOXBONE_NUMBER_NOT_CANCELLED: "Puhelinnumeroa ei voitu peruuttaa!",
  VOXBONE_DID_ID_NOT_FOUND:
    "Palveluntarjoajaa ei voi vaihtaa Voxboneksi, koska numeroa ei löydy Voxbonen tietokannasta.",
  VOICE_RECORDING: "Äänitallenne",
  VOICE_RECORDING_STORAGE: "Äänitallenteiden tallennus",
  VOICEMAIL_MESSAGE: "Ääniviesti",
  VOICE_CALL_RECORDING: "Puhelutallenne",
  ADD_CUSTOM_INVOICE_POST: "Lisää mukautettu laskurivi",
  BY_PREFIX: "Etuliitteen mukaan",
  PREFIX: "Etuliite",
  DESTINATION_RESTRICTOR_CONFIRM:
    "Lisäpuhelukohteiden aktivointi voi johtaa tahattomiin maksuihin ja kasvaviin kustannuksiin, koska puhelut aiemmin estettyihin maihin sallitaan. Varmista, että olet tietoinen mahdollisista taloudellisista seurauksista ennen uusien kohteiden aktivointia.",
  DISPOSITION_MAY_NOT_HAVE_MORE_THAN_3_LEVELS: "Luokittelussa ei saa olla enempää kuin 3 tasoa!",
  TO_ME: "Minulle",
  ASSIGN_CONVERSATION_TO_ME: "Osoita keskustelu minulle",
  VOICE_DEVICE_AND_TWINNING_SETTINGS: "Äänilaitteen & twinning-asetukset",
  VOICE_NOTIFICATION_SOUND: "Äänihälytysääni",
  VOICE_NOTIFICATION_MICROPHONE: "Äänimikrofoni päällä/pois",
  NO_USER: "Ei käyttäjää",
  NO_DISPOSITION: "Ei luokittelua",
  NO_SUB_DISPOSITION: "Ei alaluokittelua",
  NO_THRD_DISPOSITION: "Ei kolmannen tason luokittelua",
  NOT_REQUIRED: "Ei pakollinen",
  RESOLVE_IN: "Ratkaise ajassa",
  REPLY_AT_LATEST: "Vastaa viimeistään",
  RESOLVE_AT_LATEST: "Ratkaise viimeistään",
  CHANGELOG: "Muutosloki",
  SWITCH_TO_DARK_THEME: "Vaihda tummaan teemaan",
  SWITCH_TO_LIGHT_THEME: "Vaihda vaaleaan teemaan",
  DISPLAY_CHANGELOG_HISTORY: "Näytä muutoslokin historia",
  IMPROVEMENT: "Parannus",
  BUG_FIX: "Vikakorjaus",
  SELECT_ALL: "Valitse kaikki",
  NAV_CUSTOMER_ID: "Asiakas navision-id",
  CREATE_NEW_JOURNEY_DRAFT: "Luo uusi matkaluonnos",
  SHOW_JOURNEY_DRAFTS: "Näytä matkaluonnokset",
  DROP_TO_ATTACH_FILE: "Pudota liittääksesi tiedoston",
  NO_PREVIEW_AVAILABLE: "Esikatselua ei saatavilla tälle tiedostolle!",
  REPLY_TO_CC: "Sähköpostin kopio",
  REPLY_TO_BCC: "Sähköpostin piilokopio",
  LEAVE_EMPTY_FOR_ALL_DAY_EVERYDAY: "Jätä tyhjäksi koko päiväksi, joka päivä",
  RELEASE: "Julkaisu",
  RELEASES: "Julkaisut",
  VERSION: "versio",
  EXECUTION_AT: "Suoritus",
  EXECUTED_AT: "Suoritettu",
  FULLFILLED: "Täytetty",
  OUTPUT: "Tuloste",
  CLICK_TO_PRINT_TO_CONSOLE: "Klikkaa tulostaaksesi konsoliin",
  PRE_QUEUE_TIME_LONG: "Matka-aika (aika matkalla ennen jonoa tai lopetusta)",
  AUDIT: "Tarkastus",
  AUDITS: "Tarkastukset",
  SYS_USER: "Järjestelmä",
  AUDIT_ACTION_READ: "Lue",
  AUDIT_ACTION_UPDATE: "Päivitä",
  AUDIT_ACTION_CREATE: "Luo",
  AUDIT_ACTION_DELETE: "Poista",
  AUDIT_ACTION_SOFTDELETE: "Pehmeä poisto",
  AUDIT_SEVERITY_L: "Matala",
  AUDIT_SEVERITY_M: "Keskitaso",
  AUDIT_SEVERITY_H: "Korkea",
  ACTION: "Toiminto",
  SEVERITY: "Vakavuus",
  CHANGED_PROPS: "Muutetut ominaisuudet",
  EMPTY: "Tyhjä",
  MAIL_STATUS: "Sähköpostin tila",
  VOICE_STATUS: "Äänen tila",
  UnAssign: "Poista osoitus",
  READONLY_UI: "Vain luku (UI)",
  VOICE_RINGTONE: "Soittoääni",
  NOTIFICATION_RINGTONE: "Ilmoitusääni",
  MAX_FILESIZE: "Maksimi tiedostokoko",
  MIMETYPES: "MIME-tyypit",
  API_KEY: "API-avain",
  API_KEYS: "API-avaimet",
  COPY_API_KEY_TO_CLIPBOARD: "Kopioi API-avain leikepöydälle",
  CREATED_BY: "Luonut",
  CONTACT_CONDITION: "Yhteystietoehto",
  DATA_KEY: "Kentän alias",
  COMPARISON: "Vertailu",
  DISPLAY_QUEUE_CALLER_ID: "Näytä jononumero",
  MERGE_CONTACT_IF_EXISTS: "Yhdistä yhteystiedot jos olemassa",
  CUSTOM_DATA: "Mukautettu data",
  OVERRIDES_CURRENT: "Korvaa nykyisen arvon",
  MERGES_AND_REMOVES_DUPLICATED: "Yhdistää ja poistaa kaksoiskappaleet",
  MERGEANDKEEPEXISTING: "Yhdistä ja säilytä olemassa oleva",
  MERGEANDOVERRIDE: "Yhdistä ja korvaa olemassa oleva",
  CONVERSATION_OWNER_ONLY: "Vain keskustelun agenttien välilehdet",
  INACTIVE_TABS_HIDDEN: "Epäaktiiviset välilehdet on piilotettu",
  INACTIVE_TABS_DESCRIPTION:
    "Varmista, ettei sinulla ole yli 7 välilehteä auki sulkemalla näkyvät välilehtesi yksi kerrallaan!",
  VIEW_EVENT: "Näytä tapahtuma",
  EVENT_ORDER: "Järjestys",
  CLIENT: "Asiakas",
  CLIENTS: "Asiakkaat",
  SELECT_CLIENT: "Valitse asiakas",
  CLIENT_ID: "Asiakas",
  DATA: "Data",
  NO_CLIENT: "Ei asiakasta",
  TRANSLATIONS: "Käännökset",
  TRANSLATION: "Käännös",
  __SUBMISSION_DISPLAY_FIELDS: "Lähetyksen näyttökentät",
  ADDITIONAL_QUEUES: "Lisäjonot",
  HELP_ADDITIONAL_QUEUES:
    "Valitse lisäjonot, jotka eivät ole saatavilla nykyisessä agenttiprofiiilissa.",
  FROM_ADDITIONAL: "Lisäjonoista",
  FORM_SETTINGS: "Lomakeasetukset",
  FORM_CAPACITY: "Lomakekapasiteetti",
  FIXED_LINE_OR_MOBILE: "Kiinteä linja/mobiili",
  ATTACHMENTS_STORAGE: "Liitteiden tallennus",
  PARENTS: "Vanhemmat",
  ALL_QUEUES: "Kaikki jonot",
  PROFILE_QUEUES: "Profiilijonot",
  DATA_CHANGED: "Kenttädatan muutokset",
  FORCE_LOGOUT: "Pakota uloskirjautuminen",
  JSON_RESPONSE: "JSON-vastaus",
  JSON_PATH: "JSON-polku",
  JSON_PATH_HELP: "Esimerkki: data.property, data.array[0].property",
  DELETED_ITEM_REMOVE: "Poistettu kohde täytyy poistaa valituista kohteista!",
  UNASSIGN: "Poista osoitus",
  ALLOW_AGENT_UNASSIGN: "Salli agentin poistaa osoitus",
  ASTERISK_HOURS: "Asterisk-tunnit",
  TOGGLE_ASTERISK_HOURS: "Vaihda asterisk-tunteja",
  EXTERNAL_VOICE_RINGTONE: "Ulkoinen soittoääni",
  INTERNAL_VOICE_RINGTONE: "Sisäinen soittoääni",
  LOAD_MORE: "Lataa lisää",
  JOURNEY_TIMELINE: "Matka-aikajana",
  TIMELINE: "Aikajana",
  EMPTY_TIMELINE: "Tyhjä aikajana",
  GLOBAL_SEARCH_QUEUES: "Globaalit hakujonot",
  PERMANENTLY_DELETE_REPORT: "Poista raportti pysyvästi?",
  PERMANENTLY_DELETE_REPORT_DESCRIPTION:
    "Kaikki tämän raportin yksilölliset widgetit poistetaan myös tällä toiminnolla.",
  PERMANENTLY_DELETE_DASHBOARD: "Poista Dashboard pysyvästi?",
  PERMANENTLY_DELETE_DASHBOARD_DESCRIPTION:
    "Kaikki tämän Dashboardin yksilölliset widgetit poistetaan myös tällä toiminnolla.",
  DELETE_WIDGET: "Poista widget",
  DELETE_WIDGET_DESCRIPTION:
    "Widget poistetaan pysyvästi, jos sitä ei esiinny muissa Dashboardeissa/raporteissa.",
  CREATED_AFTER: "Luotu jälkeen",
  LAST_MESSAGE_BY_CONTACT: "Yhteyshenkilö",
  LAST_MESSAGE_BY_AGENT: "Agentti",
  SUBJECT_PREFIX: "Aiheen etuliite",
  HIDE_STATS_PREVIEW: "Piilota tilastojen esikatselu",
  DISPLAY_STATS_PREVIEW: "Näytä tilastojen esikatselu",
  SOMETHING_WENT_WRONG_CLONING_WIDGET: "Jotain meni vikaan kloonauksessa",
  STATS_WIDGET_CUSTOM_PERIOD_TO_LONG:
    "Widgetin mukautetun jakson alku ja loppu eivät saa ylittää 31 päivää.",
  TRANSFER_DASHBOARD: "Siirrä Dashboard",
  TRANSFER_REPORT: "Siirrä raportti",
  TRANSFER_TO_REPORT_OWNER: "Siirrä widget raportin omistajalle",
  OF: "/",
  WIDGET_HAS_NO_DATA: "Widgetillä ei ole dataa",
  RULE_OTHERWISE_MATCHED: 'Sääntö "Muussa tapauksessa" täsmäsi',
  RULE_X: "Sääntö {{n}}",
  RULE_X_WAS_MATCHED: '"Sääntö {{n}}" täsmäsi',
  EDIT_CONTACT: "Muokkaa yhteystietoa",
  PRECISION: "Tarkkuus",
  SKILL: "Taito",
  SKILLS: "Taidot",
  SKILL_ID: "Taito",
  LEVEL_ONE: "Taso yksi",
  LEVEL_TWO: "Taso kaksi",
  LEVEL_THREE: "Taso kolme",
  SKILL_TIMEOUT_SETTINGS: "Taitojen aikakatkaisuasetukset",
  TIMEOUT_ONE: "Aikakatkaisu yksi",
  TIMEOUT_TWO: "Aikakatkaisu kaksi",
  TIMEOUT_THREE: "Aikakatkaisu kolme",
  SKILL_TIMEOUT_ONE_DESCRIPTION:
    "Käyttäjän tason kaksi taidot sisällytetään tämän aikakatkaisun jälkeen",
  SKILL_TIMEOUT_TWO_DESCRIPTION:
    "Käyttäjän tason kolme taidot sisällytetään tämän aikakatkaisun + edellisen aikakatkaisun jälkeen",
  SKILL_TIMEOUT_THREE_DESCRIPTION:
    "Kaikki taidot sisällytetään tämän aikakatkaisun + edellisten aikakatkaisujen jälkeen",
  QUEUE_TIMEOUT_UNIT_SECOND: "Sekuntia",
  QUEUE_TIMEOUT_UNIT_MINUTE: "Minuuttia",
  QUEUE_TIMEOUT_UNIT_HOUR: "Tuntia",
  QUEUE_TIMEOUT_UNIT_DAY: "Päivää",
  DEFAULT_VOICE_COUNTRY: "Oletusäänen maa",
  NO_SKILL: "Ei taitoa",
  IS_WAITING: "Odottaa",
  IS_ONGOING: "Käynnissä",
  PREVIEW_PERIOD: "Esikatselujakso",
  WALLBOARD: "Wallboard",
  WALLBOARD_KEY: "Wallboard-avain",
  STATS_REPORT_ID: "Raportti / Dashboard",
  SHARE_DATA: "Jaa data",
  SHARE_DATA_DESCRIPTION:
    "Jaa dataa kuten käyttäjät, jonot, luokittelut, sähköpostitilit... wallboard-näyttäjille. Huomaa, että jaetut datapisteet kuten jonot sisältävät kaikki organisaation jonot, ei vain widgeteissä näkyvät. Datan jakaminen tulisi siksi poistaa käytöstä, jos jaat wallboardin ulkopuolisille tahoille, joilla ei pitäisi olla pääsyä datapisteiden tietoihin.",
  WALLBOARD_NO_MORE_TRIES: "Yhteys katkesi, päivitä sivu ja yritä uudelleen!",
  DISPOSED: "Luokiteltu",
  STATUS_CHANGED: "Tila muuttunut",
  ASSIGNED_TO: "Määrätty",
  USER_ASSIGNED: "Käyttäjä määrätty",
  USER_UNASSIGNED: "Käyttäjä poistettu",
  UNASSIGNED_FROM: "Poistettu",
  QUEUE_ASSIGNED: "Jono määrätty",
  TIMELINE_EVENT_TYPE_JOURNEYJOB: "Journey-työ",
  TIMELINE_EVENT_TYPE_VOICE: "Ääni",
  TIMELINE_EVENT_TYPE_NOTIFICATION: "Ilmoitus",
  TIMELINE_EVENT_TYPE_AUDIT: "Tarkastus",
  JOURNEY_TRIGGER_ID: "Laukaisin",
  TRANSCRIPT: "Transkriptio",
  DEFAULT_LANGUAGE: "Oletuskieli",
  VIEW_TRANSCRIPT: "Näytä transkriptio",
  DISPLAY_TRANSCRIPT: "Näytä transkriptio",
  CONDITION_AND_OR_AND: "Ja",
  CONDITION_AND_OR_OR: "Tai",
  VOICE_RECORDING_TRANSCRIPT: "Äänitranskriptio",
  WALLBOARDS: "Wallboardit",
  SLA_RESPONSE: "Vastausaika",
  SLA_RESOLUTION: "Ratkaisuaika",
  HAS_SLA: "On SLA",
  HAS_NOT_SLA: "Ei SLA:ta",
  EXCEEDED: "Ylitetty",
  NOT_EXCEEDED: "Ei ylitetty",
  TRANSCRIPT_TRIGGER: "Transkriptio laukaisin päällä",
  CLICK_TO_TRANSCRIPT: "Klikkaa",
  RECORDED_BY_JOURNEY: "Journey-tallenteet",
  MANUALLY_RECORDED: "Manuaaliset tallenteet",
  GENERATE_TRANSCRIPT: "Luo transkriptio",
  VOICE_RECORDING_TRANSCRIPT_SETTING: "Transkriptioasetukset",
  VOICE_RECORDING_TRANSCRIPT_SETTINGS: "Transkriptioasetukset",
  FIND_REPLACE: "Etsi & korvaa",
  FIND: "Etsi",
  REPLACE: "Korvaa",
  MODEL: "Malli",
  VOICE_RECORDING_TRANSCRIPT_MODEL_WHISPER: "Whisper",
  VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA: "Nova",
  "VOICE_RECORDING_TRANSCRIPT_MODEL_NOVA-2": "Nova 2",
  VOICE_RECORDING_TRANSCRIPT_MODEL_BASE: "Perus",
  VOICE_RECORDING_TRANSCRIPT_MODEL_ENHANCED: "Parannettu",
  TRANSCRIPTION_ERROR: "Transkriptiovirhe",
  CONFIDENCE: "Luotettavuus",
  TRANSCRIPTION_TIME: "Transkriptioaika",
  POINTS_SYSTEMS: "Pistejärjestelmät",
  POINTS_SYSTEM: "Pistejärjestelmä",
  MAIL_MESSAGE_SENT: "Sähköpostiviesti lähetetty",
  INBOUND_VOICE_CALL: "Saapuva äänipuhelu",
  OUTBOUND_VOICE_CALL: "Lähtevä äänipuhelu",
  INTERNAL_VOICE_CALL: "Sisäinen äänipuhelu",
  OUTBOUND_CONVERSATION_CREATED: "Lähtevä keskustelu luotu",
  OUTBOUND_CONVERSATION_RESOLVED: "Lähtevä keskustelu ratkaistu",
  INBOUND_CONVERSATION_RESOLVED: "Saapuva keskustelu ratkaistu",
  OUTBOUND_CONVERSATION_CLOSED: "Lähtevä keskustelu suljettu",
  INBOUND_CONVERSATION_CLOSED: "Saapuva keskustelu suljettu",
  POINTS: "Pisteet",
  CONVERSATION_SKILL_ID: "Taito",
  JOURNEY_TRIGGER: "Journey-laukaisin",
  JOURNEY_TRIGGERS: "Journey-laukaisimet",
  HIGHLIGHT_IN_JOURNEY: "Korosta journeyssa",
  HELP_CONVERSATION_FILTER_IS_WAITING:
    '"Odottaa" viittaa keskusteluihin, joihin ei ole vastattu ja jotka ovat yhä jonossa.',
  HELP_CONVERSATION_FILTER_IS_ONGOING:
    '"Käynnissä" viittaa keskusteluihin, joita ei ole suljettu tai ratkaistu ja joihin agentti on vastannut (järjestelmän automaattivastaukset on jätetty pois).',
  POINTS_CREATED: "Luotu",
  POINTS_RESOLVED: "Ratkaistu",
  POINTS_CLOSED: "Suljettu",
  POINTS_TALK: "Puheaika",
  POINTS_MAIL_SENT: "Pisteet sähköpostin lähettämisestä",
  TREND_COMPARISON_VALUE: "Trendivertailuarvo",
  CUSTOM_PERCENT: "Mukautettu prosentti",
  STATS_CUSTOM_PERCENT_HELP:
    "Määritä luku, jolla metriikan arvo jaetaan prosenttiarvon luomiseksi ja näyttämiseksi",
  GDPR: "GDPR",
  GDPRS: "GDPR:t",
  PASSWORD_MAY_NOT_BE_IN_HISTORY:
    "Valittu salasana on ollut äskettäin käytössä tällä tilillä eikä sitä voi käyttää uudelleen tällä hetkellä!",
  PASSWORD_LIFETIME_REACHED:
    "Salasanasi on vanhentunut! Nollaa salasanasi päästäksesi sovellukseen.",
  IP_ADDRESS_NOT_WHITELISTED:
    "IP-osoitettasi ei ole sallittujen listalla missään organisaatiossasi!",
  PASSWORD_EXPIRY_DESCRIPTION:
    "Salasanan voimassaolo päättyy alle {{days_left}} päivän kuluttua. Muista vaihtaa salasanasi.",
  PASSWORD_NOT_STRONG:
    "Valittu salasana ei ole riittävän vahva! Varmista, että salasanassasi on vähintään yksi pieni ja iso kirjain, erikoismerkki ja numero!",
  FORM_ERROR_PASSWORD_NOT_STRONG:
    "Täytyy sisältää vähintään yksi pieni ja iso kirjain, erikoismerkki ja numero",
  IP_WHITELIST: "IP-sallittujen lista",
  IP_WHITELIST_DESCRIPTION: "Rajoita pääsyä organisaatioosi IP-osoitteen perusteella.",
  IP_ADDRESSES: "IP-osoitteet",
  CONNECTEL_INFO_SHARE: "Tietojen käyttö",
  LIFETIME: "Elinaika",
  HISTORY: "Historia",
  STRONG: "Vahva",
  CONNECTEL_INFO_SHARE_DESCRIPTION:
    "Suostumus siihen, että Connectel kerää anonymisoitua tilastotietoa ja dataa organisaatiostasi parantaakseen sinulle tarjoamiamme palveluita.",
  PASSWORD_STRONG: "Salasanakäytäntö",
  PASSWORD_STRONG_DESCRIPTION: "Pakota vahvojen salasanojen käyttö.",
  PASSWORD_LIFETIME: "Salasanan elinaika",
  PASSWORD_LIFETIME_DESCRIPTION:
    "Aseta maksimipäivien määrä, jonka salasana voi olla voimassa ennen kuin se täytyy vaihtaa.",
  PASSWORD_HISTORY: "Salasanahistoria",
  PASSWORD_HISTORY_DESCRIPTION: "Rajoittaa aiemmin käytettyjen salasanojen käyttöä.",
  ANONYMIZATION: "Anonymisointi",
  AGENT_ANONYMIZATION_DESCRIPTION:
    "Anonymisoi agentin nimi keskusteluissa ja muissa datapisteissä poistettaessa.",
  EMAIL_ATTACHMENTS: "Sähköpostiliitteet",
  FORM_SUBMISSION_ATTACHMENTS: "Lomakeliitteet",
  DELETION: "Poisto",
  VOICE_RECORDINGS: "Äänitallenteet",
  CONTACT_ANONYMIZATION_DESCRIPTION:
    "Säilytettävien päivien määrä, jotka jätetään anonymisoinnin ulkopuolelle. Tämä asetus koskee vain epäaktiivisia yhteystietoja ja keskusteluja.",
  CONTACT_DELETION_RECORDINGS_DESCRIPTION:
    "Säilytettävien päivien määrä, jotka jätetään poiston ulkopuolelle. Tämä asetus koskee vain epäaktiivisia yhteystietoja ja keskusteluja.",
  CONTACT_VOICE_RECORDINGS_DESCRIPTION:
    "Säilytettävien päivien määrä, jotka jätetään poiston ulkopuolelle.",
  CONTACT_EMAIL_ATTACHMENTS_DESCRIPTION:
    "Säilytettävien päivien määrä, jotka jätetään poiston ulkopuolelle.",
  CONTACT_FORM_SUBMISSION_ATTACHMENTS_DESCRIPTION:
    "Säilytettävien päivien määrä, jotka jätetään poiston ulkopuolelle.",
  ANONYMIZED: "Anonymisoitu",
  LOGIN_ERROR: "Kirjautuminen epäonnistui",
  ANONYMIZE_CONTACT_AND_CONVERSATIONS: "Anonymisoi yhteystieto",
  CONFIRM_ANONYMIZE: "Vahvista anonymisointi",
  CONFIRM_ANONYMIZE_DESCRIPTION:
    "Anonymisoimalla yhteystiedon anonymisoit kaikki siihen ja sen keskusteluihin liittyvät tiedot. Tätä toimintoa ei voi peruuttaa!",
  ANONYMIZATION_ERROR: "Anonymisointivirhe",
  ANONYMIZE_CONTACTS: "Anonymisoi yhteystiedot",
  TRANSCRIPT_ANONYMIZED: "Transkriptio anonymisoitu",
  OpenBrowserTab: "Avaa selainvälilehti",
  MAX_INACTIVITY_READY: "Agenttien profiilien maksimi-inaktiivisuus",
  ORGANIZATION_HAS_NOT_VOICE_ASTERISK:
    "Valitulla organisaatiolla ei ole ääni-asteriskia määritettynä!",
  SIGNATURE_PREVIEW: "Allekirjoituksen esikatselu",
  KEYWORDS: "Avainsanat",
  TAG_KEYWORDS_HELP: "Avainsanat eivät ole merkkikokoriippuvaisia.",
  AUTO_TAGS: "Automaattiset tagit",
  VOICE_TRANSCRIPT: "Äänitranskriptio",
  SUB_DISPOSITIONS: "Alaluokittelut",
  THRD_DISPOSITIONS: "Kolmannen tason luokittelut",
  STATS_ALARMS: "Hälytykset",
  STATS_ALARM: "Hälytys",
  TEXT: "Teksti",
  AUTO_TEXT: "Automaattinen teksti",
  STATS_ALARM_PERIOD_LIVE_TODAY: "Tänään (Live)",
  STATS_ALARM_PERIOD_TODAY: "Tänään",
  STATS_ALARM_PERIOD_LAST_24_HOURS: "Viimeiset 24 tuntia",
  STATS_ALARM_PERIOD_THIS_WEEK: "Tämä viikko",
  STATS_ALARM_PERIOD_THIS_WEEK_SUN: "Tämä viikko (alku sunnuntai)",
  STATS_ALARM_PERIOD_LAST_7_DAYS: "Viimeiset 7 päivää",
  STATS_ALARM_PERIOD_THIS_MONTH: "Tämä kuukausi",
  STATS_ALARM_PERIOD_LAST_30_DAYS: "Viimeiset 30 päivää",
  LAST_MATCH_AT: "Viimeisin osuma",
  MIN_INTERVAL: "Minimiväli",
  STATS_ALARM_MIN_INTERVAL_HELP:
    "Minimiväli odotettavaksi ilmoituksen lähettämisen jälkeen! Roskapostin välttämiseksi, koska live-mittarit voivat muuttua usein.",
  NOTIFICATION: "Ilmoitus",
  ATLAS_NOTIFICATION: "Atlas-ilmoitus",
  USER_IDS: "Käyttäjät",
  HIGHLIGHTED: "Korostettu",
  MATCHED_CONDITION: "täytti ehdon",
  SOUND_TYPE_ELEVENLABS: "Teksti puheeksi",
  SOUND_TYPE_FILEUPLOAD: "Tiedoston lataus",
  ELEVENLABS_VOICE_ALICE: "Alice",
  ELEVENLABS_VOICE_ARIA: "Aria",
  ELEVENLABS_VOICE_BILL: "Bill",
  ELEVENLABS_VOICE_BRIAN: "Brian",
  ELEVENLABS_VOICE_CALLUM: "Callum",
  ELEVENLABS_VOICE_CHARLIE: "Charlie",
  ELEVENLABS_VOICE_CHARLOTTE: "Charlotte",
  ELEVENLABS_VOICE_CHRIS: "Chris",
  ELEVENLABS_VOICE_DANIEL: "Daniel",
  ELEVENLABS_VOICE_ERIC: "Eric",
  ELEVENLABS_VOICE_GEORGE: "George",
  ELEVENLABS_VOICE_JESSICA: "Jessica",
  ELEVENLABS_VOICE_LAURA: "Laura",
  ELEVENLABS_VOICE_LIAM: "Liam",
  ELEVENLABS_VOICE_LILY: "Lily",
  ELEVENLABS_VOICE_MATILDA: "Matilda",
  ELEVENLABS_VOICE_RIVER: "River",
  ELEVENLABS_VOICE_ROGER: "Roger",
  ELEVENLABS_VOICE_SARAH: "Sarah",
  ELEVENLABS_VOICE_WILL: "Will",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_CONVERSATIONAL_SWEDISH: "Sanna Hartfield - Keskusteleva ruotsi",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_SASSY_SWEDISH: "Sanna Hartfield - Nokkela ruotsi",
  ELEVENLABS_VOICE_SANNA_HARTFIELD_NARRATION_SWEDISH: "Sanna Hartfield - Ruotsalainen kertojaääni",
  ELEVENLABS_VOICE_JONAS: "Jonas, rauhallinen & informatiivinen ruotsalainen ääni",
  ELEVENLABS_SETTINGS: "Generaattoriasetukset",
  QUEUE_SORT_LASTINBOUNDMESSAGEAT: "Viimeisin saapuva viesti",
  QUEUE_SORT_LASTOUTBOUNDMESSAGEAT: "Viimeisin lähtevä viesti",
  QUEUE_SORT_UNHANDLED: "Käsittelemättömät",
  QUEUE_SORT_QUEUEJOINTIME: "Jonotusaika",
  SORT: "Lajittele",
  EFFECTIVE_WORK_TIME: "Tehokas työaika",
  ARTIFICIELL_INTELLIGENCE: "Tekoäly",
  AI_ASSISTANTS: "Tekoälyavustajat",
  AI_ASSISTANT: "Tekoälyavustaja",
  KB_UNIQUE_ID: "KB yksilöllinen tunnus",
  AI_ASSISTANT_ID: "Tekoälyavustaja",
  NO_AI_ASSISTANT_ON_QUEUE: "Ei tekoälyavustajaa tässä jonossa",
  NEW_MAIL_MESSAGE_WITH_AI: "Uusi sähköpostiviesti tekoälyllä",
  REPLY_WITH_AI: "Vastaa tekoälyllä",
  AI_RESPONDING: "Tekoäly vastaa...",
  AI_RESPONSE_CONFIDENCE: "Tekoälyvastauksen luotettavuus",
  AI_REQUEST_KB_ASSISTANT: "Tekoälypyyntö (Vastausavustus)",
  AI_REQUEST_KB_ASSISTANCE: "Tekoälypyyntö (Vastausavustus)",
  KbAssistant: "Vastausavustus",
  REQ: "pyyn",
  INVOICE_POST_VOICE_RECORDING_TRANSCRIPT: "Äänitranskriptio",
  TEXT_TO_VOICE_USAGE: "Teksti puheeksi -käyttö",
  QUEUE_SORT_HELP:
    '"Käsittelemättömät" lajittelee jonotusajan mukaan ja suodattaa lisäksi vain vastaamattomat keskustelut.',
  FIELD_CONDITIONS: "Kenttäehdot",
  FIELD_TO_AFFECT: "Vaikutettava kenttä",
  CONDITIONAL_FIELD: "Ehdollinen kenttä",
  THEN: "Sitten",
  FORBID: "Kiellä",
  MAKE_OPTIONAL: "Tee valinnaiseksi",
  IF: "Jos",
  MAX: "MAX",
  MAXIMUM: "Maksimi",
  HELP: "Ohje",
  HIDE_HELP: "Piilota ohje",
  VALIDATION_MAXIMUM_NUMBER_HELP: "Suurin numero, joka voidaan täyttää tähän kenttään",
  VALIDATION_MAXIMUM_LIST_HELP: "Vaihtoehtojen maksimimäärä",
  VALIDATION_MAXIMUM_TEXT_HELP: "Merkkien maksimimäärä",
  DEFAULT_VALUE: "Oletusarvo",
  IMPORT_FORM_SUBMISSION: "Tuo lomakelähetys",
  SAVE_REPLY: "Tallenna vastaus",
  EDIT_REPLY: "Muokkaa vastausta",
  CREATE_REPLY: "Luo vastaus",
  HIDE_REPLY: "Piilota vastaus",
  IMPORT_CONVERSATION_FORM: "Tuo keskustelulomake",
  CUSTOM_ANSWER: "Mukautettu",
  FIELD_CUSTOM_ANSWER_HELP:
    "Loppukäyttäjä voi täyttää mukautetun vastauksen tekstikenttään. Annettu arvo asetetaan aliakseen {field_alias}_custom.",
  CLICK_TO_COPY: "Klikkaa kopioidaksesi",
  RECONNECTS_AT: "Yhdistää uudelleen",
  DOWNLOAD_EML: "Lataa sähköposti .eml",
  USER_SETTINGS: "Käyttäjäasetukset",
  ENGLISH: "Englanti",
  SWEDISH: "Ruotsi",
  DANISH: "Tanska",
  NORWEGIAN: "Norja",
  FINNISH: "Suomen",
  GERMAN: "Saksa",
  SHOW_MORE_FIELDS: "Näytä lisää kenttiä",
  INVALID: "Virheellinen",
  TOO_SHORT: "Liian lyhyt",
  TOO_LONG: "Liian pitkä",
  ERROR_QUEUE_NO_CREATE_LIST: "Jonolla ei ole luomislistaa",
  TEXT_MESSAGE: "Tekstiviesti",
  TEXT_MESSAGE_SOURCE_CREATED: "Tekstiviestilähde luotu",
  TEXT_MESSAGE_SOURCE_CREATED_ERROR: "Tekstiviestilähteen luontivirhe",
  TEXT_MESSAGE_SOURCE: "Tekstiviestilähde",
  TEXT_MESSAGE_SOURCES: "Tekstiviestilähteet",
  ALPHA_TAG: "Alfatunniste",
  SELECT_ORGANIZATION: "Valitse organisaatio",
  MESSAGE: "Viesti",
  SELECT_SOURCE: "Valitse lähde",
  SELECT_QUEUE_WITH_CREATE_LIST: "Valitse jono, jolla on luomislista luodaksesi yhteystiedon",
  BEGIN_TYPING_TO_SEE_CONTACT: "Aloita kirjoittaminen nähdäksesi yhteystiedot...",
  MISSING_CONTACT: "Yhteystieto puuttuu",
  MISSING_EMAIL: "Sähköposti puuttuu",
  MISSING_SUBJECT: "Aihe puuttuu",
  MISSING_CONTACT_FORM: "Yhteydenottolomake puuttuu",
  VERIFIED: "Varmistettu",
  TEXT_MESSAGE_SOURCE_UPDATED: "Tekstiviestilähteen päivitys",
  TEXT_MESSAGE_SOURCE_UPDATE_FAILED: "Tekstiviestilähteen päivitysvirhe",
  SENDER_PHONE_NUMBER: "Lähettäjän puhelinnumero",
  CONVERSATION_ENUM_ID: "Keskustelun ID",
  CONVERSATION_ENUM_EMAIL: "Sähköposti",
  CONVERSATION_ENUM_STATUS: "Tila",
  CONVERSATION_ENUM_CHANNEL: "Kanava",
  CONVERSATION_ENUM_DIRECTION: "Suunta",
  CONVERSATION_ENUM_QUEUE_NAME: "Jonon nimi",
  CONVERSATION_ENUM_AGENT_NAME: "Agentin nimi",
  CONVERSATION_ENUM_DISPOSITION: "Luokittelu",
  CONVERSATION_ENUM_CREATED_DATE: "Luontipäivä",
  CONVERSATION_ENUM_CALLER_ID: "Soittajan ID",
  CONVERSATION_ENUM_ORGANIZATION_ID: "Organisaatio ID",
  CONVERSATION_ENUM_ORGANIZATION_TITLE: "Organisaatio",
  DESELECT_ALL: "Poista valinta kaikista",
  CONVERSATION_DOWNLOAD: "Valitse lataukseen sisällytettävät kentät",
  NEW_TEXT_MESSAGE: "Uusi tekstiviesti",
  SUMMARIZE_CONVERSATION: "Keskustelun kokonaisuuden kertominen",
  GET_NEW_SUMMARY: "Hae uusi kokonaisuus",
  CONVERSATION_SUMMARY: "Keskustelun kokonaisuus",
  OPEN_SUMMARY: "Avaa kokonaisuus",
  CLOSE_SUMMARY: "Sulje kokonaisuus",
  MESSAGE_SENT_SUCCESSFULLY: "Viesti lähetetty",
  MESSAGE_TOO_SHORT: "Viestin täytyy olla vähintään 10 merkkiä",
  MISSING_SOURCE: "Lähde puuttuu",
  MISSING_PHONE_NUMBER: "Puhelinnumero puuttuu",
  ERROR_SENDING_MESSAGE: "Viestin lähetysvirhe",
  MISSING_QUEUE: "Jono puuttuu",
};
