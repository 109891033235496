import React from "react";
import "./header.create.conversation.popover.scss";
import { MailIcon, SmsIcon } from "@Assets/icons";
import { canSend, ChannelEnum } from "atlas-shared";
import { useNavigate } from "react-router-dom";
import { IconText } from "@Components";
import { useTranslation } from "react-i18next";
import { useAuth } from "@Hooks";
import { FeatureFlag } from "@Containers/FeatureFlag";

export const HeaderCreateConversationPopover = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <div id="header-create-conversation-list">
      <ul>
        {canSend(auth.user, ChannelEnum.Mail) && (
          <li onClick={(_) => navigate(`/create/conversation?channel=${ChannelEnum.Mail}`)}>
            <IconText icon={MailIcon} text={t(`CHANNEL_MAIL`)} />
          </li>
        )}
        <FeatureFlag identifier="text.message" defaultValue={false}>
          {/* TODO: Get user permission */}
          <li onClick={() => navigate(`/create/conversation?channel=${ChannelEnum.Sms}`)}>
            <IconText icon={SmsIcon} text={t(`CHANNEL_SMS`)} />
          </li>
        </FeatureFlag>
      </ul>
    </div>
  );
});
