"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionFetchContactData = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const rest_request_1 = require("./rest_request");
exports.JourneyActionFetchContactData = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.FetchContactData,
    initialValues: {
        status_ports: [],
    },
    settings_schema: (0, base_1.settingsValidationSchema)({
        url: {
            type: 'string',
            uri: true,
            required: true,
        },
        method: {
            type: 'string',
            valid: ['get', 'post', 'update', 'put', 'delete',],
            required: true,
        },
        status_ports: {
            type: 'array',
            items: {
                type: 'number',
            },
        },
        query_params: (0, rest_request_1.REST_REQUEST_PARAMS_SCHEMA)('query_params'),
        headers: (0, rest_request_1.REST_REQUEST_PARAMS_SCHEMA)('headers'),
        body: Object.assign(Object.assign({}, (0, rest_request_1.REST_REQUEST_PARAMS_SCHEMA)('body')), { when: {
                reference: { '$ref': 'method', },
                is: {
                    type: 'any',
                    valid: ['get',],
                },
                then: { type: 'any', forbidden: true, },
            } }),
        merge: {
            type: 'object',
            default: {
                first_name: false,
                last_name: false,
                phones: true,
                emails: true,
                data: enums_1.ContactMergeDataEnum.MergeAndKeepExisting,
            },
            properties: {
                first_name: {
                    type: 'boolean',
                },
                last_name: {
                    type: 'boolean',
                },
                phones: {
                    type: 'boolean',
                },
                emails: {
                    type: 'boolean',
                },
                data: {
                    type: 'string',
                    valid: [enums_1.ContactMergeDataEnum.MergeAndKeepExisting, enums_1.ContactMergeDataEnum.MergeAndOverride, null,],
                },
            },
        },
    }),
    getPorts: (node) => {
        const properties = node.properties;
        const new_ports = Object.assign(Object.assign({}, base_1.journeyDefaultInputPort), { [constants_1.JOURNEY_ACTION_FETCH_CONTACT_DATA_ERROR_STATUS_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_FETCH_CONTACT_DATA_ERROR_STATUS_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: false,
                index: 0,
                title: 'ERROR',
                color: '#d91010',
            }), [constants_1.JOURNEY_ACTION_FETCH_CONTACT_DATA_SUCCESS_STATUS_OUTPUT]: (0, utils_1.journeyNodePortAddDefault)({
                id: constants_1.JOURNEY_ACTION_FETCH_CONTACT_DATA_SUCCESS_STATUS_OUTPUT,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: false,
                index: 1,
                title: 'SUCCESS',
                color: '#5bbf1c',
            }) });
        properties.status_ports.forEach((status_code, index) => {
            const id = `${constants_1.JOURNEY_ACTION_FETCH_CONTACT_DATA_DEFINED_STATUS_OUTPUT}_${status_code}`;
            new_ports[id] = (0, utils_1.journeyNodePortAddDefault)({
                id,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: true,
                required: true,
                index: index + 2,
                title: status_code + '',
                color: (status_code >= 200 && status_code < 300) ? '#5bbf1c' : (status_code >= 400 && status_code < 600) ? '#d91010' : null,
            });
        });
        return new_ports;
    },
});
