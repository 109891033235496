"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockSharedQueue = void 0;
const organization_1 = require("./organization");
const list_1 = require("./list");
const enums_1 = require("../../utilities/enums");
const utils_1 = require("../utils");
const organization = (0, organization_1.mockChildOrganization)();
const base = Object.assign({ id: 1, organization_id: organization.id, title: 'Queue One', create_list_id: (0, list_1.mockSharedListOne)().id, read_lists: [(0, list_1.mockSharedListOne)().id,], conversation_form_id: null, inbound_sla_policy_id: null, outbound_sla_policy_id: null, ai_assistant_id: null, settings: {
        require_disposition: true,
        allow_agent_unassign: false,
        require_client: false,
        conversation_assignment: enums_1.ConversationAssignmentEnum.Manually,
    }, clients: [], mail_settings: null, form_settings: null, voice_settings: null, __dispositions: [], __canned_answers: [], __tags: [], auto_tags: {
        voice_transcript: [],
        email: [],
    }, __agent_signatures: [], __outbound_voice_routes: [], __outbound_mail_accounts: [], __users: [] }, utils_1.mockDateObject);
exports.mockSharedQueue = (0, utils_1.mockFunction)(Object.assign({}, base));
