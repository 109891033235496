import {
  conversationsAddFullState,
  conversationsAddFullStateLoading,
  conversationsRemoveFullStateLoading,
  store,
} from "@Store";
import {
  IPreviewConversation,
  IPreviewMailMessage,
  ISharedAgentprofile,
  ISharedConversation,
  ISharedConversationFilter,
  ISharedConversationWatcher,
  ISharedFullConversation,
  ISharedMailMessageAttachmentProperty,
  ISharedPatchConversation,
  ISharedMailMessage,
  ISharedCreateConversation,
  ISharedDraft,
  IPreviewFormSubmission,
  ISharedCustomerJourney,
  IConversationTimeline,
  ISharedFormSubmissionAttachmentProperty,
  IAiAssistantResponse,
  Nullable,
  ICreateOutboundVoiceConversationResponse,
  ICreateOutboundVoiceConversationRequest,
  ICreateInternalVoiceConversationRequest,
  ICreateInternalVoiceConversationResponse,
  IConversationSummary,
} from "atlas-shared/dist";
import { IPaginationProps } from "../types";
import { download, getBlob, RestRequest } from "@Utils";
import { checkFlag } from "./featureFlagApi";
import { getAccesToken } from "@Components";

export * from "./abstract/conversation.api.abstract";

export const fetchConversations = async (
  conversation_filter_id: ISharedConversationFilter["id"],
  agentprofile_id: ISharedAgentprofile["id"],
  useNewFilterApi: boolean,
  pagination?: IPaginationProps
): Promise<Array<ISharedConversation>> => {
  const query: Record<string, any> = { socket_id: global.socketId };

  if (pagination) {
    query.limit = pagination.limit;
    query.offset = pagination.offset;
  }

  if (useNewFilterApi) {
    const conversations = await RestRequest.get<Array<any>>(
      `api/v1/conversation_filter/${conversation_filter_id}/conversations`,
      query
    );

    const convertedConversations = conversations.map((conversation) => {
      return convertObjectKeysToSnakeCase(conversation);
    });

    return convertedConversations;
  }

  return await RestRequest.get<Array<ISharedConversation>>(
    `conversation_filter/${conversation_filter_id}/${agentprofile_id}/conversations`,
    query
  );
};

const pascalToSnake = (str: string): string => {
  return str
    .replace(/([A-Z])/g, (match, offset) => (offset ? "_" : "") + match.toLowerCase())
    .replace(/^_/, ""); // Remove leading underscore if present
};

const convertObjectKeysToSnakeCase = (obj: any): any => {
  if (typeof obj == "string") return obj;
  if (typeof obj == "number") return obj;
  if (typeof obj == "boolean") return obj;
  if (obj == null) return null;
  if (obj == undefined) return null;
  if (obj instanceof Date) return obj.toISOString();
  if (Array.isArray(obj)) return obj.map((v) => convertObjectKeysToSnakeCase(v));

  const result = {};
  for (const key in obj) {
    result[pascalToSnake(key)] = convertObjectKeysToSnakeCase(obj[key]);
  }

  return result;
};

export const fetchConversationCount = async (
  conversation_filter_id: ISharedConversationFilter["id"],
  agentprofile_id: ISharedAgentprofile["id"]
): Promise<number> => {
  return RestRequest.get<number>(
    `conversation_filter/${conversation_filter_id}/${agentprofile_id}/count`
  );
};

export const patchConversation = (
  organization_id: IPreviewConversation["organization_id"],
  id: ISharedConversation["id"],
  payload: ISharedPatchConversation
) => {
  return RestRequest.patch<any, ISharedFullConversation>(
    `conversation/p/${organization_id}/${id}`,
    payload
  );
};

export const fetchConversation = async (
  organization_id: IPreviewConversation["organization_id"],
  id: ISharedConversation["id"]
) => {
  return RestRequest.get<ISharedFullConversation>(`conversation/p/${organization_id}/${id}`);
};

export const fetchFullConversation = async (
  organization_id: IPreviewConversation["organization_id"],
  id: ISharedConversation["id"],
  source?: ISharedConversationWatcher["source"],
  session?: ISharedConversationWatcher["session_id"],
  dispatch: boolean = true
) => {
  if (dispatch) store.dispatch(conversationsAddFullStateLoading(id));

  const query = [
    ["source", source],
    ["socket_id", session],
  ].filter((q) => !!q[1]);

  const full_conversation: ISharedFullConversation = await RestRequest.get<ISharedFullConversation>(
    `conversation/p/${organization_id}/${id}?${query.map((q) => `${q[0]}=${q[1]}`).join("&")}`
  );

  if (dispatch) {
    store.dispatch(conversationsRemoveFullStateLoading(id));
    store.dispatch(conversationsAddFullState(full_conversation));
  }

  // if (source === ConversationWatcherSourceEnum.Dashboard)  {
  //   store.dispatch(currentDashboardConversation(full_conversation.id));
  //   store.dispatch(conversationsRemoveFullState());
  // }

  return full_conversation;
};

export const fetchConversationCustomerJourney = async (
  organization_id: IPreviewConversation["organization_id"],
  id: ISharedConversation["id"],
  limit: number,
  skip: number
): Promise<Array<ISharedCustomerJourney>> => {
  return RestRequest.get<Array<ISharedCustomerJourney>>(
    `conversation/p/${organization_id}/${id}/customer_journey?limit=${limit}&skip=${skip}`
  );
};

export const fetchConversationJourney = (
  organization_id: IPreviewConversation["organization_id"],
  id: ISharedConversation["id"]
): Promise<ISharedFullConversation["journey"]> => {
  return RestRequest.get<ISharedFullConversation["journey"]>(
    `conversation/p/${organization_id}/${id}/journey`
  );
};

// export const sendMailMessage = (conversation_id: ISharedConversation['id'], body: ISharedCreateConversation['__mail_message']) => {
//   return RestRequest.post<ISharedCreateConversation['__mail_message'], ISharedMailMessage>(`conversation/${conversation_id}/mail_message`, body);
// };

export const fetchDownloadMailMessageAttachment = (
  organization_id: IPreviewConversation["organization_id"],
  mail_message_id: IPreviewMailMessage["id"],
  unique_filename: ISharedMailMessageAttachmentProperty["unique_filename"],
  filename: ISharedMailMessageAttachmentProperty["filename"],
  onError?: (error) => void
) => {
  return download(
    `mail_message/p/${organization_id}/${mail_message_id}/attachment/${
      unique_filename || filename
    }`,
    filename,
    onError
  );
};

export const fetchMailMessageAttachmentBlob = (
  organization_id: IPreviewConversation["organization_id"],
  mail_message_id: IPreviewMailMessage["id"],
  unique_filename: ISharedMailMessageAttachmentProperty["unique_filename"],
  filename: ISharedMailMessageAttachmentProperty["filename"],
  onError?: (error) => void
) => {
  return getBlob(
    `mail_message/p/${organization_id}/${mail_message_id}/attachment/${
      unique_filename || filename
    }`,
    filename,
    onError
  );
};

export const fetchMailMessageAttachmentBlobStatic = async (
  selector: string,
  organization_id: IPreviewConversation["organization_id"],
  mail_message_id: IPreviewMailMessage["id"],
  unique_filename: ISharedMailMessageAttachmentProperty["unique_filename"],
  filename: ISharedMailMessageAttachmentProperty["filename"]
) => {
  const img: Nullable<HTMLImageElement> = document.querySelector(`#${selector}`);

  if (!img) return;

  const blob = await getBlob(
    `mail_message/p/${organization_id}/${mail_message_id}/attachment/${
      unique_filename || filename
    }`,
    filename
  );
  const urlCreator = window.URL || window.webkitURL;

  img.src = urlCreator.createObjectURL(blob);
};

export const fetchDownloadFormSubmissionAttachment = (
  organization_id: IPreviewConversation["organization_id"],
  form_submission_id: IPreviewFormSubmission["id"],
  filename: ISharedFormSubmissionAttachmentProperty["filename"],
  index: number,
  onError?: (error) => void
) => {
  return download(
    `form_submission/p/${organization_id}/${form_submission_id}/attachment/${index}`,
    filename,
    onError
  );
};

export const fetchFormSubmissionAttachmentBlob = (
  organization_id: IPreviewConversation["organization_id"],
  form_submission_id: IPreviewFormSubmission["id"],
  filename: ISharedFormSubmissionAttachmentProperty["filename"],
  index: number,
  onError?: (error) => void
) => {
  return getBlob(
    `form_submission/p/${organization_id}/${form_submission_id}/attachment/${index}`,
    filename,
    onError
  );
};

export const mergeConversations = async (
  organization_id: IPreviewConversation["organization_id"],
  conversation_id: IPreviewConversation["id"],
  primary_id: IPreviewConversation["id"]
): Promise<boolean> => {
  return RestRequest.put<{ primary_id: IPreviewConversation["id"] }, boolean>(
    `conversation/p/${organization_id}/${conversation_id}/merge`,
    { primary_id }
  );
};

export const readConversation = (
  organization_id: IPreviewConversation["organization_id"],
  id: ISharedConversation["id"]
) => {
  return RestRequest.put<any, ISharedFullConversation>(
    `conversation/p/${organization_id}/${id}/read`,
    {}
  );
};

export const resendMailMessage = (
  organization_id: IPreviewConversation["organization_id"],
  id: ISharedMailMessage["id"]
) => {
  return RestRequest.put<any, ISharedFullConversation>(
    `mail_message/p/${organization_id}/${id}/resend`,
    {}
  );
};

export const createConversation = (
  payload: ISharedCreateConversation,
  draft_id: ISharedDraft["id"]
) => {
  return RestRequest.post<any, ISharedConversation>(`conversation/${draft_id}`, payload, {
    socket_id: global.socketId,
  });
};

export const fetchConversationTimeline = async (
  organization_id: IPreviewConversation["organization_id"],
  id: ISharedConversation["id"]
) => {
  return RestRequest.get<{ events: Array<IConversationTimeline> }>(
    `conversation/p/${organization_id}/${id}/timeline`
  );
};

export const fetchConversationMessageTimeline = async (
  organization_id: IPreviewConversation["organization_id"],
  id: ISharedConversation["id"],
  message_id: ISharedMailMessage["id"]
) => {
  return RestRequest.get<Array<IConversationTimeline>>(
    `conversation/p/${organization_id}/${id}/${message_id}/timeline`
  );
};

export const askAssistant = (
  organization_id: IPreviewConversation["organization_id"],
  id: ISharedConversation["id"],
  message_id: ISharedMailMessage["id"]
) => {
  return RestRequest.get<IAiAssistantResponse>(
    `conversation/p/${organization_id}/${id}/${message_id}/assistance`,
    { socket_id: global.socketId }
  );
};

export const fetchDownloadMailMessageEml = (
  organization_id: IPreviewConversation["organization_id"],
  mail_message_id: IPreviewMailMessage["id"],
  onError?: (error) => void
) => {
  return download(
    `mail_message/p/${organization_id}/${mail_message_id}/eml`,
    `message_${mail_message_id}.eml`,
    onError
  );
};

export const voiceConversationCallOutbound = async (
  body: ICreateOutboundVoiceConversationRequest
): Promise<ICreateOutboundVoiceConversationResponse> => {
  if (await checkFlag("voice.ari", false)) {
    return fetch(process.env.REACT_APP_VOICE_BASE_URL + "/api/v1/voice_call/dial", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAccesToken(),
      },
      body: JSON.stringify(body),
    }) as any;
  } else {
    return RestRequest.post<
      ICreateOutboundVoiceConversationRequest,
      ICreateOutboundVoiceConversationResponse
    >("conversation/voice/outbound/", body);
  }
};

export const voiceConversationCallInternal = (
  body: ICreateInternalVoiceConversationRequest
): Promise<ICreateInternalVoiceConversationResponse> => {
  return RestRequest.post<
    ICreateInternalVoiceConversationRequest,
    ICreateInternalVoiceConversationResponse
  >("conversation/voice/internal/", body);
};

export const fetchConversationSummaries = (conversationId: ISharedConversation["id"]) => {
  return RestRequest.get<IConversationSummary[]>(`api/v1/conversation/${conversationId}/summaries`);
};

export const summarizeConversation = (conversationId: ISharedConversation["id"]) => {
  return RestRequest.post<IConversationSummary, IConversationSummary>(
    `api/v1/conversation/${conversationId}/summarize`,
    {} as IConversationSummary
  );
};
