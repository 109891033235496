"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockPreviewTabMini = exports.mockSharedTabMini = void 0;
const organization_1 = require("./organization");
const user_1 = require("./user");
const conversation_full_1 = require("./conversation.full");
const enums_1 = require("../../utilities/enums");
const utils_1 = require("../utils");
const base = {
    id: 1,
    organization_id: (0, organization_1.mockChildOrganization)().id,
    user_id: (0, user_1.mockSharedUserOne)().id,
    conversation_id: (0, conversation_full_1.mockFullConversation)().id,
    main_channel: enums_1.ChannelEnum.Voice,
    agentprofile_id: 1,
    size: enums_1.TabSizeEnum.Mini,
    order: 1,
    created_at: utils_1.mockDateTime,
};
exports.mockSharedTabMini = (0, utils_1.mockFunction)(Object.assign({}, base));
exports.mockPreviewTabMini = (0, utils_1.mockFunction)(Object.assign({}, base));
