"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyActionRuleContact = exports.RULE_CONTACT_CONDITION_KEY_OPTIONS = exports.WHEN_CONTACT_CONDITION_CHANGED_OPTIONS = void 0;
const base_1 = require("./base");
const enums_1 = require("../../enums");
const constants_1 = require("../constants");
const misc_1 = require("../../misc");
const utils_1 = require("../utils");
exports.WHEN_CONTACT_CONDITION_CHANGED_OPTIONS = [
    'first_name',
    'last_name',
    'emails',
    'phones',
];
exports.RULE_CONTACT_CONDITION_KEY_OPTIONS = [
    'contact.first_name',
    'contact.last_name',
    'contact.emails',
    'contact.phones',
    'contact.data',
];
const condition_validation = {
    type: 'object',
    properties: {
        key: {
            type: 'string',
            valid: exports.RULE_CONTACT_CONDITION_KEY_OPTIONS,
            required: true,
        },
        data_key: {
            type: 'string',
            when: [
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'string',
                        required: true,
                        valid: [
                            'contact.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        required: true,
                    },
                    otherwise: {
                        type: 'any',
                        forbidden: true,
                    },
                },
            ],
        },
        comparison: {
            type: 'string',
            valid: [],
            required: true,
            when: [
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'string',
                        required: true,
                    },
                    then: {
                        type: 'any',
                        required: true,
                    },
                    otherwise: {
                        type: 'any',
                        forbidden: true,
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.first_name',
                            'contact.last_name',
                            'contact.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IsEmpty,
                            enums_1.JourneyConditionComparisonEnum.IsNotEmpty,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.emails',
                            'contact.phones',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.ArrayLength,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.first_name',
                            'contact.last_name',
                            'contact.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.EqStr,
                            enums_1.JourneyConditionComparisonEnum.nEqStr,
                            enums_1.JourneyConditionComparisonEnum.Contains,
                            enums_1.JourneyConditionComparisonEnum.NotContains,
                            enums_1.JourneyConditionComparisonEnum.StartsWith,
                            enums_1.JourneyConditionComparisonEnum.NotStartsWith,
                            enums_1.JourneyConditionComparisonEnum.EndsWith,
                            enums_1.JourneyConditionComparisonEnum.NotEndsWith,
                            enums_1.JourneyConditionComparisonEnum.AnyOfStr,
                            enums_1.JourneyConditionComparisonEnum.NoneOfStr,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.first_name',
                            'contact.last_name',
                            'contact.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.strictEq,
                            enums_1.JourneyConditionComparisonEnum.strictNotEq,
                            enums_1.JourneyConditionComparisonEnum.AnyOfNum,
                            enums_1.JourneyConditionComparisonEnum.NoneOfNum,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IsTruly,
                            enums_1.JourneyConditionComparisonEnum.IsFalsy,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IsDefined,
                            enums_1.JourneyConditionComparisonEnum.IsUndefined,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.first_name',
                            'contact.last_name',
                            'contact.data',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IsNull,
                            enums_1.JourneyConditionComparisonEnum.IsNotNull,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.emails',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.Includes,
                            enums_1.JourneyConditionComparisonEnum.NotIncludes,
                        ],
                    },
                },
                {
                    reference: { '$ref': 'key', },
                    is: {
                        type: 'any',
                        valid: [
                            'contact.phones',
                        ],
                    },
                    then: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IncludesPhoneNumber,
                            enums_1.JourneyConditionComparisonEnum.NotIncludesPhoneNumber,
                        ],
                    },
                },
            ],
        },
        value: {
            type: 'any',
            when: [
                {
                    reference: { '$ref': 'comparison', },
                    is: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.IsNull,
                            enums_1.JourneyConditionComparisonEnum.IsNotNull,
                            enums_1.JourneyConditionComparisonEnum.IsFalsy,
                            enums_1.JourneyConditionComparisonEnum.IsTruly,
                            enums_1.JourneyConditionComparisonEnum.IsEmpty,
                            enums_1.JourneyConditionComparisonEnum.IsNotEmpty,
                            enums_1.JourneyConditionComparisonEnum.IsUndefined,
                            enums_1.JourneyConditionComparisonEnum.IsDefined,
                            enums_1.JourneyConditionComparisonEnum.AnyOfNum,
                            enums_1.JourneyConditionComparisonEnum.NoneOfNum,
                            enums_1.JourneyConditionComparisonEnum.AnyOfStr,
                            enums_1.JourneyConditionComparisonEnum.NoneOfStr,
                        ],
                    },
                    then: { type: 'any', forbidden: true, },
                    otherwise: { type: 'any', required: true, },
                },
                {
                    reference: { '$ref': 'comparison', },
                    is: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.EqPhoneNumber,
                            enums_1.JourneyConditionComparisonEnum.nEqPhoneNumber,
                            enums_1.JourneyConditionComparisonEnum.IncludesPhoneNumber,
                            enums_1.JourneyConditionComparisonEnum.NotIncludesPhoneNumber,
                        ],
                    },
                    then: { type: 'string', meta: { phone: true, }, },
                },
                {
                    reference: { '$ref': 'comparison', },
                    is: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.GtDateTime,
                            enums_1.JourneyConditionComparisonEnum.LtDateTime,
                        ],
                    },
                    then: { type: 'date', },
                },
                {
                    reference: { '$ref': 'comparison', },
                    is: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.Gt,
                            enums_1.JourneyConditionComparisonEnum.Gte,
                            enums_1.JourneyConditionComparisonEnum.nEqNum,
                            enums_1.JourneyConditionComparisonEnum.EqNum,
                            enums_1.JourneyConditionComparisonEnum.Lt,
                            enums_1.JourneyConditionComparisonEnum.Lte,
                            enums_1.JourneyConditionComparisonEnum.IncludesNum,
                        ],
                    },
                    then: { type: 'number', },
                },
            ],
        },
        value_arr: {
            type: 'array',
            min: 1,
            items: {
                type: 'any',
                when: [
                    {
                        reference: { '$ref': '...comparison', },
                        is: {
                            type: 'any',
                            valid: [
                                enums_1.JourneyConditionComparisonEnum.AnyOfNum,
                                enums_1.JourneyConditionComparisonEnum.NoneOfNum,
                            ],
                        },
                        then: { type: 'number', },
                        otherwise: { type: 'string', },
                    },
                ],
            },
            when: [
                {
                    reference: { '$ref': 'comparison', },
                    is: {
                        type: 'any',
                        valid: [
                            enums_1.JourneyConditionComparisonEnum.AnyOfNum,
                            enums_1.JourneyConditionComparisonEnum.NoneOfNum,
                            enums_1.JourneyConditionComparisonEnum.AnyOfStr,
                            enums_1.JourneyConditionComparisonEnum.NoneOfStr,
                        ],
                    },
                    then: { type: 'any', required: true, },
                    otherwise: { type: 'any', forbidden: true, },
                },
            ],
        },
    },
};
exports.JourneyActionRuleContact = (0, base_1.journeyAction)({
    action: enums_1.JourneyActionEnum.RuleContact,
    initialValues: {
        conditions: [{
                type: enums_1.ConditionAndOr.AND,
                rules: [],
            },],
    },
    channels: (0, misc_1.EnumValues)(enums_1.ChannelEnum),
    settings_schema: (0, base_1.settingsValidationSchema)({
        conditions: {
            type: 'array',
            min: 1,
            items: {
                type: 'object',
                properties: {
                    type: {
                        type: 'string',
                        valid: (0, misc_1.EnumValues)(enums_1.ConditionAndOr),
                        default: enums_1.ConditionAndOr.AND,
                        required: true,
                        when: [
                            {
                                reference: { '$ref': 'rules', },
                                is: { type: 'array', },
                                then: { type: 'any', },
                                otherwise: { type: 'any', meta: { hide: true, }, },
                            },
                            {
                                reference: { '$ref': 'rules', },
                                is: { type: 'array', length: 0, },
                                then: { type: 'any', meta: { hide: true, }, },
                            },
                            {
                                reference: { '$ref': 'rules', },
                                is: { type: 'array', length: 1, },
                                then: { type: 'any', meta: { hide: true, }, },
                            },
                        ],
                    },
                    rules: {
                        type: 'array',
                        min: 1,
                        items: condition_validation,
                        default: [],
                    },
                },
            },
        },
    }),
    getPorts: (node) => {
        let ports = Object.assign({}, base_1.journeyDefaultInputPort);
        const properties = node.properties;
        properties.conditions.forEach((condition, index) => {
            const id = `${constants_1.JOURNEY_ACTION_RULE_IF_OUTPUT}_${index}`;
            ports[id] = (0, utils_1.journeyNodePortAddDefault)({
                id,
                type: enums_1.JourneyPortDirectionEnum.Output,
                removable: false,
                required: true,
                title: `RULE_${index + 1}`,
                color: null,
                index,
            });
        });
        ports[constants_1.JOURNEY_ACTION_RULE_ELSE_OUTPUT] = (0, utils_1.journeyNodePortAddDefault)({
            id: constants_1.JOURNEY_ACTION_RULE_ELSE_OUTPUT,
            type: enums_1.JourneyPortDirectionEnum.Output,
            removable: false,
            required: false,
            title: 'OTHERWISE',
            color: null,
            index: properties.conditions.length,
        });
        return ports;
    },
});
