import React, { useEffect, useMemo, useState } from "react";
import "./conversation.message.scss";
import {
  ISharedFullConversation,
  ISharedInternalNote,
  ISharedMailMessage,
  TypeConversationMessage,
  ChannelEnum,
  IPreviewUser,
  TConversationVoiceCall,
  ISharedFormSubmission,
  IPreviewTab,
  TConversationTextMessage,
} from "atlas-shared";
import { ConversationMessageInternalNote } from "./conversation.message.internal_note";
import { ConversationMessageVoiceCall } from "./conversation.message.voice_call";
import { ConversationMessageFormSubmission } from "./conversation.message.form_submission";
import { ConversationMessageMail } from "./conversation.message.mail";
import { IAuth } from "@Store";
import { TFunction } from "i18next";
import { THighlightedMessage, useUsers } from "@Hooks";
import { ConversationMessageAvatar } from "./conversation.message.avatar";
import { ConversationMessageText } from "./conversation.message.text/conversationMessageText";

interface IProps {
  dispatch: any;
  t: TFunction;
  auth: IAuth;
  index: number;
  message: TypeConversationMessage;
  conversation: ISharedFullConversation;
  highlightedMessage: THighlightedMessage;
  tab?: IPreviewTab;
  scrolled: boolean;
}

export const ConversationMessage = React.memo(
  ({
    dispatch,
    t,
    auth,
    conversation,
    message,
    highlightedMessage,
    tab,
    index,
    scrolled,
  }: IProps) => {
    const [user, setUser] = useState<IPreviewUser>();
    const users = useUsers();

    useEffect(() => {
      if ((message as any).user_id) setUser(users.dict[(message as any).user_id]);
    }, []);

    const avatar = useMemo(() => <ConversationMessageAvatar user={user} />, [user]);

    const isTextMessage = (
      message: TypeConversationMessage
    ): message is TConversationTextMessage => {
      return message.channel === ChannelEnum.Sms;
    };

    const isInternalNote = (message: TypeConversationMessage): message is ISharedInternalNote => {
      return message.channel === ChannelEnum.InternalNote;
    };

    const isMailMessage = (message: TypeConversationMessage): message is ISharedMailMessage => {
      return message.channel === ChannelEnum.Mail;
    };

    const isFormSubmission = (
      message: TypeConversationMessage
    ): message is ISharedFormSubmission => {
      return message.channel === ChannelEnum.Form;
    };

    const isVoiceCall = (message: TypeConversationMessage): message is TConversationVoiceCall => {
      return message.channel === ChannelEnum.Voice;
    };

    return (
      <span id={`conversation-message-${highlightedMessage[4]}-${message.channel}-${message.id}`}>
        {isInternalNote(message) && (
          <ConversationMessageInternalNote
            conversation={conversation}
            message={message}
            t={t}
            auth={auth}
            avatar={avatar}
            highlightedMessage={highlightedMessage}
            tab={tab}
          />
        )}
        {isMailMessage(message) && (
          <ConversationMessageMail
            dispatch={dispatch}
            index={index}
            conversation={conversation}
            message={message}
            t={t}
            auth={auth}
            avatar={avatar}
            highlightedMessage={highlightedMessage}
            tab={tab}
            scrolled={scrolled}
          />
        )}
        {isFormSubmission(message) && (
          <ConversationMessageFormSubmission
            dispatch={dispatch}
            conversation={conversation}
            message={message}
            t={t}
            auth={auth}
            highlightedMessage={highlightedMessage}
          />
        )}
        {isVoiceCall(message) && (
          <ConversationMessageVoiceCall
            conversation={conversation}
            message={message}
            auth={auth}
            highlightedMessage={highlightedMessage}
          />
        )}
        {isTextMessage(message) && (
          <ConversationMessageText
            conversation={conversation}
            message={message}
            auth={auth}
            highlightedMessage={highlightedMessage}
            avatar={avatar}
          />
        )}
      </span>
    );
  }
);
