import * as React from "react";
import { Form, ModalProps, Typography } from "antd";
import { FormItem } from "./form.item";
import {
  IForm,
  IFormOptions,
  IFormPreviews,
  JSONForm,
} from "../atlas-form-core";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Joi from "joi";
import "./styles.scss";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import { clone } from "@Utils";
import AppSpinner from "../../components/misc/app-spinner/app.spinner";
import { FormItemProps, IModals, ISetModal } from "./utils";
import { Button } from "../../components/misc";
import { AudioRecorder } from "./audio-recorder";
import { actionAddPopupModal, IGlobalPopupModal } from "@Store";

interface IProps<Values, Entity = any> {
  formLayout: IForm;
  form: Joi.Schema;
  antForm?: FormInstance<Values>;
  initialValues: Partial<Values>;
  options?: IFormOptions;
  previews?: IFormPreviews;
  additionalParams?: IForm["additionalParams"];
  components?: IForm["components"];
  onValuesChange?: (
    changedValues: any,
    values: Values,
    form?: JSONForm
  ) => void;
  onFinish?: (values: Values) => any;
  confirmRequired?: string;
  onFinishFailed?: Function;
  saving?: boolean;
  submitBtn?: boolean;
  entityId?: IForm["entity_id"];
  onCancel?: () => void;
  title?: string;
  entity?: Entity;
  hasAdvanced?: boolean;
  dispatch?: any;
  saveBtnTitle?: string;
}

function AtlasForm<Values>(props: IProps<Values>): ReactElement {
  const { Title } = Typography;
  const [form, setForm] = useState<JSONForm>();
  const [iForm, setIForm] = useState<IForm>();
  const { t } = useTranslation();
  const [__antForm] = Form.useForm();
  const antForm = props.antForm || __antForm;
  const [modals, setModals] = useState<IModals>({});
  const [formValues, setFormValues] = useState<IModals>({});
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [options, setOptions] = useState<IFormOptions>(props.options || {});
  const [displayAdvanced, setDisplayAdvanced] = useState(false);
  const setModal: ISetModal = (key: string, visible: ModalProps["visible"]) => {
    setModals({ ...modals, [key]: visible });
  };

  const addGlobalPopupModal = useCallback((popup_modal: IGlobalPopupModal) => {
    props.dispatch?.(actionAddPopupModal(popup_modal));
  }, []);

  const style: React.CSSProperties = {};

  if (props.formLayout.noPadding) style.padding = 0;

  useEffect(() => {
    if (form || iForm) return;

    if (props.initialValues && props.form) {
      const _form = new JSONForm(
        clone(props.formLayout),
        props.form,
        undefined,
        props.options
      )
        .setValues(props.initialValues, true)
        .onValuesChange((values, changedValue, form) =>
          onValuesChange(values, changedValue, form)
        )
        .validate(true);

      antForm.setFieldsValue(_form.getValues());
      props.onValuesChange?.(null, _form.getValues(), form);
      setForm(_form);
      setIForm(_form.getForm());
    }
  }, [props.form, props.initialValues, props.options]);

  useEffect(() => {
    if (!props.options) return;

    if (form && props.options) form.setOptions(props.options);

    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    // for some reason we get here despite no changes to props.initialValues
    if (
      form &&
      JSON.stringify(props.initialValues) !==
        JSON.stringify(form.getInitialValues())
    ) {
      form.setValues({
        ...form.getValues(),
        ...props.initialValues,
      });
      onValuesChange(null, form.getValues(), form);
    }
  }, [props.initialValues]);

  const onValuesChange = (changedValue: any, values: Values, form) => {
    if (!form) return;

    props.onValuesChange?.(null, form.getValues(), form);

    setFormValues(form.getValues());
    setIForm(form.getForm());
  };

  useEffect(() => {
    if (form) onValuesChange(null, form.getValues(), form);
  }, [!!form]);

  const onFinish = (values: Values) => {
    setIsSubmitted(true);

    if (!form?.is_valid) return;

    return props.onFinish?.(values);
  };

  if (!form || !iForm) return <AppSpinner />;

  return (
    <Form
      form={antForm}
      className={`form${props.formLayout.compact ? " compact" : ""}`}
      key={form.getForm().id}
      name={form.getForm().id}
      initialValues={form.getValues()}
      style={style}
      onSubmitCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {props.title && <Title level={2}>{t(props.title)}</Title>}
      <FormItem
        {...FormItemProps({
          form: iForm,
          json_form: form,
          options,
          previews: props.previews || {},
          components: props.components,
          additionalParams: props.additionalParams || {},
          antForm,
          isSubmitted,
          modals,
          name: [],
          path: [],
          entity: props.entity,
          t,
          setModal,
          AudioRecorder: AudioRecorder,
          addGlobalPopupModal: props.dispatch ? addGlobalPopupModal : undefined,
          displayAdvanced,
        })}
      />
      {(props.onCancel || props.submitBtn !== false) && (
        <Form.Item className={"form-submit"}>
          {props.onCancel && (
            <Button
              className="button-cancel"
              onClick={() => props.onCancel?.()}
            >
              {t("CANCEL")}
            </Button>
          )}
          {props.submitBtn !== false && (
            <Button
              type="primary"
              htmlType="submit"
              disabled={!form.is_valid}
              loading={props.saving}
              onClick={() =>
                (props.confirmRequired
                  ? confirm(props.confirmRequired)
                  : true) && onFinish(form.getValues())
              }
            >
              {t(props.saveBtnTitle || "SAVE")}
            </Button>
          )}
        </Form.Item>
      )}
      {props.hasAdvanced && (
        <span
          onClick={() => setDisplayAdvanced(!displayAdvanced)}
          className="advanced_options"
        >
          {displayAdvanced && t("HIDE")} {t("ADVANCED_OPTIONS")}
        </span>
      )}
    </Form>
  );
}

export { AtlasForm };
