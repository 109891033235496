import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AtlasForm, IForm, IFormOptions } from "atlas-form";
import {
  QueueEditForm,
  QueueEditInitialValues,
  QueueComponents,
  QueueAdditionalParams,
  QueueFormOptions,
  onQueueFormSaved,
  IQueueFormOptionsProps,
  AlertError,
  AudioPlayer,
  playSoundFile,
} from "@Utils";
import { ISharedQueue, ISharedPatchQueue, PermissionEnum, CrudEnum } from "atlas-shared";
import { fetchQueue, patchQueue, getQueueUpdateSchema, streamSoundFilename } from "@Api";
import { RuleProtected } from "@Containers";
import { withEdit, IWithEditProps } from "@Hocs/with.edit";
import {
  useAbilities,
  useAuth,
  useOrganizations,
  useLists,
  useClients,
  useForms,
  useSlaPolicies,
  useAiAssistants,
  useSounds,
  useDispositions,
  useCannedAnswers,
  useTags,
  useSignatures,
  useMailAccounts,
  useVoiceRoutes,
  useVoiceAsterisks,
} from "@Hooks";
import { AppSpinner } from "@Components";
import { useAppDispatch } from "@Store";
import { Card, Col, Row, Tag } from "antd";

interface IProps extends IWithEditProps<ISharedQueue> {}

const QueueFormEdit = ({
  id,
  value,
  setValue,
  formSchema,
  schemaLoading,
  saving,
  save,
  title,
  onSaved,
  onCancel,
  onChange,
  hasAdvanced = false,
}: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const lists = useLists();
  const clients = useClients();
  const forms = useForms();
  const sla_policies = useSlaPolicies();
  const ai_assistants = useAiAssistants();
  const voiceAsterisks = useVoiceAsterisks();
  const sounds = useSounds();
  const dispositions = useDispositions();
  const canned_answers = useCannedAnswers();
  const tags = useTags();
  const signatures = useSignatures();
  const mail_accounts = useMailAccounts();
  const voice_routes = useVoiceRoutes();
  const organisation = organizations.organizations.find((org) => org.id == value?.organization_id);

  const asterisk = voiceAsterisks.voice_asterisks.find(
    (astrerisk) => astrerisk.id == organisation?.voice_asterisk_id
  );

  const isARIBased = asterisk?.ari_based;

  useEffect(() => {
    fetchQueue(id).then((queue) => setValue(queue));
  }, [id, setValue]);

  const param_props: IQueueFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    lists: lists.lists,
    clients: clients.clients,
    forms: forms.forms,
    sla_policies: sla_policies.sla_policies,
    ai_assistants: ai_assistants.ai_assistants,
    sounds: sounds.sounds,
    dispositions: dispositions.dispositions,
    canned_answers: canned_answers.canned_answers,
    tags: tags.tags,
    signatures: signatures.signatures,
    mail_accounts: mail_accounts.mail_accounts,
    voice_routes: voice_routes.voice_routes,
  };
  const formLayout: IForm | undefined = useMemo(
    () => value && QueueEditForm(value, param_props, t),
    [
      value,
      organizations,
      lists,
      clients,
      forms,
      sla_policies,
      ai_assistants,
      sounds,
      dispositions,
      canned_answers,
      tags,
      signatures,
      mail_accounts,
      voice_routes,
      t,
      abilities,
    ]
  );
  const options: IFormOptions | undefined = useMemo(
    () => value && QueueFormOptions(value, param_props, t, isARIBased),
    [
      value,
      organizations,
      lists,
      clients,
      forms,
      sla_policies,
      ai_assistants,
      sounds,
      dispositions,
      canned_answers,
      tags,
      signatures,
      mail_accounts,
      voice_routes,
      t,
      abilities,
    ]
  );

  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchQueue, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback(
    (values: ISharedPatchQueue) =>
      value &&
      save(() =>
        patchQueue(value.id, values).then(
          (res) => (onSaved ? onSaved(res, navigate) : onQueueFormSaved(t, "edit", res, navigate)),
          (err) => {
            AlertError(t, {
              content: err,
            });
          }
        )
      ),
    [value, save, navigate, t]
  );

  if (schemaLoading) return <AppSpinner />;

  if (!value) return <>{t("ITEM_NOT_FOUND")}</>;

  if (!formSchema) return <>{t("SCHEMA_NOT_FOUND")}</>;

  if (
    !organizations.loaded ||
    !lists.loaded ||
    !clients.loaded ||
    !forms.loaded ||
    !sla_policies.loaded ||
    !ai_assistants.loaded ||
    !sounds.loaded ||
    !dispositions.loaded ||
    !canned_answers.loaded ||
    !tags.loaded ||
    !signatures.loaded ||
    !mail_accounts.loaded ||
    !voice_routes.loaded
  )
    return <>{t("LOADING_DEPENDENCIES")}</>;

  return (
    <RuleProtected
      isAllowed={() =>
        !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.Queue, value)
      }
    >
      <RuleProtected
        isAllowed={() =>
          abilities.can(
            value.organization_id,
            CrudEnum.Update,
            PermissionEnum.VoiceAsterisk,
            value
          )
        }
      >
        <Card style={{ marginBottom: "1em" }} size="small">
          <Row justify={"space-between"}>
            <Col>Asterisk: {asterisk?.title}</Col>
            <Col>{asterisk?.ari_based ? <Tag>ARI Based</Tag> : <Tag>AGI Based</Tag>}</Col>
          </Row>
        </Card>
      </RuleProtected>

      <AtlasForm<ISharedPatchQueue>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={QueueComponents}
        additionalParams={QueueAdditionalParams(auth, param_props, isARIBased)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={QueueEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t("EDIT")} ${t("QUEUE")}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
        previews={{
          voice_settings_musicclass: (it: ISharedQueue) =>
            it.voice_settings?.musicclass ? (
              <AudioPlayer
                key={it.voice_settings?.musicclass}
                t={t}
                stream={() => playSoundFile(it.voice_settings?.musicclass)}
                size="s"
              />
            ) : (
              <></>
            ),
          voice_settings_announce: (it: ISharedQueue) =>
            it.voice_settings?.announce ? (
              <AudioPlayer
                key={it.voice_settings?.announce}
                t={t}
                stream={() => streamSoundFilename(it.voice_settings?.announce as any)}
                size="s"
              />
            ) : (
              <></>
            ),
          voice_settings_periodic_announce: (it: ISharedQueue) => (
            <>
              {it.voice_settings?.periodic_announce?.map((item) => (
                <AudioPlayer key={item} t={t} stream={() => streamSoundFilename(item)} size="s" />
              ))}
            </>
          ),
        }}
      />
    </RuleProtected>
  );
};

export const QueueFormEditView = React.memo(withEdit(QueueFormEdit, getQueueUpdateSchema));
