import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./conversation.message.mail.scss";
import { AlertError, getContactFullName, highlightClassNames, InfoModal, timeSince } from "@Utils";
import {
  ISharedMailMessage,
  Undefinable,
  DirectionEnum,
  ISharedFullConversation,
  IPreviewMailAccount,
  ISharedMailMessageAttachmentProperty,
  ISharedMailMessageToProperty,
  IPreviewTab,
  ChannelEnum,
  TypeConversationMessage,
} from "atlas-shared";

import { THighlightedMessage, useMailAccounts } from "@Hooks";
import { ConversationMessageMailAttachment } from "./conversation.message.mail.attachment";
import {
  Avatar,
  ConversationMessageReply,
  ConversationMessageReplies,
  Button,
  Icon,
  cleanMailBody,
} from "@Components";
import { TFunction } from "i18next";
import { IAuth } from "@Store";
import { fetchDownloadMailMessageEml, resendMailMessage } from "@Api";
import { DownloadIcon, StarIcon } from "@Assets";

interface IProps {
  t: TFunction;
  dispatch: any;
  auth: IAuth;
  index: number;
  message: ISharedMailMessage;
  conversation: ISharedFullConversation;
  avatar: React.ReactNode;
  highlightedMessage: THighlightedMessage;
  tab?: IPreviewTab;
  scrolled: boolean;
}

const isMailMessage = (message: TypeConversationMessage): message is ISharedMailMessage => {
  return message.channel === ChannelEnum.Mail;
};

export const ConversationMessageMail = React.memo(
  ({
    message,
    conversation,
    t,
    auth,
    avatar,
    highlightedMessage,
    dispatch,
    index,
    tab,
    scrolled,
  }: IProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [mailAccount, setMailAccount] = useState<Undefinable<IPreviewMailAccount>>();
    const mail_accounts = useMailAccounts();
    const hasMoreLimit = useMemo(
      () => (message.direction === DirectionEnum.InBound ? (!index ? 600 : 300) : 200),
      [message]
    );
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [__html, setHtml] = useState<string>(
      cleanMailBody(
        message.body?.html ||
          message.body?.textAsHtml ||
          message.body?.text ||
          `<div class="missing_body">${t("MAIL_HAS_NO_BODY")}</div>`
      )
    );
    const id = `mail-message-${message.id}`;
    const lazyloadClassName = "image-lazyload";
    const lazyloadAttribute = "data-lazyload";

    useEffect(() => {
      setHtml(
        message.anonymized_at
          ? `<div class="anonymized">${t("ANONYMIZED")}</div>`
          : cleanMailBody(
              message.body?.html ||
                message.body?.textAsHtml ||
                message.body?.text ||
                `<div class="missing_body">${t("MAIL_HAS_NO_BODY")}</div>`
            )
      );
    }, [message.body]);

    useEffect(() => {
      if (hasMore) return;

      if (ref.current) {
        setHasMore((ref.current as HTMLElement).clientHeight > hasMoreLimit);
      }
    }, [hasMoreLimit, hasMore]);

    useEffect(() => {
      setMailAccount(mail_accounts.mail_accounts.find((ma) => ma.id === message.mail_account_id));
    }, [mail_accounts, message.mail_account_id]);

    const translateEmail = useCallback(
      (item: ISharedMailMessageToProperty) => {
        let name = item.name;

        if (!name && mailAccount && mailAccount?.address === item.address) name = mailAccount.title;

        if (!name && conversation.contact?.emails.includes(item.address))
          name = getContactFullName(conversation.contact, t, true);

        if (!name)
          return (
            <div className="email-address" title={item.address}>
              {item.address}
              <span className="delimiter"></span>
            </div>
          );

        return (
          <div className="email-address" title={item.address}>
            {name} &lt;{item.address}&gt;<span className="delimiter"></span>
          </div>
        );
      },
      [mailAccount, conversation.contact]
    );

    const displayMailAccount = useMemo(() => {
      return mailAccount ? (
        translateEmail({
          address: mailAccount.address,
          name: mailAccount.title,
        })
      ) : (
        <></>
      );
    }, [mailAccount]);

    const displayContact = useMemo(() => {
      return translateEmail({
        address: message.contact_email,
        name: conversation.contact && getContactFullName(conversation.contact, t, true),
      });
    }, [mailAccount]);

    const from = useMemo(() => {
      return message.direction === DirectionEnum.Outbound ? displayMailAccount : displayContact;
    }, [displayMailAccount, displayContact]);

    const to = useMemo(() => {
      return message.to?.length
        ? message.to.map(translateEmail)
        : message.direction === DirectionEnum.Outbound
        ? displayContact
        : displayMailAccount;
    }, [message, displayMailAccount, displayContact]);

    const cidReplace = useCallback(() => {
      const attachments = conversation.messages.flatMap((message) =>
        ((message as ISharedMailMessage).attachments || []).map((attachment) => ({
          attachment,
          message,
        }))
      );

      if (!__html || !message.attachments?.length) return;

      const regExp = /<img([^>]*)>/gim;
      const matches = __html.match(regExp) || [];

      const replacements = matches.map((img) => {
        const [cidMatch] = img.match(/"cid:[^"]*"/gi) || [];
        const [idMatch] = img.match(/id="[^"]*"/gi) || [];
        const [styleMatch] = img.match(/style="[^"]*"/gi) || [];
        const [classMatch] = img.match(/class="[^"]*"/gi) || [];
        const hasWidth =
          !!img.match(/width="[^"]*"/gi)?.length ||
          (styleMatch?.[0]?.includes("width:") && !styleMatch?.[0]?.includes("max-width:"));

        if (!cidMatch) return img;

        const filename = cidMatch.substr(5, cidMatch.length - 6);
        const { message: _message, attachment } =
          attachments?.find(
            ({ attachment }) => attachment.filename === filename || attachment.cid === filename
          ) || {};

        if (!attachment || !_message || !isMailMessage(_message)) return img;

        if (styleMatch) img = img.replace(styleMatch, "");

        if (classMatch) img = img.replace(classMatch, "");

        if (idMatch) img = img.replace(idMatch, "");

        const selector =
          /* idMatch ? idMatch.substr(4, idMatch.length - 5) : */ `img-lazy-load-${Math.random()}`.replace(
            "0.",
            ""
          );
        const height = Math.max(attachment.size, 100);
        const style = !hasWidth
          ? `style="min-width: 1px; ${styleMatch?.substr(7, styleMatch?.length - 8) || ""}"`
          : styleMatch || "";
        const _class = [
          (classMatch || "").substr(7, classMatch?.length - 8),
          lazyloadClassName,
        ].join(" ");

        return img.replace(
          cidMatch,
          `'/1px.png' ${lazyloadAttribute}="fetchMailMessageAttachmentBlobStatic('${selector}', ${
            _message!.organization_id
          }, ${_message!.id}, '${attachment.unique_filename || attachment.filename}', '${
            attachment.filename
          }', e => alert(e.toString()))" class="${_class}" id="${selector}" ${style} height="${height}"`
        );
      });

      let index = -1;

      setHtml(
        __html.replaceAll(regExp, (_) => {
          index++;
          return (replacements[index] as string) || "";
        })
      );
    }, [message.body]);

    const loadImagesOnShowMore = useCallback(() => {
      const images = ref.current!.querySelectorAll(`.${lazyloadClassName}`);

      if (images?.length)
        [].forEach.call(images, (el: HTMLImageElement) => {
          const lazyload = el.getAttribute(lazyloadAttribute);

          if (lazyload) {
            eval(lazyload);
            el.removeAttribute(lazyloadAttribute);
            el.className = el.className.replace(lazyloadClassName, "");
          }
        });
    }, []);

    useEffect(() => {
      loadImagesOnVisibility();
    }, [__html]);

    useEffect(() => {
      if (showMore) loadImagesOnVisibility();
    }, [scrolled, showMore]);

    useEffect(() => {
      cidReplace();
    }, [message.body]);

    const loadImagesOnVisibility = useCallback(() => {
      if (!ref.current) return setTimeout(() => loadImagesOnVisibility(), 1000);

      const images = ref.current!.querySelectorAll(`.${lazyloadClassName}`);
      const topParent = ref.current?.getBoundingClientRect().top;

      if (images?.length)
        [].forEach.call(images, (el: HTMLImageElement) => {
          const top = showMore
            ? el.getBoundingClientRect().top - window.innerHeight
            : el.getBoundingClientRect().top - topParent;

          if (top > 150) return;

          const lazyload = el.getAttribute(lazyloadAttribute);

          if (lazyload) {
            eval(lazyload);
            el.removeAttribute(lazyloadAttribute);
            el.className = el.className.replace(lazyloadClassName, "");
          }
        });
    }, [showMore]);

    return (
      <div
        className={`conversation-message conversation-message-mail${
          message.auto_reply ? " auto_reply" : ""
        } ${
          message.direction === DirectionEnum.InBound ? "inbound" : "outbound"
        }${highlightClassNames(message, highlightedMessage)}`}
        id={id}
      >
        <ConversationMessageReply t={t} auth={auth} message={message} conversation={conversation} />
        <div className="conversation-message-content">
          {message.smtp_error && (
            <div className="smtp-error">
              <Button
                type="primary"
                size="small"
                onClick={() =>
                  resendMailMessage(message.organization_id, message.id)
                    .then((_) => InfoModal(t, { content: t("RESEND_INITIATED") }))
                    .catch((e) => AlertError(t, { content: t(e) }))
                }
              >
                {t("RESEND_EMAIL")}
              </Button>
              <div className="title">
                <span>{t("SMTP_ERROR")}</span>{" "}
                {message.handled_at && timeSince(t, new Date(message.handled_at), true)}
              </div>
              <p className="description">{message.smtp_error}</p>
            </div>
          )}
          <div className={"head"}>
            <div className={"info"}>
              <div className={"time"}>{timeSince(t, new Date(message.created_at), true)}</div>
              <div className={"title subject"} key="message_title">
                {message.subject ? (
                  message.subject
                ) : (
                  <span style={{ opacity: 0.5 }}>{t("NO_SUBJECT")}</span>
                )}
                <Icon
                  icon={DownloadIcon}
                  onClick={() => fetchDownloadMailMessageEml(message.organization_id, message.id)}
                  className="eml-download"
                  title={t("DOWNLOAD_EML")}
                />
              </div>
              {
                <div className={"extras"} key="message_from">
                  <span className="t">{t("FROM")}</span> {from}
                </div>
              }
              {
                <div className={"extras"} key="message_to">
                  <span className="t">{t("TO")}</span> <div className="email-addresses">{to}</div>
                </div>
              }
              {message.cc?.length > 0 && (
                <div key="message_cc" className={"extras"}>
                  <span className="t">{t("CC")}</span>{" "}
                  <div className="email-addresses">{message.cc.map(translateEmail)}</div>
                </div>
              )}
              {message.bcc?.length > 0 && (
                <div key="message_bcc" className={"extras"}>
                  <span className="t">{t("BCC")}</span>{" "}
                  <div className="email-addresses">
                    {message.bcc.map((bcc) => translateEmail({ address: bcc }))}
                  </div>
                </div>
              )}
              {message.attachments && (
                <div className={"attachments"}>
                  {message.attachments.map((attachment, index) => (
                    <ConversationMessageMailAttachment
                      dispatch={dispatch}
                      key={index}
                      organization_id={message.organization_id}
                      attachments={
                        message.attachments as Array<ISharedMailMessageAttachmentProperty>
                      }
                      index={index}
                      attachment={attachment}
                      mail_message_id={message.id}
                      t={t}
                      anonymized={!!message.anonymized_at}
                    />
                  ))}
                </div>
              )}
            </div>
            {message.direction === DirectionEnum.Outbound && avatar}
            {message.direction === DirectionEnum.InBound && conversation.contact && (
              <div className={"user"}>
                <Avatar
                  size={24}
                  style={{ background: "var(--grey-middle)", color: "var(--blue-grey)" }}
                />
                <div className={"user-info"}>
                  <span>{getContactFullName(conversation.contact, t)}</span>
                  {message.contact_email && <span>{`<${message.contact_email || ""}>`}</span>}
                </div>
              </div>
            )}
            {message.points && (
              <div className="points">
                <div className="title"></div>
                <div className="value">
                  <Icon
                    icon={StarIcon}
                    tooltip={{ title: `${t("POINTS_MAIL_SENT")}: ${message.points}p` }}
                    iconProps={{ style: { stroke: "gold", fill: "gold" } }}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            ref={ref}
            className={`isolated-mail-body more-wrapper${showMore ? " show-more" : ""}${
              hasMore ? " has-more" : ""
            }`}
            style={{ maxHeight: hasMore ? hasMoreLimit : "auto" }}
            dangerouslySetInnerHTML={{ __html }}
          ></div>
          {hasMore && (
            <div
              className={`more-actions${showMore ? " show-more" : ""}`}
              onClick={() => {
                setShowMore(!showMore);
                //loadImagesOnShowMore();
              }}
            >
              <div className={"action"}>
                <span>{t(showMore ? "SHOW_LESS" : "SHOW_MORE")}</span>
              </div>
            </div>
          )}
        </div>
        <ConversationMessageReplies
          message={message}
          conversation={conversation}
          highlightedMessage={highlightedMessage}
        />
      </div>
    );
  }
);
