import React, { useCallback, useMemo } from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./stats_report.layout.scss";
import "./stats_report.dashboard.layout.scss";
import debounce from "lodash/debounce";

import {
  IStatsReportChildProps,
  IWithStatsReportProps,
  withStatsReport,
} from "@Hocs/with.stats_report";
import { patchStatsReportWidget } from "@Api";
import { IPreviewStatsReportWidget } from "atlas-shared/dist";
import { StatsWidgetView } from "@Components/stats/widget-types/stats.widget.view";
import { useTranslation } from "react-i18next";
import { useIsFullScreen, useIsPrint, useWindowSize } from "@Hooks";
import { enterFullScreen, exitFullscreen, Icon } from "@Components";
import { MaximizeIcon, MinimizeIcon } from "@Assets";
import { HeaderLogo } from "@Components/header/header.logo/header.logo";

interface IInputLayout {
  w: number;
  h: number;
  x: number;
  y: number;
  i: string;
}

interface IOutputLayout extends IInputLayout {
  moved: boolean;
}

export const StatsReportsDashboard = ({
  report_id,
  widgets,
  report,
  movePosition,
  form,
  addBtn,
  widgetForm,
  setWidgetForm,
  setWidgetClone,
  auth,
  screenshotBtn,
  screenshotWidgetBtn,
}: IStatsReportChildProps & IWithStatsReportProps) => {
  const { t } = useTranslation();
  const isPrint = useIsPrint();
  const [windowWidth] = useWindowSize();
  const isFullscreen = useIsFullScreen();
  const isOwner = report.user_id === auth.user.id;

  const handleLayoutChange = useCallback(
    debounce((layout: Array<IOutputLayout>) => {
      layout
        .filter((l) => {
          const widget = widgets.find((widget) => widget.id === +l.i);

          if (!widget) return false;

          const w: any = (widget.settings?.dashboard_position ||
            {}) as IPreviewStatsReportWidget["settings"]["dashboard_position"];

          return l.w !== w.w || l.h !== w.h || l.x !== w.x || l.y !== w.y;
        })
        .map((l) => {
          const widget = widgets.find((widget) => widget.id === +l.i);

          if (widget)
            patchStatsReportWidget(+l.i, {
              settings: {
                ...widget.settings,
                dashboard_position: { w: l.w, h: l.h, x: l.x, y: l.y },
              },
            });
        });
    }, 250),
    [widgets]
  );

  const onLayoutChange = useCallback(
    (layout: Array<IOutputLayout>) => {
      handleLayoutChange(layout);
    },
    [widgets]
  );
  const gridWidth = useMemo(() => (windowWidth - 25) / 12, [windowWidth]);

  const reportPngBtn = useMemo(() => screenshotBtn("png"), []);
  const reportPdfBtn = useMemo(() => screenshotBtn("pdf"), []);

  return (
    <div className={"stats-report stats-report-dashboard"}>
      {!isFullscreen && (
        <div className="header">
          <HeaderLogo auth={auth} t={t} hideVersion={true} />
          <h1>
            <span>{t("DASHBOARD")}</span> {report.title}
          </h1>
          {!isPrint && (
            <div className="buttons">
              {addBtn}
              <Icon
                icon={MaximizeIcon}
                onClick={() => enterFullScreen()}
                tooltip={{ title: t("FULLSCREEN"), placement: "bottom" }}
                iconProps={{ size: 21 }}
              />
              {reportPngBtn}
              {reportPdfBtn}
            </div>
          )}
        </div>
      )}
      {isFullscreen && (
        <div className="exit-fullscreen">
          <Icon
            icon={MinimizeIcon}
            onClick={() => exitFullscreen()}
            tooltip={{ title: t("EXIT_FULLSCREEN") }}
          />
        </div>
      )}
      <div className="widgets">
        <GridLayout
          className="layout"
          cols={12}
          rowHeight={30}
          width={windowWidth || 1480}
          onResizeStop={isOwner && onLayoutChange}
          onDragStop={isOwner && onLayoutChange}
          draggableHandle=".draggable-handle"
        >
          {widgets.map((widget, index) => {
            const { x, y, w, h } = widget.settings.dashboard_position || {
              x: 0,
              y: 0,
              w: 2,
              h: 10,
            };

            return (
              <div
                className="widget"
                key={widget.id + ""}
                data-grid={{ x, y, w, h, isResizable: auth.user.id === report.user_id }}
              >
                <StatsWidgetView
                  t={t}
                  auth={auth}
                  is_preview={false}
                  report={report}
                  stats_report_widget={widget}
                  setWidgetForm={setWidgetForm}
                  setWidgetClone={setWidgetClone}
                  widgets={widgets}
                  index={0}
                  screenshotWidgetBtn={screenshotWidgetBtn}
                  width={w * gridWidth}
                  height={h * 40 + 10}
                  is_print={isPrint}
                />
              </div>
            );
          })}
        </GridLayout>
        {form}
      </div>
    </div>
  );
};

export const StatsReportsDashboardLayout = React.memo(withStatsReport(StatsReportsDashboard));
