"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperadminProfilePermissionIdEnum = void 0;
var SuperadminProfilePermissionIdEnum;
(function (SuperadminProfilePermissionIdEnum) {
    SuperadminProfilePermissionIdEnum["User"] = "User";
    SuperadminProfilePermissionIdEnum["Team"] = "Team";
    SuperadminProfilePermissionIdEnum["Organization"] = "Organization";
    SuperadminProfilePermissionIdEnum["MailAccount"] = "MailAccount";
    SuperadminProfilePermissionIdEnum["TextMessage"] = "TextMessage";
    SuperadminProfilePermissionIdEnum["Queue"] = "Queue";
    SuperadminProfilePermissionIdEnum["List"] = "List";
    SuperadminProfilePermissionIdEnum["Adminprofile"] = "Adminprofile";
    SuperadminProfilePermissionIdEnum["Agentprofile"] = "Agentprofile";
    SuperadminProfilePermissionIdEnum["SlaPolicy"] = "SlaPolicy";
    SuperadminProfilePermissionIdEnum["Disposition"] = "Disposition";
    SuperadminProfilePermissionIdEnum["Form"] = "Form";
    SuperadminProfilePermissionIdEnum["Field"] = "Field";
    SuperadminProfilePermissionIdEnum["FormWebsite"] = "FormWebsite";
    SuperadminProfilePermissionIdEnum["Journey"] = "Journey";
    SuperadminProfilePermissionIdEnum["OperationalHours"] = "OperationalHours";
    SuperadminProfilePermissionIdEnum["Pause"] = "Pause";
    SuperadminProfilePermissionIdEnum["CannedAnswer"] = "CannedAnswer";
    SuperadminProfilePermissionIdEnum["Signature"] = "Signature";
    SuperadminProfilePermissionIdEnum["Tag"] = "Tag";
    SuperadminProfilePermissionIdEnum["TimeInterval"] = "TimeInterval";
    SuperadminProfilePermissionIdEnum["VoiceRoute"] = "VoiceRoute";
    SuperadminProfilePermissionIdEnum["VoiceRecording"] = "VoiceRecording";
    SuperadminProfilePermissionIdEnum["VoiceVoicemailMessage"] = "VoiceVoicemailMessage";
    SuperadminProfilePermissionIdEnum["Sound"] = "Sound";
    SuperadminProfilePermissionIdEnum["JourneyVariable"] = "JourneyVariable";
    SuperadminProfilePermissionIdEnum["StatsReport"] = "StatsReport";
    SuperadminProfilePermissionIdEnum["StatsWidget"] = "StatsWidget";
    SuperadminProfilePermissionIdEnum["VoiceAsterisk"] = "VoiceAsterisk";
    SuperadminProfilePermissionIdEnum["VoiceTrunk"] = "VoiceTrunk";
    SuperadminProfilePermissionIdEnum["VoiceVoicemail"] = "VoiceVoicemail";
    SuperadminProfilePermissionIdEnum["VoiceShortcut"] = "VoiceShortcut";
    SuperadminProfilePermissionIdEnum["Tariff"] = "Tariff";
    SuperadminProfilePermissionIdEnum["TariffParent"] = "TariffParent";
    SuperadminProfilePermissionIdEnum["PhonenumberMonthlyCost"] = "PhonenumberMonthlyCost";
    SuperadminProfilePermissionIdEnum["PhonenumberMonthlyCostParent"] = "PhonenumberMonthlyCostParent";
    SuperadminProfilePermissionIdEnum["PhonenumberSetupCost"] = "PhonenumberSetupCost";
    SuperadminProfilePermissionIdEnum["PhonenumberSetupCostParent"] = "PhonenumberSetupCostParent";
    SuperadminProfilePermissionIdEnum["SuperadminProfile"] = "SuperadminProfile";
    SuperadminProfilePermissionIdEnum["Invoice"] = "Invoice";
    SuperadminProfilePermissionIdEnum["InvoicePost"] = "InvoicePost";
    SuperadminProfilePermissionIdEnum["PrepaidCredit"] = "PrepaidCredit";
    SuperadminProfilePermissionIdEnum["DestinationRestrictor"] = "DestinationRestrictor";
    SuperadminProfilePermissionIdEnum["PhonenumberStock"] = "PhonenumberStock";
    SuperadminProfilePermissionIdEnum["PhonenumberPurchase"] = "PhonenumberPurchase";
    SuperadminProfilePermissionIdEnum["StatsCustomMetric"] = "StatsCustomMetric";
    SuperadminProfilePermissionIdEnum["Release"] = "Release";
    SuperadminProfilePermissionIdEnum["Audit"] = "Audit";
    SuperadminProfilePermissionIdEnum["ApiKey"] = "ApiKey";
    SuperadminProfilePermissionIdEnum["ContactCustomEvent"] = "ContactCustomEvent";
    SuperadminProfilePermissionIdEnum["Client"] = "Client";
    SuperadminProfilePermissionIdEnum["Contact"] = "Contact";
    SuperadminProfilePermissionIdEnum["Skill"] = "Skill";
    SuperadminProfilePermissionIdEnum["Wallboard"] = "Wallboard";
    SuperadminProfilePermissionIdEnum["VoiceRecordingTranscript"] = "VoiceRecordingTranscript";
    SuperadminProfilePermissionIdEnum["VoiceRecordingTranscriptSetting"] = "VoiceRecordingTranscriptSetting";
    SuperadminProfilePermissionIdEnum["PointsSystem"] = "PointsSystem";
    SuperadminProfilePermissionIdEnum["Gdpr"] = "Gdpr";
    SuperadminProfilePermissionIdEnum["GdprAnonymize"] = "GdprAnonymize";
    SuperadminProfilePermissionIdEnum["StatsAlarm"] = "StatsAlarm";
    SuperadminProfilePermissionIdEnum["AiAssistant"] = "AiAssistant";
    SuperadminProfilePermissionIdEnum["VoiceCall"] = "VoiceCall";
})(SuperadminProfilePermissionIdEnum = exports.SuperadminProfilePermissionIdEnum || (exports.SuperadminProfilePermissionIdEnum = {}));
